import { Skeleton } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import FontAwesomeIcon from "../FontAwesomeIcon";
import * as styles from "./style.scss";

interface Props {
  className?: string;
  label: string | undefined;
  helperText?: string;
  labelWidth?: number;
  breakPoint?: number;
  id?: string;
  inputId?: string;
  children: React.ReactNode;
  required?: boolean;
  readOnly?: boolean;
  disableInputFieldAlignment?: true;
}

const FormField: React.FC<Props> = ({
  className,
  label,
  helperText,
  labelWidth,
  breakPoint,
  id,
  inputId,
  readOnly,
  children,
  required,
  disableInputFieldAlignment,
}) => {
  return (
    <div className={getClassName(className, styles.container, readOnly && styles.readOnly)}>
      <div
        className={getClassName(styles.labelContainer, { "mt-2": !disableInputFieldAlignment })}
        style={labelWidth !== undefined ? { width: labelWidth } : undefined}
      >
        <label className={getClassName(styles.label, { [styles.required]: required })} htmlFor={inputId} id={id}>
          {label}
          {readOnly && (
            <span
              aria-label="The value(s) of this field are read-only"
              title="The value(s) of this field are read-only"
              className="ml-2"
            >
              <FontAwesomeIcon icon="pencil-slash" />
            </span>
          )}
        </label>
        {helperText && <div className={styles.helperText}>{helperText}</div>}
      </div>
      <div
        className={styles.field}
        style={breakPoint !== undefined ? { minWidth: `min(100%, ${breakPoint}px)` } : undefined}
      >
        {children}
      </div>
    </div>
  );
};

export default FormField;

export const FormFieldSkeleton: React.FC<{}> = () => {
  return (
    <div className={getClassName(styles.container)}>
      <div className={getClassName(styles.labelContainer)}>
        <Skeleton width={180} />
      </div>
      <div className={styles.field}>
        <Skeleton variant="rounded" height={100} width={350} />
      </div>
    </div>
  );
};
