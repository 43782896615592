import { Box, Divider, Drawer, List, ListItemAvatar, ListItemButton, ListItemText, useMediaQuery } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { matchRoutes, renderRoutes } from "react-router-config";
import { Link } from "react-router-dom";
import { ErrorPage, FontAwesomeIcon } from "#components/index.ts";
import SquareLogo from "#components/SquareLogo/index.tsx";
import type { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { toggleSidePanelCollapsed } from "#reducers/app.ts";
import type { GlobalState } from "#reducers/index.ts";
import PanelItem from "../DatasetPanel/PanelItem";
import * as panelItemStyles from "../DatasetPanel/PanelItem/style.scss";
import * as styles from "./style.scss";

const AdminSettings: React.FC<IComponentProps> = ({ route, match, location }) => {
  const { appConfig } = useSelector((state: GlobalState) => {
    return {
      appConfig: state.config.clientConfig,
    };
  });
  const logoBgType = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoBgType);
  const logoBgColor = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoBgColor);
  const collapsed = useSelector((state: GlobalState) => state.app.sidePanelCollapsed ?? false);
  const dispatch = useDispatch();
  const mobilePanel = useMediaQuery("(max-width: 767px)");
  const panelOpen = !!location.state?.sidePanelOpen;
  const panelCollapsed = collapsed && !mobilePanel;
  const matches = route?.routes && matchRoutes(route.routes, location.pathname);
  const acl = useAcl();
  const clientConfig = useSelector((state: GlobalState) => state.config.clientConfig);
  const limitStatus = clientConfig?.limitStatus;

  const hasAnAdminPermission =
    acl.check({ action: "readInstanceLimits" }).granted ||
    acl.check({ action: "editInstanceMetadata" }).granted ||
    acl.check({ action: "manageUsers" }).granted ||
    acl.check({ action: "manageDatasets" }).granted ||
    acl.check({ action: "manageQueries" }).granted ||
    acl.check({ action: "listServicesOfInstance" }).granted ||
    acl.check({ action: "manageRedirects" }).granted ||
    acl.check({ action: "readJobsOnInstance" }).granted ||
    acl.check({ action: "manageQueryJobs" }).granted ||
    acl.check({ action: "manageInstanceCache" }).granted ||
    acl.check({ action: "manageBackgroundTasks" }).granted ||
    acl.check({ action: "manageSpeedy" }).granted;

  if (!hasAnAdminPermission) return <ErrorPage statusCode={401} />;

  return (
    <div className="flex">
      <Drawer
        variant={mobilePanel ? "temporary" : "permanent"}
        open={panelOpen}
        onClose={() => dispatch(toggleSidePanelCollapsed(false))}
        className={getClassName("hideOnPrint", styles.nav, { [styles.collapsed]: panelCollapsed })}
        PaperProps={{ elevation: 3 }}
      >
        <List
          component="nav"
          disablePadding
          aria-label="Admin Settings"
          className={getClassName(styles.nav, { [styles.collapsed]: panelCollapsed })}
        >
          <div className={styles.main}>
            {/*header*/}
            {appConfig && (
              <div className={styles.homeButton}>
                <ListItemButton component={Link} to="/" className={panelItemStyles.item}>
                  <ListItemAvatar className={panelItemStyles.collapsedAvatar}>
                    <SquareLogo
                      heightWidthPx={34}
                      logo={appConfig.branding.logo}
                      logoBgColor={logoBgColor}
                      logoBgType={logoBgType}
                    />
                  </ListItemAvatar>
                  {!panelCollapsed && (
                    <ListItemText className={panelItemStyles.text}>{appConfig.branding.name}</ListItemText>
                  )}
                </ListItemButton>
              </div>
            )}
            <List>
              {acl.check({ action: "readInstanceLimits" }).granted && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url}
                  panelCollapsed={panelCollapsed}
                  icon={"home"}
                  name={"Overview"}
                  to={match.url}
                  warning={limitStatus !== undefined}
                />
              )}
              {acl.check({ action: "editInstanceMetadata" }).granted && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url + "/site-profile"}
                  panelCollapsed={panelCollapsed}
                  icon={"browser"}
                  name={"Site profile"}
                  to={match.url + "/site-profile"}
                />
              )}
              {acl.check({ action: "editInstanceMetadata" }).granted && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url + "/security"}
                  panelCollapsed={panelCollapsed}
                  icon={"shield-halved"}
                  name={"Security"}
                  to={match.url + "/security"}
                />
              )}
              {acl.check({ action: "editInstanceMetadata" }).granted && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url + "/prefixes"}
                  panelCollapsed={panelCollapsed}
                  icon={"input-text"}
                  name={"Prefixes"}
                  to={match.url + "/prefixes"}
                />
              )}
              {acl.check({ action: "manageRedirects" }).granted && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url + "/redirects"}
                  panelCollapsed={panelCollapsed}
                  icon={"arrows-turn-right"}
                  name={"Redirects"}
                  to={match.url + "/redirects"}
                />
              )}
            </List>
            <Divider />
            <List>
              {acl.check({ action: "manageUsers" }).granted && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url + "/users"}
                  panelCollapsed={panelCollapsed}
                  icon={"users"}
                  name={"Users"}
                  to={match.url + "/users"}
                />
              )}
              {acl.check({ action: "manageUsers" }).granted && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url + "/organizations"}
                  panelCollapsed={panelCollapsed}
                  icon={"building"}
                  name={"Organizations"}
                  to={match.url + "/organizations"}
                />
              )}
            </List>
            <Divider />
            <List>
              {acl.check({ action: "manageDatasets" }).granted && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url + "/datasets"}
                  panelCollapsed={panelCollapsed}
                  icon={"table"}
                  name={"Datasets"}
                  to={match.url + "/datasets"}
                />
              )}
              {acl.check({ action: "manageQueries" }).granted && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url + "/queries"}
                  panelCollapsed={panelCollapsed}
                  icon={"magnifying-glass-play"}
                  name={"Queries"}
                  to={match.url + "/queries"}
                />
              )}
              {acl.check({ action: "listServicesOfInstance" }).granted && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url + "/services"}
                  panelCollapsed={panelCollapsed}
                  icon={"cloud"}
                  name={"Services"}
                  to={match.url + "/services"}
                />
              )}
              {(acl.check({ action: "readJobsOnInstance" }).granted ||
                acl.check({ action: "manageQueryJobs" }).granted) && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url + "/jobs"}
                  panelCollapsed={panelCollapsed}
                  icon={"briefcase"}
                  name={"Jobs"}
                  to={match.url + "/jobs"}
                />
              )}
            </List>
            <Divider />
            <List>
              {acl.check({ action: "editInstanceMetadata" }).granted && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url + "/advanced-settings"}
                  panelCollapsed={panelCollapsed}
                  icon={"gear-complex-code"}
                  name={"Advanced Settings"}
                  to={match.url + "/advanced-settings"}
                />
              )}
              {acl.check({ action: "manageInstanceCache" }).granted && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url + "/cache"}
                  panelCollapsed={panelCollapsed}
                  icon={"database"}
                  name={"Caches"}
                  to={match.url + "/cache"}
                />
              )}
              {acl.check({ action: "manageBackgroundTasks" }).granted && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url + "/tasks"}
                  panelCollapsed={panelCollapsed}
                  icon={"list-check"}
                  name={"Background tasks"}
                  to={match.url + "/tasks"}
                />
              )}
              {acl.check({ action: "manageSpeedy" }).granted && (
                <PanelItem
                  mobile={mobilePanel}
                  active={matches && matches[0].match.url === match.url + "/analytics"}
                  panelCollapsed={panelCollapsed}
                  icon={"chart-line"}
                  name={"Analytics"}
                  to={match.url + "/analytics"}
                />
              )}
            </List>
          </div>
          <ListItemButton
            className={styles.toggler}
            onClick={() => {
              const newValue = !panelCollapsed;
              dispatch(toggleSidePanelCollapsed(newValue));
            }}
            aria-label={panelCollapsed ? `Expand sidebar` : "shrink sidebar"}
            aria-pressed={panelCollapsed}
          >
            <FontAwesomeIcon icon={panelCollapsed ? "chevron-right" : "chevron-left"} />
          </ListItemButton>
        </List>
      </Drawer>
      <div className={styles.content}>{route && renderRoutes(route.routes)}</div>
    </div>
  );
};

export default AdminSettings;
