import { Button, IconButton } from "@mui/material";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import fetch from "#helpers/fetch.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import { ConfirmationDialog, Dialog, FontAwesomeIcon } from "../../components";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../reducers/datasetManagement";
import { getGraphs } from "../../reducers/graphs";
import SparqlBasedConstraint from "./Forms/SparqlBasedConstraint";
import type { SparqlBasedConstraintData } from "./Forms/Types";

const factory = factories.compliant;

const EditSparqlBasedConstraint: React.FC<{
  shapeIri: string;
  query: string;
  message: string;
}> = ({ shapeIri, query, message }) => {
  const [open, setOpen] = React.useState(false);
  const [isDirty, setDirty] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);

  const currentDs = useCurrentDataset()!;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const datasetUrl = useConstructConsoleUrl()({ pathname: `/${currentDs.owner.accountName}/${currentDs.name}` });

  const dispatch = useDispatch();

  const onClose = React.useCallback(() => {
    isDirty ? setConfirmationDialogOpen(true) : setOpen(false);
  }, [isDirty]);

  const prefixes = useDatasetPrefixes();
  const baseIri = prefixes.find((prefix) => prefix.prefixLabel === "shp")?.iri || `${datasetUrl}/shp/`;

  return (
    <>
      <IconButton aria-label="add" onClick={() => setOpen(true)} size="small">
        <FontAwesomeIcon icon="pencil" />
      </IconButton>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          setOpen(false);
        }}
        onClose={() => setConfirmationDialogOpen(false)}
        title="Are sure you want to close this form?"
        actionLabel="Close"
        description="If you close the form now all changes will be lost"
      />

      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth title="Add a SPARQL based constraint" closeButton>
        <div className="px-5 pb-5">
          <SparqlBasedConstraint
            onDirty={setDirty}
            initialValues={{
              query: query,
              message: message,
            }}
            onSubmit={async (newSparqlBasedConstraint: SparqlBasedConstraintData) => {
              const shaclMessage = newSparqlBasedConstraint.message?.trim();
              const shQuery = newSparqlBasedConstraint.query.trim();

              const messageChanged = message !== shaclMessage;
              const queryChanged = query !== shQuery;

              const updateQuery = `
                prefix sh: <http://www.w3.org/ns/shacl#>
                prefix owl: <http://www.w3.org/2002/07/owl#>
                prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>

                insert {
                  ?id
                    sh:message ?message ;
                    sh:select ?query .
                } where {
                  bind(${termToString(factory.namedNode(shapeIri))} as ?id)
                  values (?message ?query) {
                    (
                      ${messageChanged && shaclMessage ? termToString(factory.literal(shaclMessage)) : "UNDEF"}
                      ${queryChanged ? termToString(factory.literal(shQuery)) : "UNDEF"}
                  )}
                };
                delete {
                  ?id
                    sh:message ?message ;
                    sh:select ?query .
                } where {
                  bind(${termToString(factory.namedNode(shapeIri))} as ?id)
                  values (?message ?query) {
                    (
                      ${messageChanged && shaclMessage ? termToString(factory.literal(message)) : "UNDEF"}
                      ${queryChanged ? termToString(factory.literal(query)) : "UNDEF"}
                    )
                  } 
                }
              `;

              const body = new FormData();
              body.set("update", updateQuery);

              await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });

              await dispatch<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
              );
              await dispatch<typeof getGraphs>(
                getGraphs({
                  accountName: currentDs.owner.accountName,
                  datasetName: currentDs.name,
                  datasetId: currentDs.id,
                }),
              );

              setOpen(false);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default EditSparqlBasedConstraint;
