import * as React from "react";
import { useSelector } from "react-redux";
import type { ServiceMetadata } from "@triply/utils/Models";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import type { GlobalState } from "#reducers/index.ts";
import { getRunningServices } from "#reducers/services.ts";

export default function useRunningServices(type: "sparql" | "elasticsearch") {
  const currentDs = useCurrentDataset();
  const services = useSelector((state: GlobalState) => {
    return !!currentDs ? state.services[currentDs.id] : undefined;
  });
  const filteredServices = React.useMemo(() => {
    return getRunningServices((services || []) as ServiceMetadata[], type === "sparql" ? "sparql" : "elasticSearch");
  }, [services, type]);
  return filteredServices;
}
