import type { RadioGroupProps } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { assign, pickBy } from "lodash-es";
import * as React from "react";
import * as ReduxForm from "redux-form";
import * as styles from "./style.scss";

class MaterialRadioButtonGroup extends React.PureComponent<
  MaterialRadioButtonGroup.Props & ReduxForm.WrappedFieldProps,
  MaterialRadioButtonGroup.State
> {
  adjustOwnProps(): RadioGroupProps {
    //map redux props to material-ui props
    const props: any = assign<{}, RadioGroupProps>({}, this.props);

    assign(props, props.input);

    return pickBy(props, (_val, key) => {
      if (["input", "meta"].indexOf(key) >= 0) return false;
      if (key.substring(0, 2) === "t_") return false;
      return true;
    }) as RadioGroupProps;
  }

  render() {
    return (
      <div className={styles.checkbox}>
        <RadioGroup
          {...this.adjustOwnProps()}
          style={{
            fontSize: "inherit",
          }}
        />
      </div>
    );
  }
}
export const Field: new () => React.Component<MaterialRadioButtonGroup.Props> = ReduxForm.Field as any;
namespace MaterialRadioButtonGroup {
  export interface Props extends Omit<RadioGroupProps, "onChange"> {
    name: string;
    component: any;
  }
  export interface State {}
}
export default MaterialRadioButtonGroup;
