import { Divider } from "@mui/material";
import * as React from "react";
import type { Prefix } from "@triply/utils";
import type { PrefixUpdate } from "#reducers/datasetManagement.ts";
import type { CacheHeader, QueryResponse, RawQueryResponse } from "../SparqlUtils";
import LargeResultWidget from "./Visualizations/LargeResultWidget";
import type { DownloadVisualization } from "./SparqlVisualizationContext";
import VisualizationSelector from "./VisualizationSelector";
import SparqlQueryResults, { type VisualizationConfig, type VisualizationLabel, type VisualizationProperties } from ".";
import * as styles from "./styles.scss";

interface Props {
  queryName: string;
  selectorClassName?: string;
  visualizationClassName?: string;
  largeResultsClassName?: string;
  datasetPath?: string;
  error?: Error;
  loading: boolean;
  duration?: number;
  delay?: number;
  responseSize?: number;
  cache?: CacheHeader;
  data: QueryResponse | undefined;
  rawData: RawQueryResponse | undefined;
  prefixes: Prefix[];
  visualization: VisualizationLabel;
  visualizationConfig: VisualizationConfig;
  visualizationProperties: VisualizationProperties;
  hideVisualizations?: VisualizationLabel[];
  skipResultsCheck?: boolean;
  onPostProcess: () => void;
  onCreatePrefix?: (prefix: PrefixUpdate) => Promise<true | string>;
  setCurrentVisualization: (newVisualization: VisualizationLabel) => void;
  setVisualizationConfig?: (selectedPlugin: VisualizationLabel, config: VisualizationConfig) => void;
}

const ConfigurableSparqlVisualization: React.FC<Props> = ({
  visualization,
  setCurrentVisualization,
  error,
  loading,
  duration,
  delay,
  cache,
  data,
  rawData,
  selectorClassName,
  visualizationClassName,
  queryName,
  hideVisualizations,
  onPostProcess,
  prefixes,
  visualizationConfig,
  visualizationProperties,
  skipResultsCheck,
  datasetPath,
  onCreatePrefix,
  responseSize,
  setVisualizationConfig,
}) => {
  const visualizationActionsCtrId = React.useId(); //Element ID of visualization Actions Container (will be passed into Plugin component via context for Portal Components)
  const [downloads, setDownloads] = React.useState<DownloadVisualization[]>([]);
  const registerDownload = React.useCallback((download: DownloadVisualization) => {
    setDownloads((downloads) => [...downloads.filter((d) => d.id !== download.id), download]);
  }, []);

  const unregisterDownload = React.useCallback((id: VisualizationLabel) => {
    setDownloads((downloads) => downloads.filter((d) => d.id !== id));
  }, []);

  return (
    <>
      <VisualizationSelector
        className={selectorClassName}
        currentVisualization={visualization}
        setCurrentVisualization={setCurrentVisualization}
        duration={duration}
        delay={delay}
        cache={cache}
        error={!!error}
        loading={loading || false}
        data={data}
        rawData={rawData}
        visualizationActionsCtrId={visualizationActionsCtrId}
        downloads={downloads}
        queryName={queryName}
        hideVisualizations={hideVisualizations}
      />
      <Divider />
      {!error && rawData && !data ? (
        <div className={styles.largeResultsContainer}>
          <LargeResultWidget
            exceededSize="resultSize"
            onContinue={() => {
              onPostProcess();
            }}
          />
        </div>
      ) : (
        <SparqlQueryResults
          className={visualizationClassName}
          queryName={queryName}
          error={error}
          prefixes={prefixes}
          data={data}
          rawData={rawData}
          datasetPath={datasetPath}
          visualization={visualization}
          visualizationConfig={visualizationConfig}
          setVisualizationConfig={setVisualizationConfig}
          visualizationActionsCtrId={visualizationActionsCtrId}
          registerDownload={registerDownload}
          unregisterDownload={unregisterDownload}
          onCreatePrefix={onCreatePrefix}
          visualizationProperties={visualizationProperties}
          responseSize={responseSize}
          checkLimits={!skipResultsCheck}
        />
      )}
    </>
  );
};

export default ConfigurableSparqlVisualization;
