import type { EmbeddedActionsParser, Lexer } from "chevrotain";
import { rdf, xsd } from "./utils/constants.ts";
import { lexer as base64BinaryLexer, parser as base64BinaryParser } from "./xsd/base64Binary.ts";
import { lexer as booleanLexer, parser as booleanParser } from "./xsd/boolean.ts";
import {
  dateTimeToCanonical,
  dateToCanonical,
  gDayToCanonical,
  gMonthDayToCanonical,
  gMonthToCanonical,
  gYearMonthToCanonical,
  gYearToCanonical,
  lexer as datetimeLexer,
  parser as datetimeParser,
  timeToCanonical,
} from "./xsd/dateTime.ts";
import {
  decimalToCanonical,
  doubleToCanonical,
  floatToCanonical,
  lexer as decimalLexer,
  parser as decimalParser,
} from "./xsd/decimal.ts";
import {
  durationToCanonical,
  lexer as durationLexer,
  parser as durationParser,
  yearMonthDurationToCanonical,
} from "./xsd/duration.ts";
import { lexer as hexBinaryLexer, parser as hexBinaryParser } from "./xsd/hexBinary.ts";
import { lexer as integerLexer, parser as integerParser } from "./xsd/integer.ts";
import { StandardParseError, ValueRangeError } from "./Errors.ts";
import type { ParserAndSerializer } from "./index.ts";

function getParserAndSerializer<
  P extends EmbeddedActionsParser,
  M extends keyof P,
  V extends M extends string ? (P[M] extends () => {} ? ReturnType<P[M]> : never) : never,
>({
  parser,
  lexer,
  parseMethod,
  serialize,
}: {
  parser: P;
  lexer: Lexer;
  parseMethod: M;
  serialize: (value: V) => string;
}): {
  parse: (lexicalValue: string) => V;
  serialize: (value: V) => string;
} {
  return {
    parse: (lexicalValue) => {
      const lexResult = lexer.tokenize(lexicalValue);
      if (lexResult.errors.length) {
        throw new StandardParseError({
          lexicalValue: lexicalValue,
          offset: lexResult.errors[0].column,
          cause: new Error(lexResult.errors[0].message),
        });
      }
      // Setting the input will reset the parser's state
      parser.input = lexResult.tokens;
      try {
        let value;
        try {
          // Parse
          value = (parser[parseMethod] as any)();
        } catch (e) {
          // Errors caught here won't be parse errors, but errors thrown during parsing (e.g. validation)
          if (e instanceof RangeError) {
            throw new StandardParseError({
              lexicalValue: lexicalValue,
              offset: parser.errors[0]?.token?.startOffset,
              cause: e,
            });
          }
          throw e;
        }
        if (parser.errors.length) {
          throw new StandardParseError({
            lexicalValue: lexicalValue,
            offset: parser.errors[0].token.startOffset,
            cause: parser.errors[0],
          });
        }
        return value;
      } finally {
        parser.reset();
      }
    },
    serialize: serialize,
  };
}

// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String#utf-16_characters_unicode_codepoints_and_grapheme_clusters
// and https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/unicode
// and https://www.w3.org/TR/xml11/#NT-Char
const ILLEGAL_STRING_CHARACTERS = /[\uFFFE\uFFFF\uD800-\uDFFF]/u; // FFFE, FFFF and isolated surrogates

// The grammar didn't support all the unicode things that we wanted, but
// regular expressions do!
const stringParserAndSerializer: ParserAndSerializer<string> = {
  parse: (lexicalValue) => {
    // Validation of string
    if (ILLEGAL_STRING_CHARACTERS.test(lexicalValue)) throw new ValueRangeError({ value: lexicalValue });
    return lexicalValue;
  },
  serialize: (value) => value,
};

const parsersAndSerializers = {
  [xsd("byte")]: getParserAndSerializer({
    parseMethod: "byte",
    lexer: integerLexer,
    parser: integerParser,
    serialize: decimalToCanonical,
  }),
  [xsd("base64Binary")]: getParserAndSerializer({
    parseMethod: "base64Binary",
    lexer: base64BinaryLexer,
    parser: base64BinaryParser,
    serialize: (value) => value,
  }),
  [xsd("boolean")]: getParserAndSerializer({
    parseMethod: "boolean",
    lexer: booleanLexer,
    parser: booleanParser,
    serialize: (bool) => (bool ? "true" : "false"),
  }),
  [xsd("dayTimeDuration")]: getParserAndSerializer({
    parseMethod: "dayTimeDurationLexicalRep",
    lexer: durationLexer,
    parser: durationParser,
    serialize: durationToCanonical,
  }),
  [xsd("date")]: getParserAndSerializer({
    parseMethod: "dateLexicalRep",
    lexer: datetimeLexer,
    parser: datetimeParser,
    serialize: dateToCanonical,
  }),
  [xsd("dateTime")]: getParserAndSerializer({
    parseMethod: "dateTimeLexicalRep",
    lexer: datetimeLexer,
    parser: datetimeParser,
    serialize: dateTimeToCanonical,
  }),
  [xsd("dateTimeStamp")]: getParserAndSerializer({
    parseMethod: "dateTimeStampLexicalRep",
    lexer: datetimeLexer,
    parser: datetimeParser,
    serialize: dateTimeToCanonical,
  }),
  [xsd("decimal")]: getParserAndSerializer({
    parseMethod: "decimalLexicalRep",
    lexer: decimalLexer,
    parser: decimalParser,
    serialize: decimalToCanonical,
  }),
  [xsd("double")]: getParserAndSerializer({
    parseMethod: "doubleRep",
    lexer: decimalLexer,
    parser: decimalParser,
    serialize: doubleToCanonical,
  }),
  [xsd("duration")]: getParserAndSerializer({
    parseMethod: "durationLexicalRep",
    lexer: durationLexer,
    parser: durationParser,
    serialize: durationToCanonical,
  }),
  [xsd("float")]: getParserAndSerializer({
    parseMethod: "floatRep",
    lexer: decimalLexer,
    parser: decimalParser,
    serialize: floatToCanonical,
  }),
  [xsd("gDay")]: getParserAndSerializer({
    parseMethod: "gDayLexicalRep",
    lexer: datetimeLexer,
    parser: datetimeParser,
    serialize: gDayToCanonical,
  }),
  [xsd("gMonth")]: getParserAndSerializer({
    parseMethod: "gMonthLexicalRep",
    lexer: datetimeLexer,
    parser: datetimeParser,
    serialize: gMonthToCanonical,
  }),
  [xsd("gMonthDay")]: getParserAndSerializer({
    parseMethod: "gMonthDayLexicalRep",
    lexer: datetimeLexer,
    parser: datetimeParser,
    serialize: gMonthDayToCanonical,
  }),
  [xsd("gYearMonth")]: getParserAndSerializer({
    parseMethod: "gYearMonthLexicalRep",
    lexer: datetimeLexer,
    parser: datetimeParser,
    serialize: gYearMonthToCanonical,
  }),
  [xsd("gYear")]: getParserAndSerializer({
    parseMethod: "gYearLexicalRep",
    lexer: datetimeLexer,
    parser: datetimeParser,
    serialize: gYearToCanonical,
  }),
  [xsd("hexBinary")]: getParserAndSerializer({
    parseMethod: "hexBinary",
    lexer: hexBinaryLexer,
    parser: hexBinaryParser,
    serialize: (value) => value,
  }),
  [xsd("int")]: getParserAndSerializer({
    parseMethod: "int",
    lexer: integerLexer,
    parser: integerParser,
    serialize: decimalToCanonical,
  }),
  [xsd("integer")]: getParserAndSerializer({
    parseMethod: "integer",
    lexer: integerLexer,
    parser: integerParser,
    serialize: decimalToCanonical,
  }),
  [rdf("langString")]: stringParserAndSerializer,
  [xsd("long")]: getParserAndSerializer({
    parseMethod: "long",
    lexer: integerLexer,
    parser: integerParser,
    serialize: decimalToCanonical,
  }),
  [xsd("negativeInteger")]: getParserAndSerializer({
    parseMethod: "negativeInteger",
    lexer: integerLexer,
    parser: integerParser,
    serialize: decimalToCanonical,
  }),
  [xsd("nonNegativeInteger")]: getParserAndSerializer({
    parseMethod: "nonNegativeInteger",
    lexer: integerLexer,
    parser: integerParser,
    serialize: decimalToCanonical,
  }),
  [xsd("nonPositiveInteger")]: getParserAndSerializer({
    parseMethod: "nonPositiveInteger",
    lexer: integerLexer,
    parser: integerParser,
    serialize: decimalToCanonical,
  }),
  [xsd("positiveInteger")]: getParserAndSerializer({
    parseMethod: "positiveInteger",
    lexer: integerLexer,
    parser: integerParser,
    serialize: decimalToCanonical,
  }),
  [xsd("short")]: getParserAndSerializer({
    parseMethod: "short",
    lexer: integerLexer,
    parser: integerParser,
    serialize: decimalToCanonical,
  }),
  [xsd("string")]: stringParserAndSerializer,
  [xsd("time")]: getParserAndSerializer({
    parseMethod: "timeLexicalRep",
    lexer: datetimeLexer,
    parser: datetimeParser,
    serialize: timeToCanonical,
  }),
  [xsd("unsignedByte")]: getParserAndSerializer({
    parseMethod: "unsignedByte",
    lexer: integerLexer,
    parser: integerParser,
    serialize: decimalToCanonical,
  }),
  [xsd("unsignedInt")]: getParserAndSerializer({
    parseMethod: "unsignedInt",
    lexer: integerLexer,
    parser: integerParser,
    serialize: decimalToCanonical,
  }),
  [xsd("unsignedLong")]: getParserAndSerializer({
    parseMethod: "unsignedLong",
    lexer: integerLexer,
    parser: integerParser,
    serialize: decimalToCanonical,
  }),
  [xsd("unsignedShort")]: getParserAndSerializer({
    parseMethod: "unsignedShort",
    lexer: integerLexer,
    parser: integerParser,
    serialize: decimalToCanonical,
  }),
  [xsd("yearMonthDuration")]: getParserAndSerializer({
    parseMethod: "yearMonthDurationLexicalRep",
    lexer: durationLexer,
    parser: durationParser,
    serialize: yearMonthDurationToCanonical,
  }),
};
export default parsersAndSerializers;
