import { mapValues } from "lodash-es";
import type { NumericDataTypeIri } from "@triplydb/recognized-datatypes/utils/constants";
import { DATA_TYPE_NAME_MAPPING } from "@triplydb/recognized-datatypes/utils/constants";
import type * as Terms from "#Terms";
import { factories } from "./DataFactory.ts";

type NameToType = typeof DATA_TYPE_NAME_MAPPING;
export const DATA_TYPES: {
  [Name in keyof NameToType]: Terms.NamedNode<NameToType[Name]>;
} = mapValues(DATA_TYPE_NAME_MAPPING, (value) => factories.compliant.namedNode(value) as any);

export type NumericDataType = Terms.NamedNode<NumericDataTypeIri>;
