import { compact, flatten, isEmpty, size, uniq } from "lodash-es";
import yaml from "yaml";
import type * as Types from "./types.ts";

var XSD_INTEGER = "http://www.w3.org/2001/XMLSchema#integer";
var XSD_STRING = "http://www.w3.org/2001/XMLSchema#string";

const INDENT = "  ";

interface SerializationOpts {
  iriSerialization?: "full iri";
}

function variableToString(variable: Types.VariableTerm) {
  return variable.surfaceForm || `?${variable.value}`;
}

export function isWildcard(
  variables:
    | Types.Expression
    | Types.Triple["subject" | "predicate" | "object"]
    | Types.Wildcard
    | Array<Types.Variable | Types.IriTerm>,
): variables is Types.Wildcard {
  return "type" in variables && variables.type === "wildcard";
}

/** Checks whether the object is a Term */
function isTerm(object: unknown): object is Types.Term {
  return !!object && typeof object === "object" && "termType" in object && typeof object.termType === "string";
}

function join(...segments: Array<string | undefined | false | null>) {
  return compact(segments).join(" ");
}

// Checks whether term1 and term2 are equivalent without `.equals()` prototype method
function equalTerms(term1?: Types.Term | Types.PropertyPath, term2?: Types.Term | Types.PropertyPath): boolean {
  if (!term1 || !isTerm(term1)) {
    return false;
  }
  if (!term2 || !isTerm(term2)) {
    return false;
  }
  if (term1.termType !== term2.termType) {
    return false;
  }
  switch (term1.termType) {
    case "Literal":
      if (term1.termType !== term2.termType) {
        return false;
      } // Just for type narrowing
      return (
        term1.value === term2.value && term1.language === term2.language && equalTerms(term1.datatype, term2.datatype)
      );

    default:
      return term1.value === term2.value;
  }
}

function encodeIRI(iri: Types.IriTerm, opts: SerializationOpts) {
  if (!opts.iriSerialization && iri.surfaceForm) {
    return iri.surfaceForm;
  }
  return "<" + iri.value + ">";
}

export function termToString(term: Types.Term, opts?: SerializationOpts) {
  switch (term.termType) {
    case "Variable":
      return variableToString(term);
    case "BlankNode":
      return "_:" + term.value;
    case "Literal":
      const lexical = term.value || "";
      let datatype = term.datatype;
      let literalString = '"' + lexical.replace(escape, escapeReplacer) + '"';
      if (term.language) {
        return literalString + "@" + term.language;
      }
      // Abbreviate literals when possible
      switch (datatype.value) {
        case XSD_STRING:
          return literalString;
        case XSD_INTEGER:
          if (/^\d+$/.test(lexical)) {
            // Add space to avoid confusion with decimals in broken parsers
            return lexical + " ";
          }
      }
      return literalString + "^^" + encodeIRI(datatype, opts || {});

    default:
      return encodeIRI(term, opts || {});
  }
}

class Serialize {
  opts: SerializationOpts;
  constructor(opts?: SerializationOpts) {
    this.opts = opts ?? {};
  }

  private entity = (value: Types.Term | Types.PropertyPath): string => {
    if (isTerm(value)) return termToString(value, this.opts);

    // property path
    const items = value.items.map(this.entity);
    const path = value.pathType;
    switch (path) {
      // prefix operator
      case "^":
        return path + items[0];
      case "!":
        return path + "(" + items.join("|") + ")";
      // postfix operator
      case "*":
      case "+":
      case "?":
        return "(" + items[0] + path + ")";
      // infix operator
      case "|":
      case "/":
        return "(" + items.join(path) + ")";
    }
  };

  expression = (expr: Types.Expression | Types.Wildcard): string => {
    if (isWildcard(expr)) return "*";
    if (isTerm(expr)) return this.entity(expr);
    if ("type" in expr) {
      switch (expr.type) {
        case "aggregate":
          return join(
            expr.aggregation,
            "(",
            expr.distinct && "distinct",
            this.expression(expr.expression),
            expr.aggregation === "group_concat" && `; SEPARATOR = "${expr.separator.replace(escape, escapeReplacer)}"`,
            ")",
          );
        case "iriFunction":
          return join(this.entity(expr.function), "(", expr.args.map(this.expression).join(", "), ")");

        case "function":
          let functionName: string = expr.function;
          switch (expr.function) {
            // Infix operators
            case "<":
            case ">":
            case ">=":
            case "<=":
            case "&&":
            case "||":
            case "=":
            case "!=":
            case "+":
            case "-":
            case "*":
            case "/":
              return join(
                isTerm(expr.args[0]) ? this.entity(expr.args[0]) : "(" + this.expression(expr.args[0]) + ")",
                functionName,
                isTerm(expr.args[1]) ? this.entity(expr.args[1]) : "(" + this.expression(expr.args[1]) + ")",
              );
            // Unary operators
            case "!":
              return "!(" + this.expression(expr.args[0]) + ")";
            case "uplus":
              return "+(" + this.expression(expr.args[0]) + ")";
            case "uminus":
              return "-(" + this.expression(expr.args[0]) + ")";
            // IN and NOT IN
            case "notin":
              functionName = "not in";
            case "in":
              return join(
                this.expression(expr.args[0]),

                functionName,
                "(",
                typeof expr.args[1] === "string" ? expr.args[1] : expr.args[1].map(this.expression).join(", "),
                ")",
              );
            // EXISTS and NOT EXISTS
            case "notexists":
              functionName = "not exists";
            case "exists":
              return join(functionName, this.group(expr.args[0], true));
            // Other expressions
            default:
              return join(functionName, "(", expr.args.map(this.expression).join(", "), ")");
          }
        default:
          throw new Error("Unknown expression type: " + (expr as any).type);
      }
    } else {
      throw new Error("Unknown expression: " + expr);
    }
  };

  private values(valuesList: Types.ValuesPattern) {
    // Gather unique keys
    const keys = uniq(flatten(valuesList.values.map((val) => Object.keys(val))));
    if (keys.length === 0 && size(valuesList.values) === 1) {
      return `values () { () }`;
    }
    // Check whether simple syntax can be used
    let lparen = "";
    let rparen = "";
    if (keys.length > 1) {
      lparen = "(";
      rparen = ")";
    }
    const valuesVars = keys.map((k) => `?${k}`);
    const datablock = valuesList.values.map((values) => {
      return (
        "  " +
        join(
          lparen,
          ...keys.map((key) => {
            const val = values[key];
            return val ? this.entity(val) : "UNDEF";
          }),
          rparen,
        )
      );
    });
    return join("values", lparen, ...valuesVars, rparen, "{") + "\n" + datablock.join("\n") + "\n" + "}";
  }

  pattern(
    pattern: Types.Pattern | Types.Triple | Types.Quads | Array<Types.Pattern | Types.Triple | Types.Quads>,
  ): string {
    if ("subject" in pattern)
      return join(this.entity(pattern.subject), this.entity(pattern.predicate), this.entity(pattern.object), ".");
    if (Array.isArray(pattern)) return pattern.map((p) => this.pattern(p)).join("\n");
    switch (pattern.type) {
      case "bgp":
        return this.encodeTriples(pattern.triples);
      case "bind":
        return "bind(" + this.expression(pattern.expression) + " as " + variableToString(pattern.variable) + ")";
      case "filter":
        return "filter(" + this.expression(pattern.expression) + ")";
      case "graph":
        return "graph " + this.entity(pattern.name) + " " + this.group(pattern);
      case "group":
        return this.group(pattern);
      case "minus":
        return "minus " + this.group(pattern);
      case "optional":
        return "optional " + this.group(pattern);
      case "query":
        return this.query(pattern);
      case "service":
        return "service " + (pattern.silent ? "silent " : "") + this.entity(pattern.name) + " " + this.group(pattern);
      case "union":
        return pattern.patterns.map((p) => this.group(p, true)).join("\nunion\n");
      case "values":
        return this.values(pattern);
    }
  }

  private encodeTriples(triples: Types.Triple[]) {
    if (!triples.length) return "";

    var parts = [],
      subject = undefined,
      predicate = undefined;
    for (var i = 0; i < triples.length; i++) {
      var triple = triples[i];
      // Triple with different subject
      if (!equalTerms(triple.subject, subject)) {
        // Terminate previous triple
        if (subject) parts.push(".\n");
        subject = triple.subject;
        predicate = triple.predicate;
        parts.push(this.entity(subject), " ", this.entity(predicate));
      }
      // Triple with same subject but different predicate
      else if (!equalTerms(triple.predicate, predicate)) {
        predicate = triple.predicate;
        parts.push(";\n", INDENT, this.entity(predicate));
      }
      // Triple with same subject and predicate
      else {
        parts.push(",");
      }
      parts.push(" ", this.entity(triple.object));
    }
    parts.push(".");

    return parts.join("");
  }
  private indent(text: string) {
    return text.replace(/^/gm, INDENT);
  }
  private group = (
    pattern: Types.GraphQuads | Types.Pattern | Types.Triple[] | Types.QuadData[] | Types.Pattern[] | Types.Quads[],
    inline = false,
  ) => {
    let group: string;
    if (inline) {
      group = this.pattern(!("type" in pattern) || pattern.type !== "group" ? pattern : pattern.patterns);
    } else {
      if ("patterns" in pattern) {
        group = pattern.patterns.map((p) => this.pattern(p)).join("\n");
      } else if ("triples" in pattern) {
        group = pattern.triples.map((p) => this.pattern(p)).join("\n");
      } else {
        group = this.pattern(pattern);
      }
    }

    return group.includes("\n") ? "{\n" + group + "}" : "{\n" + this.indent(group) + "\n}";
  };

  private graphs(keyword: string, graphs: Types.IriTerm[]) {
    if (!graphs || !graphs.length) return "";
    return graphs
      .map((g) => {
        return keyword + this.entity(g) + "\n";
      })
      .join("");
  }

  public query(q: Types.Query | Types.SubSelect | Types.Update) {
    let query = "";
    if ("annotations" in q && !isEmpty(q.annotations)) {
      const annotationString = yaml
        .stringify(q.annotations)
        .trim()
        .split("\n")
        .map((l) => `#! ${l}`)
        .join("\n");
      query += `\n${annotationString}\n`;
    }
    query += this.baseAndPrefixes(q);
    if ("queryType" in q) {
      query += (q.queryType === "subselect" ? "select" : q.queryType) + " ";
    }
    if ("reduced" in q && q.reduced) query += "reduced ";
    if ("distinct" in q && q.distinct) query += "distinct ";

    if (q.type === "query") {
      if (q.queryType === "construct") {
        query += this.group(q.template, true) + "\n";
      } else if (q.queryType !== "ask") {
        if (Array.isArray(q.variables)) {
          query += q.variables
            .map((variable) => {
              return isTerm(variable)
                ? this.entity(variable)
                : "(" + this.expression(variable.expression) + " as " + variableToString(variable.variable) + ")";
            })
            .join(" ");
        } else {
          query += this.expression(q.variables);
        }
        query += " ";
      }
    }

    if ("from" in q && q.from && q.from !== null)
      query += this.graphs("from ", q.from.default) + this.graphs("FROM NAMED ", q.from.named);
    if ("where" in q && q.where) query += "where " + this.group(q.where, true) + "\n";

    if ("updates" in q && q.updates) query += q.updates.map(this.update).join(";\n");

    if ("group" in q && q.group.length) {
      query +=
        "group by " +
        q.group
          .map((it) => {
            var result = isTerm(it.expression)
              ? this.entity(it.expression)
              : "(" + this.expression(it.expression) + ")";
            return it.variable ? "(" + result + " AS " + variableToString(it.variable) + ")" : result;
          })
          .join(" ") +
        "\n";
    }
    if ("having" in q && q.having.length) query += "having (" + q.having.map(this.expression).join(" ") + ")" + "\n";
    if ("order" in q && q.order.length)
      query +=
        "order by " +
        q.order
          .map((it) => {
            var expr = "(" + this.expression(it.expression) + ")";
            return !it.descending ? expr : "desc " + expr;
          })
          .join(" ") +
        "\n";

    if ("offset" in q && q.offset !== null) query += "offset " + q.offset + "\n";
    if ("limit" in q && q.limit !== null) query += "limit " + q.limit + "\n";

    if ("values" in q && q.values && !isEmpty(q.values)) query += this.values({ type: "values", values: q.values });

    return query.trimEnd();
  }

  private baseAndPrefixes(q: Types.Query | Types.Update | Types.SubSelect) {
    var base = "base" in q && q.base !== null ? "base <" + q.base + ">" + "\n" : "";
    var prefixes = "";
    if ("prefixes" in q) {
      for (let key in q.prefixes) {
        prefixes += "prefix " + key + ": <" + q.prefixes[key] + ">" + "\n";
      }
    }
    return base + prefixes;
  }

  // Converts the parsed update object into a SPARQL update clause
  private update = (update: Types.UpdateOperation) => {
    switch (update.type) {
      case "add":
      case "copy":
      case "move":
        return join(
          update.type,
          update.silent && "silent",
          update.source.ref === "default" ? "default" : this.entity(update.source.name),
          "to",
          update.destination.ref === "default" ? "default" : this.entity(update.destination.name),
        );
      case "create":
        return join(update.type, update.silent && "silent", `graph ${this.entity(update.graph)}`);
      case "clear":
      case "drop":
        return join(
          update.type,
          update.silent && "silent",
          "name" in update.graph && `graph ${this.entity(update.graph.name)}`,
          ["default", "named", "all"].includes(update.graph.ref) && update.graph.ref,
        );
      case "load":
        return join(
          "load",
          update.source && this.entity(update.source),
          update.destination && `into graph ${this.entity(update.destination)}`,
        );
      case "insert":
        return "insert data " + this.group(update.insert, true);
      case "delete":
        return "delete data " + this.group(update.delete, true);
      case "deletewhere":
        return "delete where " + this.group(update.where, true);
      case "insertdelete":
        return (
          (update.graph ? "with " + this.entity(update.graph) + "\n" : "") +
          (update.delete.length ? "delete " + this.group(update.delete, true) + "\n" : "") +
          (update.insert.length ? "insert " + this.group(update.insert, true) + "\n" : "") +
          (update.using.default ? this.graphs("using ", update.using.default) : "") +
          (update.using.named ? this.graphs("using named ", update.using.named) : "") +
          "where " +
          this.group(update.where, true)
        );
      default:
        throw new Error("Unknown update type: " + (update as Types.UpdateOperation).type);
    }
  };
}

const escape = /["\\\t\n\r\b\f]/g;

function escapeReplacer(c: string) {
  return c in escapeReplacements ? escapeReplacements[c as keyof typeof escapeReplacements] : c;
}
const escapeReplacements = {
  "\\": "\\\\",
  '"': '\\"',
  "\t": "\\t",
  "\n": "\\n",
  "\r": "\\r",
  "\b": "\\b",
  "\f": "\\f",
};

export function serialize(
  ast: Types.Query | Types.Update | Types.SubSelect | Types.Pattern | Types.Expression,
  opts?: SerializationOpts,
) {
  const serializer = new Serialize(opts);
  if ("termType" in ast) return serializer.expression(ast).trim();
  switch (ast.type) {
    case "query":
    case "update":
      return serializer.query(ast).trim();
    case "group":
    case "bgp":
    case "optional":
    case "union":
    case "graph":
    case "minus":
    case "service":
    case "filter":
    case "bind":
    case "values":
      return serializer.pattern(ast).trim();
    case "aggregate":
    case "iriFunction":
    case "function":
      return serializer.expression(ast).trim();
  }
}
