import { Skeleton } from "@mui/material";
import { isEmpty } from "lodash-es";
import * as React from "react";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import { Prefix } from "#components/Forms/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { updateGlobalPrefixes } from "#reducers/config.ts";
import type { GlobalState } from "#reducers/index.ts";

interface Props {}

const Prefixes: React.FC<Props> = ({}) => {
  const prefixes = useSelector((state: GlobalState) => state.config.clientConfig?.prefixes);
  const dispatch = useDispatch();
  const handlePrefixSubmit = (values: Prefix.FormData) => {
    const prefixes = values.prefixes.filter((item) => !isEmpty(item));
    return dispatch<typeof updateGlobalPrefixes>(updateGlobalPrefixes(prefixes)).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      },
    );
  };
  if (!prefixes) {
    return <Skeleton />;
  }
  return (
    <div className="whiteSink">
      <h3>Site-wide prefixes</h3>
      <Prefix
        form="globalPrefixes"
        enableReinitialize
        //append empty prefix to initialvalues (we always want an empty line via which a user can _add_ prefixes
        //don't want this in the component on-mount, as that would make the form dirty
        initialValues={{
          prefixes: [
            ...prefixes.map((prefix) => {
              const { scope, ...rest } = prefix;
              return rest;
            }),
            {} as any,
          ],
        }}
        onSubmit={handlePrefixSubmit}
      />
    </div>
  );
};
export default Prefixes;
