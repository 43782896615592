import * as d3 from "d3";
import type { Location } from "history";
import React from "react";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import { parseSearchString } from "#helpers/utils.ts";
import type { QuerySchema } from "./useFetchSchema.ts";
import { getLocalName, getPrefixAndLabel } from "./utils.ts";

type DatatypeProperty = { propertyShape?: string; path: string; datatype: string; shacl: number; owl: number };

export interface NodeData {
  id: string;
  label: string;
  prefix?: string;
  color: string;
  datatypeProperties?: DatatypeProperty[];
  blur: boolean;
  shacl: boolean;
  owl: boolean;
}

export interface EdgeData {
  from: string;
  to: string;
  iri?: string;
  label?: string;
  prefix?: string;
  color?: string;
  blur: boolean;
  shacl: boolean;
  owl: boolean;
}

// colorScheme is d3.schemeCategory10 minus the gray that we'll use for the hierarchy edges
// const hierarchyColor = "#7f7f7f";
const colorScheme = ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#bcbd22", "#17becf"];
export const color = d3.scaleOrdinal(colorScheme);

const useData = (querySchema: QuerySchema | undefined, location: Location) => {
  const prefixesRef = React.useRef(useDatasetPrefixes());
  const [data, setData] = React.useState<{ nodes: NodeData[]; edges: EdgeData[] }>();
  const focusNode = decodeURIComponent(location.hash.slice(1));
  const query = parseSearchString(location.search);
  const showOwl = query.c !== "shacl";
  const showShacl = query.c !== "owl";
  const showProperties = query.e !== "hierarchy";
  const showHierarchy = query.e !== "properties";
  const classFilterString = query.f as string | undefined;

  React.useEffect(() => {
    if (!querySchema) return;

    const classFilter = classFilterString ? classFilterString.split(",") : [];
    const getClassFilter = (variable: string) => {
      if (classFilter.length === 0) {
        return "";
      }
      return `filter(${classFilter
        .map((c) => {
          if (prefixesRef.current.find((p) => p.iri === c)) {
            return `strStarts(str(${variable}), "${c}")`;
          }
          return `${variable} = <${c}>`;
        })
        .join(" || ")})`;
    };

    const classes: { [className: string]: { blur: boolean; shacl: boolean } } = {};
    const addClass = (id: string, opts: { blur: boolean; shacl: boolean }) => {
      classes[id] = {
        shacl: classes[id]?.shacl || opts.shacl,
        blur: classes[id]?.blur === false ? false : opts.blur,
      };
    };

    const getNodesAndEdges = async () => {
      const objectPropertyEdges = showProperties
        ? (
            await querySchema(
              `
              prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
              prefix sh: <http://www.w3.org/ns/shacl#>
              prefix owl: <http://www.w3.org/2002/07/owl#>
              select ?from ?property ?to (count(?shacl) as ?shaclCount) (count(?owl) as ?owlCount) where {
                {
                  ?shape sh:targetClass ?from .
                  ?shape sh:property ?propertyShape .
                  ?propertyShape sh:path ?property .
                  ?propertyShape sh:class ?to .
                  bind(1 as ?shacl)
                } union {
                  ?property rdfs:domain|rdfs:range ?domain_or_range .
                  optional { ?property rdfs:domain ?optional_from }
                  optional { ?property rdfs:range ?optional_to }
                  bind(coalesce(?optional_from, rdfs:Resource) as ?from)
                  bind(coalesce(?optional_to, rdfs:Resource) as ?to)
                  filter not exists {
                    ?property a owl:DatatypeProperty .
                  }
                  bind(1 as ?owl)
                }
                ${getClassFilter("?from")}
              } group by ?from ?property ?to

              ${!showOwl ? "having(count(?shacl) > 0)" : ""}
              ${!showShacl ? "having(count(?owl) > 0)" : ""}
              `,
            )
          ).map((binding) => {
            addClass(binding.from!.value, {
              blur: !!focusNode && binding.from!.value !== focusNode,
              shacl: !!+binding.shaclCount!.value,
            });
            addClass(binding.to!.value, { blur: !!focusNode && binding.from!.value !== focusNode, shacl: false });
            const prefixAndLabel = getPrefixAndLabel(prefixesRef.current, binding.property!.value);
            return {
              from: binding.from!.value,
              to: binding.to!.value,
              iri: binding.property!.value,
              ...prefixAndLabel,
              color: color(prefixAndLabel.prefix ?? binding.property!.value.slice(0, -prefixAndLabel.label.length)),
              owl: !!+binding.owlCount!.value,
              shacl: !!+binding.shaclCount!.value,
              blur: !!focusNode && binding.from!.value !== focusNode,
            };
          })
        : [];

      const subClassEdges = showHierarchy
        ? (
            await querySchema(
              `
                prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
                prefix sh: <http://www.w3.org/ns/shacl#>
                select ?child ?parent where {
                    ?child rdfs:subClassOf ?parent .
                    optional {
                      ?shape sh:targetClass ?child
                    }
                    ${getClassFilter("?child")}
                } group by ?child ?parent
                ${!showOwl ? "having(count(?shape) > 0)" : ""}
                `,
            )
          ).map((binding) => {
            addClass(binding.parent!.value, { blur: !!focusNode && binding.child!.value !== focusNode, shacl: false });
            addClass(binding.child!.value, { blur: !!focusNode && binding.child!.value !== focusNode, shacl: false });
            return {
              from: binding.parent!.value,
              to: binding.child!.value,
              label: "",
              blur: !!focusNode && binding.child!.value !== focusNode,
              owl: false,
              shacl: false,
            };
          })
        : [];

      const edges = [...subClassEdges, ...objectPropertyEdges];

      if (showOwl) {
        (
          await querySchema(
            `
            prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
            prefix owl: <http://www.w3.org/2002/07/owl#>
            select distinct ?class where {
                {
                  ?class a rdfs:Class .
                } union {
                  ?class a owl:Class .
                }
                ${getClassFilter("?class")}
            }
            `,
          )
        ).forEach((binding) => {
          addClass(binding.class!.value, {
            blur: !!focusNode && binding.class!.value !== focusNode,
            shacl: false,
          });
        });
      }

      if (showShacl) {
        (
          await querySchema(
            `
            prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
            prefix sh: <http://www.w3.org/ns/shacl#>
            select ?class where {
                ?shape sh:targetClass ?class .
                ${getClassFilter("?class")}
            }
            `,
          )
        ).forEach((binding) => {
          addClass(binding.class!.value, {
            blur: !!focusNode && binding.class!.value !== focusNode,
            shacl: true,
          });
        });
      }

      for (const filterClass of classFilter) {
        if (
          (
            await querySchema(
              `
          select * where {
            {
              <${filterClass}> ?a ?b
            } union {
              ?c ?d <${filterClass}>
            }
          }
          limit 1
          `,
            )
          ).length > 0
        ) {
          addClass(filterClass, { blur: !!focusNode && filterClass !== focusNode, shacl: false });
        }
      }

      const nodes = await Promise.all(
        Object.entries(classes).map(async ([className, opts]) => {
          let shacl = opts.shacl;
          const datatypeProperties = showProperties
            ? (
                await querySchema(
                  `
                  prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
                  prefix owl: <http://www.w3.org/2002/07/owl#>
                  prefix sh: <http://www.w3.org/ns/shacl#>
                  select ?path ?datatype (count(?shacl) as ?shaclCount) (count(?owl) as ?owlCount) where {
                    {
                      ?shape sh:targetClass <${className}>;
                             sh:property ?propertyShape.
                      ?propertyShape
                        sh:datatype ?datatype;
                        sh:path ?path.
                      bind(1 as ?shacl)
                    } union {
                      ?path a owl:DatatypeProperty;
                        rdfs:domain <${className}> ;
                        rdfs:range ?datatype .
                      bind(1 as ?owl)
                    }
                  } group by ?path ?datatype

                  ${!showOwl ? "having(count(?shacl) > 0)" : ""}
                  ${!showShacl ? "having(count(?owl) > 0)" : ""}

                  order by ?path
                  `,
                )
              ).map((binding) => {
                if (!!+binding.shaclCount!.value) shacl = true;
                return {
                  propertyShape: binding.propertyShape?.value,
                  path: binding.path!.value,
                  datatype: getLocalName(binding.datatype!.value),
                  owl: +binding.owlCount!.value,
                  shacl: +binding.shaclCount!.value,
                };
              })
            : undefined;

          const hasShapes =
            shacl ||
            (
              await querySchema(
                `
                prefix sh: <http://www.w3.org/ns/shacl#>
                select ?shape where {
                  ?shape sh:targetClass <${className}>
                }
                `,
              )
            ).length > 0;

          const prefixAndLabel = getPrefixAndLabel(prefixesRef.current, className);
          return {
            id: className,
            ...prefixAndLabel,
            datatypeProperties: datatypeProperties,
            shacl: hasShapes,
            owl: true,
            color: color(prefixAndLabel.prefix ?? className.slice(0, -prefixAndLabel.label.length)),
            blur: !!focusNode && focusNode !== className && opts.blur,
          };
        }),
      );

      setData({
        nodes: nodes,
        edges: edges,
      });
    };

    console.time("Computing nodes and edges");

    getNodesAndEdges()
      .catch(console.error)
      .finally(() => console.timeEnd("Computing nodes and edges"));
  }, [querySchema, focusNode, showOwl, showShacl, showHierarchy, showProperties, classFilterString]);

  return data;
};

export default useData;
