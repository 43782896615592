import * as React from "react";
import { useSelector } from "react-redux";
import { asyncConnect } from "redux-connect";
import { ErrorPage, Meta } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import { getDatasetList, isDatasetsListLoadedForAdmin, searchDatasets } from "#reducers/datasetManagement.ts";
import type { GlobalState } from "#reducers/index.ts";
import DatasetTable from "./DatasetTable.tsx";

export interface Props extends IComponentProps {}

const AdminDatasets: React.FC<Props> = ({ location }) => {
  const datasets = useSelector((state: GlobalState) =>
    getDatasetList(state, { admin: "fullList", account: false, searchTerm: false }),
  );
  const acl = useAcl();
  const { fetchFromList, fetchFromListError } = useSelector((state: GlobalState) => {
    return {
      fetchFromListError: state.datasetManagement.fetchingListError,
      fetchFromList: state.datasetManagement.fetchingList,
    };
  });
  const Table = React.useMemo(
    () => <DatasetTable datasets={datasets} error={fetchFromListError} loading={!!fetchFromList} />,
    // We need to memoize the table ourselves as redux return a new Object every time the redux state updates.
    // So we only check for the length of the array if we need to update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [datasets.length],
  );
  if (!acl.check({ action: "manageDatasets" }).granted) return <ErrorPage statusCode={401} />;

  return (
    <>
      <Meta currentPath={location.pathname} title="Datasets - Admin settings" />
      {Table}
    </>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: async ({ store: { dispatch, getState } }) => {
      if (!isDatasetsListLoadedForAdmin(getState())) {
        return dispatch<any>(searchDatasets({ sortBy: "createdAt", sortDirection: "desc", limit: -1 }, "fullList"));
      }
    },
  },
])(AdminDatasets) as typeof AdminDatasets;
