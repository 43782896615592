import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { SubmissionError } from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import { Dialog, ListWidget, StoryBadge } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import type { GlobalState } from "#reducers/index.ts";
import type { createQuery } from "#reducers/queries.ts";
import { createStory } from "#reducers/stories.ts";
import * as styles from "./style.scss";

interface Props {}
interface LocationState {
  createStoryModalShown?: boolean;
  preserveScrollPosition?: boolean;
}
const SideStories: React.FC<Props> = ({}) => {
  const authenticatedUser = useAuthenticatedUser();

  const { push, goBack, replace } = useHistory<LocationState>();
  const { state: locationState } = useLocation<LocationState>();
  const dispatch = useDispatch();
  const acl = useAcl();
  const stories = useSelector((state: GlobalState) => (state.stories.list && Object.values(state.stories.list)) || []);
  if (!authenticatedUser) return null;

  const handleCreateStorySubmit = (data: Forms.Story.FormData) => {
    if (!authenticatedUser) return;
    return dispatch<typeof createQuery>(createStory(authenticatedUser.accountName, data))
      .then(({ body: story }) => replace(`/${story.owner.accountName}/-/stories/${story.name}`))
      .catch((e) => {
        if (e?.status == 409) {
          throw new SubmissionError({ name: "URL is already in use." });
        }
        throw new SubmissionError({ _error: e.message });
      });
  };
  const openDialog = () => {
    push({ state: { createStoryModalShown: true, preserveScrollPosition: true } });
  };
  const userAllowedToCreateStory = acl.check({
    action: "manageStory",
    context: {
      roleInOwnerAccount: acl.getRoleInAccount(authenticatedUser),
      accessLevel: "public",
    },
  }).granted;
  return (
    <>
      <ListWidget
        key="yourStories"
        title="Your stories"
        linkTo={`/${authenticatedUser.accountName}/-/stories`}
        noContentMsg="You haven't made any stories"
        onAddItem={userAllowedToCreateStory ? openDialog : undefined}
        addItemTitle="Create a story"
        showMoreLink={(!!stories && stories.length > 10 && `/${authenticatedUser.accountName}/-/stories`) || undefined}
      >
        {!!stories &&
          stories.slice(0, 10).map((story) => (
            <div key={story.id}>
              <Link
                to={`/${story.owner.accountName}/-/stories/${story.name}`}
                title={story.displayName || story.name}
                className={getClassName("flex center noLinkDecoration px-3 py-2", styles.listItem)}
              >
                <StoryBadge bannerUrl={story.bannerUrl} className="mr-3" />
                <div className={styles.itemTitle}>{story.displayName || story.name}</div>
              </Link>
            </div>
          ))}
      </ListWidget>
      {authenticatedUser && userAllowedToCreateStory && (
        <Dialog
          open={!!locationState?.createStoryModalShown}
          onClose={goBack}
          maxWidth="lg"
          fullWidth
          title="Create story"
        >
          <Forms.Story
            className={getClassName("p-5")}
            initialValues={{
              accessLevel: "private",
            }}
            currentAccount={authenticatedUser}
            onSubmit={handleCreateStorySubmit}
            cancelFunction={goBack}
          />
        </Dialog>
      )}
    </>
  );
};

export default SideStories;
