import getClassName from "classnames";
import * as React from "react";
import { Constants } from "@triply/utils";
import { Button, FontAwesomeIcon, LoadingButton, ResourceWidget } from "#components/index.ts";
import type Tree from "#helpers/LdTree.ts";
import {
  getWidgetCollection,
  selectAudio,
  selectGeometry,
  selectImage,
  selectLabel,
  selectVideo,
} from "#reducers/resourceDescriptions.ts";
import * as styles from "./styles/inLink.scss";

namespace InLink {
  export interface Props {
    label?: string;
    trees: Tree[];
    loadResource?: (subject: string, label: string) => {};
    linkPath: string;
    // We want a promise here as to not complicate the component with redux logic, Optional as for special widgets
    loadPage?: (direction: "forward" | "backward", page: number, predicate: string) => Promise<void>;
  }
  export interface State {
    loadedPages: number;
    loading: boolean;
  }
}

class InLink extends React.PureComponent<InLink.Props, InLink.State> {
  constructor(props: InLink.Props) {
    super(props);
    this.state = {
      loadedPages: 1,
      loading: false,
    };
  }

  loadPage = () => {
    const { trees, loadPage } = this.props;
    const { loadedPages } = this.state;
    //Only load more statements when needed
    if (loadPage && trees.length <= (loadedPages + 1) * Constants.BACKWARD_SUBJECTS_PAGE_SIZE) {
      // Set loading animation on the button
      this.setState({
        loading: true,
      });
      const pred = trees[0].getPredicate();
      if (pred) {
        loadPage("backward", loadedPages, pred)
          .then(() => {
            this.setState({ loadedPages: loadedPages + 1, loading: false });
          })
          .catch(() => {
            // Error is handled by notification reducer
            this.setState({ loading: false });
          });
      }
    } else {
      this.setState({ loadedPages: loadedPages + 1 });
    }
  };

  renderResource = (subject: string) =>
    this.props.label && this.props.loadResource
      ? this.props.loadResource(subject, this.props.label)
      : this.props.loadResource;
  onDragStart = (e: any) => {
    e.dataTransfer.setData("text/plain", this.props.trees[0].getPredicate());
  };
  render() {
    const { trees, label, linkPath } = this.props;

    if (!trees) return null;
    const numToRender = this.state.loadedPages * Constants.BACKWARD_SUBJECTS_PAGE_SIZE;
    const thereIsNextPage = this.props.loadPage && trees.length > numToRender;

    const collections = (thereIsNextPage ? trees.slice(0, numToRender) : trees).map((tree) => ({
      tree,
      widgetCollection: getWidgetCollection(tree, [selectLabel, selectImage, selectGeometry, selectVideo, selectAudio]),
    }));

    const imageCollections = collections.filter(
      (c) =>
        c.widgetCollection.image || c.widgetCollection.geometry || c.widgetCollection.audio || c.widgetCollection.video,
    );
    const remainingCollections = collections.filter(
      (c) =>
        !c.widgetCollection.image &&
        !c.widgetCollection.geometry &&
        !c.widgetCollection.audio &&
        !c.widgetCollection.video,
    );
    const predicate = this.props.trees[0].getPredicate();

    return (
      <div>
        <div className={styles.container}>
          <div className={styles.values}>
            {imageCollections.length > 0 && (
              <div className={getClassName("flex", styles.imageWidgets)}>
                {imageCollections.map((c) => {
                  return (
                    <ResourceWidget
                      key={c.tree.getKey()}
                      resource={c.tree.getTerm()}
                      widgetCollection={c.widgetCollection}
                      loadResource={this.renderResource}
                      linkPath={linkPath}
                    />
                  );
                })}
              </div>
            )}
            {remainingCollections.length > 0 && (
              <div className={styles.remainingWidgets}>
                {remainingCollections.map((c) => {
                  return (
                    <ResourceWidget
                      key={c.tree.getKey()}
                      resource={c.tree.getTerm()}
                      widgetCollection={c.widgetCollection}
                      loadResource={this.renderResource}
                      linkPath={linkPath}
                      className={styles.remainingWidget}
                    />
                  );
                })}
              </div>
            )}
          </div>

          {label && (
            <div className={styles.label} title={predicate} draggable onDragStart={this.onDragStart}>
              <h5 className={getClassName("paragraphHeader", styles.inLabel)}>{label}</h5>
            </div>
          )}
        </div>
        {thereIsNextPage && (
          <div className={styles.buttoncontainer}>
            <LoadingButton pulse loading={this.state.loading} onClick={this.loadPage}>
              Show more
            </LoadingButton>
          </div>
        )}
      </div>
    );
  }
}

export default InLink;
