import { formatNumber } from "@core/utils/formatting";
import { Alert, LinearProgress, Typography } from "@mui/material";
import getClassName from "classnames";
import React from "react";
import type { FileRejection } from "react-dropzone";
import Dropzone from "react-dropzone";
import type { ErrorResponse, IndexJobType } from "@triply/utils/Models";
import { Button, FontAwesomeIcon, ParseErrorContext } from "#components/index.ts";
import { JobUpload } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import type { Job } from "#reducers/datasets.ts";
import { deleteAllJobsInErrorState, deleteJob } from "#reducers/datasets.ts";
import { getSupportedExtensions } from "#reducers/uploading.ts";
import * as styles from "./style.scss";

namespace DataPipeline {
  export interface Props {
    // Job can be undefined so that we can partially load the component without waiting for the
    // server to create the job. This leads to a smoother UI transition for the user, as we don't
    // need skeletons or spinners.
    job: Job | undefined;
    onFilesSelect: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
    expectedJobType?: IndexJobType;
  }
}

const JobError: React.FC<{ error: ErrorResponse | string }> = ({ error }) => {
  if (typeof error === "string") return <Alert severity="error">{error}</Alert>;
  if (typeof error === "object") {
    return (
      <Alert severity="error">
        {error.files && error.files.length ? <span>{`Error in file ${error.files.join(" > ")}:`}</span> : null}
        <div>{error.message}</div>
        {error.serverError && error.message !== error.serverError && (
          <div>Admin error message: {error.serverError}</div>
        )}
        <ParseErrorContext error={error} />
      </Alert>
    );
  }
  return null;
};

interface ProgressBar {
  activeShimmer: boolean; // True if the shimmer animation should play.
  progress: number; // A number between 0 and 100 representing current progress to display.
  disable?: boolean; // If true, the progress text will be greyed out. This matches headings.
}

interface UploadProps {
  job?: Job;
  onFilesSelect: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
}

interface DownloadProps {
  job?: Job;
}

const ProgressBar: React.FC<ProgressBar> = ({ activeShimmer, progress, disable }) => {
  // The progress bar is composed of a LinearProgress MUI component together with a textual
  // represention of the progress, for long-lasting indexing jobs.
  return (
    <div className={styles.progressBarAndText}>
      <div className={styles.progressBar}>
        <LinearProgress value={progress} variant="determinate" />
        {activeShimmer && <div className={styles.shimmer} />}
      </div>
      <div className={styles.text}>
        <Typography variant="body2" color="textSecondary" className={getClassName({ [styles.greyText]: disable })}>
          {`${formatNumber(progress, "0")}%`}
        </Typography>
      </div>
    </div>
  );
};

const Upload: React.FC<UploadProps> = ({ job, onFilesSelect }) => {
  const acl = useAcl();
  return (
    <>
      <h3>Add data from files</h3>
      <hr />

      {/* Drop-zone area for users to drag-and-drop files they want uploaded. */}
      {(!job || job.status === "created") && (
        <Dropzone
          // See https://issues.triply.cc/issues/7219
          useFsAccessApi={false}
          disabled={!job}
          onDrop={onFilesSelect}
          accept={{
            // Dropzone requires a mapping between mime-type and extension.
            // We use a fake mimetype: For our usecase, the extension seems to suffice: we can only select files with these extensions
            "application/x-triply": getSupportedExtensions(acl.check({ action: "uploadHdt" }).granted),
          }}
          multiple
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps({
                className: getClassName(styles.fileDropZone, "p-3", {
                  [styles.dragActive]: isDragActive,
                }),
              })}
            >
              <input {...getInputProps()} />
              <div>
                <FontAwesomeIcon icon={["fas", "cloud-upload"]} className={styles.icon} />
              </div>
              <span className="mt-3">Add more files (Turtle, TriG, N-Triples, N-Quads, JSON-LD, CSV or TSV)</span>
            </div>
          )}
        </Dropzone>
      )}

      {/* The indexing progress bar. Individual file upload progress bars are shown in the JobUpload
          component below. */}
      {job && job.status !== "created" && (
        <ProgressBar activeShimmer={job.status === "indexing"} progress={job.indexingProgress} />
      )}

      {job && job.error && <JobError error={job.error} />}

      {/* The JobUpload container (@Cleanup: It should be a component!!!) shows the list of uploaded
          files with individual progress bars. The user can also remove files, before the indexing
          stage, from this list. */}
      <JobUpload job={job} />
    </>
  );
};

const Download: React.FC<DownloadProps> = ({ job }) => {
  const dispatch = useDispatch();
  const currentDs = useCurrentDataset();
  const downloadingHasNotStarted = !job || job.status === "created";
  const indexingHasNotStarted = !job || job.status === "created" || job.status === "downloading";
  return (
    <>
      <h3>Adding data</h3>
      <hr />
      {/* Download progress bar. */}
      <div
        className={getClassName(styles.downloadProgressBarHeading, {
          [styles.downloadProgressBarHeadingGrey]: downloadingHasNotStarted,
        })}
      >
        {job?.status === "indexing" || job?.status === "finished" ? <>Downloaded</> : <>Downloading</>}
      </div>
      <ProgressBar
        activeShimmer={job?.status === "downloading"}
        progress={job?.downloadingProgress || 0}
        disable={downloadingHasNotStarted}
      />
      {/* Indexing progress bar. */}
      <div
        className={getClassName(styles.downloadProgressBarHeading, {
          [styles.downloadProgressBarHeadingGrey]: indexingHasNotStarted,
        })}
      >
        {job && job.status === "finished" ? <>Indexed</> : <>Indexing</>}
      </div>
      <ProgressBar
        activeShimmer={job?.status === "indexing"}
        progress={job?.indexingProgress || 0}
        disable={indexingHasNotStarted}
      />
      {job && job.error && <JobError error={job.error} />}
      {/* URL list heading and cancel button. */}
      <div className={styles.btncontainer}>
        <h4 className={styles.heading}>Source URL{(job?.downloadUrls?.length || 0) > 1 && <>s</>}</h4>
        <hr className={styles.line} />
        <Button
          variant="text"
          disabled={!job}
          onClick={() => {
            if (job) dispatch<typeof deleteJob>(deleteJob(job)).catch(() => {});
            if (currentDs) {
              dispatch<typeof deleteAllJobsInErrorState>(
                deleteAllJobsInErrorState({
                  accountName: currentDs.owner.accountName,
                  datasetId: currentDs.id,
                  datasetName: currentDs.name,
                }),
              ).catch(() => {});
            }
          }}
        >
          Cancel
        </Button>
      </div>
      {/* URL list */}
      {job?.downloadUrls && (
        // This div is used for padding. It's not unnecessary.
        <div>
          {job.downloadUrls.map((url) => {
            return (
              <div key={url}>
                <a href={url}>{url}</a>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const DataPipeline: React.FC<DataPipeline.Props> = ({ job, onFilesSelect, expectedJobType }) => {
  if (job?.type === "upload" || expectedJobType === "upload") {
    return <Upload job={job} onFilesSelect={onFilesSelect} />;
  } else if (job?.type === "download" || expectedJobType === "download") {
    return <Download job={job} />;
  }
  return null;
};

export default DataPipeline;
