import type { CellContext } from "@tanstack/react-table";
import * as React from "react";
import HumanizedDate from "#components/HumanizedDate/index.tsx";

export const TableDateRenderer: React.FC<CellContext<any, Date | string | number>> = ({ cell }) => {
  const value = cell.getValue();
  if (!value) {
    return null;
  }
  return <HumanizedDate date={value} showAbsoluteTime />;
};

export default TableDateRenderer;

export const TableShortDateRenderer: React.FC<CellContext<any, Date | string | number>> = ({ cell }) => {
  const value = cell.getValue();
  if (!value) {
    return null;
  }
  return <HumanizedDate date={value} showAbsoluteTime showDateOnly />;
};

export const TableHumanizedDateRenderer: React.FC<CellContext<any, Date | string | number>> = ({ cell }) => {
  const value = cell.getValue();
  if (!value) {
    return null;
  }
  return <HumanizedDate date={value} />;
};
