import { syntaxTree } from "@codemirror/language";
import type { Diagnostic } from "@codemirror/lint";
import type { EditorView } from "@codemirror/view";
import Debug from "debug";
import { parse } from "@triplydb/data-factory";

const debug = Debug("triply:sparql-editor:linting:syntax");

function getDiagnostic(node: { from: number; to: number }, message: string): readonly Diagnostic[] {
  console.warn(message);
  return [
    {
      from: node.from,
      to: node.to,
      severity: "error",
      // We don't want long unreadable error messages.
      message: "",
    },
  ];
}

function syntaxLinter(view: EditorView): readonly Diagnostic[] {
  debug("start");
  const treeState = syntaxTree(view.state);
  const cursor = treeState.cursor();
  const diagnostics: Diagnostic[] = [];
  let isParsingError = false;

  do {
    if (cursor.node.type.isError || cursor.type.isSkipped) {
      continue;
    } else {
      // Skip prologue since we've already
      if (cursor.node.name === "Prologue") {
        cursor.nextSibling();
      }
      if (cursor.node.name === "Iriref") {
        const iri = view.state.doc.sliceString(cursor.node.from, cursor.node.to);
        try {
          parse(iri);
        } catch (e: any) {
          isParsingError = true;
          diagnostics.push({
            from: cursor.node.from,
            to: cursor.node.to,
            severity: "error",
            message: e.message,
          });
        }
      }
    }
  } while (cursor.next());

  if (!isParsingError) {
    const cursor = treeState.cursor();
    while (cursor.next()) {
      let node = cursor.node;
      if (node.name === "Prologue") {
        cursor.nextSibling();
      }
      if (node.type.isError) {
        // We used to re-parse the query using sparqljs, as that (arguably) gave good error messages.
        // Given that sparql-ast doesn't come with nice-enough error messages, we've removed this re-parse step.
        // This is something we may want to change later
        diagnostics.push({
          from: node.from,
          to: node.to,
          severity: "error",
          message: "",
        });
      }
    }
  }

  debug("end");
  return diagnostics;
}

export default syntaxLinter;
