import type * as RDF from "@rdfjs/types";
import { AmbiguousParseError, StandardParseError, ValueRangeError } from "./Errors.ts";
import xsd from "./parseAndSerialize.ts";
import wkt from "./wkt.ts";

export type Iri<V extends string = string> = RDF.NamedNode<V>;
export interface ParserAndSerializer<V> {
  parse: (lexicalValue: string) => V;
  serialize: (value: V) => string;
}
const parsers = { ...xsd, ...wkt };

type Parsers = typeof parsers;
export type RecognizedDatatypes = keyof Parsers;

/**
 * Returns the value for the given lexical form according to the grammar denotes by the given
 * datatype.
 */
export function lexicalToValue<D extends RecognizedDatatypes>(lexicalValue: string, datatype: D | Iri<D>) {
  const stringDatatype = typeof datatype === "string" ? datatype : datatype.value;
  if (stringDatatype in parsers) {
    try {
      return parsers[stringDatatype].parse(lexicalValue) as ReturnType<Parsers[D]["parse"]>;
    } catch (e) {
      if (e instanceof StandardParseError || e instanceof ValueRangeError || e instanceof AmbiguousParseError) {
        e.datatype = stringDatatype;
      }
      throw e;
    }
  } else {
    // Typescript should guard against this, but want a proper error anyway just to be sure
    throw new Error(`No lexical mapping found for datatype '${stringDatatype}'.`);
  }
}

/**
 * Returns the canonical lexical form for the given datatype and value.
 */
export function valueToCanonical<D extends RecognizedDatatypes>(
  value: ReturnType<Parsers[D]["parse"]>,
  datatype: D | Iri<D>,
): string {
  const stringDatatype = typeof datatype === "string" ? datatype : datatype.value;
  if (stringDatatype in parsers) {
    // Casting to any, because of typescript annoyance
    return (parsers[stringDatatype].serialize as any)(value);
  } else {
    throw new Error(`No lexical mapping found for datatype '${stringDatatype}'.`);
  }
}
/**
 * Maps a lexical form to a canonical lexical form that denotes the same value.
 */
export function lexicalToCanonical<D extends RecognizedDatatypes>(lexicalValue: string, datatype: D | Iri<D>) {
  return valueToCanonical(lexicalToValue(lexicalValue, datatype), datatype);
}

export function isRecognized(datatype: string): datatype is RecognizedDatatypes;
export function isRecognized(datatype: Iri): datatype is Iri<RecognizedDatatypes>;
export function isRecognized(datatype: string | Iri): boolean {
  const stringDatatype = typeof datatype === "string" ? datatype : datatype.value;
  return stringDatatype in parsers;
}
