import { produce } from "immer";
import type { Models, Routes } from "@triply/utils";
import { DUPLICATE_ASSETNAME_ERROR_MESSAGE } from "@triply/utils/Constants";
import type { GenericReduxApiResponse } from "#helpers/ApiClient.ts";
import type { TusUploadConfig } from "#helpers/tusUploadManagement.ts";
import { cancelAllUploadsOfKindInDs, cancelTusUpload, registerTusUploads } from "#helpers/tusUploadManagement.ts";
import type { Account } from "#reducers/accountCollection.ts";
import type { Dataset } from "#reducers/datasetManagement.ts";
import type { Action, BeforeDispatch, GlobalAction, GlobalState, Thunk } from "#reducers/index.ts";
import { Actions } from "#reducers/index.ts";

export const LocalActions = {
  GET_ASSETS: "triply/assets/GET_ASSETS",
  GET_ASSETS_SUCCESS: "triply/assets/GET_ASSETS_SUCCESS",
  GET_ASSETS_FAIL: "triply/assets/GET_ASSETS_FAIL",

  CANCEL_ASSET_UPLOAD: "triply/assets/CANCEL_ASSET_UPLOAD",
  CANCEL_ALL_ASSET_UPLOADS: "triply/assets/CANCEL_ALL_ASSET_UPLOADS",

  POST_ASSET: "triply/assets/POST_ASSET",
  POST_ASSET_SUCCESS: "triply/assets/POST_ASSET_SUCCESS",
  POST_ASSET_FAIL: "triply/assets/POST_ASSET_FAIL",

  REMOVE_ASSET_VERSION: "triply/assets/REMOVE_ASSET_VERSION",
  REMOVE_ASSET_VERSION_SUCCESS: "triply/assets/REMOVE_ASSET_VERSION_SUCCESS",
  REMOVE_ASSET_VERSION_FAIL: "triply/assets/REMOVE_ASSET_VERSION_FAIL",

  ASSET_UPLOAD_PROGRESS: "triply/assets/ASSET_UPLOAD_PROGRESS",

  ADD_ASSET_VERSION_SUCCESS: "triply/assets/ADD_ASSET_VERSION_SUCCESS",

  REMOVE_ASSET: "triply/assets/REMOVE_ASSET",
  REMOVE_ASSET_SUCCESS: "triply/assets/REMOVE_ASSET_SUCCESS",
  REMOVE_ASSET_FAIL: "triply/assets/REMOVE_ASSET_FAIL",

  REMOVE_ALL_ASSETS: "triply/assets/REMOVE_ALL_ASSETS",
  REMOVE_ALL_ASSETS_SUCCESS: "triply/assets/REMOVE_ALL_ASSETS_SUCCESS",
  REMOVE_ALL_ASSETS_FAIL: "triply/assets/REMOVE_ALL_ASSETS_FAIL",
} as const;

type GET_ASSETS = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_ASSETS,
      typeof LocalActions.GET_ASSETS_SUCCESS,
      typeof LocalActions.GET_ASSETS_FAIL,
    ];
    forDataset: Dataset;
    append: boolean;
  },
  GenericReduxApiResponse<{ Body: Models.Assets }> // Path gives back both a single and multiple assets but we only expect the one here
>;

type REMOVE_ASSET_VERSION = GlobalAction<
  {
    types: [
      typeof LocalActions.REMOVE_ASSET_VERSION,
      typeof LocalActions.REMOVE_ASSET_VERSION_SUCCESS,
      typeof LocalActions.REMOVE_ASSET_VERSION_FAIL,
    ];
    forDataset: Dataset;
    versionId: string;
    identifier: string;
  },
  Routes.datasets._account._dataset.assets._assetId._versionId.Delete
>;

type REMOVE_ASSET = GlobalAction<
  {
    types: [
      typeof LocalActions.REMOVE_ASSET,
      typeof LocalActions.REMOVE_ASSET_SUCCESS,
      typeof LocalActions.REMOVE_ASSET_FAIL,
    ];
    forDataset: Dataset;
    identifier: string;
  },
  Routes.datasets._account._dataset.assets._assetId.Delete
>;
type REMOVE_ALL_ASSETS = GlobalAction<
  {
    types: [
      typeof LocalActions.REMOVE_ALL_ASSETS,
      typeof LocalActions.REMOVE_ALL_ASSETS_SUCCESS,
      typeof LocalActions.REMOVE_ALL_ASSETS_FAIL,
    ];
    forDatasetId: string;
  },
  Routes.datasets._account._dataset.assets.Delete
>;

type CANCEL_ASSET_UPLOAD = GlobalAction<{
  type: typeof LocalActions.CANCEL_ASSET_UPLOAD;
  forDataset: Dataset;
  requestId: string;
}>;

type CANCEL_ALL_ASSET_UPLOADS = GlobalAction<{
  type: typeof LocalActions.CANCEL_ALL_ASSET_UPLOADS;
  forDataset: Dataset;
}>;

type ASSET_UPLOAD_PROGRESS = GlobalAction<{
  type: typeof LocalActions.ASSET_UPLOAD_PROGRESS;
  forDataset: Dataset;
  requestId: string;
  uploadProgress: number;
}>;

type POST_ASSET = GlobalAction<{
  type: typeof LocalActions.POST_ASSET;
  forDataset: Dataset;
  fileName: string;
  fileSize: number;
  requestId: string;
}>;

type POST_ASSET_SUCCESS = GlobalAction<{
  type: typeof LocalActions.POST_ASSET_SUCCESS;
  forDataset: Dataset;
  result: Asset;
  requestId: string;
}>;

type ADD_ASSET_VERSION_SUCCESS = GlobalAction<{
  type: typeof LocalActions.ADD_ASSET_VERSION_SUCCESS;
  forDataset: Dataset;
  result: Asset;
  requestId: string;
}>;

type POST_ASSET_FAIL = GlobalAction<{
  type: typeof LocalActions.POST_ASSET_FAIL;
  forDataset: Dataset;
  message: string;
  requestId: string;
  failTime: number;
  file: File;
}>;

export type LocalAction =
  | GET_ASSETS
  | REMOVE_ASSET_VERSION
  | REMOVE_ASSET
  | REMOVE_ALL_ASSETS
  | CANCEL_ASSET_UPLOAD
  | CANCEL_ALL_ASSET_UPLOADS
  | ASSET_UPLOAD_PROGRESS
  | POST_ASSET
  | POST_ASSET_SUCCESS
  | POST_ASSET_FAIL
  | ADD_ASSET_VERSION_SUCCESS;

export type Asset = Models.Asset;

export interface State {
  [datasetId: string]: {
    list: Asset[];
    nextPage?: string;
  };
}

export const reducer = produce((draftState: State, action: Action) => {
  switch (action.type) {
    case Actions.POST_ASSET_SUCCESS:
      if (!draftState[action.forDataset.id]) draftState[action.forDataset.id] = { list: [], nextPage: undefined };

      draftState[action.forDataset.id].list.push(action.result);
      draftState[action.forDataset.id].list.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
      return;

    case Actions.REMOVE_ASSET_VERSION_SUCCESS:
    case Actions.ADD_ASSET_VERSION_SUCCESS:
      if (!draftState[action.forDataset.id]) draftState[action.forDataset.id] = { list: [], nextPage: undefined };

      const assetIndex = draftState[action.forDataset.id].list.findIndex(
        (a) => a.identifier === action.result.identifier,
      );
      if (assetIndex >= 0) {
        draftState[action.forDataset.id].list[assetIndex] = action.result;
      } else {
        draftState[action.forDataset.id].list.push(action.result);
      }
      return;

    case Actions.GET_ASSETS_SUCCESS:
      if (!draftState[action.forDataset.id]) draftState[action.forDataset.id] = { list: [], nextPage: undefined };

      draftState[action.forDataset.id].list = [
        ...(action.append ? draftState[action.forDataset.id].list : []),
        ...action.result,
      ];
      draftState[action.forDataset.id].nextPage =
        action.meta && action.meta.links && action.meta.links["next"] ? action.meta.links["next"].url : undefined;
      return;

    case Actions.REMOVE_ASSET_SUCCESS:
      if (!draftState[action.forDataset.id]) draftState[action.forDataset.id] = { list: [], nextPage: undefined };

      draftState[action.forDataset.id].list = draftState[action.forDataset.id].list.filter(
        (a) => a.identifier !== action.identifier,
      );
      return;
    case Actions.REMOVE_ALL_ASSETS_SUCCESS:
      draftState[action.forDatasetId] = { list: [], nextPage: undefined };
      return;
  }
}, {} as State);

export function getAssets(forAccount: Account, forDataset: Dataset, nextLink?: string): BeforeDispatch<GET_ASSETS> {
  return {
    types: [Actions.GET_ASSETS, Actions.GET_ASSETS_SUCCESS, Actions.GET_ASSETS_FAIL],
    promise: (client) => {
      if (nextLink)
        return client.req({
          url: nextLink,
          method: "get",
        });
      return client.req({
        pathname: `/datasets/${forAccount.accountName}/${forDataset.name}/assets`,
        method: "get",
      });
    },
    forDataset,
    append: !!nextLink,
  };
}

export function isAssetListLoaded(globalState: GlobalState, forDataset: Dataset) {
  return globalState.assets && forDataset && globalState.assets[forDataset.id];
}

function onUploadError(
  dispatch: (action: any) => any,
  requestId: string,
  forDataset: Dataset,
  message: string,
  file: File,
) {
  dispatch(postAssetError(forDataset, requestId, message, file));
}

export function addAssets(forDataset: Dataset, files: File[], versionOf?: string): Thunk<unknown> {
  return (dispatch, getState) => {
    function onError(uploadId: string, message: string, file: File) {
      onUploadError(dispatch, uploadId, forDataset, message, file);
    }

    function onProgress(percentage: number, uploadId: string) {
      dispatch(updateAssetUploadProgress(forDataset, percentage, uploadId));
    }

    function onSuccess(jsonBody: Models.Asset, uploadId: string) {
      dispatch(uploadComplete(forDataset, jsonBody, uploadId, !!versionOf));
    }

    function onUploadObjectCreated(uploadId: string, file: File) {
      dispatch(enqueueUpload(forDataset, file.name, file.size, uploadId));
    }

    function validate(file: File) {
      if (!versionOf && alreadyUsedFileNames.indexOf(file.name) > -1) {
        return DUPLICATE_ASSETNAME_ERROR_MESSAGE;
      }
    }

    const datasetAssets = getState().assets[forDataset.id];
    const alreadyUsedFileNames = datasetAssets ? datasetAssets.list.map((a) => a.assetName) : [];
    const endpoint = `/_api/datasets/${forDataset.owner.accountName}/${forDataset.name}/assets/add`;

    registerTusUploads(
      files.map(
        (file) =>
          ({
            file: file,
            type: "asset",
            accountId: forDataset.owner.uid,
            datasetId: forDataset.id,
            endpoint: endpoint,
            onSuccess: onSuccess,
            onProgress: onProgress,
            onError: onError,
            onUploadObjectCreated: onUploadObjectCreated,
            versionOf: versionOf,
            validate: validate,
          }) as TusUploadConfig<Models.IndexJob | Models.Asset>,
      ),
    );
  };
}

export function cancelUpload(forDataset: Models.Dataset, requestId: string): BeforeDispatch<CANCEL_ASSET_UPLOAD> {
  cancelTusUpload(requestId);
  return {
    type: Actions.CANCEL_ASSET_UPLOAD,
    forDataset,
    requestId,
  };
}

export function cancelAllUploads(forDataset: Dataset) {
  cancelAllUploadsOfKindInDs("asset", forDataset.id);
  return {
    type: Actions.CANCEL_ALL_ASSET_UPLOADS,
    forDataset,
  };
}

export function removeAsset(
  forAccount: Account,
  forDataset: Dataset,
  identifier: string,
): BeforeDispatch<REMOVE_ASSET> {
  return {
    types: [Actions.REMOVE_ASSET, Actions.REMOVE_ASSET_SUCCESS, Actions.REMOVE_ASSET_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forAccount.accountName}/${forDataset.name}/assets/${identifier}`,
        method: "delete",
      }),
    forDataset,
    identifier,
  };
}
export function removeAllAssets(
  accountName: string,
  datasetName: string,
  datasetId: string,
): BeforeDispatch<REMOVE_ALL_ASSETS> {
  return {
    types: [Actions.REMOVE_ALL_ASSETS, Actions.REMOVE_ALL_ASSETS_SUCCESS, Actions.REMOVE_ALL_ASSETS_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${accountName}/${datasetName}/assets/`,
        method: "delete",
      }),
    forDatasetId: datasetId,
  };
}

export function removeVersion(
  forAccount: Account,
  forDataset: Dataset,
  identifier: string,
  versionId: string,
): BeforeDispatch<REMOVE_ASSET_VERSION> {
  return {
    types: [Actions.REMOVE_ASSET_VERSION, Actions.REMOVE_ASSET_VERSION_SUCCESS, Actions.REMOVE_ASSET_VERSION_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forAccount.accountName}/${forDataset.name}/assets/${identifier}/${versionId}`,
        method: "delete",
      }),
    forDataset,
    versionId,
    identifier,
  };
}

export function updateAssetUploadProgress(
  forDataset: Dataset,
  uploadProgress: number,
  requestId: string,
): BeforeDispatch<ASSET_UPLOAD_PROGRESS> {
  return {
    type: Actions.ASSET_UPLOAD_PROGRESS,
    forDataset,
    requestId,
    uploadProgress,
  };
}

export function postAssetError(
  forDataset: Dataset,
  requestId: string,
  message: string,
  file: File,
): BeforeDispatch<POST_ASSET_FAIL> {
  return {
    type: Actions.POST_ASSET_FAIL,
    forDataset,
    message,
    requestId,
    failTime: Date.now(),
    file: file,
  };
}

export function enqueueUpload(
  forDataset: Models.Dataset,
  fileName: string,
  fileSize: number,
  requestId: string,
): BeforeDispatch<POST_ASSET> {
  return {
    type: Actions.POST_ASSET,
    forDataset,
    fileName,
    fileSize,
    requestId,
  };
}

export function uploadComplete(
  forDataset: Dataset,
  asset: Models.Asset,
  requestId: string,
  version: boolean,
): BeforeDispatch<POST_ASSET_SUCCESS> | BeforeDispatch<ADD_ASSET_VERSION_SUCCESS> {
  return {
    type: version ? Actions.ADD_ASSET_VERSION_SUCCESS : Actions.POST_ASSET_SUCCESS,
    forDataset,
    result: asset,
    requestId,
  };
}
