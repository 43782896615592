import { produce } from "immer";
import { pick } from "lodash-es";
import React, { createContext, useContext } from "react";
import { useParams } from "react-router";
import { IDE_SPARQL_ACCEPT_HEADER } from "@triply/utils/Constants";
import type { SparqlQueryServiceType } from "@triply/utils/Models";
import { parsers } from "@triplydb/sparql-ast";
import type { VisualizationConfig, VisualizationLabel } from "#components/Sparql/QueryResults/index.tsx";
import type {
  CacheHeader,
  PositionInformation,
  QueryResponse,
  QueryResultJson,
  QueryType,
  RawQueryResponse,
  UpdateResponse,
} from "#components/Sparql/SparqlUtils.ts";
import {
  getIdeVisualization,
  isIdeVisualization,
  postProcessSparqlJsonResponse,
  postProcessTriplesResponse,
  yasguiVisualizationConfigToIdeVisualizationConfig,
} from "#components/Sparql/SparqlUtils.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import type { StaticConfig } from "#reducers/config.ts";
import { refreshDatasetsInfo } from "#reducers/datasetManagement.ts";
import { getGraphs } from "#reducers/graphs.ts";

/* This context and hook is used to support the SPARQL IDE in managing its cache  */

export const DEFAULT_QUERY = "select * where {\n  ?s ?p ?o.\n} limit 10" as const;

const MAX_QUERY_RESPONSE_SIZE = 5 * 1024 * 1024;

type QueryResponseType = "Error" | "Bindings" | "Triples";

type UpdateResponseType = "Update";
type ResponseType = QueryResponseType | UpdateResponseType;

export function getNewExpiryDate() {
  return Date.now() + 3 * 30 * 24 * 60 * 60 * 1000;
}

const DEFAULT_TAB_CONFIG: PersistedTabQuery = {
  name: "Query",
  query: DEFAULT_QUERY,
  visualization: "Table" as const,
  visualizationConfig: {},
};

interface PersistedTabQuery {
  name: string;
  query: string;
  endpointType?: SparqlQueryServiceType;
  endpointName?: string;
  visualization: VisualizationLabel;
  visualizationConfig: VisualizationConfig;
}

interface TabQueryUpdate {
  name?: string;
  query?: string;
  endpointType?: SparqlQueryServiceType;
  endpointName?: string;
  visualization?: VisualizationLabel;
  visualizationConfig?: VisualizationConfig;
}

export interface PersistedIdeDatasetConfig {
  selectedTab: string;
  tabOrder: string[];
  queries: { [id: string]: TabQuery };
  exp: number;
}
export interface PersistedGlobalIdeConfig {
  [key: string]: PersistedIdeDatasetConfig;
}

interface BaseSparqlQueryTab extends PersistedTabQuery {
  loading?: boolean;
  requestDuration?: number;
  requestDelay?: number;
  cache?: CacheHeader;
  postProcessingDuration?: number;
  error?: Error | undefined;
  responseType?: unknown;
  responseSize?: number;
  zeroResultOperationLocations?: PositionInformation[];
  skipResultSizeCheck?: boolean;
  queryRawData?: unknown;
  queryParsedData?: unknown;
  queryType?: unknown;
  updateResult?: unknown;
}

export interface SparqlQueryTab extends BaseSparqlQueryTab {
  queryType?: "query";
  queryRawData?: RawQueryResponse | undefined;
  queryParsedData?: QueryResponse;
  responseType?: QueryResponseType;
  updateResult?: never;
}
export interface UpdateQueryTab extends BaseSparqlQueryTab {
  queryType?: "update";
  updateResult?: UpdateResponse;
  responseType?: UpdateResponseType;
  queryRawData?: never;
  queryParsedData?: never;
}

export type TabQuery = SparqlQueryTab | UpdateQueryTab;
/**
 * Base actions
 */
interface BaseAction {
  action: unknown;
  dataset: string;
}
interface QueryAction extends BaseAction {
  tabId: string;
}

/**
 * Reducer actions
 */
interface UpdateTabOrderAction extends BaseAction {
  action: "updateTabOrder";
  newOrder: string[];
}
interface SelectTabAction extends BaseAction {
  action: "selectTab";
  selectedTab: string;
}
interface AddTabAction extends BaseAction {
  action: "addTab";
  newTab?: TabQueryUpdate;
}
interface ActivateAction extends BaseAction {
  action: "activate";
}

interface UpdateQueryStringAction extends QueryAction {
  action: "updateQueryString";
  query: string;
  queryType: QueryType;
}
interface RenameQueryAction extends QueryAction {
  action: "renameQuery";
  name: string;
}
interface ChangeEndpointQueryAction extends QueryAction {
  action: "updateQueryEndpoint";
  endpointType?: SparqlQueryServiceType;
  endpointName?: string;
}

interface ExecuteQueryAction extends QueryAction {
  action: "executeQuery";
}
interface AbortQueryAction extends QueryAction {
  action: "abortResponse";
}

interface ExecuteQueryActionResponse extends QueryAction {
  action: "queryResponse";
  queryRawData?: RawQueryResponse;
  queryParsedData?: QueryResponse;
  requestDuration?: number;
  requestDelay?: number;
  cache?: CacheHeader;
  postProcessingDuration?: number;
  responseSize?: number;
  responseType?: ResponseType;
  zeroResultOperationLocations?: PositionInformation[];
}
interface ExecuteUpdateActionResponse extends QueryAction {
  action: "updateResponse";
  updateResponse?: UpdateResponse;
  requestDelay?: number;
  cache?: CacheHeader;
  requestDuration?: number;
  zeroResultOperationLocations?: PositionInformation[];
}
interface ExecuteUpdateActionResponseError extends QueryAction {
  action: "updateResponseError";
  queryRawData?: RawQueryResponse;
  requestDuration?: number;
  error: Error;
}
interface ExecuteQueryActionResponseError extends QueryAction {
  action: "queryResponseError";
  queryRawData?: RawQueryResponse;
  requestDuration?: number;
  error: Error;
}
interface PostProcessLargeResult extends QueryAction {
  action: "postProcessLargeResultEnd";
  queryParsedData: QueryResponse;
  queryRawData?: RawQueryResponse;
  postProcessingDuration: number;
}
interface StartProcessLargeResult extends QueryAction {
  action: "startProcessLargeResult";
}
interface UpdateVisualizationConfig extends QueryAction {
  action: "updateVisualizationConfig";
  selectedVisualization: VisualizationLabel;
  selectedConfig: VisualizationConfig;
}
type ReducerActions =
  | ActivateAction
  | UpdateTabOrderAction
  | SelectTabAction
  | AddTabAction
  | UpdateQueryStringAction
  | RenameQueryAction
  | ChangeEndpointQueryAction
  | UpdateVisualizationConfig
  | ExecuteQueryAction
  | AbortQueryAction
  | PostProcessLargeResult
  | ExecuteQueryActionResponse
  | ExecuteQueryActionResponseError
  | ExecuteUpdateActionResponse
  | ExecuteUpdateActionResponseError
  | StartProcessLargeResult;

function reducer(state: PersistedGlobalIdeConfig, action: ReducerActions): PersistedGlobalIdeConfig {
  switch (action.action) {
    case "updateTabOrder":
      state[action.dataset].tabOrder = action.newOrder;
      return state;
    case "selectTab":
      state[action.dataset].selectedTab = action.selectedTab;
      return state;
    case "addTab":
      const id = Math.random().toString(36).substring(7);
      const currentTab = state[action.dataset].queries[state[action.dataset].selectedTab];
      let name = action.newTab?.name;
      if (name === undefined) {
        // Grabbing the names from the currently active tabs
        const labels = state[action.dataset].tabOrder.map((tab) => state[action.dataset].queries[tab]?.name);
        name = "Query";
        let currentIteration = 1;
        while (labels.includes(name)) {
          name = `Query ${currentIteration}`;
          currentIteration++;
          if (currentIteration === Number.MAX_SAFE_INTEGER) break;
        }
      }

      const newTab = {
        ...DEFAULT_TAB_CONFIG,
        endpointName: currentTab.endpointName,
        endpointType: currentTab.endpointType,
        ...action.newTab,
        name: name,
      };
      state[action.dataset].queries[id] = newTab;
      state[action.dataset].selectedTab = id;
      state[action.dataset].tabOrder.push(id);
      return state;
    case "updateQueryString":
      // When restoring queries, the zeroResultsOperation need to be cleared see #9146-#28
      if (action.query !== state[action.dataset].queries[action.tabId].query) {
        state[action.dataset].queries[action.tabId].zeroResultOperationLocations = undefined;
      }
      state[action.dataset].queries[action.tabId].query = action.query;
      if (state[action.dataset].queries[action.tabId].queryType !== action.queryType) {
        state[action.dataset].queries[action.tabId].updateResult = undefined;
        state[action.dataset].queries[action.tabId].queryRawData = undefined;
        state[action.dataset].queries[action.tabId].queryParsedData = undefined;
      }
      state[action.dataset].queries[action.tabId].queryType = action.queryType;
      return state;
    case "renameQuery":
      state[action.dataset].queries[action.tabId].name = action.name;
      return state;
    case "updateVisualizationConfig":
      state[action.dataset].queries[action.tabId].visualization = action.selectedVisualization;
      state[action.dataset].queries[action.tabId].visualizationConfig = action.selectedConfig;
      return state;
    case "updateQueryEndpoint":
      state[action.dataset].queries[action.tabId].endpointName = action.endpointName;
      state[action.dataset].queries[action.tabId].endpointType = action.endpointType;
      return state;
    case "activate":
      state[action.dataset].exp = getNewExpiryDate();
      return state;
    case "executeQuery":
      state[action.dataset].queries[action.tabId].loading = true;
      return state;
    case "queryResponse":
      if (state[action.dataset].queries[action.tabId].queryType === "query") {
        state[action.dataset].queries[action.tabId].queryParsedData = action.queryParsedData;
        state[action.dataset].queries[action.tabId].queryRawData = action.queryRawData;
        state[action.dataset].queries[action.tabId].loading = false;
        state[action.dataset].queries[action.tabId].error = undefined;
        state[action.dataset].queries[action.tabId].requestDuration = action.requestDuration;
        state[action.dataset].queries[action.tabId].requestDelay = action.requestDelay;
        state[action.dataset].queries[action.tabId].cache = action.cache;
        state[action.dataset].queries[action.tabId].postProcessingDuration = action.postProcessingDuration;
        state[action.dataset].queries[action.tabId].responseType = action.responseType;
        state[action.dataset].queries[action.tabId].responseSize = action.responseSize;
        state[action.dataset].queries[action.tabId].zeroResultOperationLocations = action.zeroResultOperationLocations;
        state[action.dataset].queries[action.tabId].updateResult = undefined;
      } else {
        console.warn(
          "Tried to write a response to a Tab with queryType 'query' where the response was of type 'update'",
        );
      }
      return state;
    case "abortResponse":
      state[action.dataset].queries[action.tabId].loading = false;
      return state;
    case "queryResponseError":
    case "updateResponseError":
      state[action.dataset].queries[action.tabId].requestDuration = action.requestDuration;
      state[action.dataset].queries[action.tabId].error = action.error;
      state[action.dataset].queries[action.tabId].loading = false;
      state[action.dataset].queries[action.tabId].queryRawData = action.queryRawData;
      state[action.dataset].queries[action.tabId].queryParsedData = undefined;
      state[action.dataset].queries[action.tabId].updateResult = undefined;
      state[action.dataset].queries[action.tabId].responseType = "Error";
      return state;
    case "startProcessLargeResult":
      state[action.dataset].queries[action.tabId].loading = true;
      return state;
    case "postProcessLargeResultEnd":
      state[action.dataset].queries[action.tabId].postProcessingDuration = action.postProcessingDuration;
      state[action.dataset].queries[action.tabId].loading = false;
      state[action.dataset].queries[action.tabId].queryParsedData = action.queryParsedData;
      state[action.dataset].queries[action.tabId].skipResultSizeCheck = true;
      if (action.queryRawData) {
        state[action.dataset].queries[action.tabId].queryRawData = action.queryRawData;
      }
      return state;
    case "updateResponse":
      if (state[action.dataset].queries[action.tabId].queryType === "update") {
        state[action.dataset].queries[action.tabId].updateResult = action.updateResponse;
        state[action.dataset].queries[action.tabId].queryRawData = undefined;
        state[action.dataset].queries[action.tabId].queryParsedData = undefined;
        state[action.dataset].queries[action.tabId].loading = false;
        state[action.dataset].queries[action.tabId].error = undefined;
        state[action.dataset].queries[action.tabId].requestDuration = action.requestDuration;
        state[action.dataset].queries[action.tabId].requestDelay = action.requestDelay;
        state[action.dataset].queries[action.tabId].cache = action.cache;
        state[action.dataset].queries[action.tabId].postProcessingDuration = undefined;
        state[action.dataset].queries[action.tabId].responseType = "Update";
        state[action.dataset].queries[action.tabId].responseSize = undefined;
        state[action.dataset].queries[action.tabId].zeroResultOperationLocations = action.zeroResultOperationLocations;
      } else {
        console.warn(
          "Tried to write a response to a Tab with queryType 'update' where the response was 'of type query'",
        );
      }
      return state;
  }
}

interface SparqlIDEContext {
  // State
  readonly ideConfig?: PersistedIdeDatasetConfig;
  // Methods
  addTab?: (config?: TabQueryUpdate) => void;
  setTabOrder: (newOrder: string[]) => void;
  setSelectedTab: (tab: string) => void;
  setVisualizationConfig: (key: string, selectedVis: VisualizationLabel, visConfig?: VisualizationConfig) => void;
  setEndpoint: (tab: string, endpointName: string, endpointType: SparqlQueryServiceType) => void;
  setTabName: (key: string, newName: string) => void;
  onQueryChange: (key: string, newName: string, queryType: QueryType) => void;
  executeQuery: (tabId: string, endpointName?: string) => void;
  abortQuery: (tabId: string) => void;
  postProcessLargeResult: (tabId: string) => void;
}

const SparqlIDEContext = createContext<SparqlIDEContext>({
  ideConfig: undefined,
  addTab: undefined,
  setTabOrder: () => {
    throw new Error("No Provider mounted");
  },
  setSelectedTab: () => {
    throw new Error("No Provider mounted");
  },
  setVisualizationConfig: () => {
    throw new Error("No Provider mounted");
  },
  setEndpoint: () => {
    throw new Error("No Provider mounted");
  },
  setTabName: () => {
    throw new Error("No Provider mounted");
  },
  onQueryChange: () => {
    throw new Error("No Provider mounted");
  },
  executeQuery: () => {
    throw new Error("No Provider mounted");
  },
  abortQuery: () => {
    throw new Error("No Provider mounted");
  },
  postProcessLargeResult: () => {
    throw new Error("No Provider mounted");
  },
});
function getConfigFromLocalStore(dataset: string): PersistedGlobalIdeConfig {
  const config = localStorage.getItem("Sparql-IDE");
  const id = Math.random().toString(36).substring(7);
  if (!config) {
    return {
      [dataset]: {
        selectedTab: id,
        tabOrder: [id],
        queries: { [id]: DEFAULT_TAB_CONFIG },
        exp: getNewExpiryDate(),
      },
    };
  }
  try {
    const parsedJson: PersistedGlobalIdeConfig = JSON.parse(config);
    // The reducer will remove expired configs and filter datasets without any queries left e.g. Map + Filter
    for (const dsKey in parsedJson) {
      const dsConfig = parsedJson[dsKey];
      if (dsConfig.exp < Date.now()) {
        delete parsedJson[dsKey];
        continue;
      }
    }
    if (parsedJson[dataset] === undefined) {
      const id = Math.random().toString(36).substring(7);
      parsedJson[dataset] = {
        selectedTab: id,
        tabOrder: [id],
        queries: { [id]: DEFAULT_TAB_CONFIG },
        exp: getNewExpiryDate(),
      };
    }
    return parsedJson;
  } catch (e) {
    console.error("Couldn't recover config");
    return {
      [dataset]: {
        selectedTab: id,
        tabOrder: [id],
        queries: { [id]: DEFAULT_TAB_CONFIG },
        exp: getNewExpiryDate(),
      },
    };
  }
}
const KEYS_TO_PERSIST: (keyof PersistedTabQuery)[] = [
  "endpointName",
  "endpointType",
  "name",
  "query",
  "visualization",
  "visualizationConfig",
];
export function storeConfig(config: PersistedGlobalIdeConfig) {
  const configToPersist: PersistedGlobalIdeConfig = {};
  for (const dsKey in config) {
    configToPersist[dsKey] = {
      ...config[dsKey],
      queries: {},
    };
    for (const queryKey in config[dsKey].queries) {
      if (config[dsKey].tabOrder.includes(queryKey)) {
        configToPersist[dsKey].queries[queryKey] = pick(config[dsKey].queries[queryKey], KEYS_TO_PERSIST);
      }
    }
  }
  localStorage.setItem("Sparql-IDE", JSON.stringify(configToPersist));
}

export const SparqlIDEContextProvider: React.FC<{
  children: React.ReactNode;
  dataset: string;
}> = ({ children, dataset }) => {
  const [state, dispatch] = React.useReducer(produce(reducer), dataset, getConfigFromLocalStore);
  const dispatchRedux = useDispatch();
  const [abortControllers, setAbortControllers] = React.useState<{
    [queryId: string]: AbortController;
  }>({});
  const urlParams = useParams<{ account: string; dataset: string }>();
  const constructApiUrl = useConstructUrlToApi();
  const dsUrlSegment = `/datasets/${urlParams.account}/${urlParams.dataset}/`;
  // Refresh the current active tab on load
  React.useEffect(() => {
    dispatch({
      action: "activate",
      dataset: dataset,
    });
  }, [dataset]);
  React.useEffect(() => {
    storeConfig(state);
  }, [state]);

  const addTab = React.useCallback(
    (tabQuery?: TabQueryUpdate) => {
      dispatch({
        action: "addTab",
        dataset: dataset,
        newTab: tabQuery,
      });
    },
    [dataset],
  );
  const setSelectedTab = React.useCallback(
    (tabId: string) => {
      dispatch({
        action: "selectTab",
        dataset: dataset,
        selectedTab: tabId,
      });
    },
    [dataset],
  );
  const setTabOrder = React.useCallback(
    (newOrder: string[]) => {
      dispatch({
        action: "updateTabOrder",
        dataset: dataset,
        newOrder: newOrder,
      });
    },
    [dataset],
  );
  const setVisualizationConfig = React.useCallback(
    (tabId: string, selectedVis: VisualizationLabel, visConfig?: VisualizationConfig) => {
      dispatch({
        action: "updateVisualizationConfig",
        dataset: dataset,
        tabId: tabId,
        selectedConfig: visConfig,
        selectedVisualization: selectedVis,
      });
    },
    [dataset],
  );

  const renameTab = React.useCallback(
    (tabId: string, newName: string) => {
      dispatch({
        action: "renameQuery",
        dataset: dataset,
        name: newName,
        tabId: tabId,
      });
    },
    [dataset],
  );

  const setEndpoint = React.useCallback(
    (tabId: string, endpointName: string, endpointType: SparqlQueryServiceType) => {
      dispatch({
        action: "updateQueryEndpoint",
        dataset: dataset,
        tabId: tabId,
        endpointName: endpointName,
        endpointType: endpointType,
      });
    },
    [dataset],
  );

  // Add/Update
  const onQueryChange = React.useCallback(
    (tabId: string, newQuery: string, queryType: QueryType) => {
      dispatch({
        action: "updateQueryString",
        dataset: dataset,
        query: newQuery,
        tabId: tabId,
        queryType: queryType,
      });
    },
    [dataset],
  );
  const onAbortQuery = React.useCallback(
    (tabId: string) => {
      if (abortControllers[tabId]) {
        abortControllers[tabId].abort(new DOMException("User aborted query execution"));
        setAbortControllers((abort) => {
          delete abort[tabId];
          return abort;
        });
        dispatch({
          action: "abortResponse",
          dataset: dataset,
          tabId,
        });
      }
    },
    [abortControllers, dataset],
  );
  const onExecuteQuery = React.useCallback(
    (tabId: string, overrideEndpointName?: string) => {
      dispatch({
        action: "executeQuery",
        dataset: dataset,
        tabId,
      });

      const controller = new AbortController();
      const start = Date.now();
      setAbortControllers((abort) => ({ ...abort, ...{ [tabId]: controller } }));

      const { query, endpointName, skipResultSizeCheck, queryType } = state[dataset].queries[tabId];

      let requestType = queryType;
      if (!requestType) {
        try {
          const q = parsers.lenient(query, {
            baseIri: "https://triplydb.com/",
          });
          requestType = q.type;
        } catch (e) {
          // If the query is that invalid that we can't parse it with the leniant parser, assume it is a "query"
          requestType = "query";
        }
      }
      return fetch(
        constructApiUrl({
          pathname: `${dsUrlSegment}${
            (overrideEndpointName || endpointName) === "Speedy"
              ? requestType === "query"
                ? "sparql"
                : "update"
              : `services/${overrideEndpointName || endpointName}/sparql`
          }`,
        }),
        {
          signal: controller.signal,
          method: "Post",
          body: JSON.stringify(
            requestType === "query"
              ? {
                  query: query,
                }
              : {
                  update: query,
                },
          ),
          credentials: "same-origin",
          headers: {
            Accept: IDE_SPARQL_ACCEPT_HEADER,
            "Content-Type": "application/json",
          },
        },
      )
        .then(async (response) => {
          const responseSize = response.headers.get("X-Triply-length");
          const duration = Date.now() - start;
          const delay = Number(response.headers?.get("X-T-Delay-In-Ms") || 0);
          const cache = response.headers?.get("X-T-Cache") as CacheHeader;
          const zeroResultOperationLocations = response.headers
            ?.get("X-Triply-noResults")
            ?.split(" ")
            .map((segment) => {
              const [startLine, startColumn, endLine, endColumn] = segment.split(",");
              return {
                startLine: +startLine,
                startColumn: +startColumn,
                endLine: +endLine,
                endColumn: +endColumn,
              };
            });
          if (!response.ok) {
            const res: {
              message: string;
            } = await response.json();
            dispatch({
              action: "queryResponseError",
              dataset: dataset,
              tabId,
              error: new Error(res.message || response.statusText),
              queryRawData: res as any,
              requestDuration: duration,
            });
          } else if (responseSize && +responseSize >= MAX_QUERY_RESPONSE_SIZE && !skipResultSizeCheck) {
            const responseIsBindings = response.headers
              .get("content-type")
              ?.includes("application/sparql-results+json");
            dispatch({
              action: "queryResponse",
              dataset: dataset,
              tabId: tabId,
              requestDuration: duration,
              responseSize: responseSize ? +responseSize : undefined,
              queryRawData: await response.text(),
              responseType: responseIsBindings ? "Bindings" : "Triples",
              zeroResultOperationLocations: zeroResultOperationLocations,
              requestDelay: delay,
              cache: cache,
            });

            return;
          } else if (response.headers.get("content-type")?.includes("application/sparql-results+json")) {
            const processingStart = Date.now();
            const result: QueryResultJson = await response.json();
            const postProcessedResult = postProcessSparqlJsonResponse(result);
            const processingDuration = Date.now() - processingStart;
            dispatch({
              action: "queryResponse",
              dataset: dataset,
              tabId: tabId,
              queryRawData: result,
              queryParsedData: postProcessedResult,
              requestDuration: duration,
              requestDelay: delay,
              cache: cache,
              postProcessingDuration: processingDuration,
              responseSize: responseSize ? +responseSize : undefined,
              responseType: "Bindings",
              zeroResultOperationLocations: zeroResultOperationLocations,
            });
          } else if (response.headers.get("content-type")?.includes("text/turtle")) {
            const processingStart = Date.now();

            const result = await response.text();
            const postProcessedResult = postProcessTriplesResponse(result);
            const processingDuration = Date.now() - processingStart;

            dispatch({
              action: "queryResponse",
              dataset: dataset,
              tabId: tabId,
              queryRawData: result,
              queryParsedData: postProcessedResult,
              requestDuration: duration,
              requestDelay: delay,
              cache: cache,
              postProcessingDuration: processingDuration,
              responseSize: responseSize ? +responseSize : undefined,
              responseType: "Triples",
              zeroResultOperationLocations: zeroResultOperationLocations,
            });
          } else if (response.headers.get("content-type")?.includes("application/json")) {
            const result = await response.json();
            dispatch({
              action: "updateResponse",
              dataset: dataset,
              tabId: tabId,
              updateResponse: result,
              requestDuration: duration,
              requestDelay: delay,
              cache: cache,
              zeroResultOperationLocations: zeroResultOperationLocations,
            });
            await Promise.all([
              dispatchRedux<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: urlParams.account, datasetName: urlParams.dataset }),
              ),
              dispatchRedux<typeof getGraphs>(
                getGraphs({ accountName: urlParams.account, datasetName: urlParams.dataset, datasetId: dataset }),
              ),
            ]);
          }
        })
        .catch((e) => {
          if (e instanceof DOMException && e.message === "User aborted query execution") {
            return;
          }
          dispatch({
            action: "queryResponseError",
            error: e,
            queryRawData: undefined,
            dataset,
            tabId,
          });
        })
        .finally(() => {
          setAbortControllers((abort) => {
            delete abort[tabId];
            return abort;
          });
        });
    },
    [dataset, state, constructApiUrl, dsUrlSegment, dispatchRedux, urlParams.account, urlParams.dataset],
  );
  const postProcessLargeResult = React.useCallback(
    async (tabId: string) => {
      const tab = state[dataset].queries[tabId];
      if (!tab.queryRawData) {
        throw new Error("Missing data");
      }
      if (typeof tab.queryRawData !== "string") {
        return;
      }
      dispatch({
        tabId: tabId,
        dataset: dataset,
        action: "startProcessLargeResult",
      });
      if (tab.responseType === "Bindings") {
        const processingStart = Date.now();
        const result: QueryResultJson = JSON.parse(tab.queryRawData);
        const postProcessedResult = postProcessSparqlJsonResponse(result);
        const processingDuration = Date.now() - processingStart;
        dispatch({
          action: "postProcessLargeResultEnd",
          dataset: dataset,
          tabId: tabId,
          queryRawData: result,
          queryParsedData: postProcessedResult,
          postProcessingDuration: processingDuration,
        });
      } else if (tab.responseType === "Triples") {
        const processingStart = Date.now();
        const postProcessedResult = postProcessTriplesResponse(tab.queryRawData);
        const processingDuration = Date.now() - processingStart;

        dispatch({
          action: "postProcessLargeResultEnd",
          dataset: dataset,
          tabId: tabId,
          queryParsedData: postProcessedResult,
          postProcessingDuration: processingDuration,
        });
      }
    },
    [dataset, state],
  );
  const contextObject: SparqlIDEContext = {
    ideConfig: state[dataset],
    addTab: addTab,
    setSelectedTab: setSelectedTab,
    setTabOrder: setTabOrder,
    setTabName: renameTab,
    setVisualizationConfig: setVisualizationConfig,
    onQueryChange: onQueryChange,
    executeQuery: onExecuteQuery,
    abortQuery: onAbortQuery,
    setEndpoint: setEndpoint,
    postProcessLargeResult: postProcessLargeResult,
  };

  return <SparqlIDEContext value={contextObject}>{children}</SparqlIDEContext>;
};

// Hook to provide access to context object
export const useSparqlIDEContext = () => {
  return useContext(SparqlIDEContext);
};

export function ideToQueryConfigLink(
  ideConfig: TabQuery,
  endpointUrl: string,
): {
  query: string;
  endpoint: string;
  tabTitle: string;
  outputFormat: string;
  outputSettings: any;
} {
  return {
    query: ideConfig.query,
    endpoint: endpointUrl,
    tabTitle: ideConfig.name,
    outputFormat: ideConfig.visualization,
    outputSettings: ideConfig.visualizationConfig,
  };
}

export function getEndpointNameFromUrl(endpoint: string) {
  const endpointSegments = endpoint.split("/").slice(-3);
  if (endpointSegments[0] === "services" && endpointSegments[2] === "sparql") {
    return endpointSegments[1];
  }
  return "Speedy";
}

export function queryConfigLinkToIde(
  config: {
    query: string;
    endpoint: string | undefined;
    tabTitle: string;
    outputFormat: string;
    outputSettings: any;
  },
  endpoint: string,
  staticConfig: StaticConfig | undefined,
): TabQuery | undefined {
  if (!config.endpoint) return undefined;
  try {
    return {
      ...DEFAULT_TAB_CONFIG,
      name: config.tabTitle,
      query: config.query,
      endpointName: getEndpointNameFromUrl(config.endpoint || endpoint),
      visualization: getIdeVisualization(config.outputFormat) || "Table",
      visualizationConfig: isIdeVisualization(config.outputFormat)
        ? config.outputSettings
        : yasguiVisualizationConfigToIdeVisualizationConfig(config.outputFormat, config.outputSettings, staticConfig),
    };
  } catch {
    return undefined;
  }
}
