import match from "autosuggest-highlight/match/index.js";
import parse from "autosuggest-highlight/parse/index.js";
import { deburr, escapeRegExp, sortBy } from "lodash-es";
import * as React from "react";

const whitespacesRegex = /\s+/;

type Matcher = (text: string, query: string) => [number, number][];

export const substringMatch: Matcher = (text: string, query: string) => {
  //remove diacritics
  text = deburr(text);
  query = deburr(query);

  const searchWords = query
    .trim()
    .split(whitespacesRegex)
    // If query is blank, we'll get empty string here, so let's filter it out.
    .filter((word) => word.length > 0);

  const result: [number, number][] = [];

  for (const searchWord of searchWords) {
    const index = text.search(new RegExp(escapeRegExp(searchWord), "i"));

    // Word not found, lets look at the next one
    if (index === -1) continue;
    result.push([index, index + searchWord.length]);

    // Replace what we just found with spaces so we don't find it again.
    text = text.slice(0, index) + new Array(searchWord.length + 1).join(" ") + text.slice(index + searchWord.length);
  }
  // Sort results
  return sortBy(result, (x) => x[0]);
};

export const substringMatchInOrder: Matcher = (text: string, query: string) => {
  //remove diacritics
  text = deburr(text);
  query = deburr(query);

  const searchWords = query
    .trim()
    .split(whitespacesRegex)
    // If query is blank, we'll get empty string here, so let's filter it out.
    .filter((word) => word.length > 0);

  const result: [number, number][] = [];

  for (const searchWord of searchWords) {
    const index = text.search(new RegExp(escapeRegExp(searchWord), "i"));

    //one search word does not match, so fail
    if (index === -1) return [];

    result.push([index, index + searchWord.length]);

    // Replace what we just found with spaces so we don't find it again.
    text = new Array(index + searchWord.length + 1).join(" ") + text.slice(index + searchWord.length);
  }

  return result;
};

export const wordStartMatch = match;

interface Props {
  fullText: string;
  highlightedText: string;
  matcher?: Matcher;
  className?: string;
}

const Highlight: React.FC<Props> = ({ fullText, highlightedText, matcher = wordStartMatch, className }) => {
  const matches = matcher(fullText, highlightedText);
  const parts: { highlight: boolean; text: string }[] = parse(fullText, matches);
  return (
    <span className={className}>
      {parts.map((part, index) => {
        return part.highlight ? (
          <span key={String(index)} style={{ fontWeight: 600 }}>
            {part.text}
          </span>
        ) : (
          <strong key={String(index)} style={{ fontWeight: 400 }}>
            {part.text}
          </strong>
        );
      })}
    </span>
  );
};
export default Highlight;
