import type * as RdfJs from "@rdfjs/types";
// @ts-ignore: the console validation script complains wrt missing types
import _forkedN3Store from "./forked/N3Store.js"; // Store that supports custom termToId and termFromId config
import type { Store as N3StoreInterface } from "./forked/N3StoreInterface.ts";
import type { AnyTdbDataFactory } from "./DataFactory.ts";
import { factories } from "./DataFactory.ts";
import type { Quad, Term } from "./Terms.ts";
import { DefaultGraph } from "./Terms.ts";
// Explicitly typed as any, as we dont want derived ts info about this js file
// Instead, we can re-use the types from `@types/n3`
const forkedN3Store = _forkedN3Store as any as { new <Q extends RdfJs.BaseQuad>(...args: any): N3StoreInterface<Q> };
interface StoreOptions {
  factory?: AnyTdbDataFactory;
}

export type PatternTerm = RdfJs.Term | null;

export interface Pattern {
  subject?: PatternTerm;
  predicate?: PatternTerm;
  object?: PatternTerm;
  graph?: PatternTerm;
}

export interface Store<Q extends Quad = Quad> extends N3StoreInterface<Q> {}
export class Store<Q extends Quad = Quad> extends forkedN3Store<Q> {
  // This factory is used to migrate store IDs (strings) back to terms
  readonly factory: AnyTdbDataFactory;
  private termMap: Map<string, Term> = new Map();
  constructor(triples: Q[], options?: StoreOptions);
  constructor(options?: StoreOptions);
  constructor(triplesOrOpts?: Q[] | StoreOptions, possibleOptions?: StoreOptions) {
    let options = possibleOptions || {};
    if (triplesOrOpts && !Array.isArray(triplesOrOpts)) {
      options = triplesOrOpts;
    }
    const factory = options.factory || factories.compliant;

    super({
      ...options,
      factory,
      // Use own term[To|From]Id, to persist the validation status.
      // This avoids having to re-validate terms that are already validated
      termToId: (term: Term | string) => this.termToId(term),
      termFromId: (id: string) => this.termFromId(id),
    });
    this.factory = factory;
    if (Array.isArray(triplesOrOpts)) this.addQuads(triplesOrOpts);
  }

  public getQuads(pattern: Pattern): Q[];
  public getQuads(
    subject: PatternTerm,
    predicate: PatternTerm,
    object: PatternTerm | PatternTerm[],
    graph: PatternTerm,
  ): Q[];
  public getQuads(
    subjectOrPattern: PatternTerm | Pattern,
    predicate?: PatternTerm,
    object?: any,
    graph?: PatternTerm,
  ): Q[] {
    if (
      subjectOrPattern === undefined ||
      subjectOrPattern === null ||
      typeof subjectOrPattern === "string" ||
      "termType" in subjectOrPattern
    ) {
      const subject = subjectOrPattern as PatternTerm;
      return super.getQuads(subject ?? null, predicate ?? null, object ?? null, graph ?? null);
    } else {
      return super.getQuads(
        subjectOrPattern.subject ?? null,
        subjectOrPattern.predicate ?? null,
        subjectOrPattern.object ?? null,
        subjectOrPattern.graph ?? null,
      );
    }
  }

  // ### Constructs an internal string ID from the given term or ID string
  private termToId = (_term: Term | string | undefined) => {
    /**
     * We cannot be fully certain that a term is created by us. For example, we've had a situation where
     * the shacl validation library added rdf:list items to the store that were not created by our factory
     * (even though we explicitly pass that factory as an argument to the validator)
     * As a result, IDs may be incorrect, causing atypical behaviour
     * To avoid this, always run the factory.fromTerm (this is a cheap function, as we escape early)
     */
    if (typeof _term === "string") return _term; // it's already an ID
    // The term can be undefined when we're e.g. adding a graph with `undefined` as graph name
    if (!_term) return DefaultGraph.id;
    const term = this.factory.fromTerm(_term);
    if (term.termType === "Quad") throw new Error("RDF* not supported");
    const id = term.id;
    this.termMap.set(id, term);
    return id;
  };

  // ### Constructs a term from the given internal string ID
  private termFromId = (id: string) => {
    // Falsy value or empty string indicate the default graph
    if (!id) return this.factory.defaultGraph();
    return this.termMap.get(id);
  };
}
