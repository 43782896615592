import debug from "debug";
import { getReasonPhrase } from "http-status-codes";
import type { Context } from "koa";
import { map } from "lodash-es";
import parseLinkHeader from "parse-link-header";
import type { Models, Routes } from "@triply/utils";
import type { GlobalState } from "#reducers/index.ts";
import type { ConstructUrlToApi } from "#staticConfig.ts";
import { getConfig, getConstructUrlToApi } from "#staticConfig.ts";
import { checkSessionEnd } from "./fetch.ts";

const log = debug("triply:console:requests");
export interface Links extends parseLinkHeader.Links {
  next: parseLinkHeader.Link;
  first: parseLinkHeader.Link;
}

export interface ResponseMetaData {
  status: number;
  links: Partial<Links>;
}
export interface ErrorResponse {
  requestTo: string;
  status?: number;
  message: string;
  devError?: string;
}
export type RequestArguments<Q = { [key: string]: string }, B = Object | string> =
  | UrlRequestArguments<Q, B>
  | PathRequestArguments<Q, B>;
export interface BaseRequestArguments<Q = { [key: string]: string }, B = Object | string> {
  url?: string;
  pathname?: string;
  method: "get" | "post" | "delete" | "put" | "patch";
  query?: Q;
  body?: B;
  contentType?: string;
  accept?: string;
  statusCodeMap?: { [fromCode: number]: number }; //these status codes are set to 200 (OK).Useful if we expect e.g. a 404, but don't want to clobber the browser with errors
  files?: { [fieldName: string]: string | File };
}

export interface UrlRequestArguments<Q = { [key: string]: string }, B = Object | string>
  extends BaseRequestArguments<Q, B> {
  url: string;
  pathname?: never;
}
export interface PathRequestArguments<Q = { [key: string]: string }, B = Object | string>
  extends BaseRequestArguments<Q, B> {
  pathname: string;
  url?: never;
}

export type ClientRequestArguments<Req extends Routes.RequestTemplate> = RequestArguments<Req["Query"], Req["Body"]>;

export type GenericReduxApiResponse<Res extends Routes.ResponseTemplate> = {
  body: Res["Body"];
  meta: ResponseMetaData;
};

export interface GenericReduxContext<Method extends Routes.HttpMethodTemplate> {
  req(args: ClientRequestArguments<Method["Req"]>): Promise<GenericReduxApiResponse<Method["Res"]>>;
}

export interface ClientResult {
  body: any;
  meta?: ResponseMetaData;
}

export default class ApiClient {
  private ctx?: Context;
  private constructUrlToApi: ConstructUrlToApi;

  constructor(initialState: GlobalState, ctx?: Context) {
    if (initialState && initialState.config && initialState.config.staticConfig) {
      this.constructUrlToApi = getConstructUrlToApi({
        consoleUrlInfo: initialState.config.staticConfig.consoleUrl,
        apiUrlInfo: initialState.config.staticConfig.apiUrl,
      });
    } else {
      //just get state from module directly.
      //Would like to avoid this when the class is ran from the client,
      //because the client would not know about env variable overwrites
      const conf = getConfig();
      this.constructUrlToApi = getConstructUrlToApi({ consoleUrlInfo: conf.consoleUrl, apiUrlInfo: conf.apiUrl });
    }

    this.ctx = ctx;
  }

  private parseLinkHeader(linkHeader: string | undefined | null) {
    if (!linkHeader) return {};
    const links = parseLinkHeader(linkHeader);
    if (!links) return {};
    return links;
  }
  private async getBody(response: Response) {
    if (response.headers.get("Content-Type")?.toLowerCase().includes("json")) {
      return response.json();
    } else {
      return response.text();
    }
  }

  public async req<A extends RequestArguments, R extends ClientResult>(args: A): Promise<R> {
    const requestTo = this.constructUrlToApi(
      args.url ? { fullUrl: args.url, query: args.query } : { pathname: args.pathname as string, query: args.query },
    );
    const start = Date.now();
    try {
      log(`--> Fetching ${requestTo}`);
      if (__CLIENT__) console.info(requestTo);

      const headers: Record<string, string> = {};
      if (__SERVER__ && this.ctx) {
        //always behind a proxy anyway, so proxy these headers
        for (const key of ["cookie", "x-forwarded-for", "x-real-ip", "x-forwarded-proto", "x-dockerhealthcheck"]) {
          const val = this.ctx.get(key);
          if (val) headers[key] = val;
        }
        // Also set a header that says we are server side rendering
        headers["X-Triply-Render"] = "server";
      }
      if (args.accept) {
        headers["Accept"] = args.accept;
      }
      let requestBody: FormData | string | undefined;
      if (args.files) {
        requestBody = new FormData();
        for (const key in args.files) {
          const val = args.files[key];
          requestBody.append(key, val);
        }
      } else if (args.body) {
        headers["Content-Type"] = "application/json";
        requestBody = JSON.stringify(args.body);
      }
      if (args.statusCodeMap) {
        headers["x-statusMap"] = map(args.statusCodeMap, function (val: string, key: string) {
          return key + ":" + val;
        }).join(",");
      }

      const res = await fetch(requestTo, { method: args.method.toUpperCase(), headers, body: requestBody });

      // When server side rendering and the token is expired, remove the token cookie
      if (__SERVER__ && this.ctx && res.headers.get("X-Triply-Session-Ended")) {
        this.ctx.cookies.set("jwt", null, { overwrite: true });
      }
      // When in the browser and the session has expired, go to the login page
      checkSessionEnd(res.headers.get("X-Triply-Session-Ended"));

      const responseBody = await this.getBody(res);
      if (!res.ok) {
        log(`<-- Fetching ${requestTo} failed (${Date.now() - start}ms)`);
        let errorMessage: string;
        let errorResponseJson: Models.ErrorResponse | undefined =
          typeof responseBody === "object" ? responseBody : undefined;
        // don't want to return html as part of the message, ending up in the interface
        const isHtmlResponse = res.headers.get("Content-Type")?.toLowerCase().includes("text/html");

        if (errorResponseJson?.message && !isHtmlResponse) {
          errorMessage = errorResponseJson.message;
        } else if (res.statusText) {
          errorMessage = res.statusText;
        } else {
          errorMessage = getReasonPhrase(res.status);
        }
        const devError = errorResponseJson?.serverError;
        // Throwing JSON object for legacy reasons (we once implemented it this way).
        // One reason for doing this may be that we want to store plain json in the redux store
        throw {
          message: errorMessage,
          devError,
          requestTo,
          status: res.status,
        } as ErrorResponse;
      }
      const meta: ResponseMetaData = {
        status: res.status,
        links: this.parseLinkHeader(res.headers.get("link")),
      };
      log(
        `<-- Fetched ${requestTo} (${res.headers.get("x-t-cache") ? `cache-${res.headers.get("x-t-cache")} ` : ""}${
          Date.now() - start
        }ms)`,
      );
      // Any cast, as our types arent exact enough to map the API response to the
      // returntype of this fn
      return {
        body: responseBody,
        meta,
      } as any;
    } catch (e) {
      // A fatal error, e.g. we can't connect to the API, or we have a bug in constructing
      // the request or postprocessing the response
      // All 4xx and 5xx responses are _not_ handled in this clause
      log(`<-- Fetching ${requestTo} failed (${Date.now() - start}ms)`);
      if (e instanceof Error) {
        // Throwing JSON object for legacy reasons (we once implemented it this way).
        // One reason for doing this may be that we want to store plain json in the redux store
        throw {
          message: e.message,
          requestTo,
        } as ErrorResponse;
      }
      throw e;
    }
  }
}
