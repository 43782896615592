import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { asyncConnect } from "redux-connect";
import * as ReduxForm from "redux-form";
import type { Token } from "@triply/utils/Models";
import * as Forms from "#components/Forms/index.ts";
import { tokenGroupsToScopes, tokenToAuthLevel } from "#components/Forms/Token/tokenGroups.ts";
import { AccountMetadata, ErrorPage, FlexContainer } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import type { Account } from "#reducers/accountCollection.ts";
import { accountIsCurrentAccount, getAccountInfo, getCurrentAccount } from "#reducers/app.ts";
import type { DispatchedFn, GlobalState } from "#reducers/index.ts";
import { createToken, getToken, isTokenLoaded, updateToken } from "#reducers/tokens.ts";

namespace TokenInfo {
  export interface Props extends IComponentProps {
    currentAccount: Account;
    createToken: DispatchedFn<typeof createToken>;
    updateToken: DispatchedFn<typeof updateToken>;
    currentToken: Token;

    goBack: typeof connectedReactRouter.goBack;
  }
}

const TokenInfo: React.FC<TokenInfo.Props> = ({
  currentAccount,
  currentToken,
  location,
  goBack,
  createToken,
  updateToken,
}) => {
  const acl = useAcl();
  if (!acl.check({ action: "manageTokens", context: { roleInUser: acl.getRoleInAccount(currentAccount) } }).granted) {
    return <ErrorPage statusCode={401} />;
  }
  return (
    <FlexContainer>
      <AccountMetadata currentAccount={currentAccount} currentPath={location.pathname} title={"API Token"} />
      <div className="whiteSink">
        <h3>{currentToken ? "Update token" : "Create token"}</h3>
        <Forms.Token
          initialValues={{
            description: currentToken.description,
            authLevel: tokenToAuthLevel(currentToken),
          }}
          currentAccount={currentAccount}
          onSubmit={(values: Forms.Token.FormData) => {
            if (currentToken) {
              return updateToken(
                currentAccount,
                currentToken.tokenId,
                values.description,
                tokenGroupsToScopes(values),
              ).then(
                () => {},
                (e: any) => {
                  throw new ReduxForm.SubmissionError({ _error: e.message });
                },
              );
            } else {
              return createToken(currentAccount, values.description, tokenGroupsToScopes(values)).then(
                () => {},
                (e: any) => {
                  throw new ReduxForm.SubmissionError({ _error: e.message });
                },
              );
            }
          }}
          token={currentToken}
          cancel={goBack}
          enableReinitialize
        />
      </div>
    </FlexContainer>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ match: { params }, store: { dispatch, getState } }) => {
      const promises: Promise<any>[] = [];
      if (!accountIsCurrentAccount(getState(), params.account)) {
        promises.push(
          dispatch<any>(getAccountInfo(getState(), params.account))
            .catch(() => {})
            .then(() => {
              const currentAccount = getCurrentAccount(getState());
              if (!currentAccount) return;
              return dispatch<any>(getToken(currentAccount, params.tokenId)).catch(() => {});
            }),
        );
      } else if (params.tokenId && !isTokenLoaded(getState(), params.tokenId)) {
        const currentAccount = getCurrentAccount(getState());
        if (currentAccount) promises.push(dispatch<any>(getToken(currentAccount, params.tokenId)).catch(() => {}));
      }
      return Promise.all(promises);
    },
  },
])(
  (connect as any)(
    (state: GlobalState, _ownProps: TokenInfo.Props) => {
      return {
        currentAccount: getCurrentAccount(state),
        currentToken: state.tokens.currentToken,
      } as TokenInfo.Props;
    },
    {
      createToken,
      updateToken,
      goBack: connectedReactRouter.goBack,
    },
  )(TokenInfo),
);
