import { Typography } from "@mui/material";
import * as React from "react";
import useAcl from "#helpers/hooks/useAcl.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import EditClass from "./EditClass";
import RemoveClass from "./RemoveClass";

const Header: React.FC<{ label: string }> = ({ label }) => {
  const currentClass = useCurrentResource();

  const currentAccount = useCurrentAccount();
  const acl = useAcl();
  const mayManageDataModel = acl.check({
    action: "manageDataModel",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  if (!currentClass) return null;

  return (
    <div className="flex center g-5">
      <Typography className="grow" variant="h5" component="h1">
        {label}
      </Typography>
      {mayManageDataModel && <EditClass />}
      {mayManageDataModel && <RemoveClass />}
    </div>
  );
};

export default Header;
