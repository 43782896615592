import type { StandardTextFieldProps } from "@mui/material";
import { Autocomplete, Badge, Checkbox, Chip, Fab, TextField } from "@mui/material";
import getClassName from "classnames";
import type { Location } from "history";
import React from "react";
import { useHistory } from "react-router";
import { getPrefixInfoFromIri } from "@triply/utils/prefixUtils";
import { FontAwesomeIcon } from "#components/index.ts";
import { useHotkeys } from "#containers/Hotkeys/index.tsx";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import ColorPrefixed from "./ColorPrefixed.tsx";
import type { QuerySchema } from "./useFetchSchema.ts";
import { getPrefixIri, prefixedOrLocalName } from "./utils.ts";
import * as styles from "./style.scss";

const FilterButton: React.FC<{
  querySchema: QuerySchema | undefined;
  location: Location;
}> = ({ querySchema, location }) => {
  const [open, setOpen] = React.useState(false);
  const [classes, setClasses] = React.useState<string[]>([]);
  const prefixesRef = React.useRef(useDatasetPrefixes());

  React.useEffect(() => {
    if (!querySchema) return;
    const findNodes = async () => {
      const classes = (
        await querySchema(`
            prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
            prefix owl: <http://www.w3.org/2002/07/owl#>
            prefix sh: <http://www.w3.org/ns/shacl#>
            select distinct ?class where {
              {
                ?shape sh:targetClass ?class.
              } union {
                ?class a rdfs:Class.
              } union {
                ?class a owl:Class.
              } union {
                ?property rdfs:domain|rdfs:range ?class.
              } union {
                ?class rdfs:subClassOf|^rdfs:subClassOf ?otherClass.
              }
            } order by ?class
          `)
      ).map((binding) => binding.class!.value);
      const prefixIris = new Set<string>();
      for (const c of classes) {
        const prefixIri = getPrefixIri(prefixesRef.current, c);
        if (prefixIri) prefixIris.add(prefixIri);
      }
      setClasses([...prefixIris, ...classes]);
    };

    findNodes().catch(console.error);
  }, [querySchema]);

  const history = useHistory();
  const query = parseSearchString(location.search);
  const classFilter = query.f ? (query.f as string).split(",") : [];

  useHotkeys(
    { component: "Schema", description: "Filter classes", keyBinds: "Mod+shift+f" },
    (event) => {
      if (location.hash.length > 1) return;
      setOpen(true);
      event.preventDefault();
    },
    {
      enableOnFormTags: ["INPUT", "TEXTAREA", "SELECT"],
    },
  );

  return (
    <>
      <Badge badgeContent={classFilter.length} color="secondary" classes={{ badge: styles.badge }}>
        <Fab
          onClick={(event) => {
            event.stopPropagation();
            if (!open) setOpen(true);
          }}
          size="medium"
          aria-label="Filter classes"
          variant="extended"
          disableFocusRipple
          disableRipple
          className={getClassName(styles.filterButton, { [styles.collapsed]: !open })}
          classes={{
            focusVisible: styles.focusVisible,
          }}
          title="Filter"
          component="span"
        >
          {open && (
            <Autocomplete
              className={styles.filterField}
              value={classFilter}
              onChange={(_e, newValue: string[]) => {
                history.replace({
                  search: stringifyQuery({
                    ...query,
                    f: newValue.join(","),
                  }),
                });
              }}
              onKeyUp={(e) => {
                if (e.key === "Escape") {
                  setOpen(false);
                }
              }}
              onBlur={() => {
                setOpen(false);
              }}
              multiple
              fullWidth
              options={classes}
              noOptionsText="No matching classes"
              open
              popupIcon={null}
              autoHighlight
              getOptionLabel={(option) => {
                return prefixedOrLocalName(prefixesRef.current, option);
              }}
              classes={{
                tag: styles.chip,
                inputRoot: styles.inputRoot,
              }}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip label={<ColorPrefixed>{option}</ColorPrefixed>} {...getTagProps({ index })} />
                ))
              }
              renderOption={(props, c, { selected }) => {
                const prefixInfo = getPrefixInfoFromIri(c, prefixesRef.current);
                return (
                  <li {...props}>
                    <Checkbox
                      icon={<FontAwesomeIcon icon="square" />}
                      checkedIcon={<FontAwesomeIcon icon="square-check" />}
                      indeterminateIcon={<FontAwesomeIcon icon="square-minus" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      indeterminate={!selected && !!prefixInfo.prefixLabel && classFilter.includes(prefixInfo.iri)}
                    />
                    <ColorPrefixed>{c}</ColorPrefixed>
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...(params as StandardTextFieldProps)} placeholder="Class" autoFocus />
              )}
            />
          )}
          <FontAwesomeIcon icon="filter" size="lg" />
        </Fab>
      </Badge>
    </>
  );
};
export default FilterButton;
