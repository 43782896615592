/**
 * WKT parser based on:
 * - OpenGIS® Implementation Standard for Geographic
 *   information - Simple feature access - Part 1: Common
 *   architecture
 *   Date: 2011-05-28
 *   See https://issues.triply.cc/attachments/3968 for the PDF (section 7.2 contains the BNF)
 * - OGC GeoSPARQL - A Geographic Query Language for RDF Data
 *   Publication Date: 2012-09-10
 *   Version 1.0
 *   See https://issues.triply.cc/attachments/3973 for the PDF (section 8.5.1 contains the CRS-related spec)  *
 */

import type { IOrAlt, TokenType } from "chevrotain";
import { createToken, EmbeddedActionsParser, Lexer } from "chevrotain";
import { compact } from "lodash-es";
import { DATA_TYPE_NAME_MAPPING } from "./utils/constants.ts";
import { StandardParseError } from "./Errors.ts";

const Empty_Set = createToken({ name: "Empty_Set", pattern: /empty/i });
const Left_Paren = createToken({ name: "Left_Paren", pattern: "(" });
const Right_Paren = createToken({ name: "Right_Paren", pattern: ")" });
const Comma = createToken({ name: "Comma", pattern: /[ \t]*,[ \t]*/ });
/**
 * The points below use the following mechanism:
 * - Pattern for <number> <number>, with a trailing lookahead for a comma or right-parenthesis
 * - This ensures that we can parse without ambiguity and needing to look ahead. Situations where this may otherwise happen:
 *   - disambiguating the mantissa of the approximate_numeric_literal from the exact_numeric_literal
 *   - disambiguating the trailing comma of an exact_numeric_literal with the separator in e.g. a LineString
 * This does mean we're not following the grammar to the letter, but the result should be the same.
 * It also means that (given that the regex is not as accurate as the spec), we need to apply custom postprocessing / validation
 */
const Point_2 = createToken({ name: "Point_2", pattern: /[ \t]*[\.,0-9e-]+[ \t]+[\.,0-9e-]+[ \t]*(?=[,\)])/ });
const Point_3 = createToken({
  name: "Point_3",
  pattern: /[ \t]*[\.,0-9e-]+[ \t]+[\.,0-9e-]+[ \t]+[\.,0-9e-]+[ \t]*(?=[,\)])/,
});
const Point_4 = createToken({
  name: "Point_4",
  pattern: /[ \t]*[\.,0-9e-]+[ \t]+[\.,0-9e-]+[ \t]+[\.,0-9e-]+[ \t]+[\.,0-9e-]+[ \t]*(?=[,\)])/,
});
// @DECISION Deviating from the grammar which isn't explicit enough: adding optional trailing whitespace
const Point_Tag = createToken({ name: "Point_Tag", pattern: /point[\t ]*/i });
const Linestring_Tag = createToken({ name: "Linestring_Tag", pattern: /linestring[\t ]*/i });
const Polygon_Tag = createToken({ name: "Polygon_Tag", pattern: /polygon[\t ]*/i });
const Polyhedralsurface_Tag = createToken({ name: "Polyhedralsurface_Tag", pattern: /polyhedralsurface[\t ]*/i });
const Triangle_Tag = createToken({ name: "Triangle_Tag", pattern: /triangle[\t ]*/i });
const Tin_Tag = createToken({ name: "Tin_Tag", pattern: /tin[\t ]*/i });
const Multipoint_Tag = createToken({ name: "Multipoint_Tag", pattern: /multipoint[\t ]*/i });
const Multilinestring_Tag = createToken({ name: "Multilinestring_Tag", pattern: /multilinestring[\t ]*/i });
const Multipolygon_Tag = createToken({ name: "Multipolygon_Tag", pattern: /multipolygon[\t ]*/i });
const Geometrycollection_Tag = createToken({ name: "Geometrycollection_Tag", pattern: /geometrycollection[\t ]*/i });
const ZM = createToken({ name: "ZM", pattern: /zm[ \t]*/i });
const M = createToken({ name: "M", pattern: /m[ \t]*/i });
const Z = createToken({ name: "Z", pattern: /z[ \t]*/i });

// The spec is unclear where whitespace can be used. I.e., using this when we see fit
const WS = createToken({ name: "WS", pattern: /[ \t\n\r]+/ });
// The GeoSPARQL spec dictates an uri (rfc 2396), not an iri
// The regex below comes from the spec: https://datatracker.ietf.org/doc/html/rfc3986#appendix-B
// It's modified to remove the capture groups, and to force the URI to be absolute (as the spec dictates)
const Crs_Uri = createToken({
  name: "Crs_Uri",
  pattern: /<[^:^/^?^#^>]+:\/\/[^/^?^#^>]+[^>]*>/,
});
const allTokensExceptPoints = [
  Empty_Set,
  Left_Paren,
  Right_Paren,
  Point_Tag,
  Linestring_Tag,
  Polygon_Tag,
  Polyhedralsurface_Tag,
  Triangle_Tag,
  Tin_Tag,
  Multipoint_Tag,
  Multilinestring_Tag,
  Multipolygon_Tag,
  Geometrycollection_Tag,
  ZM,
  M,
  Z,
  // @DECISION The spec is unclear where whitespace can be used. I.e., using this when we see fit
  WS,
  // The GeoSPARQL spec dictates an uri (rfc 2396), not an iri
  // The regex below comes from the spec: https://datatracker.ietf.org/doc/html/rfc3986#appendix-B
  // It's modified to remove the capture groups, and to force the URI to be absolute (as the spec dictates)
  Crs_Uri,
  Comma,
];
type GeometryTypeInfo = Pick<Geometry, "hasZ" | "hasMeasurement">;
type GeometryType = "z" | "zm" | "m" | undefined;
type AlternativesForGeometry =
  | IOrAlt<{
      hasZ?: boolean;
      hasMeasurements?: boolean;
      type: string;
      coordinates?: Coordinates | Coordinates[] | Coordinates[][] | Coordinates[][][];
      geometries?: GeometryWithoutCrs[];
    }>[]
  | undefined;

export class WktParser extends EmbeddedActionsParser {
  public geometryTypeInfo: GeometryTypeInfo;
  private pointToken: TokenType;
  private geometryTypeToken: TokenType | undefined;
  // We want this property for caching alternatives.
  // https://chevrotain.io/docs/guide/performance.html#caching-arrays-of-alternatives
  private geometry_tagged_text_list: AlternativesForGeometry = undefined;
  constructor(opts: {
    geometryTypeInfo: GeometryTypeInfo;
    pointToken: TokenType;
    geometryTypeToken: TokenType | undefined;
  }) {
    super(
      [...allTokensExceptPoints, opts.pointToken],
      // https://chevrotain.io/docs/guide/initialization_performance.html#use-a-smaller-global-maxlookahead
      // Even though we don't expect big performance difference between this value and the default (maxLookAhead=3),
      // we kept it here as a best practice for when designing rules in the future.
      { maxLookahead: 2 },
    );
    this.geometryTypeInfo = opts.geometryTypeInfo;
    this.pointToken = opts.pointToken;
    this.geometryTypeToken = opts.geometryTypeToken;
    this.performSelfAnalysis();
  }

  /**
   * Main entrypoint for the grammar
   */
  public geometry = this.RULE("geometry", () => {
    this.OPTION1(() => this.CONSUME1(WS));
    const crs = this.OPTION(() => this.SUBRULE(this.crs_def));
    const geometry = this.SUBRULE(this.geometry_tagged_text);
    this.OPTION2(() => this.CONSUME2(WS));
    return { ...geometry, crs: crs || DEFAULT_CRS } as Geometry;
  });

  /**
   * Crs, as defined in GeoSparql spec.
   */
  private crs_def = this.RULE("crs_def", () => {
    const crs = this.CONSUME(Crs_Uri);
    this.MANY(() => this.CONSUME(WS));
    let returnValue = "";
    this.ACTION(() => (returnValue = crs.image.slice(1, crs.image.length - 1)));
    return returnValue;
  });

  private point = this.RULE("point", () => {
    // Due to our lexing order, a comma may match when it's leading in a numeric value.
    // To adjust for this, we're including that here. Deviating from the exact grammar notation here,
    // but this is a consequence of our lexing changes wrt the point token
    const optionalLeadingComma = this.OPTION(() => this.CONSUME(Comma))?.image || "";
    const pointString = this.CONSUME(this.pointToken).image;

    return this.ACTION(() => {
      // Code inside `ACTION` will not be executed during the grammar recording phase.
      return (optionalLeadingComma + pointString)
        .trim()
        .split(/[\s]+/)
        .map((s) => {
          const number = Number(s.replaceAll(",", "."));
          if (isNaN(number)) throw new RangeError("Invalid coordinate " + s);
          return number;
        }) as Coordinates;
    });
  });

  private geometry_tagged_text = this.RULE("geometry_tagged_text", () => {
    // https://chevrotain.io/docs/guide/performance.html#caching-arrays-of-alternatives
    return this.OR(
      this.geometry_tagged_text_list ??
        (this.geometry_tagged_text_list = [
          { ALT: () => this.SUBRULE(this.point_tagged_text) },
          { ALT: () => this.SUBRULE(this.multipolygon_tagged_text) },
          { ALT: () => this.SUBRULE(this.linestring_tagged_text) },
          { ALT: () => this.SUBRULE(this.polygon_tagged_text) },
          { ALT: () => this.SUBRULE(this.triangle_tagged_text) },
          { ALT: () => this.SUBRULE(this.polyhedralsurface_tagged_text) },
          { ALT: () => this.SUBRULE(this.tin_tagged_text) },
          { ALT: () => this.SUBRULE(this.multipoint_tagged_text) },
          { ALT: () => this.SUBRULE(this.multilinestring_tagged_text) },
          { ALT: () => this.SUBRULE(this.geometrycollection_tagged_text) },
        ]),
    );
  });

  private point_tagged_text = this.RULE("point_tagged_text", () => {
    this.CONSUME(Point_Tag);
    if (this.geometryTypeToken) {
      this.CONSUME(this.geometryTypeToken); // the z/zm/m
    }
    const coordinates = this.SUBRULE(this.point_text);

    return { type: "Point", coordinates, ...this.geometryTypeInfo };
  });
  private linestring_tagged_text = this.RULE("linestring_tagged_text", () => {
    this.CONSUME(Linestring_Tag);
    if (this.geometryTypeToken) this.CONSUME(this.geometryTypeToken); // the z/zm/m
    const coordinates = this.SUBRULE(this.linestring_text);

    return { type: "LineString", coordinates, ...this.geometryTypeInfo };
  });
  private polygon_tagged_text = this.RULE("polygon_tagged_text", () => {
    this.CONSUME(Polygon_Tag);
    if (this.geometryTypeToken) this.CONSUME(this.geometryTypeToken); // the z/zm/m
    const coordinates = this.SUBRULE(this.polygon_text);

    return { type: "Polygon", coordinates, ...this.geometryTypeInfo };
  });
  private polyhedralsurface_tagged_text = this.RULE("polyhedralsurface_tagged_text", () => {
    this.CONSUME(Polyhedralsurface_Tag);
    if (this.geometryTypeToken) this.CONSUME(this.geometryTypeToken); // the z/zm/m
    const coordinates = this.SUBRULE(this.polyhedralsurface_text);

    return { type: "PolyhedralSurface", coordinates, ...this.geometryTypeInfo };
  });
  private triangle_tagged_text = this.RULE("triangle_tagged_text", () => {
    this.CONSUME(Triangle_Tag);
    if (this.geometryTypeToken) this.CONSUME(this.geometryTypeToken); // the z/zm/m
    const coordinates = this.SUBRULE(this.polygon_text);

    return { type: "Triangle", coordinates, ...this.geometryTypeInfo };
  });
  private tin_tagged_text = this.RULE("tin_tagged_text", () => {
    this.CONSUME(Tin_Tag);
    if (this.geometryTypeToken) this.CONSUME(this.geometryTypeToken); // the z/zm/m
    const coordinates = this.SUBRULE(this.polyhedralsurface_text);

    return { type: "Tin", coordinates, ...this.geometryTypeInfo };
  });

  private multipoint_tagged_text = this.RULE("multipoint_tagged_text", () => {
    this.CONSUME(Multipoint_Tag);
    if (this.geometryTypeToken) this.CONSUME(this.geometryTypeToken); // the z/zm/m
    const coordinates = this.SUBRULE(this.multipoint_text);

    return { type: "MultiPoint", coordinates, ...this.geometryTypeInfo };
  });
  private multilinestring_tagged_text = this.RULE("multilinestring_tagged_text", () => {
    this.CONSUME(Multilinestring_Tag);
    if (this.geometryTypeToken) this.CONSUME(this.geometryTypeToken); // the z/zm/m
    const coordinates = this.SUBRULE(this.multilinestring_text);

    return { type: "MultiLineString", coordinates, ...this.geometryTypeInfo };
  });
  private multipolygon_tagged_text = this.RULE("multipolygon_tagged_text", () => {
    this.CONSUME(Multipolygon_Tag);
    if (this.geometryTypeToken) this.CONSUME(this.geometryTypeToken); // the z/zm/m
    const coordinates = this.SUBRULE(this.multipolygon_text);

    return { type: "MultiPolygon", coordinates, ...this.geometryTypeInfo };
  });
  private geometrycollection_tagged_text = this.RULE("geometrycollection_tagged_text", () => {
    this.CONSUME(Geometrycollection_Tag);
    if (this.geometryTypeToken) this.CONSUME(this.geometryTypeToken); // the z/zm/m
    const geometries = this.SUBRULE(this.geometrycollection_text);

    return { type: "GeometryCollection", geometries, ...this.geometryTypeInfo };
  });

  private point_text = this.RULE("point_text", () => {
    return this.OR<Point["coordinates"]>([
      {
        ALT: () => {
          this.CONSUME(Empty_Set);
          return [];
        },
      },
      {
        ALT: () => {
          this.CONSUME(Left_Paren);
          const point = this.SUBRULE(this.point);
          this.CONSUME(Right_Paren);
          return point;
        },
      },
    ]);
  });
  private linestring_text = this.RULE("linestring_text", () => {
    return this.OR<LineString["coordinates"]>([
      {
        ALT: () => {
          this.CONSUME(Empty_Set);
          return [];
        },
      },
      {
        ALT: () => {
          this.CONSUME(Left_Paren);
          let coordinates: LineString["coordinates"] = [];
          coordinates.push(this.SUBRULE(this.point));
          //https://chevrotain.io/docs/guide/performance.html#minor-runtime-optimizations
          // We replaced AT_LEAST_ONE_SEP with MANY.
          this.MANY(() => {
            this.CONSUME(Comma);
            coordinates.push(this.SUBRULE1(this.point));
          });
          this.CONSUME(Right_Paren);
          return coordinates;
        },
      },
    ]);
  });
  private polygon_text = this.RULE("polygon_text", () => {
    return this.OR<Polygon["coordinates"]>([
      {
        ALT: () => {
          this.CONSUME(Empty_Set);
          return [];
        },
      },
      {
        ALT: () => {
          this.CONSUME(Left_Paren);
          let linestrings: Polygon["coordinates"] = [];
          linestrings.push(this.SUBRULE(this.linestring_text));
          //https://chevrotain.io/docs/guide/performance.html#minor-runtime-optimizations
          // We replaced AT_LEAST_ONE_SEP with MANY.
          this.MANY(() => {
            this.CONSUME(Comma);
            linestrings.push(this.SUBRULE1(this.linestring_text));
          });
          this.CONSUME(Right_Paren);
          return linestrings;
        },
      },
    ]);
  });
  private polyhedralsurface_text = this.RULE("polyhedralsurface_text", () => {
    return this.OR<PolyhedralSurface["coordinates"]>([
      {
        ALT: () => {
          this.CONSUME(Empty_Set);
          return [];
        },
      },
      {
        ALT: () => {
          this.CONSUME(Left_Paren);
          let polygons: PolyhedralSurface["coordinates"] = [];
          polygons.push(this.SUBRULE(this.polygon_text));

          this.MANY(() => {
            this.CONSUME(Comma);
            polygons.push(this.SUBRULE1(this.polygon_text));
          });
          this.CONSUME(Right_Paren);
          return polygons;
        },
      },
    ]);
  });
  private multipoint_text = this.RULE("multipoint_text", () => {
    return this.OR<MultiPoint["coordinates"]>([
      {
        ALT: () => {
          this.CONSUME(Empty_Set);
          return [];
        },
      },
      {
        ALT: () => {
          this.CONSUME(Left_Paren);
          let points: MultiPoint["coordinates"] = [];
          const point = this.OR1([
            {
              ALT: () => this.SUBRULE1(this.point_text),
            },
            {
              ALT: () => this.SUBRULE1(this.point),
            },
          ]);
          // }
          if (point.length !== 0) points.push(point);
          this.MANY(() => {
            this.CONSUME(Comma);
            const point = this.OR2([
              {
                ALT: () => this.SUBRULE2(this.point_text),
              },
              {
                ALT: () => this.SUBRULE2(this.point),
              },
            ]);
            if (point.length !== 0) points.push(point);
          });
          this.CONSUME(Right_Paren);
          return points;
        },
      },
    ]);
  });
  private multilinestring_text = this.RULE("multilinestring_text", () => {
    return this.OR<MultiLineString["coordinates"]>([
      {
        ALT: () => {
          this.CONSUME(Empty_Set);
          return [];
        },
      },
      {
        ALT: () => {
          this.CONSUME(Left_Paren);
          let lines: MultiLineString["coordinates"] = [];
          lines.push(this.SUBRULE(this.linestring_text));
          this.MANY(() => {
            this.CONSUME(Comma);
            lines.push(this.SUBRULE1(this.linestring_text));
          });
          this.CONSUME(Right_Paren);
          return lines;
        },
      },
    ]);
  });
  private multipolygon_text = this.RULE("multipolygon_text", () => {
    return this.OR<MultiPolygon["coordinates"]>([
      {
        ALT: () => {
          this.CONSUME(Empty_Set);
          return [];
        },
      },
      {
        ALT: () => {
          this.CONSUME(Left_Paren);
          let multipolygons: MultiPolygon["coordinates"] = [];
          multipolygons.push(this.SUBRULE2(this.polygon_text));
          this.MANY({
            DEF: () => {
              this.CONSUME(Comma);
              multipolygons.push(this.SUBRULE(this.polygon_text));
            },
          });
          this.CONSUME(Right_Paren);
          return multipolygons;
        },
      },
    ]);
  });
  private geometrycollection_text = this.RULE("geometrycollection_text", () => {
    return this.OR<GeometryCollection["geometries"]>([
      {
        ALT: () => {
          this.CONSUME(Empty_Set);
          return [];
        },
      },
      {
        ALT: () => {
          this.CONSUME(Left_Paren);
          let geometries: GeometryCollection["geometries"] = [];
          geometries.push(this.SUBRULE(this.geometry_tagged_text));
          this.MANY(() => {
            this.CONSUME(Comma);
            geometries.push(this.SUBRULE1(this.geometry_tagged_text));
          });
          this.CONSUME(Right_Paren);
          return geometries;
        },
      },
    ]);
  });

  /**
   * 7.2.1: BNF Productions for Three-Dimension Geometry WKT
   */
}
function getParserAndLexer(geometryType: GeometryType) {
  let pointToken: TokenType;
  let geometryTypeInfo: GeometryTypeInfo;
  let geometryTypeToken: TokenType | undefined;
  switch (geometryType) {
    case "z":
      pointToken = Point_3;
      geometryTypeInfo = { hasZ: true };
      geometryTypeToken = Z;
      break;
    case "m":
      pointToken = Point_3;
      geometryTypeInfo = { hasMeasurement: true };
      geometryTypeToken = M;
      break;
    case "zm":
      pointToken = Point_4;
      geometryTypeInfo = { hasZ: true, hasMeasurement: true };
      geometryTypeToken = ZM;
      break;
    default:
      pointToken = Point_2;
      geometryTypeInfo = {};
  }
  const tokens = [...allTokensExceptPoints, pointToken];
  const parser = new WktParser({ geometryTypeInfo, pointToken, geometryTypeToken });
  return {
    lexer: new Lexer(tokens, {
      ensureOptimizations: true,
      // not tracking lines for this grammar (not setting this will print a warning)
      positionTracking: "onlyOffset",
    }),
    parser: parser,
  };
}

/**
 * We use different parsers for section 7.2.2 (2d), 7.2.3 (3d), section 7.2.4 (2d with measurement) and section 7.2.5 (3d with measurement)
 * They are all instances of the same grammar, but instantiated slightly different.
 * This means we still follow the spec, without verbosely repeating the grammar rules for each variant.
 *
 * Reasons:
 * - Code quality (not too much repetition)
 * - It allows us to instantiate the lexer with different `point` tokens, depending on the variant we're using
 *   For more context, see the comments at the `POINT_2` token
 */
export const parser_3d_m = getParserAndLexer("zm");
export const parser_3d = getParserAndLexer("z");
export const parser_m = getParserAndLexer("m");
export const parser_2d = getParserAndLexer(undefined);

/**
 * Get the correct parser for a lexical value. Doing a simple regex to decide which one to choose
 * It's definitely not pretty, but allows us to re-use the same grammar for slightly different parser types,
 * and exploit being able to use slightly different lexers
 */
export function getParserForLexicalValue(lexicalValue: string) {
  if (lexicalValue.match(/[ \t]*zm[ \t]*(empty|\()/i)) return parser_3d_m;
  if (lexicalValue.match(/[ \t]*m[ \t]*(empty|\()/i)) return parser_m;
  if (lexicalValue.match(/[ \t]*z[ \t]*(empty|\()/i)) return parser_3d;
  return parser_2d;
}

export type Geometry<Crs extends string = string> =
  | Point<Crs>
  | LineString<Crs>
  | Polygon<Crs>
  | Triangle<Crs>
  | PolyhedralSurface<Crs>
  | Tin<Crs>
  | MultiPoint<Crs>
  | MultiPolygon<Crs>
  | MultiLineString<Crs>
  | GeometryCollection<Crs>;
// Geometries without CRS can occur in geometry collections
export type GeometryWithoutCrs =
  | Omit<Point, "crs">
  | Omit<LineString, "crs">
  | Omit<Polygon, "crs">
  | Omit<Triangle, "crs">
  | Omit<PolyhedralSurface, "crs">
  | Omit<Tin, "crs">
  | Omit<MultiPoint, "crs">
  | Omit<MultiPolygon, "crs">
  | Omit<GeometryCollection, "crs">;

/**
 * The coordinates includes x, y, and optionally a z and m (measurement)
 */
export type Coordinates = [number, number, number?, number?];

interface BaseGeometry<Crs extends string> {
  type: string;
  hasMeasurement?: true;
  hasZ?: true;
  crs: Crs;
}

export interface Point<Crs extends string = string> extends BaseGeometry<Crs> {
  type: "Point";
  coordinates: Coordinates | [];
}
export interface LineString<Crs extends string = string> extends BaseGeometry<Crs> {
  type: "LineString";
  coordinates: Array<Coordinates>;
}
export interface Polygon<Crs extends string = string> extends BaseGeometry<Crs> {
  type: "Polygon";
  coordinates: Array<LineString["coordinates"]>;
}
export interface PolyhedralSurface<Crs extends string = string> extends BaseGeometry<Crs> {
  type: "PolyhedralSurface";
  coordinates: Array<Polygon["coordinates"]>;
}
export interface Triangle<Crs extends string = string> extends BaseGeometry<Crs> {
  type: "Triangle";
  coordinates: Array<LineString["coordinates"]>;
}
export interface MultiPoint<Crs extends string = string> extends BaseGeometry<Crs> {
  type: "MultiPoint";
  coordinates: Array<Coordinates>;
}
export interface MultiLineString<Crs extends string = string> extends BaseGeometry<Crs> {
  type: "MultiLineString";
  coordinates: Array<LineString["coordinates"]>;
}
export interface MultiPolygon<Crs extends string = string> extends BaseGeometry<Crs> {
  type: "MultiPolygon";
  coordinates: Array<Polygon["coordinates"]>;
}

export interface Tin<Crs extends string = string> extends BaseGeometry<Crs> {
  type: "Tin";
  coordinates: Array<Polygon["coordinates"]>;
}

export interface GeometryCollection<Crs extends string = string> extends BaseGeometry<Crs> {
  type: "GeometryCollection";
  geometries: Array<GeometryWithoutCrs>;
}

// Using a default (see req10 of that spec) when CRS is undefined
export const DEFAULT_CRS = "http://www.opengis.net/def/crs/OGC/1.3/CRS84";

export function lexicalToValue(lexicalValue: string): Geometry {
  if (lexicalValue.trim().length === 0) {
    // Geo-SPARQP req13: An empty RDFS Literal of type geo:wktLiteral shall be interpreted as an empty geometry.
    return {
      type: "Point",
      coordinates: [],
      crs: DEFAULT_CRS,
    };
  }

  const { parser, lexer } = getParserForLexicalValue(lexicalValue);
  const lexResult = lexer.tokenize(lexicalValue);
  if (lexResult.errors.length)
    throw new StandardParseError({
      lexicalValue: lexicalValue,
      cause: lexResult.errors[0],
    });
  // Setting the input will reset the parser's state
  parser.input = lexResult.tokens;
  try {
    let value;
    try {
      // Parse
      value = parser.geometry();
    } catch (e) {
      // Errors caught here won't be parse errors, but errors thrown during parsing (e.g. validation)
      if (e instanceof RangeError) {
        throw new StandardParseError({
          lexicalValue: lexicalValue,
          offset: parser.errors[0].token.startOffset,
          cause: e,
        });
      }
      throw e;
    }
    if (parser.errors.length) {
      throw new StandardParseError({
        lexicalValue: lexicalValue,
        offset: parser.errors[0].token.startOffset,
        cause: parser.errors[0],
      });
    }
    return value;
  } finally {
    parser.reset();
  }
}

function toCoordinateString(coordinates: Array<any>): string {
  if (!Array.isArray(coordinates[0])) {
    return coordinates.join(" ");
  } else {
    return "(" + coordinates.map(toCoordinateString).join(", ") + ")";
  }
}

export function valueToLexical(geometry: Geometry | GeometryWithoutCrs): string {
  const crs = !("crs" in geometry) || geometry.crs === DEFAULT_CRS ? undefined : `<${geometry.crs}>`;
  let ordinateValue: string | undefined;
  if (geometry.hasMeasurement && geometry.hasZ) {
    ordinateValue = "zm";
  } else if (geometry.hasZ) {
    ordinateValue = "z";
  } else if (geometry.hasMeasurement) {
    ordinateValue = "m";
  }

  // Exiting early for empty coordinates. This way we avoid using optional chaining later on
  if (
    ("coordinates" in geometry && !geometry.coordinates.length) ||
    ("geometries" in geometry && !geometry.geometries.length)
  ) {
    return compact([crs, geometry.type, ordinateValue, "empty"]).join(" ");
  }
  switch (geometry.type) {
    case "Point":
      return compact([crs, geometry.type, ordinateValue, "(" + toCoordinateString(geometry.coordinates) + ")"]).join(
        " ",
      );
    case "MultiPoint":
      return compact([
        crs,
        geometry.type,
        ordinateValue,
        "(" +
          geometry.coordinates.map((coordinates) => {
            return `(${toCoordinateString(coordinates)})`;
          }) +
          ")",
      ]).join(" ");
    case "GeometryCollection":
      return compact([
        crs,
        geometry.type,
        ordinateValue,
        "(" + geometry.geometries.map(valueToLexical).join(", ") + ")",
      ]).join(" ");
    default:
      return compact([crs, geometry.type, ordinateValue, toCoordinateString(geometry.coordinates)]).join(" ");
  }
}

const parseAndSerialize = {
  [DATA_TYPE_NAME_MAPPING.GEO_WKT_LITERAL]: {
    parse: lexicalToValue,
    serialize: valueToLexical,
  },
};

export default parseAndSerialize;
