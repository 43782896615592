import type { IOrAlt, IToken, ParserMethod } from "chevrotain";
import { EmbeddedActionsParser, Lexer } from "chevrotain";
import eachDeep from "deepdash/eachDeep";
import { compact, findLastIndex } from "lodash-es";
import type { AnyTdbDataFactory } from "@triplydb/data-factory/DataFactory";
import { getFactory } from "@triplydb/data-factory/DataFactory";
import type { AnnotationBlock } from "./annotations.ts";
import { assertQueryAnnotations, assertSubSelectAnnotations, dissectAnnotationBlocks } from "./annotations.ts";
import {
  AnnotationError,
  InconsistentValuesLengthError,
  InvalidAggregate,
  SparqlAstError,
  SparqlGrammarError,
  SparqlLexError,
} from "./error.ts";
import type { FuncArity0Name, FuncArity1Name, FuncArity2Name, TokenType } from "./tokens.ts";
import * as T from "./tokens.ts";
import { allTokens, Keywords } from "./tokens.ts";
import type * as Types from "./types.ts";
import { validate } from "./validate.ts";

/**
 * Duplicate of the interface in chevrotain, but now with ARGS required
 */
export interface SubruleMethodOpts<ARGS> {
  /**
   * The arguments to parameterized rules, see:
   * https://github.com/chevrotain/chevrotain/blob/master/examples/parser/parametrized_rules/parametrized.js
   */
  ARGS: ARGS;
  /**
   * A label to be used instead of the subrule's name in the created CST.
   */
  LABEL?: string;
}

/**
 * We used to be able to set some query-engine flags using 'magic prefixes'.
 * E.g. `prefix triply__optimise: <https://triplydb.com/Triply/sparql/id/value/true>
 * This is replaced by annotations, but we're supporting the old notation for a while for backwards compatability
 */
function getLegacyMagicPrefixValue(iriString: string): boolean {
  return iriString.toLowerCase() === "https://triplydb.com/triply/sparql/id/value/true";
}
// Regular expression and replacement strings to escape strings
const escapeSequence = /\\u([a-fA-F0-9]{4})|\\U([a-fA-F0-9]{8})|\\(.)/g;
const escapeReplacements: { [from: string]: string } = {
  "\\": "\\",
  "'": "'",
  '"': '"',
  t: "\t",
  b: "\b",
  n: "\n",
  r: "\r",
  f: "\f",
};

const partialSurrogatesWithoutEndpoint = /[\uD800-\uDBFF]([^\uDC00-\uDFFF]|$)/;
// Translates escape codes in the string into their textual equivalent
function unescapeString(string: string) {
  try {
    string = string.replace(escapeSequence, function (_sequence, unicode4, unicode8, escapedChar) {
      var charCode;
      if (unicode4) {
        charCode = parseInt(unicode4, 16);
        if (isNaN(charCode)) throw new Error("Invalid query, error code 2815"); // can never happen (regex), but helps performance
        return String.fromCharCode(charCode);
      } else if (unicode8) {
        charCode = parseInt(unicode8, 16);
        if (isNaN(charCode)) throw new Error("Invalid query, error code 2961"); // can never happen (regex), but helps performance
        if (charCode < 0xffff) return String.fromCharCode(charCode);
        return String.fromCharCode(0xd800 + ((charCode -= 0x10000) >> 10), 0xdc00 + (charCode & 0x3ff));
      } else {
        var replacement = escapeReplacements[escapedChar];
        if (!replacement) throw new Error("Invalid query, error code 19612");
        return replacement;
      }
    });
  } catch (error) {
    return "";
  }

  // Test for invalid unicode surrogate pairs
  if (partialSurrogatesWithoutEndpoint.exec(string)) {
    throw new SparqlAstError(
      "Invalid unicode codepoint of surrogate pair without corresponding codepoint in " + string,
    );
  }

  return string;
}

type RootProps = "values" | "base" | "prefixes"; // utility type to exclude props
function degroupSingle(group: Types.GroupPattern) {
  return group.type === "group" && group.patterns.length === 1 ? group.patterns[0] : group;
}

type NamedInfo = { iri: Types.IriTerm; named: boolean };
function groupDatasets(fromClauses: Array<NamedInfo>) {
  const defaults: Array<Types.IriTerm> = [];
  const named: Array<Types.IriTerm> = [];
  for (const fromClause of fromClauses) {
    if (fromClause.named) {
      named.push(fromClause.iri);
    } else {
      defaults.push(fromClause.iri);
    }
  }
  return { default: defaults, named };
}

function ensureNoVariables(operations: Types.Quads[]): void {
  for (const operation of operations) {
    if (operation.type === "graph" && operation.name.termType === "Variable") {
      throw new SparqlAstError("Detected illegal variable in GRAPH");
    }
    const triples = "triples" in operation ? operation.triples : operation.patterns[0].triples;
    for (const triple of triples) {
      if (
        triple.subject.termType === "Variable" ||
        ("termType" in triple.predicate && triple.predicate.termType === "Variable") ||
        triple.object.termType === "Variable"
      ) {
        throw new SparqlAstError("Detected illegal variable in BGP");
      }
    }
  }
}
/**
 * Construct a location object. If the input does not provide enough information to construct such an object, then return undefined
 */
function getLocation(location: Partial<Types.LocationContext>): Types.LocationContext | undefined {
  if (!location) return;
  const { startLine, endLine, startColumn, endColumn } = location;
  if (startLine !== undefined && endLine !== undefined && startColumn !== undefined && endColumn !== undefined)
    return { startLine, endLine, startColumn, endColumn };
}

function ensureNoBnodes(operations: Types.Quads[]): void {
  for (const operation of operations) {
    if (operation.type === "bgp") {
      for (const triple of operation.triples) {
        if (triple.subject.termType === "BlankNode" || triple.object.termType === "BlankNode") {
          throw new SparqlAstError("Detected illegal blank node in BGP");
        }
      }
    }
  }
}

/**
 * This interface is only used in this parser. We use this to type operations that are passed to `createOperationTree`
 */
interface PreTreeOperation {
  type: "function";
  function: Types.Function["function"];
  args: Types.Expression[];
}

/**
 * Transforms a (possible) list of functions and arguments into a tree of functions
 */
function createFunctionTree(
  initialExpression: Types.Expression,
  operationList: Array<Types.Expression | PreTreeOperation>,
): Types.Function {
  for (const item of operationList) {
    initialExpression = {
      ...item,
      args: [initialExpression, ...("args" in item ? item.args : [])],
    } as Types.Function;
  }
  return initialExpression as Types.Function;
}

type PartialTriple = Optional<Types.Triple, "subject">;

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

interface CollectionItemPath {
  entity: Types.Term;
  triples: Optional<Types.Triple, "subject">[];
}

interface CollectionItem {
  entity: Types.Term;
  triples: Types.TripleTemplate[];
}

interface ParserContext {
  // Aggregates can only be used in a select/having/order-by clause: https://www.w3.org/TR/sparql11-query/#sparqlGrammar (rule 14)
  // Nested aggregates are also not allowed
  aggregate: "allowed" | "not-allowed";
}

export class SparqlParser extends EmbeddedActionsParser {
  private dataFactory: AnyTdbDataFactory;
  private prefixes: { [key: string]: string } = {};
  private annotations: Array<AnnotationBlock> = [];
  private legacyPaginateAnnotationLines: Array<number> = [];
  private rdf;
  private xsd;
  private base!: string;
  private basePath!: string;
  private baseRoot!: string;
  // assertValidAnnotations will always be set, right before parsing
  private assertValidAnnotations!: boolean;
  public constructor(datafactory: AnyTdbDataFactory) {
    super(allTokens);
    this.dataFactory = datafactory;

    const rdf = this.dataFactory.prefixer("http://www.w3.org/1999/02/22-rdf-syntax-ns#");
    this.rdf = {
      type: rdf("type"),
      first: rdf("first"),
      rest: rdf("rest"),
      nil: rdf("nil"),
    };
    const xsd = this.dataFactory.prefixer("http://www.w3.org/2001/XMLSchema#");
    this.xsd = {
      integer: xsd("integer"),
      decimal: xsd("decimal"),
      double: xsd("double"),
      boolean: xsd("boolean"),
      string: xsd("string"),
    };
    this.performSelfAnalysis();
  }

  public reset() {
    super.reset();
    this.prefixes = {};
    this.dataFactory.resetBnodeCounter();
    this.annotations = [];
    this.legacyPaginateAnnotationLines = [];
  }

  private getQueryAnnotations(beforeLine: number) {
    if (!this.annotations.length) return {};

    // Inclusive, i.e. include this last index
    const endOfQueryAnnotationsIndex =
      findLastIndex(this.annotations, (a) => a.location.endLine < beforeLine) ?? this.annotations.length - 1;
    /**
     * Get all annotation blocks from the prologue part
     */
    const annotationBlocks = this.annotations.splice(0, endOfQueryAnnotationsIndex + 1);
    let queryAnnotations: Types.QueryAnnotations = {};
    for (const annotationBlock of annotationBlocks) {
      /**
       * Validate each block separately, so we have to location info
       */
      if (this.assertValidAnnotations) {
        assertQueryAnnotations(annotationBlock.annotations, annotationBlock.location);
      }
      /**
       * Merge the found blocks into 1 object
       */
      queryAnnotations = { ...queryAnnotations, ...annotationBlock.annotations };
    }
    return queryAnnotations;
  }
  private getSubqueryAnnotations(opts: { afterLine: number; beforeLine: number }) {
    if (!this.annotations.length) return {};
    // Find an annotation between the previous token and before the current token
    const annotationIndex = this.annotations.findIndex(
      (a) => a.location.endLine > opts.afterLine && a.location.endLine < opts.beforeLine,
    );
    if (annotationIndex < 0) return {};
    const annotationBlock = this.annotations[annotationIndex];
    this.annotations.splice(annotationIndex, 1);
    if (this.assertValidAnnotations) {
      assertSubSelectAnnotations(annotationBlock.annotations, annotationBlock.location);
    }
    return annotationBlock.annotations;
  }
  private createNamedNode(iri: string, opts: { location: Types.LocationContext | undefined; surfaceForm: string }) {
    const iriTerm = this.dataFactory.namedNode(iri) as Types.IriTerm;
    iriTerm.surfaceForm = opts.surfaceForm;
    if (opts.location) iriTerm.location = opts.location;
    return iriTerm;
  }
  private createBnode(opts?: { identifier?: string; location?: Types.LocationContext }) {
    let identifier = opts?.identifier;
    let location = opts?.location;
    if (identifier) {
      /**
       * When an explicit bnode identifer is used, we want to prefix the identifier with something
       * This way, we cannot have conflicts between identifiers that we generate and identifiers that are
       * hardcoded in the query
       */
      identifier = `c_` + identifier;
      location = undefined; // location will be inaccurate, as we change the value length
    }
    const bnodeTerm = this.dataFactory.blankNode(identifier) as Types.BlankTerm;
    if (location) bnodeTerm.location = location;
    return bnodeTerm;
  }
  private createVariable(varname: string, opts: { location: Types.LocationContext | undefined; surfaceForm: string }) {
    const varTerm = this.dataFactory.variable(varname) as Types.VariableTerm;
    if (opts.location) varTerm.location = opts.location;
    varTerm.surfaceForm = opts?.surfaceForm;
    return varTerm;
  }
  private createLiteral(
    lexicalValue: string,
    languageOrDatatype: string | Types.IriTerm,
    opts: { location: Types.LocationContext | undefined },
  ) {
    const literalTerm = this.dataFactory.literal(lexicalValue, languageOrDatatype) as Types.LiteralTerm;
    if (opts.location) literalTerm.location = opts.location;
    return literalTerm;
  }
  /**
   * Main entrypoint for the grammar
   */
  public sparql = this.RULE(
    "sparql",
    (opts: {
      baseIri: string;
      prefixes?: Types.Prefixes;
      annotations: Array<AnnotationBlock>;
      legacyPaginateAnnotationLines: Array<number>;
      assertValidAnnotations: boolean;
    }): Types.Query | Types.Update => {
      this.ACTION(() => {
        this._setBase(opts.baseIri);
        this.annotations = opts.annotations;
        this.assertValidAnnotations = opts.assertValidAnnotations;
        this.legacyPaginateAnnotationLines = opts.legacyPaginateAnnotationLines;
        if (opts.prefixes) this.prefixes = opts.prefixes;
      });
      const { base, prefixes } = this.SUBRULE(this.prologue);
      const q = this.OR([{ ALT: () => this.SUBRULE(this.query) }, { ALT: () => this.SUBRULE(this.updateUnit) }]);
      const query = { ...q, base, prefixes };
      this.ACTION(() => {
        const remainingAnnotations = compact(this.annotations);
        if (remainingAnnotations.length) {
          throw new AnnotationError(`Unsupported location of annotation.`, {
            location: remainingAnnotations[0].location,
          });
        }
      });
      return query satisfies Types.SparqlQuery as Types.SparqlQuery;
    },
  );

  /**
   * Some utility rules
   */
  private _optionalDistinctKeyword = this.RULE("_optionalDistinctKeyword", () => {
    return !!this.OPTION(() => this.CONSUME(Keywords.Distinct));
  });
  private _optionalSilentKeyword = this.RULE("_optionalSilentKeyword", () => {
    return !!this.OPTION(() => this.CONSUME(Keywords.Silent));
  });
  private _createList<C extends CollectionItem | CollectionItemPath>(objects: C[]): C {
    const list = this.createBnode();
    let head: Types.Term = list;
    const listItems: Array<Types.Term> = [];
    const listTriples: C["triples"] = Array(listItems.length * 2);
    const triples: C["triples"] = [];
    for (const object of objects) {
      listItems.push(object.entity);
      triples.push(...(object.triples as any[]));
    }

    // Build an RDF list out of the items
    for (let i = 0, j = 0, l = listItems.length; i < l; ) {
      listTriples[j++] = { subject: head, predicate: this.rdf.first, object: listItems[i] };
      const bnode = this.createBnode();
      listTriples[j++] = {
        subject: head,
        predicate: this.rdf.rest,
        object: (head = ++i < l ? bnode : this.rdf.nil),
      };
    }

    // Return the list's identifier, its triples, and the triples associated with its items
    return { entity: list, triples: [...listTriples, ...triples] } satisfies CollectionItem | CollectionItemPath as C;
  }

  private _setBase(base: string) {
    this.base = base;
    this.basePath = base.replace(/[^\/:]*$/, "") ?? "";
    this.baseRoot = base.match(/^(?:[a-z]+:\/*)?[^\/]*/)?.[0] ?? "";
  }

  private _resolveIri(iri: string) {
    if (iri[0] === "<") iri = iri.slice(1, -1);
    // Return absolute IRIs unmodified
    if (/^[a-z]+:/i.test(iri)) return iri;
    if (!this.base) throw new SparqlAstError(`Cannot resolve relative IRI <${iri}> because no base IRI was set.`);
    switch (iri[0]) {
      // An empty relative IRI indicates the base IRI
      case undefined:
        return this.base;
      // Resolve relative fragment IRIs against the base IRI
      case "#":
        return this.base + iri;
      // Resolve relative query string IRIs by replacing the query string
      case "?":
        return this.base.replace(/(?:\?.*)?$/, iri);
      // Resolve root relative IRIs at the root of the base IRI
      case "/":
        return this.baseRoot + iri;
      // Resolve all other IRIs at the base IRI's path
      default:
        return this.basePath + iri;
    }
  }
  /**
   * Overwriting OR for better type narrowing, improving the returntype of such calls
   */
  public OR<A extends Array<IOrAlt<any>>>(alt: A) {
    return super.OR(alt) as ReturnType<A[number]["ALT"]>;
  }
  public OR1<A extends Array<IOrAlt<any>>>(alt: A) {
    return super.OR1(alt) as ReturnType<A[number]["ALT"]>;
  }
  public OR2<A extends Array<IOrAlt<any>>>(alt: A) {
    return super.OR2(alt) as ReturnType<A[number]["ALT"]>;
  }
  public OR3<A extends Array<IOrAlt<any>>>(alt: A) {
    return super.OR3(alt) as ReturnType<A[number]["ALT"]>;
  }
  public OR4<A extends Array<IOrAlt<any>>>(alt: A) {
    return super.OR4(alt) as ReturnType<A[number]["ALT"]>;
  }
  /**
   * Overwriting CONSUME for better type narrowing (specifically the tokentype const string)
   */
  public CONSUME<S extends TokenType<string>>(token: S) {
    return super.CONSUME(token) as Omit<IToken, "tokenType"> & { tokenType: S };
  }
  public CONSUME1<S extends TokenType<string>>(token: S) {
    return super.CONSUME1(token) as Omit<IToken, "tokenType"> & { tokenType: S };
  }
  public CONSUME2<S extends TokenType<string>>(token: S) {
    return super.CONSUME2(token) as Omit<IToken, "tokenType"> & { tokenType: S };
  }
  public CONSUME3<S extends TokenType<string>>(token: S) {
    return super.CONSUME3(token) as Omit<IToken, "tokenType"> & { tokenType: S };
  }
  public CONSUME4<S extends TokenType<string>>(token: S) {
    return super.CONSUME4(token) as Omit<IToken, "tokenType"> & { tokenType: S };
  }
  public CONSUME5<S extends TokenType<string>>(token: S) {
    return super.CONSUME5(token) as Omit<IToken, "tokenType"> & { tokenType: S };
  }
  public CONSUME6<S extends TokenType<string>>(token: S) {
    return super.CONSUME6(token) as Omit<IToken, "tokenType"> & { tokenType: S };
  }
  public CONSUME7<S extends TokenType<string>>(token: S) {
    return super.CONSUME7(token) as Omit<IToken, "tokenType"> & { tokenType: S };
  }

  /**
   *
   * Overwriting SUBRUL for better type narrowing, forcing callers to pass args when the
   * reference subrule uses those
   *
   */
  public SUBRULE<ARGS extends unknown[], R>(
    ruleToCall: ParserMethod<ARGS, R>,
    ...args: ARGS[0] extends undefined ? [undefined?] : [SubruleMethodOpts<ARGS>]
  ): R {
    return super.SUBRULE(ruleToCall, ...args);
  }
  public SUBRULE1<ARGS extends unknown[], R>(
    ruleToCall: ParserMethod<ARGS, R>,
    ...args: ARGS[0] extends undefined ? [undefined?] : [SubruleMethodOpts<ARGS>]
  ): R {
    return super.SUBRULE1(ruleToCall, ...args);
  }
  public SUBRULE2<ARGS extends unknown[], R>(
    ruleToCall: ParserMethod<ARGS, R>,
    ...args: ARGS[0] extends undefined ? [undefined?] : [SubruleMethodOpts<ARGS>]
  ): R {
    return super.SUBRULE2(ruleToCall, ...args);
  }
  public SUBRULE3<ARGS extends unknown[], R>(
    ruleToCall: ParserMethod<ARGS, R>,
    ...args: ARGS[0] extends undefined ? [undefined?] : [SubruleMethodOpts<ARGS>]
  ): R {
    return super.SUBRULE3(ruleToCall, ...args);
  }
  public SUBRULE4<ARGS extends unknown[], R>(
    ruleToCall: ParserMethod<ARGS, R>,
    ...args: ARGS[0] extends undefined ? [undefined?] : [SubruleMethodOpts<ARGS>]
  ): R {
    return super.SUBRULE4(ruleToCall, ...args);
  }
  public SUBRULE5<ARGS extends unknown[], R>(
    ruleToCall: ParserMethod<ARGS, R>,
    ...args: ARGS[0] extends undefined ? [undefined?] : [SubruleMethodOpts<ARGS>]
  ): R {
    return super.SUBRULE5(ruleToCall, ...args);
  }
  public SUBRULE6<ARGS extends unknown[], R>(
    ruleToCall: ParserMethod<ARGS, R>,
    ...args: ARGS[0] extends undefined ? [undefined?] : [SubruleMethodOpts<ARGS>]
  ): R {
    return super.SUBRULE6(ruleToCall, ...args);
  }
  public SUBRULE7<ARGS extends unknown[], R>(
    ruleToCall: ParserMethod<ARGS, R>,
    ...args: ARGS[0] extends undefined ? [undefined?] : [SubruleMethodOpts<ARGS>]
  ): R {
    return super.SUBRULE7(ruleToCall, ...args);
  }
  /**
   * [2]  	Query ::= Prologue
   * ( SelectQuery | ConstructQuery | DescribeQuery | AskQuery )
   * ValuesClause
   * Prologue part is done in top-level query, to avoid ambiguity between select and update queries
   */
  public query = this.RULE("query", () => {
    const query = this.OR([
      { ALT: () => this.SUBRULE(this.selectQuery) },
      { ALT: () => this.SUBRULE(this.describeQuery) },
      { ALT: () => this.SUBRULE(this.askQuery) },
      { ALT: () => this.SUBRULE(this.constructQuery) },
    ]);
    const values = this.SUBRULE(this.valuesClause);

    /**
     * Check whether there are some legacy 'magic-prefix' annotations
     */
    if (this.prefixes["triply_optimize"] && !("optimize" in query.annotations)) {
      query.annotations.optimize = getLegacyMagicPrefixValue(this.prefixes["triply_optimize"]);
    }
    if (this.prefixes["triply_debugonly"] && !("debug" in query.annotations)) {
      const debug = getLegacyMagicPrefixValue(this.prefixes["triply_debugonly"]);
      if (debug) query.annotations.debug = "before-execution";
    }
    if (this.prefixes["triply_debugoncompletion"] && !("debug" in query.annotations)) {
      const debug = getLegacyMagicPrefixValue(this.prefixes["triply_debugoncompletion"]);
      if (debug) query.annotations.debug = "after-execution";
    }
    return { ...query, values };
  });

  /**
   * [4]  	Prologue ::= ( BaseDecl | PrefixDecl )*
   */
  public prologue = this.RULE("prologue", () => {
    let base: string | null = null;

    this.MANY(() => {
      this.OR([
        {
          ALT: () => {
            base = this.SUBRULE(this.baseDecl);
            this.ACTION(() => {
              if (base) {
                this._setBase(base);
              }
            });
          },
        },
        {
          ALT: () => {
            const result = this.SUBRULE(this.prefixDecl);
            this.ACTION(() => {
              this.prefixes[result.prefixLabel] = this._resolveIri(result.prefixIri);
            });
          },
        },
      ]);
    });

    return { base, prefixes: this.prefixes };
  });
  /**
   * [5]  	BaseDecl ::= 'BASE' IRIREF
   */
  private baseDecl = this.RULE("baseDecl", (): string => {
    this.CONSUME(Keywords.Base);
    const iri = this.CONSUME(T.IriRef);
    return iri.image.slice(1, -1);
  });
  /**
   * [6]  	PrefixDecl ::= 'PREFIX' PNAME_NS IRIREF
   */
  private prefixDecl = this.RULE("prefixDecl", () => {
    this.CONSUME(Keywords.Prefix);
    const label = this.CONSUME(T.Pname_Ns);
    const iri = this.CONSUME(T.IriRef);
    return {
      prefixLabel: label.image.slice(0, -1),
      prefixIri: iri.image.slice(1, -1),
    };
  });
  /**
   * [7]  	SelectQuery ::= SelectClause DatasetClause* WhereClause SolutionModifier
   */
  private selectQuery = this.RULE("selectQuery", () => {
    const selectClause = this.SUBRULE(this.selectClause);
    const datasetClauses: Array<NamedInfo> = [];
    this.MANY(() => {
      datasetClauses.push(this.SUBRULE(this.datasetClause));
    });
    const where = this.SUBRULE(this.whereClause);
    const solutionModifier = this.SUBRULE(this.solutionModifier);
    return {
      type: "query",
      queryType: "select",
      ...selectClause,
      ...solutionModifier,
      from: groupDatasets(datasetClauses),
      ...where,
    } satisfies Omit<Types.SelectQuery, RootProps> as Omit<Types.SelectQuery, RootProps>;
  });

  /**
   * [8]  	SubSelect	  ::=  	SelectClause WhereClause SolutionModifier ValuesClause
   */
  private subSelect = this.RULE("subSelect", (): Types.SubSelect => {
    const selectClause = this.SUBRULE(this.subSelectClause);
    const where = this.SUBRULE(this.whereClause) satisfies { where: Types.Pattern[] };
    const startLineLegacyPaginateAnnotation = this.LA(0).endLine!; // previous token
    const solutionModifier = this.SUBRULE(this.solutionModifier);
    const values = this.SUBRULE(this.valuesClause);
    const endLineLegacyPaginateAnnotation = this.LA(1).endLine!; // next token, i.e. line of closing bracket
    // Rewrite the legacy `# paginate` notation into a query annotation
    if (
      this.legacyPaginateAnnotationLines.some(
        (line) => line >= startLineLegacyPaginateAnnotation && line <= endLineLegacyPaginateAnnotation,
      )
    ) {
      selectClause.annotations.paginate = true;
    }
    const result = {
      type: "query",
      queryType: "subselect",
      ...selectClause,
      ...solutionModifier,
      ...where,
      values,
    } satisfies Types.SubSelect;
    return result;
  });
  /**
   * [9]  	SelectClause ::= 'SELECT' ( 'DISTINCT' | 'REDUCED' )? ( ( Var | ( '(' Expression 'AS' Var ')' ) )+ | '*' )
   */
  private subSelectClause = this.RULE("subSelectClause", () => {
    const previousTokenOnLine = this.LA(0).endLine!;
    const beforeLine = this.CONSUME(Keywords.Select).startLine!;
    const annotations = this.getSubqueryAnnotations({ afterLine: previousTokenOnLine, beforeLine });
    return { annotations, ...this.SUBRULE(this._selectClause) };
  });
  private selectClause = this.RULE("selectClause", () => {
    const startLine = this.CONSUME(Keywords.Select).startLine!;
    const annotations = this.getQueryAnnotations(startLine);
    return { annotations, ...this.SUBRULE(this._selectClause) };
  });
  private _selectClause = this.RULE("_selectClause", () => {
    let reduced = false;
    let distinct = false;
    this.OPTION(() => {
      this.OR1([
        {
          ALT: () => {
            this.CONSUME(Keywords.Distinct);
            distinct = true;
          },
        },
        {
          ALT: () => {
            this.CONSUME(Keywords.Reduced);
            reduced = true;
          },
        },
      ]);
    });
    /**
     * (
     *    (
     *      Var
     *      | ( '(' Expression 'AS' Var ')' )
     *    )+
     *    | '*'
     * )
     */
    const variables = this.OR2([
      {
        ALT: () => {
          const variables: Types.Variable[] = [];
          this.AT_LEAST_ONE(() => {
            this.OR3([
              {
                ALT: () => {
                  variables.push(this.SUBRULE1(this.var));
                },
              },
              {
                ALT: () => {
                  this.CONSUME(T.LeftParen);
                  const expression = this.SUBRULE(this.expression, { ARGS: [{ aggregate: "allowed" }] });
                  this.CONSUME(Keywords.As);
                  const variable = this.SUBRULE2(this.var);
                  this.CONSUME(T.RightParen);
                  variables.push({
                    variable,
                    expression,
                  });
                },
              },
            ]);
          });
          return variables;
        },
      },
      {
        ALT: () => {
          const token = this.CONSUME(T.Wildcard);
          return { type: "wildcard", location: getLocation(token) } as const;
        },
      },
    ]);
    return {
      variables,
      reduced,
      distinct,
    };
  });

  /**
   * [10]  	ConstructQuery	  ::=  	'CONSTRUCT' ( ConstructTemplate DatasetClause* WhereClause SolutionModifier | DatasetClause* 'WHERE' '{' TriplesTemplate? '}' SolutionModifier )
   */
  private constructQuery = this.RULE("constructQuery", () => {
    const startLine = this.CONSUME(Keywords.Construct).startLine!;
    const annotations = this.getQueryAnnotations(startLine);
    return this.OR([
      {
        //  ConstructTemplate DatasetClause* WhereClause SolutionModifier
        ALT: () => {
          const template = this.SUBRULE(this.constructTemplate);
          const datasetClauses: Array<NamedInfo> = [];
          this.MANY1(() => {
            datasetClauses.push(this.SUBRULE1(this.datasetClause));
          });
          const where = this.SUBRULE1(this.whereClause);
          const solutionModifier = this.SUBRULE1(this.solutionModifier);
          return this.ACTION(() => {
            return {
              type: "query",
              queryType: "construct",
              template,
              annotations,
              ...where,
              from: groupDatasets(datasetClauses),
              ...solutionModifier,
            } satisfies Omit<Types.ConstructQuery, RootProps> as Omit<Types.ConstructQuery, RootProps>;
          });
        },
      },
      {
        // DatasetClause* 'WHERE' '{' TriplesTemplate? '}' SolutionModifier
        ALT: () => {
          const datasetClauses: Array<NamedInfo> = [];
          this.MANY2(() => {
            datasetClauses.push(this.SUBRULE2(this.datasetClause));
          });
          this.CONSUME(Keywords.Where);
          this.CONSUME(T.LeftCurl);
          const template = this.OPTION(() => this.SUBRULE2(this.triplesTemplate));
          this.CONSUME(T.RightCurl);
          const solutionModifier = this.SUBRULE2(this.solutionModifier);
          return this.ACTION(() => {
            return {
              type: "query",
              queryType: "construct",
              template: template ?? [],
              annotations,
              where: [{ type: "bgp", triples: template ?? [] }],
              from: groupDatasets(datasetClauses),
              ...solutionModifier,
            } satisfies Omit<Types.ConstructQuery, RootProps> as Omit<Types.ConstructQuery, RootProps>;
          });
        },
      },
    ]);
  });
  /**
   * [11]  	DescribeQuery	  ::=  	'DESCRIBE' ( VarOrIri+ | '*' ) DatasetClause* WhereClause? SolutionModifier
   */
  private describeQuery = this.RULE("describeQuery", () => {
    const startLine = this.CONSUME(Keywords.Describe).startLine!;
    const annotations = this.getQueryAnnotations(startLine);
    const variables = this.OR([
      {
        ALT: () => {
          const vars: Array<Types.VariableTerm | Types.IriTerm> = [];
          this.AT_LEAST_ONE(() => {
            vars.push(this.SUBRULE(this.varOrIri));
          });
          return vars;
        },
      },
      {
        ALT: () => {
          const token = this.CONSUME(T.Wildcard);
          return { type: "wildcard", location: getLocation(token) } as const;
        },
      },
    ]);
    const datasetClauses: Array<NamedInfo> = [];
    this.MANY(() => {
      datasetClauses.push(this.SUBRULE(this.datasetClause));
    });
    const where = this.OPTION(() => this.SUBRULE(this.whereClause)) ?? { where: [] };
    const solutionModifier = this.SUBRULE(this.solutionModifier);
    return {
      type: "query",
      queryType: "describe",
      variables,
      annotations,
      from: groupDatasets(datasetClauses),
      ...where,
      ...solutionModifier,
    } satisfies Omit<Types.DescribeQuery, RootProps> as Omit<Types.DescribeQuery, RootProps>;
  });
  /**
   * [12]  	AskQuery	  ::=  	'ASK' DatasetClause* WhereClause SolutionModifier
   */
  private askQuery = this.RULE("askQuery", () => {
    const startLine = this.CONSUME(Keywords.Ask).startLine!;
    const annotations = this.getQueryAnnotations(startLine);
    const datasetClauses: Array<NamedInfo> = [];
    this.MANY(() => {
      datasetClauses.push(this.SUBRULE(this.datasetClause));
    });
    const where = this.SUBRULE(this.whereClause);
    const solutionModifier = this.SUBRULE(this.solutionModifier);
    return {
      type: "query",
      queryType: "ask",
      annotations,
      from: groupDatasets(datasetClauses),
      ...where,
      ...solutionModifier,
    } satisfies Omit<Types.AskQuery, RootProps> as Omit<Types.AskQuery, RootProps>;
  });
  /**
   * [13]  	DatasetClause	  ::=  	'FROM' ( DefaultGraphClause | NamedGraphClause )
   */
  private datasetClause = this.RULE("datasetClause", (): NamedInfo => {
    this.CONSUME(Keywords.From);
    return this.OR([
      {
        ALT: () => {
          const iri = this.SUBRULE(this.defaultGraphClause);
          return { iri, named: false } satisfies { iri: Types.IriTerm; named: false };
        },
      },
      {
        ALT: () => this.SUBRULE(this.namedGraphClause),
      },
    ]);
  });
  /**
   * [15]  	NamedGraphClause	  ::=  	'NAMED' SourceSelector
   */
  private namedGraphClause = this.RULE("namedGraphClause", (): NamedInfo => {
    this.CONSUME(Keywords.Named);
    const iri = this.SUBRULE(this.sourceSelector);
    return { iri, named: true } satisfies { iri: Types.IriTerm; named: true };
  });

  /**
   * [17]  	WhereClause ::= 'WHERE'? GroupGraphPattern
   */
  private whereClause = this.RULE("whereClause", (): { where: Types.Pattern[] } => {
    this.OPTION(() => this.CONSUME(Keywords.Where));
    return { where: this.SUBRULE(this.groupGraphPattern).patterns } satisfies { where: Types.Pattern[] };
  });

  /**
   * [18]  	SolutionModifier ::= GroupClause? HavingClause? OrderClause? LimitOffsetClauses?
   */
  private solutionModifier = this.RULE("solutionModifier", (): Types.SolutionModifier => {
    const group = this.OPTION1(() => this.SUBRULE(this.groupClause)) ?? [];
    const having = this.OPTION2(() => this.SUBRULE(this.havingClause)) ?? [];
    const order = this.OPTION3(() => this.SUBRULE(this.orderClause)) ?? [];
    const limitAndOffset = this.OPTION4(() => this.SUBRULE(this.limitOffsetClauses));
    return {
      group,
      having,
      order,
      limit: limitAndOffset?.limit ?? null,
      offset: limitAndOffset?.offset ?? null,
    };
  });

  /**
   * [19]  	GroupClause ::= 'GROUP' 'BY' GroupCondition+
   */
  private groupClause = this.RULE("groupClause", () => {
    this.CONSUME(Keywords.Group);
    this.CONSUME(Keywords.By);
    const groups: Types.Grouping[] = [];
    this.AT_LEAST_ONE(() => groups.push(this.SUBRULE(this.groupCondition)));
    return groups;
  });
  /**
   * [20]  	GroupCondition ::= BuiltInCall | FunctionCall | '(' Expression ( 'AS' Var )? ')' | Var
   */
  private groupCondition = this.RULE("groupCondition", () => {
    return this.OR([
      {
        ALT: () =>
          ({
            expression: this.SUBRULE(this.builtInCall, { ARGS: [{ aggregate: "not-allowed" }] }),
            variable: null,
          }) satisfies Types.Grouping,
      },
      {
        ALT: () =>
          ({
            expression: this.SUBRULE(this.functionCall, { ARGS: [{ aggregate: "not-allowed" }] }),
            variable: null,
          }) satisfies Types.Grouping,
      },
      {
        // '(' Expression ( 'AS' Var )? ')'
        ALT: () => {
          this.CONSUME(T.LeftParen);
          const expression = this.SUBRULE(this.expression, { ARGS: [{ aggregate: "not-allowed" }] });
          const variable = this.OPTION(() => {
            this.CONSUME(Keywords.As);
            return this.SUBRULE1(this.var);
          });
          this.CONSUME(T.RightParen);
          if (variable) return { expression, variable } satisfies Types.Grouping;
          return { expression, variable: null } satisfies Types.Grouping;
        },
      },
      {
        ALT: () => ({ expression: this.SUBRULE2(this.var), variable: null }) satisfies Types.Grouping,
      },
    ]);
  });
  /**
   * [21]  	HavingClause ::= 'HAVING' HavingCondition+
   */
  private havingClause = this.RULE("havingClause", () => {
    this.CONSUME(Keywords.Having);
    const constraints: Array<Types.Expression> = [];
    this.AT_LEAST_ONE(() => {
      constraints.push(this.SUBRULE(this.havingCondition, { ARGS: [{ aggregate: "allowed" }] }));
    });
    return constraints;
  });

  /**
   * [23]  	OrderClause ::= 'ORDER' 'BY' OrderCondition+
   */
  private orderClause = this.RULE("orderClause", () => {
    this.CONSUME(Keywords.Order);
    this.CONSUME(Keywords.By);
    const ordering: Types.Ordering[] = [];
    this.AT_LEAST_ONE(() => {
      ordering.push(this.SUBRULE(this.orderCondition));
    });
    return ordering;
  });
  /**
   * [24]  	OrderCondition ::= ( ( 'ASC' | 'DESC' ) BrackettedExpression ) | ( Constraint | Var )
   */
  private orderCondition = this.RULE("orderCondition", () => {
    return this.OR1([
      {
        ALT: () => {
          const descending = this.OR2([
            {
              ALT: () => this.CONSUME(Keywords.Asc) && false,
            },
            {
              ALT: () => this.CONSUME(Keywords.Desc) && true,
            },
          ]);
          const expression = this.SUBRULE(this.brackettedExpression, { ARGS: [{ aggregate: "allowed" }] });
          return {
            expression,
            descending,
          } satisfies Types.Ordering;
        },
      },
      {
        ALT: () => {
          return this.OR3([
            {
              ALT: () => {
                const expression = this.SUBRULE(this.constraint, { ARGS: [{ aggregate: "allowed" }] });
                return { expression, descending: false } satisfies Types.Ordering;
              },
            },
            {
              ALT: () => {
                const expression = this.SUBRULE(this.var);
                return { expression, descending: false } satisfies Types.Ordering;
              },
            },
          ]);
        },
      },
    ]);
  });
  /**
   * [25]  	LimitOffsetClauses ::= LimitClause OffsetClause? | OffsetClause LimitClause?
   */
  private limitOffsetClauses = this.RULE("limitOffsetClauses", () => {
    return this.OR([
      {
        ALT: () => {
          const limit = this.SUBRULE1(this.limitClause);
          const offset = this.OPTION1(() => this.SUBRULE1(this.offsetClause));
          return { limit, offset } satisfies Optional<Pick<Types.SelectQuery, "limit" | "offset">, "offset">;
        },
      },
      {
        ALT: () => {
          const offset = this.SUBRULE2(this.offsetClause);
          const limit = this.OPTION2(() => this.SUBRULE2(this.limitClause));
          return { limit: limit ?? null, offset } satisfies Optional<
            Pick<Types.SelectQuery, "limit" | "offset">,
            "limit"
          >;
        },
      },
    ]);
  });
  /**
   * [26]  	LimitClause ::= 'LIMIT' INTEGER
   */
  private limitClause = this.RULE("limitClause", () => {
    this.CONSUME(Keywords.Limit);
    const int = this.CONSUME(T.Integer);
    return +int.image;
  });
  /**
   * [27]  	OffsetClause ::= 'OFFSET' INTEGER
   */
  private offsetClause = this.RULE("offsetClause", () => {
    this.CONSUME(Keywords.Offset);
    const int = this.CONSUME(T.Integer);
    return +int.image;
  });
  /**
   * [28]  	ValuesClause	  ::=  	( 'VALUES' DataBlock )?
   */
  private valuesClause = this.RULE("valuesClause", (): Types.ValuesPattern["values"] | null => {
    return (
      this.OPTION(() => {
        this.CONSUME(Keywords.Values);
        const values = this.SUBRULE(this.dataBlock);
        return values satisfies Types.ValuesPattern["values"];
      }) || null
    );
  });

  /**
   * [29]  	Update	  ::=  	Prologue ( Update1 ( ';' Update )? )?
   * Prologue part is done in top-level query, to avoid ambiguity between select and update queries
   */
  private update = this.RULE("update", () => {
    const updates: Types.UpdateOperation[] = [];
    this.OPTION1(() => {
      const update1 = this.SUBRULE(this.update1);
      updates.push(update1);
      this.OPTION2(() => {
        this.CONSUME(T.SemiColon);
        // Repeating the prologue here. We moved it (contrary to the spec) to the top-level query to avoid ambiguity
        // but considering we're calling the update rule recursively, we'll need to explicitly repeat the prologue rule call
        this.SUBRULE(this.prologue);
        const update2 = this.SUBRULE(this.update);
        this.ACTION(() => update2?.updates && updates.push(...update2.updates));
      });
    });
    if (updates.length) {
      return {
        type: "update",
        updates: updates,
      } satisfies Omit<Types.Update, RootProps> as Omit<Types.Update, RootProps>;
    }
    return {
      type: "update",
      updates: [],
    } satisfies Omit<Types.Update, RootProps> as Omit<Types.Update, RootProps>;
  });
  /**
   * [3]  	UpdateUnit	  ::=  	Update
   */
  private updateUnit = this.update;
  /**
   * [30]  	Update1	  ::=  	| Load | Clear | Drop | Add | Move | Copy | Create | InsertData | DeleteData | DeleteWhere | Modify
   */
  private update1 = this.RULE("update1", (): Types.UpdateOperation => {
    return this.OR([
      { ALT: () => this.SUBRULE(this.load) },
      { ALT: () => this.SUBRULE(this.clearOrDrop) },
      { ALT: () => this.SUBRULE(this.addMoveOrCopy) },
      { ALT: () => this.SUBRULE(this.create) },
      { ALT: () => this.SUBRULE(this.insertData) },
      { ALT: () => this.SUBRULE(this.deleteData) },
      { ALT: () => this.SUBRULE(this.deleteWhere) },
      { ALT: () => this.SUBRULE(this.modify) },
    ]);
  });
  /**
   * [31]  	Load	  ::=  	'LOAD' 'SILENT'? iri ( 'INTO' GraphRef )?
   */
  private load = this.RULE("load", (): Types.LoadOperation => {
    this.CONSUME(Keywords.Load);
    const silent = this.SUBRULE(this._optionalSilentKeyword);
    const source = this.SUBRULE(this.iri);
    const destination =
      this.OPTION(() => {
        this.CONSUME(Keywords.Into);
        return this.SUBRULE(this.graphRef).name;
      }) ?? false;
    return {
      type: "load",
      silent,
      source,
      destination,
    } satisfies Types.LoadOperation;
  });
  /**
   * [32]  	Clear	  ::=  	'CLEAR' 'SILENT'? GraphRefAll
   * [33]  	Drop	  ::=  	'DROP' 'SILENT'? GraphRefAll
   */
  private clearOrDrop = this.RULE("clearOrDrop", (): Types.ClearDropOperation => {
    const type = this.OR([
      {
        ALT: () => this.CONSUME(Keywords.Drop) && ("drop" as const),
      },
      {
        ALT: () => this.CONSUME(Keywords.Clear) && ("clear" as const),
      },
    ]);
    const silent = this.SUBRULE(this._optionalSilentKeyword);
    const graph = this.SUBRULE(this.graphRefAll);
    return {
      type,
      silent,
      graph,
    } satisfies Types.ClearDropOperation;
  });

  /**
   * [34]  	Create	  ::=  	'CREATE' 'SILENT'? GraphRef
   */
  private create = this.RULE("create", (): Types.CreateOperation => {
    this.CONSUME(Keywords.Create);
    const silent = this.SUBRULE(this._optionalSilentKeyword);
    const graph = this.SUBRULE(this.graphRef);
    return {
      type: "create",
      graph: graph.name,
      silent,
    } satisfies Types.CreateOperation;
  });
  /**
   * [35]  	Add	  ::=  	'ADD' 'SILENT'? GraphOrDefault 'TO' GraphOrDefault
   * [36]  	Move	  ::=  	'MOVE' 'SILENT'? GraphOrDefault 'TO' GraphOrDefault
   * [37]  	Copy	  ::=  	'COPY' 'SILENT'? GraphOrDefault 'TO' GraphOrDefault
   */
  private addMoveOrCopy = this.RULE("addMoveOrCopy", (): Types.CopyMoveAddOperation => {
    const type = this.OR([
      {
        ALT: () => this.CONSUME(Keywords.Add) && ("add" as const),
      },
      {
        ALT: () => this.CONSUME(Keywords.Move) && ("move" as const),
      },
      {
        ALT: () => this.CONSUME(Keywords.Copy) && ("copy" as const),
      },
    ]);
    const silent = this.SUBRULE(this._optionalSilentKeyword);
    const source = this.SUBRULE1(this.graphOrDefault);
    this.CONSUME(Keywords.To);
    const destination = this.SUBRULE2(this.graphOrDefault);
    return { type, silent, source, destination } satisfies Types.CopyMoveAddOperation;
  });
  /**
   * [38]  	InsertData	  ::=  	'INSERT DATA' QuadData
   */
  private insertData = this.RULE("insertData", (): Types.InsertOperation => {
    this.CONSUME(Keywords.Insert);
    this.CONSUME(Keywords.Data);
    const quads = this.SUBRULE(this.quadDataOrQuadPattern);
    // > The rule QuadData, used in INSERT DATA and DELETE DATA, must not allow variables in the quad patterns
    // See https://www.w3.org/TR/sparql11-query/#sparqlGrammar

    this.ACTION(() => ensureNoVariables(quads));
    return {
      type: "insert",
      // We already ensured that we don't have variables
      insert: quads as Types.QuadData[],
    } satisfies Types.InsertOperation;
  });
  /**
   * [39]  	DeleteData	  ::=  	'DELETE DATA' QuadData
   */
  private deleteData = this.RULE("deleteData", (): Types.DeleteOperation => {
    this.CONSUME(Keywords.Delete);
    this.CONSUME(Keywords.Data);
    const quads = this.SUBRULE(this.quadDataOrQuadPattern);
    // > The rule QuadData, used in INSERT DATA and DELETE DATA, must not allow variables in the quad patterns
    // AND
    // > Blank node syntax is not allowed in DELETE WHERE, the DeleteClause for DELETE, nor in DELETE DATA.
    // See https://www.w3.org/TR/sparql11-query/#sparqlGrammar
    this.ACTION(() => {
      ensureNoVariables(quads);
      ensureNoBnodes(quads);
    });
    return {
      type: "delete",
      // We already ensured that we don't have variables
      delete: quads as Types.QuadData[],
    } satisfies Types.DeleteOperation;
  });
  /**
   * [40]  	DeleteWhere	  ::=  	'DELETE WHERE' QuadPattern
   */
  private deleteWhere = this.RULE("deleteWhere", (): Types.DeleteWhereOperation => {
    this.CONSUME(Keywords.Delete);
    this.CONSUME(Keywords.Where);
    const quads = this.SUBRULE(this.quadDataOrQuadPattern);
    return this.ACTION(() => {
      // > Blank node syntax is not allowed in DELETE WHERE, the DeleteClause for DELETE, nor in DELETE DATA.
      // See https://www.w3.org/TR/sparql11-query/#sparqlGrammar
      ensureNoBnodes(quads);
      return {
        type: "deletewhere",
        where: quads,
      } satisfies Types.DeleteWhereOperation;
    });
  });
  /**
   * [41]  	Modify	  ::=  	( 'WITH' iri )? ( DeleteClause InsertClause? | InsertClause ) UsingClause* 'WHERE' GroupGraphPattern
   */
  private modify = this.RULE("modify", (): Types.InsertDeleteOperation => {
    // ( 'WITH' iri )?
    const graph =
      this.OPTION1(() => {
        this.CONSUME(Keywords.With);
        return this.SUBRULE(this.iri);
      }) ?? null;
    // ( DeleteClause InsertClause? | InsertClause )
    const deleteInsert = this.OR([
      {
        ALT: () => {
          const deleteClause = this.SUBRULE(this.deleteClause);
          const insertClause = this.OPTION2(() => this.SUBRULE1(this.insertClause));
          return { delete: deleteClause, insert: insertClause ?? [] } satisfies Pick<
            Types.InsertDeleteOperation,
            "delete" | "insert"
          >;
        },
      },
      {
        ALT: () => {
          const insertClause = this.SUBRULE2(this.insertClause);
          return { delete: [], insert: insertClause } satisfies Pick<Types.InsertDeleteOperation, "delete" | "insert">;
        },
      },
    ]);
    // UsingClause* 'WHERE' GroupGraphPattern
    const using: Array<NamedInfo> = [];
    this.MANY(() => {
      const use = this.SUBRULE(this.usingClause);
      this.ACTION(() => {
        using.push(use);
      });
    });
    this.CONSUME(Keywords.Where);
    const where = this.SUBRULE(this.groupGraphPattern);
    const result = {
      type: "insertdelete",
      ...deleteInsert,
      using: groupDatasets(using),
      graph,
      where: where.patterns,
    } satisfies Types.InsertDeleteOperation;
    return result;
  });
  /**
   * [42]  	DeleteClause	  ::=  	'DELETE' QuadPattern
   */
  private deleteClause = this.RULE("deleteClause", () => {
    this.CONSUME(Keywords.Delete);
    const quads = this.SUBRULE(this.quadDataOrQuadPattern);
    // > Blank node syntax is not allowed in DELETE WHERE, the DeleteClause for DELETE, nor in DELETE DATA.
    // See https://www.w3.org/TR/sparql11-query/#sparqlGrammar
    this.ACTION(() => ensureNoBnodes(quads));
    return quads;
  });
  /**
   * [43]  	InsertClause	  ::=  	'INSERT' QuadPattern
   */
  private insertClause = this.RULE("insertClause", () => {
    this.CONSUME(Keywords.Insert);
    return this.SUBRULE(this.quadDataOrQuadPattern);
  });

  /**
   * [44]  	UsingClause	  ::=  	'USING' ( iri | 'NAMED' iri )
   */
  private usingClause = this.RULE("usingClause", (): NamedInfo => {
    this.CONSUME(Keywords.Using);
    return this.OR([
      {
        ALT: () => {
          return { iri: this.SUBRULE1(this.iri), named: false } satisfies { iri: Types.IriTerm; named: false };
        },
      },
      {
        ALT: () => {
          this.CONSUME(Keywords.Named);
          return { iri: this.SUBRULE2(this.iri), named: true } satisfies { iri: Types.IriTerm; named: true };
        },
      },
    ]);
  });
  /**
   * [45]  	GraphOrDefault	  ::=  	'DEFAULT' | 'GRAPH'? iri
   */
  private graphOrDefault = this.RULE("graphOrDefault", (): Types.Graph | Types.GraphDefault => {
    return this.OR([
      { ALT: () => this.CONSUME(Keywords.Default) && ({ type: "graph", ref: "default" } satisfies Types.GraphDefault) },
      {
        ALT: () => {
          this.OPTION(() => this.CONSUME(Keywords.Graph));
          return { type: "graph", ref: "term", name: this.SUBRULE(this.iri) } satisfies Types.Graph;
        },
      },
    ]);
  });
  /**
   * [46]  	GraphRef	  ::=  	'GRAPH' iri
   */
  private graphRef = this.RULE("graphRef", () => {
    this.CONSUME(Keywords.Graph);
    return { type: "graph", ref: "term", name: this.SUBRULE(this.iri) } as const;
  });
  /**
   * [47]  	GraphRefAll	  ::=  	GraphRef | 'DEFAULT' | 'NAMED' | 'ALL'
   */
  private graphRefAll = this.RULE("graphRefAll", () => {
    return this.OR([
      {
        ALT: () => this.SUBRULE(this.graphRef),
      },
      {
        ALT: () => this.CONSUME(Keywords.Default) && ({ type: "graph", ref: "default" } as const),
      },
      {
        ALT: () => this.CONSUME(Keywords.Named) && ({ type: "graph", ref: "named" } as const),
      },
      {
        ALT: () => this.CONSUME(Keywords.All) && ({ type: "graph", ref: "all" } as const),
      },
    ]) satisfies Types.GraphReference;
  });

  /**
   * [48]  	QuadPattern	  ::=  	'{' Quads '}'
   * [49]  	QuadData	  ::=  	'{' Quads '}'
   * [50]  	Quads	  ::=  	TriplesTemplate? ( QuadsNotTriples '.'? TriplesTemplate? )*
   *
   * The QuadData and QuadPattern rules both use rule Quads.
   * The rule QuadData, used in INSERT DATA and DELETE DATA, must not allow variables in the quad patterns.
   */

  private quadDataOrQuadPattern = this.RULE("quadDataOrQuadPattern", (): Types.Quads[] => {
    this.CONSUME(T.LeftCurl);
    const quads: Types.Quads[] =
      this.OPTION1(() => [{ type: "bgp", triples: this.SUBRULE1(this.triplesTemplate) } satisfies Types.Quads]) ?? [];
    this.MANY(() => {
      const quadsNotTriples = this.SUBRULE(this.quadsNotTriples);
      this.ACTION(() => quads.push(quadsNotTriples));
      this.OPTION2(() => this.CONSUME(T.Dot));
      this.OPTION3(() => {
        const triples = this.SUBRULE2(this.triplesTemplate);
        this.ACTION(() => quads.push({ type: "bgp", triples }));
      });
    });
    this.CONSUME(T.RightCurl);
    return quads;
  });

  /**
   * [51]  	QuadsNotTriples	  ::=  	'GRAPH' VarOrIri '{' TriplesTemplate? '}'
   */
  private quadsNotTriples = this.RULE("quadsNotTriples", (): Types.Quads => {
    this.CONSUME(Keywords.Graph);
    const name = this.SUBRULE(this.varOrIri);
    this.CONSUME(T.LeftCurl);
    const triples = this.OPTION(() => this.SUBRULE(this.triplesTemplate));

    this.CONSUME(T.RightCurl);
    return {
      type: "graph",
      name,
      patterns: [{ type: "bgp", triples: triples ?? [] }],
    } satisfies Types.GraphQuads;
  });
  /**
   * [52]  	TriplesTemplate	  ::=  	TriplesSameSubject ( '.' TriplesTemplate? )?
   */
  private triplesTemplate = this.RULE("triplesTemplate", (): Types.TripleTemplate[] => {
    const triples = this.SUBRULE1(this.triplesSameSubject);
    this.OPTION1(() => {
      this.CONSUME(T.Dot);
      this.OPTION2(() => {
        const templ = this.SUBRULE2(this.triplesTemplate);
        this.ACTION(() => triples.push(...templ));
      });
    });
    return triples;
  });

  /**
   * [53]  	GroupGraphPattern ::= '{' ( SubSelect | GroupGraphPatternSub ) '}'
   */
  private groupGraphPattern = this.RULE("groupGraphPattern", (): Types.GroupPattern => {
    this.CONSUME(T.LeftCurl);
    const patterns = this.OR([
      {
        ALT: () => [this.SUBRULE(this.subSelect)],
      },
      {
        ALT: () => this.SUBRULE(this.groupGraphPatternSub),
      },
    ]);

    this.CONSUME(T.RightCurl);
    return { type: "group", patterns } satisfies Types.GroupPattern;
  });
  /**
   * [54]  	GroupGraphPatternSub ::= TriplesBlock? ( GraphPatternNotTriples '.'? TriplesBlock? )*
   */
  private groupGraphPatternSub = this.RULE("groupGraphPatternSub", (): Array<Types.PatternWithoutSubselect> => {
    const patterns: Array<Types.PatternWithoutSubselect> = [];
    this.OPTION1(() => {
      patterns.push(this.SUBRULE1(this.triplesBlock));
    });
    this.MANY(() => {
      patterns.push(this.SUBRULE(this.graphPatternNotTriples));
      this.OPTION2(() => this.CONSUME(T.Dot));
      this.OPTION3(() => {
        patterns.push(this.SUBRULE2(this.triplesBlock));
      });
    });

    return patterns satisfies Array<Types.PatternWithoutSubselect>;
  });

  /**
   * [55]  	TriplesBlock ::= TriplesSameSubjectPath ( '.' TriplesBlock? )?
   */
  private triplesBlock = this.RULE("triplesBlock", (): Types.BgpPattern => {
    const triples: Array<Types.Triple> = this.SUBRULE2(this.triplesSameSubjectPath);
    this.MANY({
      DEF: () => {
        this.CONSUME1(T.Dot);
        const t = this.SUBRULE1(this.triplesSameSubjectPath);
        this.ACTION(() => {
          triples.push(...t);
        });
      },
    });
    this.OPTION(() => this.CONSUME2(T.Dot));

    return {
      type: "bgp",
      triples,
    } satisfies Types.BgpPattern;
  });

  /**
   * [56]  	GraphPatternNotTriples ::= GroupOrUnionGraphPattern | OptionalGraphPattern | MinusGraphPattern | GraphGraphPattern | ServiceGraphPattern | Filter | Bind | InlineData
   */
  private graphPatternNotTriples = this.RULE("graphPatternNotTriples", () => {
    return this.OR([
      { ALT: () => this.SUBRULE(this.groupOrUnionGraphPattern) },
      { ALT: () => this.SUBRULE(this.optionalGraphPattern) },
      { ALT: () => this.SUBRULE(this.minusGraphPattern) },
      { ALT: () => this.SUBRULE(this.graphGraphPattern) },
      { ALT: () => this.SUBRULE(this.serviceGraphPattern) },
      { ALT: () => this.SUBRULE(this.filter) },
      { ALT: () => this.SUBRULE(this.bind) },
      { ALT: () => this.SUBRULE(this.inlineData) },
    ]);
  });
  /**
   * [57]  	OptionalGraphPattern ::= 'OPTIONAL' GroupGraphPattern
   */
  private optionalGraphPattern = this.RULE("optionalGraphPattern", (): Types.OptionalPattern => {
    this.CONSUME(Keywords.Optional);
    const result = this.SUBRULE(this.groupGraphPattern);
    return this.ACTION(() => {
      return { ...result, type: "optional" };
    });
  });
  /**
   * [58]  	GraphGraphPattern ::= 'GRAPH' VarOrIri GroupGraphPattern
   */
  private graphGraphPattern = this.RULE("graphGraphPattern", (): Types.GraphPattern => {
    this.CONSUME(Keywords.Graph);
    const g = this.SUBRULE(this.varOrIri);
    const pattern = this.SUBRULE(this.groupGraphPattern);
    return {
      ...pattern,
      type: "graph",
      name: g,
    };
  });
  /**
   * [59]  	ServiceGraphPattern ::= 'SERVICE' 'SILENT'? VarOrIri GroupGraphPattern
   */
  private serviceGraphPattern = this.RULE("serviceGraphPattern", (): Types.ServicePattern => {
    this.CONSUME(Keywords.Service);
    let silent = false;
    this.OPTION(() => {
      this.CONSUME(Keywords.Silent);
      silent = true;
    });
    const name = this.SUBRULE(this.varOrIri);
    const pattern = this.SUBRULE(this.groupGraphPattern);
    return {
      ...pattern,
      type: "service",
      silent,
      name,
    } satisfies Types.ServicePattern;
  });
  /**
   * [60]  	Bind ::= 'BIND' '(' Expression 'AS' Var ')'
   */
  private bind = this.RULE("bind", (): Types.BindPattern => {
    this.CONSUME(Keywords.Bind);
    this.CONSUME(T.LeftParen);
    const expression = this.SUBRULE(this.expression, { ARGS: [{ aggregate: "not-allowed" }] });
    this.CONSUME(Keywords.As);
    const variable = this.SUBRULE(this.var);
    this.CONSUME(T.RightParen);
    return { type: "bind", variable, expression } satisfies Types.BindPattern;
  });
  /**
   * [61]  	InlineData ::= 'VALUES' DataBlock
   */
  private inlineData = this.RULE("inlineData", (): Types.ValuesPattern => {
    this.CONSUME(Keywords.Values);
    return { type: "values", values: this.SUBRULE(this.dataBlock) } satisfies Types.ValuesPattern;
  });
  /**
   * [62]  	DataBlock ::= InlineDataOneVar | InlineDataFull
   */
  private dataBlock = this.RULE("dataBlock", () => {
    return this.OR([
      {
        ALT: () => this.SUBRULE(this.inlineDataOneVar),
      },
      {
        ALT: () => this.SUBRULE(this.inlineDataFull),
      },
    ]);
  });
  /**
   * [63]  	InlineDataOneVar ::= Var '{' DataBlockValue* '}'
   */
  private inlineDataOneVar = this.RULE("inlineDataOneVar", () => {
    const varName = this.SUBRULE(this.var);
    this.CONSUME(T.LeftCurl);
    const result: Types.ValuePatternRow[] = [];
    this.MANY(() => {
      result.push({ [varName.value]: this.SUBRULE(this.dataBlockValue) });
    });
    this.CONSUME(T.RightCurl);
    return result;
  });
  /**
   * [64]  	InlineDataFull ::= ( NIL | '(' Var* ')' ) '{' ( '(' DataBlockValue* ')' | NIL )* '}'
   */
  private inlineDataFull = this.RULE("inlineDataFull", () => {
    // ( NIL | '(' Var* ')' )
    this.CONSUME1(T.LeftParen);
    const vars: Types.VariableTerm[] = [];
    this.MANY1(() => {
      vars.push(this.SUBRULE(this.var));
    });
    this.CONSUME1(T.RightParen);

    // '{' ( '(' DataBlockValue* ')' | NIL )* '}'
    this.CONSUME(T.LeftCurl);
    const data: Array<Array<Types.ValuePatternValue>> = [];
    this.MANY2(() => {
      const row: Types.ValuePatternValue[] = [];
      this.CONSUME2(T.LeftParen);
      this.MANY3(() => {
        row.push(this.SUBRULE(this.dataBlockValue));
      });
      this.CONSUME2(T.RightParen);
      data.push(row);
    });

    this.CONSUME(T.RightCurl);

    return this.ACTION(() =>
      data.map((row) => {
        const valuesObject: Types.ValuePatternRow = {};
        if (row.length !== vars.length) {
          throw new InconsistentValuesLengthError();
        }
        for (let i = 0; i < vars.length; i++) {
          if (row[i]) valuesObject[vars[i].value] = row[i];
        }
        return valuesObject;
      }),
    );
  });

  /**
   * [65]  	DataBlockValue	  ::=  	iri | RDFLiteral | NumericLiteral | BooleanLiteral | 'UNDEF'
   */
  private dataBlockValue = this.RULE("dataBlockValue", () => {
    return this.OR([
      {
        ALT: () => this.SUBRULE(this.iri),
      },
      {
        ALT: () => this.SUBRULE(this.rdfLiteral),
      },
      {
        ALT: () => this.SUBRULE(this.numericLiteral),
      },
      {
        ALT: () => this.SUBRULE(this.booleanLiteral),
      },
      {
        ALT: () => {
          this.CONSUME(Keywords.Undef);
          return null;
        },
      },
    ]);
  });
  /**
   * [66]  	MinusGraphPattern ::= 'MINUS' GroupGraphPattern
   */
  private minusGraphPattern = this.RULE("minusGraphPattern", (): Types.MinusPattern => {
    this.CONSUME(Keywords.Minus);
    const result = this.SUBRULE(this.groupGraphPattern);
    return this.ACTION(() => {
      return { ...result, type: "minus" } satisfies Types.MinusPattern;
    });
  });
  /**
   * [67]  	GroupOrUnionGraphPattern ::= GroupGraphPattern ( 'UNION' GroupGraphPattern )*
   */
  private groupOrUnionGraphPattern = this.RULE(
    "groupOrUnionGraphPattern",
    (): Types.UnionPattern | Types.GroupPattern => {
      const group = [this.SUBRULE1(this.groupGraphPattern)];
      this.MANY(() => {
        this.CONSUME(Keywords.Union);
        group.push(this.SUBRULE2(this.groupGraphPattern));
      });
      if (group.length === 1) return group[0];
      return {
        type: "union",
        patterns: group.map(degroupSingle),
      } satisfies Types.UnionPattern;
    },
  );
  /**
   * [68]  	Filter ::= 'FILTER' Constraint
   */
  private filter = this.RULE("filter", (): Types.FilterPattern => {
    this.CONSUME(Keywords.Filter);
    return {
      type: "filter",
      // Pass an empty context: all props in the context don't apply
      expression: this.SUBRULE(this.constraint, { ARGS: [{ aggregate: "not-allowed" }] }),
    } satisfies Types.FilterPattern;
  });
  /**
   * [69]  	Constraint ::= BrackettedExpression | BuiltInCall | FunctionCall
   */
  private constraint = this.RULE("constraint", (context: ParserContext) => {
    return this.OR([
      {
        ALT: () => this.SUBRULE(this.brackettedExpression, { ARGS: [context] }),
      },
      {
        ALT: () => this.SUBRULE(this.builtInCall, { ARGS: [context] }),
      },
      {
        ALT: () => this.SUBRULE(this.functionCall, { ARGS: [context] }),
      },
    ]);
  });

  /**
   * [22]  	HavingCondition ::= Constraint
   */
  private havingCondition = this.constraint;
  /**
   * [70]  	FunctionCall ::= iri ArgList
   */
  private functionCall = this.RULE("functionCall", (context: ParserContext): Types.IriFunction => {
    const iri = this.SUBRULE(this.iri);
    const argList = this.SUBRULE(this.argList, { ARGS: [context] });
    return { type: "iriFunction", function: iri, ...argList } satisfies Types.IriFunction;
  });
  /**
   * [71]  	ArgList ::= NIL | '(' 'DISTINCT'? Expression ( ',' Expression )* ')'
   */
  private argList = this.RULE("argList", (context: ParserContext): { distinct: boolean; args: Types.Expression[] } => {
    return this.OR([
      {
        ALT: () => {
          const args = this.SUBRULE(this.nil);
          return { args, distinct: false } satisfies { distinct: false; args: Types.Function[] };
        },
      },
      {
        ALT: () => {
          this.CONSUME(T.LeftParen);
          const distinct = this.SUBRULE(this._optionalDistinctKeyword);
          const args = [this.SUBRULE1(this.expression, { ARGS: [context] })];
          this.MANY(() => {
            this.CONSUME(T.Comma);
            args.push(this.SUBRULE2(this.expression, { ARGS: [context] }));
          });
          this.CONSUME(T.RightParen);
          return { args, distinct } satisfies { distinct: boolean; args: Types.Expression[] };
        },
      },
    ]);
  });
  /**
   * [72]  	ExpressionList	  ::=  	NIL | '(' Expression ( ',' Expression )* ')'
   */
  private expressionList = this.RULE("expressionList", (context: ParserContext): Types.Expression[] => {
    return this.OR([
      {
        ALT: () => this.SUBRULE(this.nil),
      },
      {
        ALT: () => {
          this.CONSUME(T.LeftParen);
          const expressions = [this.SUBRULE1(this.expression, { ARGS: [context] })];
          this.MANY(() => {
            this.CONSUME(T.Comma);
            expressions.push(this.SUBRULE2(this.expression, { ARGS: [context] }));
          });
          this.CONSUME(T.RightParen);
          return expressions;
        },
      },
    ]);
  });
  /**
   * [73]  	ConstructTemplate	  ::=  	'{' ConstructTriples? '}'
   */
  private constructTemplate = this.RULE("constructTemplate", (): Types.TripleTemplate[] => {
    this.CONSUME(T.LeftCurl);
    const constructTriples = this.OPTION(() => this.SUBRULE(this.constructTriples)) ?? [];
    this.CONSUME(T.RightCurl);
    return constructTriples;
  });
  /**
   * [74]  	ConstructTriples	  ::=  	TriplesSameSubject ( '.' ConstructTriples? )?
   */
  private constructTriples = this.RULE("constructTriples", (): Types.TripleTemplate[] => {
    const triples = this.SUBRULE(this.triplesSameSubject);
    this.OPTION1(() => {
      this.CONSUME(T.Dot);
      this.OPTION2(() => {
        const more = this.SUBRULE(this.constructTriples);
        this.ACTION(() => {
          triples.push(...more);
        });
      });
    });
    return triples;
  });
  /**
   * [75]  	TriplesSameSubject	  ::=  	VarOrTerm PropertyListNotEmpty | TriplesNode PropertyList
   */
  private triplesSameSubject = this.RULE("triplesSameSubject", (): Types.TripleTemplate[] => {
    return this.OR([
      {
        ALT: () => {
          const subject = this.SUBRULE(this.varOrTerm);
          if (subject.termType === "Literal")
            throw new SparqlAstError(`The subject of a constructed triple may not be a literal: ${subject.rdfString}`);
          const predObj = this.SUBRULE(this.propertyListNotEmpty);
          return this.ACTION(() =>
            predObj.map((partialTriple) => {
              return { subject, ...partialTriple } satisfies Types.TripleTemplate;
            }),
          );
        },
      },
      {
        ALT: () => {
          //  | TriplesNode PropertyList -> appendAllTo($2.map(function (t) { return extend(triple($1.entity), t); }), $1.triples) /* the subject is a blank node, possibly with more triples */
          const node = this.SUBRULE(this.triplesNode);
          const propertyList = this.SUBRULE(this.propertyList);
          return this.ACTION(() => {
            const triples: Types.TripleTemplate[] = [];
            if (propertyList) {
              if (node.entity.termType === "Literal")
                throw new SparqlAstError(
                  `The subject of a constructed triple may not be a literal: ${node.entity.rdfString}`,
                );
              const subject = node.entity;
              triples.push(
                ...propertyList.map((list) => {
                  return { subject, ...list } satisfies Types.TripleTemplate;
                }),
              );
            }
            triples.push(...node.triples);
            return triples;
          });
        },
      },
    ]);
  });
  /**
   * [76]  	PropertyList ::= PropertyListNotEmpty?
   */
  private propertyList = this.RULE("propertyList", () => {
    return this.OPTION(() => this.SUBRULE(this.propertyListNotEmpty));
  });
  /**
   * [77]  	PropertyListNotEmpty ::= Verb ObjectList ( ';' ( Verb ObjectList )? )*
   */
  private propertyListNotEmpty = this.RULE(
    "propertyListNotEmpty",
    (): Array<Optional<Types.TripleTemplate, "subject">> => {
      const predicate = this.SUBRULE1(this.verb);
      const objectLists = this.SUBRULE1(this.objectList);
      const triples: Optional<Types.TripleTemplate, "subject">[] = [];
      this.ACTION(() => {
        for (const objectList of objectLists) {
          triples.push({ predicate, object: objectList.entity });
          triples.push(...objectList.triples);
        }
      });
      this.MANY(() => {
        this.CONSUME(T.SemiColon);
        this.OPTION(() => {
          const otherPredicate = this.SUBRULE2(this.verb);
          const otherObjectLists = this.SUBRULE2(this.objectList);
          this.ACTION(() => {
            for (const objectList of otherObjectLists) {
              triples.push({ predicate: otherPredicate, object: objectList.entity });
              triples.push(...objectList.triples);
            }
          });
        });
      });
      return triples;
    },
  );
  /**
   * [78]  	Verb ::= VarOrIri | 'a'
   */
  private verb = this.RULE("verb", () => {
    return this.OR([
      {
        ALT: () => this.SUBRULE(this.varOrIri),
      },
      {
        ALT: () => this.SUBRULE(this.a),
      },
    ]);
  });
  private a = this.RULE("a", () => {
    const aToken = this.CONSUME(T.A);
    return this.createNamedNode(this.rdf.type.value, { surfaceForm: aToken.image, location: getLocation(aToken) });
  });
  /**
   * [79]  	ObjectList ::= Object ( ',' Object )*
   */
  private objectList = this.RULE("objectList", (): CollectionItem[] => {
    const objects = [this.SUBRULE1(this.object)];
    this.MANY(() => {
      this.CONSUME(T.Comma);
      const object = this.SUBRULE2(this.object);
      this.ACTION(() => {
        objects.push(object);
      });
    });
    return objects;
  });

  /**
   * [81]  	TriplesSameSubjectPath ::= VarOrTerm PropertyListPathNotEmpty | TriplesNodePath PropertyListPath
   */
  private triplesSameSubjectPath = this.RULE("triplesSameSubjectPath", (): Types.Triple[] => {
    return this.OR([
      {
        ALT: () => {
          const subject = this.SUBRULE(this.varOrTerm);
          const partialTriples = this.SUBRULE(this.propertyListPathNotEmpty);
          return this.ACTION(() => {
            return partialTriples.map((possiblyPartialTriple) => {
              if (!possiblyPartialTriple.subject) {
                return { subject, ...possiblyPartialTriple } as Types.Triple;
              }
              return possiblyPartialTriple as Types.Triple;
            });
          });
        },
      },
      {
        ALT: () => {
          const subjects = this.SUBRULE(this.triplesNodePath);
          const propertyListTriples = this.SUBRULE(this.propertyListPath);
          return this.ACTION(() => {
            if (!propertyListTriples) return subjects.triples as Types.Triple[];
            return propertyListTriples
              .map((possiblyPartialTriple) => {
                if (!possiblyPartialTriple.subject) {
                  return { subject: subjects.entity, ...possiblyPartialTriple } as Types.Triple;
                }
                return possiblyPartialTriple;
              })
              .concat(subjects.triples) as Types.Triple[];
          });
        },
      },
    ]);
  });
  /**
   * [82]  	PropertyListPath ::= PropertyListPathNotEmpty?
   */
  private propertyListPath = this.RULE("propertyListPath", () => {
    return this.OPTION(() => this.SUBRULE(this.propertyListPathNotEmpty));
  });

  /**
   * [83]  	PropertyListPathNotEmpty ::= ( VerbPath | VerbSimple ) ObjectListPath ( ';' ( ( VerbPath | VerbSimple ) ObjectListPath )? )*
   * Note: errata 3 specifies that we should use always objectlistpath in this rule, and not objectlist
   * See https://www.w3.org/2013/sparql-errata#errata-query-3
   */
  private propertyListPathNotEmpty = this.RULE("propertyListPathNotEmpty", (): PartialTriple[] => {
    const predicate = this.OR1([
      {
        ALT: () => this.SUBRULE1(this.verbPath),
      },
      {
        ALT: () => this.SUBRULE1(this.verbSimple),
      },
    ]);
    const objectLists = this.SUBRULE1(this.objectListPath);
    const triples: PartialTriple[] = [];
    this.ACTION(() => {
      triples.push(...this.predicateAndObjectListsToTriples(predicate, objectLists));
    });
    this.MANY(() => {
      this.CONSUME(T.SemiColon);
      this.OPTION(() => {
        const otherPredicate = this.OR2([
          {
            ALT: () => this.SUBRULE2(this.verbPath),
          },
          {
            ALT: () => this.SUBRULE2(this.verbSimple),
          },
        ]);
        const otherObjectLists = this.SUBRULE2(this.objectListPath);
        this.ACTION(() => {
          triples.push(...this.predicateAndObjectListsToTriples(otherPredicate, otherObjectLists));
        });
      });
    });
    return triples;
  });
  private predicateAndObjectListsToTriples(
    predicate: Types.Triple["predicate"],
    objectLists: CollectionItemPath[],
  ): PartialTriple[] {
    const triples: PartialTriple[] = [];
    for (const objectList of objectLists) {
      triples.push(
        {
          predicate,
          object: objectList.entity,
        },
        ...objectList.triples,
      );
    }
    return triples;
  }

  /**
   * [84]  	VerbPath ::= Path
   * [88]  	Path ::= PathAlternative
   * [89]  	PathAlternative ::= PathSequence ( '|' PathSequence )*
   */
  private path = this.RULE("path", (): Types.PropertyPath | Types.IriTerm => {
    const items = [this.SUBRULE1(this.pathSequence)];
    this.MANY(() => {
      this.CONSUME(T.Pipe);
      items.push(this.SUBRULE2(this.pathSequence));
    });
    if (items.length === 1) return items[0];
    return { type: "path", pathType: "|", items } satisfies Types.PropertyPath;
  });
  /**
   * [84]  	VerbPath ::= Path
   */
  private verbPath = this.path;
  /**
   * [86]  	ObjectListPath ::= ObjectPath ( ',' ObjectPath )*
   */
  private objectListPath = this.RULE("objectListPath", (): CollectionItemPath[] => {
    const objects: CollectionItemPath[] = [this.SUBRULE1(this.graphNodePath)];
    this.MANY(() => {
      this.CONSUME(T.Comma);
      const moreNodes = this.SUBRULE2(this.graphNodePath);
      this.ACTION(() => {
        objects.push(moreNodes);
      });
    });
    return objects;
  });
  /**
   * [90]  	PathSequence ::= PathEltOrInverse ( '/' PathEltOrInverse )*
   */
  private pathSequence = this.RULE("pathSequence", (): Types.PropertyPath | Types.IriTerm => {
    const items = [this.SUBRULE1(this.pathEltOrInverse)];
    this.MANY(() => {
      this.CONSUME(T.ForwardSlash);
      items.push(this.SUBRULE2(this.pathEltOrInverse));
    });
    if (items.length === 1) return items[0];
    return { type: "path", pathType: "/", items } satisfies Types.PropertyPath;
  });
  /**
   * [91]  	PathElt ::= PathPrimary PathMod?
   */
  private pathElt = this.RULE("pathElt", (): Types.PropertyPath | Types.IriTerm => {
    const path = this.SUBRULE(this.pathPrimary);
    const pathType = this.OPTION(() =>
      this.OR([
        {
          ALT: () => this.CONSUME(T.Plus) && ("+" as const),
        },
        {
          ALT: () => this.CONSUME(T.Questionmark) && ("?" as const),
        },
        {
          ALT: () => this.CONSUME(T.Wildcard) && ("*" as const),
        },
      ]),
    );
    if (!pathType) return path;
    return { type: "path", pathType, items: [path] } satisfies Types.PropertyPath;
  });
  /**
   * [92]  	PathEltOrInverse ::= PathElt | '^' PathElt
   */
  private pathEltOrInverse = this.RULE("pathEltOrInveser", (): Types.PropertyPath | Types.IriTerm => {
    return this.OR([
      {
        ALT: () => this.SUBRULE1(this.pathElt),
      },
      {
        ALT: () => {
          this.CONSUME(T.Caret);
          const path = this.SUBRULE2(this.pathElt);
          return { type: "path", pathType: "^", items: [path] } satisfies Types.PropertyPath;
        },
      },
    ]);
  });
  /**
   * [94]  	PathPrimary ::= iri | 'a' | '!' PathNegatedPropertySet | '(' Path ')'
   */
  private pathPrimary = this.RULE("pathPrimary", (): Types.PropertyPath | Types.IriTerm => {
    return this.OR([
      {
        ALT: () => this.SUBRULE(this.iri),
      },
      {
        ALT: () => this.SUBRULE(this.a),
      },
      {
        ALT: () => {
          this.CONSUME(T.Exclamation);
          const items = this.SUBRULE(this.pathNegatedPropertySet);
          return { type: "path", pathType: "!", items } satisfies Types.PropertyPath;
        },
      },
      {
        ALT: () => {
          this.CONSUME(T.LeftParen);
          const path: Types.PropertyPath | Types.IriTerm = this.SUBRULE(this.path);
          this.CONSUME(T.RightParen);
          return path satisfies Types.PropertyPath | Types.IriTerm;
        },
      },
    ]);
  });
  /**
   * [95]  	PathNegatedPropertySet	  ::=  	PathOneInPropertySet | '(' ( PathOneInPropertySet ( '|' PathOneInPropertySet )* )? ')'
   */
  private pathNegatedPropertySet = this.RULE("pathNegatedPropertySet", (): Types.NegatedPropertySet["items"] => {
    return this.OR([
      {
        ALT: (): Types.NegatedPropertySet["items"] => [this.SUBRULE1(this.pathOneInPropertySet)],
      },
      {
        ALT: () => {
          this.CONSUME(T.LeftParen);
          const items: Types.NegatedPropertySet["items"] = [];
          this.OPTION(() => {
            items.push(this.SUBRULE2(this.pathOneInPropertySet));
            this.MANY(() => {
              this.CONSUME(T.Pipe);
              items.push(this.SUBRULE3(this.pathOneInPropertySet));
            });
          });
          this.CONSUME(T.RightParen);
          return items;
        },
      },
    ]);
  });
  /**
   * [96]  	PathOneInPropertySet	  ::=  		iri | 'a' | '^' ( iri | 'a' )
   */
  private pathOneInPropertySet = this.RULE("pathOneInPropertySet", (): Types.IriTerm | Types.InverseIri => {
    return this.OR1([
      {
        ALT: () => this.SUBRULE1(this.iri),
      },
      {
        ALT: () => this.SUBRULE1(this.a),
      },
      {
        ALT: () => {
          this.CONSUME(T.Caret);
          const iri = this.OR2([
            {
              ALT: () => this.SUBRULE2(this.iri),
            },
            {
              ALT: () => this.SUBRULE2(this.a),
            },
          ]);
          return { type: "path", pathType: "^", items: [iri] } satisfies Types.PropertyPath;
        },
      },
    ]);
  });
  /**
   * [98]  	TriplesNode ::= Collection | BlankNodePropertyList
   * Returns the subjects
   */
  private triplesNode = this.RULE("triplesNode", (): CollectionItem => {
    return this.OR([
      {
        ALT: () => this.SUBRULE(this.collection),
      },
      {
        ALT: () => {
          const triples = this.SUBRULE(this.blankNodePropertyList);
          const blanknode = this.ACTION(() => {
            const blank = this.createBnode();
            return blank;
          });

          return this.ACTION(() => {
            return {
              entity: blanknode,
              triples: triples.map((t) => ({ subject: blanknode, ...t })),
            } satisfies CollectionItem;
          });
        },
      },
    ]);
  });
  /**
   * [99]  	BlankNodePropertyList ::= '[' PropertyListNotEmpty ']'
   */
  private blankNodePropertyList = this.RULE("blankNodePropertyList", () => {
    this.CONSUME(T.LeftSquare);
    const propertyList = this.SUBRULE(this.propertyListNotEmpty);
    this.CONSUME(T.RightSquare);
    return propertyList;
  });
  /**
   * [100]  	TriplesNodePath ::= CollectionPath | BlankNodePropertyListPath
   */
  private triplesNodePath = this.RULE("triplesNodePath", (): CollectionItemPath => {
    return this.OR([
      { ALT: () => this.SUBRULE(this.collectionPath) },
      {
        ALT: () => {
          const triples = this.SUBRULE(this.blankNodePropertyListPath);
          return this.ACTION(() => {
            const bnode = this.createBnode();
            return {
              entity: bnode,
              triples: triples.map((t) => ({ subject: bnode, ...t }) satisfies Types.Triple),
            } satisfies CollectionItemPath;
          });
        },
      },
    ]);
  });

  /**
   * [101]  	BlankNodePropertyListPath ::= '[' PropertyListPathNotEmpty ']'
   */
  private blankNodePropertyListPath = this.RULE("blankNodePropertyListPath", (): PartialTriple[] => {
    this.CONSUME(T.LeftSquare);
    const propertyList = this.SUBRULE(this.propertyListPathNotEmpty);
    this.CONSUME(T.RightSquare);
    return propertyList;
  });
  /**
   * [102]  	Collection ::= '(' GraphNode+ ')'
   */
  private collection = this.RULE("collection", (): CollectionItem => {
    this.CONSUME(T.LeftParen);
    const collectionItems: CollectionItem[] = [];
    this.AT_LEAST_ONE(() => {
      collectionItems.push(this.SUBRULE(this.graphNode));
    });
    this.CONSUME(T.RightParen);
    return this.ACTION(() => this._createList(collectionItems));
  });
  /**
   * [103]  	CollectionPath ::= '(' GraphNodePath+ ')'
   */
  private collectionPath = this.RULE("collectionPath", (): CollectionItemPath => {
    this.CONSUME(T.LeftParen);
    const objects: CollectionItemPath[] = [];
    this.AT_LEAST_ONE(() => {
      const collection = this.SUBRULE(this.graphNodePath);
      this.ACTION(() => {
        objects.push(collection);
      });
    });
    this.CONSUME(T.RightParen);
    return this.ACTION(() => this._createList(objects));
  });

  /**
   * [104]  	GraphNode ::= VarOrTerm | TriplesNode
   */
  private graphNode = this.RULE("graphNode", (): CollectionItem => {
    return this.OR([
      {
        ALT: () => {
          const varOrTerm = this.SUBRULE(this.varOrTerm);
          return { entity: varOrTerm, triples: [] } satisfies CollectionItem;
        },
      },
      {
        ALT: () => this.SUBRULE(this.triplesNode),
      },
    ]);
  });
  /**
   * [80]  	Object ::= GraphNode
   */
  private object = this.graphNode;
  /**
   * [105]  	GraphNodePath ::= VarOrTerm | TriplesNodePath
   */
  private graphNodePath = this.RULE("graphNodePath", (): CollectionItemPath => {
    return this.OR([
      {
        ALT: () => {
          const entity = this.SUBRULE(this.varOrTerm);
          return {
            entity,
            triples: [],
          } satisfies CollectionItemPath;
        },
      },
      {
        ALT: () => this.SUBRULE(this.triplesNodePath),
      },
    ]);
  });
  /**
   * [106]  	VarOrTerm ::= Var | GraphTerm
   */
  private varOrTerm = this.RULE("varOrTerm", () => {
    return this.OR([
      {
        ALT: () => this.SUBRULE(this.graphTerm),
      },
      {
        ALT: () => this.SUBRULE(this.var),
      },
    ]);
  });
  /**
   * [107]  	VarOrIri ::= Var | iri
   */
  private varOrIri = this.RULE("varOrIri", () => {
    return this.OR([
      {
        ALT: () => {
          return this.SUBRULE(this.var);
        },
      },
      {
        ALT: () => this.SUBRULE(this.iri),
      },
    ]);
  });
  /**
   * [109]  	GraphTerm ::= iri | RDFLiteral | NumericLiteral | BooleanLiteral | BlankNode | NIL
   */
  private graphTerm = this.RULE("graphTerm", () => {
    return this.OR([
      {
        ALT: () => this.SUBRULE(this.iri),
      },
      {
        ALT: () => this.SUBRULE(this.rdfLiteral),
      },
      {
        ALT: () => this.SUBRULE(this.numericLiteral),
      },
      {
        ALT: () => this.SUBRULE(this.booleanLiteral),
      },
      {
        ALT: () => this.SUBRULE(this.blankNode),
      },
      {
        ALT: () => {
          this.SUBRULE(this.nil);
          return this.rdf.nil;
        },
      },
    ]);
  });

  /**
   * [111]  	ConditionalOrExpression ::= ConditionalAndExpression ( '||' ConditionalAndExpression )*
   */
  private conditionalOrExpression = this.RULE("conditionalOrExpression", (context: ParserContext): Types.Expression => {
    const expression = this.SUBRULE1(this.conditionalAndExpression, { ARGS: [context] });
    const remainder: Array<Types.Expression | PreTreeOperation> = [];
    this.MANY(() => {
      this.CONSUME1(T.Pipe);
      this.CONSUME2(T.Pipe);
      remainder.push({
        type: "function",
        function: "||",
        args: [this.SUBRULE2(this.conditionalAndExpression, { ARGS: [context] })],
      });
    });

    return this.ACTION(() => createFunctionTree(expression, remainder)) as Types.Expression;
  });
  /**
   * [110]  	Expression ::= ConditionalOrExpression
   */
  private expression = this.conditionalOrExpression;
  /**
   * [112]  	ConditionalAndExpression ::= ValueLogical ( '&&' ValueLogical )*
   */
  private conditionalAndExpression = this.RULE("conditionalAndExpression", (context: ParserContext): Types.Function => {
    const expression = this.SUBRULE1(this.valueLogical, { ARGS: [context] });
    const remainder: Array<Types.Expression | PreTreeOperation> = [];
    this.MANY(() => {
      this.CONSUME1(T.Ampersand);
      this.CONSUME2(T.Ampersand);
      remainder.push({
        type: "function",
        function: "&&",
        args: [this.SUBRULE2(this.valueLogical, { ARGS: [context] })],
      });
    });
    return this.ACTION(() => createFunctionTree(expression, remainder));
  });

  /**
   * [114]  	RelationalExpression ::= NumericExpression ( '=' NumericExpression | '!=' NumericExpression | '<' NumericExpression | '>' NumericExpression | '<=' NumericExpression | '>=' NumericExpression | 'IN' ExpressionList | 'NOT' 'IN' ExpressionList )?
   */
  private relationalExpression = this.RULE("relationalExpression", (context: ParserContext): Types.Function => {
    const expression = this.SUBRULE1(this.numericExpression, { ARGS: [context] });
    const asOperation: Types.Function | undefined = this.OPTION1(() => {
      return this.OR([
        {
          ALT: () => {
            this.CONSUME1(T.Equals);
            const otherExpression = this.SUBRULE2(this.numericExpression, { ARGS: [context] });
            return {
              type: "function",
              function: "=",
              args: [expression, otherExpression],
            } satisfies Types.Function;
          },
        },
        {
          ALT: () => {
            this.CONSUME2(T.NotEquals);
            const otherExpression = this.SUBRULE3(this.numericExpression, { ARGS: [context] });
            return {
              type: "function",
              function: "!=",
              args: [expression, otherExpression],
            } satisfies Types.Function;
          },
        },
        {
          ALT: () => {
            this.CONSUME3(T.LessThan);
            const otherExpression = this.SUBRULE4(this.numericExpression, { ARGS: [context] });
            return {
              type: "function",
              function: "<",
              args: [expression, otherExpression],
            } satisfies Types.Function;
          },
        },
        {
          ALT: () => {
            this.CONSUME4(T.GreaterThan);
            const otherExpression = this.SUBRULE5(this.numericExpression, { ARGS: [context] });
            return {
              type: "function",
              function: ">",
              args: [expression, otherExpression],
            } satisfies Types.Function;
          },
        },
        {
          ALT: () => {
            this.CONSUME5(T.LessThanOrEquals);
            const otherExpression = this.SUBRULE6(this.numericExpression, { ARGS: [context] });
            return {
              type: "function",
              function: "<=",
              args: [expression, otherExpression],
            } satisfies Types.Function;
          },
        },
        {
          ALT: () => {
            this.CONSUME6(T.GreaterThanOrEquals);
            const otherExpression = this.SUBRULE7(this.numericExpression, { ARGS: [context] });
            return {
              type: "function",
              function: ">=",
              args: [expression, otherExpression],
            } satisfies Types.Function;
          },
        },
        {
          ALT: () => {
            // 'IN' ExpressionList | 'NOT' 'IN' ExpressionList
            const not = !!this.OPTION2(() => this.CONSUME(Keywords.Not));
            this.CONSUME(Keywords.In);
            const expressions = this.SUBRULE(this.expressionList, { ARGS: [context] });
            return {
              type: "function",
              function: not ? "notin" : "in",
              args: [expression, expressions],
            } satisfies Types.Function;
          },
        },
      ]);
    });
    return asOperation ?? expression;
  });
  /**
   * [113]  	ValueLogical ::= RelationalExpression
   */
  private valueLogical = this.relationalExpression;

  /**
   * [116]  	AdditiveExpression	  ::=
   *              MultiplicativeExpression (
   *                  '+' MultiplicativeExpression
   *                  | '-' MultiplicativeExpression
   *                  | ( NumericLiteralPositive | NumericLiteralNegative ) ( ( '*' UnaryExpression ) | ( '/' UnaryExpression ) )*
   *              )*
   */
  private additiveExpression = this.RULE("additiveExpression", (context: ParserContext): Types.Function => {
    const expression = this.SUBRULE1(this.multiplicativeExpression, { ARGS: [context] });
    const remainder: Array<Types.Expression | PreTreeOperation> = [];
    this.MANY1(() => {
      this.OR1([
        {
          ALT: () => {
            this.CONSUME(T.Plus);

            remainder.push({
              type: "function",
              function: "+",
              args: [this.SUBRULE2(this.multiplicativeExpression, { ARGS: [context] })],
            });
          },
        },
        {
          ALT: () => {
            this.CONSUME(T.Minus);
            remainder.push({
              type: "function",
              function: "-",
              args: [this.SUBRULE3(this.multiplicativeExpression, { ARGS: [context] })],
            });
          },
        },
        {
          // ( NumericLiteralPositive | NumericLiteralNegative ) ( ( '*' UnaryExpression ) | ( '/' UnaryExpression ) )*
          ALT: () => {
            let positiveOrNegative!: "positive" | "negative";
            const numericLiteral = this.OR2([
              {
                ALT: () => {
                  const literal = this.SUBRULE(this.numericLiteralPositive);
                  positiveOrNegative = "positive";
                  return literal;
                },
              },
              {
                ALT: () => {
                  const literal = this.SUBRULE(this.numericLiteralNegative);
                  positiveOrNegative = "negative";
                  return literal;
                },
              },
            ]);
            const unaryExpressions: Array<Types.Expression | PreTreeOperation> = [];
            this.MANY2(() => {
              this.OR3([
                {
                  ALT: () => {
                    this.CONSUME(T.Wildcard);

                    unaryExpressions.push({
                      type: "function",
                      function: "*",
                      args: [this.SUBRULE1(this.unaryExpression, { ARGS: [context] })],
                    });
                  },
                },
                {
                  ALT: () => {
                    this.CONSUME(T.ForwardSlash);
                    unaryExpressions.push({
                      type: "function",
                      function: "/",
                      args: [this.SUBRULE2(this.unaryExpression, { ARGS: [context] })],
                    });
                  },
                },
              ]);
            });
            this.ACTION(() => {
              if (positiveOrNegative === "positive") {
                remainder.push({
                  type: "function",
                  function: "+",
                  args: [createFunctionTree(numericLiteral, unaryExpressions)],
                });
              } else {
                remainder.push({
                  type: "function",
                  function: "-",
                  args: [
                    createFunctionTree(
                      this.createLiteral(numericLiteral.value.substring(1), numericLiteral.datatype, {
                        location: numericLiteral.location,
                      }),
                      unaryExpressions,
                    ),
                  ],
                });
              }
            });
          },
        },
      ]);
    });
    return this.ACTION(() => createFunctionTree(expression, remainder));
  });
  /**
   * [115]  	NumericExpression ::= AdditiveExpression
   */
  private numericExpression = this.additiveExpression;
  /**
   * [117]  	MultiplicativeExpression ::= UnaryExpression ( '*' UnaryExpression | '/' UnaryExpression )*
   */
  private multiplicativeExpression = this.RULE("multiplicativeExpression", (context: ParserContext): Types.Function => {
    const expr = this.SUBRULE1(this.unaryExpression, { ARGS: [context] });
    const remainder: Array<Types.Expression | PreTreeOperation> = [];
    this.MANY(() => {
      this.OR([
        {
          ALT: () => {
            this.CONSUME(T.Wildcard);
            const expr = this.SUBRULE2(this.unaryExpression, { ARGS: [context] });
            remainder.push({
              type: "function",
              function: "*",
              args: [expr],
            });
          },
        },
        {
          ALT: () => {
            this.CONSUME(T.ForwardSlash);
            const expr = this.SUBRULE3(this.unaryExpression, { ARGS: [context] });
            remainder.push({
              type: "function",
              function: "/",
              args: [expr],
            });
          },
        },
      ]);
    });
    return this.ACTION(() => createFunctionTree(expr, remainder));
  });

  /**
   * [118]  	UnaryExpression ::=   '!' PrimaryExpression
   *   | '+' PrimaryExpression
   *   | '-' PrimaryExpression
   * | PrimaryExpression
   */
  private unaryExpression = this.RULE("unaryExpression", (context: ParserContext) => {
    return this.OR([
      {
        ALT: () => {
          this.CONSUME(T.Exclamation);
          const expression = this.SUBRULE1(this.primaryExpression, { ARGS: [context] });
          return { type: "function", function: "!", args: [expression] } satisfies Types.Function;
        },
      },
      {
        ALT: () => {
          this.CONSUME(T.Plus);
          const expression = this.SUBRULE2(this.primaryExpression, { ARGS: [context] });
          return {
            type: "function",
            function: "uplus",
            args: [expression],
          } satisfies Types.Function;
        },
      },
      {
        ALT: () => {
          this.CONSUME(T.Minus);
          const expression = this.SUBRULE3(this.primaryExpression, { ARGS: [context] });
          return {
            type: "function",
            function: "uminus",
            args: [expression],
          } satisfies Types.Function;
        },
      },
      {
        ALT: () => {
          return this.SUBRULE4(this.primaryExpression, { ARGS: [context] });
        },
      },
    ]);
  });

  /**
   * [119]  	PrimaryExpression ::= BrackettedExpression | BuiltInCall | iriOrFunction | RDFLiteral | NumericLiteral | BooleanLiteral | Var
   */
  private primaryExpression = this.RULE("primaryExpression", (context: ParserContext) => {
    return this.OR([
      {
        ALT: () => this.SUBRULE(this.brackettedExpression, { ARGS: [context] }),
      },
      {
        ALT: () => this.SUBRULE(this.builtInCall, { ARGS: [context] }),
      },
      {
        ALT: () => this.SUBRULE(this.iriOrFunction, { ARGS: [context] }),
      },
      {
        ALT: () => this.SUBRULE(this.rdfLiteral),
      },
      {
        ALT: () => this.SUBRULE(this.numericLiteral),
      },
      {
        ALT: () => this.SUBRULE(this.booleanLiteral),
      },
      {
        ALT: () => this.SUBRULE(this.var),
      },
    ]);
  });
  /**
   * [120]  	BrackettedExpression ::= '(' Expression ')'
   */
  private brackettedExpression = this.RULE("brackettedExpression", (context: ParserContext): Types.Expression => {
    this.CONSUME(T.LeftParen);
    const result = this.SUBRULE(this.expression, { ARGS: [context] });
    this.CONSUME(T.RightParen);
    return result;
  });

  /**
   * [121]  	BuiltInCall
   * Aggregate
   * | <long-list-of-built-in-functions>
   * | RegexExpression
   * | ExistsFunc
   * | NotExistsFunc
   */
  private builtInCall = this.RULE("builtInCall", (context: ParserContext) => {
    return this.OR([
      {
        ALT: () => {
          return this.SUBRULE(this.aggregate, { ARGS: [context] });
        },
      },
      {
        ALT: () => this.SUBRULE(this._func_arity_0),
      },
      {
        ALT: () => this.SUBRULE(this._func_arity_1, { ARGS: [context] }),
      },
      {
        ALT: () => this.SUBRULE(this._func_arity_2, { ARGS: [context] }),
      },
      {
        ALT: () => this.SUBRULE(this.regexOrSubstring, { ARGS: [context] }),
      },
      {
        ALT: () => this.SUBRULE(this.strReplaceExpression, { ARGS: [context] }),
      },
      {
        ALT: () => this.SUBRULE(this.existsFunc),
      },
      {
        ALT: () => this.SUBRULE(this.notExistsFunc),
      },

      {
        ALT: () => {
          this.CONSUME(Keywords.If);
          this.CONSUME(T.LeftParen);
          const expression1 = this.SUBRULE1(this.expression, { ARGS: [context] });
          this.CONSUME1(T.Comma);
          const expression2 = this.SUBRULE2(this.expression, { ARGS: [context] });
          this.CONSUME2(T.Comma);
          const expression3 = this.SUBRULE3(this.expression, { ARGS: [context] });
          this.CONSUME(T.RightParen);
          return {
            type: "function",
            function: "if",
            args: [expression1, expression2, expression3],
          } satisfies Types.Function;
        },
      },
      {
        ALT: () => {
          this.CONSUME(Keywords.Bnode);
          this.CONSUME1(T.LeftParen);
          const expression = this.OPTION(() => this.SUBRULE(this.expression, { ARGS: [context] }));
          this.CONSUME2(T.RightParen);
          return {
            type: "function",
            function: "bnode",
            args: expression ? [expression] : [],
          } satisfies Types.Function;
        },
      },
      {
        ALT: () => {
          const name = this.OR2([
            {
              ALT: () => this.CONSUME(Keywords.Coalesce),
            },
            {
              ALT: () => this.CONSUME(Keywords.Concat),
            },
          ]);
          const list = this.SUBRULE(this.expressionList, { ARGS: [context] });
          return this.ACTION(
            () =>
              ({
                type: "function",
                function: name.tokenType.name.toLowerCase() as "concat" | "coalesce",
                args: list,
              }) satisfies Types.Function,
          );
        },
      },
      {
        ALT: () => {
          this.CONSUME(Keywords.Bound);
          this.CONSUME2(T.LeftParen);
          const variable = this.SUBRULE(this.var);
          this.CONSUME3(T.RightParen);
          return { type: "function", function: "bound", args: [variable] } satisfies Types.Function;
        },
      },
    ]);
  });
  private _func_arity_0 = this.RULE("_func_arity_0", () => {
    const func = this.CONSUME(T.FuncArity0);
    const funcName = func.tokenType.name.toLowerCase() as Lowercase<FuncArity0Name>;
    this.SUBRULE(this.nil);
    return { type: "function", function: funcName, args: [] } satisfies Types.Function;
  });
  private _func_arity_1 = this.RULE("_func_arity_1", (context: ParserContext) => {
    const func = this.CONSUME(T.FuncArity1);
    const funcName = func.tokenType.name.toLowerCase() as Lowercase<FuncArity1Name>;
    this.CONSUME(T.LeftParen);
    const expression = this.SUBRULE(this.expression, { ARGS: [context] });
    this.CONSUME(T.RightParen);
    return { type: "function", function: funcName, args: [expression] } satisfies Types.Function;
  });
  private _func_arity_2 = this.RULE("_func_arity_2", (context: ParserContext) => {
    const func = this.CONSUME(T.FuncArity2);
    const funcName = func.tokenType.name.toLowerCase() as Lowercase<FuncArity2Name>;
    this.CONSUME(T.LeftParen);
    const expression1 = this.SUBRULE1(this.expression, { ARGS: [context] });
    this.CONSUME(T.Comma);
    const expression2 = this.SUBRULE2(this.expression, { ARGS: [context] });

    this.CONSUME(T.RightParen);
    return {
      type: "function",
      function: funcName,
      args: [expression1, expression2],
    } satisfies Types.Function;
  });
  /**
   * [122]  	RegexExpression	  ::=  	'REGEX' '(' Expression ',' Expression ( ',' Expression )? ')'
   * [123]  	SubstringExpression	  ::=  	'SUBSTR' '(' Expression ',' Expression ( ',' Expression )? ')'
   */
  private regexOrSubstring = this.RULE("regexOrSubstring", (context: ParserContext) => {
    const name = this.OR([
      {
        ALT: () => this.CONSUME(Keywords.Regex) && ("regex" as const),
      },
      {
        ALT: () => this.CONSUME(Keywords.Substr) && ("substr" as const),
      },
    ]);
    this.CONSUME(T.LeftParen);
    const expression1 = this.SUBRULE1(this.expression, { ARGS: [context] });
    this.CONSUME1(T.Comma);
    const expression2 = this.SUBRULE2(this.expression, { ARGS: [context] });
    const expression3 = this.OPTION(() => {
      this.CONSUME2(T.Comma);
      return this.SUBRULE3(this.expression, { ARGS: [context] });
    });
    this.CONSUME(T.RightParen);
    return {
      type: "function",
      function: name,
      args: expression3 ? [expression1, expression2, expression3] : [expression1, expression2],
    } satisfies Types.Function;
  });

  /**
   * [124]  	StrReplaceExpression	  ::=  	'REPLACE' '(' Expression ',' Expression ',' Expression ( ',' Expression )? ')'
   */
  private strReplaceExpression = this.RULE("strReplaceExpression", (context: ParserContext) => {
    this.CONSUME(Keywords.Replace);

    this.CONSUME(T.LeftParen);
    const expression1 = this.SUBRULE1(this.expression, { ARGS: [context] });
    this.CONSUME1(T.Comma);
    const expression2 = this.SUBRULE2(this.expression, { ARGS: [context] });
    this.CONSUME2(T.Comma);
    const expression3 = this.SUBRULE3(this.expression, { ARGS: [context] });
    const expression4 = this.OPTION(() => {
      this.CONSUME3(T.Comma);
      return this.SUBRULE4(this.expression, { ARGS: [context] });
    });
    this.CONSUME(T.RightParen);
    return {
      type: "function",
      function: "replace",
      args: expression4
        ? [expression1, expression2, expression3, expression4]
        : [expression1, expression2, expression3],
    } satisfies Types.Function;
  });

  /**
   * [125]  	ExistsFunc	  ::=  	'EXISTS' GroupGraphPattern
   */
  private existsFunc = this.RULE("existsFunc", () => {
    this.CONSUME(Keywords.Exists);
    const pattern = this.SUBRULE(this.groupGraphPattern);
    return {
      type: "function",
      function: "exists",
      args: [degroupSingle(pattern)],
    } satisfies Types.Function;
  });
  /**
   * [126]  	NotExistsFunc	  ::=  	'NOT' 'EXISTS' GroupGraphPattern
   */
  private notExistsFunc = this.RULE("notExistsFunc", () => {
    this.CONSUME(Keywords.Not);
    this.CONSUME(Keywords.Exists);
    const pattern = this.SUBRULE(this.groupGraphPattern);
    return {
      type: "function",
      function: "notexists",
      args: [degroupSingle(pattern)],
    } satisfies Types.Function;
  });

  /**
   * [127]  	Aggregate ::=   'COUNT' '(' 'DISTINCT'? ( '*' | Expression ) ')'
   *   | 'SUM' '(' 'DISTINCT'? Expression ')'
   *   | 'MIN' '(' 'DISTINCT'? Expression ')'
   *   | 'MAX' '(' 'DISTINCT'? Expression ')'
   *   | 'AVG' '(' 'DISTINCT'? Expression ')'
   *   | 'SAMPLE' '(' 'DISTINCT'? Expression ')'
   *   | 'GROUP_CONCAT' '(' 'DISTINCT'? Expression ( ';' 'SEPARATOR' '=' String )? ')'
   */
  private aggregate = this.RULE("aggregate", (context: ParserContext): Types.AggregateExpression => {
    const result = this.OR1([
      {
        // : 'COUNT' '(' 'DISTINCT'? ( '*' | Expression ) ')' -> expression($4, { type: 'aggregate', aggregation: lowercase($1), distinct: !!$3 })
        ALT: () => {
          const countToken = this.CONSUME(Keywords.Count);
          this.CONSUME1(T.LeftParen);
          const distinct = this.SUBRULE1(this._optionalDistinctKeyword);
          const expression = this.OR2([
            {
              ALT: () => {
                const token = this.CONSUME(T.Wildcard);
                return { type: "wildcard", location: getLocation(token) } as const;
              },
            },
            {
              ALT: () => this.SUBRULE1(this.expression, { ARGS: [{ aggregate: "not-allowed" }] }),
            },
          ]);
          this.CONSUME1(T.RightParen);
          this.ACTION(() => {
            if (context.aggregate === "not-allowed") {
              throw new InvalidAggregate(`Aggrate function '${countToken.image}' is not allowed here.`, {
                location: getLocation(countToken),
                aggregateFunction: countToken.image,
              });
            }
          });
          return {
            expression,
            type: "aggregate",
            aggregation: "count",
            distinct,
          } satisfies Types.AggregateExpressionWithoutSeparator;
        },
      },
      {
        ALT: () => {
          const aggregationToken = this.OR3([
            {
              ALT: () => this.CONSUME(Keywords.Sum),
            },
            {
              ALT: () => this.CONSUME(Keywords.Min),
            },
            {
              ALT: () => this.CONSUME(Keywords.Max),
            },
            {
              ALT: () => this.CONSUME(Keywords.Avg),
            },
            {
              ALT: () => this.CONSUME(Keywords.Sample),
            },
          ]);
          this.CONSUME2(T.LeftParen);
          const distinct = this.SUBRULE2(this._optionalDistinctKeyword);
          const expression = this.SUBRULE2(this.expression, { ARGS: [{ aggregate: "not-allowed" }] });
          this.CONSUME2(T.RightParen);
          this.ACTION(() => {
            if (context.aggregate === "not-allowed") {
              throw new InvalidAggregate(`Aggrate function '${aggregationToken.image}' is not allowed here.`, {
                location: getLocation(aggregationToken),
                aggregateFunction: aggregationToken.image,
              });
            }
          });
          return this.ACTION(() => {
            return {
              expression,
              type: "aggregate",
              aggregation: aggregationToken.tokenType.name.toLowerCase() as Lowercase<
                typeof aggregationToken.tokenType.name
              >,
              distinct,
            } satisfies Types.AggregateExpressionWithoutSeparator;
          });
        },
      },
      {
        ALT: () => {
          const groupConcatToken = this.CONSUME(Keywords.Group_Concat);
          this.CONSUME3(T.LeftParen);
          const distinct = this.SUBRULE3(this._optionalDistinctKeyword);

          const expression = this.SUBRULE3(this.expression, { ARGS: [{ aggregate: "not-allowed" }] });
          const separator = this.OPTION(() => {
            this.CONSUME(T.SemiColon);
            this.CONSUME(Keywords.Separator);
            this.CONSUME(T.Equals);
            return this.SUBRULE(this.string).string;
          });
          this.CONSUME3(T.RightParen);
          this.ACTION(() => {
            if (context.aggregate === "not-allowed") {
              throw new InvalidAggregate(`Aggrate function '${groupConcatToken.image}' is not allowed here.`, {
                location: getLocation(groupConcatToken),
                aggregateFunction: groupConcatToken.image,
              });
            }
          });
          return {
            expression,
            type: "aggregate",
            aggregation: "group_concat",
            distinct,
            separator: typeof separator === "string" ? separator : " ",
          } satisfies Types.AggregateExpression_GroupConcat;
        },
      },
    ]);

    return result;
  });
  /**
   * [128]  	iriOrFunction ::= iri ArgList?
   */
  private iriOrFunction = this.RULE("iriOrFunction", (context: ParserContext): Types.IriFunction | Types.IriTerm => {
    const iri = this.SUBRULE(this.iri);
    const argList = this.OPTION(() => this.SUBRULE(this.argList, { ARGS: [context] }));
    if (!argList) return iri;
    return { type: "iriFunction", function: iri, ...argList } satisfies Types.IriFunction;
  });
  /**
   * [129]  	RDFLiteral ::= String ( LANGTAG | ( '^^' iri ) )?
   */
  private rdfLiteral = this.RULE("rdfLiteral", () => {
    const lexicalValue = this.SUBRULE(this.string);
    let langTag: string | undefined;
    let datatype: Types.IriTerm | undefined;
    let { endLine, endColumn, startLine, startColumn } = lexicalValue.location || {};

    this.OPTION(() => {
      this.OR([
        {
          ALT: () => {
            const langTagToken = this.CONSUME(T.Langtag);
            this.ACTION(() => {
              langTag = langTagToken.image.substring(1);
              endLine = langTagToken.endLine;
              endColumn = langTagToken.endColumn;
            });
          },
        },
        {
          ALT: () => {
            this.CONSUME1(T.DoubleCaret);
            datatype = this.SUBRULE(this.iri);
            endLine = datatype.location?.endLine;
            endColumn = datatype.location?.endColumn;
          },
        },
      ]);
    });
    return this.ACTION(() => {
      return this.createLiteral(lexicalValue.string, langTag ?? datatype ?? this.xsd.string, {
        location: getLocation({ startLine, startColumn, endLine, endColumn }),
      });
    });
  });
  /**
   * [130]  	NumericLiteral ::= NumericLiteralUnsigned | NumericLiteralPositive | NumericLiteralNegative
   */
  private numericLiteral = this.RULE("numericLiteral", () => {
    return this.OR([
      {
        ALT: () => this.SUBRULE(this.numericLiteralUnsigned),
      },
      {
        ALT: () => this.SUBRULE(this.numericLiteralPositive),
      },
      {
        ALT: () => this.SUBRULE(this.numericLiteralNegative),
      },
    ]);
  });
  /**
   * [131]  	NumericLiteralUnsigned ::= INTEGER | DECIMAL | DOUBLE
   */
  private numericLiteralUnsigned = this.RULE("numericLiteralUnsigned", () => {
    return this.OR([
      {
        ALT: () => {
          const val = this.CONSUME(T.Integer);
          return this.ACTION(() => this.createLiteral(val.image, this.xsd.integer, { location: getLocation(val) }));
        },
      },
      {
        ALT: () => {
          const val = this.CONSUME(T.Decimal);
          return this.ACTION(() => this.createLiteral(val.image, this.xsd.decimal, { location: getLocation(val) }));
        },
      },
      {
        ALT: () => {
          const val = this.CONSUME(T.Double);
          return this.ACTION(() => this.createLiteral(val.image, this.xsd.double, { location: getLocation(val) }));
        },
      },
    ]);
  });
  /**
   * [132]  	NumericLiteralPositive ::= INTEGER_POSITIVE | DECIMAL_POSITIVE | DOUBLE_POSITIVE
   */
  private numericLiteralPositive = this.RULE("numericLiteralPositive", () => {
    return this.OR([
      {
        ALT: () => {
          const val = this.CONSUME(T.Integer_Positive);
          return this.ACTION(() => this.createLiteral(val.image, this.xsd.integer, { location: getLocation(val) }));
        },
      },
      {
        ALT: () => {
          const val = this.CONSUME(T.Decimal_Positive);
          return this.ACTION(() => this.createLiteral(val.image, this.xsd.decimal, { location: getLocation(val) }));
        },
      },
      {
        ALT: () => {
          const val = this.CONSUME(T.Double_Positive);
          return this.ACTION(() => this.createLiteral(val.image, this.xsd.double, { location: getLocation(val) }));
        },
      },
    ]);
  });
  /**
   * [133]  	NumericLiteralNegative ::= INTEGER_NEGATIVE | DECIMAL_NEGATIVE | DOUBLE_NEGATIVE
   */
  private numericLiteralNegative = this.RULE("numericLiteralNegative", () => {
    return this.OR([
      {
        ALT: () => {
          const val = this.CONSUME(T.Integer_Negative);
          return this.ACTION(() => this.createLiteral(val.image, this.xsd.integer, { location: getLocation(val) }));
        },
      },
      {
        ALT: () => {
          const val = this.CONSUME(T.Decimal_Negative);
          return this.ACTION(() => this.createLiteral(val.image, this.xsd.decimal, { location: getLocation(val) }));
        },
      },
      {
        ALT: () => {
          const val = this.CONSUME(T.Double_Negative);
          return this.ACTION(() => this.createLiteral(val.image, this.xsd.double, { location: getLocation(val) }));
        },
      },
    ]);
  });
  /**
   * [134]  	BooleanLiteral ::= 'true' | 'false'
   */
  private booleanLiteral = this.RULE("booleanLiteral", () => {
    return this.OR([
      {
        ALT: () => {
          const val = this.CONSUME(T.True);
          return this.ACTION(() => this.createLiteral("true", this.xsd.boolean, { location: getLocation(val) }));
        },
      },
      {
        ALT: () => {
          const val = this.CONSUME(T.False);
          return this.ACTION(() => this.createLiteral("false", this.xsd.boolean, { location: getLocation(val) }));
        },
      },
    ]);
  });
  /**
   * [135]  	String ::= STRING_LITERAL1 | STRING_LITERAL2 | STRING_LITERAL_LONG1 | STRING_LITERAL_LONG2
   */
  private string = this.RULE("literal", () => {
    const { string, token } = this.OR([
      {
        ALT: () => {
          const token = this.CONSUME(T.String_Literal1);
          return {
            string: token.image.slice(1, -1),
            token,
          };
        },
      },
      {
        ALT: () => {
          const token = this.CONSUME(T.String_Literal2);
          return {
            string: token.image.slice(1, -1),
            token,
          };
        },
      },
      {
        ALT: () => {
          const token = this.CONSUME(T.String_Literal_Long1);
          return {
            string: token.image.slice(3, -3),
            token,
          };
        },
      },
      {
        ALT: () => {
          const token = this.CONSUME(T.String_Literal_Long2);
          return {
            string: token.image.slice(3, -3),
            token,
          };
        },
      },
    ]);
    return { string: unescapeString(string), location: getLocation(token) };
  });
  /**
   * [136]  	iri ::= IRIREF | PrefixedName
   */
  private iri = this.RULE("iri", () => {
    return this.OR([
      {
        ALT: () => {
          const matched = this.CONSUME(T.IriRef);
          return this.ACTION(() => {
            return this.createNamedNode(this._resolveIri(matched.image), {
              surfaceForm: matched.image,
              location: getLocation(matched),
            });
          });
        },
      },
      {
        ALT: () => {
          const { prefixLabel, localName, surfaceForm, location } = this.SUBRULE(this.prefixedName);
          return this.ACTION(() => {
            if (!this.prefixes[prefixLabel]) throw new SparqlAstError(`Undefined prefix ${prefixLabel}`);
            return this.createNamedNode(this._resolveIri(this.prefixes[prefixLabel] + localName), {
              surfaceForm,
              location,
            });
          });
        },
      },
    ]);
  });
  /**
   * [16]  	SourceSelector	  ::=  	iri
   */
  private sourceSelector = this.iri;
  /**
   * [14]  	DefaultGraphClause	  ::=  	SourceSelector
   */
  private defaultGraphClause = this.sourceSelector;
  /**
   * [137]  	PrefixedName ::= PNAME_LN | PNAME_NS
   */
  private prefixedName = this.RULE("prefixedName", () => {
    const prefixToken = this.OR([
      {
        ALT: () => this.CONSUME(T.Pname_Ln),
      },
      {
        ALT: () => this.CONSUME(T.Pname_Ns),
      },
    ]);

    return this.ACTION(() => {
      const prefixString = prefixToken.image;

      const splitAt = prefixString.indexOf(":");
      return {
        location: getLocation(prefixToken),
        surfaceForm: prefixString,
        prefixLabel: prefixString.substring(0, splitAt),
        localName: prefixString.substring(splitAt + 1).replaceAll("\\", ""),
      };
    });
  });
  /**
   * [138]  	BlankNode	  ::=  	BLANK_NODE_LABEL | ANON
   */
  private blankNode = this.RULE("blankNode", () => {
    return this.OR([
      {
        ALT: () => {
          const bnodeToken = this.CONSUME(T.BlankNodeLabel);
          return this.createBnode({ identifier: bnodeToken.image.substring(2), location: getLocation(bnodeToken) });
        },
      },
      {
        ALT: () => {
          return this.SUBRULE(this.anon);
        },
      },
    ]);
  });

  /**
   * [161]  	NIL ::= '(' WS* ')'
   */
  private nil = this.RULE("nil", (): Types.Function[] => {
    this.CONSUME(T.LeftParen);
    this.CONSUME(T.RightParen);
    return [] satisfies Types.Function[];
  });

  private anon = this.RULE("anon", () => {
    const leftSquareToken = this.CONSUME(T.LeftSquare);
    const rightSquareToken = this.CONSUME(T.RightSquare);
    const blank = this.createBnode();
    if (
      leftSquareToken.startLine !== undefined &&
      leftSquareToken.startColumn !== undefined &&
      rightSquareToken.endLine !== undefined &&
      rightSquareToken.endColumn !== undefined
    ) {
      blank.location = {
        startLine: leftSquareToken.startLine,
        startColumn: leftSquareToken.startColumn,
        endLine: rightSquareToken.endLine,
        endColumn: rightSquareToken.endColumn,
      };
    }
    return blank;
  });
  /**
   * [143]  	VAR1 ::= '?' VARNAME
   * [144]  	VAR2 ::= '$' VARNAME
   * [166]  	VARNAME ::= ( PN_CHARS_U | [0-9] ) ( PN_CHARS_U | [0-9] | #x00B7 | [#x0300-#x036F] | [#x203F-#x2040] )*
   */
  private var = this.RULE("var", () => {
    const varname = this.CONSUME(T.Varname);
    return this.createVariable(varname.image.substring(1), {
      surfaceForm: varname.image,
      location: getLocation(varname),
    });
  });
  /**
   * [85]  	VerbSimple ::= Var
   */
  private verbSimple = this.var;
}

// We cannot use ensureOptimization because we are breaking a condition
// https://chevrotain.io/docs/guide/resolving_lexer_errors.html#CUSTOM_OPTIMIZE
export const lexer = new Lexer(allTokens);
const factoryWithoutValidation = getFactory({ skipValidation: true });

export const factory = getFactory({
  // Use consistent bnode prefix
  bnodePrefix: "b",
  // Disable memoization, given that we mutate terms (adding the surface form).
  // Adding memoization may mean that we end up with terms with an incorrect surface form
  disableMemoization: true,
  // If we receive an invalid literal in the SPARQL query, then we make it a xsd:string literal
  onInvalidLiteral: ({ value }) => {
    return { replaceWith: factoryWithoutValidation.literal(value.toString()) };
  },
});
const parser = new SparqlParser(factory);

interface Opts<T extends QueryType> extends Types.ParseOptions {
  type: T;
}

type QueryTypeName = { [QT in Types.SparqlQuery as QT["type"]]: QT } & { [QT in Types.Query as QT["queryType"]]: QT };
type QueryType = keyof QueryTypeName;

export const parsers = {
  /**
   * A parser that applies validation of the AST, e.g. correct use of the group-by clause. You'll want to use this
   * when parsing a query for e.g. speedy. Virtuoso and other sparql endpoints should use the lenient parser
   */
  compliant: getParser("compliant"),
  /**
   * A parser that skips some validation steps. We e.g. skip validating annotations, and skip validation of the group-by clause
   * thats restricted by section 11.4 of the SPARQL spec:
   *
   * > In a query level which uses aggregates, only expressions consisting of
   * > aggregates and constants may be projected, with one exception. When GROUP
   * > BY is given with one or more simple expressions consisting of just a
   * > variable, those variables may be projected from the level.
   * from: https://www.w3.org/TR/sparql11-query/#aggregateRestrictions
   *
   * This is useful because:
   * - Virtuoso happily executes those queries
   * - about one in three queries on TriplyDB instances make use of anomaly in
   *   Virtuoso
   */
  lenient: getParser("lenient"),
};
function getParser(parseMethod: "compliant" | "lenient") {
  function parse<T extends QueryType>(sparqlQuery: string, opts: Opts<T>): QueryTypeName[T];
  function parse(sparqlQuery: string, opts: Types.ParseOptions & { type?: never }): Types.SparqlQuery;
  function parse<T extends QueryType>(sparqlQuery: string, opts: Opts<T> | Types.ParseOptions) {
    /**
     * Tokenize first
     */
    const lexResult = lexer.tokenize(sparqlQuery);
    if (lexResult.errors.length) {
      throw new SparqlLexError(sparqlQuery, lexResult.errors[0]);
    }
    /**
     * Grab annotation blocks and parse using yaml. Not validating yet though, that's in the parse step
     */
    const { parseableTokens, annotations, legacyPaginateAnnotationLines } = dissectAnnotationBlocks(lexResult.tokens);
    parser.input = parseableTokens;
    /**
     * Apply actual parsing. Here, the annotation blocks are validated and included in the AST
     */
    const ast = parser.sparql({
      ...opts,
      baseIri: typeof opts.baseIri === "string" ? opts.baseIri : opts.baseIri.value,
      annotations,
      assertValidAnnotations: !!opts.assertValidAnnotations,
      legacyPaginateAnnotationLines,
    });
    if (parser.errors.length) {
      throw new SparqlGrammarError(sparqlQuery, parser.errors[0]);
    }
    if (opts && "type" in opts) {
      if (ast.type !== opts.type && (!("queryType" in ast) || ast.queryType !== opts.type)) {
        const expected =
          opts.type === "update"
            ? "update operation"
            : opts.type === "query"
              ? "select, construct, describe or ask query"
              : `${opts.type} query`;
        const actual = ast.type === "update" ? "update operation" : `${ast.queryType} query`;
        throw new SparqlAstError(`Expected a SPARQL ${expected}, but got a ${actual}.`);
      }
    }
    if (parseMethod === "compliant") {
      /**
       * We now have a syntactically valid query. Apply extra validation steps to make sure we're valid according to the spec
       */
      validate(ast, opts);
    }
    return ast;
  }
  return parse;
}

/**
 * Remove term metadata such as surfaceform and location
 * This can be useful in unittests, or when we need to compare ASTs where such metadata is irrelevant
 * It takes any object as input, so we can apply this to
 */
export function removeAstMetadata(obj: Object, removeValidationStatus?: "remove validation status") {
  const keysToRemove = removeValidationStatus
    ? (["surfaceForm", "location", "validationStatus"] as const)
    : (["surfaceForm", "location"] as const);
  return eachDeep(obj, (value) => {
    if (typeof value === "object" && value) {
      const isTerm = "termType" in value && "value" in value;
      const isWildcard = "type" in value && value.type === "wildcard";
      if (isTerm || isWildcard) {
        for (const removeKey of keysToRemove) {
          if (value[removeKey]) delete value[removeKey];
        }
      }
    }
  });
}
