// extracted by mini-css-extract-plugin
var _1 = "style--container--Pz1vMLpo";
var _2 = "style--createButton--ST7fzNPx";
var _3 = "style--fullPage--F5Q0d3Zs";
var _4 = "style--groupHeader--ja0TznF7";
var _5 = "style--groupHeaderGray--NIRVgSED";
var _6 = "style--instanceSearch--wo1eCd2R";
var _7 = "style--loading--hSv4KV87";
var _8 = "style--noContentMsg--mwCiK6im";
export { _1 as "container", _2 as "createButton", _3 as "fullPage", _4 as "groupHeader", _5 as "groupHeaderGray", _6 as "instanceSearch", _7 as "loading", _8 as "noContentMsg" }
