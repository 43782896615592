import { formatNumber } from "@core/utils/formatting";
import { MenuItem, MenuList, Tooltip } from "@mui/material";
import getClassName from "classnames";
import { sortBy } from "lodash-es";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { asyncConnect } from "redux-connect";
import { Constants } from "@triply/utils";
import AccessLevelIcon from "#components/AccessLevels/Icon.tsx";
import {
  Alert,
  Avatar,
  EllipsisMenu,
  ErrorPage,
  FlexContainer,
  FontAwesomeIcon,
  HumanizedDate,
  Markdown,
  ResourceWidget,
  Topics,
} from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import type { Account } from "#reducers/accountCollection.ts";
import { getCurrentAccount } from "#reducers/app.ts";
import type { Asset } from "#reducers/assets.ts";
import { getAssets, isAssetListLoaded } from "#reducers/assets.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import type { Dataset } from "#reducers/datasetManagement.ts";
import { getCurrentDataset } from "#reducers/datasetManagement.ts";
import type { Graphs } from "#reducers/graphs.ts";
import { getGraphs, needToFetchGraphs } from "#reducers/graphs.ts";
import type { GlobalState } from "#reducers/index.ts";
import type { ResourceDescriptions } from "#reducers/resourceDescriptions.ts";
import {
  descriptionIsLoadedFor,
  getDescription,
  getLabel,
  getLabelFromLocalName,
  getStatementsAsTree,
  getWidgetCollectionMemoized,
  selectAudio,
  selectGeometry,
  selectImage,
  selectLabel,
  selectVideo,
} from "#reducers/resourceDescriptions.ts";
import type { Services } from "#reducers/services.ts";
import DatasetCopyModal from "./DatasetCopyModal/index.tsx";
import Dependents from "./Dependents.tsx";
import Meta from "./Meta.tsx";
import * as styles from "./style.scss";

export namespace DatasetInfo {
  export interface OwnProps extends IComponentProps {}
  export interface PropsFromState {
    currentDs?: Dataset;
    currentAccount?: Account;
    graphs?: Graphs;
    resourceDescriptions?: ResourceDescriptions;
    services?: Services;
    assets?: Asset[];
  }
  export type Props = OwnProps & PropsFromState;
}

const DatasetInfo: React.FC<DatasetInfo.Props> = ({
  currentDs,
  currentAccount,
  graphs,
  resourceDescriptions,
  assets,
  services: services,
}) => {
  const acl = useAcl();
  const authenticatedUser = useAuthenticatedUser();
  if (!currentAccount || !currentDs) return <ErrorPage statusCode={404} />;
  const canCopyDataset = acl.check({
    action: "copyDataset",
    context: { accessLevel: currentDs.accessLevel, roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  const hasGraphs = currentDs.graphCount > 0;
  const canShowMenu = canCopyDataset || hasGraphs;

  return (
    <FlexContainer>
      <Meta currentAccount={currentAccount} currentDs={currentDs} services={services} />
      <div className={getClassName("whiteSink")}>
        <div className={getClassName("flex", styles.header)}>
          <Avatar
            size="xl"
            avatarName={currentDs.displayName || currentDs.name}
            avatarUrl={currentDs.avatarUrl}
            title={currentDs.name}
            alt=""
          />

          <div className={styles.primaryInfo}>
            <div className={getClassName("flex", styles.title)}>
              <h1 className="headerSpacing">{currentDs.displayName || currentDs.name}</h1>
              <AccessLevelIcon
                level={currentDs.accessLevel}
                type="dataset"
                addTitleForAccountType={currentDs.owner.type}
                size="lg"
                style={{ marginLeft: 15 }}
              />
            </div>

            <div className={getClassName("flex", styles.by)}>
              by{" "}
              <h4>
                <Link to={`/${currentDs.owner.accountName}`}>{currentAccount.name || currentAccount.accountName}</Link>
              </h4>
            </div>

            {currentDs.lastGraphsUpdateTime && (
              <div className={styles.created}>
                Last updated <HumanizedDate date={currentDs.lastGraphsUpdateTime} />
              </div>
            )}

            <div className={styles.created}>
              Created <HumanizedDate date={currentDs.createdAt} />
            </div>

            <div className={styles.statements}>{`${formatNumber(currentDs.statements)} statements`}</div>
          </div>
          <div className="grow"></div>
          {canShowMenu && (
            <EllipsisMenu className={styles.menu}>
              {canCopyDataset && authenticatedUser && (
                <DatasetCopyModal authenticatedUser={authenticatedUser} currentDs={currentDs}>
                  <MenuItem title="Copy dataset">Copy</MenuItem>
                </DatasetCopyModal>
              )}
              {hasGraphs && (
                <MenuItem
                  title="Download dataset"
                  component="a"
                  href={`/${currentDs.owner.accountName}/${currentDs.name}/download.trig.gz`}
                  className={styles.menuItemNoLink}
                  download
                >
                  Download
                </MenuItem>
              )}
            </EllipsisMenu>
          )}
        </div>
        <Markdown>{currentDs.description}</Markdown>
        {currentDs.license && (
          <div>
            <h5 className="headerSpacing paragraphHeader">License</h5>
            <a href={Constants.LICENSES[currentDs.license]?.url} rel="noopener noreferrer" target="_blank">
              {Constants.LICENSES[currentDs.license].gui_name}
            </a>
          </div>
        )}

        {currentDs.topics && currentDs.topics.length > 0 && (
          <div>
            <h5 className="headerSpacing paragraphHeader">Topics</h5>
            <Topics topics={currentDs.topics} implicitTopics={currentDs.implicitTopics} />
          </div>
        )}

        {graphs && graphs.length > 0 && (
          <div>
            <div className={styles.graphsHeading}>
              <h5 className="headerSpacing mr-3 paragraphHeader">Graphs</h5>
              <Link
                to={`/${currentDs.owner.accountName}/${currentDs.name}/download.trig.gz`}
                target="_blank"
                download
                className="clickableIcon"
                title={`Export ${currentDs.graphCount > 1 ? "all graphs" : "graph"}`}
              >
                <FontAwesomeIcon icon="arrow-to-bottom" />
              </Link>
            </div>
            <div>
              {sortBy(graphs, "numberOfStatements")
                .reverse()
                .map((graph) => {
                  const desc = resourceDescriptions?.[graph.graphName];
                  const tree = desc && getStatementsAsTree(graph.graphName, desc.statements, "forward");
                  return (
                    <div key={graph.id} className={styles.graph}>
                      <div title={graph.graphName} style={{ minWidth: 150, flexGrow: 1 }}>
                        <Link
                          to={{
                            pathname: `/${currentAccount.accountName}/${currentDs.name}/table`,
                            search: stringifyQuery({ graph: graph.graphName }),
                          }}
                        >
                          {getLabel(tree) || getLabelFromLocalName(graph.graphName)}
                        </Link>
                      </div>
                      <div style={{ width: 300 }}>
                        <div
                          style={{
                            width: currentDs.statements ? (300 * graph.numberOfStatements) / currentDs.statements : 0,
                          }}
                          className={styles.nr}
                        >
                          <div>{formatNumber(graph.numberOfStatements)}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {(currentDs.graphCount > Constants.MAX_GRAPHS_ON_LANDING_PAGE ||
                graphs.length < currentDs.graphCount) && (
                <div>
                  <Link to={`/${currentAccount.accountName}/${currentDs.name}/graphs`}>...</Link>
                </div>
              )}
            </div>
          </div>
        )}

        {resourceDescriptions && currentDs.exampleResources && currentDs.exampleResources.length > 0 && (
          <div>
            <h5 className="headerSpacing paragraphHeader">Example resources</h5>
            <div className={styles.exampleResources}>
              {currentDs.exampleResources.map((r) => {
                const desc = resourceDescriptions[r];
                const tree = desc && getStatementsAsTree(r, desc && desc.statements, "forward");
                return (
                  tree && (
                    <ResourceWidget
                      key={r}
                      resource={tree.getTerm()}
                      widgetCollection={getWidgetCollectionMemoized(tree, [
                        selectLabel,
                        selectImage,
                        selectGeometry,
                        selectAudio,
                        selectVideo,
                      ])}
                      linkPath={`/${currentAccount.accountName}/${currentDs.name}/browser`}
                    />
                  )
                );
              })}
            </div>
          </div>
        )}

        {assets?.length && (
          <div>
            <h5 className="headerSpacing paragraphHeader">Assets</h5>
            <div>
              {assets.slice(0, 4).map((f) => (
                <div key={f.assetName}>
                  <a
                    href={`/${currentAccount.accountName}/${currentDs.name}/assets/${f.identifier}`}
                    download
                    target="_blank"
                  >
                    {f.assetName}
                  </a>
                </div>
              ))}
              {assets.length > 4 && (
                <div>
                  <Link to={`/${currentAccount.accountName}/${currentDs.name}/assets`}>...</Link>
                </div>
              )}
            </div>
          </div>
        )}

        <Dependents datasetId={currentDs.id} />
      </div>
      {acl.check({
        action: "importDataToDataset",
        context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
      }).granted &&
        (!graphs || graphs.length === 0) && (
          <Link to={`/${currentAccount.accountName}/${currentDs.name}/graphs`} className="noLinkDecoration">
            <Alert
              className={getClassName("shadow", styles.alert)}
              key="alert"
              message="This dataset is empty. To add data, click here"
              info
            />
          </Link>
        )}
    </FlexContainer>
  );
};

export default connect<DatasetInfo.PropsFromState, {}, DatasetInfo.OwnProps, GlobalState>((state) => {
  const currentDs = getCurrentDataset(state);
  const graphs = currentDs && state.graphs[currentDs.id];
  return {
    currentDs: currentDs,
    currentAccount: getCurrentAccount(state),
    graphs: graphs?.largestList,
    resourceDescriptions: currentDs && state.resourceDescriptions[currentDs.id],
    services: currentDs && state.services[currentDs.id],
    assets: currentDs && state.assets[currentDs.id] && state.assets[currentDs.id].list,
  };
})(
  asyncConnect<GlobalState>([
    {
      promise: ({ store: { dispatch, getState } }) => {
        const state = getState();
        const currentDs = getCurrentDataset(state);

        if (currentDs && currentDs.exampleResources) {
          const promises: Promise<any>[] = [];
          currentDs.exampleResources.forEach((resource) => {
            if (
              !descriptionIsLoadedFor({
                scope: "all",
                state: state.resourceDescriptions,
                dataset: currentDs,
                resource: resource,
                concise: true,
              })
            ) {
              promises.push(
                dispatch<any>(getDescription({ dataset: currentDs, resource: resource, concise: true })).catch(
                  () => {},
                ),
              );
            }
          });
          return Promise.all(promises);
        }
      },
    },
    {
      promise: ({ store: { dispatch, getState } }) => {
        const state = getState();
        const currentDs = getCurrentDataset(state);
        const currentAccount = getCurrentAccount(state);
        if (currentAccount && currentDs && currentDs.assetCount > 0 && !isAssetListLoaded(state, currentDs)) {
          return dispatch<any>(getAssets(currentAccount, currentDs));
        }
      },
    },
  ])(DatasetInfo) as typeof DatasetInfo,
);
