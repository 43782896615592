import getClassName from "classnames";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";
import type BasicTree from "#helpers/BasicTree.ts";
import * as styles from "./style.scss";

interface Props {
  node: BasicTree;
  onClick?: (node: BasicTree) => any;
}

const Tree: React.FC<Props> = ({ node, onClick }) => {
  const [showChildren, setShowChildren] = React.useState(false);

  const label = (
    <div key={node.getData().id} className={getClassName("px-3 py-1 flex", styles.node)}>
      <button
        onClick={onClick ? () => onClick(node) : () => setShowChildren(!showChildren)}
        style={{ marginLeft: node.getDepth() * 10 - 2, paddingLeft: 2, flexGrow: 1 }}
        className={styles.label}
      >
        {node.getData().label}
      </button>
      {node.getChildren().length > 0 && (
        <button onClick={() => setShowChildren(!showChildren)} className={styles.expander} aria-label="Expand topic">
          <FontAwesomeIcon icon={["fas", showChildren ? "caret-down" : "caret-right"]} />
        </button>
      )}
    </div>
  );
  const children = showChildren
    ? node.getChildren().map((n) => <Tree key={n.getData().id} node={n} onClick={onClick} />)
    : [];
  return [label, ...children] as any;
};

export default Tree;
