import getClassName from "classnames";
import type { GraphEdge } from "dagre";
import type { Location } from "history";
import React from "react";
import { useHistory } from "react-router";
import type { EdgeData } from "./useData.ts";
import { escapeId } from "./utils.ts";
import * as styles from "./style.scss";

const EdgeLabel: React.FC<{ data: EdgeData; layoutData?: GraphEdge; location: Location }> = ({ data, layoutData }) => {
  const history = useHistory();
  return (
    <g
      className={getClassName(styles.edgeLabel, {
        [styles.owl]: !!data.owl,
        [styles.shacl]: !!data.shacl,
        [styles.blur]: !!data.blur,
      })}
      style={{
        transform: layoutData
          ? `translate(${layoutData.x - layoutData.width / 2}px,${layoutData.y - layoutData.height / 2}px)`
          : "",
      }}
      onClick={(e) => {
        e.stopPropagation();
        history.push({ hash: `#${encodeURIComponent(data.from)}`, search: location.search });
      }}
    >
      <rect x={-5} y={-3} width={layoutData ? layoutData.width + 10 : undefined} height={25} rx={5} />
      <text id={"edgeLabel-" + escapeId(`${data.from}-${data.to}-${data.label}`)} dy={14}>
        {data.prefix && <tspan fill={data.color}>{data.prefix + ":"}</tspan>}
        {data.label}
      </text>
    </g>
  );
};
export default EdgeLabel;
