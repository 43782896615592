import { assign, omit } from "lodash-es";
import type { Store } from "redux";
import type ApiClient from "#helpers/ApiClient.ts";
import type { GlobalState } from "#reducers/index.ts";

var createClientMiddleware = function (client: ApiClient): any {
  return (store: Store<GlobalState>) => {
    return (next: Function) => (action: any) => {
      try {
        if (typeof action === "function") {
          return action(store.dispatch, store.getState);
        }
        const { promise, types } = action;
        const rest = omit(action, ["promise, types"]);
        // const {promise, types, ...rest} = action; // eslint-disable-line no-redeclare
        if (!promise) {
          return next(action);
        }
        const [REQUEST, SUCCESS, FAILURE] = types;
        next(assign({}, rest, { type: REQUEST }));
        const actionPromise = promise(client);
        actionPromise
          .then(
            (result: any) => {
              if (result && result.body && result.meta) {
                //this promise comes from our API client
                next(
                  assign({}, rest, {
                    result: result.body,
                    meta: result.meta,
                    type: SUCCESS,
                  }),
                );
              } else {
                next(assign({}, rest, { result: result, type: SUCCESS }));
              }
            },
            (error: any) => {
              console.error("ERRR", error);
              next(
                assign({}, rest, error, {
                  message: error.message,
                  devMessage: error.serverError,
                  status: error.status,
                  type: FAILURE,
                }),
              );
            },
          )
          .catch((error: any) => {
            console.error("MIDDLEWARE ERROR:", error);
            console.error("related action: ", action);
            next(assign({}, rest, error, { type: FAILURE }));
          });
        return actionPromise;
      } catch (e) {
        console.error("MIDDLEWARE ERROR:", e, action);
      }
    };
  };
};
export default createClientMiddleware;
