/**
 * W3C XML Schema Definition Language (XSD) 1.1 Part 2: Datatypes.
 * --------------------------------------------------------------
 *  A W3C Recommendation published on 2012-04-05
 *  @see https://www.w3.org/TR/xmlschema11-2/
 */
import { EmbeddedActionsParser, Lexer } from "chevrotain";
import { hexBinaryToken } from "./tokens.ts";

/**
 * [24] hexDigit ::= [0-9a-fA-F]
 * [25] hexOctet ::= hexDigit hexDigit
 * [26] hexBinary ::= hexOctet*
 */

// I figured that if we can encode everything into the lexer, that'd be faster
const tokens = [hexBinaryToken];
export class HexBinaryParser extends EmbeddedActionsParser {
  constructor() {
    super(
      tokens,
      // https://chevrotain.io/docs/guide/initialization_performance.html#use-a-smaller-global-maxlookahead
      // Even though we don't expect big performance difference between this value and the default (maxLookAhead=3),
      // we kept it here as a best practice for when designing rules in the future.
      { maxLookahead: 2 },
    );
    this.performSelfAnalysis();
  }

  public hexBinary = this.RULE(
    "hexBinary",
    () => this.OPTION(() => this.CONSUME(hexBinaryToken).image.toUpperCase()) ?? "",
  );
}

export const lexer = new Lexer(tokens, {
  ensureOptimizations: true,
  // not tracking lines for this grammar (not setting this will print a warning)
  positionTracking: "onlyOffset",
});
export const parser = new HexBinaryParser();
