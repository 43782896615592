import type { CompletionContext, CompletionResult } from "@codemirror/autocomplete";
import { ifIn } from "@codemirror/autocomplete";
import { syntaxTree } from "@codemirror/language";
import type { SyntaxNode } from "@lezer/common";
import Debug from "debug";
import { OutsidePrefixes } from "../facets/outsidePrefixes.ts";
import { definedPrefixesField } from "../fields/definedPrefixes.ts";
import { PNAME_NS, PREFIX_KEY, PrefixDecl } from "../grammar/sparqlParser.terms.ts";

const debug = Debug("triply:sparql-editor:autocomplete:prefixes");

export function sparqlPrefixAutocompleteFunc(context: CompletionContext): CompletionResult | null {
  const prefixes = context.state.facet(OutsidePrefixes);
  if (prefixes.length === 0) return null;
  debug("Start autocompletion");
  const { state, pos } = context;
  const autocompleteNode = syntaxTree(state).resolve(pos, -1);
  let prefixDeclaration: SyntaxNode | undefined = undefined;
  // We should complete when
  // We are inside whitespace inside of the PrefixDeclToken
  if (autocompleteNode.type.id === PrefixDecl) {
    prefixDeclaration = autocompleteNode;
    // Anythings directy parent is a PrefixDeclaration
  } else if (autocompleteNode.matchContext(["PrefixDecl"])) {
    prefixDeclaration = autocompleteNode.parent!;
    // We can be in an error state if a token we didn't expect is recognized. For example, in `PREFIX x: <http: ` Both `x:` and `http:` are tokenized as prefix names. However we still want to autocomplete this
  } else if (autocompleteNode.parent?.type.isError && autocompleteNode.parent?.matchContext(["PrefixDecl"])) {
    prefixDeclaration = autocompleteNode.parent.parent!;
  }
  if (!prefixDeclaration) return null;
  const prefixKeyWord = prefixDeclaration.getChild(PREFIX_KEY);
  // Don't expect this to ever be null
  if (!prefixKeyWord) return null;

  const currentPrefixNameNode = prefixDeclaration.getChild(PNAME_NS);
  const currentPrefixName =
    currentPrefixNameNode && state.sliceDoc(currentPrefixNameNode.from, currentPrefixNameNode.to - 1);

  // Generate the autocomplete suggestions
  const autocompleteLine = state.doc.lineAt(prefixDeclaration.from);
  const needToAddSpace = prefixKeyWord.to === autocompleteLine.to;

  const definedPrefixes = state
    .field(definedPrefixesField)
    .filter((prefixDec) => prefixDec.prefixLabel !== currentPrefixName)
    .map((prefix) => prefix.prefixLabel);

  return {
    from: prefixKeyWord.to + (needToAddSpace ? 0 : 1),
    to: autocompleteLine.to, // While syntactically allowed, lets just focus on the current line for autocompletion
    validFor: (_text, from, _to, _state) => from === prefixDeclaration?.from,
    options: prefixes
      .filter((prefix) => !definedPrefixes.includes(prefix.prefixLabel)) // Apply the filter
      .map((prefix) => {
        return {
          label: `${prefix.prefixLabel}: <${prefix.iri}>`,
          apply: `${needToAddSpace ? " " : ""}${prefix.prefixLabel}: <${prefix.iri}>`,
        };
      }),
  };
}
const sparqlPrefixAutocomplete = ifIn(["PrefixDecl"], sparqlPrefixAutocompleteFunc);

export default sparqlPrefixAutocomplete;
