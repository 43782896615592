import { Alert } from "@mui/material";
import { set } from "lodash-es";
import * as React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import type { DashViewer, DashViewerProps } from ".";

const LabelViewerComponent: React.FC<DashViewerProps> = ({ property }) => {
  const location = useLocation();
  const search = useCurrentSearch();

  if (property.nodeKind !== "IRI" || property.valueLabel === undefined)
    return <Alert severity="error">Wrongly configured Labelviewer</Alert>;

  set(search, "resource", property.value);
  return (
    <Link
      to={{
        pathname: location.pathname,
        search: stringifyQuery(search),
      }}
      draggable
      onDragStart={(e) => e.dataTransfer.setData("text/plain", property.value)}
    >
      {property.valueLabel}
    </Link>
  );
};

const LabelViewer: DashViewer = {
  component: LabelViewerComponent,
  getScore({ nodeKind, label }) {
    if (nodeKind === "IRI" && label !== undefined) return 5;
    return 0;
  },
};

export default LabelViewer;
