import { Alert, Typography } from "@mui/material";
import * as React from "react";
import type { DashViewer, DashViewerProps } from ".";
import * as styles from "./style.scss";

const RDF_LANG_STRING = "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString";

const LangStringViewerComponent: React.FC<DashViewerProps> = ({ property }) => {
  if (property.nodeKind !== "Literal" || property.datatype !== "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString")
    return <Alert severity="error">Wrongly configured Langstring</Alert>;
  return (
    <span>
      <Typography variant="body1" component="span" className={styles.multiline}>
        {property.value}
      </Typography>
      <Typography variant="body2" component="span">
        @{property.language}
      </Typography>
    </span>
  );
};

const LangStringViewer: DashViewer = {
  component: LangStringViewerComponent,
  getScore({ nodeKind, datatype }) {
    if (nodeKind === "Literal" && datatype === RDF_LANG_STRING) return 10;
    return 0;
  },
};

export default LangStringViewer;
