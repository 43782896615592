import type { MenuItem, MenuItemProps } from "@mui/material";
import { IconButton, Menu } from "@mui/material";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";

interface Props {
  className?: string;
  children: (React.ReactElement<MenuItemProps, typeof MenuItem> | null | false | undefined)[];
}

const EllipsisMenu: React.FC<Props> = (props) => {
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

  return (
    <div className={props.className}>
      <IconButton
        onClick={(event: React.MouseEvent<HTMLElement>) => setMenuAnchor(event.currentTarget)}
        aria-label="Open menu"
        size="large"
      >
        <FontAwesomeIcon icon="ellipsis-v" style={{ width: "1em" }} />
      </IconButton>
      <Menu anchorEl={menuAnchor} keepMounted open={!!menuAnchor} onClose={() => setMenuAnchor(null)}>
        {React.Children.map(
          props.children.filter((child) => !!child),
          (menuItem: any) =>
            React.cloneElement(menuItem, {
              onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                setMenuAnchor(null);
                menuItem.props.onClick?.(e);
              },
            }),
        )}
      </Menu>
    </div>
  );
};

export default EllipsisMenu;
