import { upperFirst } from "lodash-es";
import { IllegalTermType } from "./DataFactory.ts";
import type { BaseQuad, QuadPosition, Term, TermType, TermTypeToTerm } from "./Terms.ts";
import { BASE_QUAD_TERM_TYPES } from "./Terms.ts";

export function isValidTerm<T extends TermType>(term: Term, allowedTermTypes: T[]): term is TermTypeToTerm[T] {
  return allowedTermTypes.includes(term.termType as T);
}
export function assertIsValidTerm<T extends TermType>(
  term: Term,
  allowedTermTypes: T[],
  position: QuadPosition,
): asserts term is TermTypeToTerm[T] {
  if (!isValidTerm(term, allowedTermTypes))
    throw new IllegalTermType(
      `${upperFirst(position)} of type '${
        term.termType
      }' is not valid. Only the following termtypes are allowed: ${allowedTermTypes.join(", ")}`,
    );
}

export function assertIsValidQuad<
  S extends BaseQuad["subject"]["termType"],
  P extends BaseQuad["predicate"]["termType"],
  O extends BaseQuad["object"]["termType"],
  G extends BaseQuad["graph"]["termType"],
>(
  allowedTerms:
    | {
        subject: Array<S>;
        predicate: Array<P>;
        object: Array<O>;
        graph: Array<G>;
      }
    | undefined,
  subject: TermTypeToTerm[S],
  predicate: TermTypeToTerm[P],
  object: TermTypeToTerm[O],
  graph?: TermTypeToTerm[G],
) {
  const allowedQuadPositions = allowedTerms || BASE_QUAD_TERM_TYPES;
  assertIsValidTerm(subject, allowedQuadPositions.subject, "subject");
  assertIsValidTerm(predicate, allowedQuadPositions.predicate, "predicate");
  assertIsValidTerm(object, allowedQuadPositions.object, "object");
  if (graph) assertIsValidTerm(graph, allowedQuadPositions.graph, "graph");
}

export function isValidQuad<
  S extends BaseQuad["subject"]["termType"],
  P extends BaseQuad["predicate"]["termType"],
  O extends BaseQuad["object"]["termType"],
  G extends BaseQuad["graph"]["termType"],
>(
  allowedTerms:
    | {
        subject: Array<S>;
        predicate: Array<P>;
        object: Array<O>;
        graph: Array<G>;
      }
    | undefined,
  subject: TermTypeToTerm[S],
  predicate: TermTypeToTerm[P],
  object: TermTypeToTerm[O],
  graph?: TermTypeToTerm[G],
) {
  const allowedQuadPositions = allowedTerms || BASE_QUAD_TERM_TYPES;
  return (
    isValidTerm(subject, allowedQuadPositions.subject) &&
    isValidTerm(predicate, allowedQuadPositions.predicate) &&
    isValidTerm(object, allowedQuadPositions.object) &&
    graph &&
    isValidTerm(graph, allowedQuadPositions.graph)
  );
}
