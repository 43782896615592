/**
 * W3C XML Schema Definition Language (XSD) 1.1 Part 2: Datatypes.
 * --------------------------------------------------------------
 *  A W3C Recommendation published on 2012-04-05
 *  @see https://www.w3.org/TR/xmlschema11-2/
 */
import { EmbeddedActionsParser, Lexer } from "chevrotain";
import { ValueRangeError } from "../Errors.ts";
import { Digit, Dot, Sign } from "./tokens.ts";

const tokens = [Sign, Digit, Dot];
export class IntegerParser extends EmbeddedActionsParser {
  constructor() {
    super(
      tokens,
      // https://chevrotain.io/docs/guide/initialization_performance.html#use-a-smaller-global-maxlookahead
      // Even though we don't expect big performance difference between this value and the default (maxLookAhead=3),
      // we kept it here as a best practice for when designing rules in the future.
      { maxLookahead: 2 },
    );
    this.performSelfAnalysis();
  }

  /**
   * [47] noDecimalPtNumeral ::= ('+' | '-')? unsignedNoDecimalPtNumeral
   */
  private noDecimalPtNumeral = this.RULE("noDecimalPtNumeral", () => {
    let value = this.OPTION(() => this.CONSUME(Sign))?.image ?? "";
    /**
     * [46] unsignedNoDecimalPtNumeral ::= digit+
     */
    this.AT_LEAST_ONE(() => (value += this.CONSUME(Digit).image));
    return value;
  });

  public byte = this.RULE("byte", () => {
    const result = this.SUBRULE(this.noDecimalPtNumeral);
    this.ACTION(() => validateByte(result));
    return result;
  });

  public integer = this.RULE("integer", () => {
    const result = this.SUBRULE(this.noDecimalPtNumeral);
    this.ACTION(() => validateInteger(result));
    return result;
  });
  public int = this.RULE("int", () => {
    const result = this.SUBRULE(this.noDecimalPtNumeral);
    this.ACTION(() => validateInt(result));
    return result;
  });
  public long = this.RULE("long", () => {
    const result = this.SUBRULE(this.noDecimalPtNumeral);
    this.ACTION(() => validateLong(result));
    return result;
  });
  public negativeInteger = this.RULE("negativeInteger", () => {
    const result = this.SUBRULE(this.noDecimalPtNumeral);
    this.ACTION(() => validateNegativeInteger(result));
    return result;
  });
  public nonNegativeInteger = this.RULE("nonNegativeInteger", () => {
    const result = this.SUBRULE(this.noDecimalPtNumeral);
    this.ACTION(() => validateNonNegativeInteger(result));
    return result;
  });
  public nonPositiveInteger = this.RULE("nonPositiveInteger", () => {
    const result = this.SUBRULE(this.noDecimalPtNumeral);
    this.ACTION(() => validateNonPositiveInteger(result));
    return result;
  });
  public positiveInteger = this.RULE("positiveInteger", () => {
    const result = this.SUBRULE(this.noDecimalPtNumeral);
    this.ACTION(() => validatePositiveInteger(result));
    return result;
  });
  public short = this.RULE("short", () => {
    const result = this.SUBRULE(this.noDecimalPtNumeral);
    this.ACTION(() => validateShort(result));
    return result;
  });
  public unsignedByte = this.RULE("unsignedByte", () => {
    const result = this.SUBRULE(this.noDecimalPtNumeral);
    this.ACTION(() => validateUnsignedByte(result));
    return result;
  });
  public unsignedInt = this.RULE("unsignedInt", () => {
    const result = this.SUBRULE(this.noDecimalPtNumeral);
    this.ACTION(() => validateUnsignedInt(result));
    return result;
  });
  public unsignedLong = this.RULE("unsignedLong", () => {
    const result = this.SUBRULE(this.noDecimalPtNumeral);
    this.ACTION(() => validateUnsignedLong(result));
    return result;
  });
  public unsignedShort = this.RULE("unsignedShort", () => {
    const result = this.SUBRULE(this.noDecimalPtNumeral);
    this.ACTION(() => validateUnsignedShort(result));
    return result;
  });
}

export const lexer = new Lexer(tokens, {
  ensureOptimizations: true,
  // not tracking lines for this grammar (not setting this will print a warning)
  positionTracking: "onlyOffset",
});
export const parser = new IntegerParser();

const BYTE_MAX = 127;
const BYTE_MIN = -128;
const INT_MAX = 2147483647;
const INT_MIN = -2147483648;
const LONG_MAX = BigInt("9223372036854775807");
const LONG_MIN = BigInt("-9223372036854775808");
const SHORT_MAX = 32767;
const SHORT_MIN = -32768;
const UNSIGNED_BYTE_MAX = 255;
const UNSIGNED_INT_MAX = 4294967295;
const UNSIGNED_LONG_MAX = BigInt("18446744073709551615");
const UNSIGNED_SHORT_MAX = 65535;

function validateInteger(value: string) {
  if (!Number.isInteger(+value)) throw new ValueRangeError({ value: value });
}
function validateLong(value: string) {
  validateInteger(value);
  if (!(BigInt(value) <= LONG_MAX && BigInt(value) >= LONG_MIN)) throw new ValueRangeError({ value: value });
}
function validateInt(value: string) {
  validateLong(value);
  if (!(+value <= INT_MAX && +value >= INT_MIN)) throw new ValueRangeError({ value: value });
}
function validateShort(value: string) {
  validateInt(value);
  if (!(+value <= SHORT_MAX && +value >= SHORT_MIN)) throw new ValueRangeError({ value: value });
}
function validateByte(value: string) {
  validateShort(value);
  if (!(+value <= BYTE_MAX && +value >= BYTE_MIN)) throw new ValueRangeError({ value: value });
}
function validateNonNegativeInteger(value: string) {
  validateInteger(value);
  if (!(+value >= 0)) throw new ValueRangeError({ value: value });
}
function validatePositiveInteger(value: string) {
  validateNonNegativeInteger(value);
  if (!(+value !== 0)) throw new ValueRangeError({ value: value });
}
function validateUnsignedInt(value: string) {
  validateNonNegativeInteger(value);
  if (!(+value <= UNSIGNED_INT_MAX)) throw new ValueRangeError({ value: value });
}
function validateUnsignedShort(value: string) {
  validateUnsignedInt(value);
  if (!(+value <= UNSIGNED_SHORT_MAX)) throw new ValueRangeError({ value: value });
}
function validateUnsignedByte(value: string) {
  validateUnsignedShort(value);
  if (!(+value <= UNSIGNED_BYTE_MAX)) throw new ValueRangeError({ value: value });
}
function validateUnsignedLong(value: string) {
  validateNonNegativeInteger(value);
  if (!(+value <= UNSIGNED_LONG_MAX)) throw new ValueRangeError({ value: value });
}
function validateNonPositiveInteger(value: string) {
  validateInteger(value);
  if (!(+value <= 0)) throw new ValueRangeError({ value: value });
}
function validateNegativeInteger(value: string) {
  validateNonPositiveInteger(value);
  if (!(+value !== 0)) throw new ValueRangeError({ value: value });
}
