import { MenuItem } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import * as ReduxForm from "redux-form";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { Alert, FormField, Label, MaterialSelectRedux, MuiSwitchRedux, SimpleDateField } from "#components/index.ts";
import type { Role } from "#helpers/Acl.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import type { GlobalState } from "#reducers/index.ts";

namespace AccountPermissionsForm {
  export interface FormData {
    role: Role;
    expiresAt: string;
    verified: boolean;
  }

  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    editingSelf: boolean;
    authMethod?: string;
  }
}

const REMOVE_SELF_AS_SITE_ADMIN_WARNING =
  "Warning: You are removing yourself as administrator, and you will not be able to regain these privileges without assistance";
const DEVERIFY_SELF_WARNING =
  "Warning: You are unverifying your account, and you will not be able to regain access without assistance";

const _AccountPermissionsForm: React.FC<AccountPermissionsForm.Props> = ({
  authMethod,
  editingSelf,
  error,
  handleSubmit,
  pristine,
  submitSucceeded,
  submitting,
}) => {
  const triplyDbComMode = useSelector((state: GlobalState) => {
    return !!state.config?.clientConfig?.triplydb;
  });
  const acl = useAcl();
  const renderRoleOptions = React.useMemo(() => {
    return [
      triplyDbComMode && (
        <MenuItem key="light" value="light">
          Light
        </MenuItem>
      ),
      <MenuItem key="regular" value="regular">
        Regular
      </MenuItem>,
      <MenuItem key="siteAdmin" value="siteAdmin">
        Site admin
      </MenuItem>,
      acl.check({ action: "changeUserRole", context: { toRole: "superAdmin", fromRole: "superAdmin" } }).granted ? (
        <MenuItem key="superAdmin" value="superAdmin">
          Super admin
        </MenuItem>
      ) : null,
    ].filter((el) => !!el);
  }, [acl, triplyDbComMode]);

  const renderVerifiedField = React.useMemo(
    () => (props: MuiSwitchRedux.Props) => {
      const warningMessage =
        (!props.meta.pristine && editingSelf && !props.input.value && DEVERIFY_SELF_WARNING) || undefined;
      return (
        <div>
          <div style={{ marginTop: -3, marginLeft: -12, marginBottom: -4 }}>
            <MuiSwitchRedux color="primary" {...props} />
          </div>
          {warningMessage && (
            <div className="mt-2">
              <Label warning message={warningMessage} />
            </div>
          )}
        </div>
      );
    },
    [editingSelf],
  );

  const roleSelect = React.useMemo(
    () => (props: MaterialSelectRedux.Props) => {
      const warningMessage = (!props.meta.pristine && editingSelf && REMOVE_SELF_AS_SITE_ADMIN_WARNING) || undefined;
      return (
        <div>
          <MaterialSelectRedux {...props} />
          {warningMessage && (
            <div className="mt-3">
              <Label warning message={warningMessage} />
            </div>
          )}
        </div>
      );
    },
    [editingSelf],
  );

  return (
    <div>
      <form method="POST" onSubmit={handleSubmit}>
        <FormField label="Role" className="mt-6 mb-5">
          <ReduxForm.Field
            name="role"
            component={roleSelect}
            props={{
              children: renderRoleOptions,
            }}
          ></ReduxForm.Field>
        </FormField>
        {authMethod === "password" && (
          <FormField label="Account verified" className="mb-5">
            <ReduxForm.Field name="verified" component={renderVerifiedField} />
          </FormField>
        )}
        {acl.check({ action: "manageUsers" }).granted && (
          <div>
            <FormField label="Account expiration date" className="mb-6">
              <SimpleDateField.Field
                name="expiresAt"
                startDate={new Date().toISOString().slice(0, 10)}
                component={SimpleDateField}
              />
            </FormField>
          </div>
        )}
        <Alert transparent message={error} />
        <div className="form-group mt-5">
          <LoadingButton
            type="submit"
            color="secondary"
            disabled={pristine}
            onClick={handleSubmit}
            loading={submitting}
          >
            Update permissions
          </LoadingButton>
        </div>
      </form>
      {submitSucceeded && pristine && (
        <div className="mt-4">
          <Label success message="You have successfully updated the permissions." />
        </div>
      )}
    </div>
  );
};

const AccountPermissionsForm = ReduxForm.reduxForm<AccountPermissionsForm.FormData, AccountPermissionsForm.Props>({
  form: "accountPermissions",
  enableReinitialize: true,
})(_AccountPermissionsForm as any);
export default AccountPermissionsForm;
