import * as React from "react";
import type { Models } from "@triply/utils";
import { parsers, serialize } from "@triplydb/sparql-ast";
import { injectVariablesInPlace } from "@triplydb/sparql-ast/inject";

export function useAdjustQueryBeforeRequest(
  testValues: { [varName: string]: string | undefined },
  queryDeclarations: Models.VariableConfig[] | undefined,
  draftDeclarations?: Models.VariableConfig[],
) {
  const adjustQuery = (queryString: string) => {
    const declarations = draftDeclarations ?? queryDeclarations ?? [];
    const values = { ...testValues };
    for (const variable of declarations) {
      if (variable.defaultValue && values[variable.name] === undefined) {
        values[variable.name] = variable.defaultValue;
      }
    }
    try {
      return serialize(
        injectVariablesInPlace(parsers.lenient(queryString, { baseIri: "https://triplydb.com/" }), {
          declarations: declarations,
          values: values,
        }),
      );
    } catch (e) {
      console.warn(e);
      return queryString;
    }
  };
  const ref = React.useRef(adjustQuery);
  ref.current = adjustQuery;
  return ref;
}
