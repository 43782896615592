import { produce } from "immer";
import type { Models } from "@triply/utils";
import type { Action, GlobalState } from "#reducers/index.ts";
import { Actions } from "#reducers/index.ts";
import { getAccountFromAccountCollection } from "./accountCollection.ts";

export type Dataset = Models.Dataset;

type ReduxDataset = Omit<Models.Dataset, "owner"> & { owner: string };

export type State = { [id: string]: ReduxDataset };

export const reducer = produce((draftState: State, action: Action) => {
  switch (action.type) {
    case Actions.UPLOAD_DATASET_AVATAR_SUCCESS:
    case Actions.GET_CURRENT_DATASET_SUCCESS:
    case Actions.REFRESH_CURRENT_DATASET_SUCCESS:
    case Actions.UPDATE_DATASET_SUCCESS:
    case Actions.ADD_DATASET_SUCCESS:
      addDataset(draftState, action.result);
      return;
    case Actions.SOCKET_EVENT.indexJobFinished:
      addDataset(draftState, action.data.dataset);
      return;

    case Actions.GET_DATASETS_SUCCESS:
    case Actions.ADMIN_GET_DATASETS_WITH_RUNNING_JOBS_SUCCESS:
      for (const dataset of action.result) {
        addDataset(draftState, dataset);
      }
      return;

    case Actions.LOAD_LOGGED_IN_USER_SUCCESS:
    case Actions.UPLOAD_ACCOUNT_AVATAR_SUCCESS:
    case Actions.GET_CURRENT_ACCOUNT_SUCCESS:
    case Actions.UPDATE_PROFILE_SUCCESS:
    case Actions.ADD_EXTRA_DETAILS_SUCCESS:
    case Actions.CREATE_ORG_SUCCESS:
      if (action.result && action.result.pinnedItems) {
        for (const pinnedItem of action.result.pinnedItems) {
          if (pinnedItem.type === "Dataset") {
            addDataset(draftState, pinnedItem.item);
          }
        }
      }
      return;

    case Actions.DELETE_DATASET_SUCCESS:
      delete draftState[action.dataset.id];
      return;

    case Actions.RENAME_GRAPH_SUCCESS:
      if (!draftState[action.forDataset.id]) return;

      draftState[action.forDataset.id] = {
        ...draftState[action.forDataset.id],
        lastGraphsUpdateTime: new Date().toISOString(),
      };
      return;

    case Actions.REMOVE_GRAPH_SUCCESS:
      if (!draftState[action.forDataset.id]) return;

      draftState[action.forDataset.id] = {
        ...draftState[action.forDataset.id],
        lastGraphsUpdateTime: new Date().toISOString(),
        graphCount: draftState[action.forDataset.id].graphCount - 1,
        statements: draftState[action.forDataset.id].statements - action.graph.numberOfStatements,
      };
      return;

    case Actions.REMOVE_ALL_GRAPHS_SUCCESS:
      if (!draftState[action.forDataset.id]) return;

      draftState[action.forDataset.id] = {
        ...draftState[action.forDataset.id],
        lastGraphsUpdateTime: new Date().toISOString(),
        graphCount: 0,
        statements: 0,
      };
      return;

    case Actions.POST_ASSET_SUCCESS:
    case Actions.REMOVE_ASSET_SUCCESS:
      if (action.forDataset && action.forDataset.id && draftState[action.forDataset.id]) {
        draftState[action.forDataset.id] = {
          ...draftState[action.forDataset.id],
          assetCount:
            draftState[action.forDataset.id].assetCount + (action.type === Actions.REMOVE_ASSET_SUCCESS ? -1 : 1),
        };
      }
      return;
    case Actions.REMOVE_ALL_ASSETS_SUCCESS:
      if (action.forDatasetId && draftState[action.forDatasetId]) {
        draftState[action.forDatasetId].assetCount = 0;
      }
      return;

    case Actions.ADD_IMPORTS_SUCCESS:
      if (!draftState[action.forDataset.id]) return;

      const justImportedDatasetnames = action.imports.map((i) => i.dataset.ownerName + "_" + i.dataset.datasetName);
      let statements = draftState[action.forDataset.id].statements;
      let graphCount = draftState[action.forDataset.id].graphCount;
      for (const imp of action.result as Models.Imports) {
        const importIndex = justImportedDatasetnames.indexOf(imp.dataset.ownerName + "_" + imp.dataset.datasetName);
        if (importIndex === -1) {
          // this import was added at an earlier point, and is not among the imports we just added
          continue;
        }

        const justImportedGraphs = action.imports[importIndex].graphs.map((g) => g.to);

        for (const g of imp.graphs) {
          if (justImportedGraphs.indexOf(g.to) === -1) {
            // this graph was imported earlier, and not in this batch of imports
            continue;
          }
          statements += g.numberOfStatements;
          graphCount++;
        }
      }
      draftState[action.forDataset.id] = {
        ...draftState[action.forDataset.id],
        lastGraphsUpdateTime: new Date().toISOString(),
        statements,
        graphCount,
      };
      return;

    case Actions.CREATE_SERVICE_SUCCESS:
      if (!draftState[action.datasetId]) return;

      draftState[action.datasetId] = {
        ...draftState[action.datasetId],
        serviceCount: draftState[action.datasetId].serviceCount + 1,
      };
      return;
    case Actions.DELETE_SERVICE_SUCCESS:
      if (!draftState[action.datasetId]) return;

      draftState[action.datasetId] = {
        ...draftState[action.datasetId],
        serviceCount: draftState[action.datasetId].serviceCount - 1,
      };
      return;
    case Actions.UPDATE_DATASET_PREFIXES_SUCCESS:
      draftState[action.datasetId].prefixes = action.result.filter((prefix) => prefix.scope === "local");
      return;
    case Actions.ADD_DATASET_PREFIX_SUCCESS:
      if (draftState[action.datasetId]) {
        const alreadyContainsPrefixLabel = draftState[action.datasetId].prefixes.findIndex(
          (prefix) => prefix.prefixLabel === action.result.prefixLabel,
        );
        if (alreadyContainsPrefixLabel >= 0) {
          draftState[action.datasetId].prefixes[alreadyContainsPrefixLabel] = action.result;
        } else {
          draftState[action.datasetId].prefixes.push(action.result);
        }
      }
      return;
  }
}, {} as State) as any;

function addDataset(draftState: State, dataset: Dataset | Models.DatasetVerbose) {
  const { services, largestGraphs, openJobs, owner, ...rest } = dataset as Models.DatasetVerbose;
  draftState[dataset.id] = {
    ...rest,
    owner: owner.uid,
  };
}

export function getDataset(state: GlobalState, id: string): Dataset | undefined {
  return getDatasetFromCollections(state.datasetCollection, state.accountCollection, id);
}
export function getDatasetFromCollections(
  datasetCollection: State,
  accountCollection: GlobalState["accountCollection"],
  id: string,
): Dataset | undefined {
  if (!datasetCollection[id]) return undefined;
  const owner = getAccountFromAccountCollection(accountCollection, datasetCollection[id].owner);
  if (!owner) {
    //A dataset, without an owner? Dont want this to happen
    return undefined;
  }
  return {
    ...datasetCollection[id],
    owner: owner,
  };
}
