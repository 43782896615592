import * as React from "react";
import urlParse from "url-parse";
import type { Geometry, GeometryWithoutCrs } from "@triplydb/recognized-datatypes/wkt";
import { lexicalToValue as parseWkt } from "@triplydb/recognized-datatypes/wkt";
import { geoProject } from "@triplydb/utils/GeoProject";
import { DatasetMetadata } from "#components/index.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { filterEmptyVals } from "#helpers/utils.ts";
import type { Account } from "#reducers/accountCollection.ts";
import type { Dataset } from "#reducers/datasetManagement.ts";
import type { WidgetCollection } from "#reducers/resourceDescriptions.ts";

export interface Props {
  currentAccount: Account;
  currentDs: Dataset;
  widgets?: WidgetCollection;
  resourceId?: string;
}

const ResourceInfoMetadata: React.FC<Props> = (props) => {
  const constructConsoleUrl = useConstructConsoleUrl();
  const { resourceId, widgets, currentDs, currentAccount } = props;
  // When no resource is displayed or this has no outLinks, return the dataset metadata
  const datasetUrl = urlParse(constructConsoleUrl());
  datasetUrl.set("pathname", `/${currentAccount.accountName}/${currentDs.name}`);
  datasetUrl.set("query", "");
  if (!resourceId || !widgets)
    return (
      <DatasetMetadata
        currentPath={datasetUrl.pathname}
        currentAccount={currentAccount}
        currentDs={currentDs}
        title="Browser"
      />
    );

  const resourceUrl = urlParse(constructConsoleUrl());
  resourceUrl.set("pathname", datasetUrl.pathname + "/browser");
  resourceUrl.set("query", `resource=${encodeURIComponent(resourceId)}`);

  let typeBasedJson:
    | { "@type": "GeoCoordinates"; latitude: number | string; longitude: number | string; additionalType?: string[] } // Can be both string and number see https://schema.org/latitude
    | {
        "@type": "Place";
        geo: {
          latitude: number; // these can't be strings as we gather the point from a wkt
          longitude: number;
          "@id": string;
          "@type": "GeoCoordinates";
        };
        additionalType?: string[];
      }
    | {
        "@type": string | "Property" | "Class" | "Thing";
        domainIncludes?: {
          "@id": string;
          "@type": "Class";
        };
        rangeIncludes?: {
          "@id": string;
          "@type": "Class";
        };

        supersededBy?: {
          "@id": string;
          "@type": "Class";
        };
        additionalType?: string[];
      }
    | undefined;
  const geoWidget = widgets.geometry;
  if (geoWidget && geoWidget.config?.type === "latLong" && geoWidget?.values?.length) {
    typeBasedJson = {
      "@type": "GeoCoordinates",
      latitude: geoWidget.values[0].getTerm().value,
      longitude: geoWidget.values[1].getTerm().value,
    };
  } else if (geoWidget && geoWidget.config?.type === "wkt" && geoWidget?.values?.length) {
    // Don't add first point if it throws an error
    const coords = getFirstPointFromWktString(geoWidget.values[0].getTerm().value);
    if (coords) {
      if (geoWidget.values[0].getDepth() === 2) {
        const parentId = geoWidget.values[0].getParent()?.getTerm().value;
        if (parentId) {
          typeBasedJson = {
            "@type": "Place",
            geo: {
              "@id": `${datasetUrl}/browser?resource=${encodeURIComponent(parentId)}`,
              "@type": "GeoCoordinates",
              latitude: coords.latitude,
              longitude: coords.longitude,
            },
          };
        }
      } else {
        typeBasedJson = {
          "@type": "GeoCoordinates",
          latitude: coords.latitude,
          longitude: coords.longitude,
        };
      }
    }
  } else if (widgets.classes?.values) {
    const classURIs = widgets.classes.values.map((node) => node.getTerm().value);
    if (classURIs.includes("http://www.w3.org/2002/07/owl#Class")) {
      typeBasedJson = {
        "@type": "Class",
      };
      const subClassWidget =
        widgets.properties?.children &&
        widgets.properties.children.find(
          (widget) => widget.values?.[0].getPredicate() === "http://www.w3.org/2000/01/rdf-schema#subClassOf",
        );
      if (subClassWidget?.values?.length) {
        typeBasedJson.supersededBy = {
          "@id": subClassWidget.values[0].getTerm().value,
          "@type": "Class",
        };
      }
    } else if (
      classURIs.includes("http://www.w3.org/2002/07/owl#DatatypeProperty") ||
      classURIs.includes("http://www.w3.org/2002/07/owl#ObjectProperty")
    ) {
      const domainWidget =
        widgets.properties?.children &&
        widgets.properties.children.find(
          (widget) => widget.values?.[0].getPredicate() === "http://www.w3.org/2000/01/rdf-schema#domain",
        );
      const rangeWidget =
        widgets.properties?.children &&
        widgets.properties.children.find(
          (widget) => widget.values?.[0].getPredicate() === "http://www.w3.org/2000/01/rdf-schema#range",
        );
      typeBasedJson = { "@type": "Property" };
      if (domainWidget?.values?.length) {
        typeBasedJson.domainIncludes = {
          "@id": domainWidget.values[0].getTerm().value,
          "@type": "Class",
        };
      }
      if (rangeWidget?.values?.length) {
        typeBasedJson.rangeIncludes = {
          "@id": rangeWidget.values[0].getTerm().value,
          "@type": "Class",
        };
      }
    } else {
      typeBasedJson = {
        "@type":
          classURIs
            .find((element: string) => element.includes("://schema.org/"))
            ?.split("/")
            .pop() || "Thing",
      };
    }
  }

  if (widgets.classes?.values) {
    const additionalTypes = widgets.classes.values
      .map((node) => node.getTerm().value)
      .filter((element: string) => !element.includes(typeBasedJson?.["@type"] || "://schema.org/Thing"));

    if (additionalTypes && typeBasedJson) {
      typeBasedJson.additionalType = additionalTypes;
    }
  }

  const description = widgets.description?.values?.[0].getTerm().value;

  const image = widgets.image?.values?.[0].getTerm().value;

  const label = widgets.label?.values?.[0].getTerm().value;

  const datasetJson = filterEmptyVals({
    "@type": "Dataset",
    "@id": datasetUrl.href,
    name: currentDs.displayName || currentDs.name,
    description: currentDs.description,
    license: currentDs.license,
  });
  const schemaDotOrgJsonLd = {
    "@context": "https://schema.org/",
    "@type": "WebPage",
    url: resourceUrl.href, // Should be full url link
    mainEntity: filterEmptyVals({
      "@id": resourceId,
      "@type": "Thing", // Will be overwritten by spread operator
      ...typeBasedJson,
      description: description,
      image: image,
      name:
        label ||
        (resourceId.indexOf("#") >= 0 ? resourceId.split("#").slice(-1)[0] : resourceId.split("/").slice(-1)[0]),
      subjectOf: datasetJson,
      url: resourceUrl.href,
    }),
    speakable: {
      "@type": "SpeakableSpecification",
      xpath: ["/html/head/title", "/html/head/meta[@name='description']/@content"],
    },
  };

  return (
    <DatasetMetadata
      currentPath={resourceUrl.pathname + `?resource=${encodeURIComponent(resourceId)}`}
      currentAccount={currentAccount}
      currentDs={currentDs}
      description={
        description ||
        `Resource ${label || resourceId} in dataset ${currentDs.displayName || currentDs.name} created by ${
          currentAccount.name || currentAccount.accountName
        }.`
      }
      image={image || currentDs.avatarUrl || currentAccount.avatarUrl}
      imageAlt={label}
      jsonLd={[{ key: "schema", json: schemaDotOrgJsonLd }]}
      longTitle={label || resourceId}
      title={label || resourceId}
      type="schema:Thing"
    />
  );
};
export default ResourceInfoMetadata;

/**
 * Gets first point from wkt string
 * @param wktString a wkt string
 * @returns  the first point of the wkt string as coordinates
 */
function getFirstPointFromWktString(wktString: string) {
  try {
    const parsedWkt = parseWkt(wktString);
    const wgs84 = geoProject(parsedWkt, "http://www.opengis.net/def/crs/EPSG/0/4326");
    const firstPoint = getFirstPointFromWkt(parsedWkt);
    if (firstPoint?.latitude) {
      return firstPoint;
    }
  } catch (e) {}
  return undefined;
}

/**
 * Gets fist point from wkt
 * @param wkt a wkt object
 * @returns the first point of a wkt
 */
function getFirstPointFromWkt(
  geometry: Geometry | GeometryWithoutCrs,
): { longitude: number; latitude: number } | undefined {
  try {
    switch (geometry.type) {
      case "Point":
        return { longitude: geometry.coordinates[0]!, latitude: geometry.coordinates[1]! };
      case "MultiPoint":
      case "LineString":
        return { longitude: geometry.coordinates[0][0], latitude: geometry.coordinates[0][1] };
      case "Polygon":
      case "MultiLineString":
        return { longitude: geometry.coordinates[0][0][0], latitude: geometry.coordinates[0][0][1] };
      case "MultiPolygon":
        return { longitude: geometry.coordinates[0][0][0][0], latitude: geometry.coordinates[0][0][0][1] };
      case "GeometryCollection":
        return getFirstPointFromWkt(geometry.geometries[0]);
      default: {
        console.info(`Unknown geometry type ${(geometry as any).type}`);
        return undefined;
      }
    }
  } catch {
    return undefined;
  }
}
