import { stringifyQuery } from "@core/utils";
import { IconButton, Paper } from "@mui/material";
import getClassName from "classnames";
import { push } from "connected-react-router";
import * as React from "react";
import { useLocation } from "react-router";
import { FontAwesomeIcon, KeyboardKey } from "#components/index.ts";
import { useHotkeys } from "#containers/Hotkeys/index.tsx";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import * as styles from "./style.scss";

interface Props {
  className?: string;
  mayShrink: boolean;
}

const SiteSearch: React.FC<Props> = ({ className, mayShrink }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = React.useState<string>();
  const location = useLocation();
  const inputRef = React.useRef<HTMLInputElement>(null);

  //Assigning the hotkey to the search input
  const focusHotKey = { keyBinds: "/", component: "Navigation", description: "Search" };
  useHotkeys(
    focusHotKey,
    (event: KeyboardEvent) => {
      if (!event.repeat) inputRef.current?.focus();
    },
    {
      preventDefault: true,
    },
  );

  function onSearch() {
    inputRef.current?.focus();
    if (searchText) {
      const searchPathname =
        location.pathname && location.pathname.includes("/browse/") ? location.pathname : "/browse/datasets";
      dispatch(push(`${searchPathname}?${stringifyQuery({ q: searchText })}`));
      setSearchText("");
      inputRef.current?.blur();
    }
  }
  return (
    <div
      className={className}
      onClickCapture={() => {
        inputRef.current?.focus();
      }}
    >
      <Paper
        className={getClassName(styles.quickNavigateSearch, { [styles.mayShrink]: mayShrink })}
        onClickCapture={() => {
          inputRef.current?.focus();
        }}
      >
        <div className={styles.searchInputContainer}>
          <input
            aria-label="Search"
            type="search"
            ref={inputRef}
            value={searchText}
            onKeyDown={(event) => {
              if (event.key === "Enter" && searchText) {
                event.preventDefault();
                onSearch();
              } else if (event.key === "Escape") {
                inputRef.current?.blur();
                setSearchText("");
              }
            }}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
            className={styles.searchInput}
            placeholder="Search..."
          />
          <KeyboardKey className={styles.hotkeys}>/</KeyboardKey>
        </div>
        <IconButton className={styles.searchButton} onClick={onSearch} size="small">
          <FontAwesomeIcon icon="search" />
        </IconButton>
      </Paper>
    </div>
  );
};

export default SiteSearch;
