import getClassName from "classnames";
import * as React from "react";
import { Constants } from "@triply/utils";
import { Button, FontAwesomeIcon, Leaflet, LoadingButton } from "#components/index.ts";
import type { Statements, WidgetCollection, WidgetConfig } from "#reducers/resourceDescriptions.ts";
import { getPredicateLabel } from "#reducers/resourceDescriptions.ts";
import OutLink from "./OutLink.tsx";
import * as styles from "./styles/description.scss";

namespace ForwardDescription {
  export interface Props {
    outLinkWidgets?: WidgetCollection;
    loadForwardResource: (resource: string, property: string) => {};
    linkPath: string;
    loadPage: (direction: "forward" | "backward", page: number, predicate?: string) => Promise<void>;
    statements: Statements;
    resource: string;
  }

  export interface State {
    loadedPages: number;
    loading: boolean;
  }
}

class ForwardDescription extends React.PureComponent<ForwardDescription.Props, ForwardDescription.State> {
  constructor(props: ForwardDescription.Props) {
    super(props);
    this.state = {
      loadedPages: 1,
      loading: false,
    };
  }
  fallbackRenderer = (widgetConfig?: WidgetConfig) => {
    if (!widgetConfig?.values) return <></>;
    const pred = widgetConfig.values[0].getPredicate();
    return (
      <OutLink
        key={widgetConfig.values[0].getPredicate() || ""}
        label={pred && getPredicateLabel(widgetConfig.values[0].getRoot(), pred)}
        trees={widgetConfig.values}
        loadResource={this.props.loadForwardResource}
        linkPath={this.props.linkPath}
        className={styles.outLink}
      />
    );
  };
  loadPage = () => {
    const { loadPage } = this.props;
    const { loadedPages } = this.state;
    //if we already have more predicates than expected assume we already fetched the next page
    if (this.getNumberOfPredicates() <= loadedPages * Constants.FORWARD_PREDICATES_PAGE_SIZE + 1) {
      this.setState({ loading: true });
      loadPage("forward", loadedPages)
        .then(() => {
          this.setState({ loadedPages: loadedPages + 1, loading: false });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loadedPages: loadedPages + 1 });
    }
  };
  renderShowMoreButton = () => {
    return (
      <div className={styles.container}>
        <LoadingButton loading={this.state.loading} pulse onClick={this.loadPage}>
          Show more
        </LoadingButton>
      </div>
    );
  };
  getNumberOfPredicates() {
    const { outLinkWidgets } = this.props;
    return ((outLinkWidgets && outLinkWidgets.properties && outLinkWidgets.properties.children) || []).length;
  }
  render() {
    const { outLinkWidgets, loadForwardResource, linkPath, loadPage } = this.props;
    const numToRender = this.state.loadedPages * Constants.FORWARD_PREDICATES_PAGE_SIZE;
    const trees = outLinkWidgets && outLinkWidgets.properties ? outLinkWidgets.properties.children : [];
    const thereIsNextPage = this.getNumberOfPredicates() > numToRender;
    if (!outLinkWidgets) return null;
    return (
      <div className={getClassName(styles.outLinkProperties, "p-5")}>
        {(outLinkWidgets.geometry?.config?.type === "latLong" && outLinkWidgets.geometry?.values?.length && (
          <Leaflet
            className={styles.outLink}
            lat={outLinkWidgets.geometry.values[0].getTerm().value}
            long={outLinkWidgets.geometry.values[1].getTerm().value}
            fallbackRenderer={() => this.fallbackRenderer(outLinkWidgets.geometry)}
          />
        )) ||
          (outLinkWidgets.geometry?.config?.type === "wkt" && outLinkWidgets.geometry.values && (
            <Leaflet
              className={styles.outLink}
              wkt={outLinkWidgets.geometry.values[0].getTerm().value}
              fallbackRenderer={() => this.fallbackRenderer(outLinkWidgets.geometry)}
            />
          ))}
        {outLinkWidgets.audio?.values && (
          <audio controls className={getClassName(styles.outLink, styles.media)}>
            {outLinkWidgets.audio.values.map((audioTrack, index) => (
              <source
                key={audioTrack.getKey()}
                src={outLinkWidgets.audio?.config?.media?.[index]?.getTerm().value}
                type={outLinkWidgets.audio?.config?.encodings?.[index]?.getTerm().value}
              />
            ))}
            Your browser doesn't support HTML5 Audio
          </audio>
        )}
        {outLinkWidgets.video?.values && (
          <video
            controls
            height={400}
            className={getClassName(styles.outLink, styles.media)}
            poster={outLinkWidgets.image?.values?.[0]?.getTerm().value}
          >
            {outLinkWidgets.video.values.map((video, index) => (
              <source
                key={video.getKey()}
                src={outLinkWidgets.video?.config?.media?.[index]?.getTerm().value}
                type={outLinkWidgets.video?.config?.encodings?.[index]?.getTerm().value}
              />
            ))}
            Your browser doesn't support HTML5 Video
          </video>
        )}
        {trees &&
          trees.slice(0, numToRender).map((widget) => {
            const pred = widget.values?.[0].getPredicate();
            if (!widget.values || !pred) return <></>;
            return (
              <OutLink
                key={pred}
                label={widget.label}
                trees={widget.values}
                loadResource={loadForwardResource}
                linkPath={linkPath}
                className={styles.outLink}
                loadPage={loadPage}
              />
            );
          })}
        {thereIsNextPage && this.renderShowMoreButton()}
      </div>
    );
  }
}
export default ForwardDescription;
