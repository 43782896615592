import { Masonry } from "@mui/lab";
import { Alert } from "@mui/material";
import getClassname from "classnames";
import { isEmpty } from "lodash-es";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";
import type { Binding } from "#components/Sparql/SparqlUtils.ts";
import ExampleQuery from "../../ExampleQuery";
import { SparqlVisualizationContext, useSparqlResults } from "../../SparqlVisualizationContext";
import { EmptyResults, SanitizedContent } from "../displayUtils";
import ErrorResponse from "../Error";
import type { PluginConfig } from ".";
import type { Props as ItemProps } from "./GalleryCard";
import GalleryCard from "./GalleryCard";
import * as styles from "./styles.scss";

const EXAMPLE_QUERY = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix def: <https://triplydb.com/Triply/vocab/def/>
select * where {
  values (?widget ?widgetLabel ?widgetImage) { # Replace ?widget with ?markup to ignore the gallery styling
    ("Normal text"@en "Title" "https://triplydb.com/imgs/logos/logo.svg?v=4")
    ("<ol><li>You</li><li>also</li><li>use</li><li>HTML</li></ol>"^^rdf:HTML "HTML" "") # The datatype of a literal is used to determine how the result can be rendered
    ("""graph LR
    Sub --> Pred
    Pred --> Obj
    """^^def:mermaid "A mermaid diagram" "")
    ("""# Or
You *can* just use **Markdown**S
    """^^def:markdown "" "")
  }
}`;

interface Props {
  reduceSpacing?: boolean;
  minimizeColumns?: boolean;
}

export function getWidgetsFromResults(config: PluginConfig | undefined, binding: Binding | undefined) {
  if (!binding) return undefined;
  // There was a bug where a different render-config than the intended plugin got saved. #9561
  // There was a bug where we store the config as a string, instead of an object #9791
  const useConfig = config !== undefined && !isEmpty(config) && typeof config !== "string" && "displayType" in config;
  if (config?.displayType === "None") {
    if (config.body?.key && config.body.key in binding) {
      return { body: binding[config.body.key] };
    }
    return undefined;
  }
  if (!config && "widgetSingle" in binding) {
    return { body: binding.widgetSingle };
  }
  if (!config && "markup" in binding) {
    return { body: binding.markup };
  }
  const result: Omit<ItemProps, "config"> = {};
  const bodyKey = config?.body?.key || (!config && ("widget" in binding ? "widget" : "widgetDescription"));
  if (bodyKey && bodyKey in binding) {
    result.body = binding[bodyKey];
  }
  const labelKey = useConfig ? config?.label?.var : "widgetLabel";
  if (labelKey && labelKey in binding) {
    result.label = {
      text: binding[labelKey],
    };
    const linkKey = useConfig ? config.label?.link : "widgetLabelLink";
    if (linkKey && linkKey in binding) result.label.link = binding[linkKey];
  }
  const imageKey = useConfig ? config?.image?.src : "widgetImage";
  if (imageKey && imageKey in binding) {
    result.image = { src: binding[imageKey] };
    const captionKey = useConfig ? config.image?.caption?.key : "widgetImageCaption";
    if (captionKey && captionKey in binding) result.image.caption = binding[captionKey];
  }
  if (Object.keys(result).length === 0) return undefined;
  return result;
}

const Renderer: React.FC<Props> = ({ minimizeColumns, reduceSpacing }) => {
  const { results, emptyResults, noResults, variables, isAsk } = useSparqlResults();
  const { setVisualizationConfig, getVisualizationConfig } = React.useContext(SparqlVisualizationContext);
  const config = getVisualizationConfig("Gallery");
  const widgetData = React.useMemo(
    () =>
      (isAsk ? [] : (results || []).map((binding) => getWidgetsFromResults(config, binding)))?.filter(
        (item) => item !== undefined,
      ),
    [results, config, isAsk],
  );
  if (noResults) return null;
  if (isAsk) {
    return (
      <Alert severity="warning" role="alert">
        This visualization cannot render with ask responses
      </Alert>
    );
  }
  if (emptyResults) {
    return <EmptyResults />;
  }
  if (widgetData.length === 0) {
    if (!!setVisualizationConfig) {
      return (
        <Alert severity="info" role="alert">
          Create a gallery by hitting the <FontAwesomeIcon icon="cogs" /> Configure button.{" "}
          <ExampleQuery name="Gallery example" query={EXAMPLE_QUERY} visualization="Gallery" />.
        </Alert>
      );
    } else {
      return <ErrorResponse error={new Error("Configuration couldn't be applied on the results")} />;
    }
  }

  const displaySingle = !!config
    ? config?.displayType === "None"
    : results.some((binding) => "widgetSingle" in binding || "markup" in binding);
  if (displaySingle) {
    return (
      <>
        {widgetData.map((binding, idx) =>
          binding?.body ? (
            <SanitizedContent
              key={(binding?.body?.value || "") + idx}
              term={binding.body}
              renderAs={config?.body?.renderer || undefined}
            />
          ) : null,
        )}
      </>
    );
  } else {
    return (
      <div
        className={getClassname(styles.gallery, {
          ["px-3"]: minimizeColumns,
        })}
      >
        <Masonry
          columns={minimizeColumns ? { xs: 1, sm: 1, lg: 2, xl: 2 } : { xs: 1, sm: 2, lg: 3, xl: 4 }}
          spacing={reduceSpacing ? undefined : 2}
          className="mx-3 mt-3"
        >
          {widgetData.map((widget, idx) => (
            <GalleryCard key={idx} {...widget} config={config || {}} />
          ))}
        </Masonry>
      </div>
    );
  }
};

export default Renderer;
