import type { ReactNode } from "react";
import { createContext, useContext, useEffect } from "react";
import * as React from "react";
import useSparql from "#helpers/hooks/useSparql.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";

const CachedSparqlContext = createContext(new Map());

export function CachedSparqlProvider({ children }: { children: ReactNode }) {
  const cache = React.useRef(new Map());
  const currentDs = useCurrentDataset();
  const lastUpdate = currentDs?.lastGraphsUpdateTime;

  useEffect(() => {
    const cacheReference = cache.current;

    return () => {
      cacheReference.clear();
    };
  }, [lastUpdate]);

  return <CachedSparqlContext value={cache.current}>{children}</CachedSparqlContext>;
}

export function useCachedSparql<T>(
  query: string | false,
  initialJsonTree?: { singularizeVariables: { [variable: string]: boolean } },
): Omit<ReturnType<typeof useSparql<T>>, "resultsFor"> {
  const cache = useContext(CachedSparqlContext);
  const currentDs = useCurrentDataset();
  const lastUpdate = currentDs?.lastGraphsUpdateTime;
  const fingerPrint = `${query}${lastUpdate}`;

  const {
    data: freshData,
    loading,
    error,
    resultsFor,
  } = useSparql<T>(cache.has(fingerPrint) ? false : query, initialJsonTree);

  useEffect(() => {
    if (!!query && !cache.has(fingerPrint) && freshData && resultsFor === query) {
      cache.set(fingerPrint, freshData);
    }
    // This shouldn't trigger when the fingerPrint changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freshData, cache, query, resultsFor]);

  return { data: (cache.has(fingerPrint) ? cache.get(fingerPrint) : freshData) as T, loading, error };
}
