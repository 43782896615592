import type { ButtonProps as MuiButtonProps } from "@mui/material";
import { Button as MuiButton } from "@mui/material";
import * as React from "react";
import FontAwesomeIcon from "#components/FontAwesomeIcon/index.tsx";

interface Props extends Omit<MuiButtonProps, "disableElevation"> {
  loading: boolean | undefined;
  children: string | (string | number)[];
  pulse?: boolean;
  elevation?: boolean;
  drawTextInSpan?: boolean;
}

const LoadingButton: React.FC<Props> = ({
  loading,
  startIcon,
  disabled,
  pulse,
  elevation,
  drawTextInSpan,
  ...otherProps
}) => {
  const loadingIcon = pulse ? <FontAwesomeIcon icon="spinner" pulse /> : <FontAwesomeIcon icon={["fas", "cog"]} spin />;
  if (drawTextInSpan) otherProps.children = (<span>{otherProps.children}</span>) as any;
  return (
    <MuiButton
      {...otherProps}
      startIcon={loading ? loadingIcon : startIcon}
      disabled={disabled || loading}
      disableElevation={!elevation}
    />
  );
};

export default LoadingButton;
