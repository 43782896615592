import { MenuItem, Select } from "@mui/material";
import type { Column } from "@tanstack/react-table";
import { capitalize } from "lodash-es";
import * as React from "react";

const SelectFilter: React.FC<{ column: Column<any, string> }> = ({ column }) => {
  const filterValue = column.getFilterValue();
  let values = column.getFacetedUniqueValues();
  // We may have a filter value selected that is not actually available as value in this column
  // (e.g. a default value for this column that we set when instantiating the table. This value may not have occurrances (yet) in the table body)
  // In this case, simply add it to the list of unique values
  if (filterValue && !values.has(filterValue)) values.set(filterValue, 0);
  return (
    <Select
      displayEmpty
      value={column.getFilterValue() || ""}
      onChange={(e) => {
        column.setFilterValue(e.target.value);
      }}
      onClick={(e) => e.stopPropagation()}
      fullWidth
    >
      <MenuItem title="All" value="">
        All
      </MenuItem>
      {Array.from(values).map(([value, amount]) => {
        // "n/a" should be the last option,
        if (value === "n/a") return null;
        return (
          <MenuItem title={value} value={value} key={`${column.id}-select-${value}`}>
            {capitalize(value)} ({amount})
          </MenuItem>
        );
      })}
      {values.has("n/a") && (
        <MenuItem value="n/a" title="unset">
          Unset ({values.get("n/a")})
        </MenuItem>
      )}
    </Select>
  );
};

export default SelectFilter;
