/**
 * Advices: use tokens that do not overlap. Situations to avoid:
 * - Using a `Minus` token (matching `-`) and a `Sign` token (matching `+` and `-`). If your lexer needs both, then it'll introduce ambiguity
 * - Using too specific patterns, e.g. creating a pattern for day-of-month (some regex matchin 1 to 31) and a pattern for matching the month (some regex matching 01 - 12). This will again
 *   introduce ambiguity (what token would `01` be here?), and it's not something we can fix given that the lexer runs _before_ the parser
 */

import { createToken } from "chevrotain";

export const D = createToken({ name: "D", pattern: "D" });
export const H = createToken({ name: "H", pattern: "H" });
export const M = createToken({ name: "M", pattern: "M" });
export const P = createToken({ name: "P", pattern: "P" });
export const S = createToken({ name: "S", pattern: "S" });
export const T = createToken({ name: "T", pattern: "T" });
export const Y = createToken({ name: "Y", pattern: "Y" });
export const Z = createToken({ name: "Z", pattern: "Z" });
export const Digit = createToken({ name: "Digit", pattern: /[0-9]/ });
export const Colon = createToken({ name: "Colon", pattern: ":" });
export const Sign = createToken({ name: "Sign", pattern: /[+-]/ });
export const Dash = createToken({ name: "Dash", pattern: "-" });
export const Plus = createToken({ name: "Plus", pattern: "+" });
export const Dot = createToken({ name: "Dot", pattern: "." });
export const True = createToken({ name: "True", pattern: "true" });
export const False = createToken({ name: "False", pattern: "false" });
export const OneOrZero = createToken({ name: "False", pattern: /[01]/ });
export const Exponential = createToken({ name: "Exponential", pattern: /e|E/ });
export const Inf = createToken({ name: "Infinity", pattern: "INF" });
export const Nan = createToken({ name: "NaN", pattern: "NaN" });
export const hexBinaryToken = createToken({ name: "hexBinaryToken", pattern: /(?:[0-9a-fA-F]{2})+/ });
