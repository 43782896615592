import { Button } from "@mui/material";
import * as React from "react";
import { useHistory } from "react-router";
import { v4 as uuid } from "uuid";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import fetch from "#helpers/fetch.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import { ConfirmationDialog, Dialog, FontAwesomeIcon } from "../../components";
import useConstructConsoleUrl from "../../helpers/hooks/useConstructConsoleUrl";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../helpers/hooks/useDispatch";
import { stringifyQuery } from "../../helpers/utils";
import { refreshDatasetsInfo, useCurrentDataset } from "../../reducers/datasetManagement";
import { getGraphs } from "../../reducers/graphs";
import ClassForm from "./Forms/Class";
import type { ClassData } from "./Forms/Types";
import * as styles from "./styles/index.scss";

const factory = factories.compliant;

const AddClass: React.FC<{}> = () => {
  const [open, setOpen] = React.useState(false);
  const [isDirty, setDirty] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);

  const currentDs = useCurrentDataset()!;
  const datasetPath = `${currentDs.owner.accountName}/${currentDs.name}`;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const datasetUrl = useConstructConsoleUrl()({ pathname: `/${datasetPath}` });
  const history = useHistory();
  const dispatch = useDispatch();
  const removePrefixes = useRemovePrefixes();

  const prefixes = useDatasetPrefixes();
  const baseIri = prefixes.find((prefix) => prefix.prefixLabel === "shp")?.iri || `${datasetUrl}/shp/`;

  const onClose = React.useCallback(() => {
    isDirty ? setConfirmationDialogOpen(true) : setOpen(false);
  }, [isDirty]);

  return (
    <>
      <Button color="primary" aria-label="add" onClick={() => setOpen(true)} className={styles.addClassButton}>
        <FontAwesomeIcon icon="plus" size="xl" />
      </Button>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          setOpen(false);
        }}
        onClose={() => setConfirmationDialogOpen(false)}
        title="Are sure you want to close this form?"
        actionLabel="Close"
        description="If you close the form now all changes will be lost"
      />

      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth title="Create a new class" closeButton>
        <div className="px-5 pb-5">
          <ClassForm
            datasetUrl={datasetUrl}
            onDirty={setDirty}
            onSubmit={async (newClass: ClassData) => {
              const defIri = factory.namedNode(removePrefixes(newClass.iri?.trim()));
              const shpIri = factory.namedNode(`${baseIri}c-${uuid()}`);
              const parent = newClass.parent
                ? typeof newClass.parent === "string"
                  ? termToString(factory.namedNode(removePrefixes(newClass.parent?.trim())))
                  : termToString(factory.namedNode(newClass.parent.id))
                : "";
              const query = `
              prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
              prefix sh: <http://www.w3.org/ns/shacl#>
              insert {
                ?def a rdfs:Class;
                    rdfs:label ?defLabel;
                    rdfs:comment ?comment;
                    rdfs:subClassOf ?parent.
                  ?shp a sh:NodeShape;
                    sh:targetClass ?def;
                    sh:order ?order.
              } where {
                bind(${termToString(defIri)} as ?def)
                bind(${termToString(factory.literal(newClass.label.trim()))} as ?defLabel)
                bind(${termToString(shpIri)} as ?shp)
                optional {
                  values ?comment {
                    ${newClass.description?.trim() ? termToString(factory.literal(newClass.description?.trim() || "")) : ""}
                  }
                }
                optional {
                  values ?parent {
                    ${parent}
                  }
                }
                optional {
                  values ?order {
                    ${newClass.order ? termToString(factory.literal(newClass.order, factory.namedNode("http://www.w3.org/2001/XMLSchema#decimal"))) : ""}
                  }
                }
              }
              `;

              const body = new FormData();
              body.set("update", query);

              await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });
              await dispatch<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
              );
              await dispatch<typeof getGraphs>(
                getGraphs({
                  accountName: currentDs.owner.accountName,
                  datasetName: currentDs.name,
                  datasetId: currentDs.id,
                }),
              );

              setOpen(false);

              history.push({
                search: stringifyQuery({ resource: defIri.id }),
              });
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddClass;
