import { Alert, Paper } from "@mui/material";
import * as React from "react";
import { Redirect, useLocation } from "react-router";
import { asyncConnect } from "redux-connect";
import { TfaSetup } from "#components/Forms/index.ts";
import { ErrorPage, Meta } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import { parseSearchString } from "#helpers/utils.ts";
import { accountIsCurrentAccount, getAccountInfo, useCurrentAccount } from "#reducers/app.ts";
import type { GlobalState } from "#reducers/index.ts";

const Forced2faSetup: React.FC<{}> = () => {
  const location = useLocation();
  const query = parseSearchString(location.search);
  const acl = useAcl();
  const user = useCurrentAccount();
  if (!acl.isAuthenticatedUser(user)) {
    return <ErrorPage statusCode={401} />;
  }
  if (user?.mfaEnabled) {
    return <Redirect to={(query.returnTo as string) || `/${user.accountName}`} />;
  }
  return (
    <div style={{ margin: "auto", maxWidth: 600 }}>
      <Meta title="Two-factor authentication configuration" />
      <Paper className="mt-5">
        <div className="pt-5">
          <Alert severity="info" className="mx-7">
            To ensure your account is secure, you are required to configure two-factor authentication.
          </Alert>
        </div>
        <TfaSetup cancelable={false} />
      </Paper>
    </div>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ match: { params }, store: { dispatch, getState } }) => {
      if (!accountIsCurrentAccount(getState(), params.account)) {
        return dispatch<any>(getAccountInfo(getState(), params.account, false));
      }
    },
  },
])(() => <Forced2faSetup />);
