import type { CloseBracketConfig } from "@codemirror/autocomplete";
import {
  continuedIndent,
  delimitedIndent,
  foldInside,
  foldNodeProp,
  indentNodeProp,
  LanguageSupport,
  LRLanguage,
} from "@codemirror/language";
import { styleTags, tags as t } from "@lezer/highlight";
import { indentGroupGraphPattern, indentSelectQuery, indentTriplesPattern, noIndent } from "./indentFunctions.ts";
import { parser } from "./sparqlParser.ts";

export const SparqlLanguage = LRLanguage.define({
  name: "SPARQL 1.1 query",
  parser: parser.configure({
    props: [
      indentNodeProp.add({
        GroupGraphPattern: indentGroupGraphPattern,
        "ConstructTemplate QuadPattern QuadData QuadsNotTriples ConstructQuery": indentTriplesPattern,
        "InlineDataOneVar InlineDataFull": delimitedIndent({ closing: "}", align: false }),
        "BlankNodePropertyList BlankNodePropertyListPath": delimitedIndent({ closing: "]", align: false }),
        "Bind ArgList ExpressionList Collection CollectionPath BrackettedExpression BuiltInCall RegexExpression SubstringExpression StrReplaceExpression Aggregate InlineDataFullVarList InlineDataFullValue":
          delimitedIndent({ closing: ")", align: false }),
        "ObjectList ObjectListPath PropertyListPath PropertyList TriplesSameSubject TriplesSameSubjectPath SelectClause GroupClause OrderClause":
          continuedIndent(),
        "STRING_LITERAL_LONG1 STRING_LITERAL_LONG2": noIndent,
        SelectQuery: indentSelectQuery,
      }),
      foldNodeProp.add({
        Prologue(tree) {
          // Lets make it fold until the first error child (id: 0 is the error node)
          const hasErrorNodes = tree.getChildren(0)?.[0];
          // Shorten down the prologue to the first keyword. E.g. PREFIX or BASE
          return { from: tree.firstChild!.firstChild!.to, to: hasErrorNodes ? hasErrorNodes.prevSibling!.to : tree.to };
        },
        // Fold nodes delimited by '{' '}' leaving only the braces
        "GroupGraphPattern ConstructTemplate QuadPattern QuadData": foldInside,
        // Fold nodes from the first opening brace to the closing brace (is always the last character)
        "QuadsNotTriples InlineDataOneVar InlineDataFull ConstructQuery"(tree) {
          const bracket = tree.getChild("{");
          const closingBracket = tree.getChildren("}").pop();
          if (bracket) {
            return { from: bracket!.to, to: closingBracket?.from || tree.to - 1 };
          }
          return null;
        },
      }),
      styleTags({
        IRIREF: t.url,
        "Var!": t.special(t.attributeName), // Will style any Var token and ignore any styling of children
        "PNAME_LN PNAME_NS PathMod PathPrimary": t.propertyName,
        Null: t.null,
        "NumericLiteral! INTEGER": t.number,
        "String!": t.string,
        Comment: t.comment,
        Annotation: t.heading,
        "( ) { } [ ]": t.bracket,
        KEYWORD: t.keyword,
        "BooleanLiteral!": t.bool,
      }),
    ],
  }),
  languageData: {
    commentTokens: { line: "#" },
    closeBrackets: { brackets: ["(", "[", "{", "'", '"', '"""', "'''"] } as CloseBracketConfig,
  },
});
export function Sparql() {
  return new LanguageSupport(SparqlLanguage);
}
