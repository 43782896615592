import * as React from "react";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import { asyncConnect } from "redux-connect";
import { Button, DatasetMetadata, FlexContainer, FontAwesomeIcon, SinkList } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { getCurrentAccount } from "#reducers/app.ts";
import {
  addAssets,
  getAssets,
  isAssetListLoaded,
  removeAllAssets,
  removeAsset,
  removeVersion,
} from "#reducers/assets.ts";
import { getCurrentDataset, useCurrentDataset } from "#reducers/datasetManagement.ts";
import type { GlobalState } from "#reducers/index.ts";
import { showNotification } from "#reducers/notifications.ts";
import AssetListItem from "./AssetListItem.tsx";
import AssetUploads from "./AssetUploads.tsx";

export interface Props extends IComponentProps {}
const Assets: React.FC<Props> = ({ location }) => {
  const confirm = useConfirmation();
  const [numPages, setNumPages] = React.useState(1);
  const dispatch = useDispatch();
  const currentDs = useCurrentDataset();
  const currentAccount = useSelector((state: GlobalState) => getCurrentAccount(state));
  const allowedAssetExtensions = useSelector((state: GlobalState) => state.config.values?.allowedAssetExtensions);

  const assets = useSelector(
    (state: GlobalState) => currentDs && state.assets[currentDs.id] && state.assets[currentDs.id].list,
  );
  const nextPage = useSelector(
    (state: GlobalState) => currentDs && state.assets[currentDs.id] && state.assets[currentDs.id].nextPage,
  );
  const acl = useAcl();
  if (!currentDs || !currentAccount) return null;

  const getNextAssetPageLoader = () => {
    if (assets && assets.length > numPages * 30) {
      return () => setNumPages(numPages + 1);
    } else if (nextPage) {
      return () => {
        if (currentAccount && currentDs) {
          dispatch(getAssets(currentAccount, currentDs, nextPage));
          setNumPages(numPages + 1);
        }
      };
    }
  };
  let accept;
  if (allowedAssetExtensions?.length) {
    accept = {
      // Dropzone requires a mapping between mime-type and extension.
      // We use a fake mimetype: For our usecase, the extension seems to suffice: we can only select files with these extensions
      "application/x-triply": allowedAssetExtensions,
    };
  }
  return (
    <div>
      <DatasetMetadata
        currentPath={location.pathname}
        currentAccount={currentAccount}
        currentDs={currentDs}
        title="Assets"
      />

      <div className="mt-3">
        <FlexContainer>
          {acl.check({ action: "manageAssets", context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) } })
            .granted && (
            <Dropzone
              // See https://issues.triply.cc/issues/7219
              useFsAccessApi={false}
              onDrop={(files: File[], rejectedFiles) => {
                if (files?.length && currentDs) {
                  dispatch(addAssets(currentDs, files));
                }
                if (rejectedFiles.length) {
                  dispatch<typeof showNotification>(
                    showNotification(
                      `File(s) ${rejectedFiles.map((f) => f.file.name).join(", ")} are not allowed. Supported extensions are: ${allowedAssetExtensions?.join(", ")}`,
                      "error",
                    ),
                  );
                }
              }}
              accept={accept}
              noClick
              noKeyboard
            >
              {({ getRootProps, getInputProps, open }) => (
                <div {...getRootProps()} className="flex">
                  <input {...getInputProps()} />
                  <Button
                    color="secondary"
                    className="ml-3 mt-3"
                    elevation
                    onClick={open}
                    startIcon={<FontAwesomeIcon icon={["fas", "upload"]} />}
                  >
                    Upload asset(s)
                  </Button>
                  <div className="grow" />
                  <Button
                    color="error"
                    className="ml-3 mt-3"
                    elevation
                    disabled={!assets || assets.length === 0}
                    onClick={() =>
                      confirm({
                        description: `Are you sure you want to delete all (${currentDs.assetCount}) assets?`,
                        title: `Delete all (${currentDs.assetCount}) assets?`,
                        actionLabel: "Delete",
                        onConfirm: () => {
                          dispatch(removeAllAssets(currentDs.owner.accountName, currentDs.name, currentDs.id));
                        },
                      })
                    }
                    startIcon={<FontAwesomeIcon icon="trash" />}
                  >
                    Delete all assets
                  </Button>
                </div>
              )}
            </Dropzone>
          )}

          <AssetUploads />

          <SinkList loadNextPage={getNextAssetPageLoader()} noContentMsg="This dataset has no assets.">
            {assets &&
              assets.slice(0, 30 * numPages).map((asset) => {
                return (
                  <AssetListItem
                    key={asset.assetName}
                    allowedAssetExtensions={allowedAssetExtensions}
                    asset={asset}
                    currentAccount={currentAccount}
                    currentDs={currentDs}
                    removeAsset={(assetIdentifier) => {
                      confirm({
                        description: `Are you sure you want to delete '${asset.assetName}'?`,
                        title: "Delete asset?",
                        actionLabel: "Delete",
                        onConfirm: () => {
                          dispatch(removeAsset(currentAccount, currentDs, assetIdentifier));
                        },
                      });
                    }}
                    removeVersion={(assetIdentifier, versionId) => {
                      dispatch(removeVersion(currentAccount, currentDs, assetIdentifier, versionId));
                    }}
                    addVersion={(files, hash) => {
                      if (files?.length) {
                        dispatch(addAssets(currentDs, files, hash));
                      }
                    }}
                    {...asset}
                    writeAccess={
                      acl.check({
                        action: "manageAssets",
                        context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
                      }).granted
                    }
                  />
                );
              })}
          </SinkList>
        </FlexContainer>
      </div>
    </div>
  );
};
export default asyncConnect<GlobalState>([
  {
    promise: ({ store: { dispatch, getState } }) => {
      const state = getState();
      const currentDs = getCurrentDataset(state);
      const currentAccount = getCurrentAccount(state);
      if (currentAccount && currentDs && currentDs.assetCount > 0 && !isAssetListLoaded(state, currentDs)) {
        return dispatch<any>(getAssets(currentAccount, currentDs));
      }
    },
  },
])(Assets) as typeof Assets;
