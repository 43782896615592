import type { FormControlProps, InputLabelProps, SelectProps } from "@mui/material";
import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";
import { omit } from "lodash-es";
import * as React from "react";
import type * as ReduxForm from "redux-form";

export namespace MaterialSelect {
  interface OwnProps {
    InputLabelProps?: InputLabelProps;
    error?: string; // Mui defines error as an boolean, we want it to contain an error message
    classes?: FormControlProps["classes"];
  }
  export type Props = Omit<SelectProps, "classes" | "error"> & OwnProps;
}

const MaterialSelect: React.FC<MaterialSelect.Props> = ({ classes, InputLabelProps, error, ...props }) => {
  return (
    <FormControl classes={classes} error={!!error}>
      {props.label && (
        <InputLabel shrink={props.displayEmpty || undefined} {...InputLabelProps}>
          {props.label}
        </InputLabel>
      )}
      <Select {...props} />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
export namespace MaterialSelectRedux {
  export type Props = ReduxForm.WrappedFieldProps & MaterialSelect.Props;
}

export const MaterialSelectRedux: React.FC<MaterialSelectRedux.Props> = ({ input, meta, ...props }) => {
  //omitting key here, as the input key is not compatible with the interface of the materialselect key
  return <MaterialSelect {...omit(input, "key")} {...props} error={meta.touched && meta.error} />;
};
export default MaterialSelect;
