import * as React from "react";
import type { Prefix } from "@triply/utils/prefixUtils";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";

export function getPrefixAndLabel(prefixes: Prefix[], iri: string) {
  for (const p of prefixes) {
    if (iri.startsWith(p.iri)) {
      return {
        label: iri.slice(p.iri.length),
        prefix: p.prefixLabel,
      };
    }
  }
  return { label: iri };
}

const Prefixed: React.FC<{ children: string }> = ({ children }) => {
  const prefixes = useDatasetPrefixes();
  const { prefix, label } = getPrefixAndLabel(prefixes, children);
  return <>{prefix ? `${prefix}:${label}` : label}</>;
};

export default Prefixed;
