import type { ILexingError, IRecognitionException } from "chevrotain";
import { repeat, trimStart } from "lodash-es";
import type { Declaration } from "./inject.ts";
import type { LocationContext, VariableTerm } from "./types.ts";

function getParseErrorMessageWithSnippet(queryString: string, opts: { line: number; col: number }) {
  const lineSnippet = queryString.split("\n")[opts.line - 1];
  const lineSnippetTrimmed = trimStart(lineSnippet);
  const adjustedCol = opts.col - (lineSnippet.length - lineSnippetTrimmed.length); // adjust for trimming
  if (lineSnippetTrimmed) {
    return [`Parser error on line ${opts.line}:`, ``, lineSnippet.trim(), repeat("-", adjustedCol - 1) + "^"].join(
      "\n",
    );
  }
}

export class SparqlAstError extends Error {
  location?: LocationContext;
}

export class SparqlGrammarError extends SparqlAstError {
  constructor(query: string, parseError: IRecognitionException) {
    let message = "Parser error";
    if (parseError.token.startLine !== undefined && parseError.token.startColumn !== undefined) {
      const lineSnippet = query.split("\n")[parseError.token.startLine - 1];

      if (lineSnippet) {
        const errorWithSnippet = getParseErrorMessageWithSnippet(query, {
          line: parseError.token.startLine,
          col: parseError.token.startColumn,
        });
        if (errorWithSnippet) message = errorWithSnippet;
      }
    }
    super(message, { cause: parseError });
    if (
      parseError.token.startLine !== undefined &&
      !Number.isNaN(parseError.token.startLine) &&
      parseError.token.endLine !== undefined &&
      !Number.isNaN(parseError.token.endLine) &&
      parseError.token.startColumn !== undefined &&
      !Number.isNaN(parseError.token.startColumn) &&
      parseError.token.endColumn !== undefined &&
      !Number.isNaN(parseError.token.endColumn)
    ) {
      this.location = {
        startLine: parseError.token.startLine,
        endLine: parseError.token.endLine,
        startColumn: parseError.token.startColumn,
        endColumn: parseError.token.endColumn,
      };
    }
  }
}

export class SparqlLexError extends SparqlAstError {
  constructor(query: string, lexingError: ILexingError) {
    let message = "Parser error";
    if (lexingError.line !== undefined && lexingError.column !== undefined) {
      const lineSnippet = query.split("\n")[lexingError.line - 1];

      if (lineSnippet) {
        const errorWithSnippet = getParseErrorMessageWithSnippet(query, {
          line: lexingError.line,
          col: lexingError.column,
        });
        if (errorWithSnippet) message = errorWithSnippet;
      }
    }
    super(message, { cause: lexingError });

    if (lexingError.line !== undefined && lexingError.column !== undefined && lexingError.length !== undefined) {
      this.location = {
        startLine: lexingError.line,
        endLine: lexingError.line,
        startColumn: lexingError.column,
        endColumn: lexingError.column + lexingError.length,
      };
    }
  }
}

export class AnnotationError extends SparqlAstError {
  constructor(message: string, opts: { location: LocationContext | undefined; cause?: any }) {
    super(message, { cause: opts?.cause });
    this.location = opts.location;
  }
}

export class InjectError extends SparqlAstError {
  public arg?: string;
  public declaration?: Declaration;
  public value?: string;
  constructor(opts: { message: string; arg?: string; declaration?: Declaration; value?: string; cause?: any }) {
    super(opts.message, { cause: opts.cause });
    this.arg = opts.arg;
    this.declaration = opts.declaration;
    this.value = opts.value;
  }
}

/**
 * Validation related errors
 */
export class SparqlAstValidationError extends SparqlAstError {
  constructor(message: string, opts?: { cause?: Error; location?: LocationContext }) {
    super(message, { cause: opts?.cause });
    this.location = opts?.location;
  }
}

export class IllegalBindError extends SparqlAstValidationError {
  public variable: VariableTerm;
  constructor(variable: VariableTerm) {
    const surfaceForm = variable?.surfaceForm || `?${variable.value}`;
    super(`The variable '${surfaceForm}' cannot be bound more than once`);
    this.location = variable.location;
    this.variable = variable;
  }
}
export class IllegalWildcardAndGroupByError extends SparqlAstValidationError {
  constructor(location: LocationContext | undefined) {
    super("Wildcards are not allowed in combination with 'group by'");
    this.location = location;
  }
}
export class InconsistentValuesLengthError extends SparqlAstValidationError {
  constructor() {
    super("Inconsistent values length");
  }
}
export class InvalidAggregate extends SparqlAstValidationError {
  public aggregateFunction: string;
  constructor(
    message: string,
    opts: { cause?: Error; aggregateFunction: string; location: LocationContext | undefined },
  ) {
    super(message, opts);
    this.aggregateFunction = opts.aggregateFunction;
  }
}

export class UngroupedVariableError extends SparqlAstValidationError {
  public variable: VariableTerm;
  constructor(variableTerm: VariableTerm) {
    super(
      `Syntactically invalid query: the variable '?${variableTerm.value}' is ` +
        "projected from an aggregate SPARQL query or used as an aggregated " +
        `variable, but is not used for grouping. Include '?${variableTerm.value}' in the ` +
        "'group by' to make the query valid.",
    );
    this.variable = variableTerm;
    this.location = variableTerm.location;
  }
}
