import getClassName from "classnames";
import { take } from "lodash-es";
import * as React from "react";
import { Link } from "react-router-dom";
import { CachePolicies } from "use-http";
import type { Models } from "@triply/utils";
import { FontAwesomeRoundIcon, StoryBadge } from "#components/index.ts";
import { getQueryIconForType } from "#helpers/FaIcons.tsx";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useFetch from "#helpers/hooks/useFetch.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import * as styles from "./style.scss";

export interface Props {
  datasetId: string;
}

const Dependents: React.FC<Props> = ({ datasetId }) => {
  const constructUrlToApi = useConstructUrlToApi();
  const { data: queries, error: queryError } = useFetch<Models.QueryFacets>(
    constructUrlToApi({ pathname: "/facets/queries", query: { dataset: datasetId } }),
    {
      cachePolicy: CachePolicies.NO_CACHE,
    },
    [],
  );
  const { data: stories, error: storyError } = useFetch<Models.StoryFacets>(
    constructUrlToApi({ pathname: "/facets/stories", query: { dataset: datasetId } }),
    {
      cachePolicy: CachePolicies.NO_CACHE,
    },
    [],
  );

  if (!queries || !stories || queryError || storyError || queries.count === 0) return null;

  return (
    <div className={getClassName("flex wrap", styles.dependentsContainer)}>
      <div className={getClassName("grow flex", styles.dependentsList)}>
        <h5 className="headerSpacing paragraphHeader" title="Queries that use this dataset">
          Dependent queries
        </h5>
        {take(queries.results, 3).map((q) => {
          return (
            <div key={q.id}>
              <Link
                to={`/${q.ownerAccountName}/-/queries/${q.name}`}
                className="flex center"
                title={`${q.displayName || q.name} by ${q.ownerAccountDisplayName || q.ownerAccountName}`}
                aria-label={`${q.displayName || q.name} by ${q.ownerAccountDisplayName || q.ownerAccountName}`}
              >
                <FontAwesomeRoundIcon
                  aria-label={q.visualization}
                  size="sm"
                  icon={["far", getQueryIconForType(q.visualization)]}
                  className={getClassName("mr-3", {
                    [styles.flippedIcon]: q.visualization === "BarChart", // since there is no icon for barchart, we use the same as columnchart and flip it
                  })}
                />
                {q.displayName || q.name}
              </Link>
            </div>
          );
        })}
        {queries.count > 3 && (
          <Link
            to={{ pathname: "/browse/queries", search: stringifyQuery({ dataset: datasetId }) }}
            aria-label="Show all dependent queries"
            className="mt-2"
          >
            {`Show all ${queries.count} queries...`}
          </Link>
        )}
      </div>
      <div className={getClassName("grow flex", styles.dependentsList)}>
        {stories.count > 0 && (
          <h5 className="headerSpacing paragraphHeader" title="Stories that use this dataset">
            Dependent stories
          </h5>
        )}
        {take(stories.results, 3).map((s) => {
          return (
            <div key={s.id}>
              <Link
                to={`/${s.ownerAccountName}/-/stories/${s.name}`}
                className="flex center"
                title={`${s.displayName || s.name} by ${s.ownerAccountDisplayName || s.ownerAccountName}`}
                aria-label={`${s.displayName || s.name} by ${s.ownerAccountDisplayName || s.ownerAccountName}`}
              >
                <StoryBadge bannerUrl={s.bannerUrl} className="mr-3" />
                <div>{s.displayName || s.name}</div>
              </Link>
            </div>
          );
        })}
        {stories.count > 3 && (
          <Link
            to={{ pathname: "/browse/stories", search: stringifyQuery({ dataset: datasetId }) }}
            aria-label="Show all dependent stories"
            className="mt-2"
          >
            {`Show all ${stories.count} stories...`}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Dependents;
