import * as React from "react";

export default function usePrevious<I = any>(value: I): I | undefined {
  const ref = React.useRef<I>(undefined);

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  return ref.current;
}
