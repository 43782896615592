import { isEmpty } from "lodash-es";
import type { ParsedQs } from "qs";
import { stringify } from "qs";
import { parse, serializeCalver } from "@triply/utils/calver";
import * as _validation from "./validation.ts";

export { default as Acl } from "./acl/index.ts";
export { expandSearchQuery, type QueryObject } from "./SearchQueryParser.ts";
export const validation = _validation;

// Create a name based on originalName that does not occur in existingNames and has a max length of maxLength
export const getNewName = (originalName: string, existingNames: string[], maxLength?: number) => {
  // Transform to lowercase to make sure we are catching actual duplicates
  let casedName = originalName.toLowerCase();
  const existingCasedNames = existingNames.map((value) => value.toLowerCase());

  // If needed limit name to the max length
  if (maxLength) casedName = casedName.substring(0, maxLength);
  if (existingCasedNames.indexOf(casedName) < 0) {
    if (maxLength) return originalName.substring(0, maxLength);
    return originalName;
  }

  // Iterate through all new variations
  for (let i = 1; i <= existingCasedNames.length; i++) {
    // Create new name with original name to preserve casing
    const option = maxLength
      ? `${originalName.substring(0, maxLength - String(i).length - 1)}-${i}`
      : `${originalName}-${i}`;
    if (existingCasedNames.indexOf(option.toLowerCase()) < 0) return option;
  }
  if (maxLength) return originalName.substring(0, maxLength);
  return originalName;
};

export interface UrlInfo {
  ssl: boolean;
  domain: string;
  port: number;
}
export function urlInfoToString(config?: UrlInfo, url?: { pathname: string; query?: ParsedQs }) {
  if (url?.pathname && url.pathname[0] !== "/") {
    throw new Error(`Invalid path name passed. Expected an absoute path. Got '${url.pathname}'`);
  }
  if (!config) {
    throw new Error(`Expected a url info object`);
  }
  const scheme = config.ssl ? "https" : "http";
  const hidePortFromUrl = !config.port || (config.port === 80 && !config.ssl) || (config.port === 443 && config.ssl);
  const port = hidePortFromUrl ? "" : ":" + config.port;
  return (
    scheme +
    "://" +
    config.domain +
    port +
    (url?.pathname || "") +
    (url?.query && !isEmpty(url?.query) ? "?" + stringifyQuery(url.query) : "")
  );
}

export function stringifyQuery(query?: ParsedQs) {
  if (isEmpty(query)) return "";
  return stringify(query);
}

/**
 * Regex that matches the calver part of a docker tag, e.g.
 *
 *  23.09.0           => 23.9.100
 *  23.09.0-k8        => 23.9.100
 *  23.09.0-k8-debug  => 23.9.100
 *  23.09.0-k8-debug  => 23.9.100
 *  23.09.1           => 23.9.200
 *  23.9.201          => 23.9.201
 *  23.09.0-4         => 23.9.104
 *  23.09.0-4-k8      => 23.9.104
 *  25.1.200          => 25.1.200
 *
 * See:
 * - https://issues.triply.cc/issues/8260
 * - https://issues.triply.cc/issues/8152
 * - https://issues.triply.cc/issues/8068
 */
export function serviceTagToVersion(tag: string): string | undefined {
  let oldCalverString = tag.match(/\d\d\.\d\d\.\d(?:-\d+)?/)?.[0];
  if (oldCalverString) {
    const parsedCalver = parse(oldCalverString);
    // A '0' version-in-month is not allowed in the new calver notation. Increase all by 1
    parsedCalver.versionInMonth++;
    return serializeCalver(parsedCalver);
  }
  // new calver notation
  return tag.match(/\d\d\.\d+\.[1-9]\d\d/)?.[0];
}

/**
 * Regex that matches the release branch's version number
 *
 *  triplydb-v-23.09.0           => 23.09.0
 *  triplydb-v-25.1.100          => 25.1.100
 *  triplydb-v-23.09.0-4         => 23.09.0-4
 *  triplydb-v-23.09.0-40        => 23.09.0-40
 *  feature/something-something  => undefined
 *  undefined                    => undefined
 *
 * See:
 * - https://issues.triply.cc/issues/8307
 */
export function buildRefToVersion(buildRef: string | undefined): string | undefined {
  return buildRef?.match(/\d+\.\d+\.\d+((?:-\d+))?/)?.[0];
}
