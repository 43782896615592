import { Alert, Drawer, IconButton, NoSsr, Skeleton } from "@mui/material";
import getClassName from "classnames";
import type { Location } from "history";
import { groupBy, partition, sortBy } from "lodash-es";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import type { Bindings, Term } from "@triplydb/engine/constants";
import { FontAwesomeIcon } from "#components/index.ts";
import useClickOutside from "#helpers/hooks/useClickOutside.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import ColorPrefixed from "./ColorPrefixed.tsx";
import Prefixed from "./Prefixed.tsx";
import type { QuerySchema } from "./useFetchSchema.ts";
import * as styles from "./style.scss";

type Properties = { datatypeProperties: Bindings<Term>[][]; objectProperties: Bindings<Term>[][] };

const SidePanel: React.FC<{
  querySchema: QuerySchema;
  containsShacl: boolean;
  location: Location;
}> = ({ querySchema, containsShacl, location }) => {
  const { hash } = location;
  const history = useHistory();
  const focusNode = decodeURIComponent(hash.slice(1));
  const [{ datatypeProperties, objectProperties }, setProperties] = React.useState<Properties>({
    datatypeProperties: [],
    objectProperties: [],
  });
  const [shapes, setShapes] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const currentDs = useCurrentDataset();
  const datasetUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs?.owner.accountName}/${currentDs?.name}`,
    fromBrowser: true,
  });
  const panelRef = React.useRef<HTMLDivElement>(null);
  useClickOutside((event) => {
    if (!focusNode) return;
    if (event.target instanceof Node && document.getElementById("schema-svg")?.contains(event.target)) {
      // Only close when clicking the SVG
      history.push({ search: location.search, hash: "" });
    }
  }, panelRef);

  React.useEffect(() => {
    if (!focusNode) return;

    const loadData = async () => {
      setError(undefined);
      setLoading(true);

      try {
        const bindings = await querySchema(`
          PREFIX owl: <http://www.w3.org/2002/07/owl#>
          PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
          PREFIX sh: <http://www.w3.org/ns/shacl#>
          SELECT * WHERE {
            {
              ?property rdfs:domain <${focusNode}> .
              optional {
                ?property rdfs:range ?datatype .
                ?property a owl:DatatypeProperty .
              }
              optional {
                ?property rdfs:range ?class .
              }
              bind(1 as ?owl)
            } union {
              ?shape sh:targetClass <${focusNode}>.
              ?shape sh:property ?propertyShape .
              ?propertyShape sh:path ?property .
              optional {
                ?propertyShape sh:class ?class .
              }
              optional {
                ?propertyShape sh:datatype ?datatype .
              }
              bind(1 as ?shacl)
            }
          }
          `);
        const grouped = Object.values(
          groupBy(bindings, (b) => `${b.property!.value}-${b.datatype?.value || b.class?.value}`),
        );
        const [datatypeProperties, objectProperties] = partition(grouped, (p) => !!p[0].datatype?.value);
        setProperties({ datatypeProperties: datatypeProperties, objectProperties: objectProperties });

        if (!containsShacl) return;

        setShapes(
          (
            await querySchema(`
              PREFIX sh: <http://www.w3.org/ns/shacl#>
              SELECT DISTINCT ?shape WHERE {
                  ?shape sh:targetClass <${focusNode}>.
              }
            `)
          ).map((binding) => binding.shape!.value),
        );
      } catch (e) {
        console.error(e);
        setError("Failed loading details.");
      } finally {
        setLoading(false);
      }
    };

    loadData().catch(() => {});
  }, [focusNode, datasetUrl, querySchema, containsShacl]);

  const browserPath = `/${currentDs?.owner.accountName}/${currentDs?.name}/browser`;

  return (
    <NoSsr>
      <Drawer
        PaperProps={{ className: getClassName(styles.focusDrawer, "shadow") }}
        open={!!focusNode}
        anchor="right"
        variant="persistent"
        ref={panelRef}
      >
        <div className={getClassName(styles.focusDrawerHeader)}>
          <Link
            to={{
              pathname: browserPath,
              search: stringifyQuery({ resource: focusNode }),
            }}
            draggable
            onDragStart={(e) => e.dataTransfer.setData("text/plain", focusNode)}
          >
            <h3 title={focusNode} className="ellipsis">
              <Prefixed>{focusNode}</Prefixed>
            </h3>
          </Link>
          <IconButton
            onClick={() => history.push({ search: location.search, hash: "" })}
            aria-label="Close"
            title="Close"
          >
            <FontAwesomeIcon icon="times" />
          </IconButton>
        </div>
        <div className={styles.focusDrawerContent}>
          {error && <Alert severity="error">{error}</Alert>}
          {!error && shapes.length > 0 && (
            <div className="mb-4">
              {shapes.map((shape) => {
                return (
                  <div key={shape}>
                    <Link
                      to={{
                        pathname: browserPath,
                        search: stringifyQuery({ resource: shape }),
                      }}
                      onDragStart={(e) => e.dataTransfer.setData("text/plain", shape)}
                      title={shape}
                    >
                      <Prefixed>{shape}</Prefixed>
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
          {!error && (
            <>
              <h5>Datatype properties</h5>
              {loading && <Skeleton variant="text" height={50} />}
              <div className={styles.propertyList}>
                {datatypeProperties.length === 0 && <i>none</i>}
                {!loading &&
                  sortBy(datatypeProperties, (b) => b[0].property!.value).map((b, i) => (
                    <Property
                      key={b[0]!.property!.value + b[0]!.datatype!.value}
                      bindings={b}
                      property={b[0]!.property!.value}
                      object={b[0]!.datatype!.value}
                      background={i % 2 === 0}
                      browserPath={browserPath}
                      containsShacl={containsShacl}
                    />
                  ))}
              </div>
              <h5>Object properties</h5>
              {loading && <Skeleton variant="text" height={50} />}
              <div className={styles.propertyList}>
                {objectProperties.length === 0 && <i>none</i>}
                {!loading &&
                  sortBy(objectProperties, (b) => b[0].property!.value).map((b, i) => (
                    <Property
                      key={b[0]!.property!.value + b[0]!.class?.value}
                      bindings={b}
                      property={b[0]!.property!.value}
                      object={b[0]!.class?.value || ""}
                      background={i % 2 === 0}
                      browserPath={browserPath}
                      containsShacl={containsShacl}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      </Drawer>
    </NoSsr>
  );
};

const Property: React.FC<{
  property: string;
  object: string;
  bindings: Bindings<Term>[];
  background: boolean;
  browserPath: string;
  containsShacl: boolean;
}> = ({ bindings, property, object, background, browserPath, containsShacl }) => {
  const [open, setOpen] = React.useState(false);
  const bg = getClassName({ [styles.bg]: background });
  const handleClick = containsShacl ? () => setOpen(!open) : undefined;

  return (
    <React.Fragment>
      <div
        className={getClassName(
          bg,
          styles.left,
          { [styles.clickable]: containsShacl, [styles.open]: !!open },
          styles.draggable,
          "ellipsis",
        )}
        title={property}
        draggable
        onDragStart={(e) => e.dataTransfer.setData("text/plain", property)}
        onClick={handleClick}
        role="button"
        tabIndex={0}
      >
        <ColorPrefixed>{property}</ColorPrefixed>
      </div>
      <div
        className={getClassName(bg, { [styles.clickable]: containsShacl }, styles.draggable, "ellipsis")}
        title={object}
        onClick={handleClick}
        role="button"
        tabIndex={0}
        draggable
        onDragStart={(e) => e.dataTransfer.setData("text/plain", object)}
      >
        <Prefixed>{object}</Prefixed>
      </div>
      <div
        className={getClassName(
          bg,
          styles.right,
          { [styles.clickable]: containsShacl, [styles.open]: !!open },
          "flex g-1 center horizontalEnd",
        )}
        onClick={handleClick}
        role="button"
        tabIndex={0}
      >
        {containsShacl && (
          <>
            {!open &&
              bindings.map((bindings, i) => {
                const type = "owl" in bindings ? "owl" : "shacl";
                return <div key={type + i} className={styles[`${type}Dot`]} />;
              })}
            <FontAwesomeIcon icon={open ? "angle-down" : "angle-right"} fixedWidth />
          </>
        )}
      </div>
      {open && (
        <div className={getClassName(bg, styles.details)}>
          {bindings.map((b, i) => {
            if ("shacl" in b) {
              return (
                <div key={"shacl" + i} className="flex g-3">
                  <div className="grow ellipsis">
                    Property shape:{" "}
                    <Link
                      to={{
                        pathname: browserPath,
                        search: stringifyQuery({ resource: b.propertyShape!.value }),
                      }}
                      onDragStart={(e) => e.dataTransfer.setData("text/plain", b.propertyShape!.value)}
                      title={b.propertyShape!.value}
                    >
                      <Prefixed>{b.propertyShape!.value}</Prefixed>
                    </Link>
                  </div>
                  <div className={getClassName("mt-2", styles.shaclDot)} />
                </div>
              );
            } else {
              return (
                <div key={"owl" + i} className="flex g-3">
                  <Link
                    to={{
                      pathname: browserPath,
                      search: stringifyQuery({ resource: b.property!.value }),
                    }}
                    className="grow ellipsis"
                    onDragStart={(e) => e.dataTransfer.setData("text/plain", b.property!.value)}
                    title={b.property!.value}
                  >
                    <Prefixed>{b.property!.value}</Prefixed>
                  </Link>
                  <div className={getClassName("mt-2", styles.owlDot)} />
                </div>
              );
            }
          })}
        </div>
      )}
    </React.Fragment>
  );
};

export default SidePanel;
