import getClassName from "classnames";
import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import * as calver from "@triply/utils/calver";
import { FlexContainer } from "#components/index.ts";
import type { GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

function getLatestVersion(apiVersion: string | undefined, consoleVersion: string | undefined): string | undefined {
  if (apiVersion && consoleVersion) {
    if (apiVersion === "unset" && consoleVersion === "unset") return "Latest";
    if (apiVersion === "unset" && consoleVersion !== "unset") return consoleVersion;
    if (apiVersion !== "unset" && consoleVersion === "unset") return apiVersion;
    if (calver.gte(apiVersion, consoleVersion)) return apiVersion;
    if (calver.gte(consoleVersion, apiVersion)) return consoleVersion;
  } else {
    if (!apiVersion && consoleVersion) return consoleVersion === "unset" ? "Latest" : consoleVersion;
    if (!consoleVersion && apiVersion) return apiVersion === "unset" ? "Latest" : consoleVersion;
    return undefined;
  }
}

export namespace Footer {
  export interface Props {
    className?: string;
  }
}

const Footer: React.FC<Footer.Props> = (props) => {
  const { className } = props;

  const includeExternalReferences = useSelector((state: GlobalState) => state.config.values?.includeExternalReferences);
  const privacyPolicyUrl = useSelector((state: GlobalState) => state.config.clientConfig?.legal?.privacyPolicyUrl);
  const generalTermsUrl = useSelector((state: GlobalState) => state.config.clientConfig?.legal?.generalTermsUrl);
  const clientConfigVersion = useSelector((state: GlobalState) => state.config.clientConfig?.version);
  const instanceContactAddress = useSelector((state: GlobalState) => state.config.clientConfig?.contactEmail);
  const instanceName = useSelector((state: GlobalState) => state.config.clientConfig?.branding.name);
  const staticConfigVersion = useSelector((state: GlobalState) => state.config.staticConfig?.consoleVersion);
  const latestVersion = getLatestVersion(clientConfigVersion, staticConfigVersion);
  return (
    <FlexContainer className={getClassName(styles.footerContainer, "pt-9", className)}>
      <footer className={getClassName(styles.footer)}>
        {includeExternalReferences && (
          <div className={getClassName(styles.upperFooter)}>
            <div className={getClassName(styles.pageCollection, "p-5")}>
              <h4 className={getClassName("headerSpacing", styles.pageHeader)}>{instanceName}</h4>
              <a href={`mailto:${instanceContactAddress}`} target="_blank">
                Contact
              </a>
            </div>

            <div className={getClassName(styles.pageCollection, "p-5")}>
              <h4 className={getClassName("headerSpacing", styles.pageHeader)}>Documentation</h4>
              <a href="https://docs.triply.cc/triply-db-getting-started" target="_blank">
                Getting started
              </a>
              <a href="https://docs.triply.cc/triply-api" target="_blank">
                API reference
              </a>
              <a href="https://docs.triply.cc/triplydb-js" target="_blank">
                Javascript Client
              </a>
              <a
                href={`https://docs.triply.cc/triplydb-changelog${
                  !latestVersion || latestVersion === "Latest" ? "" : `#${latestVersion.split("-")[0]}`
                }`}
                target="_blank"
              >
                <span>{`Changelog ${!latestVersion ? "" : "(" + latestVersion + ")"}`}</span>
              </a>
            </div>
            <div className={getClassName(styles.pageCollection, "p-5")}>
              <h4 className={getClassName("headerSpacing", styles.pageHeader)}>Triply</h4>
              <a href="https://triply.cc" target="_blank">
                About
              </a>
              {/* <a href="https://docs.triply.cc" target="_blank">Docs</a> */}
              <a href="https://triply.cc/contact" target="_blank">
                Contact
              </a>
              {generalTermsUrl && (
                <a href={generalTermsUrl} target="_blank">
                  General terms
                </a>
              )}
              {privacyPolicyUrl && (
                <a href={privacyPolicyUrl} target="_blank">
                  Privacy policy
                </a>
              )}
            </div>
          </div>
        )}
        <div className={getClassName(styles.lowerFooter, "px-3")}>
          {latestVersion && (
            <div className={getClassName(styles.footerItem, "mx-4")}>
              <span title="Current Triply DB Version" aria-label="Current Triply DB Version">
                {`Version: ${latestVersion}`}
              </span>
            </div>
          )}
          <div>
            <span className={getClassName(styles.footerText)}>
              © {moment().get("year")} Triply B.V. All rights reserved.
            </span>
          </div>
        </div>
      </footer>
    </FlexContainer>
  );
};

export default Footer;
