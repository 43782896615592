import { produce } from "immer";
import { isEqual, omit, reduce } from "lodash-es";
import type { Models, Routes } from "@triply/utils";
import type * as Forms from "#components/Forms/index.ts";
import type { GenericReduxApiResponse } from "#helpers/ApiClient.ts";
import type { Account } from "#reducers/accountCollection.ts";
import type { Action, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";
import { Actions } from "#reducers/index.ts";

export const LocalActions = {
  GET_QUERIES: "triply/queries/GET_QUERIES",
  GET_QUERIES_SUCCESS: "triply/queries/GET_QUERIES_SUCCESS",
  GET_QUERIES_FAIL: "triply/queries/GET_QUERIES_FAIL",
  GET_QUERY: "triply/queries/GET_QUERY",
  GET_QUERY_SUCCESS: "triply/queries/GET_QUERY_SUCCESS",
  GET_QUERY_FAIL: "triply/queries/GET_QUERY_FAIL",
  CHECK_QUERY_AVAILABILITY: "triply/queries/CHECK_QUERY_AVAILABILITY",
  CHECK_QUERY_AVAILABILITY_SUCCESS: "triply/queries/CHECK_QUERY_AVAILABILITY_SUCCESS",
  CHECK_QUERY_AVAILABILITY_FAIL: "triply/queries/CHECK_QUERY_AVAILABILITY_FAIL",
  CREATE_QUERY: "triply/queries/CREATE_QUERY",
  CREATE_QUERY_SUCCESS: "triply/queries/CREATE_QUERY_SUCCESS",
  CREATE_QUERY_FAIL: "triply/queries/CREATE_QUERY_FAIL",
  UPDATE_QUERY: "triply/queries/UPDATE_QUERY",
  UPDATE_QUERY_SUCCESS: "triply/queries/UPDATE_QUERY_SUCCESS",
  UPDATE_QUERY_FAIL: "triply/queries/UPDATE_QUERY_FAIL",
  DELETE_QUERY: "triply/queries/DELETE_QUERY",
  DELETE_QUERY_SUCCESS: "triply/queries/DELETE_QUERY_SUCCESS",
  DELETE_QUERY_FAIL: "triply/queries/DELETE_QUERY_FAIL",
  ADD_QUERY_VERSION: "triply/queries/ADD_QUERY_VERSION",
  ADD_QUERY_VERSION_SUCCESS: "triply/queries/ADD_QUERY_VERSION_SUCCESS",
  ADD_QUERY_VERSION_FAIL: "triply/queries/ADD_QUERY_VERSION_FAIL",
  CHOWN_QUERY: "triply/queries/CHOWN_QUERY",
  CHOWN_QUERY_SUCCESS: "triply/queries/CHOWN_QUERY_SUCCESS",
  CHOWN_QUERY_FAIL: "triply/queries/CHOWN_QUERY_FAIL",
} as const;

type GET_QUERIES = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_QUERIES,
      typeof LocalActions.GET_QUERIES_SUCCESS,
      typeof LocalActions.GET_QUERIES_FAIL,
    ];
    listFor: ListFor | undefined;
    append: boolean;
  },
  GenericReduxApiResponse<{ Body: Models.Queries }> //the route returns Queries | string, but in this case it's always Queries
>;

type GET_QUERY = GlobalAction<
  {
    types: [typeof LocalActions.GET_QUERY, typeof LocalActions.GET_QUERY_SUCCESS, typeof LocalActions.GET_QUERY_FAIL];
  },
  GenericReduxApiResponse<{ Body: Models.Query }> //the route returns a Query | string, but in this case it's always a Query
>;

type CREATE_QUERY = GlobalAction<
  {
    types: [
      typeof LocalActions.CREATE_QUERY,
      typeof LocalActions.CREATE_QUERY_SUCCESS,
      typeof LocalActions.CREATE_QUERY_FAIL,
    ];
  },
  Routes.queries._account.Post
>;

type ADD_QUERY_VERSION = GlobalAction<
  {
    types: [
      typeof LocalActions.ADD_QUERY_VERSION,
      typeof LocalActions.ADD_QUERY_VERSION_SUCCESS,
      typeof LocalActions.ADD_QUERY_VERSION_FAIL,
    ];
  },
  Routes.queries._account._query.Post
>;

type UPDATE_QUERY = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_QUERY,
      typeof LocalActions.UPDATE_QUERY_SUCCESS,
      typeof LocalActions.UPDATE_QUERY_FAIL,
    ];
  },
  Routes.queries._account._query.Patch
>;

type DELETE_QUERY = GlobalAction<
  {
    types: [
      typeof LocalActions.DELETE_QUERY,
      typeof LocalActions.DELETE_QUERY_SUCCESS,
      typeof LocalActions.DELETE_QUERY_FAIL,
    ];
    query: Query;
  },
  Routes.queries._account._query.Delete
>;

type CHECK_QUERY_AVAILABILITY = GlobalAction<{
  types: [
    typeof LocalActions.CHECK_QUERY_AVAILABILITY,
    typeof LocalActions.CHECK_QUERY_AVAILABILITY_SUCCESS,
    typeof LocalActions.CHECK_QUERY_AVAILABILITY_FAIL,
  ];
}>;

type CHOWN_QUERY = GlobalAction<
  {
    types: [
      typeof LocalActions.CHOWN_QUERY,
      typeof LocalActions.CHOWN_QUERY_SUCCESS,
      typeof LocalActions.CHOWN_QUERY_FAIL,
    ];
  },
  Routes.queries._account._query.chown.Post
>;

export type LocalAction =
  | GET_QUERIES
  | GET_QUERY
  | CREATE_QUERY
  | ADD_QUERY_VERSION
  | UPDATE_QUERY
  | DELETE_QUERY
  | CHECK_QUERY_AVAILABILITY
  | CHOWN_QUERY;

export type Query = Models.Query;
export interface QueryList {
  [queryId: string]: Query;
}
export interface ListFor {
  account?: string | false;
  searchTerm?: string | false;
}
export interface State {
  list?: QueryList;
  listFor?: ListFor;
  nextPage?: string;
  current?: Query;
}

//The immer producer has useful generics, but these'll cause `Type instantiation is excessively deep and possibly infinite.`
//typescript errors, or possible out-of-memory errors caused by the `Immutable<>` immer typings on our state.
//So, avoid using the immer typings, and add our own for now. We can try to move to the generics in a later stage
//when either typescript or immer solved this issue
export const reducer = produce((draftState: State, action: Action) => {
  switch (action.type) {
    case Actions.GET_QUERIES_SUCCESS:
      draftState.list = reduce(
        action.result,
        (result, q) => {
          result[q.id] = q;
          return result;
        },
        action.append && draftState.list ? draftState.list : ({} as QueryList),
      );
      if (!action.append) draftState.listFor = action.listFor;
      draftState.nextPage =
        action.meta && action.meta.links && action.meta.links["next"] ? action.meta.links["next"].url : undefined;
      return draftState;
    case Actions.GET_QUERY:
      draftState.current = undefined;
      return draftState;
    case Actions.GET_QUERY_SUCCESS:
    case Actions.ADD_QUERY_VERSION_SUCCESS:
      draftState.current = action.result;
      if (
        draftState.list &&
        draftState.list[action.result.id] &&
        action.result.version === action.result.numberOfVersions
      ) {
        draftState.list[action.result.id] = action.result;
      }
      return draftState;
    case Actions.UPDATE_QUERY_SUCCESS:
      if (draftState.current) {
        //only update the metadata, not the current version
        draftState.current = {
          ...draftState.current,
          ...omit<Omit<Query, "version" | "requestConfig" | "renderConfig">>(action.result, [
            "version",
            "requestConfig",
            "renderConfig",
          ]),
        };
      }

      if (draftState.list && draftState.list[action.result.id]) {
        draftState.list[action.result.id] = action.result;
      }
      return draftState;

    case Actions.CREATE_QUERY_SUCCESS:
      if (
        draftState.list &&
        isEqual(draftState.listFor, { account: action.result.owner.accountName, searchTerm: false })
      ) {
        // Only add if the list is already loaded
        draftState.list = {
          [action.result.id]: action.result,
          ...draftState.list,
        };
        draftState.current = action.result;
      }

      return draftState;
    case Actions.DELETE_QUERY_SUCCESS:
      delete draftState.list?.[action.query.id];
      if (draftState.current && draftState.current.id === action.query.id) {
        draftState.current = undefined;
      }
      return draftState;
    case Actions.CHOWN_QUERY_SUCCESS:
      if (draftState.list) draftState.list[action.result.id] = action.result;
      if (draftState.current && draftState.current.id === action.result.id) {
        draftState.current = action.result;
      }
      return draftState;
    case Actions.DELETE_DATASET_SUCCESS:
      if (draftState.current?.dataset?.id === action.dataset.id) {
        draftState.current = undefined;
      }
      const affectedQueries = draftState.list
        ? Object.entries(draftState.list).filter(([_id, query]) => {
            return query.dataset?.id === action.dataset.id;
          })
        : [];
      affectedQueries.forEach(([id, _]) => {
        if (draftState.list?.[id]?.dataset) {
          draftState.list[id].dataset = undefined;
          draftState.list[id].service = "";
        }
      });
      return draftState;
    // We need to cause a hard refresh of the current query so that it updates with the updated service Metadata
    case Actions.UPDATE_SERVICE_SUCCESS:
      draftState.current = undefined;
      return draftState;
    case Actions.ADD_DATASET_PREFIX_SUCCESS:
      if (draftState.current?.dataset?.id === action.datasetId) {
        draftState.current.dataset.prefixes.push(action.result);
      }
      const queryWithThisDs = draftState.list
        ? Object.entries(draftState.list).filter(([_id, query]) => {
            return query.dataset?.id === action.datasetId;
          })
        : [];
      queryWithThisDs.forEach(([id, _]) => {
        if (draftState.list?.[id]?.dataset?.prefixes) {
          draftState.list?.[id]?.dataset?.prefixes.push(action.result);
        }
      });
      return draftState;
  }
}, {} as State) as any;

export function needToFetchQueries(state: GlobalState, forAccount: Account) {
  if (!forAccount) return false;
  return !isEqual(state.queries.listFor, { account: forAccount.accountName, searchTerm: false });
}

export function getQueries(
  forAccount?: Account,
  query?: Routes.queries.Get["Req"]["Query"],
  nextPage?: string,
): BeforeDispatch<GET_QUERIES> {
  return {
    types: [Actions.GET_QUERIES, Actions.GET_QUERIES_SUCCESS, Actions.GET_QUERIES_FAIL],
    promise: (client) => {
      if (nextPage)
        return client.req({
          url: nextPage,
          method: "get",
          query: query,
        });
      return client.req({
        pathname: forAccount ? `/queries/${forAccount.accountName}` : "/search/queries",
        method: "get",
        query: query,
      });
    },
    listFor: nextPage
      ? undefined
      : {
          account: !!forAccount && forAccount.accountName,
          searchTerm: typeof query?.q === "string" && !!query.q ? query.q : false,
        },
    append: !!nextPage,
  };
}

export function getQuery(ownerName: string, queryName: string, version?: number): BeforeDispatch<GET_QUERY> {
  return {
    types: [Actions.GET_QUERY, Actions.GET_QUERY_SUCCESS, Actions.GET_QUERY_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/queries/${ownerName}/${queryName}/${version || ""}`,
        method: "get",
      }),
  };
}

export function formDataToUpdateObj(formValues: Forms.QueryMeta.FormData) {
  const { dataset, serviceType, ...remaining } = formValues;
  const updateBody: Models.QueryCreate = {
    dataset: dataset ? dataset.id : undefined,
    serviceConfig: { type: serviceType },
    ...remaining,
  };
  return updateBody;
}

export function createQuery(forAccountName: string, payload: Models.QueryCreate): BeforeDispatch<CREATE_QUERY> {
  return {
    types: [Actions.CREATE_QUERY, Actions.CREATE_QUERY_SUCCESS, Actions.CREATE_QUERY_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/queries/" + forAccountName,
        method: "post",
        body: payload,
      }),
  };
}
export function addVersion(queryLink: string, data: Models.QueryVersionUpdate): BeforeDispatch<ADD_QUERY_VERSION> {
  return {
    types: [Actions.ADD_QUERY_VERSION, Actions.ADD_QUERY_VERSION_SUCCESS, Actions.ADD_QUERY_VERSION_FAIL],
    promise: (client) =>
      client.req({
        url: queryLink,
        method: "post",
        body: data,
      }),
  };
}

export function updateQuery(query: Query, data: Models.QueryMetaUpdate): BeforeDispatch<UPDATE_QUERY> {
  return {
    types: [Actions.UPDATE_QUERY, Actions.UPDATE_QUERY_SUCCESS, Actions.UPDATE_QUERY_FAIL],
    promise: (client) =>
      client.req({
        url: query.link,
        method: "patch",
        body: data,
      }),
  };
}
export function deleteQuery(query: Query): BeforeDispatch<DELETE_QUERY> {
  return {
    types: [Actions.DELETE_QUERY, Actions.DELETE_QUERY_SUCCESS, Actions.DELETE_QUERY_FAIL],
    promise: (client) =>
      client.req({
        url: query.link,
        method: "delete",
      }),
    query: query,
  };
}
export function transferQuery(queryApiLink: string, toAccountName: string): BeforeDispatch<CHOWN_QUERY> {
  return {
    types: [Actions.CHOWN_QUERY, Actions.CHOWN_QUERY_SUCCESS, Actions.CHOWN_QUERY_FAIL],
    promise: (client) =>
      client.req({
        url: `${queryApiLink}/chown`,
        method: "post",
        body: {
          toAccount: toAccountName,
        },
      }),
  };
}
export function getQueryRecordForAccount(state: GlobalState, accountName: string) {
  if (!isEqual(state.queries.listFor, { account: accountName, searchTerm: false })) return;
  return { list: state.queries.list, nextPage: state.queries.nextPage };
}
export function isQueryNameAvailable(
  formItemName: string,
  forAccountName: string,
  queryName: string,
): BeforeDispatch<CHECK_QUERY_AVAILABILITY> {
  return {
    types: [
      Actions.CHECK_QUERY_AVAILABILITY,
      Actions.CHECK_QUERY_AVAILABILITY_SUCCESS,
      Actions.CHECK_QUERY_AVAILABILITY_FAIL,
    ],
    promise: (client) =>
      client
        .req({
          pathname: "/queries/" + forAccountName + "/" + queryName,
          method: "get",
          statusCodeMap: { 404: 202 },
        })
        .then((d) => {
          if (d.body.name)
            throw {
              [formItemName]: "A query named " + d.body.name + " already exists",
            };
          return null;
        }),
  };
}

export function isQueryListLoaded(state: GlobalState, listFor: ListFor) {
  return isEqual(state.queries.listFor, listFor);
}

export function getQueryList(state: GlobalState, listFor: ListFor) {
  if (!state.queries.list || !isEqual(listFor, state.queries.listFor)) return [];
  return Object.values(state.queries.list);
}

export function getCurrentQuery(
  state: GlobalState,
  accountName: string | undefined,
  queryName: string | undefined,
  versionNumber: string | undefined,
) {
  const currentQuery = state.queries.current;
  if (currentQuery?.owner.accountName.toLowerCase() !== accountName?.toLowerCase()) return undefined;
  if (currentQuery?.name.toLowerCase() !== queryName?.toLowerCase()) return undefined;
  if (versionNumber && currentQuery?.version.toString() !== versionNumber) return undefined;
  return currentQuery;
}
