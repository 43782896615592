import { syntaxTree } from "@codemirror/language";
import type { Action, Diagnostic } from "@codemirror/lint";
import type { EditorView } from "@codemirror/view";
import type { SyntaxNode } from "@lezer/common";
import Debug from "debug";
import type { Prefix } from "@triply/utils";
import { OutsidePrefixes } from "../facets/outsidePrefixes.ts";
import { getPrologueNodeFromGlobalTree } from "../fields/definedPrefixes.ts";
import { PNAME_NS, PrefixDecl } from "../grammar/sparqlParser.terms.ts";

const debug = Debug("triply:sparql-editor:linting:prefixes");

function prefixLinter({ state }: EditorView): readonly Diagnostic[] {
  debug("start");
  const diagnostics: Diagnostic[] = [];
  const tree = syntaxTree(state);

  // Don't use the field for these, we want to look inside the prologue
  const prefixDefinitions: string[] = [];
  const prologue = getPrologueNodeFromGlobalTree(tree);
  const definedPrefixNodes = prologue?.getChildren(PrefixDecl);
  // Let's see which prefixes are already defined
  for (const prefixDec of definedPrefixNodes || []) {
    // Check for errors, this makes sure we inject the prefixes at the most "valid" place
    const PrefixNameNode = prefixDec.getChild(PNAME_NS);
    if (PrefixNameNode !== null) {
      const prefixName = state.sliceDoc(PrefixNameNode.from, PrefixNameNode.to - 1);
      if (prefixDefinitions.includes(prefixName)) {
        diagnostics.push(getDuplicatePrefixDiagnostic(prefixDec, prefixName));
      } else {
        // Push the prefix name except ':'
        prefixDefinitions.push(prefixName);
      }
    }
  }
  const cursor = tree.cursor();
  do {
    if (cursor.node.type.isError || cursor.type.isSkipped) {
      continue;
    } else {
      // Skip prologue since we've already
      if (cursor.node.name === "Prologue") {
        cursor.nextSibling();
      }
      if (cursor.node.name === "PNAME_NS" || cursor.node.name === "PNAME_LN") {
        const prefixLabel = state.doc.sliceString(cursor.node.from, cursor.node.to).split(":")[0];
        if (!prefixDefinitions.some((prefix) => prefixLabel === prefix)) {
          const actions: Action[] = [];
          for (const prefix of state.facet(OutsidePrefixes)) {
            if (prefix.prefixLabel === prefixLabel) {
              actions.push(getImportPrefixAction(prologue?.to || 0, prefix, prologue));
            }
          }
          actions.push(getUnknownPrefixAction(prologue?.to || 0, prefixLabel, prologue));
          diagnostics.push(getUnknownPrefixDiagnostic(cursor.node, prefixLabel, actions));
        }
      }
    }
  } while (cursor.next());
  return diagnostics;
}

export default prefixLinter;

function getDuplicatePrefixDiagnostic(node: { from: number; to: number }, name: string): Diagnostic {
  return {
    from: node.from,
    to: node.to,
    severity: "warning",
    message: `Prefix '${name}' is already defined`,
    actions: [
      {
        name: `Remove duplicate declaration of '${name}'`,
        apply(view, from, to) {
          view.dispatch({ changes: { from: from, to: to + 1 } });
        },
      },
    ],
  };
}
function getUnknownPrefixDiagnostic(node: { from: number; to: number }, name: string, actions: Action[]): Diagnostic {
  return {
    from: node.from,
    to: node.to,
    severity: "error",
    message: `'${name}' is not defined`,
    actions: actions,
  };
}

function getImportPrefixAction(injectAt: number, prefix: Prefix, prologue: SyntaxNode | null | undefined): Action {
  return {
    name: `Define prefix '${prefix.prefixLabel}: ${prefix.iri}'`,
    apply(view, _from, _to) {
      view.dispatch({
        changes: {
          from: injectAt,
          insert: `${prologue?.from === injectAt ? "" : "\n"}prefix ${prefix.prefixLabel}: <${prefix.iri}>${prologue?.from === injectAt || view.state.doc.lineAt(injectAt).to !== injectAt ? "\n" : ""}`,
        },
        userEvent: "input.complete",
      });
    },
  };
}

function getUnknownPrefixAction(injectAt: number, text: string, prologue: SyntaxNode | null | undefined): Action {
  const prefixLabel = text.split(":")[0];
  return {
    name: `Define prefix '${prefixLabel}'`,
    apply(view, _from, _to) {
      view.dispatch({
        changes: {
          from: injectAt,
          insert: `${prologue?.from === injectAt ? "" : "\n"}prefix ${prefixLabel}: <${prologue?.from === injectAt || view.state.doc.lineAt(injectAt).to !== injectAt ? "\n" : ""}`,
        },
        userEvent: "input.complete",
        selection: {
          anchor: injectAt + `\nPREFIX ${prefixLabel}: <`.length,
        },
      });
    },
  };
}
