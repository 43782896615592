import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { SubmissionError } from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import { Avatar, Dialog, ListWidget } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import type { GlobalState } from "#reducers/index.ts";
import { createOrganization } from "#reducers/orgs.ts";
import * as styles from "./style.scss";

interface Props {}
interface LocationState {
  createOrgModalShown?: boolean;
  preserveScrollPosition?: boolean;
}
const SideOrgs: React.FC<Props> = ({}) => {
  const authenticatedUser = useAuthenticatedUser();
  const triplyDbComMode = useSelector((state: GlobalState) => !!state.config.clientConfig?.triplydb);

  const { push, goBack, replace } = useHistory<LocationState>();
  const { state: locationState } = useLocation<LocationState>();
  const dispatch = useDispatch();
  const acl = useAcl();
  if (!authenticatedUser || (triplyDbComMode && authenticatedUser.role === "light")) return null;

  const handleOrgAdd = (data: Forms.OrgAdd.FormData) => {
    if (!authenticatedUser) return;
    return dispatch<typeof createOrganization>(createOrganization(authenticatedUser, data))
      .then(({ body: org }) => replace(`/${org.accountName}`))
      .catch((e) => {
        if (e?.status == 409) {
          throw new SubmissionError({ name: "URL is already in use." });
        }
        throw new SubmissionError({ _error: e.message });
      });
  };
  const openDialog = () => {
    push({ state: { createOrgModalShown: true, preserveScrollPosition: true } });
  };
  const userAllowedToCreateOrg = acl.check({
    action: "createOrganization",
    context: {
      roleInUser: acl.getRoleInAccount(authenticatedUser),
    },
  }).granted;
  return (
    <>
      <ListWidget
        key="yourOrgs"
        title="Your organizations"
        linkTo={`/${authenticatedUser.accountName}/-/settings/orgs`}
        noContentMsg="You are not a member of any organization"
        onAddItem={userAllowedToCreateOrg ? openDialog : undefined}
        addItemTitle="Create organization"
        showMoreLink={
          (!!authenticatedUser &&
            !!authenticatedUser.orgs &&
            authenticatedUser.orgs.length > 10 &&
            `/${authenticatedUser.accountName}/-/settings/orgs`) ||
          undefined
        }
      >
        {!!authenticatedUser &&
          !!authenticatedUser.orgs &&
          authenticatedUser.orgs.slice(0, 10).map((org) => (
            <div key={org.accountName}>
              <Link
                to={`/${org.accountName}`}
                title={org.name || org.accountName}
                className={getClassName("flex center noLinkDecoration px-3 py-2", styles.listItem)}
              >
                {org.accountName && (
                  <Avatar size="sm" avatarName={org.accountName} avatarUrl={org.avatarUrl} className="mr-3" alt="" />
                )}
                <div className={styles.itemTitle}>{org.name || org.accountName}</div>
              </Link>
            </div>
          ))}
      </ListWidget>
      {authenticatedUser && userAllowedToCreateOrg && (
        <Dialog
          open={!!locationState?.createOrgModalShown}
          onClose={goBack}
          maxWidth="lg"
          fullWidth
          title="Create organization"
        >
          <Forms.OrgAdd className="p-5" onSubmit={handleOrgAdd} cancel={goBack} />
        </Dialog>
      )}
    </>
  );
};

export default SideOrgs;
