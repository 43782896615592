import { syntaxTree } from "@codemirror/language";
import type { EditorState } from "@codemirror/state";
import { StateField } from "@codemirror/state";
import type { Tree } from "@lezer/common";
import Debug from "debug";
import { isEqual } from "lodash-es";
import type { Prefixes } from "@triply/utils/Models";
import {
  IRIREF,
  PNAME_NS,
  PrefixDecl,
  Prologue,
  Query,
  QueryUnit,
  Update,
  UpdateUnit,
} from "../grammar/sparqlParser.terms.ts";
import { updateOnDocAndParserChanges } from "./utils.ts";

const debug = Debug("triply:sparql-editor:fields:prefixes");

export const definedPrefixesField = StateField.define<Prefixes>({
  create: getPrefixesFromState,
  update: (value, transaction) => {
    if (updateOnDocAndParserChanges(transaction)) {
      return getPrefixesFromState(transaction.state);
    }
    return value;
  },
  compare: isEqual,
});

export function getPrologueNodeFromGlobalTree(tree: Tree) {
  const queryUnit = tree.topNode.getChild(QueryUnit);
  if (queryUnit) return queryUnit.getChild(Query)?.getChild(Prologue);
  return tree.topNode.getChild(UpdateUnit)?.getChild(Update)?.getChild(Prologue);
}

function getPrefixesFromState(state: EditorState): Prefixes {
  debug("Running");
  // Since the prefix is part of the index
  const tree = syntaxTree(state);

  const prologue = getPrologueNodeFromGlobalTree(tree);
  if (!prologue) {
    debug("No prologue found");
    return [];
  }
  const prefixDeclarations: Prefixes = [];
  // Lets grab all the defined prefixes
  const prefixDeclarationNodes = prologue.getChildren(PrefixDecl);
  for (const prefixDeclarationNode of prefixDeclarationNodes) {
    const prefixLabel = prefixDeclarationNode.getChild(PNAME_NS);
    const prefixIri = prefixDeclarationNode.getChild(IRIREF);
    if (prefixLabel !== null && !prefixLabel.type.isError && prefixIri !== null && !prefixIri.type.isError) {
      prefixDeclarations.push({
        iri: state.sliceDoc(prefixIri.from + 1, prefixIri.to - 1), // Slice away the braces
        prefixLabel: state.sliceDoc(prefixLabel.from, prefixLabel.to - 1), //Slice away the :
      });
    }
  }
  return prefixDeclarations;
}
