import { validation } from "@core/utils";
import { InputAdornment, MenuItem, Select } from "@mui/material";
import memoizee from "memoizee";
import * as React from "react";
import { connect } from "react-redux";
import * as ReduxForm from "redux-form";
import type { MarkRequired } from "ts-essentials";
import type { Models } from "@triply/utils";
import type { MuiAutosuggest } from "#components/index.ts";
import {
  Alert,
  Avatar,
  Button,
  FontAwesomeIcon,
  FormField,
  Highlight,
  LoadingButton,
  MuiAutosuggestRedux,
} from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import type { Account } from "#reducers/accountCollection.ts";
import { checkAccountName } from "#reducers/accounts.ts";
import type { GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

type AccountWithName = MarkRequired<Account, "accountName">;
const nameValidator = validation.toStringValidator([validation.required], {
  formatMessage: () => `An account name is required`,
});
const roleValidator = validation.toStringValidator([validation.required], {
  formatMessage: () => `A role is required`,
});

namespace OrgMemberAdd {
  export interface FormData {
    name: string;
    role: Models.OrgRole;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState & Partial<ReduxForm.InjectedFormProps<FormData>>;
  export interface OwnProps extends Partial<ReduxForm.ConfigProps<FormData>> {
    className?: string;
    checkAccountName?: typeof checkAccountName;
    accountsUrl: string;
    cancel?: React.EventHandler<React.MouseEvent<any>>;
  }
  export interface DispatchProps {}
  export interface PropsFromState {}
  export interface State {}
  export interface RoleFieldProps {
    children: any[];
  }
}

// //use withRef: we need access to the form from a modal parent, to start submitting it
const OrgMemberAdd = connect<OrgMemberAdd.State, OrgMemberAdd.DispatchProps, OrgMemberAdd.OwnProps, GlobalState>(
  () => ({}),
  {
    checkAccountName: checkAccountName,
  },
  undefined,
  { forwardRef: true },
)(
  ReduxForm.reduxForm<OrgMemberAdd.FormData, OrgMemberAdd.Props>({
    form: "orgMemberAdd",
    validate: memoizee(
      (formData: OrgMemberAdd.FormData) => {
        return {
          name: nameValidator(formData.name),
          role: roleValidator(formData.role),
        };
      },
      { max: 10 },
    ),
  })(
    class OrgMemberAdd extends React.PureComponent<OrgMemberAdd.Props, OrgMemberAdd.State> {
      getAccountName = (account: Account) => account.accountName;

      renderRoleField = ({ input, children }: ReduxForm.WrappedFieldProps & OrgMemberAdd.RoleFieldProps) => {
        return (
          <div className={styles.roleSelect}>
            <Select
              {...input}
              fullWidth
              inputProps={{
                id: "role-field",
              }}
            >
              {children}
            </Select>
          </div>
        );
      };
      searchAccounts = async (queryString: string) => {
        if (queryString.length > 0) {
          return this.search(this.props.accountsUrl + "?type=user&substring=" + queryString);
        }
        return [];
      };

      search(url: string): Promise<AccountWithName[]> {
        return this.fetch(url).then((results) => {
          if (results && Array.isArray(results)) {
            return results.filter((a) => !!a.accountName) as AccountWithName[];
          }
          return [];
        });
      }

      fetch = memoizee(
        async (url: string) => {
          return fetch(url, { credentials: "same-origin" })
            .then((response) => {
              if (response.status === 200) return response.json() as Promise<Account[]>;
            })
            .catch((error) => {
              console.error(error);
            });
        },
        { primitive: true, async: true },
      );

      render() {
        const { handleSubmit, error, submitting, className, valid, cancel } = this.props;

        return (
          <form onSubmit={handleSubmit} className={className}>
            <FormField label="User name" inputId="username-field" className="mt-3 mb-5">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiAutosuggest.Props<AccountWithName>>>
                name="name"
                props={{
                  loadSuggestions: this.searchAccounts,
                  transformSuggestionToReduxValue: (account) => {
                    return account.accountName;
                  },
                  getSuggestionSearchText: (account) => account.accountName,
                  TextFieldProps: {
                    className: styles.accountSearch,
                    autoFocus: true,
                    InputProps: {
                      id: "username-field",
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon="user" />
                        </InputAdornment>
                      ),
                    },
                  },
                  renderSuggestion: (suggestion, { query, isHighlighted }) => {
                    return (
                      <MenuItem selected={isHighlighted} component="div">
                        <Avatar
                          alt=""
                          size="sm"
                          className="mr-2"
                          avatarName={suggestion.accountName}
                          avatarUrl={suggestion.avatarUrl}
                        />
                        <Highlight fullText={suggestion.accountName} highlightedText={query} />
                      </MenuItem>
                    );
                  },
                }}
                component={MuiAutosuggestRedux}
              />
            </FormField>

            <FormField label="Role" inputId="role-field" className="mt-3 mb-5">
              <ReduxForm.Field name="role" component={this.renderRoleField}>
                <MenuItem value="owner">Owner</MenuItem>
                <MenuItem value="member">Member</MenuItem>
              </ReduxForm.Field>
            </FormField>

            <Alert transparent message={error} className="mt-5" />

            <LoadingButton
              className="mt-5"
              type="submit"
              color="secondary"
              disabled={!valid}
              onClick={handleSubmit}
              loading={submitting}
            >
              Add member
            </LoadingButton>
            {!!cancel && (
              <Button onClick={cancel} className="ml-2 mt-5" variant="text">
                Cancel
              </Button>
            )}
          </form>
        );
      }
    } as any,
  ) as any,
);
export default OrgMemberAdd;
