import type { Models, Routes } from "@triply/utils";
import type { GenericReduxApiResponse } from "#helpers/ApiClient.ts";
import type { ListFor } from "#reducers/datasetManagement.ts";
import type { BeforeDispatch, GlobalAction } from "#reducers/index.ts";

export const LocalActions = {
  ADMIN_REGISTER: "triply/admin/ADMIN_REGISTER",
  ADMIN_REGISTER_SUCCESS: "triply/admin/ADMIN_REGISTER_SUCCESS",
  ADMIN_REGISTER_FAIL: "triply/admin/ADMIN_REGISTER_FAIL",
  ADMIN_REGISTER_BULK: "triply/admin/ADMIN_REGISTER_BULK",
  ADMIN_REGISTER_BULK_SUCCESS: "triply/admin/ADMIN_REGISTER_BULK_SUCCESS",
  ADMIN_REGISTER_BULK_FAIL: "triply/admin/ADMIN_REGISTER_BULK_FAIL",
  CLEAR_CACHE: "triply/admin/CLEAR_CACHE",
  CLEAR_CACHE_SUCCESS: "triply/admin/CLEAR_CACHE_SUCCESS",
  CLEAR_CACHE_FAIL: "triply/admin/CLEAR_CACHE_FAIL",
  TOGGLE_CACHE: "triply/admin/TOGGLE_CACHE",
  TOGGLE_CACHE_SUCCESS: "triply/admin/TOGGLE_CACHE_SUCCESS",
  TOGGLE_CACHE_FAIL: "triply/admin/TOGGLE_CACHE_FAIL",
  DEBUG: "triply/admin/DEBUG",
  DEBUG_SUCCESS: "triply/admin/DEBUG_SUCCESS",
  DEBUG_FAIL: "triply/admin/DEBUG_FAIL",
  EDIT_TOPICS: "triply/admin/EDIT_TOPICS",
  EDIT_TOPICS_SUCCESS: "triply/admin/EDIT_TOPICS_SUCCESS",
  EDIT_TOPICS_FAIL: "triply/admin/EDIT_TOPICS_FAIL",
  ADMIN_GET_DATASETS_WITH_RUNNING_JOBS: "triply/admin/ADMIN_GET_DATASETS_WITH_RUNNING_JOBS",
  ADMIN_GET_DATASETS_WITH_RUNNING_JOBS_SUCCESS: "triply/admin/ADMIN_GET_DATASETS_WITH_RUNNING_JOBS_SUCCESS",
  ADMIN_GET_DATASETS_WITH_RUNNING_JOBS_FAIL: "triply/admin/ADMIN_GET_DATASETS_WITH_RUNNING_JOBS_FAIL",
  ADMIN_GET_LIMITS: "triply/admin/ADMIN_GET_LIMITS",
  ADMIN_GET_LIMITS_SUCCESS: "triply/admin/ADMIN_GET_LIMITS_SUCCESS",
  ADMIN_GET_LIMITS_FAIL: "triply/admin/ADMIN_GET_LIMITS_FAIL",
} as const;

type ADMIN_REGISTER = GlobalAction<
  {
    types: [
      typeof LocalActions.ADMIN_REGISTER,
      typeof LocalActions.ADMIN_REGISTER_SUCCESS,
      typeof LocalActions.ADMIN_REGISTER_FAIL,
    ];
    verbose: false;
  },
  GenericReduxApiResponse<{ Body: Models.User }>
>;

type ADMIN_REGISTER_BULK = GlobalAction<
  {
    types: [
      typeof LocalActions.ADMIN_REGISTER_BULK,
      typeof LocalActions.ADMIN_REGISTER_BULK_SUCCESS,
      typeof LocalActions.ADMIN_REGISTER_BULK_FAIL,
    ];
    verbose: false;
  },
  GenericReduxApiResponse<{ Body: Models.BulkUserReport }>
>;

type CLEAR_CACHE = GlobalAction<
  {
    types: [
      typeof LocalActions.CLEAR_CACHE,
      typeof LocalActions.CLEAR_CACHE_SUCCESS,
      typeof LocalActions.CLEAR_CACHE_FAIL,
    ];
  },
  Routes.admin.cache.Post
>;

type TOGGLE_CACHE = GlobalAction<
  {
    types: [
      typeof LocalActions.TOGGLE_CACHE,
      typeof LocalActions.TOGGLE_CACHE_SUCCESS,
      typeof LocalActions.TOGGLE_CACHE_FAIL,
    ];
  },
  Routes.admin.cache.Post
>;

type DEBUG = GlobalAction<
  {
    types: [typeof LocalActions.DEBUG, typeof LocalActions.DEBUG_SUCCESS, typeof LocalActions.DEBUG_FAIL];
  },
  Routes.admin.debug.Post
>;

type EDIT_TOPICS = GlobalAction<
  {
    types: [
      typeof LocalActions.EDIT_TOPICS,
      typeof LocalActions.EDIT_TOPICS_SUCCESS,
      typeof LocalActions.EDIT_TOPICS_FAIL,
    ];
  },
  Routes.topics.Patch
>;

type ADMIN_GET_DATASETS_WITH_RUNNING_JOBS = GlobalAction<
  {
    types: [
      typeof LocalActions.ADMIN_GET_DATASETS_WITH_RUNNING_JOBS,
      typeof LocalActions.ADMIN_GET_DATASETS_WITH_RUNNING_JOBS_SUCCESS,
      typeof LocalActions.ADMIN_GET_DATASETS_WITH_RUNNING_JOBS_FAIL,
    ];
    listFor: ListFor;
    append: false;
  },
  Routes.admin.indexJobs.Get
>;

type ADMIN_GET_LIMITS = GlobalAction<
  {
    types: [
      typeof LocalActions.ADMIN_GET_LIMITS,
      typeof LocalActions.ADMIN_GET_LIMITS_SUCCESS,
      typeof LocalActions.ADMIN_GET_LIMITS_FAIL,
    ];
  },
  Routes.admin.limits.Get
>;

export type LocalAction =
  | ADMIN_REGISTER
  | ADMIN_REGISTER_BULK
  | CLEAR_CACHE
  | TOGGLE_CACHE
  | ADMIN_GET_DATASETS_WITH_RUNNING_JOBS
  | ADMIN_GET_LIMITS;

export function register(email: string, accountName: string, expiresAt?: string): BeforeDispatch<ADMIN_REGISTER> {
  return {
    types: [LocalActions.ADMIN_REGISTER, LocalActions.ADMIN_REGISTER_SUCCESS, LocalActions.ADMIN_REGISTER_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/admin/register",
        method: "post",
        body: {
          email,
          accountName,
          expiresAt,
        },
      }),
    verbose: false,
  };
}
export function registerBulk(file: File): BeforeDispatch<ADMIN_REGISTER_BULK> {
  return {
    types: [
      LocalActions.ADMIN_REGISTER_BULK,
      LocalActions.ADMIN_REGISTER_BULK_SUCCESS,
      LocalActions.ADMIN_REGISTER_BULK_FAIL,
    ],
    promise: (client) =>
      client.req({
        pathname: "/admin/registerBulk",
        method: "post",
        files: { bulk: file },
      }),
    verbose: false,
  };
}
export function clearCache(type: Models.CacheType): BeforeDispatch<CLEAR_CACHE> {
  return {
    types: [LocalActions.CLEAR_CACHE, LocalActions.CLEAR_CACHE_SUCCESS, LocalActions.CLEAR_CACHE_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/admin/cache",
        method: "post",
        body: {
          clearCache: type,
        },
      }),
  };
}
export function enableCache(type: Models.CacheType, enabled: boolean): BeforeDispatch<TOGGLE_CACHE> {
  return {
    types: [LocalActions.TOGGLE_CACHE, LocalActions.TOGGLE_CACHE_SUCCESS, LocalActions.TOGGLE_CACHE_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/admin/cache",
        method: "post",
        body: {
          enableCache: {
            type,
            enabled,
          },
        },
      }),
  };
}
export function editDebugNamespace(namespace: string): BeforeDispatch<DEBUG> {
  return {
    types: [LocalActions.DEBUG, LocalActions.DEBUG_SUCCESS, LocalActions.DEBUG_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/admin/debug",
        method: "post",
        body: { namespace },
      }),
  };
}
export function editTopics(topicUpdates: Models.TopicUpdates): BeforeDispatch<EDIT_TOPICS> {
  return {
    types: [LocalActions.EDIT_TOPICS, LocalActions.EDIT_TOPICS_SUCCESS, LocalActions.EDIT_TOPICS_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/topics",
        method: "put",
        body: topicUpdates,
      }),
  };
}
export function getDatasetsWithRunningJobs(): BeforeDispatch<ADMIN_GET_DATASETS_WITH_RUNNING_JOBS> {
  return {
    types: [
      LocalActions.ADMIN_GET_DATASETS_WITH_RUNNING_JOBS,
      LocalActions.ADMIN_GET_DATASETS_WITH_RUNNING_JOBS_SUCCESS,
      LocalActions.ADMIN_GET_DATASETS_WITH_RUNNING_JOBS_FAIL,
    ],
    promise: (client) =>
      client.req({
        pathname: "/admin/indexJobs",
        method: "get",
      }),
    listFor: { admin: "jobs", account: false, searchTerm: false },
    append: false,
  };
}
export function getLimits(): BeforeDispatch<ADMIN_GET_LIMITS> {
  return {
    types: [LocalActions.ADMIN_GET_LIMITS, LocalActions.ADMIN_GET_LIMITS_SUCCESS, LocalActions.ADMIN_GET_LIMITS_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/admin/limits",
        method: "get",
      }),
  };
}
