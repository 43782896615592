import getClassName from "classnames";
import { difference } from "lodash-es";
import * as React from "react";
import type { PivotTableUIProps, TableInput, TQuerySort } from "react-pivottable";
import type { Prefix } from "@triply/utils";
import { getPrefixed } from "@triply/utils/prefixUtils";
import type { Binding, Term } from "../../../SparqlUtils";
import { SparqlVisualizationContext, useSparqlResults } from "../../SparqlVisualizationContext";
import { EmptyResults } from "../displayUtils";
import { googleChartRenderers } from "./gchartRenderer";
import "react-pivottable/pivottable.css";
import * as styles from "./styles.scss";

const PivotTableUI = React.lazy(() => import("react-pivottable"));
const Table = React.lazy(() => import("react-pivottable/PivotTable"));

const isChartRender = (renderType: string | undefined) => {
  if (!renderType) return false;
  return renderType in googleChartRenderers;
};

export interface PluginConfig {
  cols?: string[];
  rows?: string[];
  rendererName?: string;
  aggregatorName?: string;
  vals?: string[];
  colOrder?: TQuerySort;
  rowOrder?: TQuerySort;
}

export function canDraw() {
  return true;
}

function termToPivot(term: Term | undefined, prefixes: Prefix[]) {
  if (term === undefined) return "";
  if (term.type === "uri") {
    return getPrefixed(term.value, prefixes) || term.value;
  }
  return term.value;
}

function resultsToPivotTable(data: Binding[], prefixes: Prefix[]): TableInput {
  return data.map((binding) => {
    const newBinding: { [key: string]: string } = {};
    for (const key in binding) {
      newBinding[key] = termToPivot(binding[key], prefixes);
    }
    return newBinding;
  });
}

const PivotTable: React.FC<{}> = ({}) => {
  const { prefixes, getVisualizationConfig, setVisualizationConfig } = React.useContext(SparqlVisualizationContext);
  const { results, isTabular, emptyResults, noResults } = useSparqlResults();
  const [renderers, setRenderers] = React.useState<{ [name: string]: any }>({});

  React.useEffect(() => {
    const renderers = new Promise(async (resolve) => {
      const tableRenderers = await import("react-pivottable/TableRenderers");
      const createGoogleChartRenders = await import("./gchartRenderer");
      resolve({ ...tableRenderers.default, ...createGoogleChartRenders.default() });
    }).then((renderers: any) => {
      setRenderers(renderers);
    });
  }, []);

  const data = React.useMemo(() => {
    if (isTabular) {
      return resultsToPivotTable(results, prefixes);
    } else {
      return undefined;
    }
  }, [isTabular, prefixes, results]);
  const config = getVisualizationConfig("Pivot");
  const [restState, setRestState] = React.useState<Partial<PivotTableUIProps>>({});
  if (noResults) return null;
  if (emptyResults || !isTabular || !data) return <EmptyResults />;
  if (setVisualizationConfig)
    return (
      <div
        className={getClassName(styles.pivotTable, {
          [styles.chartRender]: isChartRender(config?.rendererName),
        })}
      >
        <React.Suspense>
          <PivotTableUI
            data={data}
            renderers={renderers}
            onChange={(pivotState) => {
              // Remove all properties we control
              const { data, onChange, renderers, ...persistableValues } = pivotState;
              // Spread all functions which are persisted
              const { rendererName, aggregatorName, vals, colOrder, rowOrder, ...staticValues } = persistableValues;
              // Due to a bug in react-pivottable when, dragging between cols and rows duplication occurs. https://github.com/plotly/react-pivottable/issues/164
              // Fixed by seeing which property was added, and removed that from the current other one
              let { rows, cols, ...restState } = staticValues;
              if (cols?.length !== config?.cols?.length) {
                const diff = difference(cols, config?.cols || [])?.[0];
                if (diff) {
                  rows = rows?.filter((el) => el !== diff);
                }
              }
              if (rows?.length !== config?.rows?.length) {
                const diff = difference(rows, config?.rows || [])?.[0];
                if (diff) {
                  cols = cols?.filter((el) => el !== diff);
                }
              }
              setVisualizationConfig("Pivot", {
                cols,
                rows,
                rendererName,
                aggregatorName,
                vals,
                colOrder,
                rowOrder,
              });
              setRestState(restState);
            }}
            {...config}
            {...restState}
          />
        </React.Suspense>
      </div>
    );
  return (
    <div
      className={getClassName(styles.pivotTable, {
        [styles.chartRender]: isChartRender(config?.rendererName),
      })}
    >
      <React.Suspense>
        <Table data={data} renderers={renderers} {...config} />
      </React.Suspense>
    </div>
  );
};

export default PivotTable;
