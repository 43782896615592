import * as url from "url";
import * as React from "react";
import type { TermLiteral } from "#components/index.ts";
import { TermLiteralDefault } from "#components/index.ts";

class TermLiteralLink extends React.PureComponent<TermLiteral.Props, any> {
  static shouldRender(props: TermLiteral.Props) {
    return props.datatype === "http://www.w3.org/2001/XMLSchema#anyURI";
  }
  render() {
    const { value, className } = this.props;
    const parsed = url.parse(value);
    return parsed.protocol === "http:" || parsed.protocol === "https:" ? (
      <div className={className} title={this.props.expanded ? this.props.datatype : ""}>
        <a href={value} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      </div>
    ) : (
      <TermLiteralDefault {...this.props} />
    );
  }
}
export default TermLiteralLink;
