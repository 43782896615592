import getClassName from "classnames";
import { push } from "connected-react-router";
import * as React from "react";
import { useSelector } from "react-redux";
import { asyncConnect } from "redux-connect";
import { FlexContainer, Meta, QueryListItem, SearchField, SinkList } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import type { GlobalState } from "#reducers/index.ts";
import { getQueries, getQueryList, isQueryListLoaded } from "#reducers/queries.ts";
import * as styles from "./style.scss";

interface Props extends IComponentProps {}

const QuerySearch: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const parsedSearchString = parseSearchString(props.location.search);
  const searchTerm = typeof parsedSearchString.q === "string" && !!parsedSearchString.q.length && parsedSearchString.q;
  const { queries, nextPage } = useSelector((state: GlobalState) => {
    const queries = getQueryList(state, { account: false, searchTerm: searchTerm });
    return {
      queries: queries,
      nextPage: (queries?.length > 0 && state.queries.nextPage) || undefined,
    };
  });

  const loadNextPage = React.useCallback(() => {
    return dispatch(getQueries(undefined, undefined, nextPage));
  }, [dispatch, nextPage]);

  const search = React.useCallback(
    (term: string) => {
      return dispatch(
        push({
          pathname: `/queries`,
          search: stringifyQuery({ q: term.trim() }),
        }),
      );
    },
    [dispatch],
  );

  return (
    <>
      <Meta title="Queries" currentPath={props.location.pathname} />
      <FlexContainer className="mt-3">
        <div className={styles.outerSearchContainer}>
          <div className={getClassName("my-7 px-1", styles.innerSearchContainer)}>
            <SearchField
              key={searchTerm || ""}
              search={search}
              initialSearchTerm={searchTerm || ""}
              ariaLabel="Sitewide"
              placeHolder="Find a query"
              autoFocus
            />
          </div>
        </div>

        <SinkList loadNextPage={nextPage ? loadNextPage : undefined} noContentMsg="No queries found">
          {queries.map((query) => (
            <QueryListItem key={query.id} query={query} showOwner />
          ))}
        </SinkList>
      </FlexContainer>
    </>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ location, store: { dispatch, getState } }) => {
      const query = parseSearchString(location.search);
      if (
        !isQueryListLoaded(getState(), {
          account: false,
          searchTerm: typeof query.q === "string" && !!query.q.length && query.q,
        })
      ) {
        return dispatch<any>(getQueries(undefined, query));
      }
    },
  },
])(QuerySearch) as typeof QuerySearch;
