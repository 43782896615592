import { jsonLanguage } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";
import { formatNumber } from "@core/utils/formatting";
import { Alert } from "@mui/material";
import { githubLight } from "@uiw/codemirror-theme-github";
import CodeMirror from "@uiw/react-codemirror";
import getClassName from "classnames";
import * as React from "react";
import { Button } from "..";

const DEFAULT_MAX_LINES = 1_500;

interface Props {
  data: string;
  className?: string;
  maxLines?: number;
}

const JsonViewer: React.FC<Props> = ({ data, className, maxLines = DEFAULT_MAX_LINES }) => {
  const [displayLines, setDisplayLines] = React.useState(() => {
    return data.split("\n").slice(0, maxLines).join("\n");
  });
  const [lineCount, setLines] = React.useState(data.split("\n").length);
  const [displayAll, setDisplayAll] = React.useState(false);
  React.useEffect(() => {
    const lines = data.split("\n");

    setDisplayLines(lines.slice(0, maxLines).join("\n"));
    setLines(lines.length);
    setDisplayAll(false);
  }, [data, maxLines]);
  return (
    <div className={className}>
      <CodeMirror
        className={getClassName({ hidden: data === "" })}
        value={displayAll ? data : displayLines}
        readOnly
        extensions={[jsonLanguage, githubLight, EditorView.lineWrapping]}
      />
      {!displayAll && lineCount > maxLines && (
        <Alert
          severity={"info"}
          action={
            <Button variant="text" onClick={() => setDisplayAll(true)}>
              Load all lines
            </Button>
          }
        >
          Displaying the first {formatNumber(maxLines)} lines of {formatNumber(lineCount)}. Loading all lines might
          impact your browser performance.
        </Alert>
      )}
    </div>
  );
};

export default React.memo(JsonViewer);
