import { Alert, NoSsr } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { renderRoutes } from "react-router-config";
import { Link, Redirect, useRouteMatch } from "react-router-dom";
import { FlexContainer } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { ClassInfoProvider } from "./ClassInfoContext";
import ProcessContextProvider from "./Process";
import Toolbar from "./Toolbar";
import * as styles from "./style.scss";

export const MAX_SEARCH_RESULTS = 50;

interface Props extends IComponentProps {}

const DataEditor: React.FC<Props> = ({ route, location }) => {
  const currentAccount = useCurrentAccount();
  const currentDs = useCurrentDataset();
  const match = useRouteMatch<{ renderer?: string }>();
  const acl = useAcl();

  if (!currentDs || !currentAccount) return null;
  const userAllowedToCreateData = acl.check({
    action: "importDataToDataset",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;
  if (currentDs.graphCount === 0) {
    if (userAllowedToCreateData) {
      return (
        <FlexContainer innerClassName={getClassName(styles.container, "my-5")}>
          <div>
            <Link to={`/${currentAccount.accountName}/${currentDs.name}/graphs`} className="noLinkDecoration">
              <Alert className="shadow" key="alert" severity="info">
                This dataset is empty, start by importing data
              </Alert>
            </Link>
          </div>
        </FlexContainer>
      );
    } else {
      return <div className={styles.noContentMsg}>This dataset is empty</div>;
    }
  }
  if (!match.params.renderer) {
    return (
      <Redirect
        to={{
          ...location,
          search: location.search,
          pathname: `${location.pathname}${location.pathname.endsWith("/") ? "" : "/"}skos`,
        }}
      />
    );
  }
  return (
    <div className={styles.fullPage}>
      <ProcessContextProvider>
        <ClassInfoProvider>
          <Toolbar />
          <NoSsr>{renderRoutes(route?.routes)}</NoSsr>
        </ClassInfoProvider>
      </ProcessContextProvider>
    </div>
  );
};

export default DataEditor;
