import type { Models, Routes } from "@triply/utils";
import type { BeforeDispatch, GlobalAction } from "#reducers/index.ts";
import { Actions } from "#reducers/index.ts";
import type { Member, Org, User } from "./accountCollection.ts";

export const LocalActions = {
  ADD_MEMBER: "triply/orgs/ADD_MEMBER",
  ADD_MEMBER_SUCCESS: "triply/orgs/ADD_MEMBER_SUCCESS",
  ADD_MEMBER_FAIL: "triply/orgs/ADD_MEMBER_FAIL",
  CREATE_ORG: "triply/orgs/CREATE_ORG",
  CREATE_ORG_SUCCESS: "triply/orgs/CREATE_ORG_SUCCESS",
  CREATE_ORG_FAIL: "triply/orgs/CREATE_ORG_FAIL",
  UPDATE_MEMBER: "triply/orgs/UPDATE_MEMBER",
  UPDATE_MEMBER_SUCCESS: "triply/orgs/UPDATE_MEMBER_SUCCESS",
  UPDATE_MEMBER_FAIL: "triply/orgs/UPDATE_MEMBER_FAIL",
  DELETE_MEMBER: "triply/orgs/DELETE_MEMBER",
  DELETE_MEMBER_SUCCESS: "triply/orgs/DELETE_MEMBER_SUCCESS",
  DELETE_MEMBER_FAIL: "triply/orgs/DELETE_MEMBER_FAIL",
} as const;

export type ADD_MEMBER = GlobalAction<
  {
    types: [
      typeof LocalActions.ADD_MEMBER,
      typeof LocalActions.ADD_MEMBER_SUCCESS,
      typeof LocalActions.ADD_MEMBER_FAIL,
    ];
    org: Org;
  },
  Routes.account.members.Post
>;

export type CREATE_ORG = GlobalAction<
  {
    types: [
      typeof LocalActions.CREATE_ORG,
      typeof LocalActions.CREATE_ORG_SUCCESS,
      typeof LocalActions.CREATE_ORG_FAIL,
    ];
    user: User;
    verbose: true;
  },
  Routes.account.orgs.Post
>;

export type UPDATE_MEMBER = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_MEMBER,
      typeof LocalActions.UPDATE_MEMBER_SUCCESS,
      typeof LocalActions.UPDATE_MEMBER_FAIL,
    ];
    org: Org;
    member: Member;
  },
  Routes.account.members._member.Patch
>;

export type DELETE_MEMBER = GlobalAction<
  {
    types: [
      typeof LocalActions.DELETE_MEMBER,
      typeof LocalActions.DELETE_MEMBER_SUCCESS,
      typeof LocalActions.DELETE_MEMBER_FAIL,
    ];
    org: Org;
    user: User;
  },
  Routes.account.members._member.Delete
>;

export type LocalAction = ADD_MEMBER | CREATE_ORG | UPDATE_MEMBER | DELETE_MEMBER;

export function createOrganization(
  user: User,
  body: { accountName: string; name: string },
): BeforeDispatch<CREATE_ORG> {
  return {
    types: [Actions.CREATE_ORG, Actions.CREATE_ORG_SUCCESS, Actions.CREATE_ORG_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/accounts/" + user.accountName + "/orgs",
        method: "post",
        body: body,
      }),
    user: user,
    verbose: true,
  };
}
export function addMember(org: Org, memberName: string, role: Models.OrgRole): BeforeDispatch<ADD_MEMBER> {
  return {
    types: [Actions.ADD_MEMBER, Actions.ADD_MEMBER_SUCCESS, Actions.ADD_MEMBER_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/accounts/" + org.accountName + "/members",
        method: "post",
        body: {
          accountName: memberName,
          role: role,
        },
      }),
    org: org,
  };
}
export function removeMemberFromOrg(memberToRemove: User, org: Org): BeforeDispatch<DELETE_MEMBER> {
  return {
    types: [Actions.DELETE_MEMBER, Actions.DELETE_MEMBER_SUCCESS, Actions.DELETE_MEMBER_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/accounts/" + org.accountName + "/members/" + memberToRemove.accountName,
        method: "delete",
      }),
    user: memberToRemove,
    org: org,
  };
}

export function disallowedToRemoveMemberFromOrg(org: Org, member?: Member): string | undefined {
  if (org.members?.length === 1)
    return "This organization has 1 member left. You are not allowed to remove all members of an organization.";
  if (member) return disallowedToChangeRoleOf(org, member);
  return undefined;
}

//Not allowed to change role when this member is the only remaining owner
export function disallowedToChangeRoleOf(org: Org, member: Member): string | undefined {
  const hasOtherOwner =
    org.members && org.members.find((m) => m.role === "owner" && m.user.accountName !== member.user.accountName);
  if (member.role === "owner" && !hasOtherOwner) {
    return "This is the only owner of this organization. You are not allowed to remove all owners.";
  }
  return undefined;
}
export function changeRole(org: Org, member: Member, role: Models.OrgRole): BeforeDispatch<UPDATE_MEMBER> {
  return {
    types: [Actions.UPDATE_MEMBER, Actions.UPDATE_MEMBER_SUCCESS, Actions.UPDATE_MEMBER_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/accounts/" + org.accountName + "/members/" + member.user.accountName,
        method: "patch",
        body: {
          role: role,
        },
      }),
    member: member,
    org: org,
  };
}
