import { clone, flatten, size, uniqBy, values } from "lodash-es";
import type { ObjectFilter, QueryPattern } from "@triply/utils/Constants";
import type { NtriplyTerm } from "@triply/utils/Models";
import type { Statements } from "#reducers/resourceDescriptions.ts";

export type Direction = "forward" | "backward";

export default class LdTreeNode {
  private term: NtriplyTerm;
  private direction: Direction;
  private childrenCount = 0;
  private parent?: LdTreeNode;
  private statements: Statements;
  private predicate?: string;
  private children: {
    [pred: string]: LdTreeNode[];
  } = {};
  private depth = 0;
  /**
   * Private methods
   */
  private constructor(
    term: NtriplyTerm,
    statements: Statements,
    direction: Direction,
    predicate?: string,
    depth?: number,
    parent?: LdTreeNode,
  ) {
    this.term = term;
    this.statements = statements;
    if (depth) this.depth = depth;
    this.predicate = predicate;
    this.direction = direction;
    this.parent = parent;
  }

  public getRoot(): LdTreeNode {
    if (!this.parent) return this;
    return this.parent.getRoot();
  }
  private addChild(predTerm: NtriplyTerm, objTerm: NtriplyTerm) {
    const predTermVal = predTerm.value;
    this.childrenCount++;
    if (!this.children[predTermVal]) this.children[predTermVal] = [];
    const obj = LdTreeNode.fromStatements(objTerm, this.statements, "forward", predTermVal, this.depth + 1, this);
    this.children[predTermVal].push(obj);
  }
  private populateChildren(statements: Statements) {
    if (this.term.termType === "Literal") {
      //nothing to populate. it's a leaf
    } else if (this.depth < 3) {
      // Only a 2 deep depth is interesting for the browser to get relevant information, also avoids cyclic dependencies
      for (const statement of statements) {
        // Check if the term exists as a subject of object of an statement
        if (this.termEquals(statement[this.direction === "backward" ? 2 : 0].value)) {
          this.addChild(statement[1], statement[this.direction === "backward" ? 0 : 2]);
        }
      }
    }
  }

  public getChildren(predicate?: string): LdTreeNode[] {
    if (!predicate) return flatten<LdTreeNode>(values(this.children));
    if (this.children[predicate]) return this.children[predicate];
    return [];
  }
  /**
   * Public methods
   */
  public hasChildren(): boolean {
    return !!size(this.children);
  }
  public getStatements() {
    return this.statements;
  }
  public termEquals(termVal: string) {
    return this.term.value === termVal;
  }
  public termMatches(requirements: ObjectFilter<NtriplyTerm>) {
    return !(
      (requirements.value && requirements.value !== this.term.value) ||
      (requirements.language && requirements.language !== this.term.language) ||
      (requirements.datatype && requirements.datatype !== this.term.datatype) ||
      (requirements.termType && requirements.termType !== this.term.termType) ||
      (requirements.validationFunction && !requirements.validationFunction(this.term))
    );
  }
  public getDepth() {
    return this.depth;
  }
  public find(pattern?: QueryPattern<NtriplyTerm>, root = true) {
    return new Query(this, pattern, root);
  }
  public getChildrenCount() {
    return this.childrenCount;
  }
  public getParent() {
    return this.parent;
  }
  public getParents(): LdTreeNode[] {
    if (this.parent) {
      return [this.parent].concat(this.parent.getParents());
    }
    return [];
  }
  public getTerm() {
    return this.term;
  }
  public getLevel() {
    return this.depth;
  }
  public getKey(): string {
    return (
      (this.predicate || "_") +
      this.depth +
      this.childrenCount +
      this.term.value +
      this.term.datatype +
      this.term.language
    );
  }
  public getPredicate(): string | undefined {
    return this.predicate;
  }
  public toJson(): Object {
    return {
      term: this.term,
      count: this.childrenCount,
      children: this.getChildren().map((c) => c.toJson()),
    };
  }
  public static fromStatements(
    term: NtriplyTerm,
    statements: Statements,
    direction: Direction,
    predicate?: string,
    level = 0,
    parent?: LdTreeNode,
  ) {
    const tree = new LdTreeNode(term, statements, direction, predicate, level, parent);
    tree.populateChildren(statements);
    return tree;
  }
}

export class Query {
  private pattern: QueryPattern<NtriplyTerm>;
  private tree: LdTreeNode;
  private returnOffset = -1;
  private _limit = -1;
  private rootQuery = false;
  constructor(tree: LdTreeNode, pattern?: QueryPattern<NtriplyTerm>, root: boolean = true) {
    this.pattern = pattern || ([] as QueryPattern<NtriplyTerm>);
    this.tree = tree;
    this.rootQuery = root;
  }

  private patternHasBoundVariables() {
    return !!this.pattern.find((p) => !!p);
  }
  public depth(offset: number) {
    this.returnOffset = offset;
    return this;
  }
  public limit(limit: number) {
    this._limit = limit;
    return this;
  }
  private postProcessResults(results: LdTreeNode[]) {
    if (!this.rootQuery) return results;
    if (this._limit > 0) {
      results = results.slice(0, this._limit);
    }
    //make results unique. We could get non-distinct results if we're retrieving from e.g. a depth of 1, but at a depth
    //of 2 there are more than 1 leaf nodes
    return uniqBy(results, (n) => n.getKey());
  }
  public exec(): LdTreeNode[] {
    const hasMatchesToBeMade = this.patternHasBoundVariables();
    //we've reached a leaf node. If there are no more matches to be made, just return this one
    if (this.tree.getChildrenCount() === 0 && (this.pattern.length === 0 || !hasMatchesToBeMade)) {
      if (this.returnOffset >= 0 && this.returnOffset !== this.tree.getDepth()) {
        return [];
      }
      return [this.tree];
    }

    const pattern = clone(this.pattern);
    const matchPred = pattern.shift();
    const matchingPreds = this.tree.getChildren(matchPred as string);
    const matchObj = pattern.shift();

    var matchingObjs = matchingPreds;
    if (matchObj) {
      matchingObjs = matchingPreds.filter((node) => node.termMatches(matchObj as any));
    }

    const results = pattern.length
      ? flatten(matchingObjs.map((node) => node.find(pattern, false).exec()))
      : matchingObjs;
    if (this.returnOffset < 0) return this.postProcessResults(results);

    /**
     * Traverse upwards in the tree to select the node we're interested in
     */
    const selectedResults: LdTreeNode[] = [];
    for (const result of results) {
      var selectedNode = result;

      for (var l = selectedNode.getDepth(); l > this.returnOffset; l--) {
        const parent = selectedNode.getParent();
        if (!parent) throw new Error("Expected a parent given the node offset");
        selectedNode = parent;
      }
      selectedResults.push(selectedNode);
    }

    return this.postProcessResults(selectedResults);
  }
}
