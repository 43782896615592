/*
 *  18.2.1 Variable Scope
 *  https://www.w3.org/TR/sparql11-query/#variableScope
 *
 *
 *  Syntax Form               | In-scope variables
 *  -----------               | ------------------
 *  Basic Graph Pattern (BGP) | v occurs in the BGP
 *  Path                      | v occurs in the path
 *  Group { P1 P2 ... }       | v is in-scope if it is in-scope in one or more of P1, P2, ...
 *  GRAPH term { P }          | v is term or v is in-scope in P
 *  { P1 } UNION { P2 }       | v is in-scope in P1 or in-scope in P2
 *  OPTIONAL {P}              | v is in-scope in P
 *  SERVICE term {P}          | v is term or v is in-scope in P
 *  BIND (expr AS v)          | v is in-scope
 *  SELECT .. v .. { P }      | v is in-scope
 *  SELECT ... (expr AS v)    | v is in-scope
 *  GROUP BY (expr AS v)      | v is in-scope
 *  SELECT * { P }            | v is in-scope in P
 *  VALUES v { values }       | v is in-scope
 *  VALUES varlist { values } | v is in-scope if v is in varlist
 */
import { IllegalBindError, IllegalWildcardAndGroupByError } from "./error.ts";
import type { Types } from "./index.ts";
import { isWildcard } from "./serialize.ts";
import type { Expression } from "./types.ts";
import { ungroupedVariablesCheck } from "./ungrouped-variables.ts";

export function validate(
  ast: Types.SparqlQuery,
  opts?: Pick<Types.ParseOptions, "excludeFromUngroupedVariablesCheck">,
) {
  // We must deplete the generator for any errors to pop up
  if (ast.type === "query") {
    for (const _ of generateVariablesInScopeOfQuery(ast)) continue;
    ungroupedVariablesCheck(ast, opts?.excludeFromUngroupedVariablesCheck ?? new Set());
  } else {
    for (const op of ast.updates) {
      // this is the only update operation that can contain a BIND
      if (op.type === "insertdelete") {
        getVariablesInScopeOfPatterns(op.where);
        ungroupedVariablesCheck(op.where, opts?.excludeFromUngroupedVariablesCheck ?? new Set());
      }
    }
  }
}

/**
 *  18.2.4.1 Grouping and Aggregation
 *           ========================
 *  https://www.w3.org/TR/sparql11-query/#sparqlGroupAggregate
 *
 *  > If Q contains GROUP BY exprlist
 *  >    Let G := Group(exprlist, P)
 *  > Else If Q contains an aggregate in SELECT, HAVING, ORDER BY
 *  >    Let G := Group((1), P)
 *  > Else
 *  >    skip the rest of the aggregate step
 *  >    End
 */
export function isAggregateQuery(query: Types.Query | Types.SubSelect): boolean {
  return (
    !!query.group.length ||
    // SELECT
    ("variables" in query &&
      !isWildcard(query.variables) &&
      query.variables.some((variable) => "expression" in variable && isAggregateExpression(variable.expression))) ||
    !!query.having.some(isAggregateExpression) ||
    !!query.order.some((variable) => isAggregateExpression(variable.expression))
  );
}

function isAggregateExpression(expression: Types.FunctionArg): boolean {
  // This happens in IN and NOT IN expressions
  if (Array.isArray(expression)) return expression.some(isAggregateExpression);
  if ("termType" in expression) return false;
  switch (expression.type) {
    case "aggregate":
      return true;
    case "function":
    case "iriFunction":
      return expression.args.some(isAggregateExpression);
    // This happens in an Exists expression
    case "graph":
    case "group":
    case "minus":
    case "service":
    case "union":
    case "optional":
    case "query":
    case "bgp":
    case "bind":
    case "values":
    case "filter":
      return false;
  }
}

function* generateVariablesInScopeOfPattern(
  pattern: Exclude<Types.Pattern, Types.BindPattern>,
): Generator<string, undefined, undefined> {
  switch (pattern.type) {
    case "bgp":
      // Basic Graph Pattern (BGP) | v occurs in the BGP
      // Path                      | v occurs in the path
      yield* pattern.triples.flatMap(getVariablesInScopeOfTriple);
      return;
    case "query":
      // SELECT .. v .. { P }      | v is in-scope
      // SELECT ... (expr AS v)    | v is in-scope
      // GROUP BY (expr AS v)      | v is in-scope
      // SELECT * { P }            | v is in-scope in P
      yield* generateVariablesInScopeOfQuery(pattern);
      return;
    case "values":
      // VALUES v { values }       | v is in-scope
      // VALUES varlist { values } | v is in-scope if v is in varlist
      yield* pattern.values.flatMap((bindings) => Object.keys(bindings));
      return;
    case "service": // intentional fall-through
    case "graph":
      // SERVICE term {P}          | v is term or v is in-scope in P
      // GRAPH term { P }          | v is term or v is in-scope in P
      if (pattern.name.termType === "Variable") {
        yield pattern.name.value;
      } // intentional fall-through
    case "group":
    case "optional":
    case "union":
      // Group { P1 P2 ... }       | v is in-scope if it is in-scope in one or more of P1, P2, ...
      // OPTIONAL {P}              | v is in-scope in P
      // { P1 } UNION { P2 }       | v is in-scope in P1 or in-scope in P2
      // Inside a union, earlier patterns don't influence later patterns
      yield* getVariablesInScopeOfPatterns(
        pattern.patterns,
        pattern.type === "union" ? "independent operations" : undefined,
      );
      return;
    case "minus":
      // > use of a variable in FILTER, or in MINUS does not cause a variable to be in-scope outside of those forms.
      // But we do want to validate them!
      getVariablesInScopeOfPatterns(pattern.patterns);
      return;
    case "filter":
      // > use of a variable in FILTER, or in MINUS does not cause a variable to be in-scope outside of those forms.
      // But we do want to validate them!
      validateExistsExpression(pattern.expression);
      return;
    default:
      throw new Error("Please contact a developer. Code 1312.");
  }
}

function getVariablesInScopeOfPatterns(patterns: Types.Pattern[], independentOperations?: "independent operations") {
  const variables = new Set<string>();
  for (const pattern of patterns) {
    if (pattern.type === "bind") {
      // BIND (expr AS v)          | v is in-scope
      for (const v of generateVariablesInScopeOfVariablesList([pattern], independentOperations ? new Set() : variables))
        continue;
    } else for (const variable of generateVariablesInScopeOfPattern(pattern)) variables.add(variable);
  }
  return variables;
}

function* generateVariablesInScopeOfQuery(query: Types.Query | Types.SubSelect) {
  // SELECT * { P }            | v is in-scope in P
  const whereVariables = getVariablesInScopeOfPatterns(query.where);
  if ("variables" in query) {
    if (isWildcard(query.variables)) {
      if (isAggregateQuery(query)) throw new IllegalWildcardAndGroupByError(query.variables.location);
      yield* whereVariables;
    } else {
      // > Note that a subquery with a projection can hide variables;
      // These cases are disjoint with the *-case because:
      // 1. you can't have other things in the SELECT expression if you already
      //    have a wildcard
      // 2. the wildcard is syntactically forbidden in combination with GROUP BY

      // SELECT .. v .. { P }      | v is in-scope
      // SELECT ... (expr AS v)    | v is in-scope
      yield* generateVariablesInScopeOfVariablesList(query.variables, whereVariables);

      // GROUP BY (expr AS v)      | v is in-scope
      if (query.group) {
        // Grouping can be a VariableExpression, typings are wrong
        yield* generateVariablesInScopeOfVariablesList(query.group, whereVariables);
      }
    }
  }

  // VALUES v { values }       | v is in-scope
  // VALUES varlist { values } | v is in-scope if v is in varlist
  if (query.values) {
    yield* query.values.flatMap((bindings) => Object.keys(bindings));
  }
}

function getVariablesInScopeOfTriple(triple: Types.Triple) {
  const variables: string[] = [];
  if (triple.subject.termType === "Variable") variables.push(triple.subject.value);
  // Property paths cannot contain variables
  if ("termType" in triple.predicate && triple.predicate.termType === "Variable")
    variables.push(triple.predicate.value);
  if (triple.object.termType === "Variable") variables.push(triple.object.value);
  return variables;
}

function* generateVariablesInScopeOfVariablesList(
  variables: (Types.Variable | Types.IriTerm | Types.Grouping | Types.BindPattern)[],
  variablesInSameGroupGraphPattern: Set<string>,
) {
  for (const variable of variables) {
    if ("termType" in variable) {
      if (variable.termType === "Variable") {
        // SELECT .. v .. { P }      | v is in-scope
        yield variable.value;
      }
    } else {
      validateExistsExpression(variable.expression);
      if (variable.variable) {
        // SELECT ... (expr AS v)    | v is in-scope
        // GROUP BY (expr AS v)      | v is in-scope
        // BIND (expr AS v)          | v is in-scope
        if (variablesInSameGroupGraphPattern.has(variable.variable.value)) {
          // > The syntax error arises for use of a variable as the named target of
          // > AS (e.g. ... AS ?x), when the variable is used inside the WHERE clause
          // > of the SELECT or if already used as the target of AS in this SELECT
          // > expression.
          //
          // from: https://www.w3.org/TR/sparql11-query/#sparqlSelectExpressions
          //
          // > Within `GROUP BY` clauses the binding keyword, `AS`, may be used, such
          // > as `GROUP BY (?x + ?y AS ?z)`. This is equivalent to
          // > `{ ... BIND(?x + ?y AS ?z) } GROUP BY ?z`.
          //
          // from: https://www.w3.org/TR/sparql11-query/#groupby
          // So we should validate the group by clauses too
          throw new IllegalBindError(variable.variable);
        }
        variablesInSameGroupGraphPattern.add(variable.variable.value);
        yield variable.variable.value;
      }
    }
  }
}

function validateExistsExpression(expression: Expression) {
  if (
    "type" in expression &&
    expression.type === "function" &&
    (expression.function === "exists" || expression.function === "notexists")
  ) {
    getVariablesInScopeOfPatterns([expression.args[0]]);
  }
}
