import { last, sortBy } from "lodash-es";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import type { PropertyModel } from "../PropertyShape";
import type { FormValues } from "../Types";
import AutoCompleteEditor from "./AutoCompleteEditor";
import BooleanEditor from "./BooleanEditor";
import DetailsEditor from "./DetailsEditor";
import TextFieldEditor from "./TextFieldEditor";

export interface Editor {
  id: string; // an IRI
  Component: React.FC<{
    name: `properties.${string}.${number}`;
    propertyModel: PropertyModel;
  }>;
  getScore: (opts: { nodeKind: "IRI" | "Literal" | "NestedNode"; datatype: string | undefined }) => number;
}

const editors = [TextFieldEditor, AutoCompleteEditor, DetailsEditor, BooleanEditor];

const Editor: React.FC<{
  name: `properties.${string}.${number}`;
  propertyModel: PropertyModel;
}> = ({ name, propertyModel }) => {
  const { getValues } = useFormContext<FormValues>();

  const value = getValues(name);

  const selectedEditor = editors.find((editor) => editor.id === propertyModel.editor);

  const WinningEditor =
    selectedEditor?.Component ||
    last(
      sortBy(
        editors.map((editor) => {
          return {
            component: editor.Component,
            score: editor.getScore({
              nodeKind: value?.nodeKind || propertyModel.nodeKind || "Literal",
              datatype: (value?.nodeKind === "Literal" && value.datatype) || propertyModel.datatype,
            }),
          };
        }),
        "score",
      ),
    )!.component;

  return <WinningEditor name={name} propertyModel={propertyModel} />;
};

export default Editor;
