import * as React from "react";
import useClickOutside from "#helpers/hooks/useClickOutside.ts";
import useHover from "#helpers/hooks/useHover.ts";
/**
 * Hook that supports having eg a tooltip that opens both when clicking and hovering
 * The close state is set when clicking outside of a particular element (given as argument)
 */
export default function useClickOrHover<T extends HTMLElement, U extends HTMLElement>(
  clickableElement: React.RefObject<T | null>,
  outsideClickRef: React.RefObject<U | null>,
): [boolean, (active: boolean) => void] {
  const isHovered = useHover(clickableElement);
  const [active, setActive] = React.useState(false);
  const onOutsideClick = React.useCallback(() => {
    if (active) setActive(false);
  }, [active, setActive]);
  useClickOutside(onOutsideClick, outsideClickRef);
  return [active || isHovered, setActive];
}
