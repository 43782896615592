import eachDeep from "deepdash/eachDeep";
import type { InterfaceCoordinates } from "proj4";
import proj4 from "proj4";
import type { Coordinates, Geometry } from "@triplydb/recognized-datatypes/wkt";
import type { AllCrsUrl } from "./Crs.ts";
import { allCrsProjectionDefs, isSupportedCrs } from "./Crs.ts";
import { GeoFunctionError, UnsupportedGeoError } from "./helpers.ts";

proj4.defs(Object.entries(allCrsProjectionDefs).map(([url, def]) => [url, def.proj4]));

/**
 * Project a geometry to a new CRS
 * The geometry JSON is superset of GeoJSON. Any parsed WKT string (using recognized-datatypes) is supported
 */
export function geoProject<G extends Geometry>(geometry: G, toCrs: AllCrsUrl): G {
  if (!isSupportedCrs(geometry.crs)) {
    throw new UnsupportedGeoError(`Projecting of CRS ${geometry.crs} is not supported`);
  }
  if (geometry.crs === toCrs) return geometry;
  try {
    const converter = proj4(geometry.crs, toCrs);
    function project(c: InterfaceCoordinates): InterfaceCoordinates {
      // @AS the types don't allow for specifying the `enforceAxis` parameter.
      // `true` means that we want to use the axis order that is specified in the CRS
      // See https://www.npmjs.com/package/proj4#axis-order
      return (
        converter.forward as (c: Parameters<typeof converter.forward>[0], enforceAxis: boolean) => InterfaceCoordinates
      )(c, true);
    }

    const projString = allCrsProjectionDefs[toCrs].proj4;
    const toCrsHasZ = projString.includes("+vunits=") || projString.includes("+vto_meter="); // See https://proj.org/en/9.4/usage/projections.html#units
    const toCrsHasMeasurement = geometry.hasMeasurement; // 2024-09-10 Martin couldn't quickly find what part of the proj4 string indicates the presence of a measurement

    const transformedVal: G = eachDeep(structuredClone(geometry), (value: unknown) => {
      if (Array.isArray(value) && typeof value[0] === "number") {
        const coordinatesAsArray = value as Coordinates;
        const coordiatesAsObject: InterfaceCoordinates = { x: coordinatesAsArray[0], y: coordinatesAsArray[1] };

        if (geometry.hasZ) coordiatesAsObject.z = coordinatesAsArray[2];
        if (geometry.hasMeasurement) coordiatesAsObject.m = coordinatesAsArray[geometry.hasZ ? 3 : 2];

        const newCoordinatesAsObject = project(coordiatesAsObject);
        coordinatesAsArray.splice(0, coordinatesAsArray.length, newCoordinatesAsObject.x, newCoordinatesAsObject.y);
        if (toCrsHasZ) coordinatesAsArray.push(newCoordinatesAsObject.z ?? 0);
        if (toCrsHasMeasurement) coordinatesAsArray.push(newCoordinatesAsObject.m ?? 0);
        return false; // stop recursing into this part of the tree
      }
    });

    // Change the CRS
    transformedVal.crs = toCrs;

    if (toCrsHasZ) transformedVal.hasZ = true;
    else delete transformedVal.hasZ;

    if (toCrsHasMeasurement) transformedVal.hasMeasurement = true;
    else delete transformedVal.hasMeasurement;

    return transformedVal;
  } catch (e) {
    /**
     * The proj4j lib seems to throw strings sometimes...
     * This catch clause should never trigger, but encountered this during development
     * so added this check for good measure
     */
    if (typeof e === "string") throw new GeoFunctionError(`Failed to project coordinates: ${e}`, { cause: e });
    throw new GeoFunctionError((e as Error).message, { cause: e });
  }
}
