import { Fab, Tooltip } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { asyncConnect } from "redux-connect";
import useResizeObserver from "use-resize-observer";
import { ErrorPage, FontAwesomeIcon, StaticRouterContext } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { SparqlQueryQueue } from "#helpers/hooks/useSparqlQuery.tsx";
import { parseSearchString } from "#helpers/utils.ts";
import { accountIsCurrentAccount, getAccountInfo, useCurrentAccount } from "#reducers/app.ts";
import type { GlobalState } from "#reducers/index.ts";
import { addStoryHistoryItem } from "#reducers/sessionHistory.ts";
import { getStory } from "#reducers/stories.ts";
import Header from "./Header.tsx";
import MenuButton from "./MenuButton.tsx";
import Meta from "./Meta.tsx";
import SourceDatasets from "./SourceDatasets.tsx";
import StoryElements from "./StoryElements.tsx";
import useEmbedded from "./useEmbedded.ts";
import WarningsButton from "./WarningsButton.tsx";
import * as styles from "./index.scss";

export type LocationState =
  | {
      storyEditModalShown?: boolean;
      storyCopyModalShown?: boolean;
      storyTransferModalShown?: boolean;
      storyEmbedModalShown?: boolean;
      storyElementAddModalShown?: boolean;
      storyElementEditModalShown?: string;
      position?: number;
      preserveScrollPosition?: boolean;
    }
  | undefined;

const editingStories: { [storyId: string]: boolean } = {};

const Story: React.FC<IComponentProps> = ({ location, match }) => {
  const dispatch = useDispatch();
  const acl = useAcl();
  const story = useSelector((state: GlobalState) => state.stories.current);

  const currentAccount = useCurrentAccount();

  const resizeObserver = useResizeObserver();
  const pageRef = React.useRef<HTMLDivElement | null>(undefined);

  const manageStoryPermissions = acl.check({
    action: "manageStory",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount), accessLevel: story?.accessLevel! },
  }).granted;
  if (manageStoryPermissions && story?.content?.length === 0) editingStories[story.id] = true;

  const [editMode, setEditModeState] = React.useState(manageStoryPermissions && !!story && !!editingStories[story.id]);

  const embedded = useEmbedded({ location: location, pageRef: pageRef, resizeObserver: resizeObserver });
  const lncs = parseSearchString(location.search).lncs !== undefined;

  React.useEffect(() => {
    if (story !== undefined) dispatch(addStoryHistoryItem(story));
  }, [story, dispatch]);

  if (!story) {
    return <ErrorPage statusCode={404} message={`Story '${match.params.story}' does not exist`} />;
  }
  if (!currentAccount) return <ErrorPage statusCode={404} />;

  return (
    <StaticRouterContext headers={embedded ? { "X-Frame-Options": null, "Content-Security-Policy": null } : undefined}>
      <SparqlQueryQueue concurrency={4}>
        <div className={styles.container}>
          <div
            className={getClassName(styles.story, {
              embedded: embedded,
              [styles.lncs]: lncs,
            })}
            ref={(element) => {
              resizeObserver.ref(element);
              pageRef.current = element;
            }}
          >
            <Meta />

            {!embedded && (
              <div className={getClassName(styles.fabWrapper, "mui-fixed", styles.top)}>
                <MenuButton story={story} />
              </div>
            )}
            {!embedded && manageStoryPermissions && (
              <div className={getClassName(styles.fabWrapper, "mui-fixed", styles.bottom)}>
                <WarningsButton story={story} />
                <Tooltip title={`${editMode ? "Stop editing" : "Start editing"}`} placement="left">
                  <Fab
                    color={editMode ? "secondary" : "default"}
                    onClick={() => {
                      setEditModeState(!editMode);
                      if (story) editingStories[story.id] = !editMode;
                    }}
                    aria-label="Edit current story"
                  >
                    <FontAwesomeIcon icon="edit" className={styles.editIcon} />
                  </Fab>
                </Tooltip>
              </div>
            )}

            <Header story={story} embedded={embedded} lncs={lncs} />

            <StoryElements story={story} editMode={editMode} className={styles.content} />

            <div className="hideOnPrint" style={{ height: 80 }} />

            {!lncs && <SourceDatasets story={story} embedded={embedded} />}
          </div>
        </div>
      </SparqlQueryQueue>
    </StaticRouterContext>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ store: { dispatch, getState }, match: { params } }) => {
      if (!accountIsCurrentAccount(getState(), params.account)) {
        return dispatch<any>(getAccountInfo(getState(), params.account)).catch(() => {});
      }
    },
  },
  {
    promise: async ({ store: { dispatch, getState }, match: { params } }) => {
      const currentStory = getState().stories.current;
      if (currentStory && currentStory.name === params.story && currentStory.owner.accountName === params.account) {
        //currentStory is already the story we are looking for
        return;
      }
      //else fetch the story
      return dispatch<any>(getStory(params.account, params.story));
    },
  },
])(Story) as typeof Story;
