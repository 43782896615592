import { validation } from "@core/utils";
import { InputAdornment } from "@mui/material";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import type { MarkdownEditField, MuiTextField } from "#components/index.ts";
import {
  Alert,
  FontAwesomeIcon,
  FormField,
  Label,
  MarkdownEditFieldRedux,
  MuiTextFieldRedux,
} from "#components/index.ts";

namespace UserProfile {
  export interface FormData {
    name: string;
    email: string;
    description: string;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    passwordAuthMethod?: boolean;
  }
}

const nameValidator = validation.toStringValidator(
  validation.getProfileNameValidations({ messageSubject: "A profile name" }),
);
const emailValidator = validation.toStringValidator(validation.emailValidations);
const descriptionValidator = validation.toStringValidator(validation.accountDescriptionValidations);

const UserProfile = ReduxForm.reduxForm<UserProfile.FormData, UserProfile.Props>({
  form: "profileForm",
  enableReinitialize: true,
  validate: memoizee(
    (formData: UserProfile.FormData) => {
      return {
        name: nameValidator(formData.name),
        email: emailValidator(formData.email),
        description: descriptionValidator(formData.description),
      };
    },
    { max: 10 },
  ),
})(
  class UserProfile extends React.PureComponent<
    UserProfile.Props & ReduxForm.InjectedFormProps<UserProfile.FormData>,
    any
  > {
    render() {
      const { handleSubmit, submitting, submitSucceeded, error, pristine, invalid } = this.props;
      return (
        <div>
          <form method="POST" onSubmit={handleSubmit}>
            <FormField label="Profile name" className="mt-3 mb-6">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
                name="name"
                props={{
                  type: "text",
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon style={{ cursor: "default" }} icon="user" />
                      </InputAdornment>
                    ),
                  },
                }}
                component={MuiTextFieldRedux}
              />
            </FormField>
            <FormField label="Primary email address" className="mb-6">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextField.Props & { showEmailChangedWarning: boolean }>>
                name="email"
                props={{
                  type: "email",
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon style={{ cursor: "default" }} icon="envelope" />
                      </InputAdornment>
                    ),
                  },
                  showEmailChangedWarning: !!this.props.passwordAuthMethod,
                }}
                component={warningFieldRenderer}
              />
            </FormField>
            <FormField label="Description (optional)" className="mb-6">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MarkdownEditField.Props>>
                name="description"
                props={{
                  multiline: true,
                  rows: 5,
                  fullWidth: true,
                }}
                component={MarkdownEditFieldRedux}
              />
            </FormField>
            <Alert transparent message={error} />
            <div className="form-group mt-5">
              <LoadingButton
                type="submit"
                color="secondary"
                disabled={invalid || submitting || pristine}
                onClick={handleSubmit}
                loading={submitting}
              >
                Update profile
              </LoadingButton>
            </div>
          </form>
          {submitSucceeded && pristine && (
            <div className="mt-4">
              <Label success message="Profile successfully updated." />
            </div>
          )}
        </div>
      );
    }
  } as any,
);

export default UserProfile;

const warningFieldRenderer: React.FC<MuiTextFieldRedux.WrappedProps & { showEmailChangedWarning: boolean }> = ({
  showEmailChangedWarning,
  ...props
}) => (
  <>
    <MuiTextFieldRedux {...props} />
    {!props.meta.pristine && showEmailChangedWarning && (
      <Alert className="my-2" warning message="Use this updated email address next time you login" />
    )}
  </>
);
