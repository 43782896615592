import eachDeep from "deepdash/eachDeep";
import { fromPairs } from "lodash-es";
import type { Terms } from "@triplydb/data-factory";
import { factories } from "@triplydb/data-factory";
import { DATA_TYPES } from "@triplydb/data-factory/constants";
import { InjectError } from "./error.ts";
import type * as Types from "./types.ts";

type DeepCallback = Exclude<Parameters<typeof eachDeep>[1], undefined>;
type Context = Parameters<DeepCallback>[3];
type Parents = Context["parents"];

type ProjectionVariables = Types.SelectQuery["variables"];
type GroupByItems = Array<Types.Grouping>;
interface CommonDeclaration {
  name: string;
  defaultValue?: string;
  required?: boolean;
  allowedValues?: string[];
}
export interface NamedNodeDeclaration extends CommonDeclaration {
  termType: "NamedNode";
}
export interface LiteralDeclaration extends CommonDeclaration {
  termType: "Literal";
  datatype?: string;
  language?: string;
}

export type Declaration = NamedNodeDeclaration | LiteralDeclaration;
export interface Values {
  [variableName: string]: string | undefined;
}
export interface PaginationArgs {
  page: number;
  pageSize: number;
  maximumSize?: number;
}
export interface InjectArgs {
  declarations: Array<Declaration>;
  values: Values;
}
function assertIsFinitePositive(opts: { argument: string; val: number; max?: number }) {
  const n = Math.floor(opts.val);
  if (n === Infinity || n !== opts.val || n < 1) {
    throw new InjectError({ arg: opts.argument, message: `Argument '${opts.argument}' must be a positive integer` });
  }
  if (opts.max && n > opts.max) {
    throw new InjectError({ arg: opts.argument, message: `Argument '${opts.argument}' may be at most ${opts.max}` });
  }
}

export function assertValidPaginationArgs(opts: PaginationArgs) {
  assertIsFinitePositive({
    argument: "page",
    val: opts.page,
  });
  assertIsFinitePositive({
    argument: "pageSize",
    val: opts.pageSize,
    max: opts?.maximumSize,
  });
}

export function assertValidValue({ declaration, value }: { declaration: Declaration; value: string | undefined }) {
  if (declaration.allowedValues && declaration.allowedValues.length > 0) {
    if (!value || declaration.allowedValues.indexOf(value) < 0) {
      const allowed = declaration.allowedValues.join(", ");
      throw new InjectError({ message: `"${declaration.name}" must be one of [${allowed}]`, declaration });
    }
  }
  if (value !== undefined) {
    if (declaration.termType === "NamedNode") {
      try {
        factories.compliant.namedNode(value);
      } catch (e) {
        throw new InjectError({
          arg: declaration.name,
          value,
          declaration,
          message: `Argument ${declaration.name} is not a valid IRI`,
          cause: e,
        });
      }
    } else {
      // We have a language tag or we're a string literal
      if (declaration.language || !declaration.datatype) {
        try {
          factories.compliant.literal(value, declaration.language);
        } catch (e) {
          throw new InjectError({
            arg: declaration.name,
            value,
            declaration,
            cause: e,
            message: `Argument ${declaration.name} is not a valid string literal`,
          });
        }
      } else {
        try {
          factories.compliant.literal(value, factories.compliant.namedNode(declaration.datatype));
        } catch (e) {
          throw new InjectError({
            arg: declaration.name,
            value,
            declaration,
            cause: e,
            message: `Argument ${declaration.name} is not a valid literal for datatype ${declaration.datatype}`,
          });
        }
      }
    }
  }
}
export function assertValidValues(opts: {
  declarations: Array<Declaration>;
  values: { [variableName: string]: string | undefined };
}) {
  for (const declaration of opts.declarations) {
    if (declaration.required && !declaration.defaultValue && !(declaration.name in opts.values)) {
      throw new InjectError({ declaration, message: `Argument '${declaration.name}' is required` });
    }
  }

  for (const arg of Object.keys(opts.values)) {
    if (["page", "pageSize", "pageMeta"].includes(arg)) return;
    const value = opts.values[arg];
    const declaration = opts.declarations.find((declaration) => declaration.name === arg);
    if (declaration === undefined) {
      throw new InjectError({ arg, message: `Argument ${arg} is not allowed` });
    }
    assertValidValue({ value, declaration });
  }
}

/**
 * Modifies the SPARQL AST and adjusts the limit and offset values.
 * Update queries are not supported. Other queries (e.g. construct/ask/select/describe) are
 */
export function applyPaginationInPlace<S extends Types.SelectQuery | Types.ConstructQuery | Types.DescribeQuery>(
  parsedQuery: S,
  opts: {
    page: number;
    pageSize: number;
  },
) {
  const { page, pageSize } = opts;
  if (page === undefined && pageSize === undefined) {
    // Nothing to paginate
    return parsedQuery;
  }

  const limit = opts.pageSize;
  const offset = (opts.page - 1) * opts.pageSize;

  let appliedPaginationOnSubquery = false;
  /**
   * Find possible subqueries
   */
  eachDeep(parsedQuery, (value: unknown, key) => {
    // some early stop criteria to reduce this search tree
    if (key === "triples" || key === "variables") return false;
    if (!value || typeof value !== "object") return false;
    if ("queryType" in value && value.queryType === "subselect") {
      // It's a subquery. Start casting to that type, to make sure we're not
      // breaking this check when possibly changing the types later
      let subSelect = value as Types.SubSelect;
      if (subSelect.annotations.paginate) {
        appliedPaginationOnSubquery = true;
        subSelect.limit = limit;
        subSelect.offset = offset;
      } // else: do nothing. See https://issues.triply.cc/issues/9483
    }
    return;
  });
  if (appliedPaginationOnSubquery) {
    parsedQuery.limit = null;
    parsedQuery.offset = null;
  } else {
    parsedQuery.limit = limit;
    parsedQuery.offset = offset;
  }
  return parsedQuery;
}
export function injectVariablesInPlace(
  parsedQuery: Types.SparqlQuery,
  opts: {
    declarations: Array<Declaration>;
    values: { [variableName: string]: string | undefined };
  },
) {
  const declarationsMap = fromPairs(opts.declarations.map((d) => [d.name, d]));

  function getReplaceInfo(variableName: string) {
    const declaration = declarationsMap[variableName];

    if (!declaration) return;
    const stringReplacement = opts.values[variableName] || declaration.defaultValue;
    if (!stringReplacement) return;
    return {
      stringReplacement,
      declaration,
    };
  }
  /**
   * Uses lenient parsing. That way, it's up to the caller of this function to validate terms (using `assertValidValues`) or not
   * Useful, considering we may want to avoid throwing errors in some UI contexts, but handle invalid terms more gracefully
   */
  function replaceVar(variableName: string) {
    const replaceInfo = getReplaceInfo(variableName);

    if (!replaceInfo) return;
    if (replaceInfo.declaration.termType === "NamedNode") {
      return factories.lenient.namedNode(replaceInfo.stringReplacement);
    } else {
      //its a literal
      if (replaceInfo.declaration.language) {
        return factories.lenient.literal(replaceInfo.stringReplacement, replaceInfo.declaration.language);
      } else if (replaceInfo.declaration.datatype) {
        return factories.lenient.literal(
          replaceInfo.stringReplacement,
          factories.lenient.namedNode(replaceInfo.declaration.datatype),
        );
      } else {
        return factories.lenient.literal(replaceInfo.stringReplacement);
      }
    }
  }

  if (opts.declarations.length > 0) {
    function inSelectVariablesClause(parents: Parents) {
      return (
        parents &&
        // typed as any because ESM difficulties. Try removing later
        parents.some((parent: any) => {
          return (
            parent.key === "variables" &&
            // Dont check the immediate parent, as that's the array of `variables`. Instead, check the parent above for the query type
            (parent.parent?.value.queryType === "select" || parent.parent?.value.queryType === "subselect")
          );
        })
      );
    }
    function isVariableExpression(value: unknown): value is Types.VariableExpression {
      return !!value && typeof value === "object" && "expression" in value && "variable" in value;
    }
    function isUsedInVariableExpression(value: unknown, variableName: string): value is Types.VariableExpression {
      return isVariableExpression(value) && value.variable.value === variableName;
    }
    function inOperatorArgument(parents: Parents) {
      // Typed as any because ESM difficulties. Try removing it later
      return parents && parents.some((parent: any) => parent.key === "args");
    }
    type Bound = Extract<Types.Function, { function: "bound" }>;
    function isBoundExpression(value: unknown): value is Bound {
      return (
        !!value &&
        typeof value === "object" &&
        "type" in value &&
        value.type === "function" &&
        "function" in value &&
        value.function === "bound"
      );
    }
    eachDeep(parsedQuery, (value, key, parentValue, context) => {
      if (inSelectVariablesClause(context.parents) && value && value.termType === "Variable") {
        // E.g., we've encountered `(IF(?bla, 1,1) as ?something)` in the projection clause
        // we don't want to add an expression here, but we want to replace the variable as usual.
        if (!inOperatorArgument(context.parents)) {
          if (isVariableExpression(parentValue)) {
            if (key === "expression") {
              // we've encountered the `?bla` in `(?bla as ?something)`
              const projectionVariable: Terms.Variable = value;
              const projectionVariableName = projectionVariable.value;
              const replaceWith = replaceVar(projectionVariableName);
              if (replaceWith) {
                parentValue[key] = replaceWith;
              }
            }
            // E.g., we've encountered `("bla" as ?something)` in the projection clause
            return;
          }
          const projectionVariable: Terms.Variable = value;
          const projectionVariableName = projectionVariable.value;
          const replaceWith = replaceVar(projectionVariableName);
          if (replaceWith) {
            parentValue[key] = {
              expression: replaceWith,
              variable: projectionVariable,
            };
            // Check the parent for whether we already added aliases for this variable. If so, reset that
            // (make sure to remove the last item from the parents, otherwise we're undoing the projection variable we just added)
            resetParentAlias(context.parents, projectionVariableName);
            return false;
          }
        }
      }

      if (isBoundExpression(value)) {
        const replaceWith = replaceVar(value.args[0].value);
        if (replaceWith) {
          parentValue[key] = factories.compliant.literal(true, DATA_TYPES.XSD_BOOLEAN);
        }
      }

      if (key === "group") {
        const groupByItems: GroupByItems = value;
        for (let i = 0; i < groupByItems.length; i++) {
          const groupByItem = groupByItems[i];
          if (
            groupByItem.expression &&
            "termType" in groupByItem.expression &&
            groupByItem.expression.termType === "Variable"
          ) {
            const groupByVariableName = groupByItem.expression.value;
            if (hasParentProjectionVariable(context.parents, groupByVariableName)) {
              // There is already a parent projection variable for this one (we may have aliased it above)
              // In that case, no need to replace the group by with a literal/iri term
              continue;
            }
            const replaceWith = replaceVar(groupByVariableName);
            if (replaceWith) {
              groupByItems[i] = {
                expression: replaceWith,
                variable: groupByItem.expression,
              };
            }
          }
        }
        return false; // no need to do deeper. We dont want our generic variable replacer to mess things up
      }
      /**
       * This section will cater to any other part of the SPARQL query that arent projection / group-by clauses (those are done above)
       */
      if (value && value.termType === "Variable") {
        const term: Terms.Term = value;
        // We want to skip replacing the variable when we encounter `("bla" as ?toReplace)`, as we'd create invalid sparql if we replace `?toReplace` with an IRI or literal
        if (!isUsedInVariableExpression(parentValue, term.value)) {
          const replaceWith = replaceVar(term.value);
          if (replaceWith) {
            parentValue[key] = replaceWith;
            return false; // No need to go deeper
          }
        }
      }
    });
  }
  return parsedQuery;
}

/**
 * Go through parents, and rewrite a projection variable alias (e.g. `("a" as ?a)` ), then rewrite that
 * to just the variable (`?a`)
 */
function resetParentAlias(parents: Parents, variableName: string) {
  if (!parents) return;
  let immediateParentFound = false;
  // parents start from the root node. So iterate from the end to beginning
  for (const parent of parents.reverse()) {
    if (!parent.value.variables) continue; // parent is not a select clause, continue upwards
    if (!immediateParentFound) {
      // We only want to reset the select clause of the upper queries, not the sub-query that we're actually in (and that we just modified)
      immediateParentFound = true;
      continue;
    }
    const projectionVariables: ProjectionVariables = parent.value.variables;
    if (projectionVariables && Array.isArray(projectionVariables)) {
      for (let i = 0; i < projectionVariables.length; i++) {
        const projectVariable = projectionVariables[i];
        if ("expression" in projectVariable && projectVariable.variable.value === variableName) {
          projectionVariables[i] = projectVariable.variable;
        }
      }
    }
    return; // stop iterating, we've found the projection variables
  }
}

/**
 * Check a list of parents whether they reference a projection variable
 */
function hasParentProjectionVariable(parents: Parents, variableName: string) {
  if (!parents) return false;
  // parents start from the root node. So iterate from the end to beginning
  for (const parent of parents.reverse()) {
    if (!parent.value.variables) continue; // parent is not a select clause, continue upwards
    const projectionVariables: ProjectionVariables = parent.value.variables;
    return (
      Array.isArray(projectionVariables) &&
      projectionVariables.some((projectionVariable) => {
        if (
          "termType" in projectionVariable &&
          projectionVariable.termType === "Variable" &&
          projectionVariable.value === variableName
        ) {
          return true;
        }
        return "expression" in projectionVariable && projectionVariable.variable.value === variableName;
      })
    );
  }
}
