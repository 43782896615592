import { Alert } from "@mui/material";
import * as React from "react";

namespace FallbackRenderer {
  export interface State {
    errorMessage: string;
    currentFallback: number;
  }
  export interface Props {
    fallBackElements: (React.ReactElement<any> | number | string | null)[];
  }
}

class FallbackRenderer extends React.Component<FallbackRenderer.Props, FallbackRenderer.State> {
  state: FallbackRenderer.State = { errorMessage: "", currentFallback: 0 };

  componentDidCatch(error: Error, _errorInfo: any) {
    this.setState((state) => {
      return {
        errorMessage: error.message,
        currentFallback: state.currentFallback + 1,
      };
    });
  }
  render() {
    const filteredFallbacks = this.props.fallBackElements.filter((el) => !!el);
    if (this.state.currentFallback >= filteredFallbacks.length)
      return (
        <Alert variant="filled" severity="error">
          This component could not be rendered
        </Alert>
      );
    return filteredFallbacks[this.state.currentFallback];
  }
}

export default FallbackRenderer;
