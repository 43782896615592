import type { IChange } from "json-diff-ts";
import { diff } from "json-diff-ts";
import type { FormValues } from "./Types";

type Properties = {
  [property: string]: PropBody[];
};
interface PropBody {
  properties?: Properties;
  value?: number | string | boolean;
}

function getHtmlValue(value: any, applyPrefixes: (value: string) => string) {
  if (value?.nodeKind === "IRI") {
    if (value.value === "") {
      return undefined;
    }
    return `<a href="${value.value}">${value?.label || applyPrefixes(value.value)}</a>`;
  } else if (value?.nodeKind === "NestedNode") {
    if (value.value.indexOf("/.well-known/genid") > 0) {
      return `<a href="${value.value}">[...${value.value.substring(value.value.length - 3)}]</a>`;
    }
    return `<a href="${value.value}">${value?.label || applyPrefixes(value.value)}</a>`;
  } else if (typeof value === "object") {
    return value?.value;
  } else {
    return value;
  }
}

// These vales are removed every time a field is updated, or are properties that are filled in by the components at a later stage. (Basically everything that is not an IRI)
const PROPERTIES_TO_SKIP: string[] = [
  "predicate",
  "rawValue",
  "language",
  "key",
  "datatype",
  "iri",
  "description",
  "nestedNode",
];

function parseNestedProperties(properties: Properties, applyPrefixes: (value: string) => string): string[] {
  const changes: string[] = [];
  for (const [property, values] of Object.entries(properties)) {
    const propertyIri = redotLink(property);
    const propertyChanges: string[] = [];
    for (const value of values) {
      const htmlValue = getHtmlValue(value, applyPrefixes);
      if (htmlValue) {
        propertyChanges.push(`<li>${htmlValue}</li>`);
      }
    }
    if (propertyChanges.length > 0) {
      changes.push(`
        <li>
          Property <a href="${propertyIri}">${applyPrefixes(propertyIri)}</a> with values
          <ul>
            ${propertyChanges.join("\n")}
          </ul>
        </li>
        `);
    }
  }
  return changes;
}
function redotLink(link: string) {
  return link.replace(/ /g, ".");
}

function parseNewChanges(changes: IChange[], applyPrefixes: (value: string) => string) {
  let rapport = "";
  for (const change of changes) {
    if (change.key === "type") {
      if (change.type === "UPDATE") {
        const iriChange = change.changes?.find((change) => change.key === "id");
        const labelChange = change.changes?.find((change) => change.key === "label");
        if (!iriChange) {
          throw new Error("Updated type without IRI");
        }
        rapport += `<li>Updated type from <a href="${iriChange.oldValue}">${labelChange?.oldValue || applyPrefixes(iriChange.oldValue)}</a> to <a href="${iriChange.value}">${labelChange?.value || applyPrefixes(iriChange.value)}</a><li>`;
      }
      if (change.type === "ADD") {
        rapport += `<li>With type <a href="${change?.value?.id}">${change?.value?.label || applyPrefixes(change?.value?.id)}</a></li>`;
      }
    } else if (change.changes && change.embeddedKey === "value") {
      // Direct properties
      const propertyIri = redotLink(change.key);
      const newChanges = parseNewChanges(change.changes, applyPrefixes);
      if (newChanges.length > 0) {
        rapport += `<li>
          For property <a href="${propertyIri}">${applyPrefixes(propertyIri)}</a>
            <ul>
              ${newChanges}
            </ul>
          </li>`;
      }
    } else if (change.changes && change.key === "properties" && change.type === "UPDATE") {
      // Updates of nested properties (probably the properties key of a nested node)
      rapport += parseNewChanges(change.changes, applyPrefixes);
    } else if (change.key === "properties" && change.type === "ADD") {
      // When adding something we should emit the properties and (nested) values as well
      for (const [property, values] of Object.entries(change.value as Properties)) {
        if (values.length === 0) {
          continue;
        }
        const propertyIri = redotLink(property);
        const changes: string[] = [];
        for (const value of values) {
          if (value === null) continue;
          // Nested node property
          if (
            typeof change.value === "object" &&
            "nodeKind" in value &&
            value.nodeKind === "NestedNode" &&
            !!value.properties
          ) {
            const nestedProperties = parseNestedProperties(value.properties, applyPrefixes);
            if (nestedProperties.length > 0) {
              changes.push(`<li>
                Created ${getHtmlValue(value, applyPrefixes)} with
                <ul>
                  ${nestedProperties.join("\n")}
                </ul>
              </li>`);
            } else {
              // Nested node with no properties
              changes.push(`<li>Created ${getHtmlValue(value, applyPrefixes)}</li>`);
            }
          } else if (!!value) {
            // Normal property
            const htmlValue = getHtmlValue(value, applyPrefixes);
            if (htmlValue) {
              changes.push(`<li>${htmlValue}</li>`);
            }
          }
        }
        if (changes.length > 0) {
          rapport += `<li>
          Property <a href="${propertyIri}">${applyPrefixes(propertyIri)}</a> with values
            <ul>
              ${changes.join("\n")}
            </ul>
          </li>`;
        }
      }
    } else if (!change.changes) {
      // Leaf values
      switch (change.type) {
        case "ADD":
          // Add new instance
          if (change.key === "label") {
            // Label means the label changed of a namednode, this should be covered by a property or this might indicate an update of the referenced namedNode
            continue;
          }
          if (change.value === null) continue;
          if (
            typeof change.value === "object" &&
            "nodeKind" in change.value &&
            change.value.nodeKind === "NestedNode"
          ) {
            const changes = parseNestedProperties(change.value.properties, applyPrefixes);
            if (changes.length > 0) {
              rapport += `<li>
                Added ${getHtmlValue(change.value, applyPrefixes)} with
                <ul>
                  ${changes.join("\n")}
                </ul>
              </li>`;
            } else {
              rapport += `<li>Added ${getHtmlValue(change.value, applyPrefixes)}</li>`;
            }
            // Add new property to exising
          } else if (Array.isArray(change.value)) {
            if (change.value.length === 0) {
              // Optional properties with no values will give an empty list
              continue;
            }
            const propertyIri = redotLink(change.key);
            const changes: string[] = [];
            for (const value of change.value) {
              if (value === null) continue;
              if (
                typeof change.value === "object" &&
                "nodeKind" in value &&
                "properties" in value &&
                value.nodeKind === "NestedNode"
              ) {
                const nestedChanges = parseNestedProperties(value.properties, applyPrefixes);
                if (nestedChanges.length > 0) {
                  changes.push(`<li>
                    Added embedded object with values
                    <ul>
                      ${nestedChanges.join("\n")}
                    </ul>
                  </li>`);
                } else {
                  changes.push(`<li>Added embedded object</li>`);
                }
              } else {
                const val = getHtmlValue(value, applyPrefixes);
                if (val) {
                  changes.push(`<li>${val}</li>`);
                }
              }
            }
            if (changes.length) {
              rapport += `<li>
                Added property <a href="${propertyIri}">${applyPrefixes(propertyIri)}</a> with values
                  <ul>
                    ${changes.join("\n")}
                  </ul>
                </li>`;
            }
          } else {
            const val = getHtmlValue(change.value, applyPrefixes);
            if (val) {
              rapport += `<li>Added ${val}</li>`;
            }
          }
          break;
        case "REMOVE":
          if (Array.isArray(change.value)) {
            const propertyIri = redotLink(change.key);
            const changes: string[] = [];
            // Removed properties
            for (const removal of change.value) {
              if (removal === null) continue;
              if (
                typeof change.value === "object" &&
                "nodeKind" in removal &&
                "properties" in removal &&
                removal.nodeKind === "NestedNode"
              ) {
                const nestedChanges = parseNestedProperties(removal.properties, applyPrefixes);
                if (nestedChanges.length > 0) {
                  changes.push(`<li>
                  Removed ${getHtmlValue(removal, applyPrefixes)} with values
                  <ul>
                    ${nestedChanges.join("\n")}
                  </ul>
                </li>`);
                }
              } else {
                const val = getHtmlValue(removal, applyPrefixes);
                if (val) {
                  changes.push(`<li>${val}</li>`);
                }
              }
            }
            if (changes.length > 0) {
              rapport += `
              <li>
                Removed property <a href="${propertyIri}">${applyPrefixes(propertyIri)}</a> with values
                <ul>
                  ${changes.join("\n")}
                </ul>
              </li>`;
            }
          } else if (change.value) {
            if (change.value === null) continue;
            // Removed values
            if (
              typeof change.value === "object" &&
              "nodeKind" in change.value &&
              "properties" in change.value &&
              change.value.nodeKind === "NestedNode"
            ) {
              const nestedCahnges = parseNestedProperties(change.value.properties, applyPrefixes);
              if (nestedCahnges.length > 0) {
                rapport += `<li>
                  Removed ${getHtmlValue(change.value, applyPrefixes)} object with values
                  <ul>
                    ${nestedCahnges.join("\n")}
                  </ul>
                </li>
                  `;
              }
            } else {
              const val = getHtmlValue(change.value, applyPrefixes);
              if (val) {
                rapport += `<li>Removed ${getHtmlValue(change.value, applyPrefixes)}</li>`;
              }
            }
          }
          break;
        case "UPDATE":
          if (change.value.oldValue !== undefined && change.value.newValue !== undefined) {
            rapport += `<li>Updated ${change.value.oldValue} to ${change.value.newValue}</li>`;
          }
      }
    } else if (change.changes && change.type === "UPDATE") {
      // Updates from blanknodes
      const changes = parseNewChanges(change.changes, applyPrefixes);
      if (changes.length > 0) {
        rapport += `<li>
        Updated ${getHtmlValue({ value: change.key, nodeKind: "NestedNode" }, applyPrefixes)} with values
          <ul>
            ${changes}
          </ul>
        </li>`;
      }
    }
  }
  return rapport;
}

export function getChangeDiff(
  values: FormValues,
  applyPrefixes: (value: string) => string,
  initialValues?: FormValues,
  isCopy?: boolean,
): string {
  const embeddedObjKeys = values.properties
    ? {
        // Tells the difftool to use the values as keys in arrays, this makes sure that we don't get array placement updates, but also loses us the information if an array item has been edited.
        // Can't use '*' in the selector position unfortunately, so we need to parse the object and find all the property keys
        ...Object.keys(values.properties).reduce<{ [key: string]: string }>((prev, propertyKey) => {
          if (values.properties[propertyKey].length !== 0) {
            if (
              values.properties[propertyKey][0]?.nodeKind === "IRI" ||
              values.properties[propertyKey][0]?.nodeKind === "Literal"
            ) {
              prev[`.properties.${propertyKey}`] = "value";
            } else if (values.properties[propertyKey][0]?.nodeKind === "NestedNode") {
              prev[`.properties.${propertyKey}`] = "value";
              values.properties[propertyKey].forEach((option) => {
                option?.nodeKind === "NestedNode" &&
                  Object.keys(option.properties).forEach((subKey) => {
                    prev[`.properties.${propertyKey}.properties.${subKey}`] = "value";
                  });
              });
            }
          }
          return prev;
        }, {}),
      }
    : {};
  const initialDiff = diff(initialValues || {}, values, {
    keysToSkip: PROPERTIES_TO_SKIP,
    embeddedObjKeys: embeddedObjKeys,
  });
  const subjectIRI = redotLink((values as any).value || values.iri);
  const rapportHeading = `${initialValues === undefined ? "Created instance" : isCopy ? "Copied instance" : "Updated instance"} <a href="${subjectIRI}">${applyPrefixes(subjectIRI)}</a>`;
  const newChanges = parseNewChanges(initialDiff, applyPrefixes);
  return rapportHeading + "<ul>" + newChanges + "</ul>";
}
