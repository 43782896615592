import { jsonLanguage } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";
import { formatNumber } from "@core/utils/formatting";
import { TabPanel } from "@mui/lab";
import { DialogTitle } from "@mui/material";
import { githubLight } from "@uiw/codemirror-theme-github";
import CodeMirror from "@uiw/react-codemirror";
import getClassName from "classnames";
import * as React from "react";
import { useCookies } from "react-cookie";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { ElasticUtils } from "@triply/utils";
import {
  Alert,
  Dialog,
  ErrorPage,
  FlexContainer,
  FontAwesomeIcon,
  LinkButton,
  LoadingButton,
  ResourceWidgetLarge,
  SearchField,
} from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import useLocalStorage from "#helpers/hooks/useLocalStorage.tsx";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import type { Dataset } from "#reducers/datasetManagement.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { setLastSearchQuery } from "#reducers/datasets.ts";
import ServiceSelector from "./ServiceSelector.tsx";
import useCurrentService from "./useCurrentService.ts";
import * as styles from "./style.scss";

const DEFAULT_PAGE_SIZE = 10;

interface ElasticMeta {
  time: number;
  totalHits: number;
  hitCountRelation: "eq" | "gte";
}
type ElasticHits = unknown[];

interface ElasticSearchResponse {
  took: number;
  timed_out: boolean;
  _shards: {
    total: number;
    successful: number;
    skipped: number;
    failed: number;
  };
  hits: {
    total: {
      value: number;
      relation: "eq" | "gte";
    };
    hits: ElasticHits;
  };
}

interface ElasticSearchError {
  status: number;
  error: {
    type: string;
    reason: string;
    line?: number;
    col?: number;
    caused_by?: {
      type: string;
      reason: string;
      line: number;
      col: number;
    };
  };
}

export type ElasticQuery = {
  [value: string]: any;
};

const Result: React.FC<{
  currentDs: Dataset;
  result: any;
}> = ({ currentDs, result }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  return (
    <div className="white rounding shadow my-5">
      <ResourceWidgetLarge dataset={currentDs} resourceId={result._id} openJson={() => setDialogOpen(true)} />
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="xl" fullWidth closeButton>
        <DialogTitle>JSON Result</DialogTitle>
        <CodeMirror
          className={styles.codemirror}
          value={JSON.stringify(result, null, 2)}
          readOnly={true}
          extensions={[jsonLanguage, githubLight, EditorView.lineWrapping]}
        />
      </Dialog>
    </div>
  );
};

const Text: React.FC = () => {
  const dispatch = useDispatch();
  const currentDs = useCurrentDataset();
  const match = useRouteMatch<{ serviceName: string }>();
  const currentService = useCurrentService(match.params.serviceName);
  const location = useLocation();
  const history = useHistory();

  const [_cookies, _setCookie, removeCookie] = useCookies(["ecp"]);
  const [storedQuery, setStoredQuery] = useLocalStorage(`ElasticQE_${currentService?.id}_query`, "");

  const query = parseSearchString(location.search);
  const searchTerm = typeof query.q === "string" ? query.q : "";

  const [localSearchString, setLocalSearchString] = React.useState<string>(searchTerm);

  const [loading, setLoading] = React.useState(false);
  const [resultMeta, setResultMeta] = React.useState<ElasticMeta | undefined>(undefined);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [hits, setHits] = React.useState<ElasticHits | undefined>(undefined);
  const [loadNextPage, setLoadNextPage] = React.useState<(() => void) | undefined>(undefined);
  const url = useConstructUrlToApi()({
    fullUrl: currentService?.endpoint || "",
  });

  const runQuery = React.useCallback(
    (query: ElasticQuery, append = false) => {
      if (!query) return;
      setLoading(true);
      fetch(url, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(query, null, 2),
      })
        .then(async (res) => {
          if (res.status === 200) {
            setErrorMessage(undefined);
            const response: ElasticSearchResponse = await res.json();
            if (append) {
              setHits((currentHits) =>
                currentHits !== undefined ? [...currentHits, ...response.hits.hits] : response.hits.hits,
              );
            } else {
              setResultMeta({
                time: response.took,
                totalHits: response.hits.total.value,
                hitCountRelation: response.hits.total.relation,
              });
              setHits(
                response.hits.hits, // The best hits
              );
            }
            const skippedResults = "from" in query && query.from >= 0 ? query.from : 0;
            const hasNextPage = response.hits.total.value - skippedResults > response.hits.hits.length;
            if (hasNextPage) {
              query.from = ("from" in query ? query.from : 0) + ("size" in query ? query.size : DEFAULT_PAGE_SIZE);
              setLoadNextPage(() => () => runQuery(query, true));
            } else {
              setLoadNextPage(undefined);
            }
          } else {
            setResultMeta(undefined);
            setHits(undefined);
            setLoadNextPage(undefined);
            if ((res.headers.get("content-type")?.indexOf("application/json") ?? -1) >= 0) {
              const errorObject: ElasticSearchError | { message: string; serverError?: string } = await res.json();
              if ("message" in errorObject) {
                setErrorMessage(errorObject.message);
              } else if ("error" in errorObject) {
                setErrorMessage(errorObject.error.reason);
              } else {
                setErrorMessage("Something went wrong.");
              }
            } else {
              setErrorMessage(res.statusText || "Something went wrong.");
            }
          }
        })
        .catch((e) => console.error(e))
        .finally(() => {
          setLoading(false);
        });
    },
    [url],
  );

  React.useEffect(() => {
    removeCookie("ecp", { path: "/" });
  }, [removeCookie]);

  const currentDsId = currentDs?.id;
  React.useEffect(() => {
    if (currentDsId && searchTerm) dispatch(setLastSearchQuery(currentDsId, searchTerm));
  }, [currentDsId, searchTerm, dispatch]);

  React.useEffect(() => {
    runQuery(ElasticUtils.getElasticSimpleSearchQueryObject(searchTerm));
    setLocalSearchString(searchTerm);
  }, [runQuery, searchTerm]);

  if (!currentDs || !currentService) return <ErrorPage statusCode={404} />;
  if (!currentService.endpoint) {
    let message;
    switch (currentService.status) {
      case "stopped":
      case "stopping":
        message = "This service has been stopped, and is therefore not accessible";
        break;
      case "starting":
        message = "This service is starting, please try again later";
        break;
      case "removing":
        message = "This service is being removed, it can not be accessed anymore";
        break;
      case "running":
        message = "This service is running, but its endpoint is not available, please try again later";
        break;
      default:
        message = "This service is not able to connect to the endpoint yet, please try again later";
        break;
    }
    return <ErrorPage title="Elasticsearch endpoint not accessible" message={message} />;
  }

  return (
    <>
      <FlexContainer className={styles.searchContainer} innerClassName={styles.resultContainer}>
        <div className={styles.searchFieldWrapper}>
          <div className={styles.tabWrapper}>
            <TabPanel value="text" className={getClassName(styles.searchField, "my-7")}>
              <div className="flex mr-2">
                <ServiceSelector currentServiceName={match.params.serviceName} />
                <SearchField
                  ariaLabel="Inside the dataset"
                  search={(term: string) => {
                    history[searchTerm ? "push" : "replace"]({ search: stringifyQuery({ q: term.trim() }) });
                  }}
                  onChange={setLocalSearchString}
                  initialSearchTerm={searchTerm}
                  autoFocus
                  className={styles.textSearch}
                />
              </div>
              {!storedQuery && !!localSearchString && (
                <div className="mt-2 ml-2">
                  <LinkButton
                    onClickOrEnter={() => {
                      setStoredQuery(
                        JSON.stringify(
                          ElasticUtils.getElasticSimpleSearchQueryObject(localSearchString || ""),
                          null,
                          2,
                        ),
                      );
                      history.push({
                        pathname: `/${currentDs.owner.accountName}/${currentDs.name}/elasticsearch/${match.params.serviceName}/query`,
                      });
                    }}
                  >
                    Show query
                  </LinkButton>
                </div>
              )}
            </TabPanel>
          </div>
        </div>

        {loading && (
          <div className="flex horizontalCenter py-2">
            <FontAwesomeIcon icon="spinner" pulse />
          </div>
        )}
        {!loading && resultMeta && (
          <div className="flex horizontalCenter py-1">
            <span>
              {resultMeta.hitCountRelation === "eq" ? "Total of " : "At least "}
              {formatNumber(resultMeta.totalHits)} result{resultMeta.totalHits == 1 || "s"}{" "}
              <time dateTime={`PTS${Math.round(resultMeta.time / 1000)}`}>
                ({formatNumber(resultMeta.time / 1000, "0,0.000")} seconds)
              </time>
            </span>
          </div>
        )}
      </FlexContainer>

      <FlexContainer innerClassName={styles.resultContainer}>
        {errorMessage && <Alert className="shadow my-5" message={errorMessage} error />}
        {hits?.map((r: any) => <Result key={r._id} currentDs={currentDs} result={r} />)}
        {loadNextPage && (
          <div className="flex horizontalCenter">
            <LoadingButton disabled={loading} onClick={loadNextPage} loading={loading}>
              Show more
            </LoadingButton>
          </div>
        )}
      </FlexContainer>
    </>
  );
};

export default Text;
