import { Alert, Paper, Slider, Stack } from "@mui/material";
import * as d3 from "d3";
import { isArray, max } from "lodash-es";
import moment from "moment";
import * as React from "react";
import { CachePolicies } from "use-http";
import type { SpeedyStats } from "@triply/utils/Models";
import { ErrorPage, FontAwesomeButton, Meta } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useFetch from "../../../helpers/hooks/useFetch";
import * as styles from "./style.scss";

const AdminSpeedy: React.FC<IComponentProps> = ({ location }) => {
  const acl = useAcl();
  const ref = React.useRef<HTMLDivElement>(null);
  const [zoomLevel, setZoomLevel] = React.useState(8);
  const url = useConstructUrlToApi()({
    pathname: "/admin/speedyStats",
    query: { zoom: zoomLevel.toString() },
    fromBrowser: true,
  });

  const { data, error } = useFetch<SpeedyStats>(
    url,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    },
    [zoomLevel],
  );

  React.useEffect(() => {
    if (!ref.current || !data || !isArray(data)) return;

    drawChart({ node: ref.current, data: data });
  }, [data]);

  if (!acl.check({ action: "manageSpeedy" }).granted) {
    return <ErrorPage statusCode={404} />;
  }

  if (!data) return null;

  return (
    <div className="p-5">
      <Meta currentPath={location.pathname} title="Analytics - Admin settings" />
      {error && <Alert severity="error">{error.message}</Alert>}
      {!error && (
        <Paper className="p-5" sx={{ width: "min-content", margin: "auto" }}>
          <h3>Speedy stats</h3>
          <Alert severity="info" className="mt-5">
            The chart below shows information about the Speedy workers sampled at an interval of one minute.
          </Alert>
          <div className="flex horizontalEnd mt-5">
            <Stack spacing={2} direction="row" sx={{ width: 180 }} alignItems="center">
              <FontAwesomeButton
                icon="magnifying-glass-minus"
                onClick={() => setZoomLevel(zoomLevel - 1)}
                title="Zoom out"
                disabled={zoomLevel <= 1}
              />
              <Slider
                aria-label="Zoom level"
                value={zoomLevel}
                onChange={(_e, value) => setZoomLevel(value as number)}
                size="small"
                marks
                min={1}
                max={8}
              />
              <FontAwesomeButton
                icon="magnifying-glass-plus"
                onClick={() => setZoomLevel(zoomLevel + 1)}
                title="Zoom in"
                disabled={zoomLevel >= 8}
              />
            </Stack>
          </div>
          <div ref={ref} />
          <div className="flex center horizontalCenter g-5">
            <div className="flex center g-3">
              <div className={styles.idleWorkers} />
              <div>Idle workers</div>
            </div>
            <div className="flex center g-3">
              <div className={styles.activeTasks} />
              <div>Active tasks</div>
            </div>
            <div className="flex center g-3">
              <div className={styles.pendingTasks} />
              <div>Pending tasks</div>
            </div>
          </div>
          {data.some((d) => d.pendingTasks > 0 && d.idleWorkers > 0) && (
            <Alert className="mt-5" severity="info">
              Sometimes the chart shows that there are idle workers and pending tasks at the same time. This can have
              two reasons:
              <ul>
                <li>query requests came in on an API node that had no idle speedy workers, while another node had.</li>
                <li>different API nodes do not report Speedy statistics at exactly the same point in time.</li>
              </ul>
            </Alert>
          )}
        </Paper>
      )}
    </div>
  );
};

function drawChart({ node, data }: { node: HTMLDivElement; data: SpeedyStats }) {
  d3.select(node).html("");

  const showDate = data[0].timestamp < Date.now() - 1000 * 60 * 60 * 24;
  const maxY = max(data.map((d) => d.activeTasks + d.pendingTasks + d.idleWorkers || 0)) || 0;

  if (maxY === 0) {
    d3.select(node).html(`<div style="width: 1000px;" class="flex horizontalCenter my-7">No data</div>`);
    return;
  }

  const margin = { top: 10, right: 30, bottom: showDate ? 70 : 60, left: 40 },
    width = 1000 - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom;

  const svg = d3
    .select(node)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  const x = d3
    .scaleBand()
    .range([0, width])
    .domain(data.map((d) => d.timestamp.toString()))
    .padding(0.2);

  svg
    .append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(x).tickFormat((v) => moment(Number(v)).format(showDate ? "DD-MM HH:mm" : "HH:mm")))
    .selectAll("text")
    .attr("transform", "translate(-10,0)rotate(-45)")
    .style("text-anchor", "end");

  const y = d3.scaleLinear().domain([0, maxY]).range([height, 0]);

  svg.append("g").call(
    d3
      .axisLeft(y)
      .tickValues(y.ticks().filter((tick) => Number.isInteger(tick)))
      .tickFormat(d3.format("d")),
  );

  const bar = svg.selectAll().data(data).enter();

  bar
    .append("rect")
    .attr("x", (d) => x(d.timestamp.toString()) || 0)
    .attr("y", (d) => y(d.idleWorkers + d.activeTasks))
    .attr("width", x.bandwidth())
    .attr("height", (d) => height - y(d.idleWorkers))
    .attr("fill", "var(--color-gray-lighter)");

  bar
    .append("rect")
    .attr("x", (d) => x(d.timestamp.toString()) || 0)
    .attr("y", (d) => y(d.activeTasks))
    .attr("width", x.bandwidth())
    .attr("height", (d) => height - y(d.activeTasks))
    .attr("fill", "var(--color-warning)");

  bar
    .append("rect")
    .attr("x", (d) => x(d.timestamp.toString()) || 0)
    .attr("y", (d) => y(d.pendingTasks + d.activeTasks + d.idleWorkers))
    .attr("width", x.bandwidth())
    .attr("height", (d) => height - y(d.pendingTasks))
    .attr("fill", "var(--color-error)");
}

export default AdminSpeedy;
