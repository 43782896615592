import {
  applyRuleConfig,
  getDisplayNameValidations,
  getQueryNameValidations,
  queryDescriptionValidations,
  required,
  toStringValidator,
} from "@core/utils/validation";
import { Alert, MenuItem } from "@mui/material";
import getClassName from "classnames";
import { capitalize } from "lodash-es";
import * as React from "react";
import { useSelector } from "react-redux";
import * as ReduxForm from "redux-form";
import type { Models } from "@triply/utils";
import type { AccessLevel, Dataset, DatasetVerbose } from "@triply/utils/Models";
import AccessLevelIcon from "#components/AccessLevels/Icon.tsx";
import { substringMatch } from "#components/Highlight/index.tsx";
import type { MarkdownEditField, MuiAutosuggest } from "#components/index.ts";
import {
  AccessLevels,
  Avatar,
  Button,
  FormField,
  Highlight,
  LoadingButton,
  MarkdownEditFieldRedux,
  MuiAutosuggestRedux,
  NameAndUrl,
} from "#components/index.ts";
import useMemoizedFetch from "#helpers/hooks/useMemoizedFetch.ts";
import type { GlobalState } from "#reducers/index.ts";
import type { Account } from "../../../reducers/accountCollection";
import ServiceType from "./ServiceType";
import * as styles from "./style.scss";

const descriptionValidator = toStringValidator(queryDescriptionValidations);
const queryNameValidator = toStringValidator(getQueryNameValidations());
const displayNameValidator = toStringValidator([
  ...getDisplayNameValidations({ messageSubject: "A query name" }),
  applyRuleConfig(required, {
    formatMessage: () => `A query name is required.`,
  }),
]);

type FetchedDataset = Pick<
  DatasetVerbose,
  "id" | "name" | "displayName" | "accessLevel" | "avatarUrl" | "owner" | "services"
>;

function getDatasetLabel(dataset: FetchedDataset) {
  return dataset.owner.accountName + " / " + dataset.name;
}

function getSparqlServices(dataset: FetchedDataset) {
  const sparqlServices = dataset.services
    .filter((s) => {
      return "capabilities" in s && s.capabilities.includes("sparql");
    })
    .filter((s) => !!s && !!s.endpoint) as Models.ServiceMetadata[];

  return sparqlServices;
}

const QueryMetaComponent: React.FC<
  QueryMetaForm.Props & ReduxForm.InjectedFormProps<QueryMetaForm.FormData, QueryMetaForm.Props>
> = ({
  handleSubmit,
  isNewQuery,
  error,
  submitting,
  className,
  style,
  invalid,
  cancelFunction,
  change,
  initialValues,
  pristine,
  hasUnsavedChanges,
  form,
  datasetSearchUrl,
  owner,
}) => {
  const [servicesMetaOfSelectedDataset, setServicesMetaOfSelectedDataset] = React.useState<
    Models.ServiceMetadataSmall[]
  >([]);

  const fetch = useMemoizedFetch<FetchedDataset[]>();
  const datasetValue = useSelector((state: GlobalState) => ReduxForm.formValueSelector(form)(state, "dataset"));
  const serviceTypeValue = useSelector((state: GlobalState) => ReduxForm.formValueSelector(form)(state, "serviceType"));

  function handleDatasetChange(dataset: FetchedDataset) {
    change?.("dataset", dataset.id);
    const sparqlServices = getSparqlServices(dataset);
    setServicesMetaOfSelectedDataset(sparqlServices);
    const sortedRunningServicesMeta = sparqlServices
      .sort(
        (a, b) =>
          ((a.loadedAt && new Date(a.loadedAt).getTime()) || 0) - ((b.loadedAt && new Date(b.loadedAt).getTime()) || 0),
      )
      .reverse()
      .filter((service) => service.status === "running");

    const mostRecentRunningServiceType = sortedRunningServicesMeta[0] ? sortedRunningServicesMeta[0].type : "speedy";
    change?.("serviceType", mostRecentRunningServiceType);
  }

  function searchDataset(queryString: string) {
    const url = `${datasetSearchUrl}/?limit=50&fields=id,name,displayName,accessLevel,avatarUrl,owner,services&verbose&q=${encodeURIComponent(
      queryString,
    )}`;
    return fetch(url).then((results) => {
      if (results && Array.isArray(results)) {
        return results;
      }
      return [];
    });
  }

  React.useEffect(() => {
    //Checking for initial values and fetching necessary data. Executing only once on render.
    if (initialValues && initialValues.dataset) {
      const queryString = `${initialValues.dataset.owner.name || ""} ${initialValues.dataset.owner.accountName} ${
        initialValues.dataset.displayName || ""
      } ${initialValues.dataset.name}`;
      searchDataset(queryString)
        .then((results) => {
          const selectedDataset = results.find((d) => d.id === initialValues.dataset?.id);
          if (selectedDataset) {
            const sparqlServices = getSparqlServices(selectedDataset);
            setServicesMetaOfSelectedDataset(sparqlServices);
          }
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit} className={getClassName("px-5 pb-5", className)} style={style}>
      <NameAndUrl
        changeFormValues={change || (() => {})}
        initialSlug={initialValues?.name}
        formIsPristine={pristine || false}
        autoFocus
        nameFieldName="displayName"
        nameFieldLabel="Name"
        slugFieldName="name"
        slugFieldLabel="URL"
        urlPrefixPath={`/${owner?.accountName}/-/queries/`}
        disableSlugField={(hasUnsavedChanges && "Save your changes before changing the URL of this query") || undefined}
        className="mb-6"
      />

      <FormField label="Dataset" className="mb-6" inputId={`${form}-dataset-field`}>
        <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiAutosuggest.Props<FetchedDataset, DatasetVerbose>>>
          name="dataset"
          props={{
            showErrors: !isNewQuery,
            noResultsMessage: "No matching dataset with SPARQL endpoints.",
            loadSuggestions: searchDataset,
            clearOnSelect: false,
            TextFieldProps: {
              fullWidth: true,
              inputProps: { id: `${form}-dataset-field` },
            },
            transformInitialValueToSearchText: getDatasetLabel,
            onSuggestionSelected: (_e, data) => handleDatasetChange(data.suggestion),
            getSuggestionSearchText: getDatasetLabel,
            renderSuggestion: (ds, { query, isHighlighted }) => {
              return (
                <MenuItem selected={isHighlighted} component="div">
                  <Avatar
                    size="sm"
                    className="mr-3"
                    avatarUrl={ds.avatarUrl}
                    avatarName={ds.displayName || ds.name}
                    alt=""
                  />
                  <Highlight fullText={getDatasetLabel(ds)} highlightedText={query} matcher={substringMatch} />
                  <AccessLevelIcon level={ds.accessLevel} size="xs" type="dataset" className="ml-3" />
                </MenuItem>
              );
            },
          }}
          component={MuiAutosuggestRedux}
        />
      </FormField>

      {!!datasetValue && (
        <FormField label="Service type" className="mb-6">
          <div className={getClassName("flex wrap g-5", styles.serviceTypeField)}>
            <ReduxForm.Field
              name="serviceType"
              component={ServiceType}
              servicesMetaOfSelectedDataset={servicesMetaOfSelectedDataset}
            />
            <div>
              {serviceTypeValue !== "speedy" &&
                servicesMetaOfSelectedDataset &&
                servicesMetaOfSelectedDataset.filter((service) => service.type === serviceTypeValue).length === 0 && (
                  <Alert severity="warning" className="transparent">
                    There are currently no {capitalize(serviceTypeValue)} services available for this dataset.
                  </Alert>
                )}
            </div>
          </div>
        </FormField>
      )}

      <FormField label="Description (optional)" className="mt-7 mb-6" inputId={`${form}-description-field`}>
        <ReduxForm.Field<ReduxForm.BaseFieldProps<MarkdownEditField.Props>>
          name="description"
          props={{
            type: "text",
            fullWidth: true,
            multiline: true,
            rows: 5,
            inputProps: { id: `${form}-description-field` },
          }}
          component={MarkdownEditFieldRedux}
        />
      </FormField>

      <FormField label="Access level" className="mb-6">
        <AccessLevels
          name="accessLevel"
          type="query"
          accountType={owner?.type}
          changeAccessLevel={(value) => change?.("accessLevel", value)}
        />
      </FormField>

      {error && (
        <Alert variant="outlined" severity="error" className="mt-5">
          {error}
        </Alert>
      )}

      <div className={getClassName("form-group mt-6")}>
        <LoadingButton
          type="submit"
          color="secondary"
          disabled={invalid || pristine}
          onClick={handleSubmit}
          loading={submitting}
        >
          {!isNewQuery ? "Update" : "Create"} query
        </LoadingButton>

        {cancelFunction && (
          <Button disabled={submitting} onClick={cancelFunction} variant="text" className="mx-3">
            Cancel
          </Button>
        )}
      </div>
    </form>
  );
};

namespace QueryMetaForm {
  export interface FormData {
    name: string;
    displayName?: string;
    description: string;
    accessLevel: AccessLevel;
    dataset: Dataset;
    serviceType: Models.QueryServiceType;
  }

  export interface Props {
    className?: string;
    style?: React.CSSProperties;
    cancelFunction?: React.EventHandler<React.MouseEvent<any>>;
    isNewQuery?: boolean;
    datasetSearchUrl: string;
    hasUnsavedChanges?: boolean;
    owner: Account;
  }
}

const QueryMetaForm = ReduxForm.reduxForm<QueryMetaForm.FormData, QueryMetaForm.Props>({
  form: "queryMeta",
  validate: (formData, props) => {
    const datasetAllowedEmpty = !props.isNewQuery && !props.initialValues?.dataset;
    return {
      name: queryNameValidator(formData.name),
      displayName: displayNameValidator(formData.displayName),
      dataset: !formData.dataset && !datasetAllowedEmpty ? "A dataset is required." : undefined,
      description: descriptionValidator(formData.description),
    };
  },
  warn: (formData, props) => {
    return {
      name:
        (!props.isNewQuery &&
          formData.name &&
          props.initialValues?.name &&
          formData.name.toLowerCase() !== props.initialValues.name.toLowerCase() &&
          "Changing the query URL will break existing links to this query") ||
        undefined,
    };
  },
})(QueryMetaComponent);

export default QueryMetaForm;
