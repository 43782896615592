import { NTriplyStatementToQuad } from "@core/utils/nTriply";
import * as React from "react";
import type { NtriplyStatements } from "@triply/utils/Models";
import { termToString } from "@triplydb/sparql-ast/serialize";
import { Button, ConfirmationDialog, FontAwesomeIcon } from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { refreshDatasetsInfo, useCurrentDataset } from "#reducers/datasetManagement.ts";
import { getGraphs } from "#reducers/graphs.ts";
import { printQuadStatements } from "./utils";

const RemoveStatements: React.FC<{
  quadsToRemove: NtriplyStatements;
}> = ({ quadsToRemove }) => {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const currentDs = useCurrentDataset()!;

  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const dispatch = useDispatch();
  const _quadsToRemove = quadsToRemove.map((tquad) => NTriplyStatementToQuad(tquad));
  const valuesToRemove = printQuadStatements(_quadsToRemove);

  return (
    <>
      <Button
        color="error"
        elevation
        onClick={() => setOpen(true)}
        title={`Delete ${quadsToRemove.length} statement(s)`}
        startIcon={<FontAwesomeIcon icon="trash" />}
        size="small"
        className="m-2 mt-5 "
      >
        {`Delete ${quadsToRemove.length} statement(s)`}
      </Button>
      {open && (
        <ConfirmationDialog
          open={open}
          loading={saving}
          onClose={() => setOpen(false)}
          actionLabel={`Delete ${quadsToRemove.length} statement(s)`}
          title={`Delete ${quadsToRemove.length} statement(s)?`}
          onConfirm={async () => {
            setSaving(true);

            const query = `
                        delete {
                        ${valuesToRemove}
                        } where {}`;

            const body = new FormData();
            body.set("update", query);

            await fetch(updateUrl, {
              credentials: "same-origin",
              method: "POST",
              body: body,
            });

            await dispatch<typeof refreshDatasetsInfo>(
              refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
            );
            await dispatch<typeof getGraphs>(
              getGraphs({
                accountName: currentDs.owner.accountName,
                datasetName: currentDs.name,
                datasetId: currentDs.id,
              }),
            );

            setSaving(false);
            setOpen(false);
          }}
          description={`Are you sure you want to delete ${quadsToRemove.length} statement(s) from the dataset?`}
        />
      )}
    </>
  );
};

export default RemoveStatements;
