import { div, mod } from "./helpers.ts";

export interface Month extends MonthUtc, Timezone {}

interface MonthUtc {
  /**
   * An integer between 1 and 12 inclusive.
   */
  month: number;
}

export interface MonthDay extends MonthDayUtc, Timezone {}

interface MonthDayUtc extends DayUtc, MonthUtc {}

export interface Time extends TimeUtc, Timezone {}

export interface TimeUtc extends HourMinuteUtc {
  /**
   * An integer greater than or equal to 0 and less than 60.
   */
  second: number;

  /**
   * The part after the decimal point of `second`
   */
  fraction: string;
}

interface Timezone {
  /**
   * An optional integer between -840 and 840 inclusive.
   *
   * This allows the representation of timezone offsets of up to 14 hours.
   */
  timezone?: number;
}

export interface Year extends Timezone, YearUtc {}

interface YearUtc {
  /**
   * An integer.
   *
   * - Values 1582 and higher denote years in the Gregorian calendar.
   * - Values less than 1582 represent years in the proleptic Gregorian calendar.
   * - The value 0 represents the year 1 BCE.
   * - The value -1 represents the year 2 BCE, -2 is 3 BCE, etc.
   */
  year: number;
}

export interface YearMonth extends Timezone, YearMonthUtc {}

interface YearMonthUtc extends MonthUtc, YearUtc {}

export interface XsdDate extends DateUtc, Timezone {}

interface DateUtc extends YearMonthUtc {
  /**
   * An integer between 1 and 31 inclusive, possibly restricted further
   * depending on the values of month and year.
   */
  day: number;
}

interface DateHourMinuteUtc extends DateUtc, HourMinuteUtc {}

export interface DateTime extends DateTimeUtc, Timezone {}

interface DateTimeUtc extends DateUtc, TimeUtc {}

export interface Day extends DayUtc, Timezone {}

interface DayUtc {
  /**
   * An integer between 1 and 31 inclusive, possibly restricted further
   * depending on the values of month and year.
   */
  day: number;
}

interface HourMinuteUtc {
  /**
   * An integer between 0 and 23 inclusive.
   */
  hour: number;
  /**
   * An integer between 0 and 59 inclusive.
   */
  minute: number;
}

export interface MaybeDateTime {
  year?: number;
  month?: number;
  day?: number;
  hour?: number;
  minute?: number;
  second?: number;
  fraction?: string;
  timezone?: number;
}

// Defaulting to a leap year, to accomodate `02-29` month-day notation, which is allowed.
// See https://www.w3.org/TR/xmlschema11-2/#gMonthDay
const defaultYear = 2020;
const defaultMonth = 1;
const defaultDay = 1;
/**
 * Returns an instance of `DateTime` with property values as specified in the
 * arguments.
 *
 * @param year     An optional integer.
 * @param month    An optional integer between 1 and 12 inclusive.
 * @param day      An optional integer between 1 and 31 inclusive.
 * @param hour     An optional integer between 0 and 24 inclusive.
 * @param minute   An optional integer between 0 and 59 inclusive.
 * @param second   An optional decimal number greater than or equal to 0 and
 *                 less than 60.
 * @param timezone An optional integer between -840 and 840 inclusive.
 * @return An instance of the date/time SevenPropertyModel with property values
 *         as specified in the arguments.  If an argument is omitted, the
 *         corresponding property is set to absent.
 *
 * - Let: `date0` be an instance of the date/timeSevenPropertyModel.
 * - Let: `year0` be `year` when `year` is not absent, otherwise 1.
 * - Let: `month0` be `month` when `month` is not absent, otherwise 1.
 * - Let: `day0` be `day` when `day` is not absent, otherwise 1.
 * - Let: `hour0` be `hour` when `hour` is not absent, otherwise 0.
 * - Let: `minute0` be `minute` when `minute` is not absent, otherwise 0.
 * - Let: `second0` be `second` when `second` is not absent, otherwise 0.
 * - `normalizeSecond(year0, month0, day0, hour0, minute0, second0)`
 * - Set the `year` property of `date` to absent when `year` is absent, otherwise `year0`.
 * - Set the `month` property of `date` to absent when `month` is absent, otherwise `month0`.
 * - Set the `day` property of `date` to absent when `day` is absent, otherwise `day0`.
 * - Set the `hour` property of `date` to absent when `hour` is absent, otherwise `hour0`.
 * - Set the `minute` property of `date` to absent when `minute` is absent, otherwise `minute0`.
 * - Set the `second` property of `date` to absent when `second` is absent, otherwise `second0`.
 * - Set the `timezoneOffset` property of `date` to `timezone`
 * - Return `date`.
 */
export function newDateTime(args: {
  year?: number | null;
  month?: number | null;
  day?: number | null;
  hour?: number | null;
  minute?: number | null;
  second?: number | null;
  fraction?: string | null;
  timezone?: number | null;
}): MaybeDateTime {
  constraintDayOfMonth(args.year ?? defaultYear, args.month ?? defaultMonth, args.day ?? defaultDay);
  const tmp = normalizeSecond(
    args.year ?? defaultYear,
    args.month ?? defaultMonth,
    args.day ?? defaultDay,
    args.hour ?? 0,
    args.minute ?? 0,
    args.second ?? 0,
    args.fraction ?? "",
  );
  return {
    year: args.year === null || args.year === undefined ? undefined : tmp.year,
    month: args.month === null || args.month === undefined ? undefined : tmp.month,
    day: args.day === null || args.day === undefined ? undefined : tmp.day,
    hour: args.hour === null || args.hour === undefined ? undefined : tmp.hour,
    minute: args.minute === null || args.minute === undefined ? undefined : tmp.minute,
    second: args.second === null || args.second === undefined ? undefined : tmp.second,
    fraction: args.fraction === null || args.fraction === undefined ? undefined : tmp.fraction,
    timezone: args.timezone ?? undefined,
  };
}

/**
 * Constraint: Day-of-month Values
 * -------------------------------
 *
 * The `day` value must be no more than 30 if `month` is one of 4, 6, 9, or 11;
 * no more than 28 if `month` is 2 and `year` is not divisible by 4, or is
 * divisible by 100 but not by 400;
 * and no more than 29 if `month` is 2 and `year` is divisible by 400, or by 4
 * but not by 100.
 *
 * @param year
 * @param month
 * @param day
 */
function constraintDayOfMonth(year: number, month: number, day: number): void {
  if (day > 30 && [4, 6, 9, 11].includes(month)) {
    throw RangeError(`Day ${day} is not valid in month ${month}.`);
  }
  const divisible = (y: number) => mod(year, y) === 0;
  if (
    (day > 28 && month === 2 && (!divisible(4) || (divisible(100) && !divisible(400)))) ||
    (day > 29 && month === 2 && (divisible(400) || (divisible(4) && !divisible(100))))
  ) {
    throw RangeError(`Day ${day} is not valid in year/month ${year}-${month}.`);
  }
  return;
}

/**
 * Returns the number of the last day of the month for any combination of year
 * and month.
 *
 * @param year  An optional integer.
 * @param month An integer between 1 and 12.
 * @return      An integer between 28 and 31 inclusive.
 *
 * Return:
 * - 28 when `month` is 2 and `year` is not evenly divisible by 4, or is
 *   evenly divisible by 100 but not by 400, or is absent,
 * - 29 when `month` is 2 and `year` is evenly divisible by 400, or is evenly
 *   divisible by 4 but not by 100,
 * - 30 when `month` is 4, 6, 9, or 11,
 * - 31 otherwise (`month` is 1, 3, 5, 7, 8, 10, or 12).
 */
function daysInMonth(year: number, month: number): number {
  const divisible = (y: number) => mod(year, y) === 0;
  if (month === 2 && (!divisible(4) || (divisible(100) && !divisible(400)) || !year)) {
    return 28;
  } else if (month === 2 && (divisible(400) || (divisible(4) && !divisible(100)))) {
    return 29;
  } else if ([4, 6, 9, 11].includes(month)) {
    return 30;
  } else {
    return 31;
  }
}

/**
 * If month is out of range, or day is out of range for the appropriate month,
 * then adjust values accordingly, otherwise make no change.
 *
 * @param year  An integer.
 * @param month An integer.
 * @param day   An integer.
 *
 * - `normalizeMonth(year, month)`
 * - Repeat until `day` is positive and not greater than
 *   `daysInMonth(year, month)`:
 *   - If `day` exceeds `daysInMonth(year, month)` then:
 *     - Subtract that limit from `day`.
 *     - Add 1 to `month`.
 *     - `normalizeMonth(year, month)`
 *   - If `day` is not positive then:
 *     - Subtract 1 from `month`.
 *     - `normalizeMonth(year, month)`
 *     - Add the new upper limit from the table to `day`.
 */
function normalizeDay(year: number, month: number, day: number): DateUtc {
  let [_year, _month] = normalizeMonth(year, month); // Oops! non-const
  while (day < 0 || day > daysInMonth(_year, _month)) {
    const _daysInMonth = daysInMonth(_year, _month);
    if (day > _daysInMonth) {
      day -= _daysInMonth;
      const tmp = normalizeMonth(_year, ++_month);
      _year = tmp[0]; // OOPS! Canot reassign to [_year, _month].
      _month = tmp[1]; // OOPS! Canot reassign to [_year, _month].
    }
    if (day <= 0) {
      day += _daysInMonth;
      const tmp = normalizeMonth(_year, --_month);
      _year = tmp[0]; // OOPS! Canot reassign to [_year, _month].
      _month = tmp[1]; // OOPS! Canot reassign to [_year, _month].
    }
  }
  return {
    year: _year,
    month: _month,
    day: day,
  };
}

/**
 * Normalizes minute, hour, month, and year values to values that obey the
 * appropriate constraints.
 *
 * @param year   An integer.
 * @param month  An integer.
 * @param day    An integer.
 * @param hour   An integer.
 * @param minute An integer.
 *
 * - Add `minute div 60` to `hour`.
 * - Set `minute` to `minute mod 60`.
 * - Add `hour div 24` to `day`.
 * - Set `hour` to `hour mod 24`.
 * - Return `normalizeDay(year, month, day)`.
 */
function normalizeMinute(year: number, month: number, day: number, hour: number, minute: number): DateHourMinuteUtc {
  const _hour = hour + div(minute, 60);
  return {
    ...normalizeDay(year, month, day + div(_hour, 24)),
    hour: mod(_hour, 24),
    minute: mod(minute, 60),
  };
}

/**
 * If month is out of range, adjust month and year accordingly; otherwise,
 * make no change.
 *
 * @param year  An integer.
 * @param month An integer.
 *
 * - Add `(month - 1) div 12` to `year`.
 * - Set `month` to `(month - 1) mod 12 + 1`.
 */
function normalizeMonth(year: number, month: number): [number, number] {
  return [year + div(month - 1, 12), mod(month - 1, 12) + 1];
}

/**
 * Normalizes second, minute, hour, month, and year values to values that obey
 * the appropriate constraints.
 *
 * This algorithm ignores leap seconds.
 *
 * @param year   An integer.
 * @param month  An integer.
 * @param day    An integer.
 * @param hour   An integer.
 * @param minute An integer.
 * @param second A decimal number.
 *
 * - Add `second div 60` to `minute`.
 * - Set `second` to `second mod 60`.
 * - Return `normalizeMinute(year, month, day, hour, minute)`.
 */
function normalizeSecond(
  year: number,
  month: number,
  day: number,
  hour: number,
  minute: number,
  second: number,
  fraction: string,
): DateTimeUtc {
  return {
    ...normalizeMinute(year, month, day, hour, minute + div(second, 60)),
    second: mod(second, 60),
    fraction,
  };
}

/**
 * Maps a nonnegative integer less than 100 onto an unsigned always-two-digit
 * numeral.
 *
 * @param i A nonnegative integer less than 100.
 * @return  A string that matches grammar rule `unsignedNoDecimalPtNumeral`.
 *
 * Algorithm:
 * - Return `digit(i div 10)` &
 *          `digit(i mod 10)`.
 */
export function unsignedTwoDigitFragmentToCanonical(i: number): string {
  return digit(div(i, 10)) + digit(mod(i, 10));
}
/**
 * Maps each integer between 0 and 9 to the corresponding digit.
 *
 * @param i An integer between 0 and 9 inclusive.
 * @return  A string that matches the grammar rule `digit`.
 *
 * Algorithm:
 * - Return '0' when `i = 0`,
 * - Return '1' when `i = 1`,
 * - Return '2' when `i = 2`,
 * - etc.
 */
function digit(i: number): string {
  switch (i) {
    case 0:
      return "0";
    case 1:
      return "1";
    case 2:
      return "2";
    case 3:
      return "3";
    case 4:
      return "4";
    case 5:
      return "5";
    case 6:
      return "6";
    case 7:
      return "7";
    case 8:
      return "8";
    case 9:
      return "9";
    default:
      throw new Error(`Unanticipated value: i=${i}.`);
  }
}
