import { fromPairs } from "lodash-es";
import { Converter } from "sparqljson-to-tree";
import { valuesQuery } from "./Queries";
import type { ResourceData } from "./Types";
import useSparql from "./useSparql";

const converter = new Converter({ materializeRdfJsTerms: true });

const stringClasses = [
  "http://www.w3.org/2000/01/rdf-schema#Literal",
  "http://www.w3.org/2001/XMLSchema#integer",
  "http://www.w3.org/2001/XMLSchema#string",
  "http://www.w3.org/2001/XMLSchema#anyURI",
  "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
];

const useFetchInitialValues = () => {
  const sparql = useSparql();

  const fetchInitialValues = async (resource: string) => {
    const results = await sparql(valuesQuery(resource));
    const json = converter.sparqlJsonResultsToTree(results, {
      singularizeVariables: {
        "": true,
        type: true,
        properties_property: true,
        ...fromPairs(results.head!.vars.map((v) => [v, true])),
      },
    });
    // console.log(json);
    return {
      ...json,
      properties:
        json.properties?.map((p: any) => ({
          property: p.property,
          value: p.property.datatype
            ? { id: p.value.toString(), datatype: p.property.datatype }
            : p.property.values?.find((v: any) => v.id === p.value) || null,
        })) || [],
    } as ResourceData;
  };

  return fetchInitialValues;
};

export default useFetchInitialValues;
