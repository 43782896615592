import * as React from "react";
import { asyncConnect } from "redux-connect";
import { ErrorPage } from "#components/index.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import { getCurrentDataset } from "#reducers/datasetManagement.ts";
import type { GlobalState } from "#reducers/index.ts";
import { getTriples } from "#reducers/triples.ts";
import { getConstructConsoleUrl } from "#staticConfig.ts";

@asyncConnect<GlobalState>([
  {
    promise: async ({ location, match, store: { dispatch, getState }, helpers: { redirect } }) => {
      const state: GlobalState = getState();
      const iri =
        state.config.staticConfig &&
        getConstructConsoleUrl({ consoleUrlInfo: state.config.staticConfig.consoleUrl })({
          pathname: location.pathname,
        }) + (location.search ? `?${location.search}` : "");
      const currentDs = getCurrentDataset(state);
      if (iri && currentDs && state.datasetManagement.current) {
        // first check if the iri is a graph. If so, go to the table view and filter by the graph
        const graphResult = await dispatch<any>(getTriples(currentDs, { graph: iri }));
        if (graphResult.body?.length > 0) {
          return redirect(`${match.url}/table?${stringifyQuery({ graph: iri })}`);
        }
        // otherwise, check if the iri appears as a subject in the data. If so, go to its' browser page.
        const subjectResult = await dispatch<any>(getTriples(currentDs, { subject: iri }));
        if (subjectResult.body?.length > 0) {
          return redirect(`${match.url}/browser?${stringifyQuery({ resource: iri })}`);
        }
      }
    },
  },
])
export default class DereferenceOrNotFound extends React.PureComponent<{}, {}> {
  render() {
    return <ErrorPage statusCode={404} />;
  }
}
