import * as React from "react";
import { useSelector } from "react-redux";
import { DatasetListItem, FlexContainer, SinkList } from "#components/index.ts";
import { getDatasetList } from "#reducers/datasetManagement.ts";
import type { GlobalState } from "#reducers/index.ts";

const ImportJobs: React.FC<{}> = ({}) => {
  const fetchingListError = useSelector((state: GlobalState) => state.datasetManagement.fetchingListError);
  const datasets = useSelector((state: GlobalState) =>
    getDatasetList(state, { admin: "jobs", account: false, searchTerm: false }),
  );

  return (
    <FlexContainer>
      <SinkList
        fetchingListError={fetchingListError}
        noContentMsg="There are currently no import jobs unpacking and/or indexing"
      >
        {datasets.map((ds) => (
          <DatasetListItem key={ds.id} ds={ds} />
        ))}
      </SinkList>
    </FlexContainer>
  );
};

export default ImportJobs;
