import type { EditorState } from "@codemirror/state";
import { StateField } from "@codemirror/state";
import Debug from "debug";
import { parsers } from "@triplydb/sparql-ast";
import { updateOnDocAndParserChanges } from "./utils";

const debug = Debug("triply:sparql-editor:fields:validity");

interface ValidConfig {
  valid: boolean;
}

export const validField = StateField.define<ValidConfig>({
  create: isQueryValid,
  update: (value, transaction) => {
    if (updateOnDocAndParserChanges(transaction)) {
      return isQueryValid(transaction.state);
    }
    return value;
  },
});

function isQueryValid(state: EditorState) {
  debug("Running");
  let valid = true;
  try {
    parsers.compliant(state.doc.toString(), {
      assertValidAnnotations: true,
      baseIri: "https://triplydb.com/",
    });
  } catch (e) {
    valid = false;
  } finally {
    debug("Finished");
    return { valid: valid };
  }
}
