import { Slider, Typography } from "@mui/material";
import { max } from "lodash-es";
import * as React from "react";
import { useHistory } from "react-router";
import type { FacetItem } from "@triply/utils/Models";
import { stringifyQuery } from "#helpers/utils.ts";
import * as styles from "./style.scss";

interface Props {
  query: any;
  facets: FacetItem[];
}

const marks = [
  {
    value: 0,
    label: "0",
    hint: "0",
    id: "0",
  },
  {
    value: 1,
    hint: "10",
    id: "10",
  },
  {
    value: 2,
    hint: "100",
    id: "100",
  },
  {
    value: 3,
    hint: "1K",
    id: "1000",
  },
  {
    value: 4,
    hint: "10K",
    id: "10000",
  },
  {
    value: 5,
    hint: "100K",
    id: "100000",
  },
  {
    value: 6,
    hint: "1M",
    id: "1000000",
  },
  {
    value: 7,
    hint: "10M",
    id: "10000000",
  },
  {
    value: 8,
    label: "100M+",
    hint: "100M+",
    id: "100000000",
  },
];

const StatementsSlider: React.FC<Props> = ({ query, facets }) => {
  const history = useHistory();
  const [value, setValue] = React.useState([
    "statementsMin" in query ? Math.log10(Number(query.statementsMin)) : 0,
    "statementsMax" in query ? Math.log10(Number(query.statementsMax)) : 8,
  ]);

  React.useEffect(() => {
    setValue([
      "statementsMin" in query ? Math.log10(Number(query.statementsMin)) : 0,
      "statementsMax" in query ? Math.log10(Number(query.statementsMax)) : 8,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.statementsMin, query.statementsMax]);

  const maxCount = max(facets.map((f) => f.count)) || 1;
  const bars = marks.map((m) => {
    const facet = facets.find((f) => f.id === m.id);
    return {
      height: facet ? facet.count / maxCount : 0,
      title:
        facet && `${m.hint}${m.value < 7 ? ` - ${marks[m.value + 1].hint}` : "+"} statements: ${facet.count} results`,
      disabled: m.value < value[0] || m.value >= value[1],
    };
  });

  return (
    <div>
      <Typography id="statements-slider" gutterBottom className={styles.title}>
        Statements
      </Typography>
      <div className={styles.statements}>
        <div className={styles.bars}>
          {bars.map((b, i) => {
            return (
              <div
                key={i}
                title={b.title}
                style={{ height: `${100 * b.height}%` }}
                className={b.disabled ? styles.disabled : undefined}
                role="button"
                tabIndex={0}
                onClick={() => {
                  history.push({
                    search: stringifyQuery({
                      ...query,
                      statementsMin: i > 0 ? Math.pow(10, i).toString() : undefined,
                      statementsMax: i + 1 < 8 ? Math.pow(10, i + 1).toString() : undefined,
                    }),
                  });
                }}
              />
            );
          })}
        </div>
        <Slider
          size="small"
          value={value}
          getAriaValueText={(v) => "" + v}
          aria-labelledby="statements-slider"
          marks={marks}
          min={0}
          max={8}
          scale={(x) => Math.pow(10, x)}
          step={null}
          valueLabelDisplay="auto"
          onChange={(_, values) => {
            if (typeof values === "number") return;
            setValue(values);
          }}
          onChangeCommitted={(_, values) => {
            if (typeof values === "number") return;
            const [min, max] = values;
            history.push({
              search: stringifyQuery({
                ...query,
                statementsMin: min > 0 ? Math.pow(10, min).toString() : undefined,
                statementsMax: max < 8 ? (max === 0 ? "0" : Math.pow(10, max).toString()) : undefined,
              }),
            });
          }}
          valueLabelFormat={(value) => {
            value = Math.log10(value);
            return marks.find((m) => m.value === value)?.hint;
          }}
          className={styles.slider}
        />
      </div>
    </div>
  );
};

export default StatementsSlider;
