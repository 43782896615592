import getClassName from "classnames";
import * as React from "react";
import { matchRoutes, renderRoutes } from "react-router-config";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import * as styles from "./style.scss";

export interface TabLink {
  pathname: string;
  name: string;
}
export interface ConsoleTabsProps extends IComponentProps {
  title: string;
  tabLinks: TabLink[];
  alerts?: { [tabName: string]: "warning" | "exceeded" };
}

const ConsoleTabs: React.FC<ConsoleTabsProps> = ({ location, title, tabLinks, alerts, route, match }) => {
  const matches = route?.routes && matchRoutes(route.routes, location.pathname);
  const currentPath = location.pathname;
  return (
    <div>
      <div className={styles.header}>
        <Link to={currentPath} className={styles.headerTitle}>
          {title}
        </Link>
        <ul className={styles.tabs}>
          {tabLinks.map((link, key) => {
            const toPage = match.url + link.pathname;
            return (
              <li key={key}>
                <Link
                  className={getClassName(styles.tabBtn, {
                    [styles.activeBtn]: matches && matches[0].match.url === toPage,
                  })}
                  to={toPage}
                >
                  {link.name}
                  {alerts && alerts[link.name] && (
                    <FontAwesomeIcon
                      icon={["fas", "exclamation-triangle"]}
                      size="sm"
                      style={{ marginLeft: "8px", marginBottom: "1px" }}
                    />
                  )}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div>{route && renderRoutes(route.routes)}</div>
    </div>
  );
};
export default ConsoleTabs;
