import type { Geometry } from "@triplydb/recognized-datatypes/wkt";
import { findZValue, getGeometryCollectionZValue } from "./helpers.ts";
/**
 * Implementation of GeoSPARQL 1.1 minZ function.
 * See here details https://defs.opengis.net/vocprez/object?uri=https://www.opengis.net/def/function/geosparql/minZ
 *
 * @param  {Geometry} feature input
 * @returns The minimum z coordinate of the input geometry.
 */
export function geoMinZ(feature: Geometry): number {
  if (!feature.hasZ) return 0;
  return feature.type === "GeometryCollection"
    ? getGeometryCollectionZValue(feature, "Min")
    : findZValue(feature.coordinates, "Min");
}
