import * as React from "react";
import { useSelector } from "react-redux";
import type { GlobalState } from "#reducers/index.ts";

export default function useSafeCurrentDataset() {
  const datasetCollection = useSelector((state: GlobalState) => state.datasetCollection);
  const accountCollection = useSelector((state: GlobalState) => state.accountCollection);
  const currentDatasetId = useSelector((state: GlobalState) => state.datasetManagement.current);

  const currentDataset = React.useMemo(() => {
    if (!currentDatasetId || !datasetCollection[currentDatasetId]) return undefined;
    const currentDataset = datasetCollection[currentDatasetId];
    if (!currentDataset.owner) return undefined;
    let account = accountCollection[currentDataset.owner];
    if (!account) return undefined;
    if (account.type === "user") {
      const { orgs, pinnedItems, ...rest } = account;
      account = rest;
    } else {
      const { members, ...rest } = account;
      account = rest;
    }
    return {
      ...currentDataset,
      owner: account,
    };
  }, [accountCollection, currentDatasetId, datasetCollection]);
  return currentDataset;
}
