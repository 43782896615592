import getClassName from "classnames";
import { findLastIndex } from "lodash-es";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { FontAwesomeIcon, ResourceWidget } from "#components/index.ts";
import type { Browser } from "#containers/Browser/index.tsx";
import type { ResourceDescriptions } from "#reducers/resourceDescriptions.ts";
import {
  getStatementsAsTree,
  getWidgetCollection,
  selectAudio,
  selectGeometry,
  selectImage,
  selectLabel,
  selectVideo,
} from "#reducers/resourceDescriptions.ts";
import * as styles from "./style.scss";

export namespace BrowserBreadCrumbs {
  export interface Props {
    history?: Browser.History;
    datasetResourceDescriptions: ResourceDescriptions;
    currentResource: string;
    linkPath: string;
    go: Function;
  }
}

class BrowserBreadCrumbs extends React.PureComponent<BrowserBreadCrumbs.Props, any> {
  element: React.RefObject<HTMLDivElement | null>;
  constructor(props: BrowserBreadCrumbs.Props) {
    super(props);
    this.element = React.createRef();
  }
  componentDidUpdate() {
    if (this.element.current) this.element.current.scrollLeft = 10000;
  }

  getIcon(type: Browser.State["animationDirection"] | "line") {
    const icon = (
      <FontAwesomeIcon
        icon={type === "backward" ? "long-arrow-left" : "long-arrow-right"}
        size="lg"
        className={type && getClassName(styles[type])}
      />
    );
    if (type === "line") {
      return <div className={styles.lineWrapper}>{icon}</div>;
    }
    return icon;
  }
  render() {
    const { history, currentResource, datasetResourceDescriptions, linkPath, go } = this.props;

    const desc = datasetResourceDescriptions[currentResource];

    if (!desc) return null;

    const fromIndex = findLastIndex(history, (h: any) => !datasetResourceDescriptions[h.resource]);
    let historyItems = (history && history.slice(Math.max(fromIndex, 0))) || [];
    const addDots = historyItems.length > 6;
    historyItems = historyItems.slice(-6);

    const currentTree = getStatementsAsTree(currentResource, desc.statements, "forward");

    return (
      <div ref={this.element} className={getClassName(styles.container, "shadow rounding mb-5 p-5")}>
        {addDots && <div className="mr-7">........</div>}
        {historyItems.map((item, i) => {
          const historicalDesc = datasetResourceDescriptions[item.resource];
          const tree = historicalDesc && getStatementsAsTree(item.resource, historicalDesc.statements, "forward");
          return (
            <React.Fragment key={i + item.resource}>
              {tree && (
                <div className={styles.resourceAndProperty}>
                  <div style={{ minWidth: 100 }}>
                    <ResourceWidget
                      resource={tree.getTerm()}
                      widgetCollection={getWidgetCollection(tree, [
                        selectLabel,
                        selectImage,
                        selectGeometry,
                        selectAudio,
                        selectVideo,
                      ])}
                      loadResource={() => go(i - historyItems.length)}
                      linkPath={linkPath}
                    />
                  </div>
                  <div>
                    <div className={styles.property}>
                      {this.getIcon(item.animationDirection === "forward" ? "line" : item.animationDirection)}

                      <div className={styles.propertyName}>{item.property}</div>
                      {this.getIcon(item.animationDirection === "backward" ? "line" : item.animationDirection)}
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
        <div className={styles.currentResource} key={currentTree.getTerm().value}>
          <ResourceWidget
            resource={currentTree.getTerm()}
            widgetCollection={getWidgetCollection(currentTree, [
              selectLabel,
              selectImage,
              selectGeometry,
              selectAudio,
              selectVideo,
            ])}
            linkPath={linkPath}
          />
        </div>
        <div style={{ minWidth: 20, height: 1 }} />
      </div>
    );
  }
}
export default BrowserBreadCrumbs;
