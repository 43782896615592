import { Skeleton } from "@mui/material";
import { isNumber } from "lodash-es";
import * as React from "react";
import type { GoogleChartOptions, GoogleChartWrapperChartType, GoogleViz } from "react-google-charts";
import type { Pivot } from "react-pivottable";
import { PivotData } from "react-pivottable/Utilities";

const Chart = React.lazy(() => import("react-google-charts"));

function makeGoogleChart(chartType: GoogleChartWrapperChartType, extraOptions?: { isStacked: boolean }) {
  return (props: Pivot) => {
    const googleRef = React.useRef<GoogleViz>(undefined);
    const fullAggName: string = props.aggregatorName || "";
    const [googleTableData, setGoogleTableData] = React.useState<string | undefined>(undefined);
    let title = "",
      hAxisTitle = "",
      vAxisTitle = "",
      groupByTitle = "";

    if (chartType == "ScatterChart") {
      hAxisTitle = props.cols?.join("-") || "";
      vAxisTitle = props.rows?.join("-") || "";
    } else {
      title = fullAggName;
      vAxisTitle = fullAggName;
      hAxisTitle = props.cols?.join("-") || "";
      if (hAxisTitle) title += ` vs ${hAxisTitle}`;
      groupByTitle = props.rows?.join("-") || "";
      if (groupByTitle) title += ` by ${groupByTitle}`;
    }
    const updateData = React.useCallback(
      (data: Pivot) => {
        if (!googleRef.current) return;
        const pivotData = new (PivotData as any)(data);
        if (chartType == "ScatterChart") {
          const dataArray: [number, number, string][] = [];
          for (const [y, tree2] of Object.entries(pivotData.tree)) {
            for (const [x, agg] of Object.entries(tree2 as any)) {
              dataArray.push([
                parseFloat(x),
                parseFloat(y),
                fullAggName + ": \n" + (agg as any).format((agg as any).value()),
              ]);
            }
          }
          const dataTable = new googleRef.current.visualization.DataTable([]);
          dataTable.addColumn({ type: "number", label: pivotData.colKeys.join("-") });
          dataTable.addColumn({ type: "number", label: pivotData.rowKeys.join("-") });
          dataTable.addColumn({ type: "string", role: "tooltip" as any });
          dataTable.addRows(dataArray);
          setGoogleTableData(dataTable.toJSON());
        } else {
          const rowKeys = pivotData.getRowKeys().map((rowKey: string[]) => rowKey.join("-"));
          // Prepending the data array with the "headers list" also if there are no headers add another empty value for the "row id" as well
          const dataArray = [["", ...(rowKeys.length > 0 ? rowKeys : [""])]];
          const colKeys: string[][] = pivotData.getColKeys();
          if (colKeys.length === 0) colKeys.push([]);
          for (const colKey of colKeys) {
            const row: (string | number | null)[] = [colKey.join(" ")];
            const rowKeys = pivotData.getRowKeys();
            if (rowKeys.length === 0) rowKeys.push([]);
            for (const rowKey of rowKeys) {
              const agg = pivotData.getAggregator(rowKey, colKey);
              const value = agg.value();
              if (value !== undefined) {
                if (isNumber(value)) {
                  if (value < 1) {
                    row.push(parseFloat(value.toPrecision(3)));
                  } else {
                    row.push(parseFloat(value.toFixed(3)));
                  }
                } else {
                  row.push(value);
                }
              } else {
                row.push(null);
              }
            }
            dataArray.push(row);
          }
          setGoogleTableData(googleRef.current.visualization.arrayToDataTable(dataArray).toJSON());
        }
      },
      [fullAggName],
    );
    React.useEffect(() => {
      if (googleRef.current) updateData(props);
    }, [props, updateData]);
    const gChartOptions = React.useMemo(() => {
      const options: Partial<GoogleChartOptions> = {
        title: title,
        hAxis: { title: hAxisTitle, slantedText: hAxisTitle.length > 50 },
        vAxis: { title: vAxisTitle },
        tooltip: { textStyle: { fontName: "Arial", fontSize: 12 }, isHtml: true, ignoreBounds: false },
        width: undefined,
        height: undefined,
        isStacked: !!extraOptions?.isStacked,
      };
      if (chartType === "ColumnChart") {
        options.vAxis!.minValue = 0;
      }
      if (chartType === "ScatterChart") {
        options.legend = { position: "none" };
        options.chartArea = { width: "80%", height: "80%" };
      }
      return options;
    }, [hAxisTitle, title, vAxisTitle]);
    return (
      <React.Suspense fallback={<Skeleton height="inherit" variant="rectangular" />}>
        <Chart
          loader={<Skeleton height="500px" variant="rectangular" />}
          data={googleTableData || undefined}
          chartType={chartType}
          height="600px"
          width="100%"
          options={gChartOptions}
          onLoad={(google) => {
            googleRef.current = google;
            updateData(props);
          }}
        />
      </React.Suspense>
    );
  };
}
export default function createGoogleChartRenders() {
  return {
    "Line Chart": makeGoogleChart("LineChart"),
    "Bar Chart": makeGoogleChart("ColumnChart"),
    "Stacked Bar Chart": makeGoogleChart("ColumnChart", { isStacked: true }),
    "Area Chart": makeGoogleChart("AreaChart", { isStacked: true }),
    "Scatter Chart": makeGoogleChart("ScatterChart"),
  };
}

export const googleChartRenderers = ["Line Chart", "Bar Chart", "Stacked Bar Chart", "Area Chart", "Scatter Chart"];
