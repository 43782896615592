import { Alert, MenuItem } from "@mui/material";
import getClassName from "classnames";
import { capitalize } from "lodash-es";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as ReduxForm from "redux-form";
import type { Models } from "@triply/utils";
import { mergePrefixArray } from "@triply/utils/prefixUtils";
import AccessLevelIcon from "#components/AccessLevels/Icon.tsx";
import * as Forms from "#components/Forms/index.ts";
import {
  Dialog,
  EllipsisMenu,
  ErrorPage,
  FontAwesomeIcon,
  FontAwesomeRoundIcon,
  HumanizedDate,
  Markdown,
  ServiceTypeBadge,
} from "#components/index.ts";
import type { VisualizationConfig, VisualizationLabel } from "#components/Sparql/QueryResults/index.tsx";
import type { TestValues } from "#containers/Story/Query.tsx";
import { getQueryIcon } from "#helpers/FaIcons.tsx";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useQueryJobsEnabled } from "#helpers/hooks/useQueryJobsEnabled.ts";
import humanizeDuration from "#helpers/HumanizeDate.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import type { GlobalState } from "#reducers/index.ts";
import { deleteQuery, formDataToUpdateObj, updateQuery } from "#reducers/queries.ts";
import type { QueryJobFormInput } from "./forms/QueryJob/index.tsx";
import QueryJobForm from "./forms/QueryJob/index.tsx";
import QueryCopyModal from "./QueryCopyModal.tsx";
import { useSavedQuery } from "./SavedQueryContext.tsx";
import TransferQuery from "./TransferQuery.tsx";
import * as styles from "./style.scss";

export interface Props {
  editAllowed: boolean;
  hasUnsavedChanges: boolean;
  visualization: VisualizationLabel | undefined;
  visualizationConfig: VisualizationConfig | undefined;
  variableConfig: Models.VariableConfig[] | undefined;
  testValues: TestValues;
}

function isMoreStrictThan(level1: Models.AccessLevel, level2: Models.AccessLevel) {
  if (!level1 || !level2) return false;
  if (level1 === "private" && level2 !== "private") return true;
  if (level1 === "internal" && level2 === "public") return true;
  return false;
}

const QueryInfo: React.FC<Props> = ({
  editAllowed,
  hasUnsavedChanges,
  variableConfig,
  visualization,
  visualizationConfig,
  testValues,
}) => {
  const [editing, setEditing] = React.useState(false);
  const [creatingQueryJob, setCreatingQueryJob] = React.useState(false);
  const [initialQueryJobValues, setInitialQueryJobValues] = React.useState<QueryJobFormInput>();
  const dispatch = useDispatch();
  const confirm = useConfirmation();
  const constructUrlToApi = useConstructUrlToApi();
  const currentAccount = useCurrentAccount();
  const query = useSelector((state: GlobalState) => state.queries.current);
  const queryJobsEnabled = useQueryJobsEnabled();
  const acl = useAcl();
  const canCreateQueryJob = acl.check({
    action: "runPipeline",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;
  const canCopyQuery = acl.check({
    action: "copyQuery",
    context: { accessLevel: query?.accessLevel || "private", roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;
  const canShowQueryJobCreateForm = queryJobsEnabled && canCreateQueryJob;
  const canShowMenu = editAllowed || canCopyQuery || canShowQueryJobCreateForm;
  const { replace, push, location } = useHistory();

  React.useEffect(() => {
    const parsedQs = parseSearchString(location.hash.slice(1));
    if ("query-job" in parsedQs && typeof parsedQs["query-job"] === "string") {
      try {
        const config = JSON.parse(parsedQs["query-job"]) as QueryJobFormInput;
        setInitialQueryJobValues(config);
        setCreatingQueryJob(true);
      } finally {
        delete parsedQs["query-job"];
        replace({ ...location, hash: stringifyQuery(parsedQs) });
      }
    }
  }, [location, replace]);

  if (!currentAccount?.accountName || !currentAccount.type || !query) return <ErrorPage />;

  const handleSubmit = async (values: Forms.QueryMeta.FormData) => {
    try {
      const { body: newQuery } = await dispatch<typeof updateQuery>(updateQuery(query, formDataToUpdateObj(values)));
      if (newQuery.name !== query.name) {
        return dispatch(
          replace(`/${newQuery.owner.accountName}/-/queries/${newQuery.name}/${query.version}`, {
            skipDraftPrompt: true,
          }),
        );
      } else {
        setEditing(false);
      }
    } catch (e: any) {
      throw new ReduxForm.SubmissionError({ _error: e.message });
    }
  };

  function getInAccessibleMessage(serviceConfig: Models.Query["serviceConfig"]) {
    if (serviceConfig.type === "speedy") {
      return "No accessible service";
    }
    return `No accessible ${capitalize(serviceConfig.type)} service`;
  }

  return (
    <div className={styles.readOnlyMetadata}>
      <div className="flex center">
        <FontAwesomeRoundIcon
          icon={["far", getQueryIcon(query)]}
          className={getClassName("mr-5")}
          aria-label={`Visualization type: ${
            query.renderConfig?.output === "gchart"
              ? query.renderConfig.settings?.chartType || "gchart"
              : query.renderConfig?.output || "unknown"
          }`}
        />
        <div>
          <div className="flex center pt-2">
            <h1 className={getClassName("m-0", styles.title)}>{query.displayName || query.name}</h1>
            <AccessLevelIcon
              level={query.accessLevel}
              type="query"
              addTitleForAccountType={query.owner.type}
              size="lg"
              className="ml-4"
            />
          </div>
          <div className={getClassName(styles.queryOwnerName, "p-2")}>
            {"By "}
            <Link to={`/${query.owner.accountName}`}>{query.owner.name || query.owner.accountName}</Link>
          </div>
        </div>
        <div className={styles.spacer} />
        {canShowMenu && (
          <EllipsisMenu className={styles.menu}>
            {editAllowed ? (
              <MenuItem
                title="Edit the query metadata"
                onClick={() => {
                  setEditing(true);
                }}
              >
                Edit
              </MenuItem>
            ) : null}
            {editAllowed ? <TransferQuery /> : null}
            {editAllowed ? (
              <MenuItem
                title="Delete the complete query with all its versions"
                onClick={() => {
                  confirm({
                    description: `Are you sure you want to delete query '${query.name}'?`,
                    title: "Delete query?",
                    actionLabel: "Delete",
                    onConfirm: async () => {
                      try {
                        await dispatch<typeof deleteQuery>(deleteQuery(query));
                        dispatch(push({ pathname: `/${query.owner.accountName}/-/queries` }));
                      } catch (e) {
                        // No need to do anything, a notification is shown when deleting the query fails.
                      }
                    },
                  });
                }}
              >
                Delete
              </MenuItem>
            ) : null}
            {canCopyQuery ? (
              <QueryCopyModal
                variableConfig={variableConfig}
                visualization={visualization}
                visualizationConfig={visualizationConfig}
              />
            ) : null}
            {canShowQueryJobCreateForm ? (
              <MenuItem disabled={hasUnsavedChanges} onClick={() => setCreatingQueryJob(true)}>
                Create query job
              </MenuItem>
            ) : null}
          </EllipsisMenu>
        )}
      </div>
      <div className="flex">
        <dl className={styles.metadataList}>
          <dt>Created</dt>
          <dd>
            <HumanizedDate date={query.createdAt} />
          </dd>
          <dt>Modified</dt>
          <dd>
            <HumanizedDate date={query.updatedAt} />
          </dd>
          {query.lastQueriedAt && (
            <>
              <dt>Last Queried</dt>
              <dd>
                <HumanizedDate
                  date={query.lastQueriedAt}
                  humanizeDuration={(date) => humanizeDuration(date, "past", "capitalize")}
                />
              </dd>
            </>
          )}
          <dt>Versions</dt> <dd>{query.numberOfVersions === 0 ? "None" : query.numberOfVersions}</dd>
          <dt>Dataset</dt>
          <dd>
            {query.dataset ? (
              <>
                <Link
                  to={`/${query.dataset.owner.accountName}/${query.dataset.name}`}
                >{`${query.dataset.owner.accountName} / ${query.dataset.name}`}</Link>{" "}
                <AccessLevelIcon level={query.dataset.accessLevel} type="dataset" className="ml-2" />
              </>
            ) : (
              "Not accessible"
            )}
          </dd>
          {query.dataset && isMoreStrictThan(query.dataset.accessLevel, query.accessLevel) && (
            <dd>
              <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className={styles.warningIcon} />
              Not everyone who has access to the query has access to this dataset.
            </dd>
          )}
          {query.dataset && (
            <>
              <dt>Service</dt>
              <dd>
                {query.serviceConfig.service ? (
                  <>
                    <Link
                      to={`/${query.dataset?.owner.accountName}/${query.dataset?.name}/services#${query.serviceConfig.service.name}`}
                      className={getClassName(styles.serviceLink, "flex center")}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ServiceTypeBadge type={query.serviceConfig.service.type} size="xs" className="mr-2" />{" "}
                      {query.serviceConfig.service.name}
                    </Link>
                  </>
                ) : (
                  getInAccessibleMessage(query.serviceConfig)
                )}
              </dd>
            </>
          )}
        </dl>

        <Dialog
          disableEscapeKeyDown
          open={editing}
          title="Update query"
          maxWidth="lg"
          fullWidth
          onClose={() => setEditing(false)}
        >
          <Forms.QueryMeta
            initialValues={{
              name: query.name,
              displayName: query.displayName || query.name,
              description: query.description,
              accessLevel: query.accessLevel,
              dataset: query.dataset,
              serviceType: query.serviceConfig.type,
            }}
            isNewQuery={false}
            hasUnsavedChanges={hasUnsavedChanges}
            onSubmit={handleSubmit}
            cancelFunction={() => {
              setEditing(false);
            }}
            datasetSearchUrl={constructUrlToApi({ pathname: "/datasets" })}
            owner={currentAccount}
          />
        </Dialog>
        {canCreateQueryJob && (
          <Dialog
            disableEscapeKeyDown
            open={creatingQueryJob}
            title="Create query job"
            maxWidth="lg"
            fullWidth
            onClose={() => setCreatingQueryJob(false)}
          >
            {hasUnsavedChanges ? (
              <Alert severity="warning">
                This query is in a draft state. Therefore a query job cannot be created from this query.
              </Alert>
            ) : (
              <QueryJobForm
                initialValues={{
                  variables: testValues,
                  ...initialQueryJobValues,
                }}
                onCancel={() => {
                  setCreatingQueryJob(false);
                  setInitialQueryJobValues(undefined);
                }}
              />
            )}
          </Dialog>
        )}
      </div>
      {query.description && <Markdown className={getClassName(styles.description)}>{query.description}</Markdown>}
      {(!query.dataset || !query.serviceConfig.service) && (
        <Alert severity="warning">The configured endpoint is not available. The query can't be executed.</Alert>
      )}
    </div>
  );
};

export default React.memo(QueryInfo);
