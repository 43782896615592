import { stringifyQuery, validation } from "@core/utils";
import { Autocomplete, debounce, ListItem, ListItemText, TextField } from "@mui/material";
import eachDeep from "deepdash/eachDeep";
import memoizee from "memoizee";
import { stringToTerm } from "rdf-string";
import * as React from "react";
import { CachePolicies } from "use-http";
import type { FindTermsQuery } from "@triply/utils/Models";
import { getPrefixed, getPrefixInfoFromPrefixedValue, mergePrefixArray } from "@triply/utils/prefixUtils";
import { parsers, type Types } from "@triplydb/sparql-ast";
import Highlight from "#components/Highlight/index.tsx";
import { extractQueryPrefixes } from "#components/Sparql/SparqlUtils.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useFetch from "#helpers/hooks/useFetch.ts";
import { type QueryVariableFieldProps } from "./QueryVariableField.tsx";
import * as styles from "./style.scss";

const getCompletionDetails = memoizee(
  function (queryString: string | undefined, variableName: string): FindTermsQuery {
    const incomingPredicates: string[] = [];
    const predicateObject: Array<{ predicate: string; object: string }> = [];
    const outgoingPredicates: string[] = [];
    const positions = new Set<"subject" | "predicate" | "object" | "graph">();
    if (queryString) {
      try {
        const query = parsers.lenient(queryString, { baseIri: "https://triplydb.com/" });
        // Find triple patterns that have:
        // - a term as predicate AND
        // - the relevant API variable as object
        // OR
        // - the relevant API variable as subject AND
        // - have a term as predicate
        // OR
        // - the relevant API variable as subject AND
        // - have a term as predicate AND
        // - have a term as object
        eachDeep(query, (node) => {
          if (typeof node !== "object" || node === null) return;
          if ("type" in node && node.type === "graph") {
            const graphPattern = node as Types.GraphPattern;
            if ((graphPattern.name.termType as string) === "Variable" && graphPattern.name.value === variableName)
              positions.add("graph");
          }
          if ("subject" in node && "predicate" in node && "object" in node) {
            const { subject, predicate, object } = node as Types.Triple;
            if (subject.termType === "Variable" && subject.value === variableName) {
              positions.add("subject");
              if ("termType" in predicate && predicate.termType === "NamedNode") {
                outgoingPredicates.push(predicate.value);
                if (object.termType === "Literal" || object.termType === "NamedNode")
                  predicateObject.push({
                    predicate: predicate.value,
                    object: object.value,
                  });
              }
            }
            if ("termType" in predicate && predicate.termType === "Variable" && predicate.value === variableName)
              positions.add("predicate");
            if (object.termType === "Variable" && object.value === variableName) {
              positions.add("object");
              if ("termType" in predicate && predicate.termType === "NamedNode")
                incomingPredicates.push(predicate.value);
            }
          }
        });
      } catch (err) {
        // most likely a parse error
        console.error(err);
        return {};
      }
      // @DECISION  we chose to use the predicate of the first occurrence if a
      //            variable occurs with multiple predicates, because the
      //            completion will be better than when ignoring all of the
      //            predicates, and the risk of confusion will be there anyway
      if (predicateObject.length) return { pos: "subject", ...predicateObject[0] };
      // Although it seems like the `length === 1` and `length` checks below are
      // the same, the order matters: if one of `incomingPredicates` and
      // `outgoingPredicates` has length 1, we can be more precise. If both have
      // `length !== 1`, then we'll use whatever we have available.
      if (incomingPredicates.length === 1) return { pos: "object", predicate: incomingPredicates[0] };
      if (outgoingPredicates.length === 1) return { pos: "subject", predicate: outgoingPredicates[0] };
      if (incomingPredicates.length) return { pos: "object", predicate: incomingPredicates[0] };
      if (outgoingPredicates.length) return { pos: "subject", predicate: outgoingPredicates[0] };

      if (positions.size === 1) {
        for (const pos of positions.values()) {
          return { pos };
        }
      }
    }
    return {};
  },
  // https://github.com/medikoo/memoizee#primitive-mode
  { primitive: true },
);

export const InputField: React.FC<QueryVariableFieldProps> = ({
  testValue,
  onTestValueChange,
  variableDefinition,
  datasetPath,
  getQueryString,
  fieldVariant,
  prefixes,
  error,
}) => {
  const constructUrlToApi = useConstructUrlToApi();

  const allPrefixes = mergePrefixArray(extractQueryPrefixes(getQueryString()), prefixes);

  const baseTerms = React.useMemo(() => {
    return {
      q: variableDefinition.termType === "Literal" ? `"` : "",
      termType: variableDefinition.termType,
      dataType:
        variableDefinition.termType === "Literal"
          ? !variableDefinition.datatype && !variableDefinition.language
            ? "http://www.w3.org/2001/XMLSchema#string"
            : variableDefinition.datatype
          : undefined,
      languageTag: variableDefinition.termType === "Literal" ? variableDefinition.language : undefined,
    };
  }, [variableDefinition]);
  const completionDetails = React.useRef(() => {
    return getCompletionDetails(getQueryString(), variableDefinition.name);
  });

  const [searchText, _setSearchText] = React.useState("");

  const setSearchText = React.useMemo(() => debounce(_setSearchText, 200), []);

  const termsPath = constructUrlToApi({
    pathname: `/datasets/${datasetPath}/terms`,
  });

  const { data: options = [], response } = useFetch<ReturnType<typeof stringToTerm>[]>(
    `${termsPath}?${stringifyQuery({
      ...completionDetails.current,
      ...baseTerms,
      q: variableDefinition.termType === "Literal" ? `"${searchText}` : searchText,
    })}`,
    {
      interceptors: {
        response: async ({ response }) => {
          response.data = response.data.map((value: string) => stringToTerm(value));
          return response;
        },
      },
      cachePolicy: CachePolicies.NO_CACHE,
    },
    [searchText, baseTerms],
  );
  const optionsFor = (response.url && new URL(response.url)?.searchParams?.get("q")) || "";

  const validateValue = React.useMemo(
    () => validation.toStringValidator(validation.getQueryVarValidations(variableDefinition)),
    [variableDefinition],
  );
  const valueAsTerm: ReturnType<typeof stringToTerm> | null = React.useMemo(
    () =>
      testValue
        ? variableDefinition.termType === "Literal"
          ? stringToTerm(
              `"${testValue}"${variableDefinition.language ? `@${variableDefinition.language}` : ""}${!variableDefinition.language && variableDefinition.datatype ? `^^<${variableDefinition.datatype}>` : ""}`,
            )
          : stringToTerm(testValue)
        : null,
    [testValue, variableDefinition],
  );
  const defaultValueTerm: ReturnType<typeof stringToTerm> | null = React.useMemo(
    () =>
      variableDefinition.defaultValue
        ? variableDefinition.termType === "Literal"
          ? stringToTerm(
              `"${variableDefinition.defaultValue}"${variableDefinition.language ? `@${variableDefinition.language}` : ""}${!variableDefinition.language && variableDefinition.datatype ? `^^<${variableDefinition.datatype}>` : ""}`,
            )
          : stringToTerm(variableDefinition.defaultValue)
        : null,
    [variableDefinition],
  );
  const errorValue =
    error ||
    validateValue(testValue) ||
    (variableDefinition.required && testValue?.trim() === "" && "A value is required");
  return (
    <Autocomplete
      value={valueAsTerm}
      size="small"
      freeSolo
      className={styles.muiOverride}
      options={options}
      onInputChange={(_e, value, reason) => {
        // We only want to change on input. Anything else should be handled by the default onChange
        if (reason === "input") {
          if (variableDefinition.termType === "NamedNode" && value.trim() !== "") {
            const info = getPrefixInfoFromPrefixedValue(value, allPrefixes);
            setSearchText(`${info.iri}${info.localName || ""}`);
            onTestValueChange(`${info.iri}${info.localName || ""}`);
          } else {
            setSearchText(value);
            onTestValueChange(value);
          }
        }
      }}
      onChange={(_event, suggestion) => {
        if (suggestion) {
          let val;
          if (typeof suggestion === "string") {
            val = suggestion;
          } else {
            val = suggestion.value;
          }
          onTestValueChange(val);
          setSearchText(val);
        }
      }}
      filterOptions={(x) => x}
      getOptionLabel={(term) => {
        if (typeof term === "string") {
          return term;
        } else {
          if (term.termType === "NamedNode") return getPrefixed(term.value, allPrefixes) || term.value;
          return term.value;
        }
      }}
      renderInput={({ InputLabelProps, ...props }) => (
        <TextField
          {...(props as any)}
          label={variableDefinition.name}
          placeholder={
            defaultValueTerm &&
            (defaultValueTerm.termType === "NamedNode"
              ? getPrefixed(defaultValueTerm.value, allPrefixes) || defaultValueTerm.value
              : defaultValueTerm.value)
          }
          variant={fieldVariant || "outlined"}
          size="small"
          InputLabelProps={{ ...InputLabelProps, shrink: true }}
          required={variableDefinition.required}
          fullWidth
          error={!!errorValue}
          helperText={errorValue}
        />
      )}
      renderOption={(props, value) => {
        const primary =
          variableDefinition.termType === "NamedNode"
            ? getPrefixed(value.value, allPrefixes) || value.value
            : value.value.split("\n")[0];
        const toHighlight =
          variableDefinition.termType === "NamedNode"
            ? getPrefixed(optionsFor, allPrefixes) || optionsFor
            : optionsFor.slice(1);
        const secondary: string | undefined = value.termType === "NamedNode" ? value.value : undefined;
        const showEllipsis = value.termType === "Literal" && value.value.length > primary.length;

        return (
          <ListItem dense {...props}>
            <ListItemText
              primary={
                <>
                  <Highlight fullText={primary} highlightedText={toHighlight} />
                  {showEllipsis && "…"}
                </>
              }
              secondary={
                secondary && secondary !== primary ? (
                  <Highlight fullText={secondary} highlightedText={optionsFor} />
                ) : undefined
              }
            />
          </ListItem>
        );
      }}
    />
  );
};
