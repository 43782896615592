import { UngroupedVariableError } from "./error.ts";
import type { Types } from "./index.ts";
import { isWildcard } from "./serialize.ts";
import { isAggregateQuery } from "./validate.ts";

export function ungroupedVariablesCheck(ast: Types.Query | Types.SubSelect | Types.Pattern[], exclude: Set<string>) {
  if (Array.isArray(ast)) groupGraphPattern(ast, exclude);
  else query(ast, exclude);
}

type AggregateInfo = { type: "non-aggregate" } | { type: "aggregate"; aggregatedVariables: Set<string> };

function query(query: Types.Query | Types.SubSelect, exclude: Set<string>) {
  // 18.2.2 Converting Graph Patterns
  groupGraphPattern(query.where, exclude);

  // 18.2.4.1 Grouping and Aggregation
  const aggregatedVariables = new Set<string>();
  /**
   * aggregateInfo is used to keep track of whether this is an aggregate query and, if so,
   * which variables are aggregated/grouped variables. This is important for expression
   * validation: if a query is `non-aggregate`, we don't do any checks. If we pass a
   * Set, then we check whether the variables that are used outside of an aggregate
   * function are indeed mentioned in the set.
   *
   * E.g. for this query:
   *
   *    SELECT (?o1 as ?o2)
   *    WHERE { :s :p ?o1 } group by ?o1 order by ?o2
   *
   * ?o2 should be added to `aggregateInfo` when processing `query.variables`, s.t. we will
   * not get an error when processing the `ORDER BY`.
   */
  const aggregateInfo: AggregateInfo = isAggregateQuery(query)
    ? {
        type: "aggregate",
        aggregatedVariables,
      }
    : { type: "non-aggregate" };
  for (const group of query.group) {
    if (group.variable) {
      aggregatedVariables.add(group.variable.value);
    } else if ("termType" in group.expression && group.expression.termType === "Variable") {
      aggregatedVariables.add(group.expression.value);
    }
    expression(group.expression, { type: "non-aggregate" }, exclude);
  }

  // 18.2.4.2 HAVING
  // > Note that, due to the logic position in which the HAVING clause is
  // > evaluated, expressions projected by the SELECT clause are not
  // > visible to the HAVING clause.
  //
  // from: https://www.w3.org/TR/sparql11-query/#sparqlHavingClause
  // So this means we should do HAVING before doing the PROJECT
  query.having.forEach((e) => expression(e, aggregateInfo, exclude));

  // 18.2.4.4 SELECT Expressions
  // > The syntax error arises for use of a variable as the named target of
  // > AS (e.g. ... AS ?x), when the variable is used inside the WHERE clause
  // > of the SELECT or if already used as the target of AS in this SELECT
  // > expression.
  //
  // from: https://www.w3.org/TR/sparql11-query/#sparqlSelectExpressions
  if ("variables" in query && !isWildcard(query.variables)) {
    for (const variable of query.variables) {
      if ("variable" in variable) {
        expression(variable.expression, aggregateInfo, exclude);

        // always mark the ?variable in (... as ?variabe) as grouped, because:
        // - if this query is an aggregate query, this is the correct thing to do
        // - if this query is not an aggregate query, then untranslatedGroupedVariables will just be ignored
        aggregatedVariables.add(variable.variable.value);
      } else if (variable.termType === "Variable") {
        // We call `expression` here to make sure we throw the correct error for
        // ungrouped variables
        expression(variable, aggregateInfo, exclude);
      }
    }
  }

  // 18.2.5.1 ORDER BY
  for (const order of query.order) {
    expression(order.expression, aggregateInfo, exclude);
  }
}

function expression(expr: Types.Expression, aggregateInfo: AggregateInfo, exclude: Set<string>): void {
  if ("termType" in expr) {
    if (
      expr.termType === "Variable" &&
      aggregateInfo.type === "aggregate" &&
      !exclude.has(expr.value) &&
      !aggregateInfo.aggregatedVariables.has(expr.value)
    ) {
      /**
       * @DECISION
       * > In aggregate queries and sub-queries, variables that appear in the
       * > query pattern, but are not in the GROUP BY clause, can only be
       * > projected or used in select expressions if they are aggregated. The
       * > SAMPLE aggregate may be used for this purpose. For details see the
       * > section on Projection Restrictions.
       *
       * from: https://www.w3.org/TR/sparql11-query/#aggregateExample
       *
       * > In a query level which uses aggregates, only expressions consisting
       * > of aggregates and constants may be projected, with one exception.
       * > When GROUP BY is given with one or more simple expressions consisting
       * > of just a variable, those variables may be projected from the level.
       *
       * from: https://www.w3.org/TR/sparql11-query/#aggregateRestrictions
       *
       * We raise an error when the variable is (all of the following):
       * - unaggregated
       * - projected
       *
       * We _don't_ take into account whether the variable is in scope (or
       * bound), since the "one exception" of the second quote says that only
       * the variables mentioned in the GROUP BY may be projected, irrespective
       * of whether they occur in the query pattern.
       */
      throw new UngroupedVariableError(expr);
    }
  } else {
    switch (expr.type) {
      case "aggregate":
        if (!isWildcard(expr.expression)) {
          expression(expr.expression, { type: "non-aggregate" }, exclude);
        }
        break;
      case "iriFunction":
      case "function":
        switch (expr.function) {
          case "notin":
          case "in":
            expression(expr.args[0], aggregateInfo, exclude);
            expr.args[1].forEach((exp) => expression(exp, aggregateInfo, exclude));
            break;
          case "exists":
          case "notexists":
            groupGraphPattern(expr.args, exclude);
            break;
          default:
            expr.args.forEach((exp) => expression(exp, aggregateInfo, exclude));
            break;
        }
        break;
    }
  }
}

function graphPattern(pattern: Types.Pattern, exclude: Set<string>): void {
  switch (pattern.type) {
    // no grouping can ever happen in these cases
    case "values":
    case "bgp":
      break;
    case "bind":
      expression(pattern.expression, { type: "non-aggregate" }, exclude);
      break;
    case "query":
      query(pattern, exclude);
      break;
    case "optional":
    case "service":
    case "graph":
    case "group":
    case "union":
    case "minus":
      groupGraphPattern(pattern.patterns, exclude);
      break;
  }
}

function groupGraphPattern(patterns: Types.Pattern[], exclude: Set<string>): void {
  for (const pattern of patterns) {
    graphPattern(pattern, exclude);
  }
}
