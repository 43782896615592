import type { HierarchyCircularNode, HierarchyNode, Selection } from "d3";
import { pack, pointer, select } from "d3";
import React from "react";
import type { ClassHierarchyComponentProps, Datum } from "./helpers.tsx";
import { addShadowFilter, BreadCrumbs, catchErrors, formatInstances, getLabel, useRenderRef } from "./helpers.tsx";
import * as styles from "./style.scss";

const Bubbles: React.FC<ClassHierarchyComponentProps> = (props) => {
  const ref = useRenderRef(drawBubbles, props.classHierarchyTree, props.focussedNode, props.setFocus);
  return (
    <div ref={ref} className={styles.bubbles}>
      <BreadCrumbs type="bubbles" focussedNode={props.focussedNode} />
    </div>
  );
};

export default Bubbles;

const drawBubbles = catchErrors(function (
  divElement: HTMLDivElement,
  classHierarchyTree: HierarchyNode<Datum>,
  _focussedNode: HierarchyNode<Datum>,
  setFocus: (className: string) => void,
) {
  const width = Math.max(divElement.offsetWidth, 300);
  const height = width;

  const transitionDuration = 500;

  const root = pack<Datum>().size([width, height]).padding(10)(classHierarchyTree);
  //the pack function has added attributes to the nodes, so focussedNode now is a HierarchyCircularNode too:
  const focussedNode = _focussedNode as HierarchyCircularNode<Datum>;

  function shouldShowLabel(d: HierarchyCircularNode<Datum>) {
    return d.parent === focussedNode || (d === focussedNode && (!d.children || d.children.length === 0));
  }

  const svg = select(divElement)
    .selectAll<SVGSVGElement, number>("svg")
    .data([0])
    .join((enter) => {
      return addShadowFilter(enter.append("svg"));
    })
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
    .style("display", "block")
    .on("click", function () {
      setFocus(root.data.name);
    });

  function addHover(s: Selection<any, HierarchyCircularNode<Datum>, Element, any>) {
    const mouseOffsetX = 20;
    const mouseOffsetY = 30;
    const labelHeight = 46;
    return s
      .on("mouseover", function (event, d) {
        select(this).attr("stroke", "#000").style("opacity", 0.9).style("filter", "url(#drop-shadow)");

        const svgNode = svg.node();
        if (!svgNode) return;
        const [mouseX, mouseY] = pointer(event, svgNode);
        const g = svg
          .append("g")
          .classed(styles.hoverLabel, true)
          .attr("transform", () => "translate(" + [mouseX + mouseOffsetX, mouseY + mouseOffsetY] + ")");

        const firstLineLength =
          g
            .append("text")
            .text(() => getLabel(d) + ` (${formatInstances(d.value)})`)
            .attr("dx", 6)
            .attr("dy", 18)
            .node()
            ?.getComputedTextLength() || 0;

        const secondLineLength =
          g
            .append("text")
            .text(() =>
              d.data.prefixInfo?.prefixLabel
                ? `${d.data.prefixInfo.prefixLabel}:${d.data.prefixInfo.localName}`
                : d.id || "",
            )
            .attr("dx", 6)
            .attr("dy", 38)
            .node()
            ?.getComputedTextLength() || 0;

        g.append("rect")
          .attr("width", () => Math.max(firstLineLength, secondLineLength) + 12)
          .attr("height", labelHeight)
          .style("filter", "url(#drop-shadow)")
          .lower();
      })
      .on("mousemove", function (event) {
        const svgNode = svg.node();
        if (!svgNode) return;
        const [mouseX, mouseY] = pointer(event, svgNode);
        const g = svg.select(`.${styles.hoverLabel}`);
        const labelWidth = g.select<SVGRectElement>("rect").node()?.getBBox().width || 0;
        const offsetX =
          mouseX + mouseOffsetX + labelWidth > width / 2 - 5 ? width / 2 - 5 - mouseX - labelWidth : mouseOffsetX;
        const offsetY = mouseY + mouseOffsetY + labelHeight > height / 2 - 5 ? -labelHeight - 20 : mouseOffsetY;
        g.attr("transform", () => "translate(" + [mouseX + offsetX, mouseY + offsetY] + ")");
      })
      .on("mouseout", function () {
        select(this).attr("stroke", null).style("opacity", "unset").style("filter", null);

        svg.selectAll(`.${styles.hoverLabel}`).remove();
      });
  }

  //circles
  svg
    .selectAll<SVGGElement, HierarchyCircularNode<Datum>>(`g.circles`)
    .data([0])
    .join("g")
    .classed("circles", true)
    .selectAll<SVGCircleElement, HierarchyCircularNode<Datum>>("circle")
    .data(root.descendants().slice(1))
    .join((enter) => {
      return enter
        .append("circle")
        .attr("fill", (d) => d.data.color || "black")
        .attr("pointer-events", null)
        .style("cursor", "pointer")
        .on("click", function (event, d) {
          setFocus(d.data.name);
          event.stopPropagation();
        })
        .attr("transform", (d) => {
          const k = width / (focussedNode.r * 2);
          return `translate(${(d.x - focussedNode.x) * k},${(d.y - focussedNode.y) * k})`;
        })
        .attr("r", (d) => {
          const k = width / (focussedNode.r * 2);
          return d.r * k;
        });
    })
    .call(addHover)
    .transition()
    .duration(transitionDuration)
    .attr("transform", (d) => {
      const k = width / (focussedNode.r * 2);
      return `translate(${(d.x - focussedNode.x) * k},${(d.y - focussedNode.y) * k})`;
    })
    .attr("r", (d) => {
      const k = width / (focussedNode.r * 2);
      return d.r * k;
    });

  //labels
  svg
    .selectAll("g.labels")
    .data([0])
    .join("g")
    .classed("labels", true)
    .attr("pointer-events", "none")
    .attr("text-anchor", "middle")
    .selectAll<SVGTextElement, HierarchyCircularNode<Datum>>("text")
    .data(root.descendants())
    .join((enter) => {
      return enter
        .append("text")
        .text(getLabel)
        .attr("dy", "0.25em")
        .style("fill", "white")
        .style("fill-opacity", 0)
        .style("fill-opacity", (d) => (shouldShowLabel(d) ? 1 : 0))
        .attr("transform", (d) => {
          const k = width / (focussedNode.r * 2);
          return `translate(${(d.x - focussedNode.x) * k},${(d.y - focussedNode.y) * k}) scale(${k})`;
        });
    })
    .style("font-size", function (d) {
      //make sure we measure the size using the regular font size
      this.style.fontSize = "1em";
      const availableWidth = d.r * 2;
      const usedWidth = this.getComputedTextLength();
      return `${(0.9 * availableWidth) / usedWidth}em`;
    })
    .transition()
    .duration(transitionDuration)
    .style("fill-opacity", (d) => (shouldShowLabel(d) ? 1 : 0))
    .attr("transform", (d) => {
      const k = width / (focussedNode.r * 2);
      return `translate(${(d.x - focussedNode.x) * k},${(d.y - focussedNode.y) * k}) scale(${k})`;
    });
});
