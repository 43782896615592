import { IconButton, Skeleton } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import type { Dataset } from "@triply/utils/Models";
import { FontAwesomeIcon, Html, ResourceWidget } from "#components/index.ts";
import { RDF_HTML_TYPES } from "#components/TermLiteralHTML/index.tsx";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import type LdTreeNode from "#helpers/LdTree.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import type { GlobalState } from "#reducers/index.ts";
import {
  getDescription,
  getDescriptionFor,
  getStatementsAsTree,
  getWidgetCollection,
  selectAudio,
  selectClasses,
  selectDescription,
  selectGeometry,
  selectImage,
  selectLabel,
  selectVideo,
} from "#reducers/resourceDescriptions.ts";
import * as styles from "./style.scss";

interface Props {
  dataset: Dataset;
  resourceId: string;
  openJson: () => void;
}
function renderDescription(description: LdTreeNode) {
  const datatype = description.getTerm().datatype;
  if (datatype && RDF_HTML_TYPES.includes(datatype)) {
    return <Html>{description.getTerm().value}</Html>;
  } else {
    return <div>{description.getTerm().value.trim()}</div>;
  }
}

const ResourceWidgetLarge: React.FC<Props> = ({ dataset, resourceId, openJson }) => {
  const dispatch = useDispatch();
  const resourceDescription = useSelector((state: GlobalState) =>
    getDescriptionFor({
      scope: "all",
      state: state.resourceDescriptions,
      dataset: dataset,
      resource: resourceId,
      concise: false,
    }),
  );
  React.useEffect(() => {
    if (!resourceDescription) dispatch(getDescription({ dataset: dataset, resource: resourceId, concise: false }));
    // Dataset is required for the call however only changing the id should trigger this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset.id, resourceId, dispatch, resourceDescription]);

  if (!resourceDescription) {
    return (
      <div className={styles.widget}>
        <div className={styles.widgetMedia}>
          <Skeleton width={120} height={120} variant="rectangular" />
        </div>
        <div className={getClassName("p-2", styles.widgetContent)}>
          <Skeleton variant="text" height="3rem" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </div>
      </div>
    );
  }
  const tree = getStatementsAsTree(resourceId, resourceDescription.statements, "forward");
  const widgets =
    tree &&
    getWidgetCollection(tree, [
      selectLabel,
      selectDescription,
      selectVideo,
      selectAudio,
      selectClasses,
      selectImage,
      selectGeometry,
    ]);
  const browserUrl = `/${dataset.owner.accountName}/${dataset.name}/browser`;
  return (
    <div className={styles.widget}>
      <ResourceWidget
        linkPath={browserUrl}
        resource={tree.getTerm()}
        widgetCollection={widgets}
        omitLabel
        fallbackElement={<></>}
      />
      <div className={getClassName("p-2", styles.widgetContent)}>
        <h4 className={getClassName("headerSpacing", styles.widgetTitle)}>
          <Link
            draggable
            to={{ pathname: browserUrl, search: stringifyQuery({ resource: resourceId }) }}
            onDragStart={(e: React.DragEvent<HTMLAnchorElement>) => e.dataTransfer.setData("text/plain", resourceId)}
          >
            {widgets.label?.values?.[0].getTerm().value || resourceId}
          </Link>
        </h4>
        {widgets.description?.values?.[0] && (
          <div className={styles.widgetComment}>{renderDescription(widgets.description.values[0])}</div>
        )}
        <div>
          {widgets.classes?.values &&
            widgets.classes.values.map((item) => (
              <ResourceWidget
                key={item.getTerm().value}
                linkPath={browserUrl}
                widgetCollection={getWidgetCollection(item, [selectLabel])}
                resource={item.getTerm()}
                className={styles.classWidget}
              />
            ))}
        </div>
      </div>
      <div className="flex center mr-2">
        <IconButton onClick={openJson} aria-label="Open raw result" size="large">
          <FontAwesomeIcon icon={["far", "brackets-curly"]} />
        </IconButton>
      </div>
    </div>
  );
};
export default ResourceWidgetLarge;
