import { Tooltip } from "@mui/material";
import * as React from "react";
import { factories, getWriter, parse, Store } from "@triplydb/data-factory";
import fetch from "#helpers/fetch.ts";
import { Button, Dialog, FontAwesomeIcon } from "../../components";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../reducers/datasetManagement";
import { getGraphs } from "../../reducers/graphs";
import Resource from "./Forms/Resource";
import type { ResourceData } from "./Forms/Types";

const factory = factories.compliant;

const EditResource: React.FC<{ resource: string }> = ({ resource }) => {
  const [open, setOpen] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const statementsUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/statements.trig`,
    query: { limit: "10000" },
    fromBrowser: true,
  });
  const jobsUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/jobs`,
    fromBrowser: true,
  });
  const graphsUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/graphs`,
    fromBrowser: true,
  });
  const dispatch = useDispatch();

  return (
    <>
      <Button
        color="primary"
        elevation
        onClick={() => setOpen(true)}
        title="Edit this resource"
        startIcon={<FontAwesomeIcon icon="pencil" />}
        size="small"
      >
        Edit resource
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth title="Edit resource" closeButton>
        <div className="px-5 pb-5">
          <Resource
            editingResource={resource}
            onSubmit={async (values: ResourceData) => {
              const id = factory.namedNode(resource);

              const currentData = await fetch(statementsUrl, {
                credentials: "same-origin",
              }).then((response) => response.text());

              const store = new Store();

              const quads = parse(currentData, { format: "trig" });
              store.addQuads(quads);

              await new Promise((resolve, reject) => {
                store.removeMatches(id).on("end", resolve).on("error", reject);
              });

              const writer = getWriter({ format: "trig" });
              writer.addQuads(store.getQuads({}));

              writer.addQuad(
                id,
                factory.namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#type"),
                factory.namedNode(values.type!.id),
              );
              writer.addQuad(
                id,
                factory.namedNode("http://www.w3.org/2000/01/rdf-schema#label"),
                factory.literal(values.label),
              );
              for (const p of values.properties) {
                writer.addQuad(
                  id,
                  factory.namedNode(p.property!.id),
                  p.value?.datatype
                    ? factory.literal(p.value.id, factory.namedNode(p.value.datatype))
                    : factory.namedNode(p.value!.id),
                );
              }

              const serialized: string = await new Promise((resolve, reject) => {
                writer.end((error: Error | undefined, result: string) => {
                  if (error) reject(error);
                  resolve(result);
                });
              });

              const body = new FormData();
              body.set("file", new Blob([serialized]), "data.trig");

              await fetch(graphsUrl, {
                credentials: "same-origin",
                method: "DELETE",
              });
              await fetch(jobsUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });

              await dispatch<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
              );
              await dispatch<typeof getGraphs>(
                getGraphs({
                  accountName: currentDs.owner.accountName,
                  datasetName: currentDs.name,
                  datasetId: currentDs.id,
                }),
              );

              setOpen(false);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default EditResource;
