//@ts-ignore Truncate has types, but exposes them incorrectly. Try removing this ignore later
import truncate from "@turf/truncate";
// @ts-ignore Typescript
import de9im from "de9im";
import type {
  Coordinates,
  Geometry,
  GeometryCollection,
  GeometryWithoutCrs,
  LineString,
  MultiLineString,
  MultiPolygon,
  Polygon,
} from "@triplydb/recognized-datatypes/wkt";
import { type AllCrsUrl, isSupportedCrs } from "./Crs.ts";
import { geoMaxZ } from "./GeoMaxZ.ts";
import { geoMinZ } from "./GeoMinZ.ts";
import { geoProject } from "./GeoProject.ts";
import { geoWithin } from "./GeoWithin.ts";

export class PolygonWithHole extends Error {}
export class UnsupportedGeoError extends Error {}
export class GeoFunctionError extends Error {}
export type GeometryCoordinates = Exclude<Geometry, GeometryCollection>["coordinates"];
export const CRS84 = "http://www.opengis.net/def/crs/OGC/1.3/CRS84";
export const uom = prefixer("http://www.opengis.net/def/uom/OGC/1.0/");
export const units = prefixer("http://eulersharp.sourceforge.net/2003/03swap/units#");
export const unit = prefixer("http://qudt.org/vocab/unit/");
export type AreaUnitDefType = (typeof AREA_UNIT_DEF)[number];
export type DistUnitDefType = (typeof DIST_UNIT_DEF)[number];

export interface Feature {
  type: string;
  properties: {};
  geometry: Geometry;
}
/**
 * The Units we support distance for
 */
export const AREA_UNIT_DEF = [uom("metre"), uom("meter"), unit("IN2"), unit("M2"), unit("MI2"), unit("YD2")];

/**
 * The Units we support Area conversion for
 */
export const DIST_UNIT_DEF = [
  uom("metre"),
  uom("meter"),
  uom("radian"),
  uom("degree"),
  uom("kilometer"),
  uom("miles"),
  unit("CentiM"),
  units("meter"),
  units("metre"),
];

/**
 * Check whether unit we're measuring the distance in is support
 */
export function isSupportedDistanceUnit(unit: string): unit is DistUnitDefType {
  return (DIST_UNIT_DEF as string[]).includes(unit);
}

/**
 * Check whether unit we're converting the area to is support
 */
export function isSupportedAreaUnit(unit: string): unit is AreaUnitDefType {
  return (AREA_UNIT_DEF as string[]).includes(unit);
}

/**
 * This helper function is used to covert (multiLineStrings/MultiPolygon) in to an array of (lineStrings/polygon)
 *
 * @param {MultiLineString | MultiPolygon} feature
 * @returns An array of lineStrings/Polygon
 */
export function multiGeometryToArray(feature: MultiLineString | MultiPolygon): Polygon[] | LineString[] {
  if (feature.type === "MultiLineString") {
    return feature.coordinates.map((coordinates) => ({
      type: "LineString",
      coordinates: coordinates,
      crs: feature.crs,
      hasMeasurement: feature.hasMeasurement,
      hasZ: feature.hasZ,
    }));
  } else if (feature.type === "MultiPolygon") {
    return feature.coordinates.map((coordinates) => ({
      type: "Polygon",
      coordinates: coordinates,
      crs: feature.crs,
      hasMeasurement: feature.hasMeasurement,
      hasZ: feature.hasZ,
    }));
  }
  throw new Error("Invalid feature type");
}

/**
 * Check to see if two features cross or not
 */
export function checkIfCrosses(feature1: Geometry, feature2: Geometry) {
  if (
    (feature1.type === "Point" && (feature2.type.includes("LineString") || feature2.type.includes("Polygon"))) ||
    feature1.type === "LineString" ||
    (feature1.type === "Polygon" && (feature2.type.includes("Point") || feature2.type.includes("LineString")))
  )
    return de9im.crosses(prepareForDe9im(feature1), prepareForDe9im(feature2));
}

/**
 * This functions takes the given Iri and extracts the unit form Iri and returns to its equivalent in Turf
 *
 * @param {String} unitIri The Iri of the distance unit
 * @returns The requested distance unite according to the Turf library
 */
export function getDistanceUnit(unitIri: string) {
  switch (unitIri) {
    case uom("meter"):
    case uom("metre"):
    case units("meter"):
    case units("metre"):
      return "meters";
    case unit("CentiM"):
      return "centimeters";
    case uom("miles"):
      return "miles";
    case uom("radian"):
      return "radians";
    case uom("degree"):
      return "degrees";
    case uom("kilometer"):
      return "kilometers";
    default:
      throw new UnsupportedGeoError("Unit not supported");
  }
}

/**
 * This is a helper function used by both geof:MinZ and geof:MaxZ to extract either the smallest or largest Z value.
 * @param {GeometryCoordinates} coordinates The Coordinates of the Geometry
 * @param {Min | Max }evaluation Min or Max
 * @returns {number} The minimum or the maximum Z value
 */
export function findZValue(coordinates: GeometryCoordinates, evaluation: "Min" | "Max"): number {
  const { initialZ, mathMethod } = zValueEvaluationMethods[evaluation];

  const extractZ = (coordinates: Coordinates | []) => {
    return coordinates[2] !== undefined ? coordinates[2] : 0;
  };

  const findZInCoordinates = (coordinates: Coordinates[]): number => {
    return coordinates.reduce(
      (zValue: number, coord: Coordinates): number => mathMethod(zValue, extractZ(coord)),
      initialZ,
    );
  };

  const findZInComplexStructures = (coordinates: Coordinates[][] | Coordinates[][][]): number => {
    let zValue = initialZ;
    coordinates.forEach((coord) => {
      if (!is2DCoordinateArray(coord)) {
        coord.forEach((innerCoord) => {
          zValue = mathMethod(zValue, findZInCoordinates(innerCoord));
        });
      } else {
        zValue = mathMethod(zValue, findZInCoordinates(coord));
      }
    });
    return zValue;
  };

  if (!is1DCoordinateArray(coordinates)) {
    if (!is2DCoordinateArray(coordinates)) {
      return findZInComplexStructures(coordinates);
    } else {
      return findZInCoordinates(coordinates);
    }
  } else {
    return extractZ(coordinates);
  }
}

/**
 * This is a helper function used by both geof:MinZ and geof:MaxZ to extract either the smallest or largest Z value for a GeometryCollection .
 * @param {GeometryCollection} feature input
 * @param {Min | Max } evaluation Min or Max
 * @returns The minimum or the maximum Z value
 */
export function getGeometryCollectionZValue(feature: GeometryCollection, evaluation: "Min" | "Max") {
  const { initialZ, mathMethod, geoMethod } = zValueEvaluationMethods[evaluation];

  return feature.geometries.reduce((acc: number, ele: GeometryWithoutCrs) => {
    const zValue = geoMethod({ ...ele, crs: feature.crs });
    return mathMethod(zValue, acc);
  }, initialZ);
}

/**
 * This Maps the Hierarchy of each geometry
 */
export const geometryHierarchy: GeometryValueMap = {
  Point: 1,
  MultiPoint: 2,
  LineString: 3,
  MultiLineString: 4,
  Polygon: 5,
  MultiPolygon: 6,
};

/**
 *  Check to see if the two features are within each other
 * @param {Geometry} feature1
 * @param {Geometry} feature2
 * @returns
 */
export const geometriesWithin = (feature1: Geometry, feature2: Geometry): boolean => {
  if (feature1.type == feature2.type) {
    return geoWithin(feature1, feature2) || geoWithin(feature2, feature1);
  } else {
    return geoWithin(feature1, feature2);
  }
};

/**
 * Asserts that the input geometry has a supported CRS.
 * If not, throws an error.
 */
export function assertHasSupportedCrs(geometry: Geometry): asserts geometry is Geometry<AllCrsUrl> {
  if (!hasSupportedCrs(geometry)) throw new UnsupportedGeoError(`The CRS ${geometry.crs} is currently not supported`);
}

/**
 * @param {Geometry} feature
 * @returns {Geometry}
 *
 * Checks if the input feature's CRS is CRS84.
 * If it is, refine the type and return it. Otherwise, project it to CRS84.
 */

export function normalizeGeometryCRS(feature: Geometry<AllCrsUrl>): Geometry<typeof CRS84> {
  if (hasCrs(feature, CRS84)) return feature;
  return geoProject(feature, CRS84) satisfies Geometry as Geometry<typeof CRS84>;
}

/**
 * The de9im package describes the following performance related tips:
 * 1) Data is expected to be in WGS 84 coordinates as per the GeoJSON standard.
 * 2) Data with the GeoJSON bbox attribute already defined will process faster.
 * 3) Data coordinates should be truncated to avoid unrealistically high precision (more than 6 decimal places).
 *
 * In this function, we implement 1) and 3) (leaving out 2 for now due to scoping purposes)
 * See https://github.com/dpmcmlxxvi/de9im
 *
 * The `as any` is necessary because geoProject may return a geometry with a stricter type than expected by truncate,
 * but we expect it to work since truncate operates on valid GeoJSON geometries, which geoProject produces.

 */
export function prepareForDe9im(geometry: Geometry): Geometry {
  return truncate(geoProject(geometry, "http://www.opengis.net/def/crs/OGC/1.3/CRS84") as any, { precision: 6 });
}

function hasCrs<Crs extends string>(geometry: Geometry, crs: Crs): geometry is Geometry<Crs> {
  return geometry.crs === crs;
}

function hasSupportedCrs(geometry: Geometry): geometry is Geometry<AllCrsUrl> {
  return isSupportedCrs(geometry.crs);
}

const zValueEvaluationMethods = {
  Min: { initialZ: +Infinity, mathMethod: Math.min, geoMethod: geoMinZ },
  Max: { initialZ: -Infinity, mathMethod: Math.max, geoMethod: geoMaxZ },
};

function prefixer<Prefix extends string>(base: Prefix) {
  return function prefix<Suffix extends string>(local: Suffix): `${Prefix}${Suffix}` {
    return `${base}${local}`;
  };
}

interface GeometryValueMap {
  [geometry: string]: number;
}

function is1DCoordinateArray(coordinates: GeometryCoordinates): coordinates is Coordinates | [] {
  return coordinates.length === 0 || typeof coordinates[0] === "number";
}

function is2DCoordinateArray(coordinates: GeometryCoordinates): coordinates is Coordinates[] {
  return Array.isArray(coordinates) && Array.isArray(coordinates[0]) && typeof coordinates[0][0] === "number";
}
