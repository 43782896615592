import "#helpers/markup/prism.ts"; //Include all prism language
import getClassName from "classnames";
import type Mermaid from "mermaid";
import * as Prism from "prismjs";
import * as React from "react";
import { useSelector } from "react-redux";
import useResizeObserver from "use-resize-observer";
import renderMarkup from "#helpers/markup/index.ts";
import { isLinkToDomain } from "#helpers/markup/unifiedPlugins.ts";
import type { GlobalState } from "#reducers/index.ts";
import "./prism.scss";
import * as styles from "./index.scss";

export interface Props {
  className?: string;
  compact?: boolean;
  openLinkInCurrentWindow?: (href: string) => boolean;
  children: string;
}
export namespace Markup {
  export interface MarkupProps extends Props {
    from: "html" | "markdown";
  }
}

const Markup: React.FC<Markup.MarkupProps> = React.memo((props) => {
  const resizeObserver = useResizeObserver();
  const domain = useSelector((state: GlobalState) => state.config.staticConfig?.consoleUrl.domain);
  const html = renderMarkup(props.children, {
    from: props.from,
    to: "html",
    //by default open links to the current domain in the current window
    openLinkInCurrentWindow: props.openLinkInCurrentWindow ?? ((href: string) => isLinkToDomain(href, domain)),
    compact: props.compact,
  });

  return (
    <div
      ref={resizeObserver.ref}
      suppressHydrationWarning
      className={getClassName(styles.markup, props.className, { [styles.compact]: props.compact })}
    >
      <Content html={html} width={resizeObserver.width || 0} />
    </div>
  );
});

const Content: React.FC<{ html: string; width: number }> = React.memo(({ html, width }) => {
  React.useEffect(() => {
    if (html.indexOf(`class="mermaid"`) >= 0 && width) {
      //Only use this dynamic import when we actually have a mermaid div
      import("mermaid")
        .then(async (m: { default: typeof Mermaid }) => {
          m.default.initialize({
            class: {
              useWidth: width,
            },
            startOnLoad: false,
            theme: "neutral",
            sequence: {
              actorFontFamily: "inherit",
              actorFontSize: 14,
              noteFontFamily: "inherit",
              noteFontSize: 14,
              messageFontFamily: "inherit",
              messageFontSize: 14,
            },
            themeCSS: "text.messageText { stroke: none; }",
            themeVariables: {
              fontFamily: "inherit",
              fontSize: "13px",
            },
          });
          await m.default.run();
          m.default.contentLoaded();
        })
        .catch(console.error);
    }
  }, [html, width]);

  React.useEffect(() => {
    Prism.highlightAll();
  }, [html, width]);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
});

export const Markdown: React.FC<Props> = (props) => <Markup {...props} from="markdown" />;
export const Html: React.FC<Props> = (props) => <Markup {...props} from="html" />;
