import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Chip, Skeleton } from "@mui/material";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import { LoadingButton } from "#components/index.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { stringifyQuery } from "../../../helpers/utils";
import * as styles from "./style.scss";

const factory = factories.compliant;

const PAGE_SIZE = 10;

interface Props {
  iri: string;
}
interface InLinkInstance {
  inlink: string;
  inlinkLabel?: string;
  inlinkClass?: string;
  inlinkClassLabel?: string;
}

const RelatedInstances: React.FC<Props> = ({ iri }) => {
  const applyPrefixes = useApplyPrefixes();
  const [instancesList, setInstancesList] = React.useState<InLinkInstance[]>([]);
  const [page, setPage] = React.useState(0);
  const search = useCurrentSearch();
  const location = useLocation();

  // This component won't remount for every data object, so we should clear the pagination state helpers when the current resoruce changes
  React.useEffect(() => {
    setInstancesList([]);
    setPage(0);
  }, [iri]);

  const { data, error, loading } = useSparql<InLinkInstance[]>(
    iri &&
      `
    # Related instances

    prefix triply: <https://triplydb.com/Triply/function/>
    prefix meta: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>

    select
      ?inlink
      (triply:firstLabel(?inlink) as ?inlinkLabel)
      (sample(?_inlinkClass) as ?inlinkClass )
      (triply:firstLabel(?inlinkClass) as ?inlinkClassLabel )
    where {
      {
        select distinct ?inlink {
          bind(${termToString(factory.namedNode(iri))} as ?currentInstance)
          ?inlink ?predicate ?currentInstance .
          filter ( ?predicate != meta:product )
        }
        limit ${PAGE_SIZE} offset ${page * PAGE_SIZE}
      }
      optional {
        ?inlink a ?_inlinkClass.
      }
    }
    group by ?inlink
    `,
  );
  // Don't display if we've retrieved an empty page (but we can only do this check after loading)
  const canLoad =
    (loading || (page + 1) * PAGE_SIZE === instancesList.length) && instancesList.length % PAGE_SIZE === 0;
  React.useEffect(() => {
    if (data !== undefined) {
      setInstancesList((list) => {
        return [...list, ...data];
      });
    }
  }, [data]);

  if (error) return <Alert severity="warning">Instances could not be loaded.</Alert>;

  if (loading && instancesList.length === 0) return <Skeleton height={54} variant="rectangular" />;

  if (!iri || instancesList.length === 0) return null;

  return (
    <Accordion variant="outlined" className={styles.block}>
      <AccordionSummary expandIcon={<FontAwesomeIcon size="lg" icon={["fas", "caret-down"]} />}>
        Related instances
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.instancesGrid}>
          {instancesList.map((instance) => {
            const classLabel = instance.inlinkClassLabel || applyPrefixes(instance.inlinkClass);
            return (
              <Link
                key={`${instance.inlink}@${instance.inlinkClass}`}
                className={styles.instanceItem}
                to={{
                  pathname: location.pathname,
                  search: stringifyQuery({ ...search, resource: instance.inlink }),
                }}
                draggable
                onDragStart={(e) => e.dataTransfer.setData("text/plain", instance.inlink)}
              >
                {instance.inlinkLabel || applyPrefixes(instance.inlink)}
                {classLabel && <Chip size="small" label={classLabel} />}
              </Link>
            );
          })}
        </div>
        {canLoad && (
          <LoadingButton loading={loading} onClick={() => setPage((page) => page + 1)} className="mt-3">
            Load more
          </LoadingButton>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default RelatedInstances;
