import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import type { PrefixInfo } from "@triply/utils/Models";
import Ellipsis from "#components/Ellipsis/index.tsx";
import { FontAwesomeIcon, Prefixable } from "#components/index.ts";
import * as styles from "./style.scss";
import * as rowStyles from "#containers/Table/style.scss";

export namespace TermLink {
  export interface Props {
    className?: string;
    iri: string;
    prefixInfo?: PrefixInfo;
    createPrefix?: any;
    isFiltered?: boolean;
    link?: {
      to: {
        pathname: string;
        search?: string;
      };
    };
  }
}

//used for e.g. IRIs and graphnames
export const TermLink: React.FC<TermLink.Props> = ({ link, className, iri, isFiltered, prefixInfo, createPrefix }) => {
  const useLink = link || { to: iri, target: "_blank", rel: "noopener noreferrer" };
  const prefixLabel = prefixInfo && prefixInfo.prefixLabel !== undefined && prefixInfo.iri !== iri && (
    <span className={styles.prefixLabel}>{`${prefixInfo.prefixLabel}:`}</span>
  );
  const localName = prefixInfo && prefixInfo.localName && `${prefixInfo.localName}`;
  const prefixable =
    !!localName && prefixInfo?.prefixLabel === undefined && prefixInfo?.iri ? (
      <Prefixable createPrefix={createPrefix} iri={prefixInfo.iri} />
    ) : (
      <></>
    );

  const isSkolemIri = iri.indexOf("/.well-known/genid") > 0;

  return (
    <div
      draggable
      onDragStart={(e: any) => {
        e.dataTransfer.setData("text/plain", iri);
      }}
      className={getClassName(styles.link, className)}
    >
      {isFiltered === true ? (
        <div>
          {isSkolemIri ? (
            <>
              <FontAwesomeIcon icon={"bracket-square"} />
              ...{iri.substring(iri.length - 3)}
              <FontAwesomeIcon icon={"bracket-square-right"} />
            </>
          ) : (
            <div>
              {prefixLabel || prefixable}
              <Ellipsis value={localName || iri} />
            </div>
          )}
        </div>
      ) : (
        <div>
          {isSkolemIri ? (
            <Link {...useLink} aria-label={"Skolemized Iri"}>
              <>
                <FontAwesomeIcon icon={"bracket-square"} />
                ...{iri.substring(iri.length - 3)}
                <FontAwesomeIcon icon={"bracket-square-right"} />
              </>
            </Link>
          ) : (
            <div>
              {prefixLabel || prefixable}
              <Link {...useLink}>
                <Ellipsis value={localName || iri} />
              </Link>
            </div>
          )}
        </div>
      )}
      <a
        href={iri}
        target="_blank"
        rel="noopener noreferrer"
        title={"Open external link in new window"}
        className={getClassName(styles.extLink, rowStyles.rowHover)}
      >
        {iri}
      </a>
    </div>
  );
};

export default TermLink;
