import { Alert, NoSsr } from "@mui/material";
import * as React from "react";
import type { QueryResponse } from "../../../SparqlUtils";
import { isSelectResponse } from "../../../SparqlUtils";
import ExampleQuery from "../../ExampleQuery";
import { SparqlVisualizationContext, useSparqlResults } from "../../SparqlVisualizationContext";
import { EmptyResults } from "../displayUtils";
import ErrorResponse from "../Error";

export function canDraw(data?: QueryResponse) {
  if (!isSelectResponse(data)) {
    return false;
  }
  return data.head.vars.includes(START_VARIABLE) || data.head.vars.includes(DATE_VARIABLE);
}

const EXAMPLE_QUERY = `SELECT * WHERE {
  values (?eventDate ?eventLabel ?eventDescription) { # To show a duration, use ?eventStart and ?eventEnd
          ("2023-01-01" "Event 1" "Something about event 1")
          ("2023-01-02" "Event 2" "Something about event 2")
          ("2023-01-03" "Event 3" "Something about event 3")
          ("2024-01-04" "Event 4" "Something about event 4")
        }
}`;

const TimelineComponent = React.lazy(() => import("./TimelineComponent"));

const TimelineVis: React.FC<{}> = ({}) => {
  const { isSelect, variables, emptyResults, noResults } = useSparqlResults();
  const { setVisualizationConfig } = React.useContext(SparqlVisualizationContext);
  if (noResults) return null;
  if (!isSelect) {
    return (
      <Alert severity="warning" role="alert">
        This visualization can only render with Select queries
      </Alert>
    );
  }
  if (emptyResults) {
    return <EmptyResults />;
  }
  if (!(variables.includes(START_VARIABLE) || variables.includes(DATE_VARIABLE))) {
    if (!!setVisualizationConfig) {
      return (
        <Alert severity="warning" role="alert">
          Couldn't render the Timeline visualization. Check the documentation using the button at the right of the menu
          above for more information.{" "}
          <ExampleQuery query={EXAMPLE_QUERY} name="Timeline example" visualization="Timeline" />
        </Alert>
      );
    } else {
      return <ErrorResponse error={new Error("No bound start or end variable was bound")} />;
    }
  }
  return (
    <NoSsr>
      <React.Suspense>
        <TimelineComponent />
      </React.Suspense>
    </NoSsr>
  );
};

export default TimelineVis;

export const START_VARIABLE = "eventStart";
export const DATE_VARIABLE = "eventDate";
