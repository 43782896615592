import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import type { Query } from "@triply/utils/Models";
import { Meta } from "#components/index.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { filterEmptyVals } from "#helpers/utils.ts";
import type { GlobalState } from "#reducers/index.ts";

const DATE_FORMAT = "YYYY-MM-DD"; // ISO-8601 date format
// see https://schema.org/accessMode . Unfortunately there is no good documentation of these strings.
type AccessMode =
  | "auditory"
  | "tactile"
  | "textual"
  | "visual"
  | "colorDependent"
  | "chartOnVisual"
  | "chemOnVisual"
  | "diagramOnVisual"
  | "mathOnVisual"
  | "musicOnVisual"
  | "textOnVisual";

// mapping from default yasgui result types to accessMode values
const regularAccessModes = {
  table: "textual",
  Table: "textual",
  response: "textual",
  Response: "textual",
  gallery: "visual",
  Gallery: "visual",
  geo: "visual",
  Geo: "visual",
  geo3d: "visual",
  pivot: "chartOnVisual",
  Pivot: "chartOnVisual",
  network: "chartOnVisual",
  Network: "chartOnVisual",
  timeline: "textOnVisual",
  Timeline: "textOnVisual",
  geoEvents: "textOnVisual",
} as { [type: string]: AccessMode };

// mapping from Google Chart result types to accessMode values
const gChartIcons = {
  LineChart: "chartOnVisual",
  AreaChart: "chartOnVisual",
  ColumnChart: "chartOnVisual",
  BarChart: "chartOnVisual",
  ScatterChart: "chartOnVisual",
  PieChart: "chartOnVisual",
  GeoChart: "chartOnVisual",
  ImageSparkLine: "chartOnVisual",
  Gauge: "chartOnVisual",
  OrgChart: "chartOnVisual",
  TreeMap: "chartOnVisual",
  Table: "textual",
  ComboChart: "chartOnVisual",
  ImageChart: "chartOnVisual",
  SteppedAreaChart: "chartOnVisual",
  Histogram: "chartOnVisual",
  BubbleChart: "chartOnVisual",
} as { [type: string]: AccessMode };

export function getAccessMode(query: Query): AccessMode {
  const outputType = query.renderConfig?.output;
  if (!outputType) return "textual"; // default to text
  if (outputType in regularAccessModes) return regularAccessModes[outputType];
  if (outputType === "gchart") {
    const gChartType: string = (query.renderConfig?.settings?.chartConfig?.chartType as string) || "Table";
    if (gChartType in gChartIcons) {
      return gChartIcons[gChartType];
    }
  }
  if (outputType === "Charts") {
    const gChartType: string = (query.renderConfig?.settings?.chartType as string) || "Table";
    if (gChartType in gChartIcons) {
      return gChartIcons[gChartType];
    }
  }
  return "textual";
}

const QueryMetaData = () => {
  const constructConsoleUrl = useConstructConsoleUrl();
  const { accountName, accountDisplayName, query, accountType, accountAvatar } = useSelector((state: GlobalState) => {
    let account = state.app.currentAccount ? state.accountCollection[state.app.currentAccount] : undefined;
    return {
      accountName: account?.accountName,
      accountDisplayName: account?.name,
      accountType: account?.type,
      accountAvatar: account?.avatarUrl,
      query: state.queries.current,
    };
  });
  const brandingName = useSelector((state: GlobalState) => state.config.clientConfig?.branding.name);
  const logoLg = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoLg);
  if (!query) return <></>;
  const title = `Query ${query.displayName || query.name} of ${accountType === "org" ? "organization" : "user"} ${
    accountDisplayName || accountName
  } on ${brandingName}.`;
  const description = query.description || title;
  const queryUrl = constructConsoleUrl({ pathname: `/${accountName}/-/queries/${query.name}` });
  const accountUrl = constructConsoleUrl({ pathname: `/${accountName}` });
  const publisher = {
    "@type": accountType === "org" ? "Organization" : "Person",
    "@id": accountUrl,
    name: accountDisplayName || accountName,
  };

  const schemaDotOrgJsonLd = {
    "@context": "https://schema.org/",
    "@type": "WebPage",
    url: queryUrl,
    mainEntity: filterEmptyVals({
      "@id": queryUrl,
      "@type": "CreativeWork",
      dateModified: moment(query.updatedAt).format(DATE_FORMAT),
      datePublished: moment(query.createdAt).format(DATE_FORMAT),
      description: description,
      identifier: queryUrl,
      name: query.displayName || query.name,
      creator: publisher,
      url: queryUrl,
      encodingFormat: "application/sparql-query",
      version: query.version,
      accessMode: getAccessMode(query),
      image: accountAvatar || logoLg,
      potentialAction: query.requestConfig?.payload.query && {
        "@type": "SearchAction",
        query: query.requestConfig.payload.query,
        url: query.service,
      },
    }),
    speakable: {
      "@type": "SpeakableSpecification",
      xpath: ["/html/head/title", "/html/head/meta[@property='og:description']/@content"],
    },
  };
  return (
    <Meta
      currentPath={`/${accountName}/-/queries/${query.name}`}
      title={`${query.displayName || query.name} - ${accountDisplayName || accountName}`}
      longTitle={title}
      jsonLd={[{ key: "schema", json: schemaDotOrgJsonLd }]}
    />
  );
};
export default React.memo(QueryMetaData);
