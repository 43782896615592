import type { Prefix } from "@triply/utils/prefixUtils";

export function escapeId(id: string) {
  return encodeURIComponent(id).replace(/[/\.:#%+@\(\)`'~"]/g, "_");
}

export function getLocalName(iri: string) {
  return iri.replace(/.*[\\/#]/g, "");
}

export function getPrefixAndLabel(prefixes: Prefix[], iri: string) {
  for (const p of prefixes) {
    if (iri.startsWith(p.iri)) {
      return {
        label: iri.slice(p.iri.length),
        prefix: p.prefixLabel,
      };
    }
  }
  return { label: getLocalName(iri) };
}

export function getPrefixIri(prefixes: Prefix[], iri: string) {
  for (const p of prefixes) {
    if (iri.startsWith(p.iri)) {
      return p.iri;
    }
  }
}

export function prefixedOrLocalName(prefixes: Prefix[], iri: string) {
  const { prefix, label } = getPrefixAndLabel(prefixes, iri);
  return prefix ? `${prefix}:${label}` : label;
}

export function getLabel(prefixes: Prefix[], iri: string) {
  for (const p of prefixes) {
    if (iri.startsWith(p.iri)) {
      return `${p.prefixLabel}:${iri.slice(p.iri.length)}`;
    }
  }
  return iri;
}
