import { ErrorMessage } from "@hookform/error-message";
import {
  Alert,
  Autocomplete,
  FormHelperText,
  MenuItem,
  Skeleton,
  TextField,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { cloneDeep, forEach, merge } from "lodash-es";
import * as React from "react";
import { Controller, FormProvider as ReactHookFormProvider, useForm, useFormContext } from "react-hook-form";
import { useLocation } from "react-router";
import { v4 as uuid } from "uuid";
import { factories } from "@triplydb/data-factory";
import { injectVariablesInPlace, parsers, serialize } from "@triplydb/sparql-ast";
import { termToString } from "@triplydb/sparql-ast/serialize";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { substringMatch } from "#components/Highlight/index.tsx";
import { FormField, Highlight, Prompt } from "#components/index.ts";
import { validateIri } from "#containers/DataModel/Forms/helpers.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { generateId } from "../idUtils";
import formValuesToSparqlValues from "./formValuesToSparqlValues";
import { getSparqlBasedConstraints } from "./getSparqlBasedConstrains";
import NodeShape from "./NodeShape";
import localTheme from "./Theme";
import type { FormValues } from "./Types";
import useClasses from "./useClasses";
import useFetchInitialValues from "./useFetchInitialValues";
import * as styles from "./style.scss";

const factory = factories.compliant;

interface Props {
  onSubmit: (values: FormValues, initialValues?: FormValues) => Promise<void>;
  onDirty: (dirty: boolean) => void;
  isCopy?: boolean;
  editingResource?: string;
  instanceOf?: string;
}
const InstanceForm: React.FC<Props> = (props) => {
  return (
    <Theme>
      <FormProvider editingResource={props.editingResource} isCopy={props.isCopy}>
        <UnsavedChanges>
          <Form {...props} />
        </UnsavedChanges>
      </FormProvider>
    </Theme>
  );
};

const Theme: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useTheme();
  const formTheme = React.useMemo(() => merge(cloneDeep(localTheme), theme), [theme]);
  return <ThemeProvider theme={formTheme}> {children} </ThemeProvider>;
};

const FormProvider: React.FC<{ children: React.ReactNode; editingResource?: string; isCopy?: boolean }> = ({
  children,
  editingResource,
  isCopy,
}) => {
  const fetchInitialValues = useFetchInitialValues();
  const consoleUrl = useConstructConsoleUrl()();

  const methods = useForm<FormValues>({
    shouldUnregister: false,
    defaultValues:
      (editingResource &&
        (async () => {
          let values = await fetchInitialValues(editingResource);
          if (isCopy) {
            forEach(values.properties, (property, key) => {
              values.properties[key] = property.map((p) => {
                if (p && p.nodeKind === "NestedNode") {
                  return { ...p, value: `${consoleUrl}/.well-known/genid/${uuid()}` };
                } else {
                  return p;
                }
              });
            });
            return values;
          } else {
            return values;
          }
        })) ||
      undefined,
  });

  return <ReactHookFormProvider {...methods}>{children}</ReactHookFormProvider>;
};

const UnsavedChanges: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const {
    formState: { isDirty },
  } = useFormContext<FormValues>();
  return (
    <>
      <Prompt
        when={isDirty}
        message={(newState) => {
          //dont want the prompt to show up when only changing the location state
          //Otherwise, drawing the modal would trigger it
          if (location.pathname === newState.pathname) return true;
          return "Your changes have not been saved. Are you sure you want to continue?";
        }}
      />
      {children}
    </>
  );
};

const Form: React.FC<Props> = ({ onSubmit, onDirty, isCopy, editingResource, instanceOf }) => {
  const fetchInitialValues = useFetchInitialValues();
  const applyPrefixes = useApplyPrefixes();
  const currentDs = useCurrentDataset()!;
  const consoleUrl = useConstructConsoleUrl()();
  const datasetUrl = `${consoleUrl}/${currentDs.owner.accountName}/${currentDs.name}`;
  const removePrefixes = useRemovePrefixes();
  const prefixes = useDatasetPrefixes();
  const baseIri = prefixes.find((prefix) => prefix.prefixLabel === "id")?.iri || `${datasetUrl}/id/`;
  const [sparqlConstraintValidating, setSparqlConstraintValidating] = React.useState(false);
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/_console/sparql`,
    fromBrowser: true,
  });

  const {
    control,
    watch,
    formState: { isDirty, errors, isSubmitting },
    setValue,
    handleSubmit,
    setError,
  } = useFormContext<FormValues>();

  watch("type"); // Somehow just watching "type.id" misses updates..
  const stem = watch("type.stem");
  const type = watch("type.id");
  const classes = useClasses(instanceOf);

  const submitEnabled = isCopy || isDirty;

  React.useEffect(() => {
    onDirty?.(isDirty);
  }, [isDirty, onDirty]);

  const submitWithoutPropagation = React.useCallback(
    (e: React.FormEvent) => {
      e.stopPropagation();
      e.preventDefault();
      return handleSubmit(async (values: FormValues) => {
        // Retrieve initial values again, as when fields are removed from the form, the original values are deleted
        setSparqlConstraintValidating(true);
        const initialValues = editingResource ? await fetchInitialValues(editingResource) : undefined;

        // Sparql based constraints
        const id = editingResource || factory.namedNode(removePrefixes(values.iri.trim())).id;

        const constraints = values.type?.id
          ? await getSparqlBasedConstraints(values.type.id, sparqlUrl, currentDs.name, currentDs.owner.accountName)
          : [];
        if (!editingResource) {
          //Constraint for checking duplicate iris when auto generating ids
          constraints.push({
            id: "autgenerated-id-duplicate",
            message: `IRI ${id} already exists. Please use a unique IRI.`,
            query: `
select * where {
  $this ?p ?o.
} limit 1
          `,
          });
        }
        const rawPropertyValues = formValuesToSparqlValues(id, values.properties, removePrefixes);
        const positive = rawPropertyValues
          ? rawPropertyValues.join(". \n") + (rawPropertyValues.length ? "." : "")
          : "";
        const initialTriples = initialValues
          ? formValuesToSparqlValues(id, initialValues.properties, removePrefixes)
          : [];
        const uniqueNegativeTriples = initialTriples.filter((triple) => !rawPropertyValues.includes(triple));
        const negative = uniqueNegativeTriples.join(". \n") + (uniqueNegativeTriples.length ? "." : "");

        // We need to group the constraint errors by path, as the setError of useForm doesn't merge the objects
        const submissionErrors: { [path: string]: { [error: string]: string } } = {};
        const constraintPromises = constraints.map(async (constraint) => {
          const query = `
            ${(constraint.prefixDeclarations || []).map((prefixDeclaration) => `prefix ${prefixDeclaration.prefix}: ${termToString(factory.namedNode(prefixDeclaration.namespace))} \n`)}
            ${constraint.query}
          `;
          const parsedQuery = parsers.lenient(query, { baseIri: "https://triplydb.com/" });
          const mutatedQuery = injectVariablesInPlace(parsedQuery, {
            declarations: [{ name: "this", termType: "NamedNode" }],
            values: { this: id },
          });
          const response = await fetch(sparqlUrl, {
            credentials: "same-origin",
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify({
              queryString: serialize(mutatedQuery),
              account: currentDs.owner.accountName,
              dataset: currentDs.name,
              extraNquads:
                constraint.id !== "autgenerated-id-duplicate"
                  ? {
                      positive: positive ? positive : undefined,
                      negative: negative ? negative : undefined,
                    }
                  : undefined,
            }),
          });
          if (response.status === 200) {
            const results: { this: string; path: string; value: string }[] = await response.json();
            if (results.length) {
              for (const result of results) {
                if (result.path) {
                  const path = `properties.${result.path.replace(/\./g, " ")}.root`;
                  submissionErrors[path] = {
                    ...(submissionErrors[path] || {}),
                    [constraint.message || "SPARQL based contraint"]:
                      constraint.message || "A SPARQL based constraint was not satisfied",
                  };
                } else {
                  const path = `root.serverError`;
                  submissionErrors[path] = {
                    ...(submissionErrors[path] || {}),
                    [constraint.message || "SPARQL based contraint"]:
                      constraint.message || "A SPARQL based constraint was not satisfied",
                  };
                }
              }
            }
          } else {
            const path = `root.serverError`;
            submissionErrors[path] = {
              ...(submissionErrors[path] || {}),
              [constraint.message || constraint.id]:
                `An error happened while validating rule "${constraint.message || constraint.id}"`,
            };
          }
        });

        try {
          await Promise.all(constraintPromises);
          let grabFocus = true;
          setSparqlConstraintValidating(false);
          if (Object.keys(submissionErrors).length > 0) {
            for (const [path, error] of Object.entries(submissionErrors)) {
              setError(path as `properties.${string}`, { types: error }, { shouldFocus: grabFocus });
              grabFocus = false;
            }
            return;
          }
          await onSubmit(values, editingResource ? initialValues : undefined);
        } catch (e) {
          setSparqlConstraintValidating(false);
          console.error(e);
          setError("root.serverError", {
            type: "500",
          });
        }
      })(e);
    },
    [
      handleSubmit,
      editingResource,
      fetchInitialValues,
      removePrefixes,
      sparqlUrl,
      currentDs.name,
      currentDs.owner.accountName,
      onSubmit,
      setError,
    ],
  );

  if (!classes) {
    return <Skeleton variant="rectangular" width={860} height={175} />;
  }
  if (classes.length === 0) {
    return (
      <Alert severity="info">
        No SHACL shapes were found {editingResource ? "for this resource" : "in this dataset"}
      </Alert>
    );
  }

  return (
    <form method="POST" onSubmit={submitWithoutPropagation} className="flex column g-7">
      <FormField className={styles.formField} label="Type" required>
        <Controller
          name="type"
          control={control}
          defaultValue={null}
          rules={{ required: "A type is required." }}
          render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
            <Autocomplete
              options={classes || []}
              onChange={(_e, data: any) => {
                // When a class is selected we reset the value for the IRI to take dash:stem into account.
                setValue(
                  "iri",
                  data?.stem
                    ? `${data.stem}${generateId(currentDs.autogeneratedIdType)}`
                    : applyPrefixes(`${baseIri}${generateId(currentDs.autogeneratedIdType)}`),
                );
                onChange(data);
              }}
              renderInput={(params) => (
                <TextField
                  {...(params as any)}
                  required
                  error={!!error}
                  helperText={error?.message || rest.value?.description}
                />
              )}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              getOptionLabel={(option) => {
                return option.label || applyPrefixes(option.id);
              }}
              getOptionKey={(option) => option.id}
              renderOption={(props, option, { inputValue }) => {
                const label = option.label || applyPrefixes(option.id) || "";
                return (
                  <MenuItem {...props}>
                    <Highlight fullText={label} highlightedText={inputValue} matcher={substringMatch} />
                  </MenuItem>
                );
              }}
              {...rest}
            />
          )}
        />
      </FormField>

      {(isCopy || !editingResource) && (
        <FormField className={styles.formField} label="Instance IRI" required>
          <Controller
            name="iri"
            control={control}
            rules={{
              validate: (value) => validateIri(removePrefixes(value?.trim())),
            }}
            defaultValue={
              !!stem
                ? `${stem}${generateId(currentDs.autogeneratedIdType)}`
                : applyPrefixes(`${baseIri}${generateId(currentDs.autogeneratedIdType)}`)
            }
            render={({ field, fieldState }) => (
              <>
                {!!stem ? <FormHelperText>{applyPrefixes(stem)}</FormHelperText> : null}
                <TextField
                  {...field}
                  value={!!stem ? field.value.replace(stem, "") : field.value}
                  onChange={(event) => {
                    field.onChange({
                      ...event,
                      target: {
                        ...event.target,
                        value: !!stem ? `${stem}${event.target.value}` : event.target.value,
                      },
                    });
                  }}
                  error={!!fieldState.error}
                  required
                  helperText={fieldState.error?.message}
                />
              </>
            )}
          />
        </FormField>
      )}

      <NodeShape classIri={type} namePrefix="properties" />

      <ErrorMessage
        errors={errors}
        name="root.serverError"
        render={({ message, messages }) => {
          if (message)
            return (
              <Alert severity="error" variant="outlined">
                {message}
              </Alert>
            );
          if (messages) {
            return Object.entries(messages).map(([type, message]) => (
              <Alert severity="error" variant="outlined" key={type}>
                {message}
              </Alert>
            ));
          }
          return (
            <Alert severity="error" variant="outlined">
              Something went wrong on the server...
            </Alert>
          );
        }}
      />

      <LoadingButton color="secondary" type="submit" loading={isSubmitting} disabled={!submitEnabled}>
        {(sparqlConstraintValidating && "Validating") || (isSubmitting && "Submitting") || "Save"}
      </LoadingButton>
    </form>
  );
};

export default InstanceForm;
