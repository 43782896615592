import { validation } from "@core/utils";
import { InputAdornment } from "@mui/material";
import getClassName from "classnames";
import memoizee from "memoizee";
import * as React from "react";
import { connect } from "react-redux";
import * as ReduxForm from "redux-form";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { Alert, FontAwesomeIcon, FormField, MuiTextFieldRedux } from "#components/index.ts";
import { checkAccountName } from "#reducers/accounts.ts";
import type { DispatchedFn, GlobalState } from "#reducers/index.ts";

const userNameValidator = validation.toStringValidator(
  validation.getAccountNameValidations({ messageSubject: "A user name" }),
);

namespace RenameAccountForm {
  export interface FormData {
    name: string;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState & Partial<ReduxForm.InjectedFormProps<FormData>>;
  export interface OwnProps extends Partial<ReduxForm.ConfigProps<FormData>> {
    className?: string;
  }
  export interface DispatchProps {
    checkAccountName: DispatchedFn<typeof checkAccountName>;
  }
  export interface PropsFromState {}
  export interface State {}
}

function asyncValidate(
  values: RenameAccountForm.FormData,
  _dispatch: any,
  props: RenameAccountForm.Props,
  _blurredField: string,
) {
  //promise should return object with key as form key, and value as error message
  if (props.initialValues?.name?.toLowerCase() === values.name.toLowerCase()) return Promise.resolve();
  return Promise.resolve(props.checkAccountName("name", values.name));
}

const RenameAccountForm = connect<
  RenameAccountForm.State,
  { [K in keyof RenameAccountForm.DispatchProps]: any },
  RenameAccountForm.OwnProps,
  GlobalState
>(() => ({}), {
  checkAccountName: checkAccountName,
})(
  ReduxForm.reduxForm<RenameAccountForm.FormData, RenameAccountForm.Props>({
    form: "renameAccountForm",
    validate: memoizee(
      (formData: RenameAccountForm.FormData) => {
        return {
          name: userNameValidator(formData.name),
        };
      },
      { max: 10 },
    ),
    asyncValidate: asyncValidate,
    asyncBlurFields: ["name"],
  })(
    class RenameAccountForm extends React.PureComponent<RenameAccountForm.Props, RenameAccountForm.State> {
      render() {
        const { handleSubmit, error, submitting, className, asyncValidating, pristine, valid } = this.props;
        return (
          <form onSubmit={handleSubmit} className={getClassName(className)}>
            <FormField label="New user name">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
                name="name"
                props={{
                  fullWidth: true,
                  InputProps: {
                    endAdornment: asyncValidating && (
                      <InputAdornment position="end">
                        <FontAwesomeIcon icon="cog" spin />
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon="user" />
                      </InputAdornment>
                    ),
                  },
                }}
                component={MuiTextFieldRedux}
              />
            </FormField>
            <Alert transparent message={error} />
            <div className="form-group mt-5">
              <LoadingButton
                type="submit"
                color="secondary"
                disabled={pristine || !valid}
                onClick={handleSubmit}
                loading={submitting}
              >
                Rename account
              </LoadingButton>
            </div>
          </form>
        );
      }
    } as any,
  ),
);

export default RenameAccountForm;
