import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import type { Models } from "@triply/utils";
import { Markdown } from "#components/index.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import type { Story } from "#reducers/stories.ts";
import * as styles from "./style.scss";

interface Props {
  story: Story;
  className?: string;
  style?: React.CSSProperties;
}

const StoryWidget: React.FC<Props> = ({ story, className, style }) => {
  const constructUrlToApi = useConstructUrlToApi();
  const ref = React.useRef<HTMLDivElement>(null);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const storyLink = `/${story.owner.accountName}/-/stories/${story.name}`;

  React.useEffect(() => {
    setShowOverlay(!!ref.current?.scrollHeight && ref.current.scrollHeight > 150);
  }, [ref.current?.scrollHeight]);

  if (!story) return null;

  const content =
    (story.content.find((element) => element.type === "paragraph" && element.paragraph) as Models.StoryElementParagraph)
      ?.paragraph || "";

  return (
    <div className={getClassName(className, styles.storyWidget)} style={style}>
      <div className={styles.header}>
        {story.bannerUrl && (
          <picture className={styles.bannerImage}>
            <source
              srcSet={`${constructUrlToApi({ fullUrl: story.bannerUrl, fromBrowser: true })}&w=1200`}
              type="image/webp"
            />
            <source
              srcSet={`${constructUrlToApi({ fullUrl: story.bannerUrl, fromBrowser: true }).replace(
                "webp",
                "png",
              )}&w=1200`}
              type="image/png"
            />
            <img alt=""></img>
          </picture>
        )}
        <Link
          className={getClassName(styles.bannerContent, { [styles.customBanner]: !!story.bannerUrl })}
          to={storyLink}
        >
          <h2 className={styles.bannerLink}>{story.displayName || story.name}</h2>
        </Link>
      </div>

      {content && (
        <div ref={ref} className={getClassName(styles.widgetDescription)}>
          <Markdown compact>{content}</Markdown>
          {showOverlay && <div className={styles.overlay} />}
        </div>
      )}
      <div className={getClassName("p-3", styles.widgetFooter)}>
        <Link to={storyLink}>Read the full story</Link>
      </div>
    </div>
  );
};
export default StoryWidget;
