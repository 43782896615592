import type { JSX } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { SubmissionError } from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import { Alert, FlexContainer, Meta } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import { checkResetTokenValidity, resetPassword } from "#reducers/auth.ts";
import type { DispatchedFn, GlobalState } from "#reducers/index.ts";

export namespace ResetPassword {
  export interface OwnProps extends IComponentProps {}
  export interface DispatchProps {
    resetPassword: DispatchedFn<typeof resetPassword>;
    checkResetTokenValidity: DispatchedFn<typeof checkResetTokenValidity>;
  }
  export interface State {
    error?: string | JSX.Element;
    fetchingTokenInfo?: boolean;
  }
  export interface PropsFromState {}
  export type Props = OwnProps & DispatchProps & PropsFromState;
}

class ResetPassword extends React.PureComponent<ResetPassword.Props, ResetPassword.State> {
  constructor(props: ResetPassword.Props, context: any) {
    super(props);
    this.state = { error: undefined, fetchingTokenInfo: undefined };
  }
  /**
   * Check, checks if the token is already used or expired #
   */
  componentDidMount() {
    this.setState({ error: undefined, fetchingTokenInfo: true });
    this.props
      .checkResetTokenValidity(this.props.match.params.token || "")
      .then(() => {
        this.setState({ error: undefined, fetchingTokenInfo: false });
      })
      .catch((e) => {
        // We are only interested in 401 as those are the ones we have defined at the server
        if (e.status === 401) {
          // Token expired, or already used
          const customMessage = (
            <span>
              {e.message}. Please <Link to="/auth/resendpassword">click here</Link> to get a new token
            </span>
          );
          this.setState({ error: customMessage, fetchingTokenInfo: false });
        }
        // Else: ignore
      });
  }

  handleSubmit = (values: Forms.ResetPassword.FormData) => {
    return this.props.resetPassword({ token: this.props.match.params.token }, values.password).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      },
    );
  };
  render() {
    return (
      <FlexContainer innerStyle={{ display: "flex", justifyContent: "center" }}>
        <Meta currentPath={this.props.location.pathname} title="Set password" />
        {!this.state.fetchingTokenInfo && (
          <div className="whiteSink" style={{ width: "100%", maxWidth: this.state.error ? 600 : 300 }}>
            {this.state.error ? (
              <Alert transparent message={this.state.error} />
            ) : (
              <div>
                {" "}
                <h3 className="headerSpacing" style={{ display: "block", textAlign: "center" }}>
                  Set password
                </h3>
                <Forms.ResetPassword askForCurrentPw={false} onSubmit={this.handleSubmit} submitBehavior="lockForm" />
              </div>
            )}
          </div>
        )}
      </FlexContainer>
    );
  }
}

export default connect<
  ResetPassword.PropsFromState,
  { [K in keyof ResetPassword.DispatchProps]: any },
  ResetPassword.OwnProps,
  GlobalState
>(
  (_state, _ownProps) => {
    return {};
  },
  //dispatch
  {
    resetPassword,
    checkResetTokenValidity,
  },
)(ResetPassword as any);
