import Debug from "debug";
import React from "react";
import { useStore } from "react-redux";
import { Manager } from "socket.io-client";
import type { SocketEvent } from "@triply/utils/SocketEvents";
import type { WebSocketContextType } from "#context.ts";
import type { ErrorResponse } from "#helpers/ApiClient.ts";
import { getSocketToken, logout } from "#reducers/auth.ts";
import type { GlobalState } from "#reducers/index.ts";
import { serviceSocketEpics } from "#reducers/services.ts";
import { socketEventAsReduxAction } from "#reducers/socket.ts";
import type { Dispatch } from "./useDispatch.ts";
import useDispatch from "./useDispatch.ts";

const debug = Debug("triply:console:socket");

let manager: Manager;
function getManager(apiUrl: string) {
  if (!manager) {
    manager = new Manager(apiUrl, {
      transports: ["websocket"],
    })
      .on("reconnect", () => {
        console.error("socket manager: reconnect");
      })
      .on("reconnect_attempt", () => {
        console.error("socket manager: reconnect_attempt");
      })
      .on("reconnect_error", () => {
        console.error("socket manager: reconnect_error");
      });
  }
  return manager;
}

export type SocketEpic = (
  event: SocketEvent,
  getState: () => GlobalState,
  dispatch: Dispatch<(...args: any[]) => unknown>,
) => void;

const doNothing = { subscribe: () => {}, unsubscribe: () => {} } as const;

export default function (apiUrl: string, isLoggedIn: boolean) {
  if (!isLoggedIn) return doNothing; // #6163
  const dispatch = useDispatch();
  const { getState } = useStore<GlobalState>();

  const emitToReduxCb = React.useCallback(
    (event: SocketEvent) => {
      debug(`dispatching to redux (${event.eventType})`);
      dispatch(socketEventAsReduxAction(event));

      for (const socketEpic of serviceSocketEpics) {
        socketEpic(event, getState, dispatch);
      }
    },
    [dispatch, getState],
  );

  const subscribe = React.useCallback<WebSocketContextType>(
    (namespace, eventName, listener) => {
      const socket = getManager(apiUrl).socket(namespace, {
        auth: (cb) => {
          debug("authenticating");
          dispatch<typeof getSocketToken>(getSocketToken(namespace))
            .then((result) => {
              cb({ token: `Bearer ${result.body.token}` });
            })
            .catch((err: ErrorResponse) => {
              if (err.status === 401) {
                logout();
              } else {
                cb(err);
              }
            });
        },
      });

      if (socket.listeners("connect_error").length === 0) {
        socket.on("connect_error", (error: any) => {
          console.error("socket connect_error:", namespace, error);
        });
      }
      if (socket.listeners("connect").length === 0) {
        socket.on("connect", () => {
          console.info("socket connected:", namespace);
        });
      }
      if (socket.listeners("disconnect").length === 0) {
        socket.once("disconnect", (reason: any) => {
          console.error("socket disconnected:", namespace, reason);
        });
      }

      if (listener) {
        if (!socket.listeners(eventName).includes(listener as any)) {
          debug(`Registering listener for ${namespace} ${eventName}`);
          socket.on(eventName as string, listener);
        }
      } else if (!socket.listeners(eventName).includes(emitToReduxCb as any)) {
        debug(`Registering redux listener for ${namespace} ${eventName}`);
        socket.on(eventName as string, emitToReduxCb);
      }
    },
    [emitToReduxCb, dispatch, apiUrl],
  );

  const unsubscribe = React.useCallback<WebSocketContextType>(
    (namespace, eventName, listener) => {
      const socket = getManager(apiUrl).socket(namespace, {
        auth: (cb: Function) => {
          debug("authenticating");
          dispatch<typeof getSocketToken>(getSocketToken(namespace))
            .then((result) => {
              cb({ token: `Bearer ${result.body.token}` });
            })
            .catch((err: ErrorResponse) => {
              if (err.status === 401) {
                logout();
              } else {
                cb(err);
              }
            });
        },
      });

      if (listener) {
        if (socket.listeners(eventName).includes(listener as any)) {
          debug(`De-registering listener for ${namespace} ${eventName}`);
          socket.off(eventName as string, listener);
        }
      } else if (socket.listeners(eventName).includes(emitToReduxCb as any)) {
        debug(`De-registering redux listener for ${namespace} ${eventName}`);
        socket.off(eventName as string, emitToReduxCb);
      }
    },
    [emitToReduxCb, dispatch, apiUrl],
  );

  return {
    subscribe,
    unsubscribe,
  };
}
