import type { JSX } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import type { MatchedRoute } from "react-router-config";
import { Link } from "react-router-dom";
import { useCurrentAccount } from "#reducers/app.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import type { GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

interface Props {
  match: MatchedRoute<{
    account?: string;
    dataset?: string;
    query?: string;
    story?: string;
  }>["match"];
}

const BreadCrumbs: React.FC<Props> = ({ match }) => {
  const currentAccountName = useCurrentAccount()?.accountName;
  const currentDsName = useCurrentDataset()?.name;
  const currentQueryName = useSelector((state: GlobalState) => state.queries.current && state.queries.current.name);
  const currentStoryName = useSelector((state: GlobalState) => state.stories.current && state.stories.current.name);

  if (!currentAccountName) return null;

  const accountLink = `/${currentAccountName}`;
  const parts: JSX.Element[] = [];

  //first check for params.account. If we have a `currentAccountName` but no params.account,
  //we're probably at something like an `_admin` page, and we don't want to show the accountname
  if (match.params.account && currentAccountName) {
    parts.push(
      <Link key={accountLink} to={accountLink}>
        {currentAccountName}
      </Link>,
    );
  }

  if (match.params.dataset && currentDsName) {
    parts.push(
      <span key="dataset-separator" className={styles.separator}>
        /
      </span>,
    );
    const dsLink = `${accountLink}/${currentDsName}`;
    parts.push(
      <Link key={dsLink} to={dsLink}>
        {currentDsName}
      </Link>,
    );
  }

  if (match.path.indexOf("/:account/-/queries") === 0) {
    parts.push(
      <span key="queries-separator" className={styles.separator}>
        /
      </span>,
    );
    const queriesLink = `${accountLink}/-/queries`;
    parts.push(
      <Link key={queriesLink} to={queriesLink}>
        queries
      </Link>,
    );
  }

  if (match.params.query && currentQueryName) {
    parts.push(
      <span key="query-separator" className={styles.separator}>
        /
      </span>,
    );
    const queryLink = `${accountLink}/-/queries/${currentQueryName}`;
    parts.push(
      <Link key={queryLink} to={queryLink}>
        {currentQueryName}
      </Link>,
    );
  }

  if (match.path.indexOf("/:account/-/stories") === 0) {
    parts.push(
      <span key="stories-separator" className={styles.separator}>
        /
      </span>,
    );
    const storiesLink = `${accountLink}/-/stories`;
    parts.push(
      <Link key={storiesLink} to={storiesLink}>
        stories
      </Link>,
    );
  }

  if (match.params.story && currentStoryName) {
    parts.push(
      <span key="story-separator" className={styles.separator}>
        /
      </span>,
    );
    const storyLink = `${accountLink}/-/stories/${currentStoryName}`;
    parts.push(
      <Link key={storyLink} to={storyLink}>
        {currentStoryName}
      </Link>,
    );
  }

  return <div className={styles.breadCrumbs}>{...parts}</div>;
};

export default BreadCrumbs;
