import getClassName from "classnames";
import * as React from "react";
import type { Models } from "@triply/utils";
import { Svg } from "#components/index.ts";
import * as styles from "./style.scss";

interface Props {
  heightWidthPx: number;
  logo: string;
  logoBgType?: Models.LogoBackgroundType;
  logoBgColor?: string;
  showBorderLine?: boolean;
}

const SquareLogo: React.FC<Props> = ({ heightWidthPx, logo, logoBgType, logoBgColor, showBorderLine }) => {
  const logoCtrClassNames = {
    [styles.logoBg]: !!styles.logoBg,
    [styles.circleBg]: !!logoBgType && logoBgType === "circle",
    [styles.squareBg]: !!logoBgType && logoBgType === "square",
    [styles.noBg]: !!logoBgType && logoBgType === "none",
    [styles.showBorder]: showBorderLine && !!logoBgType && logoBgType !== "none",
  };

  let logoHeightWidthPx = heightWidthPx; //no bg
  if (logoBgType === "circle") logoHeightWidthPx = heightWidthPx * 0.7;
  if (logoBgType === "square") logoHeightWidthPx = heightWidthPx * 0.8;

  return (
    <div
      className={getClassName(logoCtrClassNames)}
      style={{
        height: heightWidthPx,
        width: heightWidthPx,
        backgroundColor: logoBgType === "none" ? "unset" : logoBgColor || "unset",
      }}
    >
      <Svg src={logo} alt={"logo"} imgStyle={{ height: logoHeightWidthPx, width: logoHeightWidthPx }} />
    </div>
  );
};

export default SquareLogo;
