import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { isLinkToDomain } from "#helpers/markup/unifiedPlugins.ts";
import { parseSearchString } from "#helpers/utils.ts";
import type { GlobalState } from "#reducers/index.ts";

function isLinkToDomainAndPathAndSearch(href: string, domain: string | undefined, pathname: string, search: string) {
  const baseUrl = "https://" + domain + pathname + search;
  try {
    const url = new URL(href, baseUrl);
    return url.hostname === domain && url.pathname === pathname && url.search === search;
  } catch {
    return false;
    // Probably a malformed URL.
  }
}

export default function useOpenLinkInCurrentWindow() {
  const location = useLocation();
  const embedded = parseSearchString(location.search).embed !== undefined;
  const consoleDomain = useSelector((state: GlobalState) => state.config.staticConfig?.consoleUrl.domain);
  if (embedded) {
    return (href: string) => isLinkToDomainAndPathAndSearch(href, consoleDomain, location.pathname, location.search);
  } else {
    return (href: string) => isLinkToDomain(href, consoleDomain);
  }
}
