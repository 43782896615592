import type { ChipProps } from "@mui/material";
import { Chip } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import MuiAutosuggest from "./AutoSuggest.tsx";
import * as styles from "./style.scss";

namespace MuiChip {
  export interface Props extends ChipProps {
    value: string;
    objectKey: string;
    handleRemove: (suggestionKey: string) => void;
  }
}
const MuiChip = React.memo<MuiChip.Props>((props) => {
  const { objectKey, value, handleRemove, ...rest } = props;
  const onDelete = () => handleRemove(objectKey);
  return <Chip {...rest} onDelete={onDelete} label={value} />;
});
namespace MuiAutosuggestMulti {
  export interface Props<S = string, V = string>
    extends Pick<
      MuiAutosuggest.Props<S, V>,
      "loadSuggestions" | "renderSuggestion" | "TextFieldProps" | "transformSuggestionToReduxValue"
    > {
    value: S[];
    getDisplayValueFromSuggestion: (suggestion: S) => string;
    getKeyFromFromSuggestion: (suggestion: S) => string;
    onChange: (values: S[]) => void;
    onBlur?: (event: React.SyntheticEvent) => void;
    placeholder?: string;
    label?: string;
    className?: string;
  }
  export interface State {}
}
class MuiAutosuggestMulti<S = string, V = string> extends React.PureComponent<
  MuiAutosuggestMulti.Props<S, V>,
  MuiAutosuggestMulti.State
> {
  handleRemove = (recordKey: string) => {
    this.props.onChange(this.props.value.filter((r) => this.props.getKeyFromFromSuggestion(r) !== recordKey));
  };

  handleSuggestionSelection = (record: S) => {
    const value = this.props.value || [];
    this.props.onChange([...value, record]);
  };

  //We want to filter out currently selected values from the suggestions
  filteredLoadSuggestions = (searchString: string): Promise<S[]> => {
    return this.props.loadSuggestions(searchString).then((suggestions) => {
      return suggestions.filter((suggestion) => {
        const currentKey = this.props.getKeyFromFromSuggestion(suggestion);
        return !this.props.value.find((current) => this.props.getKeyFromFromSuggestion(current) === currentKey);
      });
    });
  };
  render() {
    const { value, placeholder, label, className, renderSuggestion, TextFieldProps } = this.props;
    const autosuggestProps: MuiAutosuggest.Props<S, V> = {
      placeholder: placeholder,
      label: label,
      TextFieldProps: TextFieldProps,
      loadSuggestions: this.filteredLoadSuggestions,
      getSuggestionSearchText: this.props.getDisplayValueFromSuggestion,
      renderSuggestion: renderSuggestion,
      clearOnSelect: true,
      onSuggestionSelected: (_e, suggestion) => this.handleSuggestionSelection(suggestion.suggestion),
    };
    return (
      <div className={getClassName(className)}>
        <div className={styles.chips}>
          {value &&
            value.map((value) => {
              return (
                <MuiChip
                  key={`chip-${this.props.getKeyFromFromSuggestion(value)}`}
                  objectKey={this.props.getKeyFromFromSuggestion(value)}
                  value={this.props.getDisplayValueFromSuggestion(value)}
                  handleRemove={this.handleRemove}
                />
              );
            })}
        </div>
        <MuiAutosuggest {...autosuggestProps} />
      </div>
    );
  }
}

export default MuiAutosuggestMulti;
