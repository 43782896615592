import { produce } from "immer";
import type { Models, Routes } from "@triply/utils";
import type { Action, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";
import { Actions } from "#reducers/index.ts";

export const LocalActions = {
  GET_TOPICS: "triply/topics/GET_TOPICS",
  GET_TOPICS_SUCCESS: "triply/topics/GET_TOPICS_SUCCESS",
  GET_TOPICS_FAIL: "triply/topics/GET_TOPICS_FAIL",
} as const;

type GET_TOPICS = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_TOPICS,
      typeof LocalActions.GET_TOPICS_SUCCESS,
      typeof LocalActions.GET_TOPICS_FAIL,
    ];
  },
  Routes.topics.Get
>;

export type LocalAction = GET_TOPICS;

export type Topic = Models.Topic;

export interface State {
  list?: Topic[];
}

export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.GET_TOPICS_SUCCESS:
        draftState.list = action.result;
        return;
    }
  },
  {
    list: undefined,
  } as State,
);

export function getTopics(query?: Routes.topics.Get["Req"]["Query"]): BeforeDispatch<GET_TOPICS> {
  query = {
    // request 10k topics unless the query object specifies something else.
    limit: 10_000,
    ...(query ? query : {}),
  };
  return {
    types: [Actions.GET_TOPICS, Actions.GET_TOPICS_SUCCESS, Actions.GET_TOPICS_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/topics",
        method: "get",
        query: query,
      }),
  };
}

export function areTopicsLoaded(state: GlobalState) {
  return !!state.topics.list;
}
