import { Divider } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import * as styles from "./styles.scss";

const ForwardedDragHandle: React.FC<{
  handleAxis?: string;
  dragging?: boolean;
  onDoubleClick?: React.MouseEventHandler;
}> = ({ handleAxis, dragging, ...props }) => {
  return (
    <div
      {...props}
      className={getClassName(styles.dragHandle, `handle-${handleAxis}`, { [styles.dragging]: dragging })}
      aria-hidden
    >
      <br />
      <Divider></Divider>
    </div>
  );
};

export default ForwardedDragHandle;
