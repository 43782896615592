// extracted by mini-css-extract-plugin
var _1 = "styles--cell--NCJ4mfjb";
var _2 = "styles--cellContent--ujJpm3i6";
var _3 = "styles--dragHandle--RPOCuf_Z";
var _4 = "styles--footer--JqDgN4Uq";
var _5 = "styles--header--jSzFA413";
var _6 = "styles--headerButton--LOig0a62";
var _7 = "styles--headerCell--MgNy63LB";
var _8 = "styles--hidePagination--sSGtJ2yR";
var _9 = "styles--hidePaginationPerRows--E7EZJL6E";
var _a = "styles--isDragging--G2k2vSau";
var _b = "styles--prefixTooltip--S2KiAgGk";
var _c = "styles--table--Duw5imtW";
var _d = "styles--tableContainer--vbWzE_qO";
var _e = "styles--tableDrag--sH6Hw5vY";
export { _1 as "cell", _2 as "cellContent", _3 as "dragHandle", _4 as "footer", _5 as "header", _6 as "headerButton", _7 as "headerCell", _8 as "hidePagination", _9 as "hidePaginationPerRows", _a as "isDragging", _b as "prefixTooltip", _c as "table", _d as "tableContainer", _e as "tableDrag" }
