import { Alert } from "@mui/material";
import getClassName from "classnames";
import { push } from "connected-react-router";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { asyncConnect } from "redux-connect";
import { Button, DatasetMetadata, ErrorPage, FlexContainer, SinkList } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { getCurrentAccount, useCurrentAccount } from "#reducers/app.ts";
import { getCurrentDataset, useCurrentDataset } from "#reducers/datasetManagement.ts";
import type { GlobalState } from "#reducers/index.ts";
import {
  classFrequencyIsLoadedFor,
  classFrequencyIsOutdatedFor,
  getClassFrequency,
  getClassFrequencyFor,
} from "#reducers/insights.ts";
import { showNotification } from "#reducers/notifications.ts";
import BarChart from "./barChart.tsx";
import GraphSelector from "./GraphSelector.tsx";
import * as styles from "./style.scss";

const ClassFrequency: React.FC<IComponentProps> = (props) => {
  const dispatch = useDispatch();
  const constructUrlToApi = useConstructUrlToApi();
  const query = parseSearchString(props.location.search);
  const selectedGraph = typeof query.graph === "string" ? query.graph : undefined;

  const currentDs = useCurrentDataset();
  const currentAccount = useCurrentAccount();
  const prefixes = useDatasetPrefixes();
  const classFrequency = useSelector(
    (state: GlobalState) =>
      (currentDs?.id && selectedGraph && getClassFrequencyFor(state, currentDs.id, selectedGraph)) || undefined,
  );
  const largestGraph = useSelector(
    (state: GlobalState) => currentDs?.id && state.graphs[currentDs.id].largestList[0]?.graphName,
  );
  const classFrequencyIsOutdated = useSelector(
    (state: GlobalState) =>
      currentDs?.id && !!selectedGraph && classFrequencyIsOutdatedFor(state, currentDs.id, selectedGraph),
  );
  React.useEffect(() => {
    if (classFrequencyIsOutdated && currentDs?.id && currentAccount?.accountName && selectedGraph) {
      dispatch<typeof getClassFrequency>(
        getClassFrequency({
          datasetId: currentDs.id,
          datasetPath: `${currentAccount.accountName}/${currentDs.name}`,
          graphName: selectedGraph,
          lastGraphsUpdateTime: currentDs.lastGraphsUpdateTime,
        }),
      )
        .then(() => dispatch(showNotification("The data was reloaded after a change in the dataset.", "info")))
        .catch(() => {});
    }
    // Fetch class frequency again when it is outdated (when a job finishes).
  }, [
    classFrequencyIsOutdated,
    currentAccount?.accountName,
    currentDs?.id,
    currentDs?.lastGraphsUpdateTime,
    currentDs?.name,
    dispatch,
    selectedGraph,
  ]);

  const loadNextPage = React.useCallback(() => {
    if (currentDs?.id && currentAccount?.accountName && classFrequency?.nextPage && selectedGraph) {
      dispatch(
        getClassFrequency({
          datasetId: currentDs.id,
          datasetPath: `${currentAccount.accountName}/${currentDs.name}`,
          graphName: selectedGraph,
          lastGraphsUpdateTime: currentDs.lastGraphsUpdateTime,
          withLink: classFrequency?.nextPage,
        }),
      );
    }
  }, [
    classFrequency?.nextPage,
    currentAccount?.accountName,
    currentDs?.id,
    currentDs?.lastGraphsUpdateTime,
    currentDs?.name,
    dispatch,
    selectedGraph,
  ]);

  if (!currentAccount || !currentDs) {
    return <ErrorPage statusCode={404} />;
  }

  if (!selectedGraph)
    return (
      <Redirect
        to={{
          pathname: `/${currentAccount.accountName}/${currentDs.name}/insights/classFrequency`,
          search: stringifyQuery({ graph: largestGraph }),
        }}
      />
    );

  if (!classFrequency?.data) {
    return <ErrorPage statusCode={404} message={`No data found for graph '${query.graph}'.`} />;
  }

  return (
    <FlexContainer>
      <DatasetMetadata
        currentPath={props.location.pathname}
        currentAccount={currentAccount}
        currentDs={currentDs}
        title="Class frequency"
      />
      <Alert className="my-5 shadow" severity="info">
        The class frequency diagram shows how often classes and properties appear in a graph. Check{" "}
        <a
          className={styles.alertLink}
          href="https://docs.triply.cc/triply-db-getting-started/viewing-data/#class-frequency"
          target="_blank"
        >
          our documentation
        </a>{" "}
        to learn more.
      </Alert>

      <Alert severity="warning" className="my-5 shadow">
        This visualization is being moved to{" "}
        <Link to={`/${currentAccount.accountName}/${currentDs.name}/graphs`} className={styles.alertLink}>
          the graphs table
        </Link>
      </Alert>

      <SinkList>
        <div className={getClassName(styles.item, styles.barChartItem)}>
          <GraphSelector
            termsUrl={constructUrlToApi({
              pathname: `/datasets/${currentAccount.accountName}/${currentDs.name}/terms`,
            })}
            selectGraph={(graph) => {
              dispatch(push({ pathname: props.location.pathname, search: stringifyQuery({ graph: graph }) }));
            }}
            prefixes={prefixes}
            selectedGraph={selectedGraph}
          />
          {classFrequency.data.length === 0 ? (
            <Alert variant="filled" severity="warning">
              The class frequency cannot be visualized because it is too large.
            </Alert>
          ) : (
            <>
              <BarChart key={selectedGraph} data={classFrequency.data} prefixes={prefixes} />
              {classFrequency.nextPage && (
                <div className={styles.showMore}>
                  <Button variant="text" size="small" onClick={loadNextPage}>
                    Show more...
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </SinkList>
    </FlexContainer>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ store: { dispatch, getState }, location }) => {
      const query = parseSearchString(location.search);
      const state = getState();
      const currentDs = getCurrentDataset(state);
      const currentAccount = getCurrentAccount(state);
      if (
        currentDs &&
        currentAccount &&
        typeof query.graph === "string" &&
        currentDs.graphCount > 0 &&
        !classFrequencyIsLoadedFor(state, currentDs.id, query.graph)
      ) {
        return dispatch<any>(
          getClassFrequency({
            datasetId: currentDs.id,
            datasetPath: `${currentAccount.accountName}/${currentDs.name}`,
            graphName: query.graph,
            lastGraphsUpdateTime: currentDs.lastGraphsUpdateTime,
          }),
        );
      }
    },
  },
])(ClassFrequency) as typeof ClassFrequency;
