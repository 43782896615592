import { Masonry } from "@mui/lab";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { asyncConnect } from "redux-connect";
import { DatasetWidget, FlexContainer, QueryWidget, StoryWidget } from "#components/index.ts";
import { getLoggedInUser, useAuthenticatedUser } from "#reducers/auth.ts";
import { getFrontPageInfo } from "#reducers/config.ts";
import { getDatasetsForAccount, isDatasetsListLoadedForUser } from "#reducers/datasetManagement.ts";
import type { GlobalState } from "#reducers/index.ts";
import { getQueries, needToFetchQueries } from "#reducers/queries.ts";
import { getStories, needToFetchStories } from "#reducers/stories.ts";
import { areTopicsLoaded, getTopics } from "#reducers/topics.ts";
import Banner from "./Banner.tsx";
import Meta from "./Meta.tsx";
import SideDatasets from "./SideDatasets.tsx";
import SideOrgs from "./SideOrgs.tsx";
import SideQueries from "./SideQueries.tsx";
import SideStories from "./SideStories.tsx";
import * as styles from "./style.scss";

interface Props {
  setNavBarClass: (s: string) => void;
}

const ConsoleMainPage: React.FC<Props> = ({ setNavBarClass }) => {
  React.useEffect(() => {
    if (__SERVER__) return;
    const onScroll = () => {
      setNavBarClass(window.scrollY === 0 ? "" : "belowHeader");
    };
    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [setNavBarClass]);

  const frontpage = useSelector((state: GlobalState) => state.config.frontpage);
  const assumingMobile = useSelector((state: GlobalState) => state.app.assumingMobile);
  const user = useAuthenticatedUser();
  return (
    <>
      <Meta />
      <Banner />
      <FlexContainer className="mt-3" innerClassName={styles.wrapper}>
        <div className={getClassName("my-3 flex", styles.content, styles.masonryWrapper)}>
          {!!frontpage && !!frontpage.items.length && (
            <Masonry
              defaultColumns={assumingMobile ? 1 : 2}
              columns={{
                xs: 1,
                md: user || frontpage.items.length === 1 ? 1 : 2,
                lg: frontpage.items.length === 1 ? 1 : 2,
              }}
              spacing={2}
              className={getClassName(styles.masonry)}
              defaultHeight={2_000}
              defaultSpacing={2}
            >
              {frontpage.items.map((item) => {
                if (item.type === "Dataset") {
                  return (
                    <DatasetWidget
                      key={`ds-${item.item.id}`}
                      ds={item.item}
                      datasetResourceDescriptions={item.descriptions}
                      className={styles.example}
                      showOwnerName
                      headerSize={user ? "md" : "lg"}
                    />
                  );
                } else if (item.type === "Query") {
                  return <QueryWidget key={`qry-${item.item.id}`} query={item.item} className={styles.example} />;
                } else if (item.type === "Story") {
                  return <StoryWidget key={`stry-${item.item.id}`} story={item.item} className={styles.example} />;
                }
              })}
            </Masonry>
          )}

          <div className={getClassName("mx-2", styles.yourStuff)}>
            <SideOrgs />
            <SideDatasets />
            <SideStories />
            <SideQueries />
          </div>
        </div>
      </FlexContainer>
    </>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ store: { dispatch, getState } }) => {
      if (!areTopicsLoaded(getState())) {
        return dispatch<any>(getTopics());
      }
    },
  },
  {
    promise: ({ store: { dispatch, getState } }) => {
      if (!getState().config.frontpage) return dispatch<any>(getFrontPageInfo());
    },
  },
  {
    promise: ({ store: { dispatch, getState } }) => {
      // When a user is created with OAuth it is possible that the auth user has no userName yet
      const loggedInUser = getLoggedInUser(getState());
      if (loggedInUser?.accountName && !isDatasetsListLoadedForUser(getState(), loggedInUser)) {
        return dispatch<any>(getDatasetsForAccount(loggedInUser));
      }
    },
  },
  {
    promise: ({ store: { dispatch, getState } }) => {
      // When a user is created with OAuth it is possible that the auth user has no userName yet
      const loggedInUser = getLoggedInUser(getState());
      if (loggedInUser?.accountName && needToFetchQueries(getState(), loggedInUser)) {
        return dispatch<any>(getQueries(loggedInUser));
      }
    },
  },
  {
    promise: ({ store: { dispatch, getState } }) => {
      // When a user is created with OAuth it is possible that the auth user has no userName yet
      const loggedInUser = getLoggedInUser(getState());
      if (loggedInUser?.accountName && needToFetchStories(getState(), loggedInUser)) {
        return dispatch<any>(getStories(loggedInUser));
      }
    },
  },
])(ConsoleMainPage);
