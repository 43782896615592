import { deleteTrailingWhitespace, indentSelection, selectAll } from "@codemirror/commands";
import { getIndentation, IndentContext, syntaxTree } from "@codemirror/language";
import type { ChangeSpec, StateCommand } from "@codemirror/state";

/**
 * Auto formats the query. This happens with 3 passes of the query
 * 1) First pass remove all trailing whitespace, this allows us to make some assumptions in the following step
 * 2) During the second pass, line-breaks with the correct indent are added when expected.
 * 3) Select and apply the indentation to the full query.
 */
const autoFormat: StateCommand = function ({ state, dispatch }) {
  const addNewLine = function (
    simulatedBreakAt: number,
    addedChanges: ChangeSpec[],
    nodeFrom: number,
    nodeTo: number | undefined = undefined,
  ) {
    const cx = new IndentContext(state, { simulateBreak: simulatedBreakAt }); // simulates the linebreak
    const indent = getIndentation(cx, nodeFrom);
    addedChanges.push({
      from: nodeFrom,
      to: nodeTo,
      insert: "\n" + " ".repeat(indent || 0),
    });
  };
  const tree = syntaxTree(state);
  const cursor = tree.cursor();
  const formatChanges: ChangeSpec[] = [];
  const originalState = state;
  // Remove trailing whitespace
  deleteTrailingWhitespace({
    state: state,
    dispatch: (transaction) => {
      formatChanges.push(transaction.changes);
      state.update(transaction);
    },
  });
  // As we just removed all trailing whitespace, we can assume that everything that should have a line-break doesn't have any extra whitespace.
  while (cursor.next(true)) {
    // Since we're only replacing words, we shouldn't have overlap with the newLine changes
    if (cursor.name === "KEYWORD") {
      const keyword = state.sliceDoc(cursor.from, cursor.to);
      const lowerCased = keyword.toLowerCase();
      if (keyword !== lowerCased) {
        formatChanges.push({
          from: cursor.from,
          to: cursor.to,
          insert: lowerCased,
        });
      }
    }
    // We want to deeply enter all nodes (e.g. finding the '{' '}' '.' ',' etc tokens)
    if (cursor.name === "{") {
      const nextString = state.sliceDoc(cursor.to, cursor.to + 1);

      if (nextString !== "\n") {
        addNewLine(cursor.to, formatChanges, cursor.to, cursor.node.nextSibling?.from);
      }
    } else if (cursor.name === "}") {
      const prevString = state.sliceDoc(cursor.node.prevSibling?.to, cursor.from);
      if (prevString.indexOf("\n") === -1) {
        addNewLine(cursor.from, formatChanges, cursor.node.prevSibling!.to, cursor.from);
      }
    } else if (cursor.name === ".") {
      if (cursor.node.nextSibling && state.sliceDoc(cursor.to, cursor.node.nextSibling.from).indexOf("\n") === -1) {
        addNewLine(cursor.to, formatChanges, cursor.to, cursor.node.nextSibling.from);
      }
    } else if (
      cursor.name === ";" &&
      (cursor.node.parent?.name === "PropertyListPathNotEmpty" ||
        cursor.node.parent?.name === "PropertyListNotEmpty") &&
      cursor.node.nextSibling
    ) {
      if (state.sliceDoc(cursor.to, cursor.node.nextSibling.from).indexOf("\n") === -1) {
        addNewLine(cursor.to, formatChanges, cursor.to, cursor.node.nextSibling.from);
      }
    } else if (
      cursor.name === "," &&
      (cursor.node.parent?.name === "ObjectListPath" || cursor.node.parent?.name === "ObjectList") &&
      cursor.node.nextSibling
    ) {
      if (state.sliceDoc(cursor.to, cursor.node.nextSibling.from).indexOf("\n") === -1) {
        addNewLine(cursor.to, formatChanges, cursor.to, cursor.node.nextSibling.from);
      }
    } else if (cursor.name === "GraphPatternNotTriples") {
      if (cursor.node.prevSibling && state.sliceDoc(cursor.node.prevSibling.to, cursor.from).indexOf("\n") === -1) {
        addNewLine(cursor.node.prevSibling.to, formatChanges, cursor.node.prevSibling.to, cursor.from);
      }
      if (
        cursor.node.nextSibling?.name === "TriplesBlock" &&
        state.sliceDoc(cursor.to, cursor.node.nextSibling.from).indexOf("\n") === -1
      ) {
        addNewLine(cursor.to, formatChanges, cursor.to, cursor.node.nextSibling.from);
      }
    }
  }
  // Locally perform the update, so that the indentation changes don't overlap
  state = originalState.update({ changes: formatChanges, userEvent: "format" }).state;
  selectAll({
    state: state,
    dispatch: (transaction) => {
      // We don't want to change the selection.
      // However, we do need to change it for CM's indent function
      indentSelection({
        state: transaction.state,
        dispatch: (indentTransaction) => {
          // Now dispatching the action
          dispatch(
            originalState.update(
              // First remove the all extra whitespace and apply the line-breaks
              { changes: formatChanges, userEvent: "format" },
              // Then apply the indentation (sequential to let CM know it should play the transactions after each other)
              { changes: indentTransaction.changes, userEvent: "format", sequential: true },
            ),
          );
        },
      });
    },
  });
  return formatChanges.length > 0;
};

export default autoFormat;
