import { IconButton, TableFooter as MuiTableFooter, TablePagination, TableRow } from "@mui/material";
import type { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions.ts";
import getClassName from "classnames";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";
import * as tableStyles from "./tableStyle.scss";

export interface PaginationProps {
  rowCount: number;
  pageSize: number;
  currentPage: number;
  onChangePage: (newPage: number) => void;
  onChangeRowsPerPage: (newSize: number) => void;
  className?: string;
}

export const TablePaginationActions: React.FC<TablePaginationActionsProps> = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const lastPage = Math.ceil(count / rowsPerPage) - 1;
  const pageButtons = [
    <IconButton
      key="firstPage"
      title="First page"
      onClick={(event) => onPageChange(event, 0)}
      disabled={page === 0}
      aria-label="First page"
      size="large"
    >
      <FontAwesomeIcon size="sm" icon="chevron-double-left" />
    </IconButton>,
    <IconButton
      title="Previous page"
      aria-label="Previous page"
      key="previousPage"
      onClick={(event) => onPageChange(event, page - 1)}
      disabled={page === 0}
      size="large"
    >
      <FontAwesomeIcon size="sm" icon="chevron-left" />
    </IconButton>,
    <IconButton
      title="Next page"
      aria-label="Next page"
      key="nextPage"
      onClick={(event) => onPageChange(event, page + 1)}
      disabled={page >= lastPage || rowsPerPage === -1}
      size="large"
    >
      <FontAwesomeIcon size="sm" icon="chevron-right" />
    </IconButton>,
    <IconButton
      title="Last page"
      aria-label="Last page"
      key="lastPage"
      onClick={(event) => onPageChange(event, lastPage)}
      disabled={page >= lastPage || rowsPerPage === -1}
      size="large"
    >
      <FontAwesomeIcon size="sm" icon="chevron-double-right" />
    </IconButton>,
  ];
  return <div className={tableStyles.paginationOptions}>{pageButtons}</div>;
};

const TableFooter: React.FC<PaginationProps> = (props) => {
  return (
    <MuiTableFooter className={getClassName(props.className)}>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          count={props.rowCount}
          rowsPerPage={props.pageSize}
          page={props.currentPage}
          onPageChange={(_event, newPage) => props.onChangePage(newPage)}
          onRowsPerPageChange={(event) => props.onChangeRowsPerPage(parseInt(event.target.value))}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </MuiTableFooter>
  );
};

export default TableFooter;
