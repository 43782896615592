import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { SubmissionError } from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import { Avatar, Dialog, ListWidget } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { createDataset, useDatasetList } from "#reducers/datasetManagement.ts";
import type { GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

interface Props {}
interface LocationState {
  datasetAddModalShown?: boolean;
  preserveScrollPosition?: boolean;
}
const SideDatasets: React.FC<Props> = ({}) => {
  const authenticatedUser = useAuthenticatedUser();
  const fetchingList = useSelector((state: GlobalState) => state.datasetManagement.fetchingList);
  const datasets = useDatasetList({
    account: authenticatedUser?.accountName || false,
    admin: false,
    searchTerm: false,
  });
  const { push, goBack, replace } = useHistory<LocationState>();
  const { state: locationState } = useLocation<LocationState>();
  const dispatch = useDispatch();
  const acl = useAcl();
  if (!authenticatedUser || !datasets) return null;

  const handleDatasetAdd = (data: Forms.DatasetAdd.FormData) => {
    if (!authenticatedUser) return;
    return dispatch<typeof createDataset>(
      createDataset(authenticatedUser.accountName, data.name, data.accessLevel, data.description, data.displayName),
    )
      .then(({ body: ds }) => replace(`/${ds.owner.accountName}/${ds.name}/graphs`))
      .catch((e) => {
        if (e?.status == 409) {
          throw new SubmissionError({ name: "URL is already in use." });
        }
        throw new SubmissionError({ _error: e.message });
      });
  };

  const openDialog = () => push({ state: { datasetAddModalShown: true, preserveScrollPosition: true } });

  const userAllowedToCreateDs = acl.check({
    action: "createDataset",
    context: { roleInOwnerAccount: acl.getRoleInAccount(authenticatedUser), accessLevel: "public" },
  }).granted;

  return (
    <>
      <ListWidget
        key="yourDatasets"
        title="Your datasets"
        linkTo={`/${authenticatedUser.accountName}/-/datasets`}
        noContentMsg={fetchingList ? undefined : "You don't have any datasets"}
        onAddItem={userAllowedToCreateDs ? openDialog : undefined}
        addItemTitle="Add dataset"
        showMoreLink={datasets.length > 10 ? `/${authenticatedUser.accountName}/-/datasets` : undefined}
      >
        {datasets.slice(0, 10).map((ds) => (
          <div key={ds.id}>
            <Link
              to={`/${authenticatedUser.accountName}/${ds.name}`}
              title={ds.displayName || ds.name}
              className={getClassName("flex center noLinkDecoration px-3 py-2", styles.listItem)}
            >
              <Avatar
                size="sm"
                avatarName={ds.displayName || ds.name}
                avatarUrl={ds.avatarUrl}
                className="mr-3"
                alt=""
              />
              <div className={styles.itemTitle}>{ds.displayName || ds.name}</div>
            </Link>
          </div>
        ))}
      </ListWidget>
      {authenticatedUser && userAllowedToCreateDs && (
        <Dialog
          open={!!locationState?.datasetAddModalShown}
          onClose={goBack}
          maxWidth="lg"
          fullWidth
          title="Add dataset"
        >
          <Forms.DatasetAdd
            className="p-5"
            initialValues={{ accessLevel: "private" }}
            onSubmit={handleDatasetAdd}
            cancel={goBack}
            currentAccount={authenticatedUser}
          />
        </Dialog>
      )}
    </>
  );
};

export default SideDatasets;
