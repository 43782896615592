import type { Models, Routes } from "@triply/utils";
import type { Account } from "#reducers/accountCollection.ts";
import type { Dataset } from "#reducers/datasetManagement.ts";
import type { BeforeDispatch, GlobalAction } from "#reducers/index.ts";
import { Actions } from "#reducers/index.ts";

export const LocalActions = {
  ADD_IMPORTS: "triply/imports/ADD_IMPORTS",
  ADD_IMPORTS_SUCCESS: "triply/imports/ADD_IMPORTS_SUCCESS",
  ADD_IMPORTS_FAIL: "triply/imports/ADD_IMPORTS_FAIL",
  GET_GRAPHS_IMPORT: "triply/imports/GET_GRAPHS_IMPORT",
  GET_GRAPHS_IMPORT_SUCCESS: "triply/imports/GET_GRAPHS_IMPORT_SUCCESS",
  GET_GRAPHS_IMPORT_FAIL: "triply/imports/GET_GRAPHS_IMPORT_FAIL",
} as const;

type ADD_IMPORTS = GlobalAction<
  {
    types: [
      typeof LocalActions.ADD_IMPORTS,
      typeof LocalActions.ADD_IMPORTS_SUCCESS,
      typeof LocalActions.ADD_IMPORTS_FAIL,
    ];
    forDataset: Dataset;
    forAccount: Account;
    imports: Models.UpdateImports;
  },
  Routes.datasets._account._dataset.imports.Patch
>;

type GET_GRAPHS_IMPORT = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_GRAPHS_IMPORT,
      typeof LocalActions.GET_GRAPHS_IMPORT_SUCCESS,
      typeof LocalActions.GET_GRAPHS_IMPORT_FAIL,
    ];
  },
  Routes.datasets._account._dataset.graphs.Get
>;

export type LocalAction = ADD_IMPORTS | GET_GRAPHS_IMPORT;

export function addImports(
  forAccount: Account,
  forDataset: Dataset,
  imports: Models.UpdateImports,
): BeforeDispatch<ADD_IMPORTS> {
  return {
    types: [Actions.ADD_IMPORTS, Actions.ADD_IMPORTS_SUCCESS, Actions.ADD_IMPORTS_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forAccount.accountName}/${forDataset.name}/imports`,
        method: "patch",
        body: imports,
      }),
    forDataset,
    forAccount,
    imports,
  };
}

export function getGraphsFor(dataset: Dataset): BeforeDispatch<GET_GRAPHS_IMPORT> {
  return {
    types: [Actions.GET_GRAPHS_IMPORT, Actions.GET_GRAPHS_IMPORT_SUCCESS, Actions.GET_GRAPHS_IMPORT_FAIL],
    promise: (client) => {
      return client.req({
        pathname: `/datasets/${dataset.owner.accountName}/${dataset.name}/graphs`,
        method: "get",
      });
    },
  };
}
