import { Alert, Autocomplete, ListItem, Skeleton, TextField, ThemeProvider, useTheme } from "@mui/material";
import { cloneDeep, merge } from "lodash-es";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { v4 as uuid } from "uuid";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { substringMatch } from "#components/Highlight/index.tsx";
import { FormField, Highlight, Prompt } from "#components/index.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { validateIri } from "./helpers.ts";
import localTheme from "./Theme";
import type { ClassData } from "./Types";

const factory = factories.compliant;

const ClassForm: React.FC<{
  onSubmit: (values: ClassData) => Promise<void>;
  datasetUrl: string;
  onDirty: (dirty: boolean) => void;
  initialValues?: Partial<ClassData>;
}> = ({ onSubmit, datasetUrl, onDirty, initialValues }) => {
  const theme = useTheme();
  const currentClass = useCurrentResource();
  const location = useLocation();

  const { data: classes } = useSparql<{ id: string; label: string; description?: string }[]>(`
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix owl: <http://www.w3.org/2002/07/owl#>
    prefix sh: <http://www.w3.org/ns/shacl#>
    prefix triply: <https://triplydb.com/Triply/function/>

    select ?id (triply:firstLabel(?id) as ?label) (sample(?description_t) as ?description) where {
      {
        {
          select distinct ?id where {
            {
              ?id a rdfs:Class
            } union {
              ?id a owl:Class
            } union {
              ?id rdfs:subClassOf|^rdfs:subClassOf []
            } union {
              [] sh:targetClass ?class
            }
            filter (!regex(str(?id), "\.well-known/genid"))
            ${
              initialValues
                ? `
            filter not exists {
              ?id rdfs:subClassOf* ${termToString(factory.namedNode(currentClass))}
            }
              `
                : ""
            }

          }
        }
        optional {
          ?id rdfs:comment ?description_t
          filter(langMatches(lang(?description_t), "nl"))
        }
        optional {
          ?id rdfs:comment ?description_t
          filter(langMatches(lang(?description_t), "en"))
        }
        optional {
          ?id rdfs:comment ?description_t
        }
      }
    }
    group by ?id
    order by (!bound(?label)) lcase(?label)
    `);

  const applyPrefixes = useApplyPrefixes();
  const removePrefixes = useRemovePrefixes();
  const prefixes = useDatasetPrefixes();
  const baseIri = prefixes.find((prefix) => prefix.prefixLabel === "def")?.iri || `${datasetUrl}/def/`;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    setError,
  } = useForm<ClassData>({
    mode: "onChange",
    defaultValues: initialValues
      ? {
          ...initialValues,
          iri: initialValues.iri ? applyPrefixes(initialValues.iri) : applyPrefixes(`${baseIri}c-${uuid()}`),
        }
      : {
          iri: applyPrefixes(`${baseIri}c-${uuid()}`),
        },
  });

  const submit = async (values: ClassData) => {
    try {
      await onSubmit(values);
    } catch (e) {
      console.error(e);
      setError("root.serverError", {
        type: "500",
      });
    }
  };

  React.useEffect(() => {
    onDirty?.(isDirty);
  }, [onDirty, isDirty]);

  if (!classes) {
    return <Skeleton variant="rectangular" width={860} height={175} />;
  }
  return (
    <ThemeProvider theme={merge(cloneDeep(localTheme), theme)}>
      <form method="POST" onSubmit={handleSubmit(submit)} className="flex column g-7">
        <Prompt
          when={isDirty}
          message={(newState) => {
            //dont want the prompt to show up when only changing the location state
            //Otherwise, drawing the modal would trigger it
            if (location.pathname === newState.pathname) return true;
            return "Your changes have not been saved. Are you sure you want to continue?";
          }}
        />
        <FormField label="Label" required>
          <Controller
            name="label"
            control={control}
            defaultValue=""
            rules={{
              validate: (value) => {
                if (!value.trim()) return "A label is required.";
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                required
                autoFocus
              />
            )}
          />
        </FormField>

        <FormField label="Class IRI" required>
          <Controller
            name="iri"
            control={control}
            rules={{
              validate: (value) => validateIri(removePrefixes(value?.trim())),
            }}
            render={({ field, fieldState }) => {
              return (
                <>
                  <TextField {...field} error={!!fieldState.error} required helperText={fieldState.error?.message} />
                  {initialValues?.iri && fieldState.isDirty && (
                    <Alert className="mt-2" severity="warning">
                      Changes to the class IRI will only be applied in the data model. Instances will not be migrated.
                    </Alert>
                  )}
                </>
              );
            }}
          />
        </FormField>

        <FormField label="Description">
          <Controller
            name="description"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                minRows={3}
                multiline
              />
            )}
          />
        </FormField>
        <FormField label="Order" helperText="The order in which this class will be shown in the class navigator">
          <Controller
            name="order"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="number"
                inputMode="decimal"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormField>

        <FormField label="Superclass">
          <Controller
            name="parent"
            control={control}
            rules={{
              validate: (value) =>
                !!value
                  ? typeof value === "string"
                    ? validateIri(removePrefixes(value.trim()))
                    : validateIri(removePrefixes(value.id))
                  : true,
            }}
            defaultValue={(!initialValues && classes.find((c) => c.id === currentClass)) || null}
            render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
              <Autocomplete
                options={classes}
                onChange={(_e, data) => {
                  return onChange(data);
                }}
                freeSolo
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...(params as any)}
                    error={!!error}
                    helperText={
                      error?.message || (typeof rest.value === "string" ? rest.value : rest.value?.description)
                    }
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                getOptionKey={(option) => {
                  if (typeof option === "string") return option;
                  return option.id;
                }}
                getOptionLabel={(option) => {
                  if (typeof option === "string") return option;
                  return option.label || applyPrefixes(option.id);
                }}
                renderOption={(props, option, { inputValue }) => {
                  return (
                    <ListItem {...props}>
                      <Highlight
                        fullText={typeof option === "string" ? option : option.label || applyPrefixes(option.id)}
                        highlightedText={inputValue}
                        matcher={substringMatch}
                      />
                    </ListItem>
                  );
                }}
                {...rest}
              />
            )}
          />
        </FormField>

        <LoadingButton color="secondary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          Save
        </LoadingButton>

        {errors.root && <Alert severity="error">Something went wrong on the server...</Alert>}
      </form>
    </ThemeProvider>
  );
};

export default ClassForm;
