import { connectRouter } from "connected-react-router";
import type * as reactRedux from "react-redux";
import * as Redux from "redux";
import { reducer } from "redux-connect";
import * as reduxForm from "redux-form";
// @ts-ignore
import serialize from "serialize-javascript";
import type { Routes } from "@triply/utils";
import type { GenericReduxApiResponse, GenericReduxContext, ResponseMetaData } from "#helpers/ApiClient.ts";
/**
 * Reducers (keep in alphabetic order)
 */
import * as accountCollection from "./accountCollection.ts";
import * as accounts from "./accounts.ts";
import * as admin from "./admin.ts";
import * as app from "./app.ts";
import * as assets from "./assets.ts";
import * as assetUploads from "./assetUploads.ts";
import * as auth from "./auth.ts";
import * as config from "./config.ts";
import * as datasetCollection from "./datasetCollection.ts";
import * as datasetManagement from "./datasetManagement.ts";
import * as datasets from "./datasets.ts";
import * as graphs from "./graphs.ts";
import * as hookRecords from "./hookRecords.ts";
import * as imports from "./imports.ts";
import * as insights from "./insights.ts";
import * as limits from "./limits.ts";
import * as notifications from "./notifications.ts";
import * as orgs from "./orgs.ts";
import * as queries from "./queries.ts";
import * as redirects from "./redirects.ts";
import * as resourceDescriptions from "./resourceDescriptions.ts";
import * as resourceEditorDescriptions from "./resourceEditorDescriptions.ts";
import type * as router from "./router.ts";
import * as services from "./services.ts";
import * as sessionHistory from "./sessionHistory.ts";
import * as socket from "./socket.ts";
import * as stories from "./stories.ts";
import * as tokens from "./tokens.ts";
import * as topics from "./topics.ts";
import * as triples from "./triples.ts";
import * as uploading from "./uploading.ts";
import * as hooks from "./webhooks.ts";

export function serializeState({ router, ...clientState }: GlobalState) {
  //dont want router info in the state. The browser should re-initiate this itself
  //see https://github.com/supasate/connected-react-router/issues/312#issuecomment-500968504
  return serialize(clientState);
}
export type Store = Redux.Store<GlobalState>;

/**
Keep in alphabetic order
**/
export interface GlobalState extends Partial<reactRedux.ProviderProps> {
  accountCollection: accountCollection.State;
  accounts: accounts.State;
  auth: auth.State;
  app: app.State;
  assets: assets.State;
  assetUploads: assetUploads.State;
  config: config.State;
  datasetCollection: datasetCollection.State;
  datasetManagement: datasetManagement.State;
  datasets: datasets.State;
  graphs: graphs.State;
  hooks: hooks.State;
  insights: insights.State;
  notifications: notifications.State;
  queries: queries.State;
  redirects: redirects.State;
  resourceDescriptions: resourceDescriptions.State;
  resourceEditorDescriptions: resourceEditorDescriptions.State;
  services: services.State;
  sessionHistory: sessionHistory.State;
  stories: stories.State;
  tokens: tokens.State;
  topics: topics.State;
  triples: triples.State;
  uploading: uploading.State;

  limits: limits.State;

  //the state managed by included libs such as react-router-redux
  reduxAsyncConnect: any;
  form: { [formName: string]: reduxForm.Form };
  router: router.State;
}
function getGlobalReducer(history: any): Redux.Reducer<GlobalState> {
  const appReducer: Redux.Reducer<GlobalState> = Redux.combineReducers({
    /**
     * Keep in alphabetic order
     */
    accountCollection: accountCollection.reducer,
    accounts: accounts.reducer,
    app: app.reducer,
    assets: assets.reducer,
    assetUploads: assetUploads.reducer,
    auth: auth.reducer,
    config: config.reducer,
    datasetCollection: datasetCollection.reducer,
    datasetManagement: datasetManagement.reducer,
    datasets: datasets.reducer,
    graphs: graphs.reducer,
    hooks: hooks.reducer,
    insights: insights.reducer,
    notifications: notifications.reducer,
    queries: queries.reducer,
    redirects: redirects.reducer,
    resourceDescriptions: resourceDescriptions.reducer,
    resourceEditorDescriptions: resourceEditorDescriptions.reducer,
    services: services.reducer,
    sessionHistory: sessionHistory.reducer,
    stories: stories.reducer,
    tokens: tokens.reducer,
    topics: topics.reducer,
    triples: triples.reducer,
    uploading: uploading.reducer,

    limits: limits.reducer,

    //the state managed by included libs such as react-router-redux
    reduxAsyncConnect: reducer,
    form: reduxForm.reducer,
    router: connectRouter(history),
  } as { [K in keyof GlobalState]: any }) as any;
  return appReducer;
}

export const Actions = {
  ...accounts.LocalActions,
  ...admin.LocalActions,
  ...app.LocalActions,
  ...assets.LocalActions,
  ...auth.LocalActions,
  ...config.LocalActions,
  ...datasetManagement.LocalActions,
  ...datasets.LocalActions,
  ...graphs.LocalActions,
  ...hookRecords.LocalActions,
  ...hooks.LocalActions,
  ...imports.LocalActions,
  ...notifications.LocalActions,
  ...orgs.LocalActions,
  ...queries.LocalActions,
  ...redirects.LocalActions,
  ...resourceDescriptions.LocalActions,
  ...resourceEditorDescriptions.LocalActions,
  ...services.LocalActions,
  ...sessionHistory.LocalActions,
  ...socket.LocalActions,
  ...stories.LocalActions,
  ...insights.LocalActions,
  ...tokens.LocalActions,
  ...topics.LocalActions,
  ...triples.LocalActions,
  ...uploading.LocalActions,
} as const;

export type Action =
  | AfterDispatch<accounts.LocalAction>
  | AfterDispatch<admin.LocalAction>
  | AfterDispatch<app.LocalAction>
  | AfterDispatch<assets.LocalAction>
  | AfterDispatch<auth.LocalAction>
  | AfterDispatch<config.LocalAction>
  | AfterDispatch<datasetManagement.LocalAction>
  | AfterDispatch<datasets.LocalAction>
  | AfterDispatch<graphs.LocalAction>
  | AfterDispatch<hookRecords.LocalAction>
  | AfterDispatch<hooks.LocalAction>
  | AfterDispatch<imports.LocalAction>
  | AfterDispatch<notifications.LocalAction>
  | AfterDispatch<orgs.LocalAction>
  | AfterDispatch<queries.LocalAction>
  | AfterDispatch<redirects.LocalAction>
  | AfterDispatch<resourceDescriptions.LocalAction>
  | AfterDispatch<resourceEditorDescriptions.LocalAction>
  | AfterDispatch<services.LocalAction>
  | AfterDispatch<sessionHistory.LocalAction>
  | AfterDispatch<socket.LocalAction>
  | AfterDispatch<stories.LocalAction>
  | AfterDispatch<insights.LocalAction>
  | AfterDispatch<tokens.LocalAction>
  | AfterDispatch<topics.LocalAction>
  | AfterDispatch<triples.LocalAction>
  | AfterDispatch<uploading.LocalAction>
  | AfterDispatch<GlobalAction<{ type: "@@redux-form/DESTROY"; meta: { form: any[] } }>>;

interface _Thunk<A> {
  (dispatch: (action: A) => any, getState: () => GlobalState): any;
}

type ActionPromise<M> = M extends Routes.HttpMethodTemplate
  ? (client: GenericReduxContext<M>) => Promise<GenericReduxApiResponse<M["Res"]>>
  : (client: GenericReduxContext<any>) => Promise<M>;

interface PromiseActionInterface {
  types: [string, string, string];
}
interface SimpleActionInterface {
  type: string;
}
type GenericAction = PromiseActionInterface | SimpleActionInterface;

interface FailAction<Type = string> {
  type: Type;
  message: string;
  devMessage: string;
  status: number;
}
interface SuccessAction<Type = string, Result = any> {
  type: Type;
  result: Result;
  meta: ResponseMetaData;
}
export interface GlobalAction<ActionInterface extends GenericAction, PromiseResult = any> {
  beforeDispatch: ActionInterface extends PromiseActionInterface
    ? ActionInterface & { promise: ActionPromise<PromiseResult> }
    : ActionInterface;
  afterDispatch: ActionInterface extends PromiseActionInterface
    ?
        | (Omit<ActionInterface, "types"> & { type: ActionInterface["types"][0] })
        | (Omit<ActionInterface, "types"> &
            SuccessAction<
              ActionInterface["types"][1],
              PromiseResult extends Routes.HttpMethodTemplate
                ? PromiseResult["Res"]["Body"]
                : PromiseResult extends GenericReduxApiResponse<any>
                  ? PromiseResult["body"]
                  : PromiseResult
            >)
        | (Omit<ActionInterface, "types"> & FailAction<ActionInterface["types"][2]>)
    : ActionInterface;
  asThunk: _Thunk<ActionInterface>;
}

export type BeforeDispatch<A extends GlobalAction<any>> = A["beforeDispatch"];
export type AfterDispatch<A extends GlobalAction<any>> = A["afterDispatch"];
export type Thunk<A> = A extends GlobalAction<any> ? A["asThunk"] : _Thunk<A>;

export type DispatchedFn<I extends (...args: any[]) => any> = (
  ...args: Parameters<I>
) => ReturnType<I> extends PromiseActionInterface ? ReturnType<ReturnType<I>["promise"]> : void;
export type Dispatched<I extends (...args: any[]) => any> =
  ReturnType<I> extends PromiseActionInterface ? ReturnType<ReturnType<I>["promise"]> : void;

export default getGlobalReducer;
