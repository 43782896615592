import type { Context } from "koa";
import { endsWith } from "lodash-es";
import type { ParsedQs } from "qs";
import { parse, stringify } from "qs";
import * as React from "react";
import { Redirect } from "react-router";
import type { RouteConfig } from "react-router-config";
import type { Store } from "redux";
import { ErrorPage } from "#components/index.ts";
import FacetsRedirector from "#containers/FacetedSearch/Redirector.tsx";
import * as Containers from "#containers/index.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import type { GlobalState } from "#reducers//index.ts";
import { getLoggedInUser } from "#reducers/auth.ts";
import { getConfig, urlInfoToString } from "#staticConfig.ts";
import Elasticsearch from "./containers/Elasticsearch";
import ElasticRedirector from "./containers/Elasticsearch/Redirector";

export interface TopLevelRedirect {
  path: string;
  toUrl: (currentPath: string, query: ParsedQs) => string;
  shouldApplyServerRedirect?: (ctx: Context) => boolean;
  onlyServer: boolean;
  methods: Array<"GET" | "POST">;
}

export const TopLevelRedirects: TopLevelRedirect[] = [
  {
    // this path is pushed above the path `/:account/:dataset/assets/:identifier` to make sure the
    // router matches this one first
    path: "/:account/:dataset/assets/:assetId/:version",
    toUrl: (currentPath) => "/_api/datasets" + currentPath,
    onlyServer: false,
    methods: ["GET"],
  },
  {
    path: "/:account/:dataset/assets/:assetId",
    toUrl: (currentPath, query) => "/_api/datasets" + currentPath + "?" + stringifyQuery(query),
    onlyServer: false,
    methods: ["GET"],
  },
  {
    path: "/:account/:dataset/download(.*)",
    toUrl: (currentPath, query) => "/_api/datasets" + currentPath + "?" + stringifyQuery(query),
    onlyServer: false,
    methods: ["GET"],
  },
  {
    path: "/:account/:dataset/sparql/:service",
    toUrl: (currentPath, query) => {
      const conf = getConfig();
      const splitPath = currentPath.split("/");
      const service = splitPath.pop();
      splitPath.pop(); // Remove /sparql
      return (
        urlInfoToString(conf.apiUrl) + `/datasets${splitPath.join("/")}/services/${service}/sparql?${stringify(query)}`
      );
    },
    onlyServer: true,
    shouldApplyServerRedirect: (ctx) => {
      const acceptedSparqlEncodings = [
        "text/html",
        "application/sparql-results+json",
        "application/sparql-results+xml",
        "text/csv",
        "text/tab-separated-values",
        "text/turtle",
        "application/rdf+json",
        "application/ld+json",
        "application/rdf+xml",
        "application/trig",
        "application/n-triples",
        "application/n-quads",
      ];
      return !!ctx.accepts(acceptedSparqlEncodings) && ctx.accepts(acceptedSparqlEncodings) !== "text/html";
    },
    methods: ["GET", "POST"],
  },
  {
    path: "/:account/-/queries/:query/:queryVersion?",
    toUrl: (currentPath) => "/_api/queries" + currentPath.replace("/-/queries/", "/"),
    onlyServer: true,
    shouldApplyServerRedirect: (ctx) => {
      return ctx.accepts(["html", "json"]) !== "html";
    },
    methods: ["GET"],
  },
  {
    path: "/:account/:dataset/browser",
    toUrl: (currentPath, query) => {
      const conf = getConfig();
      let path = currentPath.split("/");
      path.pop(); // Pop /browser
      const redirectPath = path.join("/");
      return (
        urlInfoToString(conf.apiUrl) +
        // Don't stringify the whole query, our API requires a direction and a page to be defined (we don't use page in the browser) When users want to use the pagination features they should use the API path
        `/datasets${redirectPath}/describe?${stringifyQuery({ resource: query.resource })}`
      );
    },
    onlyServer: true,
    shouldApplyServerRedirect: (ctx) => {
      // If html is accepted at all, then show html, otherwise redirect to API.
      return !ctx.accepts("text/html");
    },
    methods: ["GET"],
  },
  {
    path: "/:account/:dataset/table",
    toUrl: (currentPath, query) => {
      const conf = getConfig();
      let path = currentPath.split("/");
      path.pop(); // Pop /table
      const redirectPath = path.join("/");
      return urlInfoToString(conf.apiUrl) + `/datasets${redirectPath}/statements?${stringifyQuery(query)}`;
    },
    onlyServer: true,
    shouldApplyServerRedirect: (ctx) => {
      const acceptedTableEncodings = [
        "text/html",
        "application/rdf+xml",
        "application/n-triples",
        "text/plain",
        "application/n-quads",
        "application/trig",
        "text/turtle",
        "application/x-turtle",
        "text/rdf+n3",
        "application/ld+json",
        "application/x-triply+json",
        "application/json",
      ];
      return !!ctx.accepts(acceptedTableEncodings) && ctx.accepts(acceptedTableEncodings) !== "text/html";
    },
    methods: ["GET"],
  },
  {
    path: "/:account/:dataset/services/:serviceName",
    toUrl: (currentPath, query) => {
      const pathElements = currentPath.split("/");
      const service = pathElements.pop();
      pathElements.pop(); // Remove /service

      return `${pathElements.join("/")}/sparql/${service}?${stringifyQuery(query)}`;
    },
    onlyServer: false,
    methods: ["GET", "POST"],
  },
];

const ApiComponent = () => {
  if (__DEVELOPMENT__) {
    return (
      <ErrorPage message="Cannot access _api via react-router. This is most likely caused by running the console with SSR disabled." />
    );
  }
  return <ErrorPage statusCode={404} />;
};

function getConsolePages(store: Store<GlobalState>): RouteConfig[] {
  return [
    {
      path: "/me",
      component: ((props: Containers.IComponentProps) => {
        const loggedInUser = getLoggedInUser(store.getState());
        if (loggedInUser) {
          return (
            <Redirect to={`/${loggedInUser.accountName}${props.location.pathname.substr(3)}${props.location.search}`} />
          );
        } else {
          return <Redirect to={`/login?returnTo=${props.location.pathname}`} />;
        }
      }) as any, // remove any cast when react-router updates to the built-in type defs of the history package
    },
    {
      component: Containers.NavConsole as any,
      routes: [
        { path: "/", exact: true, component: Containers.ConsoleMainPage as any },
        {
          path: "/search",
          exact: false,
          component: Containers.Search as any,
          routes: [
            //the extra property `pathBasename` is set in the `route` member variable and used in the component
            { path: "/search", exact: true, pathBasename: "search" } as any,
            { path: "/search/datasets", exact: true, pathBasename: "datasets" },
            { path: "/search/accounts", exact: true, pathBasename: "accounts" },
            { path: "/search/queries", exact: true, pathBasename: "queries" },
            { path: "/search/stories", exact: true, pathBasename: "stories" },
          ],
        },
        {
          path: "/browse",
          exact: true,
          component: FacetsRedirector,
        },
        {
          path: "/browse/:page(datasets|queries|stories|organizations)",
          exact: true,
          component: Containers.FacetedSearch,
        },
        { path: "/datasets", exact: true, component: Containers.DatasetSearch },
        { path: "/queries", exact: true, component: Containers.QuerySearch },
        { path: "/stories", exact: true, component: Containers.StorySearch },
        { path: "/accounts", exact: true, component: Containers.Accounts },
        { path: "/_details", exact: true, component: Containers.ExtraUserDetails },
        { path: "/login", exact: true, component: Containers.Login },
        { path: "/login/2fa", exact: true, component: Containers.Login2FA },
        { path: "/login/recovery", exact: true, component: Containers.Recovery2FA },
        { path: "/register", exact: true, component: Containers.Register },
        { path: "/auth/resendPassword", exact: true, component: Containers.ResendPassword },
        { path: "/auth/resetPassword/:token", exact: true, component: Containers.ResetPassword },
        { path: "/auth/verify/:userId/:token", exact: true, component: Containers.VerifyAccount },

        /* _admin */
        {
          path: "/_admin",
          component: Containers.AdminSettings,
          routes: [
            { path: "/_admin/", exact: true, component: Containers.AdminOverview },
            { path: "/_admin/site-profile", exact: true, component: Containers.AdminSiteProfile },
            { path: "/_admin/security", exact: true, component: Containers.AdminSecurity },
            { path: "/_admin/prefixes", exact: true, component: Containers.AdminPrefixes },
            { path: "/_admin/users", exact: true, component: Containers.AdminUsers },
            { path: "/_admin/organizations", exact: true, component: Containers.AdminOrganizations },
            { path: "/_admin/datasets", exact: true, component: Containers.AdminDatasets },
            { path: "/_admin/queries", exact: true, component: Containers.AdminQueries },
            {
              path: "/_admin/services",
              exact: true,
              component: Containers.AdminServices,
            },
            { path: "/_admin/redirects", exact: true, component: Containers.AdminRedirects },
            { path: "/_admin/jobs", exact: true, component: Containers.AdminJobs },
            { path: "/_admin/cache", exact: true, component: Containers.AdminCache },
            { path: "/_admin/tasks", exact: true, component: Containers.AdminTasks },
            { path: "/_admin/analytics", exact: true, component: Containers.AdminSpeedy },
            { path: "/_admin/advanced-settings", exact: true, component: Containers.AdminAdvancesSettings },
            { component: () => <ErrorPage statusCode={404} /> },
          ],
        },

        /* _api */
        { path: "/_api/*", component: ApiComponent },

        {
          path: "/.well-known/genid/*",
          component: () => (
            <ErrorPage message={"Well-known IRIs (blank nodes) cannot be dereferenced."} statusCode={404} />
          ),
        },

        /* :account */
        {
          path: [
            "/:account",
            "/:account/-/overview",
            "/:account/-/datasets",
            "/:account/-/stories",
            "/:account/-/queries",
          ],
          exact: true,
          component: Containers.Account as any,
        },

        {
          path: "/:account/-/queries/:query/:queryVersion?",
          exact: true,
          component: Containers.Query,
        },

        {
          path: "/:account/-/stories/:story",
          exact: true,
          component: Containers.Story,
        },

        { path: "/:account/-/2faSetup", exact: true, component: Containers.Forced2FaSetup },
        /* :account/-/settings */
        {
          path: "/:account/-/settings",
          component: Containers.AccountSettingsTabs,
          routes: [
            { path: "/:account/-/settings", exact: true, component: Containers.AccountSettings },
            { path: "/:account/-/settings/orgs", exact: true, component: Containers.OrgList },
            { path: "/:account/-/settings/members", exact: true, component: Containers.OrgMemberList },
            { path: "/:account/-/settings/tokens", exact: true, component: Containers.TokenManager },
            { path: "/:account/-/settings/tokens/:tokenId", exact: true, component: Containers.TokenInfo },
            { path: "/:account/-/settings/etl", exact: true, component: Containers.TriplyETL },
            { path: "/:account/-/settings/jobs", exact: true, component: Containers.QueryJobs },
            { component: () => <ErrorPage statusCode={404} /> },
          ],
        },

        /* catch other :account/- */
        {
          path: "/:account/-",
          component: () => <ErrorPage statusCode={404} />,
        },

        /* :account/:dataset */
        {
          path: "/:account/:dataset",
          component: Containers.NavDataset as any,
          routes: [
            { path: "/:account/:dataset", exact: true, component: Containers.DatasetInfo as any }, // remove any cast when react-router updates to the built-in type defs of the history package
            {
              path: "/:account/:dataset/settings",
              component: Containers.DatasetSettingsTabs,
              routes: [
                { path: "/:account/:dataset/settings", exact: true, component: Containers.DatasetSettings as any }, // remove any cast when react-router updates to the built-in type defs of the history package
                {
                  path: "/:account/:dataset/settings/hooks",
                  exact: true,
                  component: Containers.DatasetHooks,
                },
                { component: () => <ErrorPage statusCode={404} /> },
              ],
            },
            { path: "/:account/:dataset/table", exact: true, component: Containers.Table as any }, // remove any cast when react-router updates to the built-in type defs of the history package
            { path: "/:account/:dataset/browser", exact: true, component: Containers.Browser as any },
            {
              path: "/:account/:dataset/data-editor/:renderer?",
              component: Containers.DataEditor,
              routes: [
                { path: "/:account/:dataset/data-editor/skos", exact: true, component: Containers.SkosView },
                { component: () => <ErrorPage statusCode={404} /> },
              ],
            },
            { path: "/:account/:dataset/data-model", exact: true, component: Containers.DataModel },
            { path: "/:account/:dataset/graphs", exact: true, component: Containers.Graphs as any }, // remove any cast when react-router updates to the built-in type defs of the history package
            {
              path: "/:account/:dataset/services",
              exact: true,
              component: Containers.Services as any, // remove any cast when react-router updates to the built-in type defs of the history package
            },
            {
              path: "/:account/:dataset/sparql",
              exact: false,
              component: Containers.SparqlIde as any,
              type: "sparql",
              routes: [
                { path: "/:account/:dataset/sparql/:serviceName", exact: true },
                { path: "/:account/:dataset/sparql", exact: true },
              ],
            },
            {
              path: "/:account/:dataset/graphql",
              exact: true,
              component: Containers.Graphql,
            },
            {
              path: "/:account/:dataset/(search|elasticsearch)/:serviceName/:tab(text|query)",
              component: Elasticsearch,
            },

            {
              path: "/:account/:dataset/(search|elasticsearch)",
              exact: false,
              component: ElasticRedirector,
              type: "elasticsearch",
              routes: [
                {
                  path: "/:account/:dataset/(search|elasticsearch)/:serviceName",
                  exact: true,
                },
                {
                  path: "/:account/:dataset/(search|elasticsearch)",
                  exact: true,
                },
              ],
            },

            { path: "/:account/:dataset/assets", exact: true, component: Containers.Assets as any }, // remove any cast when react-router updates to the built-in type defs of the history package
            { path: "/:account/:dataset/schema", exact: true, component: Containers.Schema },
            {
              path: "/:account/:dataset/insights",
              component: Containers.Insights as any, // remove any cast when react-router updates to the built-in type defs of the history package,
              routes: [
                {
                  path: "/:account/:dataset/insights/classHierarchy",
                  exact: true,
                  component: Containers.ClassHierarchy as any, // remove any cast when react-router updates to the built-in type defs of the history package
                },
                {
                  path: "/:account/:dataset/insights/classFrequency",
                  exact: true,
                  component: Containers.ClassFrequency,
                },
                {
                  path: "/:account/:dataset/insights",
                  exact: true,
                  component: (props: Containers.IComponentProps) => (
                    <Redirect to={props.match.url + (endsWith(props.match.url, "/") ? "" : "/") + "classHierarchy"} />
                  ),
                },
                { component: () => <ErrorPage statusCode={404} /> },
              ],
            },
            { component: Containers.DereferenceOrNotFound },
          ],
        },

        /* catch all */
        { component: () => <ErrorPage statusCode={404} /> },
      ],
    },
  ];
}
export default (store: Store<GlobalState>): RouteConfig[] => {
  return [
    ...TopLevelRedirects.filter((tlRedirect) => !tlRedirect.onlyServer).map((tlRedirect) => {
      return {
        path: tlRedirect.path,
        exact: true,
        component: (props: any) => <Redirect to={tlRedirect.toUrl(props.match.url, parse(props.location.search))} />,
      };
    }),
    {
      component: Containers.App as any,
      routes: getConsolePages(store),
    },
  ];
};
