/**
 * IMPORTANT:
 * Do not simply add stuff to the context. I'd like us to discuss what we put in the redux state, and what we put in this context
 */
import * as React from "react";
import type { StaticRouterContext as _StaticRouterContext } from "react-router";
import type { SocketEvent } from "@triply/utils/SocketEvents";
import type { Acl } from "#helpers/Acl.ts";
import { getPredefinedAcl } from "#helpers/Acl.ts";

export const TimezoneContext = React.createContext("UTC");

export const AclContext = React.createContext<Acl>(getPredefinedAcl(undefined, undefined, undefined));

export type WebSocketContextType = <E extends SocketEvent>(
  namespace: E["namespace"],
  eventName: E["eventName"],
  listener?: (event: SocketEvent) => void,
) => void;

export const WebSocketContext = React.createContext<{
  subscribe: WebSocketContextType;
  unsubscribe: WebSocketContextType;
}>({
  subscribe: () => {},
  unsubscribe: () => {},
});

export interface StaticRouterContext extends _StaticRouterContext {
  /**
   * Set headers (overwriting headers that are previously defined). Use `null` to remove a header
   */
  headers?: { [key: string]: string | null };
}
