import { TextField } from "@mui/material";
import * as React from "react";
import { Button, Dialog, LoadingButton } from "#components/index.ts";

export interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  actionLabel?: string;
  description?: React.ReactNode;
  confirmationString?: string;
  loading?: boolean;
  stopPropagation?: boolean; // Set this to true to prevent submit(s) of forms where this element is embedded
}

const ConfirmationDialog: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  title,
  actionLabel,
  description,
  confirmationString,
  loading,
  stopPropagation,
}) => {
  const [userConfirmationString, setUserConfirmationString] = React.useState("");
  return (
    <Dialog open={open} onClose={onClose} title={title}>
      <form
        className="px-5 pb-5"
        aria-label={title}
        onSubmit={(e) => {
          e.preventDefault(); // Don't let form make a request
          stopPropagation && e.stopPropagation(); // Don't let the form trigger submits where this form is embedded
          onConfirm();
        }}
      >
        {description}
        {confirmationString && (
          <div className="mt-3">
            <label htmlFor="confirmationString">
              Please type <strong>{confirmationString}</strong> to confirm.
            </label>
            <TextField
              id="confirmationString"
              variant="outlined"
              fullWidth
              onChange={(e) => setUserConfirmationString(e.target.value)}
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              autoComplete="off"
              type="text"
            />
          </div>
        )}
        <div className="pt-5">
          <Button className="mr-4" onClick={onClose} variant="text">
            Cancel
          </Button>
          <LoadingButton
            autoFocus
            loading={!!loading}
            color="error"
            type="submit"
            disabled={confirmationString !== undefined ? userConfirmationString !== confirmationString : false}
          >
            {actionLabel || "Confirm"}
          </LoadingButton>
        </div>
      </form>
    </Dialog>
  );
};

export default ConfirmationDialog;
