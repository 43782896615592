import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import * as Forms from "#components/Forms/index.ts";
import { Alert, Button, DatasetListItem, FontAwesomeIcon, StarterDatasetWidget } from "#components/index.ts";
import { useCreateDataset } from "#helpers/hooks/datasets.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { getDatasetsWithLink, useDatasetList } from "#reducers/datasetManagement.ts";
import type { GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

const Datasets: React.FC<{ mayCreateDataset: boolean }> = ({ mayCreateDataset }) => {
  const dispatch = useDispatch();
  const currentAccount = useCurrentAccount();
  const starterDataset = useSelector((state: GlobalState) => state.config?.clientConfig?.starterDataset);
  const datasets = useDatasetList({ account: currentAccount?.accountName || false, searchTerm: false, admin: false });
  const fetchingListError = useSelector((state: GlobalState) => state.datasetManagement.fetchingListError);
  const fetchingList = useSelector((state: GlobalState) => state.datasetManagement.fetchingList);
  const nextPage = useSelector((state: GlobalState) => state.datasetManagement.nextPage);
  const allResourceDescriptions = useSelector((state: GlobalState) => state.resourceDescriptions);
  const createDataset = useCreateDataset(currentAccount?.accountName, false);

  const loadNextDsPage = React.useCallback(
    () => nextPage && dispatch(getDatasetsWithLink(nextPage)),
    [nextPage, dispatch],
  );

  if (!currentAccount) return null;

  if (fetchingListError) {
    return (
      <div className="my-5">
        <Alert error message={fetchingListError} />
      </div>
    );
  }

  const hasDatasets = datasets && datasets.length > 0;
  const showForm = !hasDatasets && mayCreateDataset;

  return (
    <div className="my-5">
      {!fetchingList && (
        <>
          {!showForm && !hasDatasets && (
            <div className={getClassName(styles.center, styles.empty)}>
              <FontAwesomeIcon size="3x" icon={["fal", "empty-set"]} />
            </div>
          )}

          {showForm && (
            <>
              {/* Let the user choose from importing a starter dataset, or creating their own. */}
              {starterDataset && (
                <div>
                  <h3 className={getClassName("headerSpacing", styles.title)}>
                    Get started by using this starter dataset
                  </h3>
                  <StarterDatasetWidget
                    key={starterDataset.id}
                    dataset={starterDataset}
                    accountName={currentAccount.accountName}
                    resourceDescriptions={allResourceDescriptions[starterDataset.id]}
                    className={getClassName(styles.listItem, "p-5")}
                  />
                </div>
              )}

              {/* Add dataset form */}
              <div>
                <h3 className={getClassName("headerSpacing", styles.title)}>
                  {starterDataset ? "Or, create one of your own" : "Get started, and create your first dataset"}
                </h3>
                <Forms.DatasetAdd
                  initialValues={{ accessLevel: "private" }}
                  onSubmit={createDataset}
                  disableAutoFocus={!!starterDataset}
                  className={styles.form}
                  currentAccount={currentAccount}
                />
              </div>
            </>
          )}

          {!showForm && (
            <div>
              {datasets &&
                datasets.map((s) => <DatasetListItem ds={s} key={s.id} hideOwnerName className={styles.listItem} />)}

              {nextPage && (
                <div className={styles.showMore}>
                  <Button onClick={loadNextDsPage} elevation className="mt-5">
                    Show more...
                  </Button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Datasets;
