import getClassName from "classnames";
import * as React from "react";
import type { Models } from "@triply/utils";
import type { Props as AvatarProps } from "#components/Avatar/index.tsx";
import { Avatar } from "#components/index.ts";
import blazegraph from "./blazegraph.png";
import elasticsearch from "./elasticsearch.svg";
import jena from "./jena.svg";
import speedy from "./speedy.svg";
import virtuoso from "./virtuoso.png";
import * as styles from "./style.scss";

interface ServiceInfo {
  logo?: string;
  label: string;
}
const serviceTypeInfo: { [type in Models.QueryServiceType]: ServiceInfo } = {
  speedy: {
    logo: speedy,
    label: "SPARQL - Speedy",
  },
  virtuoso: {
    logo: virtuoso,
    label: "SPARQL - Virtuoso",
  },
  blazegraph: {
    logo: blazegraph,
    label: "SPARQL - Blazegraph",
  },
  jena: {
    logo: jena,
    label: "SPARQL - Jena",
  },
  elasticSearch: {
    logo: elasticsearch,
    label: "Elasticsearch",
  },
};

interface Props {
  type: Models.QueryServiceType;
  className?: string;
  showLabel?: boolean;
  size?: AvatarProps["size"];
}

const ServiceTypeBadge: React.FC<Props> = ({ type, showLabel, className, size = "md" }) => {
  if (!serviceTypeInfo[type]) return null;

  const info = serviceTypeInfo[type];
  return (
    <div title={info.label}>
      <Avatar
        size={size}
        avatarUrl={info.logo}
        avatarName={info.label}
        square
        className={getClassName(styles.typeBadge, styles[size], className)}
        alt={!showLabel ? info.label : ""} // Only use as alt the label if we don't show the text as well, otherwise the screen-readers would read out the same thing twice
      />
      {showLabel && <div className={styles.label}>{info.label}</div>}
    </div>
  );
};
export default ServiceTypeBadge;
