import type { TextFieldProps } from "@mui/material";
import { Box, FormHelperText, InputLabel, Tab, Tabs, Typography } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import type * as ReduxForm from "redux-form";
import type { MarkOptional } from "ts-essentials";
import { Markdown, MuiTextField } from "#components/index.ts";
import * as styles from "./style.scss";

namespace MarkdownEditField {
  export interface OwnProps {}
  export type Props = TextFieldProps & OwnProps;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={1} className={getClassName(styles.tabPanel)}>
        {children}
      </Box>
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MarkdownEditField = React.memo<MarkdownEditField.Props>((props) => {
  const { label, className, error, helperText, maxRows, ...textFieldProps } = props;

  const [activeTab, setActiveTab] = React.useState(0);
  const [value, setValue] = React.useState<string>(props.value as string);
  const [focused, setFocus] = React.useState(false);

  const textFieldRef = React.useRef<HTMLTextAreaElement>(null);

  // We store the text input in a local value to not alter the state and trigger
  // a rerender. Only when switching tabs is the local value set in the state
  // so the markdown tab shows the up-to-date value.
  let localValue = props.value as string;
  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    localValue = event.target.value;
    props.onChange?.(event);
  };
  const handleSelectedTab = (_event: React.ChangeEvent<{}>, selectedTab: number) => {
    setActiveTab(selectedTab);
    setValue(localValue);
    // if we switch to the write tab, set focus to the input field
    if (selectedTab === 0) {
      // set focus with a timeout mechanism, otherwise the tab claims the focus after we set it
      setTimeout(() => {
        if (textFieldRef.current) textFieldRef.current.focus();
      }, 0);
    }
  };

  return (
    <div className={className}>
      <div className={getClassName(styles.box, { [styles.boxHighlighted]: focused })}>
        <InputLabel classes={{ root: styles.label }} focused={focused} error={error}>
          {label}
        </InputLabel>
        <Tabs
          classes={{ root: styles.tabs + (focused ? " " + styles.tabsHighlighted : "") }}
          value={activeTab}
          indicatorColor="primary"
          onChange={handleSelectedTab}
        >
          <Tab label="Write" {...a11yProps(0)} classes={{ root: styles.tab }} />
          <Tab label="Preview" {...a11yProps(1)} classes={{ root: styles.tab }} />
        </Tabs>
        <TabPanel value={activeTab} index={0}>
          <MuiTextField
            {...textFieldProps}
            className={getClassName(styles.tabContent, styles.inputField)}
            inputRef={textFieldRef}
            onChange={onChange}
            onFocus={(event) => {
              setFocus(true);
              props.onFocus?.(event);
            }}
            onBlur={(event) => {
              setFocus(false);
              props.onBlur?.(event);
            }}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Markdown className={getClassName(styles.markdown, styles.tabContent)}>{value}</Markdown>
        </TabPanel>
      </div>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </div>
  );
});
export default MarkdownEditField;

/**
 * Markdown edit field wrapped for easy integration in redux-form field
 */
export namespace MarkdownEditFieldRedux {
  export type OwnProps = MarkOptional<MarkdownEditField.Props, "onChange">;
  export type Props = ReduxForm.WrappedFieldProps & MarkOptional<MarkdownEditField.Props, "onChange">;
}
export const MarkdownEditFieldRedux = React.memo<ReduxForm.WrappedFieldProps & MarkdownEditField.Props>(
  ({ input, meta, helperText, ...props }) => {
    return (
      <MarkdownEditField
        {...input}
        {...props}
        className={getClassName({
          [props.className || ""]: !!props.className,
          [styles.error]: meta.touched && !!meta.error,
        })}
        helperText={(meta.touched && meta.error) || helperText}
        error={meta.touched && !!meta.error}
      />
    );
  },
);
