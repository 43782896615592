import type { AutocompleteProps } from "@mui/material";
import { Autocomplete, ListItem, ListItemText, TextField } from "@mui/material";
import getClassName from "classnames";
import { debounce } from "lodash-es";
import { stringToTerm } from "rdf-string";
import * as React from "react";
import { type Prefix } from "@triply/utils";
import { getPrefixed, getPrefixInfoFromPrefixedValue } from "@triply/utils/prefixUtils";
import { Highlight } from "../../../components";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useFetch from "../../../helpers/hooks/useFetch";
import type { VariableType } from "./QueryVariables";
import * as styles from "./style.scss";

interface Props
  extends Omit<AutocompleteProps<string, false, false, true>, "options" | "renderInput" | "onChange" | "freeSolo"> {
  datasetPath: string;
  variableType: VariableType;
  datatype?: string;
  language?: string;
  error?: string;
  onChange: (value: string) => void;
  prefixes: Prefix[];
}

const AutosuggestTerm = React.forwardRef<unknown, Props>(
  ({ datasetPath, variableType, datatype, language, error, onChange, prefixes, ...props }, ref) => {
    const [searchString, setSearchString] = React.useState<string>(props.value || "");
    const constructUrlToApi = useConstructUrlToApi();
    const debouncedSetSearchString = React.useMemo(() => debounce(setSearchString, 250), [setSearchString]);
    const searchFor = `${variableType !== "NamedNode" ? '"' : ""}${searchString}`;
    const termsPath = constructUrlToApi({
      pathname: `/datasets/${datasetPath}/terms`,
      query: {
        q: searchFor,
        dataType:
          variableType === "TypedLiteral" && datatype
            ? datatype
            : variableType === "StringLiteral"
              ? "http://www.w3.org/2001/XMLSchema#string"
              : undefined,
        languageTag: variableType === "LanguageStringLiteral" && language ? language : undefined,
        termType: variableType === "NamedNode" ? "NamedNode" : "Literal",
      },
    });
    const { data, error: fetchError, response } = useFetch<string[]>(termsPath, {}, [termsPath]);
    const searchedFor = response.url ? new URL(response.url)?.searchParams?.get("q") : undefined;
    const options = Array.isArray(data) && !fetchError ? data?.map((termString) => stringToTerm(termString).value) : [];

    return (
      <Autocomplete
        {...props}
        ref={ref}
        value={props.value || ""}
        freeSolo
        size="small"
        filterOptions={(options) => options}
        options={options}
        onChange={(_event, value, reason) => {
          if (value) {
            onChange(value);
            debouncedSetSearchString(value);
          } else if (reason === "clear") {
            onChange?.("");
            debouncedSetSearchString("");
          }
        }}
        getOptionLabel={(value) => (variableType === "NamedNode" ? getPrefixed(value, prefixes) || value : value)}
        onInputChange={(_event, value, reason) => {
          if (reason === "input") {
            if (variableType === "NamedNode") {
              const info = getPrefixInfoFromPrefixedValue(value, prefixes);
              onChange?.(`${info.iri}${info.localName || ""}`);
              debouncedSetSearchString(`${info.iri}${info.localName || ""}`);
            } else {
              onChange?.(value);
              debouncedSetSearchString(value);
            }
          }
        }}
        renderInput={({ inputProps, ...rest }) => (
          <TextField
            {...rest}
            fullWidth
            value={props.value}
            error={!!error}
            helperText={error}
            inputProps={{
              ...inputProps,
              width: typeof inputProps.width === "number" ? `${inputProps.width}px` : inputProps.width,
              height: typeof inputProps.height === "number" ? `${inputProps.height}px` : inputProps.height,
            }}
          />
        )}
        renderOption={(props, option) => {
          const displayValue = option.trim().split("\n")[0];
          const showEllipsis = option.trim().length > displayValue.length;

          return (
            <ListItem {...props} key={option} className={getClassName(styles.autocompleteItem, props.className)} dense>
              <ListItemText
                primary={
                  <Highlight
                    fullText={
                      variableType === "NamedNode" ? getPrefixed(option, prefixes) || displayValue : displayValue
                    }
                    highlightedText={
                      variableType === "NamedNode"
                        ? getPrefixed(searchFor, prefixes) || searchFor
                        : (searchedFor?.substring(1) ?? "")
                    }
                  />
                }
                secondary={
                  <Highlight
                    fullText={(variableType === "NamedNode" && !!getPrefixed(option, prefixes) && option) || ""}
                    highlightedText={searchedFor?.substring(variableType !== "NamedNode" ? 1 : 0) ?? ""}
                  />
                }
              >
                {showEllipsis ? "…" : null}
              </ListItemText>
            </ListItem>
          );
        }}
      />
    );
  },
);

export default AutosuggestTerm;
