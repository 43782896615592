import { Alert } from "@mui/material";
import * as React from "react";
import { useHistory } from "react-router";
import { SubmissionError } from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { accountHasOngoingUploads } from "#helpers/tusUploadManagement.ts";
import type { Account } from "#reducers/accountCollection.ts";
import { updateProfile } from "#reducers/accounts.ts";

const ChangeUsername: React.FC<{ currentAccount: Account }> = ({ currentAccount }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRenameAccount = (values: Forms.RenameAccount.FormData) => {
    if (accountHasOngoingUploads(currentAccount.uid)) {
      throw new SubmissionError({ _error: "Renaming account is not possible while uploading" });
    }
    return dispatch<typeof updateProfile>(updateProfile(currentAccount, { accountName: values.name })).then(
      () => {
        history.replace("/" + values.name);
      },
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      },
    );
  };

  return (
    <div className="whiteSink">
      <h3>Change user name</h3>
      <Alert severity="warning" variant="standard">
        Changing a user name breaks links to pages and APIs of the account and its datasets, services, stories and
        queries.
      </Alert>
      <Forms.RenameAccount
        //Need to consider moving from 1 accountsettings page to the settings of a different user
        //For this to work, we need to use a scoped form value so redux-form stores it in a different branch of the tree
        //we also need to specify the key property, so react re-renders this component when the account name changes
        key={currentAccount.createdAt + "_RenameAccountForm"}
        form={currentAccount.createdAt + "_RenameAccountForm"}
        initialValues={{ name: currentAccount.accountName }}
        onSubmit={handleRenameAccount}
      />
    </div>
  );
};

export default ChangeUsername;
