import type { Account } from "#reducers/accountCollection.ts";

export function trimUntouched(values: any, currentAccount: Account) {
  for (const key of Object.keys(values)) {
    let newVal = values[key];
    let oldVal = (currentAccount as any)[key];
    if ((!oldVal && !newVal) || oldVal === newVal) {
      delete values[key];
    }
  }
  return values;
}
