import { merge } from "lodash-es";
import * as React from "react";
import { Route } from "react-router";
import { type StaticRouterContext as StaticRouterContextProps } from "#context.ts";

interface Props extends StaticRouterContextProps {
  children: React.ReactNode;
}
const StaticRouterContext: React.FC<Props> = (props) => {
  return (
    <Route
      render={(routerRenderProps) => {
        const staticContext = routerRenderProps.staticContext as any as StaticRouterContextProps | undefined;
        if (staticContext) {
          if (props.statusCode) staticContext.statusCode = props.statusCode;
          if (props.headers) staticContext.headers = merge({}, staticContext.headers, props.headers);
        }
        return props.children;
      }}
    />
  );
};
export default StaticRouterContext;
