import type { EditorState } from "@codemirror/state";
import { StateField } from "@codemirror/state";
import Debug from "debug";
import { parsers } from "@triplydb/sparql-ast";
import type { QueryType } from "#components/Sparql/SparqlUtils.ts";
import { updateOnDocAndParserChanges } from "./utils";

const debug = Debug("triply:sparql-editor:fields:queryType");

export const queryTypeField = StateField.define<QueryType>({
  create: (state) => {
    try {
      return getQueryType(state);
    } catch {
      // Let's just default to query
      return "query";
    }
  },
  update: (value, transaction) => {
    if (updateOnDocAndParserChanges(transaction)) {
      try {
        return getQueryType(transaction.state);
      } catch {
        // If thrown, we should use the previous value
        return value;
      }
    }
    return value;
  },
});

function getQueryType(state: EditorState) {
  debug("Running");
  // Let it throw when it can't parse the query.
  const query = parsers.lenient(state.doc.toString(), {
    baseIri: "https://triplydb.com/",
  });
  if (query.type === "update" && query.updates.length === 0) {
    throw new Error("No operations in query");
  }
  return query.type;
}
