import getClassName from "classnames";
import { push } from "connected-react-router";
import * as React from "react";
import { useSelector } from "react-redux";
import { asyncConnect } from "redux-connect";
import { FlexContainer, Meta, SearchField, SinkList, StoryListItem } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import type { GlobalState } from "#reducers/index.ts";
import { getStories, getStoryList, isStoryListLoaded } from "#reducers/stories.ts";
import * as styles from "./style.scss";

interface Props extends IComponentProps {}

const StorySearch: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const story = parseSearchString(props.location.search);
  const { stories, nextPage } = useSelector((state: GlobalState) => {
    const stories = getStoryList(state, {
      account: false,
      searchTerm: typeof story.q === "string" && !!story.q.length && story.q,
    });
    return {
      stories: stories,
      nextPage: (stories?.length > 0 && state.stories.nextPage) || undefined,
    };
  });

  const loadNextPage = React.useCallback(() => {
    return dispatch(getStories(undefined, undefined, nextPage));
  }, [dispatch, nextPage]);

  const search = React.useCallback(
    (term: string) => {
      return dispatch(
        push({
          pathname: `/stories`,
          search: stringifyQuery({ q: term.trim() }),
        }),
      );
    },
    [dispatch],
  );

  return (
    <>
      <Meta title="Stories" currentPath={props.location.pathname} />
      <FlexContainer className="mt-3">
        <div className={styles.outerSearchContainer}>
          <div className={getClassName("my-7 px-1", styles.innerSearchContainer)}>
            <SearchField
              key={typeof story.q === "string" ? story.q : ""}
              ariaLabel="Sitewide"
              search={search}
              initialSearchTerm={typeof story.q === "string" ? story.q : ""}
              placeHolder="Find a story"
              autoFocus
            />
          </div>
        </div>

        <SinkList loadNextPage={nextPage ? loadNextPage : undefined} noContentMsg="No stories found">
          {stories.map((story) => (
            <StoryListItem key={story.id} story={story} showOwner />
          ))}
        </SinkList>
      </FlexContainer>
    </>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ location, store: { dispatch, getState } }) => {
      const story = parseSearchString(location.search);
      if (
        !isStoryListLoaded(getState(), {
          account: false,
          searchTerm: typeof story.q === "string" && !!story.q.length && story.q,
        })
      ) {
        return dispatch<any>(getStories(undefined, story));
      }
    },
  },
])(StorySearch) as typeof StorySearch;
