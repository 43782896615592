import getClassName from "classnames";
import * as React from "react";
import * as styles from "./style.scss";

const PropertyGroup: React.FC<{ groupName: string; children: React.ReactNode; gray?: boolean }> = ({
  groupName,
  children,
  gray,
}) => {
  return (
    <div>
      <h6 className={getClassName("mb-5", styles.groupHeader, gray && styles.groupHeaderGray)}>{groupName}</h6>
      {children}
    </div>
  );
};

export default PropertyGroup;
