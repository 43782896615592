import type { Quad } from "@triplydb/engine/constants";
import { termToString } from "@triplydb/sparql-ast/serialize";

export function printQuadStatements(quads: Quad[]): string {
  return `
           ${quads
             .map((quad) => {
               let statement = "";
               if (quad.graph) {
                 if (quad.graph.termType === "DefaultGraph") {
                   statement += `${termToString(quad.subject)} ${termToString(quad.predicate)} ${termToString(quad.object)}.`;
                 } else {
                   statement += `graph ${termToString(quad.graph)} {
                                 ${termToString(quad.subject)} ${termToString(quad.predicate)} ${termToString(quad.object)}.
                               }`;
                 }
               } else {
                 statement += `${termToString(quad.subject)} ${termToString(quad.predicate)} ${termToString(quad.object)}.`;
               }

               return statement;
             })
             .join("\n")}
       `;
}
