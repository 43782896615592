import type { Geometry } from "@triplydb/recognized-datatypes/wkt";
import { findZValue, getGeometryCollectionZValue } from "./helpers.ts";

/**
 * Implementation of GeoSPARQL 1.1 maxZ function.
 * See here details https://defs.opengis.net/vocprez/object?uri=https://www.opengis.net/def/function/geosparql/maxZ
 *
 * @param  {Geometry} feature input
 * @returns The maximum z coordinate of the input geometry.
 */
export function geoMaxZ(feature: Geometry): number {
  if (!feature.hasZ) return 0;
  return feature.type === "GeometryCollection"
    ? getGeometryCollectionZValue(feature, "Max")
    : findZValue(feature.coordinates, "Max");
}
