import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import useSparql from "#helpers/hooks/useSparql.ts";

const factory = factories.compliant;

export const useReadOnlyState = (resource: string) => {
  const resourceIri = resource ? factory.namedNode(resource) : false;

  const query = resourceIri
    ? `
    prefix sh: <http://www.w3.org/ns/shacl#>
    prefix form-acl: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>

    ask where {
      ${termToString(resourceIri)} a ?class.
      ?nodeShape sh:targetClass ?class .
      ?nodeShape form-acl:readOnly true .
    }
  `
    : false;

  const { data: readOnly } = useSparql<boolean>(query);
  if (!resource) return true;
  return readOnly;
};
