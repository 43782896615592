import { stratify } from "d3";
import { flatMap } from "lodash-es";
import type { ReactNode } from "react";
import { createContext, useState } from "react";
import * as React from "react";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import { useCachedSparql } from "../useCachedSparql";
import { breadcrumbPartsQuery } from "./queries";
import { SkosTreeContext } from "./SkosTreeContext";

const errorMessageForInvalidUsage = "Component must be a child of ExpandedContextProvider";
export const ExpandedContext = createContext<{
  expandedMap: ExpandedMap;
  activeMap: ExpandedMap;
  setActive: (trail: string[]) => void;
  collapse: (trail: string[]) => void;
  expand: (trail: string[]) => void;
  collapseAll: () => void;
}>({
  expandedMap: {},
  activeMap: {},
  collapseAll: () => {
    throw new Error(errorMessageForInvalidUsage);
  },
  setActive: () => {
    throw new Error(errorMessageForInvalidUsage);
  },
  collapse: () => {
    throw new Error(errorMessageForInvalidUsage);
  },
  expand: () => {
    throw new Error(errorMessageForInvalidUsage);
  },
});

export interface ExpandedMap {
  [filter: string]: {};
}

interface BreadcrumbPart {
  id: string;
  parent?: string;
}
interface ItemWithKeys {
  iri: string;
  key: string;
  parentKey: string;
}
const getTreeItemsWithKeys: (opts: {
  allItems: BreadcrumbPart[];
  fromItem?: BreadcrumbPart;
  parentIris?: string[];
}) => ItemWithKeys[] = ({ allItems, fromItem, parentIris = [] }) => {
  const iri = fromItem?.id || "tree:root";
  const subItems = parentIris.includes(iri)
    ? []
    : allItems.filter((s) => (iri === "tree:root" && !s.parent) || s.parent === iri);
  return [
    {
      key: [...parentIris, iri].join(">"),
      iri: iri,
      parentKey: parentIris.join(">"),
    },
    ...flatMap(subItems, (subItem) => {
      return getTreeItemsWithKeys({ allItems: allItems, fromItem: subItem, parentIris: [...parentIris, iri] });
    }),
  ];
};
const getHierarchy = (breadcrumbParts: BreadcrumbPart[]) => {
  const itemsWithKeys = getTreeItemsWithKeys({ allItems: breadcrumbParts });

  let hierarchy;

  try {
    hierarchy =
      itemsWithKeys.length > 0
        ? stratify<ItemWithKeys>()
            .id((item) => item.key)
            .parentId((item) => item.parentKey)(itemsWithKeys)
        : undefined;
  } catch (e) {
    console.error(e);
  }
  return hierarchy;
};

export const useResourceBreadcrumbs = () => {
  const resource = useCurrentResource();
  const search = useCurrentSearch();
  const conceptSchemesQueryString = (search.conceptScheme as string) ?? "";
  const conceptSchemes = conceptSchemesQueryString.split(",").filter(Boolean);
  const { schemes, hierarchyProperties } = React.useContext(SkosTreeContext);

  const { data: breadcrumbParts, loading } = useCachedSparql<BreadcrumbPart[]>(
    !!hierarchyProperties &&
      breadcrumbPartsQuery(resource, conceptSchemes, hierarchyProperties, !schemes || schemes.length > 0),
  );

  const processedBreadcrumbs = React.useMemo(() => {
    if (loading || !breadcrumbParts) {
      return undefined;
    }

    const hierarchy = getHierarchy(breadcrumbParts);

    if (!hierarchy) return [];

    return hierarchy.leaves().map((leave) => {
      let breadcrumbs = [];
      while (leave.parent) {
        breadcrumbs.unshift(leave.data.iri);
        leave = leave.parent;
      }
      return breadcrumbs;
    });
  }, [breadcrumbParts, loading]);

  return processedBreadcrumbs;
};

const setInitialExpandedMap = (breadcrumbs: string[][], filters: string, expandedMap: ExpandedMap = {}) => {
  const returnExpandedMap = { ...expandedMap };

  for (const breadcrumb of breadcrumbs) {
    let pointer = returnExpandedMap;
    // The first level is the filter
    if (!pointer[filters]) pointer[filters] = {};
    pointer = pointer[filters];

    for (const [index, iri] of breadcrumb.entries()) {
      // All items except the last
      if (index !== breadcrumb.length - 1) {
        if (!pointer[iri]) pointer[iri] = {};
        pointer = pointer[iri];
      }
      // Last item
      else {
        pointer[iri] = {};
      }
    }
  }

  return returnExpandedMap;
};

const setTrail = (trail: string[], object: any) => {
  let pointer: any = object;
  for (const iri of trail) {
    if (!pointer[iri]) pointer[iri] = {};
    pointer = pointer[iri];
  }

  return object;
};

const collapseTrailEnd = (trail: string[], object: any) => {
  let pointer: any = object;
  for (const [index, iri] of trail.entries()) {
    // When we are in the last one we need to delete the item. Existence of the object is read as expanded state.
    if (index === trail.length - 1) {
      delete pointer[iri];
    }

    if (!pointer[iri]) return object;
    pointer = pointer[iri];
  }

  return object;
};

const ExpandedContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const search = useCurrentSearch();
  const conceptSchemesQueryString = (search.conceptScheme as string) ?? "";
  const breadcrumbs = useResourceBreadcrumbs();
  const [expandedMap, setExpandedMap] = useState<ExpandedMap>({});
  const [activeMap, setActiveMap] = useState<ExpandedMap>({});

  const setActive = React.useCallback(
    (trail: string[]) => {
      // General expanding
      setExpandedMap(setTrail(trail, { ...expandedMap }));
      // The active trail
      setActiveMap(setTrail(trail, {}));
    },
    [expandedMap],
  );

  const collapse = React.useCallback(
    (trail: string[]) => {
      setExpandedMap(collapseTrailEnd(trail, { ...expandedMap }));
    },
    [expandedMap],
  );

  const collapseAll = React.useCallback(() => {
    setExpandedMap({});
  }, []);

  const expand = React.useCallback(
    (trail: string[]) => {
      setExpandedMap(setTrail(trail, { ...expandedMap }));
    },
    [expandedMap],
  );

  React.useEffect(() => {
    if (breadcrumbs !== undefined) {
      setActiveMap(setInitialExpandedMap(breadcrumbs, conceptSchemesQueryString, {}));
      setExpandedMap((expandedMap) =>
        setInitialExpandedMap(breadcrumbs, conceptSchemesQueryString, { ...expandedMap }),
      );
    }
  }, [breadcrumbs, conceptSchemesQueryString]);

  const value = React.useMemo(
    () => ({ expandedMap, activeMap, setActive, collapse, expand, collapseAll }),
    [expandedMap, activeMap, setActive, collapse, expand, collapseAll],
  );

  return <ExpandedContext value={value}>{children}</ExpandedContext>;
};

export default ExpandedContextProvider;
