import getClassName from "classnames";
import * as connectedReactRouter from "connected-react-router";
import { format as formatDate } from "date-fns";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { SubmissionError } from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import { Avatar, Dialog, FontAwesomeIcon, Label, Markdown } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import type { Account } from "#reducers/accountCollection.ts";
import { addMember, createOrganization } from "#reducers/orgs.ts";
import * as styles from "./style.scss";

export interface Props {
  account: Account;
}

const MembersOrOrgs: React.FC<{ account: Account }> = ({ account }) => {
  const isUser = account.type === "user";
  const acl = useAcl();
  const membersOrOrgs: Account[] =
    account.type === "user" ? account.orgs || [] : (account.members || []).map((m) => m.user);
  const roleInAccount = acl.getRoleInAccount(account);
  const permissionToAdd =
    account.type === "user"
      ? acl.check({ action: "createOrganization", context: { roleInUser: roleInAccount } }).granted
      : acl.check({ action: "manageOrganizationMembers", context: { roleInOrganization: roleInAccount } }).granted;

  if (membersOrOrgs.length === 0 && !permissionToAdd) return null;
  return (
    <div className="mt-6">
      <h6>
        {permissionToAdd && (
          <Link to={`/${account.accountName}/-/settings/${isUser ? "orgs" : "members"}`}>
            {isUser ? "Organizations" : "Members"}
          </Link>
        )}
        {!permissionToAdd && (isUser ? "Organizations" : "Members")}
      </h6>
      <div className={styles.members}>
        {membersOrOrgs.map((a) => (
          <Avatar
            key={a.accountName}
            size="sm"
            avatarName={a.accountName}
            linkTo={`/${a.accountName}`}
            avatarUrl={a.avatarUrl}
            title={a.name || a.accountName}
            alt={a.name || a.accountName}
          />
        ))}
        {permissionToAdd && (
          <Link
            to={{
              state: isUser
                ? { orgAddModalShown: true, preserveScrollPosition: true }
                : { memberAddModalShown: true, preserveScrollPosition: true },
            }}
            className={styles.addMemberLink}
          >
            <div title={isUser ? "Create an organization" : "Add a member"} className={styles.addMember}>
              <FontAwesomeIcon icon="plus" size="xs" />
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

const toDateString = (date: Date) => {
  const replaceRegex = /([a-z]*),/;

  var dateString = formatDate(date, "LLL do, yyyy");
  //add <sup> tag to stuff like '26th' or '3rd'.
  //don't want to run formatdate twice, so use regex
  //I know ugly, but it works
  return <span dangerouslySetInnerHTML={{ __html: dateString.replace(replaceRegex, "<sup>$1</sup>,") }} />;
};

const AccountInfo: React.FC<Props> = ({ account }) => {
  const acl = useAcl();
  const dispatch = useDispatch();
  const constructUrlToApi = useConstructUrlToApi();
  const location = useLocation<{ orgAddModalShown?: boolean; memberAddModalShown?: boolean } | undefined>();

  if (!account) return null;

  const permissionToEdit = acl.check({
    action: "editAccountMetadata",
    context: { roleInAccount: acl.getRoleInAccount(account) },
  }).granted;

  const isUser = account.type === "user";

  const accountsUrl = constructUrlToApi({ pathname: "/accounts" });

  const goBack = () => dispatch(connectedReactRouter.goBack());

  const handleAddOrgSubmit = (values: Forms.OrgAdd.FormData) => {
    if (account.type === "user")
      return dispatch<typeof createOrganization>(createOrganization(account, values)).then(
        () => {
          dispatch(connectedReactRouter.replace(`/${values.accountName}`));
        },
        (e: any) => {
          if (e?.status === 409) {
            throw new SubmissionError({ accountName: "URL is already in use." });
          }
          throw new SubmissionError({ _error: e.message });
        },
      );
  };

  const handleAddMemberSubmit = (values: Forms.OrgMemberAdd.FormData) => {
    if (account.type === "org")
      return dispatch<typeof addMember>(addMember(account, values.name, values.role)).then(goBack, (e: any) => {
        throw new SubmissionError({ _error: e.message });
      });
  };

  return (
    <div className={styles.accountInfoContainer}>
      <Avatar
        linkTo={permissionToEdit ? `/${account.accountName}/-/settings` : undefined}
        avatarUrl={account.avatarUrl}
        avatarName={account.accountName}
        size="md"
        className={styles.avatar}
        alt=""
      />
      <h3 className="headerSpacing">{account.name || account.accountName}</h3>
      <div className="mt-2">
        <span title={isUser ? "User" : "Organization"} className="mr-3">
          <FontAwesomeIcon icon={account.type === "user" ? "user" : "users"} />
        </span>
        {account.accountName}
      </div>

      <div className="mt-4">
        <div className="flex center">
          <div className="grow">
            {isUser ? "Member since" : "Created on"} {toDateString(new Date(account.createdAt))}
          </div>
          {permissionToEdit && (
            <div className={styles.settings}>
              <Link to={`/${account.accountName}/-/settings`} title={`${isUser ? "User" : "Organization"} settings`}>
                <FontAwesomeIcon style={{ fontSize: 20 }} icon={["fas", "cog"]} />
              </Link>
            </div>
          )}
        </div>
      </div>

      {!isUser && account.email && (
        <a href={"mailto:" + account.email} target="_blank">
          {account.email}
        </a>
      )}

      {account.type === "user" && account.role === "siteAdmin" && (
        <div className="mt-3">
          <Label success message={"Site administrator"} />
        </div>
      )}
      {account.type === "user" && account.role === "superAdmin" && (
        <div className="mt-3">
          <Label success message="Super administrator" />
        </div>
      )}
      {account.type === "user" && !!account.disabled && (
        <div className="mt-3">
          <Label error message="Account disabled" />
        </div>
      )}
      {account.type === "user" && account.verified === false && (
        <div className="mt-3">
          <Label warning message="Account unverified" />
        </div>
      )}

      {account.description && (
        <Markdown className={getClassName("mt-6", styles.description)}>{account.description}</Markdown>
      )}

      <MembersOrOrgs account={account} />

      <Dialog
        open={!!location.state?.orgAddModalShown}
        onClose={goBack}
        title="Create organization"
        maxWidth="md"
        fullWidth
      >
        <Forms.OrgAdd className="pb-5 pl-5 pr-5 pt-3" onSubmit={handleAddOrgSubmit} cancel={goBack} />
      </Dialog>
      <Dialog maxWidth="lg" open={!!location.state?.memberAddModalShown} onClose={goBack} title="Add member">
        <Forms.OrgMemberAdd
          className="pb-5 pl-5 pr-5 pt-2"
          onSubmit={handleAddMemberSubmit}
          accountsUrl={accountsUrl}
          cancel={goBack}
        />
      </Dialog>
    </div>
  );
};
export default AccountInfo;
