import { produce } from "immer";
import { isEmpty, isEqual, omitBy } from "lodash-es";
import type { Models, Routes } from "@triply/utils";
import { parseSearchString } from "#helpers/utils.ts";
import type { Account, State as AccountCollectionState } from "#reducers/accountCollection.ts";
import { getAccount } from "#reducers/accountCollection.ts";
import type { Action, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";
import { Actions } from "#reducers/index.ts";

export const LocalActions = {
  GET_ACCOUNTS: "triply/accounts/GET_ACCOUNTS",
  GET_ACCOUNTS_SUCCESS: "triply/accounts/GET_ACCOUNTS_SUCCESS",
  GET_ACCOUNTS_FAIL: "triply/accounts/GET_ACCOUNTS_FAIL",
  UPDATE_PROFILE: "triply/accounts/UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS: "triply/accounts/UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAIL: "triply/accounts/UPDATE_PROFILE_FAIL",
  UPLOAD_ACCOUNT_AVATAR: "triply/accounts/UPLOAD_ACCOUNT_AVATAR",
  UPLOAD_ACCOUNT_AVATAR_SUCCESS: "triply/accounts/UPLOAD_ACCOUNT_AVATAR_SUCCESS",
  UPLOAD_ACCOUNT_AVATAR_FAIL: "triply/accounts/UPLOAD_ACCOUNT_AVATAR_FAIL",
  ADD_EXTRA_DETAILS: "triply/auth/ADD_EXTRA_DETAILS",
  ADD_EXTRA_DETAILS_SUCCESS: "triply/auth/ADD_EXTRA_DETAILS_SUCCESS",
  ADD_EXTRA_DETAILS_FAIL: "triply/auth/ADD_EXTRA_DETAILS_FAIL",
  IS_VALID_ACCOUNT_NAME: "triply/auth/IS_VALID_ACCOUNT_NAME",
  IS_VALID_ACCOUNT_NAME_SUCCESS: "triply/auth/IS_VALID_ACCOUNT_NAME_SUCCESS",
  IS_VALID_ACCOUNT_NAME_FAIL: "triply/auth/IS_VALID_ACCOUNT_NAME_FAIL",
  DELETE_ACCOUNT: "triply/accounts/DELETE_ACCOUNT",
  DELETE_ACCOUNT_SUCCESS: "triply/accounts/DELETE_ACCOUNT_SUCCESS",
  DELETE_ACCOUNT_FAIL: "triply/accounts/DELETE_ACCOUNT_FAIL",
  ADD_AUTHENTICATOR_APP: "triply/accounts/ADD_AUTHENTICATOR_APP",
  ADD_AUTHENTICATOR_APP_SUCCESS: "triply/accounts/ADD_AUTHENTICATOR_APP_SUCCESS",
  ADD_AUTHENTICATOR_APP_FAIL: "triply/accounts/ADD_AUTHENTICATOR_APP_FAIL",
  ENABLE_AUTHENTICATOR_APP: "triply/accounts/ENABLE_AUTHENTICATOR_APP",
  ENABLE_AUTHENTICATOR_APP_SUCCESS: "triply/accounts/ENABLE_AUTHENTICATOR_APP_SUCCESS",
  ENABLE_AUTHENTICATOR_APP_FAIL: "triply/accounts/ENABLE_AUTHENTICATOR_APP_FAIL",
  CONFIRM_AUTHENTICATOR_APP: "triply/accounts/CONFIRM_AUTHENTICATOR_APP",
  CONFIRM_AUTHENTICATOR_APP_SUCCESS: "triply/accounts/CONFIRM_AUTHENTICATOR_APP_SUCCESS",
  CONFIRM_AUTHENTICATOR_APP_FAIL: "triply/accounts/CONFIRM_AUTHENTICATOR_APP_FAIL",
} as const;

type GET_ACCOUNTS = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_ACCOUNTS,
      typeof LocalActions.GET_ACCOUNTS_SUCCESS,
      typeof LocalActions.GET_ACCOUNTS_FAIL,
    ];
    listFor?: State["listFor"];
    append: boolean;
  },
  Routes.accounts.Get
>;

type UPDATE_PROFILE = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_PROFILE,
      typeof LocalActions.UPDATE_PROFILE_SUCCESS,
      typeof LocalActions.UPDATE_PROFILE_FAIL,
    ];
    verbose: true;
    updateInfo: Models.UserUpdate;
    accountId: string;
  },
  Routes.account.Patch
>;

type UPLOAD_ACCOUNT_AVATAR = GlobalAction<
  {
    types: [
      typeof LocalActions.UPLOAD_ACCOUNT_AVATAR,
      typeof LocalActions.UPLOAD_ACCOUNT_AVATAR_SUCCESS,
      typeof LocalActions.UPLOAD_ACCOUNT_AVATAR_FAIL,
    ];
    verbose: true;
  },
  Routes.imgs.avatars.a._accountId.Post
>;

type ADD_EXTRA_DETAILS = GlobalAction<
  {
    types: [
      typeof LocalActions.ADD_EXTRA_DETAILS,
      typeof LocalActions.ADD_EXTRA_DETAILS_SUCCESS,
      typeof LocalActions.ADD_EXTRA_DETAILS_FAIL,
    ];
    verbose: true;
  },
  Routes.account.Patch
>;

type IS_VALID_ACCOUNT_NAME = GlobalAction<
  {
    types: [
      typeof LocalActions.IS_VALID_ACCOUNT_NAME,
      typeof LocalActions.IS_VALID_ACCOUNT_NAME_SUCCESS,
      typeof LocalActions.IS_VALID_ACCOUNT_NAME_FAIL,
    ];
  },
  Routes.account.Get
>;

type DELETE_ACCOUNT = GlobalAction<
  {
    types: [
      typeof LocalActions.DELETE_ACCOUNT,
      typeof LocalActions.DELETE_ACCOUNT_SUCCESS,
      typeof LocalActions.DELETE_ACCOUNT_FAIL,
    ];
    account: Account;
  },
  Routes.account.Delete
>;

type ADD_AUTHENTICATOR_APP = GlobalAction<
  {
    types: [
      typeof LocalActions.ADD_AUTHENTICATOR_APP,
      typeof LocalActions.ADD_AUTHENTICATOR_APP_SUCCESS,
      typeof LocalActions.ADD_AUTHENTICATOR_APP_FAIL,
    ];
    accountId: string;
  },
  Routes.account.mfa.authenticator.Post
>;
type CONFIRM_AUTHENTICATOR_APP = GlobalAction<
  {
    types: [
      typeof LocalActions.CONFIRM_AUTHENTICATOR_APP,
      typeof LocalActions.CONFIRM_AUTHENTICATOR_APP_SUCCESS,
      typeof LocalActions.CONFIRM_AUTHENTICATOR_APP_FAIL,
    ];
    accountId: string;
  },
  Routes.account.mfa.authenticator.confirm.Post
>;
type ENABLE_AUTHENTICATOR_APP = GlobalAction<
  {
    types: [
      typeof LocalActions.ENABLE_AUTHENTICATOR_APP,
      typeof LocalActions.ENABLE_AUTHENTICATOR_APP_SUCCESS,
      typeof LocalActions.ENABLE_AUTHENTICATOR_APP_FAIL,
    ];
    accountId: string;
    verbose: false;
  },
  Routes.account.mfa.authenticator.Patch
>;

export type LocalAction =
  | GET_ACCOUNTS
  | UPDATE_PROFILE
  | UPLOAD_ACCOUNT_AVATAR
  | ADD_EXTRA_DETAILS
  | IS_VALID_ACCOUNT_NAME
  | DELETE_ACCOUNT
  | ADD_AUTHENTICATOR_APP
  | CONFIRM_AUTHENTICATOR_APP
  | ENABLE_AUTHENTICATOR_APP;

export interface State {
  list: string[];
  listFor?: ListFor;
  fetchingList: boolean;
  fetchingListError?: string;
  nextPage?: string;
}

export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.GET_ACCOUNTS:
        draftState.fetchingList = true;
        return;
      case Actions.GET_ACCOUNTS_SUCCESS:
        draftState.fetchingList = false;
        draftState.fetchingListError = undefined;
        draftState.nextPage =
          action.meta && action.meta.links && action.meta.links["next"] ? action.meta.links["next"].url : undefined;

        if (action.append) {
          draftState.list = [...draftState.list, ...action.result.map((account) => account.uid)];
          return;
        }
        draftState.list = action.result.map((account) => account.uid);
        draftState.listFor = action.listFor;
        return;
      case Actions.GET_ACCOUNTS_FAIL:
        draftState.fetchingList = false;
        draftState.nextPage = undefined;
        draftState.list = [];
        draftState.listFor = action.listFor;
        draftState.fetchingListError = action.message;
        return;
      case Actions.DELETE_ACCOUNT_SUCCESS:
        draftState.list = draftState.list.filter((accountId) => accountId !== action.account.uid);
        return;
      case Actions.CREATE_ORG_SUCCESS:
      case Actions.ADMIN_REGISTER_SUCCESS:
        draftState.list = [action.result.uid, ...draftState.list];
        return;
    }
  },
  {
    list: [],
    listFor: undefined,
    fetchingList: false,
    fetchingListError: undefined,
    nextPage: undefined,
  } as State,
);
export interface ListFor {
  searchTerm?: string | false;
}

export function getAccounts(
  withLink?: string,
  query?: Routes.accounts.Get["Req"]["Query"],
): BeforeDispatch<GET_ACCOUNTS> {
  return {
    types: [LocalActions.GET_ACCOUNTS, LocalActions.GET_ACCOUNTS_SUCCESS, LocalActions.GET_ACCOUNTS_FAIL],
    promise: (client) => {
      if (withLink)
        return client.req({
          url: withLink,
          method: "get",
          query,
        });

      return client.req({
        pathname: "/accounts",
        method: "get",
        query,
      });
    },
    listFor: withLink ? undefined : { searchTerm: !!query?.q && query?.q },
    append: !!withLink,
  };
}

export function isAccountListLoaded(state: GlobalState, urlSearchString: string) {
  const query = parseSearchString(urlSearchString);

  return (
    !state.accounts.fetchingListError && isEqual(state.accounts?.listFor?.searchTerm, !!query && !!query.q && query.q)
  );
}

function getAccountBaseRequestPath(account: Account) {
  return "/accounts/" + account.accountName;
}

export function updateProfile(account: Account, updateInfo: Models.UserUpdate): BeforeDispatch<UPDATE_PROFILE> {
  return {
    types: [LocalActions.UPDATE_PROFILE, LocalActions.UPDATE_PROFILE_SUCCESS, LocalActions.UPDATE_PROFILE_FAIL],
    promise: (client) =>
      client.req({
        pathname: getAccountBaseRequestPath(account),
        query: {
          verbose: null,
        },
        method: "patch",
        body: updateInfo,
      }),
    verbose: true,
    updateInfo: updateInfo,
    accountId: account.uid,
  };
}

export function uploadAvatar(foruser: Account, file: File): BeforeDispatch<UPLOAD_ACCOUNT_AVATAR> {
  return {
    types: [
      LocalActions.UPLOAD_ACCOUNT_AVATAR,
      LocalActions.UPLOAD_ACCOUNT_AVATAR_SUCCESS,
      LocalActions.UPLOAD_ACCOUNT_AVATAR_FAIL,
    ],
    promise: (client) =>
      client.req({
        pathname: "/imgs/avatars/a/" + foruser.uid,
        query: {
          verbose: undefined,
        },
        method: "post",
        files: { avatar: file },
      }),
    verbose: true,
  };
}

export function deleteAccount(account: Account, dissenting?: true): BeforeDispatch<DELETE_ACCOUNT> {
  return {
    types: [LocalActions.DELETE_ACCOUNT, LocalActions.DELETE_ACCOUNT_SUCCESS, LocalActions.DELETE_ACCOUNT_FAIL],
    promise: (client) =>
      client.req({
        pathname: getAccountBaseRequestPath(account),
        method: "delete",
        query: dissenting
          ? {
              dissenting,
            }
          : undefined,
      }),
    account,
  };
}

/**

Have to use the /me route: we might be adding an account name. If a user did not have an account name before (e.g. oauth login)
then there's no path to send the request to
**/
export function submitExtraUserDetails(details: {
  accountName?: string;
  email?: string;
}): BeforeDispatch<ADD_EXTRA_DETAILS> {
  return {
    types: [
      LocalActions.ADD_EXTRA_DETAILS,
      LocalActions.ADD_EXTRA_DETAILS_SUCCESS,
      LocalActions.ADD_EXTRA_DETAILS_FAIL,
    ],
    promise: (client) =>
      client.req({
        pathname: "/me",
        method: "patch",
        query: {
          verbose: null,
        },
        body: omitBy(details, isEmpty),
      }),
    verbose: true,
  };
}

export function checkAccountName(
  formItemName: string,
  accountName: string,
  expectToExist = false,
  type?: Models.AccountType,
): BeforeDispatch<IS_VALID_ACCOUNT_NAME> {
  return {
    types: [
      LocalActions.IS_VALID_ACCOUNT_NAME,
      LocalActions.IS_VALID_ACCOUNT_NAME_SUCCESS,
      LocalActions.IS_VALID_ACCOUNT_NAME_FAIL,
    ],
    //promise should return object where key is that of the form item key, with value as error message
    promise: (client) =>
      client
        .req({
          pathname: "/accounts/" + accountName,
          method: "get",
          query: {
            fields: "accountName,type",
          },
          statusCodeMap: { 404: 202 },
        })
        .then((result) => {
          if (result.body && result.body.type && type && expectToExist && result.body.type !== type) {
            throw {
              [formItemName]: `Expected ${type === "user" ? "a user" : "an organization"}. ${
                result.body.accountName
              } is a ${type === "user" ? "user" : "organization"}.`,
            };
          }
          if (!expectToExist && result.body.accountName)
            throw {
              [formItemName]: "Account name '" + result.body.accountName + "' already exists",
            };
          if (expectToExist && !result.body.accountName) {
            throw {
              [formItemName]: "Account '" + accountName + "' not found",
            };
          }
          return result;
        }),
  };
}
export function setupAuthenticator(accountName: string, accountId: string): BeforeDispatch<ADD_AUTHENTICATOR_APP> {
  return {
    types: [
      LocalActions.ADD_AUTHENTICATOR_APP,
      LocalActions.ADD_AUTHENTICATOR_APP_SUCCESS,
      LocalActions.ADD_AUTHENTICATOR_APP_FAIL,
    ],
    promise: (client) => client.req({ pathname: "/accounts/" + accountName + "/mfa/authenticator", method: "post" }),
    accountId,
  };
}
export function verifyAuthenticator(
  accountName: string,
  accountId: string,
  token: string,
): BeforeDispatch<CONFIRM_AUTHENTICATOR_APP> {
  return {
    types: [
      LocalActions.CONFIRM_AUTHENTICATOR_APP,
      LocalActions.CONFIRM_AUTHENTICATOR_APP_SUCCESS,
      LocalActions.CONFIRM_AUTHENTICATOR_APP_FAIL,
    ],
    promise: (client) =>
      client.req({
        pathname: "/accounts/" + accountName + "/mfa/authenticator/confirm",
        method: "post",
        body: { token: token },
      }),
    accountId,
  };
}
export function setAuthenticatorEnabled(
  accountName: string,
  accountId: string,
  enabled: boolean,
): BeforeDispatch<ENABLE_AUTHENTICATOR_APP> {
  return {
    types: [
      LocalActions.ENABLE_AUTHENTICATOR_APP,
      LocalActions.ENABLE_AUTHENTICATOR_APP_SUCCESS,
      LocalActions.ENABLE_AUTHENTICATOR_APP_FAIL,
    ],
    promise: (client) =>
      client
        .req({
          pathname: "/accounts/" + accountName + "/mfa/authenticator",
          method: "patch",
          body: { enabled: enabled },
        })
        .then((result) => {
          return result;
        }),
    accountId,
    verbose: false,
  };
}
export function getRoleInAccount(accountName?: string, otherAccount?: Account): Models.OrgRole | undefined {
  if (!accountName || !otherAccount) return undefined;
  if (otherAccount.type === "user") {
    if (accountName === otherAccount.accountName) return "owner";
    return undefined;
  } else {
    return otherAccount?.members?.find((m) => {
      return m.user.accountName === accountName;
    })?.role;
  }
}
export function getRoleInAccountFromState(state: AccountCollectionState, accountId?: string, otherAccountId?: string) {
  if (!accountId || !otherAccountId) return undefined;
  const otherAccount = state[otherAccountId];
  if (otherAccount?.type === "user") {
    if (accountId === otherAccount.uid) return "owner";
    return undefined;
  } else {
    return otherAccount?.members?.find((m) => {
      return m.user === accountId;
    })?.role;
  }
}
export function accountHasPasswordAuthentication(account: Account) {
  return account.type === "user" && account.authMethod === "password";
}

export function getAccountList(state: GlobalState): Account[] {
  return state.accounts.list.map((a) => getAccount(state, a, false)).filter((a) => !!a) as Account[];
}
