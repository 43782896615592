import type { CompletionContext, CompletionResult, CompletionSource } from "@codemirror/autocomplete";
import { syntaxTree } from "@codemirror/language";
import type { EditorState } from "@codemirror/state";
import type { SyntaxNode } from "@lezer/common";
import debounce from "debounce-promise"; // Remove dep (and @types dev dev). Replace with our own logic, or replace with p-debounce
import Debug from "debug";
import type { Prefix } from "@triply/utils";
import { getPrefixed, getPrefixInfoFromIri, getPrefixInfoFromPrefixedValue } from "@triply/utils/prefixUtils";
import { definedPrefixesField } from "../fields/definedPrefixes.ts";
import {
  Bind,
  BlankNodePropertyList,
  BlankNodePropertyListPath,
  ConstructTemplate,
  GroupGraphPattern,
  InlineDataFull,
  InlineDataOneVar,
  PathSequence,
  PropertyListNotEmpty,
  PropertyListPathNotEmpty,
  RDFLiteral,
  STRING_LITERAL_LONG1,
  STRING_LITERAL_LONG2,
  STRING_LITERAL1,
  STRING_LITERAL2,
  TriplesSameSubject,
  TriplesSameSubjectPath,
} from "../grammar/sparqlParser.terms.ts";

const debug = Debug("triply:sparql-editor:autocomplete:terms");
export type TermAutocompleteFunction = (searchString: string, position: TermPosition) => Promise<string[]>;

export type TermPosition = "subject" | "predicate" | "object" | "all";

export function getTermAutocompletion(
  getAutocompleteTerms: TermAutocompleteFunction,
  debounceTime = 250,
): CompletionSource {
  const debouncedAutocomplete = debounce(getAutocompleteTerms, debounceTime);
  async function getPrefixSuggestion(
    node: { from: number; to: number },
    completionString: string,
    definedPrefixes: Prefix[],
    position: TermPosition,
  ): Promise<CompletionResult> {
    const info = getPrefixInfoFromPrefixedValue(completionString, definedPrefixes);
    return {
      from: node.from,
      to: node.to,
      filter: false,
      options: (await debouncedAutocomplete(`${info.iri}${deEscapeLocalName(info.localName || "")}`, position)).map(
        (completion) => {
          const prefixInfo = getPrefixInfoFromIri(completion, definedPrefixes);
          return {
            label: getPrefixed(completion, definedPrefixes),
            apply: `${prefixInfo.prefixLabel}:${escapeLocalName(prefixInfo.localName || "")}`,
          };
        },
      ),
    };
  }
  async function getIriSuggestion(
    node: { from: number; to: number },
    completionString: string,
    position: TermPosition,
  ): Promise<CompletionResult> {
    return {
      from: node.from,
      to: node.to,
      filter: false,

      options: (await debouncedAutocomplete(completionString, position)).map((completion) => {
        return {
          label: completion,
          apply: `<${completion}>`,
        };
      }),
    };
  }
  async function getLiteralSuggestion(
    node: { from: number; to: number },
    completionString: string,
    position: "object" | "all",
  ): Promise<CompletionResult> {
    return {
      from: node.from,
      to: node.to,
      filter: false,
      options: (await debouncedAutocomplete(completionString, position)).map((completion) => ({
        label: completion,
        filter: false,
        apply: formatLiteral(completion),
      })),
    };
  }
  /**
   * This function will take a SPARQL literal value and change it to a statements API query
   */
  function getLiteralSearchString(node: SyntaxNode, state: EditorState) {
    // Remove the outlying quotes
    if (node.type.id === STRING_LITERAL_LONG1 || node.type.id === STRING_LITERAL_LONG2) {
      const searchTerm = state.sliceDoc(node.from + 3, node.to - 3);
      return `"${searchTerm}`;
    }
    // Remove the outlying quotes
    if (node.type.id === STRING_LITERAL1 || node.type.id === STRING_LITERAL2) {
      return `"${state.sliceDoc(node.from + 1, node.to - 1)}`;
    }
    // This could either be an incomplete literal or a special literal that doesn't require quotes
    const term = state.sliceDoc(node.from, node.to);
    if (term[0] === "'" || term[0] === '"') return `"${term.slice(1)}`;
    return `"${term}`;
  }

  async function termAutocomplete(context: CompletionContext): Promise<CompletionResult | null> {
    const { state, pos } = context;
    const tree = syntaxTree(state);
    const autocompleteNode = tree.resolve(pos, -1);

    // Valid triple pattern positions, starting with IRIs
    if (autocompleteNode.name === "IRIREF") {
      // Don't care about position for Values clause
      let position: TermPosition | undefined;
      if (autocompleteNode.matchContext(["DataBlockValue"])) {
        debug("IRI Values");
        position = "all";
      } else if (
        autocompleteNode.matchContext(["PathPrimary"]) || // Select
        autocompleteNode.matchContext(["PropertyListNotEmpty", "Verb", "VarOrIri"]) // Construct
      ) {
        debug("IRI Predicate");
        position = "predicate";
      } else if (
        autocompleteNode.matchContext(["TriplesSameSubjectPath", "VarOrTerm", "GraphTerm"]) || // Select
        autocompleteNode.matchContext(["TriplesSameSubject", "VarOrTerm", "GraphTerm"]) // Construct
      ) {
        debug("IRI Subject");
        position = "subject";
      } else if (
        autocompleteNode.matchContext(["GraphNodePath", "VarOrTerm", "GraphTerm"]) || // Select
        autocompleteNode.matchContext(["GraphNode", "VarOrTerm", "GraphTerm"]) // Construct
      ) {
        debug("IRI Object");
        position = "object";
      } else if (autocompleteNode.matchContext(["PrimaryExpression"])) {
        // Inside expressions
        position = "all";
      }
      const autocompleteString = state.sliceDoc(autocompleteNode.from + 1, autocompleteNode.to - 1); // Omit '<>'
      if (position) return getIriSuggestion(autocompleteNode, autocompleteString || "h", position);
      // Next-up literals
    } else if (
      (autocompleteNode.matchContext(["GraphNodePath", "VarOrTerm", "GraphTerm", "", ""]) || // Select
        autocompleteNode.matchContext(["GraphNode", "VarOrTerm", "GraphTerm", "", ""])) && // Construct
      !autocompleteNode.type.isError
    ) {
      debug("Object(literal)");
      return getLiteralSuggestion(autocompleteNode, getLiteralSearchString(autocompleteNode, state), "object");

      // Values clause
    } else if (autocompleteNode.matchContext(["DataBlockValue", "", ""])) {
      debug("Values clause");
      return getLiteralSuggestion(autocompleteNode, getLiteralSearchString(autocompleteNode, state), "all");
    } else if (autocompleteNode.matchContext(["PrimaryExpression", "", ""])) {
      debug("Valid Expression");
      // Inside expressions
      return getLiteralSuggestion(autocompleteNode, getLiteralSearchString(autocompleteNode, state), "all");
      // Prefixes
    } else if (autocompleteNode.name === "PNAME_NS" || autocompleteNode.name === "PNAME_LN") {
      const definedPrefixes = state.field(definedPrefixesField);
      const autocompleteString = state.sliceDoc(autocompleteNode.from, autocompleteNode.to);
      let position: TermPosition | undefined;
      if (
        autocompleteNode.matchContext(["TriplesSameSubjectPath", "VarOrTerm", "GraphTerm", "PrefixedName"]) || // Select
        autocompleteNode.matchContext(["TriplesSameSubject", "VarOrTerm", "GraphTerm", "PrefixedName"]) // Construct
      ) {
        position = "subject";
        debug("Subject prefix");
      } else if (
        autocompleteNode.matchContext(["PathPrimary", "PrefixedName"]) || // Select
        autocompleteNode.matchContext(["PropertyListNotEmpty", "Verb", "VarOrIri", "PrefixedName"]) // Construct
      ) {
        position = "predicate";
        debug("Predicate prefix");
      } else if (
        autocompleteNode.matchContext(["GraphNodePath", "VarOrTerm", "GraphTerm", "PrefixedName"]) || // Select
        autocompleteNode.matchContext(["GraphNode", "VarOrTerm", "GraphTerm", "PrefixedName"]) // Construct
      ) {
        position = "object";
        debug("Object prefix");
      } else if (autocompleteNode.matchContext(["DataBlockValue", "PrefixedName"])) {
        // Values
        position = "all";
        debug("Values prefix");
      } else if (autocompleteNode.matchContext(["PrimaryExpression", "PrefixedName"])) {
        // Inside expressions
        position = "all";
      }
      if (position) return getPrefixSuggestion(autocompleteNode, autocompleteString, definedPrefixes, position);
    }
    // If we're an error, try and see if we're an incomplete IRI or Literal
    if (autocompleteNode.type.isError) {
      const autocompleteText = state.sliceDoc(autocompleteNode.from, autocompleteNode.to);
      if (autocompleteText[0] !== "<" && autocompleteText[0] !== '"' && autocompleteText[0] !== "'") return null;
      let position: TermPosition | undefined;
      if (
        autocompleteNode.parent?.type.id === GroupGraphPattern || // Select
        autocompleteNode.parent?.type.id === ConstructTemplate // Construct
      ) {
        position = "subject";
        debug("Error Subject");
      } else if (
        autocompleteNode.parent?.type.id === TriplesSameSubjectPath || // Select
        autocompleteNode.parent?.type.id === TriplesSameSubject || // Construct
        autocompleteNode.parent?.type.id === PathSequence || // Select pathSequence
        autocompleteNode.parent?.type.id === BlankNodePropertyListPath || // Select anonymous property
        autocompleteNode.parent?.type.id === BlankNodePropertyList // Construct anonymous property
      ) {
        debug("Error Predicate");
        position = "predicate";
      } else if (
        autocompleteNode.parent?.type.id === PropertyListPathNotEmpty || // Select
        autocompleteNode.parent?.type.id === PropertyListNotEmpty // Construct
      ) {
        position = "object";

        debug("Error Object");
      } else if (
        autocompleteNode.parent?.type.id === RDFLiteral && // Incomplete Literal
        autocompleteNode.parent.from === autocompleteNode.from - 2 // Should only match ''' or """ offset is due to the first two making a valid token
      ) {
        // Returning here already so not complicate the generic return
        debug("Error Incomplete long literal)");
        return getLiteralSuggestion(
          {
            from: autocompleteNode.from - 2, //Include the valid part of the autocomplete
            to: autocompleteNode.to,
          },
          getLiteralSearchString(autocompleteNode, state),
          "object",
        );
      } else if (
        // Values
        autocompleteNode.parent?.type.id === InlineDataOneVar ||
        autocompleteNode.parent?.type.id === InlineDataFull ||
        // Bindings
        autocompleteNode.parent?.type.id === Bind
      ) {
        position = "all";
        debug("Error Values");
      }
      if (position) {
        if (autocompleteText[0] === "<") {
          return getIriSuggestion(autocompleteNode, autocompleteText.substring(1) || "h", position); // Omit < and force http*
        } else if (position === "object" || position === "all") {
          // We know it starts with " or '
          return getLiteralSuggestion(autocompleteNode, getLiteralSearchString(autocompleteNode, state), position);
        }
      }
    }
    return null;
  }
  return termAutocomplete;
}
function escapeLocalName(localName: string) {
  return localName.replace(/([_\-$&'()*+,;=\/?#@%~.!])/g, "\\$1");
}
function deEscapeLocalName(localName: string) {
  return localName.replace(/\\([_\-$&'()*+,;=\/?#@%~.!])/g, "$1");
}

function formatLiteral(literalString: string) {
  const stringOfLiteral = literalString.slice(1, literalString.lastIndexOf('"'));
  /**
   * Fix for how editor injects escaped backslashes.
   */
  literalString =
    '"' + stringOfLiteral.replace(/\\/g, "\\$&") + '"' + literalString.slice(literalString.lastIndexOf('"') + 1);

  if (literalString.includes("\n")) {
    return `""${literalString.slice(0, literalString.lastIndexOf('"'))}""${literalString.slice(
      literalString.lastIndexOf('"'),
    )}`;
  }

  const matchDoubleQuotes = literalString.match(/\"/g);
  const matchSingleQuotes = literalString.match(/\'/g);
  const matchingTripleDoubleQuotes = literalString.match(/\"\"\"/g);
  const matchingTripleSingleQuotes = literalString.match(/\'\'\'/g);
  /**
   * Notice that we don't care if there are only single quotes,
   * because then our literal is valid with external double quotes.
   */
  if (matchDoubleQuotes && matchDoubleQuotes.length >= 3) {
    /**
     * If there are only double quotes and no single quotes inside the literal,
     *  then we replace the outside double quotes (") with single quotes('),
     * e.g. "some"thing" to 'some"thing'
     */
    if (!matchSingleQuotes)
      return (
        "'" +
        literalString.slice(1, literalString.lastIndexOf('"')) +
        "'" +
        literalString.slice(literalString.lastIndexOf('"') + 1)
      );

    /**
     * If there are double quotes AND single quotes inside the literal without """ inside the literal,
     * then we put triple quotes around the literal (""").
     *
     */
    if (!matchingTripleDoubleQuotes)
      return `""${literalString.slice(0, literalString.lastIndexOf('"'))}""${literalString.slice(
        literalString.lastIndexOf('"'),
      )}`;
    /**
     * If there are double quotes and single quotes inside the literal with
     * A) """ inside the literal
     * A) no ''' inside the literal OR
     * B) no '' at the end OR
     * C) no '' at the beginning of the string,
     * then we put triple quotes around the literal ('''),
     * e.g. "so"""me'th'ing" to '''so"""me'th'ing'''
     *
     */
    if (
      !matchingTripleSingleQuotes &&
      literalString.indexOf("\"''") !== 0 &&
      literalString.lastIndexOf("''\"") !== literalString.lastIndexOf('"') - 2
    ) {
      return `'''${literalString.slice(1, literalString.lastIndexOf('"'))}'''${literalString.slice(
        literalString.lastIndexOf('"') + 1,
      )}`;
    }

    /**
     * If there are double and single quotes with triple double quotes in the string(""")
     * and one or more of the above cases of single quotes,
     * then we use the less frequent quote outside of the string and put backlash before it inside the string,
     * e.g "some"""th''ing" to 'some"""th\'\'ing'
     *
     */
    if (matchSingleQuotes.length >= matchDoubleQuotes.length - 2) return addBackslashInliteral(literalString, "'");
    return addBackslashInliteral(literalString, '"');
  }

  return literalString;
}

type QuoteType = "'" | '"';

function addBackslashInliteral(literal: string, mostFreqTripleQuote: QuoteType) {
  const leastFreqTripleQuote: QuoteType = mostFreqTripleQuote === '"' ? "'" : '"';
  const stringOfLiteral = literal.slice(1, literal.lastIndexOf('"'));
  return (
    leastFreqTripleQuote +
    stringOfLiteral.replace(new RegExp(leastFreqTripleQuote, "g"), `\\${leastFreqTripleQuote}`) +
    leastFreqTripleQuote +
    literal.slice(literal.lastIndexOf('"') + 1)
  );
}
