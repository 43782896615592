// extracted by mini-css-extract-plugin
var _1 = "tableStyle--actionButtons--OGz4RurN";
var _2 = "tableStyle--alignEnd--Vk64Ux2x";
var _3 = "tableStyle--cell--d6fKtxFt";
var _4 = "tableStyle--filterCell--EZN82qNJ";
var _5 = "tableStyle--header--_lVNAN3K";
var _6 = "tableStyle--noBorderBottom--qz5ymIhA";
var _7 = "tableStyle--noContentCell--pB3P3mJ5";
var _8 = "tableStyle--paginationOptions--iAKaRqAH";
var _9 = "tableStyle--slider--lrJgVchy";
var _a = "tableStyle--sorting--ilRm8hZI";
var _b = "tableStyle--space--smjtMjE1";
var _c = "tableStyle--subComponentCell--QFMOAZfc";
var _d = "tableStyle--tableContainer--MZTiETAt";
var _e = "tableStyle--tablePaper--YGtXMzVk";
export { _1 as "actionButtons", _2 as "alignEnd", _3 as "cell", _4 as "filterCell", _5 as "header", _6 as "noBorderBottom", _7 as "noContentCell", _8 as "paginationOptions", _9 as "slider", _a as "sorting", _b as "space", _c as "subComponentCell", _d as "tableContainer", _e as "tablePaper" }
