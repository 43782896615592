import { Checkbox } from "@mui/material";
import getClassName from "classnames";
import { filter as _filter, find } from "lodash-es";
import * as React from "react";
import type { NtriplyStatement, Prefixes } from "@triply/utils/Models";
import { LoadingButton } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useTriplesTablesActionsEnabled from "#helpers/hooks/useTriplesTableActionsEnabled.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import type { PrefixUpdate } from "#reducers/datasetManagement.ts";
import type { Position, Term, Triple, Triples } from "#reducers/triples.ts";
import AddStatements from "./Actions/AddStatements.tsx";
import EditStatements from "./Actions/EditStatements.tsx";
import RemoveStatements from "./Actions/RemoveStatements.tsx";
import Field from "./field.tsx";
import FilterField from "./filterField.tsx";
import type { Query } from "./index.tsx";
import * as styles from "./style.scss";

interface Props {
  className?: string;
  triples: Triples;
  goToNextPage?: React.MouseEventHandler<any>;
  createPrefix?: (prefix: PrefixUpdate) => void;
  fetchingTriples: boolean;
  query: Query;
  prefixes: Prefixes;
  filter: (query: Object) => void;
  termPath: string;
  pathname: string;
}

// Get unique key for each triple in the tabular-browser
const getStatementId = (statement: Triple) =>
  statement[0].value +
  statement[1].value +
  statement[2].value +
  statement[2].datatype +
  statement[2].language +
  statement[3]?.value;

const TriplesTable: React.FC<Props> = ({
  className,
  goToNextPage,
  triples,
  fetchingTriples,
  query,
  createPrefix,
  filter,
  pathname,
  prefixes,
  termPath,
}) => {
  const [selectedTriples, setSelectedTriples] = React.useState<{ id: string; quad: NtriplyStatement }[]>([]);
  const acl = useAcl();
  const tableActionsEnabled = useTriplesTablesActionsEnabled();
  const currentAccount = useCurrentAccount();
  const mayManageGraphs = acl.check({
    action: "manageGraphs",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;
  const showTableActions = tableActionsEnabled && mayManageGraphs;
  const activeClasses = {
    [styles.tableWrapper]: !!styles.tableWrapper,
    [className || ""]: !!className,
    [styles.filterSub]: !!query.subject,
    [styles.filterPred]: !!query.predicate,
    [styles.filterObj]: !!query.object,
    [styles.filterGraph]: !!query.graph,
    shadow: true,
  };

  const triplesWithId = triples.map((triple) => {
    return { id: getStatementId(triple), quad: triple };
  });
  const getFilterFieldProps = (pos: Position): FilterField.Props => {
    return {
      pos: pos,
      initialValue: query[pos] || "",
      selectedGraph: query.graph,
      prefixes: prefixes,
      handleFilterChange: (newVal, reset = false) => {
        if (reset) {
          filter({ [pos]: newVal });
        } else {
          filter({ ...query, [pos]: newVal });
        }
      },
      termPath: termPath,
    };
  };

  const renderField = (term: Term | undefined, pos: Position) => (
    <Field
      term={term}
      pos={pos}
      query={query}
      prefixes={prefixes}
      createPrefix={createPrefix}
      pathname={pathname}
      filter={filter}
    />
  );

  return (
    <div className={getClassName(activeClasses)}>
      {showTableActions && (
        <div className="flex">
          <AddStatements query={query} />
          {selectedTriples.length > 0 && (
            <>
              <EditStatements quadsToEdit={selectedTriples.map((triple) => triple.quad)} />
              <RemoveStatements quadsToRemove={selectedTriples.map((triple) => triple.quad)} />
            </>
          )}
        </div>
      )}

      <table className={styles.table}>
        <thead>
          <tr>
            {showTableActions && triples.length > 0 && (
              <th>
                <Checkbox
                  checked={selectedTriples.length === triples.length}
                  indeterminate={selectedTriples.length > 0 && selectedTriples.length !== triples.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedTriples(triplesWithId);
                    } else {
                      setSelectedTriples([]);
                    }
                  }}
                />
              </th>
            )}

            <th>
              <label htmlFor="subject">Subject</label>
              <FilterField key={"subject" + (query["subject"] || "")} {...getFilterFieldProps("subject")} />
            </th>
            <th>
              <label htmlFor="predicate">Predicate</label>
              <FilterField key={"predicate" + (query["predicate"] || "")} {...getFilterFieldProps("predicate")} />
            </th>
            <th>
              <label htmlFor="object">Object</label>
              <FilterField key={"object" + (query["object"] || "")} {...getFilterFieldProps("object")} />
            </th>
            <th>
              <label htmlFor="graph">Graph</label>
              <FilterField key={"graph" + (query["graph"] || "")} {...getFilterFieldProps("graph")} />
            </th>
          </tr>
        </thead>
        <tbody>
          {triplesWithId &&
            triplesWithId.map((triple) => (
              <tr key={triple.id} className={styles.row}>
                {showTableActions && (
                  <td>
                    <Checkbox
                      checked={find(selectedTriples, (selected) => selected.id === triple.id) !== undefined}
                      onChange={(e) => {
                        let temp = selectedTriples;
                        if (e.target.checked) {
                          temp = [triple, ...selectedTriples];
                        } else {
                          temp = _filter(temp, (tempTriple) => tempTriple.id !== triple.id);
                        }
                        setSelectedTriples(temp);
                      }}
                    />
                  </td>
                )}
                <td className={styles.sub}>{renderField(triple.quad[0], "subject")}</td>
                <td className={styles.pred}>{renderField(triple.quad[1], "predicate")}</td>
                <td className={styles.obj}>{renderField(triple.quad[2], "object")}</td>
                <td className={styles.graph}>{renderField(triple.quad[3], "graph")}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {goToNextPage && (
        <div className={styles.moreBtn}>
          <LoadingButton elevation onClick={goToNextPage} loading={fetchingTriples}>
            Show more...
          </LoadingButton>
        </div>
      )}
    </div>
  );
};

export default TriplesTable;
