import { validation } from "@core/utils";
import { InputAdornment } from "@mui/material";
import memoizee from "memoizee";
import * as React from "react";
import { useSelector } from "react-redux";
import * as ReduxForm from "redux-form";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import {
  Alert,
  Button,
  FontAwesomeIcon,
  ForgotPasswordLink,
  FormField,
  MuiPasswordFieldRedux,
  MuiTextFieldRedux,
} from "#components/index.ts";
import type { NewPasswordProps } from "#components/MuiPasswordField/index.tsx";
import type { GlobalState } from "#reducers/index.ts";

const emailValidator = validation.toStringValidator(validation.emailValidations);
const passwordValidator = validation.toStringValidator(validation.passwordValidations);

namespace Login {
  export interface FormData {
    accountName: string;
    email: string;
    password?: string;
  }
  export type Props = OwnProps & Partial<ReduxForm.InjectedFormProps<FormData>>;
  export interface OwnProps extends Partial<ReduxForm.ConfigProps<FormData>> {
    cancel?: React.EventHandler<React.MouseEvent<any>>;
    className?: string;
  }
}

const LoginForm: React.FC<Login.Props> = ({
  handleSubmit,
  error,
  submitting,
  pristine,
  invalid,
  cancel,
  className,
}) => {
  const email = useSelector((state: GlobalState) => ReduxForm.formValueSelector("login")(state, "email"));
  return (
    <form className={className} onSubmit={handleSubmit}>
      <FormField label="Email address" className="mb-5" inputId="email">
        <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
          name="email"
          props={{
            type: "email",
            inputProps: {
              id: "email",
            },
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon style={{ cursor: "default" }} icon="envelope" />
                </InputAdornment>
              ),
            },
            autoComplete: "email",
            autoFocus: true,
            formIsPristine: pristine,
            fullWidth: true,
          }}
          component={MuiTextFieldRedux}
        />
      </FormField>
      <FormField label="Password" className="mb-5" inputId="password">
        <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props & NewPasswordProps>>
          name="password"
          props={{
            inputProps: {
              id: "password",
            },
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon style={{ cursor: "default" }} icon="lock" />
                </InputAdornment>
              ),
            },
            autoComplete: "current-password",
            fullWidth: true,
            isNewPasswordField: false,
          }}
          component={MuiPasswordFieldRedux}
        />
      </FormField>

      <Alert transparent message={error} />
      <div style={{ marginTop: 20, display: "flex", alignItems: "baseline" }}>
        <LoadingButton
          disabled={pristine || invalid}
          type="submit"
          color="secondary"
          onClick={handleSubmit}
          loading={submitting}
        >
          Login
        </LoadingButton>
        {!!cancel && (
          <Button onClick={cancel} className="ml-2" variant="text">
            Cancel
          </Button>
        )}
        <ForgotPasswordLink className="ml-3" email={email} />
      </div>
    </form>
  );
};

const Login = ReduxForm.reduxForm<Login.FormData, Login.Props>({
  form: "login",
  validate: memoizee(
    (formData: Login.FormData, _props: Login.Props) => {
      return {
        email: emailValidator(formData.email),
        password: passwordValidator(formData.password),
      };
    },
    { max: 10 },
  ),
})(LoginForm as any);

export default Login;
