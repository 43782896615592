import type { PasswordDictionaryInfo } from "@core/utils/validation";
import { getUserDictionary } from "@core/utils/validation";
import { Alert, Collapse, Container, IconButton, InputAdornment, LinearProgress, Paper, Tooltip } from "@mui/material";
import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import * as React from "react";
import { useSelector } from "react-redux";
import type * as ReduxForm from "redux-form";
import FontAwesomeButton from "#components/FontAwesomeButton/index.tsx";
import type { MuiTextField } from "#components/index.ts";
import { FontAwesomeIcon, MuiTextFieldRedux } from "#components/index.ts";
import useDebounce from "#helpers/hooks/useDebounce.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import type { GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

export interface NewPasswordProps {
  isNewPasswordField?: boolean;
  setIsPasswordValid?: (boolean: boolean) => void;
}

const initialValidationResults: ValidationResults = {
  score: 0,
  warning: "",
  suggestions: [""],
};
export const MuiPasswordFieldRedux = React.memo<ReduxForm.WrappedFieldProps & MuiTextField.Props & NewPasswordProps>(
  ({ InputProps, setIsPasswordValid, isNewPasswordField, ...textFieldProps }) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [validationResults, setValidationResults] = React.useState<ValidationResults>(initialValidationResults);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const consoleUrl = useSelector((state: GlobalState) => state.config.clientConfig?.consoleUrl);
    const currentAccount = useCurrentAccount();
    const formValues = useSelector((state: GlobalState) => {
      const formInfo = state.form[textFieldProps.meta.form];
      if (formInfo && "values" in formInfo) {
        return {
          accountName: (formInfo as any).values.accountName,
          email: (formInfo as any).values.email,
        };
      }
    });

    const validatePasswordAndSetResults = useDebounce(
      async (password: string, dictionaryInfo: PasswordDictionaryInfo) => {
        const results = await validatePassword(password, dictionaryInfo);
        setValidationResults(results);
        if (!setIsPasswordValid) return;
        if (results.score >= 3) {
          setIsPasswordValid(true);
        } else {
          setIsPasswordValid(false);
        }
      },
      100,
    );

    const { pristine } = textFieldProps.meta;
    const userPasswordInput = textFieldProps.input.value;
    const accountName = currentAccount?.accountName || formValues?.accountName;
    const email = currentAccount?.email || formValues?.email;
    const name = currentAccount?.name;

    React.useEffect(() => {
      if (isNewPasswordField && !pristine) {
        validatePasswordAndSetResults(userPasswordInput, {
          accountName: accountName,
          email: email,
          name: name,
          consoleUrl: consoleUrl || "",
        })?.catch(() => {});
      }
    }, [
      userPasswordInput,
      isNewPasswordField,
      validatePasswordAndSetResults,
      pristine,
      accountName,
      email,
      name,
      consoleUrl,
    ]);

    return (
      <div className={styles.muiTextFieldReduxWrapper}>
        <MuiTextFieldRedux
          {...textFieldProps}
          type={showPassword ? "text" : "password"}
          InputProps={{
            ...InputProps,
            endAdornment: (
              <>
                {isNewPasswordField &&
                  userPasswordInput.length > 3 &&
                  validationResults &&
                  (!!validationResults.suggestions.length || !!validationResults.warning) && (
                    <Tooltip
                      title={<ValidationSuggestions results={validationResults} />}
                      leaveDelay={500}
                      placement={"bottom-end"}
                      PopperProps={{
                        className: styles.tooltip,
                      }}
                    >
                      <IconButton
                        color={validationResults?.warning ? "warning" : "info"}
                        size={"small"}
                        ref={anchorRef}
                      >
                        <FontAwesomeIcon
                          icon={validationResults?.warning ? "exclamation-triangle" : "info-circle"}
                          className="mr-1 ml-3"
                          aria-label={
                            validationResults?.warning ? "password warnings" : "suggestions to improve your password"
                          }
                        ></FontAwesomeIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                {isNewPasswordField && !!userPasswordInput && validationResults?.score === 4 && (
                  <FontAwesomeIcon icon={"check"} className="mr-1 ml-3" color="#61d495" size="1x" />
                )}
                <InputAdornment position="end">
                  <FontAwesomeButton
                    title={"Toggle visibility"}
                    iconClassName={styles.eyecon}
                    icon={showPassword ? "eye-slash" : "eye"}
                    onClick={() => setShowPassword(!showPassword)}
                    aria-pressed={!!showPassword}
                  />
                </InputAdornment>
              </>
            ),
          }}
        />
        <Collapse orientation="vertical" in={isNewPasswordField && !!userPasswordInput} timeout={200}>
          <PasswordValidationStrengthBar validationResults={validationResults} />
        </Collapse>
      </div>
    );
  },
);

type ValidationResults = {
  score: 0 | 1 | 2 | 3 | 4;
  warning: string;
  suggestions: Array<string>;
};
export interface PasswordValidationStrengthBarProps {
  validationResults: ValidationResults;
}
const PasswordValidationStrengthBar: React.FC<PasswordValidationStrengthBarProps> = ({ validationResults }) => {
  const strengthBar = {
    0: "error",
    1: "error",
    2: "warning",
    3: "success",
    4: "success",
  };

  return (
    <Container className={styles.progressBarWrapper} disableGutters={true}>
      <LinearProgress
        variant="buffer"
        value={validationResults && validationResults.score > 0 ? (validationResults?.score / 4) * 100 : 25}
        color={strengthBar[validationResults.score] as "error" | "warning" | "success"}
        valueBuffer={100}
      />
    </Container>
  );
};

interface ValidationSuggestionsProps {
  results: ValidationResults | undefined;
}
const ValidationSuggestions: React.FC<ValidationSuggestionsProps> = ({ results }) => {
  if (!results) return null;
  return (
    <Paper className={styles.feedback}>
      {results.warning && (
        <div className={styles.warnings}>
          <Alert severity="warning" variant="standard">
            {results.warning}
          </Alert>
        </div>
      )}
      {results.suggestions && (
        <ul className={styles.suggestions}>
          <p>💡 Some tips to improve your password:</p>
          {results?.suggestions.map((suggestion, i) => {
            return (
              <li key={suggestion + i}>
                <span className={styles.suggeestionItem}>{suggestion}</span>
              </li>
            );
          })}
        </ul>
      )}
    </Paper>
  );
};
/**
 * We use a very similar function to validate new passwords in the API.
 * Why we don't simply share the function in the private utils is that here in the console,
 * we need to lazy load the large dictionary & language packages from 'zxcvbn'
 */
async function scorePassword(password: string, userInfo: PasswordDictionaryInfo) {
  const zxcvbnCommonPackage = await import(/* webpackChunkName: "zxcvbnCommonPackage" */ "@zxcvbn-ts/language-common");
  const zxcvbnEnPackage = await import(/* webpackChunkName: "zxcvbnEnPackage" */ "@zxcvbn-ts/language-en");
  const zxcvbnNlPackage = await import(/* webpackChunkName: "zxcvbnNlPackage" */ "@zxcvbn-ts/language-nl-be");

  zxcvbnOptions.setOptions({
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
      ...zxcvbnNlPackage.dictionary,
    },
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    translations: {
      ...zxcvbnEnPackage.translations,
    },
  });

  // For performance reasons only look at the first 100 characters of the password
  // https://issues.triply.cc/issues/7371
  return zxcvbn(password.slice(0, 100), getUserDictionary(userInfo));
}
async function validatePassword(password: string, userInfo: PasswordDictionaryInfo) {
  const goodButNotStrongSuggestion = ["Try adding more characters to make it even stronger"];
  const { score, feedback } = await scorePassword(password, userInfo);
  let { warning, suggestions } = feedback;
  if (score === 3 && !suggestions.length) {
    suggestions = goodButNotStrongSuggestion;
  }
  return {
    score: score,
    warning,
    suggestions,
  } as ValidationResults;
}
