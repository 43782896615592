import { hexoid } from "hexoid";
import { v4 as uuid } from "uuid";
import type { AutoGeneratedIdType } from "@triply/utils/Models";

export function generateId(idType?: AutoGeneratedIdType): string {
  if (!idType) return uuid();
  // HexoId generates 00 for every number hence we want to ensure its randomization
  if (idType === "hex8") return hexoid(10)().toUpperCase().slice(0, 8);
  if (idType === "hex10") return hexoid(12)().toUpperCase().slice(0, 10);
  return uuid();
}
