import getClassName from "classnames";
import * as React from "react";
import type { Models } from "@triply/utils";
import { default as Topic } from "./Topic.tsx";
import * as styles from "./styles/index.scss";

export interface Props {
  topics: Models.Topic[];
  implicitTopics?: Models.Topic[];
  className?: string;
}
const Topics: React.FC<Props> = (props) => {
  return (
    <div className={getClassName(props.className, styles.topics)}>
      {props.topics.map((topic) => (
        <Topic key={topic.id} topic={topic} parents={getParents(topic, props.implicitTopics)} />
      ))}
    </div>
  );
};

function getParents(topic: Models.Topic, implicitTopics?: Models.Topic[]): Array<Models.Topic> {
  if (topic.parent && implicitTopics && implicitTopics.length > 0) {
    const parent = implicitTopics.find((t) => t.iri === topic.parent);
    if (!parent) return [];
    return getParents(parent, implicitTopics).concat(parent);
  }
  return [];
}

export default Topics;
