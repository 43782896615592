import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { SubmissionError } from "redux-form";
import { CachePolicies } from "use-http";
import type { Models } from "@triply/utils";
import * as Forms from "#components/Forms/index.ts";
import { Dialog, ErrorPage, Meta } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { register } from "#reducers/admin.ts";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi.ts";
import useFetch from "../../../helpers/hooks/useFetch.ts";
import BulkUpload from "./BulkUpload.tsx";
import UsersTableWrapper from "./UsersTable.tsx";

export interface Props extends IComponentProps {}

const AdminUsers: React.FC<Props> = ({ location }) => {
  const constructUrlToApi = useConstructUrlToApi();
  const {
    data: users,
    error: fetchUsersError,
    get: refreshUserList,
    loading,
  } = useFetch<Array<Models.User>>(
    constructUrlToApi({
      pathname: "/accounts",
      query: { type: "user", sortBy: "createdAt", sortDirection: "desc", limit: "-1" },
    }),
    {
      cachePolicy: CachePolicies.NO_CACHE,
    },
    [],
  );
  const dispatch = useDispatch();
  const acl = useAcl();

  const goBack = React.useCallback(() => dispatch(connectedReactRouter.goBack()), [dispatch]);
  const handleRegisterUser = React.useCallback(
    (values: Forms.Register.FormData) => {
      return dispatch<typeof register>(register(values.email, values.accountName, values.expiresAt)).then(
        () => {
          dispatch(connectedReactRouter.goBack());
          refreshUserList().catch(() => {});
        },
        (e: any) => {
          throw new SubmissionError({ _error: e.message });
        },
      );
    },
    [dispatch, refreshUserList],
  );
  const onBulkUpload = React.useCallback(() => {
    refreshUserList().catch(() => {});
  }, [refreshUserList]);

  if (!acl.check({ action: "manageUsers" }).granted) return <ErrorPage statusCode={401} />;

  return (
    <>
      <Meta currentPath={location.pathname} title="Users - Admin settings" />

      <UsersTableWrapper users={users || []} error={fetchUsersError} loading={loading} />

      <Dialog open={!!location.state?.accountAddModalShown} onClose={goBack} title="Add user" maxWidth="lg" fullWidth>
        <Forms.Register
          className="m-5"
          submitButtonLabel="Add user"
          onSubmit={handleRegisterUser}
          cancel={goBack}
          registeredByAdmin
        />
      </Dialog>

      <BulkUpload onChange={onBulkUpload} open={!!location.state?.accountAddBulkModalShown} />
    </>
  );
};

export default AdminUsers;
