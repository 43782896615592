/**
 * W3C XML Schema Definition Language (XSD) 1.1 Part 2: Datatypes
 * --------------------------------------------------------------
 *
 * A W3C Recommendation published on 2012-04-05
 *
 * @see https://www.w3.org/TR/xmlschema11-2/
 * @see [Section D.2.1 The Seven-property Model](https://www.w3.org/TR/xmlschema11-2/#theSevenPropertyModel)
 *
 * > Note: The redundancy between 'Z', '+00:00', and '-00:00', and the
 * >  possibility of trailing fractional '0' digits for secondFrag, are the
 * >  only redundancies preventing these mappings from being one-to-one. There
 * >  is no ·lexical mapping· for endOfDayFrag; it is handled specially by the
 * >  relevant ·lexical mappings·.  See, e.g., ·dateTimeLexicalMap·.
 *
 * This means that the lexical form of a date(-time) is almost always already
 * canonical
 */

import { EmbeddedActionsParser, Lexer } from "chevrotain";
import type { DateTime, Day, Month, MonthDay, Time, TimeUtc, Year, YearMonth } from "../utils/dateTimeHelpers.ts";
import { newDateTime, unsignedTwoDigitFragmentToCanonical } from "../utils/dateTimeHelpers.ts";
import { div, mod } from "../utils/helpers.ts";
import { decimalToCanonical, numberToDecimalLexicalValue } from "./decimal.ts";
import { Colon, Dash, Digit, Dot, Plus, T, Z } from "./tokens.ts";

const tokens = [Dash, Plus, Digit, Z, Colon, Dot, T];
export class DateParser extends EmbeddedActionsParser {
  constructor() {
    super(
      tokens,
      // https://chevrotain.io/docs/guide/initialization_performance.html#use-a-smaller-global-maxlookahead
      // Even though we don't expect big performance difference between this value and the default (maxLookAhead=3),
      // we kept it here as a best practice for when designing rules in the future.
      { maxLookahead: 2 },
    );
    this.performSelfAnalysis();
  }

  /**
   * [16] dateTimeLexicalRep ::= yearFrag '-' monthFrag '-' dayFrag 'T'
   *                             ( (hourFrag ':' minuteFrag ':' secondFrag)
   *                             | endOfDayFrag )
   *                             timezoneFrag?
   *
   * Constraint: Day-of-month Representations
   *
   * - Let `timezone` be `timezoneFragValue(T)` when `T` is present, otherwise
   *   absent.
   * - Return `newDateTime(yearFragValue(Y), monthFragValue(MO), dayFragValue(D),
   *   24, 0, 0, timezone)` when `endOfDayFrag` is present, and
   * - Return `newDateTime(yearFragValue(Y), monthFragValue(MO), dayFragValue(D),
   *   hourFragValue(H), minuteFragValue(MI), secondFragValue(S), timezone)`
   *   otherwise.
   */
  public dateTimeLexicalRep = this.RULE("dateTimeLexicalRep", () => {
    const year = this.SUBRULE(this.yearFrag);
    this.CONSUME(Dash);
    const month = this.SUBRULE(this.monthFrag);
    this.CONSUME1(Dash);
    const day = this.SUBRULE(this.dayFrag);
    this.CONSUME(T);
    const time = this.SUBRULE(this._timeUtc);
    const timezone = this.OPTION(() => this.SUBRULE(this.timezoneFrag));

    return newDateTime({
      year,
      month,
      day,
      ...time,
      timezone,
    });
  });

  public dateTimeStampLexicalRep = this.RULE("dateTimeStampLexicalRep", () => {
    const year = this.SUBRULE(this.yearFrag);
    this.CONSUME1(Dash);
    const month = this.SUBRULE(this.monthFrag);
    this.CONSUME2(Dash);
    const day = this.SUBRULE(this.dayFrag);
    this.CONSUME(T);
    const time = this.SUBRULE(this._timeUtc);
    const timezone = this.SUBRULE(this.timezoneFrag);

    return newDateTime({
      year,
      month,
      day,
      ...time,
      timezone,
    });
  });
  /**
   * Used in a few rules, such as [16]. Spec:
   *            (hourFrag ':' minuteFrag ':' secondFrag)
   *            | endOfDayFrag
   * We're not using a separate pattern for endOfDayFrag. Instead, doing this ourselves with a validation call,
   * allowing 24:00:00
   */
  private _timeUtc = this.RULE("_timeUtc", () => {
    let time: TimeUtc;
    const hour = this.SUBRULE(this.hourFrag, { ARGS: [true] });
    this.CONSUME1(Colon);
    const minute = this.SUBRULE(this.minuteFrag);
    this.CONSUME2(Colon);
    const { second, fraction } = this.SUBRULE(this.secondFrag);
    // [62] endOfDayFrag ::= '24:00:00' ('.' '0'+)?
    // Custom validation to apply 24:00:00 notation
    this.ACTION(() => {
      if (hour === 24) {
        if (minute !== 0 || second !== 0 || fraction.replaceAll("0", "") !== "") {
          throw new RangeError("Invalid end-of-day");
        }
      }
    });
    time = { hour, minute, second, fraction };
    return time;
  });

  /**
   * [18] dateLexicalRep ::= yearFrag '-' monthFrag '-' dayFrag timezoneFrag?
   */
  public dateLexicalRep = this.RULE("dateLexicalRep", () => {
    const year = this.SUBRULE(this.yearFrag);
    this.CONSUME1(Dash);
    const month = this.SUBRULE(this.monthFrag);
    this.CONSUME2(Dash);
    const day = this.SUBRULE(this.dayFrag);
    const timezone = this.OPTION(() => this.SUBRULE(this.timezoneFrag));
    return newDateTime({
      year,
      month,
      day,
      timezone,
    });
  });

  /**
   * # [19] gYearMonthLexicalRep ::= yearFrag '-' monthFrag timezoneFrag?
   */

  public gYearMonthLexicalRep = this.RULE("gYearMonthLexicalRep", () => {
    const year = this.SUBRULE(this.yearFrag);
    this.CONSUME(Dash);
    const month = this.SUBRULE(this.monthFrag);
    const timezone = this.OPTION(() => this.SUBRULE(this.timezoneFrag));
    return newDateTime({
      year,
      month,
      timezone,
    });
  });

  /**
   *  # [20] gYearLexicalRep ::= yearFrag timezoneFrag?
   */

  public gYearLexicalRep = this.RULE("gYearLexicalRep", () => {
    const year = this.SUBRULE1(this.yearFrag);
    const timezone = this.OPTION(() => this.SUBRULE6(this.timezoneFrag));
    return newDateTime({
      year,
      timezone,
    });
  });

  /**
   * # [21] gMonthDayLexicalRep ::= '--' monthFrag '-' dayFrag timezoneFrag?
   */

  public gMonthDayLexicalRep = this.RULE("gMonthDayLexicalRep", () => {
    this.CONSUME1(Dash);
    this.CONSUME2(Dash);
    const month = this.SUBRULE(this.monthFrag);
    this.CONSUME3(Dash);
    const day = this.SUBRULE(this.dayFrag);
    const timezone = this.OPTION(() => this.SUBRULE(this.timezoneFrag));
    return newDateTime({
      month,
      day,
      timezone,
    });
  });
  /**
   * [22] gDayLexicalRep ::= '---' dayFrag timezoneFrag?
   */
  public gDayLexicalRep = this.RULE("gDayLexicalRep", () => {
    this.CONSUME(Dash);
    this.CONSUME1(Dash);
    this.CONSUME2(Dash);
    const day = this.SUBRULE(this.dayFrag);
    const timezone = this.OPTION(() => this.SUBRULE(this.timezoneFrag));
    return newDateTime({
      day,
      timezone,
    });
  });

  /**
   * [23] gMonthLexicalRep ::= '--' monthFrag timezoneFrag?
   */
  public gMonthLexicalRep = this.RULE("gMonthLexicalRep", () => {
    this.CONSUME(Dash);
    this.CONSUME1(Dash);
    const month = this.SUBRULE(this.monthFrag);
    const timezone = this.OPTION(() => this.SUBRULE(this.timezoneFrag));
    return newDateTime({
      month,
      timezone,
    });
  });

  /**
   * # [17] timeLexicalRep ::= ((hourFrag ':' minuteFrag ':' secondFrag)
   *                            | endOfDayFrag) timezoneFrag?
   */
  public timeLexicalRep = this.RULE("timeLexicalRep", () => {
    const hour = this.SUBRULE(this.hourFrag, { ARGS: [true] });
    this.CONSUME1(Colon);
    const minute = this.SUBRULE(this.minuteFrag);
    this.CONSUME2(Colon);
    const { second, fraction } = this.SUBRULE(this.secondFrag);
    const timezone = this.OPTION(() => this.SUBRULE(this.timezoneFrag));
    return newDateTime({
      hour,
      minute,
      second,
      fraction,
      timezone,
    });
  });

  /**
   * [56] yearFrag ::= '-'?
   *          (([1-9] digit digit digit+)) | ('0' digit digit digit))
   */
  private yearFrag = this.RULE("yearFrag", () => {
    let year = this.OPTION(() => this.CONSUME(Dash).image) ?? "";
    const firstNumber = this.CONSUME(Digit).image;
    year += firstNumber;

    this.OR([
      {
        // ([1-9] digit digit digit+)
        GATE: () => firstNumber !== "0",
        ALT: () => {
          year += this.CONSUME4(Digit).image + this.CONSUME5(Digit).image;

          this.AT_LEAST_ONE(() => (year += this.CONSUME6(Digit).image));
        },
      },
      {
        // ('0' digit digit digit)
        GATE: () => firstNumber === "0",
        ALT: () => {
          year += this.CONSUME1(Digit).image + this.CONSUME2(Digit).image + this.CONSUME3(Digit).image;
        },
      },
    ]);
    return +year;
  });

  /**
   * [57] monthFrag ::= ('0' [1-9]) | ('1' [0-2])
   */
  private monthFrag = this.RULE("monthFrag", () => {
    const monthLex = this.CONSUME(Digit).image + this.CONSUME1(Digit).image;
    const month = +monthLex;
    this.ACTION(() => {
      if (month < 1 || month > 12) throw new RangeError(`Invalid month ${monthLex}`);
    });

    return month;
  });

  /**
   * [58] dayFrag ::= ('0' [1-9]) | ([12] digit) | ('3' [01])
   */
  private dayFrag = this.RULE("dayFrag", () => {
    const dayLex = this.CONSUME(Digit).image + this.CONSUME1(Digit).image;
    const day = +dayLex;
    this.ACTION(() => {
      if (day < 1 || day > 31) throw new RangeError(`Invalid day ${dayLex}`);
    });
    return day;
  });
  /**
   * [59] hourFrag ::= ([01] digit) | ('2' [0-3])
   * We optionally allow for allowing the 24-hour notation. Useful as this enables us
   * to simplify (remove) the endOfDayFrag
   */
  private hourFrag = this.RULE("hourFrag", (include24?: boolean) => {
    const hourLex = this.CONSUME(Digit).image + this.CONSUME1(Digit).image;
    const hour = +hourLex;
    this.ACTION(() => {
      if (hour === 24 && include24) return;
      if (hour > 23) throw new RangeError(`Invalid hour ${hourLex}`);
    });
    return hour;
  });
  /**
   * [60] minuteFrag ::= [0-5] digit
   */
  private minuteFrag = this.RULE("minuteFrag", () => {
    const minuteLex = this.CONSUME(Digit).image + this.CONSUME1(Digit).image;
    const minute = +minuteLex;
    this.ACTION(() => {
      if (minute > 59) throw new RangeError(`Invalid minute ${minuteLex}`);
    });
    return minute;
  });
  /**
   * [61] secondFrag ::= ([0-5] digit) ('.' digit+)?
   *
   * - Return `unsignedNoDecimalMap(SE)` when no decimal point occurs in `SE`, and
   * - Return `unsignedDecimalPtMap(SE)` otherwise.
   */
  private secondFrag = this.RULE("secondFrag", () => {
    const secondLex = this.CONSUME(Digit).image + this.CONSUME1(Digit).image;
    const second = +secondLex;
    this.ACTION(() => {
      if (second > 59) throw new RangeError(`Invalid second ${secondLex}`);
    });
    const fraction =
      this.OPTION(() => {
        this.CONSUME3(Dot);
        let decimalPtLex = "";
        this.AT_LEAST_ONE(() => {
          decimalPtLex += this.CONSUME4(Digit).image;
        });
        return decimalPtLex;
      }) ?? "";
    return { second, fraction };
  });

  /**
   * [63] timezoneFrag ::= 'Z' |
   *                         ('+' | '-')
   *                         (('0' digit | '1' [0-3]) ':' minuteFrag | '14:00')
   *
   * - Return `0` when the lexical form is `'Z'`,
   * - Return `-(unsignedDecimalPtMap(H) * 60 + unsignedDecimalPtMap(M))` when
   *   the sign is `'-'`, and
   * - Return `unsignedDecimalPtMap(H) * 60 + unsignedDecimalPtMap(M)` otherwise.
   */
  private timezoneFrag = this.RULE("timezoneFrag", () => {
    return this.OR([
      {
        ALT: () => {
          this.CONSUME(Z);
          return 0;
        },
      },
      {
        ALT: () => {
          this.CONSUME(Plus);
          return this.SUBRULE(this.timezoneFrag_absolute);
        },
      },
      {
        ALT: () => {
          this.CONSUME(Dash);
          return -this.SUBRULE1(this.timezoneFrag_absolute);
        },
      },
    ]);
  });

  // Subset of [63] timezonefrag: (('0' digit | '1' [0-3]) ':' minuteFrag | '14:00')
  private timezoneFrag_absolute = this.RULE("timezoneFrag_absolute", () => {
    const hoursLex = this.CONSUME(Digit).image + this.CONSUME1(Digit).image;
    const hours = +hoursLex;
    this.CONSUME(Colon);
    // const minutes = this.CONSUME(MinuteFrag);
    let minutesLex = this.CONSUME2(Digit).image + this.CONSUME3(Digit).image;
    const minutes = +minutesLex;
    const timezoneAbs = hours * 60 + minutes;
    this.ACTION(() => {
      if (timezoneAbs > 14 * 60) throw new RangeError(`Invalid time zone ${hoursLex}:${minutesLex}`);
    });
    return timezoneAbs;
  });
}

export const lexer = new Lexer(tokens, {
  ensureOptimizations: true,
  // not tracking lines for this grammar (not setting this will print a warning)
  positionTracking: "onlyOffset",
});
export const parser = new DateParser();

/**
 * Maps a date value to a string that matches grammar rule `dateLexicalRep`.
 *
 * @param da A complete date value.
 * @return   A string that matches grammar rule `dateLexicalRep`.
 *
 * Algorithm:
 * - Let `D` be `yearCanonicalFragmentMap(da's year)` &
 *              `'-'` &
 *              `monthCanonicalFragmentMap(da's month)` &
 *              `'-'` &
 *              `dayCanonicalFragmentMap(da's day)`.
 * - Return `D` when `da`'s `timezoneOffset` is absent, and
 * - Return `D` &
 *          `timezoneCanonicalFragmentMap(da's timezoneOffset)`
 *   otherwise.
 */
export function dateToCanonical(da: DateTime): string {
  return (
    yearFragmentToCanonical(da.year) +
    "-" +
    monthFragmentToCanonical(da.month) +
    "-" +
    dayFragmentToCanonical(da.day) +
    (da.timezone === undefined ? "" : timezoneFragmentToCanonical(da.timezone))
  );
}

/**
 * Maps a time value to a timeLexicalRep.
 *
 * @param ti A complete time value.
 * @return   A string that matches grammar rule `timeLexicalRep`.
 *
 * Algorithm:
 * - Let `T` be `hourCanonicalFragmentMap(ti's hour)` &
 *              `':'` &
 *              `minuteCanonicalFragmentMap(ti's minute)` &
 *              `':'` &
 *              `secondCanonicalFragmentMap(ti's second)`.
 * - Return `T`
 *   when `ti`'s timezoneOffset is absent, and
 * - Return `T` &
 *          `timezoneCanonicalFragmentMap(ti's timezoneOffset)`
 *   otherwise.
 */
export function timeToCanonical(ti: Time): string {
  return (
    hourFragmentToCanonical(ti.hour) +
    ":" +
    minuteFragmentToCanonical(ti.minute) +
    ":" +
    secondFragmentToCanonical(ti.second, ti.fraction) +
    (ti.timezone === undefined ? "" : timezoneFragmentToCanonical(ti.timezone))
  );
}

/**
 * Maps an integer, presumably the timezoneOffset property of a
 * date/timeSevenPropertyModel value, onto a string that matches grammar rule
 * `timezoneFrag`, part of a date/timeSevenPropertyModel's lexical
 * representation.
 *
 * @param t: An integer between -840 and 840 inclusive.
 * @return   A string that matches grammar rule `timezoneFrag`.
 *
 * Algorithm:
 * - Return `'Z'`
 *   when `t` is zero,
 * - Return `'-'` &
 *          `unsTwoDigitCanonicalFragmentMap(-t div 60)` &
 *          `':'` &
 *          `unsTwoDigitCanonicalFragmentMap(-t mod 60)`
 *   when `t` is negative, and
 * - Return `'+'` &
 *          `unsTwoDigitCanonicalFragmentMap(t div 60)` &
 *          `':'` &
 *          `unsTwoDigitCanonicalFragmentMap(t mod 60)`
 *   otherwise.
 *
 * Note: This function is explicitly exported for use in Speedy, to avoid duplicate code.
 */
export function timezoneFragmentToCanonical(t: number): string {
  if (!t) {
    return "Z";
  } else if (t < 0) {
    return (
      "-" + unsignedTwoDigitFragmentToCanonical(div(-t, 60)) + ":" + unsignedTwoDigitFragmentToCanonical(mod(-t, 60))
    );
  } else {
    return (
      "+" + unsignedTwoDigitFragmentToCanonical(div(t, 60)) + ":" + unsignedTwoDigitFragmentToCanonical(mod(t, 60))
    );
  }
}

/**
 * Maps an integer, presumably the hour property of a
 * date/timeSevenPropertyModel value, onto a string that matches grammar rule
 * `hourFrag`, part of a date/timeSevenPropertyModel's lexical representation.
 *
 * @param h An integer between 0 and 23 inclusive.
 * @return  A string that matches grammar rule `hourFrag`.
 *
 * Algorithm:
 * - Return `unsTwoDigitCanonicalFragmentMap(h)`.
 */
function hourFragmentToCanonical(h: number): string {
  return unsignedTwoDigitFragmentToCanonical(h);
}

/**
 * Maps a gDay value to a string that matches grammar rule `gDayLexicalRep`.
 *
 * @param gD A complete gDay value.
 * @return   A string that matches grammar rule `gDayLexicalRep`.
 *
 * Algorithm:
 * - Return `'---'` &
 *          `dayCanonicalFragmentMap(gD's day)`
 *   when `gD`'s timezoneOffset is absent, and
 * - Return `'---'` &
 *          `dayCanonicalFragmentMap(gD's day)` &
 *          `timezoneCanonicalFragmentMap(gD's timezoneOffset)`
 *   otherwise.
 */
export function gDayToCanonical(gD: Day): string {
  return (
    "---" + dayFragmentToCanonical(gD.day) + (gD.timezone === undefined ? "" : timezoneFragmentToCanonical(gD.timezone))
  );
}

/**
 * Maps a gMonth value to a string that matches grammar rule
 * `gMonthLexicalRep`.
 *
 * @param gM A complete gMonth value.
 * @return   A string that matches grammar rule `gMonthLexicalRep`.
 *
 * Algorithm:
 * - Return `'--'` &
 *          `monthCanonicalFragmentMap(gM's day)`
 *   when `gM`'s timezoneOffset` is absent, and
 * - Return `'--'` &
 *          `monthCanonicalFragmentMap(gM's day)` &
 *          `timezoneCanonicalFragmentMap(gM's timezoneOffset)`
 *   otherwise.
 */
export function gMonthToCanonical(gM: Month): string {
  return (
    "--" +
    monthFragmentToCanonical(gM.month) +
    (gM.timezone === undefined ? "" : timezoneFragmentToCanonical(gM.timezone))
  );
}

/**
 * Maps a gMonthDay value to a string that matches grammar rule
 * `gMonthDayLexicalRep`.
 *
 * @param md A complete gMonthDay value.
 * @retrun   A string that matches grammar rule `gMonthDayLexicalRep`.
 *
 * Algorithm:
 * - Let `MD` be `'--'` &
 *               `monthCanonicalFragmentMap(md's month)` &
 *               `'-'` &
 *               `dayCanonicalFragmentMap(md's day)`.
 * - Return `MD`
 *   when `md`'s timezoneOffset is absent, and
 * - Return `MD` &
 *          `timezoneCanonicalFragmentMap(md's timezoneOffset)`
 *   otherwise.
 */
export function gMonthDayToCanonical(md: MonthDay): string {
  return (
    "--" +
    monthFragmentToCanonical(md.month) +
    "-" +
    dayFragmentToCanonical(md.day) +
    (md.timezone === undefined ? "" : timezoneFragmentToCanonical(md.timezone))
  );
}

/**
 * Maps a gYear value to a string that matches grammar rule `gYearLexicalRep`.
 *
 * @param gY A complete gYear value.
 * @return   A string that matches grammar rule `gYearLexicalRep`.
 *
 * Algorithm:
 * - Return `yearCanonicalFragmentMap(gY's year)`
 *   when `gY`'s timezoneOffset is absent, and
 * - Return `yearCanonicalFragmentMap(gY's year)` &
 *          `timezoneCanonicalFragmentMap(gY's timezoneOffset)`
 *   otherwise.
 */
export function gYearToCanonical(gy: Year): string {
  return yearFragmentToCanonical(gy.year) + (gy.timezone === undefined ? "" : timezoneFragmentToCanonical(gy.timezone));
}

/**
 * Maps a gYearMonth value to a string that matches gramma rule
 * `gYearMonthLexicalRep`.
 *
 * @param ym A complete gYearMonth value.
 * @return   A string that matches grammar rule `gYearMonthLexicalRep`.
 *
 * Algorithm:
 * - Let `YM` be `yearCanonicalFragmentMap(ym's year)` &
 *               `'-'` &
 *               `monthCanonicalFragmentMap(ym's month)`.
 * - Return `YM`
 *   when `ym`'s `timezoneOffset` is absent, and
 * - Return `YM` &
 *          `timezoneCanonicalFragmentMap(ym's timezoneOffset)`
 *   otherwise.
 */
export function gYearMonthToCanonical(ym: YearMonth): string {
  return (
    yearFragmentToCanonical(ym.year) +
    "-" +
    monthFragmentToCanonical(ym.month) +
    (ym.timezone === undefined ? "" : timezoneFragmentToCanonical(ym.timezone))
  );
}

/**
 * Maps an integer, presumably the month property of a
 * date/timeSevenPropertyModel value, onto a string that matches grammar rule
 * `monthFrag`, part of a date/timeSevenPropertyModel's lexical
 * representation.
 *
 * @param m An integer between 1 and 12 inclusive.
 * @return  A string that matches grammar rule `monthFrag`.
 *
 * Algorithm:
 * - Return `unsTwoDigitCanonicalFragmentMap(m)`.
 */
function monthFragmentToCanonical(m: number): string {
  return unsignedTwoDigitFragmentToCanonical(m);
}

/**
 * Maps an integer, presumably the year property of a
 * date/timeSevenPropertyModel value, onto a string that matches grammar rule
 * `yearFrag`, part of a date/timeSevenPropertyModel's lexical representation.
 *
 * @param y An integer.
 * @return  A string that matches grammar rule `yearFrag`.
 *
 * Algorithm:
 * - Return `noDecimalPtCanonicalMap(y)`
 *   when `|y| > 9999`,
 * - Return `fourDigitCanonicalFragmentMap(y)`
 *   otherwise.
 */
function yearFragmentToCanonical(y: number): string {
  // The year 0 _does_ exist!!
  // https://www.w3.org/TR/2012/REC-xmlschema11-2-20120405/datatypes.html#dateTime-value-space
  return Math.abs(y) > 9999 ? decimalToCanonical(numberToDecimalLexicalValue(y)) : fourDigitFragmentToCanonical(y);
}

/**
 * Maps an integer, presumably the minute property of a date/time seven
 * property model value, onto a string that matches grammar rule `minuteFrag`,
 * part of a date/time seven property model's lexical representation.
 *
 * @param m An integer between 0 and 59 inclusive.
 * @return  A string that matches grammar rule `minuteFrag`.
 *
 * Algorithm:
 * - Return `unsTwoDigitCanonicalFragmentMap(m)`.
 */
function minuteFragmentToCanonical(m: number): string {
  return unsignedTwoDigitFragmentToCanonical(m);
}

/**
 * Smallest date representable in ECMAScript.
 *
 * See https://262.ecma-international.org/5.1/#sec-15.9.1.1
 * and https://stackoverflow.com/a/11526569
 */
const SMALLEST_DATE_IN_ECMASCRIPT = new Date(-8640000000000000);
/**
 * Largest date representable in ECMAScript.
 *
 * See https://262.ecma-international.org/5.1/#sec-15.9.1.1
 * and https://stackoverflow.com/a/11526569
 */
const LARGEST_DATE_IN_ECMASCRIPT = new Date(8640000000000000);
// +1 because this is a negative number and we want to be on the safe side
const SMALLEST_SUPPORTED_YEAR = SMALLEST_DATE_IN_ECMASCRIPT.getFullYear() + 1;
const LARGEST_SUPPORTED_YEAR = LARGEST_DATE_IN_ECMASCRIPT.getFullYear() - 1;

function makeTimeZoneZero(dt: DateTime): DateTime {
  if (!dt.timezone) return dt;

  if (dt.year > LARGEST_SUPPORTED_YEAR) throw new Error("Unsupported. See https://issues.triply.cc/issues/9304");
  if (dt.year < SMALLEST_SUPPORTED_YEAR) throw new Error("Unsupported. See https://issues.triply.cc/issues/9304");
  let yearString = numberToDecimalLexicalValue(dt.year);
  if (dt.year < 0) {
    // ECMAScript only supports negative years with exactly six digits
    yearString = "-" + yearString.slice(1).padStart(6, "0");
  }

  const shiftedDateTimeWithImpreciseFraction = dateTimeFromEcmaScriptDate(
    new Date(
      yearString +
        "-" +
        monthFragmentToCanonical(dt.month) +
        "-" +
        dayFragmentToCanonical(dt.day) +
        "T" +
        hourFragmentToCanonical(dt.hour) +
        ":" +
        minuteFragmentToCanonical(dt.minute) +
        ":" +
        secondFragmentToCanonical(dt.second, "") +
        timezoneFragmentToCanonical(dt.timezone),
    ),
  );

  // ECMAScript Date only does 3-digit fractions
  shiftedDateTimeWithImpreciseFraction.fraction = dt.fraction;
  return shiftedDateTimeWithImpreciseFraction;
}

function dateTimeFromEcmaScriptDate(date: Date): DateTime {
  const isoString = date.toISOString();
  const [_whole, year, month, day, hour, minute, second, fraction] = isoString.match(
    /^(-?\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+)\.(\d+)Z$/,
  )!;
  return {
    year: +year,
    month: +month,
    day: +day,
    hour: +hour,
    minute: +minute,
    second: +second,
    fraction,
    timezone: 0, // See the Z in the regex
  };
}

/**
 * Maps a date/time value to a string that matches grammar rule
 * `dateTimeLexicalRep`.
 *
 * @param dt: A complete date/time value.
 * @return    A string that matches grammar rule `dateLexicalRep`.
 *
 * Algorithm:
 *   - Let `DT` be `yearCanonicalFragmentMap(dt's year)` &
 *                 `'-'` &
 *                 `monthCanonicalFragmentMap(dt's month)` &
 *                 `'-'` &
 *                 `dayCanonicalFragmentMap(dt's day)` &
 *                 `'T'` &
 *                 `hourCanonicalFragmentMap(dt's hour)` &
 *                 `':'` &
 *                 `minuteCanonicalFragmentMap(dt's minute)` &
 *                 `':'` &
 *                 `secondCanonicalFragmentMap(dt's second)`.
 *   - Return `DT` when `dt`'s `timezoneOffset` is absent, and
 *   - Return `DT` & `timezoneCanonicalFragmentMap(dt's timezoneOffset)`
 *     otherwise.
 */
export function dateTimeToCanonical(dt: DateTime): string {
  dt = makeTimeZoneZero(dt);
  return (
    yearFragmentToCanonical(dt.year) +
    "-" +
    monthFragmentToCanonical(dt.month) +
    "-" +
    dayFragmentToCanonical(dt.day) +
    "T" +
    hourFragmentToCanonical(dt.hour) +
    ":" +
    minuteFragmentToCanonical(dt.minute) +
    ":" +
    secondFragmentToCanonical(dt.second, dt.fraction) +
    (dt.timezone === undefined ? "" : "Z")
  );
}

/**
 * Maps an integer, presumably the day property of a
 * date/timeSevenPropertyModel value, onto a string that matches grammar rule
 * `dayFrag`, part of a date/timeSevenPropertyModel's lexical representation.
 *
 * @param d An integer between 1 and 31 inclusive (may be limited further
 *          depending on the associated year and month).
 * @return  A string that matches grammar rule `dayFrag`.
 *
 * Algorithm:
 * - Return `unsTwoDigitCanonicalFragmentMap(d)`.
 */
function dayFragmentToCanonical(d: number): string {
  return unsignedTwoDigitFragmentToCanonical(d);
}

/**
 * Maps an integer between -10000 and 10000 onto an always-four-digit numeral.
 *
 * @param i An integer whose absolute value is less than 10,000.
 * @return  A string that matches grammar rule `noDecimalPtNumeral`.
 *
 * Algorithm:
 * - Return `'-'` &
 *          `unsTwoDigitCanonicalFragmentMap(-i div 100)` &
 *          `unsTwoDigitCanonicalFragmentMap(-i mod 100)`
 *   when `i` is negative,
 * - Return `unsTwoDigitCanonicalFragmentMap(i div 100)` &
 *          `unsTwoDigitCanonicalFragmentMap(i mod 100)`
 *   otherwise.
 */
function fourDigitFragmentToCanonical(i: number): string {
  const isNegative = i < 0;
  if (isNegative) i = -i;
  return (isNegative ? "-" : "") + i.toString().padStart(4, "0");
}

/**
 * Maps a decimal number, presumably the second property of a
 * date/timeSevenPropertyModel value, onto a string that matches grammar rule
 * `secondFrag`, part of a date/timeSevenPropertyModel's lexical
 * representation.
 *
 * @param s A nonnegative decimal number less than 70.
 * @return  A string that matches grammar rule `secondFrag`.
 *
 * Algorithm:
 * - Return `unsTwoDigitCanonicalFragmentMap(s)`
 *   when `s` is an integer, and
 * - Return `unsTwoDigitCanonicalFragmentMap(s div 1)` &
 *          `'.'` &
 *          `fractionDigitsCanonicalFragmentMap(s mod 1)`
 *   otherwise.
 */
function secondFragmentToCanonical(s: number, fraction: string): string {
  const before = s.toString().padStart(2, "0");
  // Regex to delete all trailing zeros (because String.trimEnd doesn't take arguments 😔)
  const after = fraction.replace(/0+$/, "");
  if (after) return before + "." + after;
  else return before;
}
