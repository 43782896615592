import type { NamedNode, Term } from "@rdfjs/types";

export function stringPrefixer<Prefix extends string>(base: Prefix) {
  return function prefix<Suffix extends string>(local: Suffix): `${Prefix}${Suffix}` {
    return `${base}${local}`;
  };
}

export const xsd = stringPrefixer("http://www.w3.org/2001/XMLSchema#");
export const rdf = stringPrefixer("http://www.w3.org/1999/02/22-rdf-syntax-ns#");
export const geo = stringPrefixer("http://www.opengis.net/ont/geosparql#");
const xpathFunctions = stringPrefixer("http://www.w3.org/2005/xpath-functions#");

export const NUMERIC_DATA_TYPE_IRIS = {
  // Numeric types
  XSD_DECIMAL: xsd("decimal"),
  XSD_FLOAT: xsd("float"),
  XSD_DOUBLE: xsd("double"),

  // Derived numeric types
  XSD_INTEGER: xsd("integer"),

  XSD_NON_POSITIVE_INTEGER: xsd("nonPositiveInteger"),
  XSD_NEGATIVE_INTEGER: xsd("negativeInteger"),

  XSD_LONG: xsd("long"),
  XSD_INT: xsd("int"),
  XSD_SHORT: xsd("short"),
  XSD_BYTE: xsd("byte"),

  XSD_NON_NEGATIVE_INTEGER: xsd("nonNegativeInteger"),
  XSD_POSITIVE_INTEGER: xsd("positiveInteger"),
  XSD_UNSIGNED_LONG: xsd("unsignedLong"),
  XSD_UNSIGNED_INT: xsd("unsignedInt"),
  XSD_UNSIGNED_SHORT: xsd("unsignedShort"),
  XSD_UNSIGNED_BYTE: xsd("unsignedByte"),
};

export type NumericDataTypeIri = (typeof NUMERIC_DATA_TYPE_IRIS)[keyof typeof NUMERIC_DATA_TYPE_IRIS];
export const numericDatatypeIris = new Set<string>(Object.values(NUMERIC_DATA_TYPE_IRIS));
export function isNumericDatatype(term: Term): term is NamedNode<NumericDataTypeIri> {
  return term.termType === "NamedNode" && numericDatatypeIris.has(term.value);
}

export const DATA_TYPE_NAME_MAPPING = {
  XSD_ANY_URI: xsd("anyURI"),
  XSD_STRING: xsd("string"),
  RDF_LANG_STRING: rdf("langString"),

  XSD_BOOLEAN: xsd("boolean"),

  // Date and time
  XSD_TIME: xsd("time"),
  XSD_DATE_TIME: xsd("dateTime"),
  XSD_DATE_TIME_STAMP: xsd("dateTimeStamp"),
  XSD_DATE: xsd("date"),
  XSD_G_YEAR_MONTH: xsd("gYearMonth"),
  XSD_G_YEAR: xsd("gYear"),
  XSD_G_MONTH_DAY: xsd("gMonthDay"),
  XSD_G_DAY: xsd("gDay"),
  XSD_G_MONTH: xsd("gMonth"),

  // Durations
  XSD_DURATION: xsd("duration"),
  XSD_YEAR_MONTH_DURATION: xsd("yearMonthDuration"),
  XSD_DAYTIME_DURATION: xsd("dayTimeDuration"),

  // Numeric types & Derived numeric types
  ...NUMERIC_DATA_TYPE_IRIS,

  // Derived String Type
  XSD_NORMALIZED_STRING: xsd("normalizedString"),
  XSD_TOKEN: xsd("token"),
  XSD_LANGUAGE: xsd("language"),
  XSD_NM_TOKEN: xsd("NMTOKEN"),

  XSD_NAME: xsd("name"),
  XSD_NC_NAME: xsd("NCName"),
  XSD_ENTITY: xsd("ENTITY"),
  XSD_ID: xsd("ID"),
  XSD_ID_REF: xsd("IDREF"),

  // Other
  XSD_ANY_ATOMIC_TYPE: xsd("anyAtomicType"),
  XSD_UNTYPED_ATOMIC: xsd("untypedAtomic"),
  XSD_BASE64BINARY: xsd("base64Binary"),
  XSD_HEX_BINARY: xsd("hexBinary"),
  XSD_NOTATION: xsd("NOTATION"),
  XSD_Q_NAME: xsd("QName"),

  GEO_WKT_LITERAL: geo("wktLiteral"),
} as const;

export const XPATH_FUNCTIONS = {
  /**
   * fn:compare: The SPARQL spec specifies to use the codepoint collation for the compare function.
   * > The collation for fn:compare is defined by XPath and identified by
   * http://www.w3.org/2005/xpath-functions/collation/codepoint.
   * > This collation allows for string comparison based on code point values.
   * Codepoint string equivalence can be tested with RDF term equivalence.
   * @DECISION We are taking the above "Codepoint string equivalence can be tested
   * with RDF term equivalence" to mean that language tags matter. Thus compare equality should
   * behave like the "sameTerm" functionCall.
   * [spec](https://www.w3.org/TR/sparql11-query/#OperatorMapping)
   */
  COMPARE: xpathFunctions("compare"),
  NOT: xpathFunctions("not"),
  BOOLEAN: xpathFunctions("boolean"),
  /**
   * Not accepting the zero argument version of the function here.
   * calling fn:string() is valid in xpath as it uses the context available to it
   * https://www.w3.org/TR/xpath-functions/#func-string
   */
  STRING: xpathFunctions("string"),
  LANG: xpathFunctions("lang"), // https://www.w3.org/TR/xpath-functions/#func-lang
  ABS: xpathFunctions("abs"), // https://www.w3.org/TR/xpath-functions/#func-abs
  CEILING: xpathFunctions("ceiling"), // https://www.w3.org/TR/xpath-functions/#func-ceiling
  FLOOR: xpathFunctions("floor"), // https://www.w3.org/TR/xpath-functions/#func-floor
  ROUND: xpathFunctions("round"), // https://www.w3.org/TR/xpath-functions/#func-round
  STRING_LENGTH: xpathFunctions("string-length"), // https://www.w3.org/TR/xpath-functions/#func-string-length
  SUBSTRING: xpathFunctions("substring"), // https://www.w3.org/TR/xpath-functions/#func-substring
  SUBSTRING_BEFORE: xpathFunctions("substring-before"), // https://www.w3.org/TR/xpath-functions/#func-substring-before
  SUBSTRING_AFTER: xpathFunctions("substring-after"), // https://www.w3.org/TR/xpath-functions/#func-substring-after
  UPPER_CASE: xpathFunctions("upper-case"), // https://www.w3.org/TR/xpath-functions/#func-upper-case
  LOWER_CASE: xpathFunctions("lower-case"), // https://www.w3.org/TR/xpath-functions/#func-lower-case
  STARTS_WITH: xpathFunctions("starts-with"), // https://www.w3.org/TR/xpath-functions/#func-starts-with
  ENDS_WITH: xpathFunctions("ends-with"), // https://www.w3.org/TR/xpath-functions/#func-ends-with
  CONTAINS: xpathFunctions("contains"), // https://www.w3.org/TR/xpath-functions/#func-contains
  CONCAT: xpathFunctions("concat"), // https://www.w3.org/TR/xpath-functions/#func-concat
  ENCODE_FOR_URI: xpathFunctions("encode-for-uri"), // https://www.w3.org/TR/xpath-functions/#func-encode-for-uri
  MATCHES: xpathFunctions("matches"), // https://www.w3.org/TR/xpath-functions/#func-encode-for-uri
  REPLACE: xpathFunctions("replace"), // https://www.w3.org/TR/xpath-functions/#func-replace
  CURRENT_DATE_TIME: xpathFunctions("current-dateTime"), // https://www.w3.org/TR/xpath-functions/#func-replace
} as const;

const DATA_TYPE_NAMES = Object.keys(DATA_TYPE_NAME_MAPPING) as Array<keyof typeof DATA_TYPE_NAME_MAPPING>;
export const DATA_TYPE_IRIS = DATA_TYPE_NAMES.map((name) => DATA_TYPE_NAME_MAPPING[name]);
type DataTypeName = (typeof DATA_TYPE_NAMES)[number];
export type DataTypeIri = (typeof DATA_TYPE_IRIS)[number];
// For readability, we first write the hierarchy in a human readable fashion
// and then compile it into a performant data structure
/** https://www.w3.org/TR/xpath-functions/#atomic-type-hierarchy */
const TYPE_HIERARCHY = {
  type: "XSD_ANY_ATOMIC_TYPE",
  children: [
    "XSD_UNTYPED_ATOMIC",
    { type: "XSD_DATE_TIME", children: ["XSD_DATE_TIME_STAMP"] },
    "XSD_DATE",
    "XSD_TIME",
    { type: "XSD_DURATION", children: ["XSD_YEAR_MONTH_DURATION", "XSD_DAYTIME_DURATION"] },
    "XSD_FLOAT",
    "XSD_DOUBLE",
    {
      type: "XSD_DECIMAL",
      children: [
        {
          type: "XSD_INTEGER",
          children: [
            { type: "XSD_NON_POSITIVE_INTEGER", children: ["XSD_NEGATIVE_INTEGER"] },
            {
              type: "XSD_LONG",
              children: [
                {
                  type: "XSD_INT",
                  children: [{ type: "XSD_SHORT", children: ["XSD_BYTE"] }],
                },
              ],
            },
            {
              type: "XSD_NON_NEGATIVE_INTEGER",
              children: [
                {
                  type: "XSD_UNSIGNED_LONG",
                  children: [
                    {
                      type: "XSD_UNSIGNED_INT",
                      children: [{ type: "XSD_UNSIGNED_SHORT", children: ["XSD_UNSIGNED_BYTE"] }],
                    },
                  ],
                },
                "XSD_POSITIVE_INTEGER",
              ],
            },
          ],
        },
      ],
    },
    "XSD_G_YEAR_MONTH",
    "XSD_G_YEAR",
    "XSD_G_MONTH_DAY",
    "XSD_G_DAY",
    "XSD_G_MONTH",
    {
      type: "XSD_STRING",
      children: [
        {
          type: "XSD_NORMALIZED_STRING",
          children: [
            {
              type: "XSD_TOKEN",
              children: [
                "XSD_LANGUAGE",
                "XSD_NM_TOKEN",
                {
                  type: "XSD_NAME",
                  children: [
                    {
                      type: "XSD_NC_NAME",
                      children: ["XSD_ID", "XSD_ID_REF", "XSD_ENTITY"],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    "XSD_BOOLEAN",
    "XSD_BASE64BINARY",
    "XSD_HEX_BINARY",
    "XSD_ANY_URI",
    "XSD_Q_NAME",
    "XSD_NOTATION",
  ],
} as const;

/**
 *  An object that tells you all the known sub types of a given super type.
 *  It's not very useful except for creating two useful other Maps. See
 *  below!
 *
 *  This sort of kind of implements the `derives-from` mentioned here:
 *  https://www.w3.org/TR/xpath20/#dt-subtype-substitution, with `AT` being the
 *  sub-type and `ET` being the super type.
 */
const SUB_TYPE_NAMES: { [superType in DataTypeName]: DataTypeName[] } = {} as any;

type Tree = { type: DataTypeName; children: ReadonlyArray<DataTypeName | Tree> };
/**
 *  Given a tree, calculate all the sub types for the top node.
 *  Then save all the sub types in SUB_TYPE_NAMES.
 *
 *  Because this function calls itself recursively, it adds the types for all
 *  the children in the tree to SUB_TYPE_NAMES as well.
 */
function setRecursively(node: Tree | DataTypeName): void {
  // Recursively calculate all the sub types of `node.type`.

  // Start with itself, because you're always a subtype of yourself
  const name = typeof node === "string" ? node : node.type;
  const subTypes: DataTypeName[] = [name];

  // Now add all the sub types of every (direct) child as a subtype
  if (typeof node === "object") {
    for (const child of node.children) {
      // First make sure to calculate what the subtypes of the child are
      setRecursively(child);
      const childName = typeof child === "string" ? child : child.type;
      // then add the subtypes of the child to those of the parent
      subTypes.push(...SUB_TYPE_NAMES[childName]);
    }
  }

  // Finally add the sub types of `node.type` to SUPER_SUB_TYPES, because we've
  // been promising to do that all the time!
  SUB_TYPE_NAMES[name] = subTypes;
}

// we ignore the output because we only care about the side-effect
setRecursively(TYPE_HIERARCHY);
// @DECISION Setting rdf:langString manually, because it isn't mentioned in the
// xsd spec, so I'm not including it in the TYPE_HIERARCHY. This means you'll
// have to check explicitly for both xsd:string and rdf:langString separately.
setRecursively("RDF_LANG_STRING");
setRecursively("GEO_WKT_LITERAL");

/**
 *  An object that tells you all the known sub types of a given super type.
 *  E.g. if you are doing some calculations and you want to know whether you
 *  can use your input parameter as a string, just check:
 *
 *    SUB_TYPES.XSD_STRING.includes(inputDataType.value)
 *
 *  This sort of kind of implements the `derives-from` mentioned here:
 *  https://www.w3.org/TR/xpath20/#dt-subtype-substitution, with `AT` being the
 *  sub-type and `ET` being the super type.
 *
 *  It's actually typed `Map<DataTypeName, string[]>`, because otherwise the
 *  `.includes` method throws an error if you haven't narrowed the data type to
 *  a known one, which is stupid because that's exactly why we would be doing
 *  the `.includes` check in the first place.
 */
export const SUB_TYPES: { [superType in DataTypeName]: string[] } = {} as any;
for (const superName of DATA_TYPE_NAMES) {
  SUB_TYPES[superName] = SUB_TYPE_NAMES[superName].map((name) => DATA_TYPE_NAME_MAPPING[name]);
}

/**
 *  An object that tells you all the known sub types of a given super type.
 *  E.g. if you are doing some calculations and you want to know whether some
 *  `dataTypeB` derives from some `dataTypeA`, do:
 *
 *    SUB_TYPE_IRIS.[dataTypeA.value].includes(dataTypeB.value)
 *
 *  This sort of kind of implements the `derives-from` mentioned here:
 *  https://www.w3.org/TR/xpath20/#dt-subtype-substitution, with `AT` being the
 *  sub-type and `ET` being the super type.
 *
 *  It's actually typed `Map<DataTypeIri, string[]>`, because otherwise the
 *  `.includes` method throws an error if you haven't narrowed the data type to
 *  a known one, which is stupid because that's exactly why we would be doing
 *  the `.includes` check in the first place.
 */
export const SUB_TYPE_IRIS: { [superType in DataTypeIri]: string[] } = {} as any;
for (const superName of DATA_TYPE_NAMES) {
  SUB_TYPE_IRIS[DATA_TYPE_NAME_MAPPING[superName]] = SUB_TYPE_NAMES[superName].map(
    (name) => DATA_TYPE_NAME_MAPPING[name],
  );
}
