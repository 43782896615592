/**
 * W3C XML Schema Definition Language (XSD) 1.1 Part 2: Datatypes.
 * --------------------------------------------------------------
 *  A W3C Recommendation published on 2012-04-05
 *  @see https://www.w3.org/TR/xmlschema11-2/
 */
import { EmbeddedActionsParser, Lexer } from "chevrotain";
import { Digit, Dot, Exponential, Inf, Nan, Sign } from "./tokens.ts";

const tokens = [Sign, Digit, Dot, Exponential, Inf, Nan];
export class DecimalParser extends EmbeddedActionsParser {
  constructor() {
    super(
      tokens,
      // https://chevrotain.io/docs/guide/initialization_performance.html#use-a-smaller-global-maxlookahead
      // Even though we don't expect big performance difference between this value and the default (maxLookAhead=3),
      // we kept it here as a best practice for when designing rules in the future.
      { maxLookahead: 2 },
    );
    this.performSelfAnalysis();
  }

  /**
   *  [3] decimalLexicalRep ::= decimalPtNumeral | noDecimalPtNumeral
   */
  // Choose a more explicit way for the rule, because otherwise we have ambiguity, which costs in performance.
  public decimalLexicalRep = this.RULE("decimalLexicalRep", () => {
    let returnValue: string = this.OPTION1(() => this.CONSUME(Sign))?.image || "";
    this.OR([
      // .01
      {
        ALT: () => {
          returnValue += this.CONSUME1(Dot).image;
          this.AT_LEAST_ONE1(() => (returnValue += this.CONSUME1(Digit).image));
        },
      },
      //1
      {
        ALT: () => {
          this.AT_LEAST_ONE2(() => (returnValue += this.CONSUME2(Digit).image));
          //1.0 or 1.
          this.OPTION2(() => {
            returnValue += this.CONSUME2(Dot).image;
            this.MANY(() => (returnValue += this.CONSUME3(Digit).image));
          });
        },
      },
    ]);
    return returnValue;
  });

  private sharedFloatAndDouble = this.RULE("sharedFloatAndDouble", () => {
    let returnValue: string = this.OPTION1(() => this.CONSUME1(Sign))?.image || "";
    let infinityOrNan = "";
    this.OR([
      // FOR NaN
      // FOR INFINITY
      {
        ALT: () => {
          this.AT_LEAST_ONE1(() => (infinityOrNan += this.CONSUME(Inf).image));
        },
      },
      {
        ALT: () => {
          this.AT_LEAST_ONE2(() => (infinityOrNan += this.CONSUME(Nan).image));
        },
      },
      // .01
      {
        ALT: () => {
          returnValue += this.CONSUME1(Dot).image;
          this.AT_LEAST_ONE3(() => (returnValue += this.CONSUME1(Digit).image));
        },
      },
      {
        ALT: () => {
          // 1
          this.AT_LEAST_ONE4(() => (returnValue += this.CONSUME2(Digit).image));
          // 1.0 or 1.
          this.OPTION(() => {
            returnValue += this.CONSUME2(Dot).image;
            this.MANY2(() => (returnValue += this.CONSUME3(Digit).image));
          });
        },
      },
    ]);
    // exponential part
    this.OPTION3(() => {
      returnValue += this.CONSUME(Exponential).image;
      this.OPTION2(() => (returnValue += this.CONSUME2(Sign)?.image));
      this.AT_LEAST_ONE5(() => (returnValue += this.CONSUME4(Digit).image));
    });
    if (infinityOrNan === "INF") {
      return returnValue + Infinity;
    } else if (infinityOrNan === "NaN") {
      return infinityOrNan;
    } else {
      return returnValue;
    }
  });

  /**
   * [4] floatRep ::= noDecimalPtNumeral // 1
   *                | decimalPtNumeral // 1.0 or .01 or 1.
   *              | scientificNotationNumeral //1.0e10 or 1e10 or .1e10 or 1.e10
   *              | numericalSpecialRep //'INF' | '-INF' | 'NaN'| '+INF'
   */
  public floatRep = this.RULE("floatRep", () => {
    return this.SUBRULE(this.sharedFloatAndDouble);
  });
  /**
   * [5] doubleRep ::= noDecimalPtNumeral | decimalPtNumeral| scientificNotationNumeral| numericalSpecialRep
   */

  public doubleRep = this.RULE("doubleRep", () => {
    return this.SUBRULE(this.sharedFloatAndDouble);
  });
}

export const lexer = new Lexer(tokens, {
  ensureOptimizations: true,
  // not tracking lines for this grammar (not setting this will print a warning)
  positionTracking: "onlyOffset",
});
export const parser = new DecimalParser();

/**
 * Maps a decimal to its canonical representation, a string that matches
 * grammar rule `decimalLexicalRep`.
 *
 * @param d A decimal value.
 * @retrun  A string that matches grammar rule `decimalLexicalRep`.
 *
 * Algorithm:
 * - If `d` is an integer, then return `noDecimalPtCanonicalMap(d)`.
 * - Otherwise, return `decimalPtCanonicalMap(d)`.
 */
export function decimalToCanonical(d: string): string {
  const sign = d[0] === "-" ? "-" : "";

  const stripStart = ["0", "+", "-"];
  const [large, small] = d.split(".");
  let start: number;
  for (start = 0; stripStart.includes(large[start]); start++);

  const startPart = large.slice(start) || "0";

  let end: number;
  if (small !== undefined) {
    for (end = small.length - 1; "0" === small[end]; end--);
    if (end >= 0) {
      return sign + startPart + "." + small.slice(0, end + 1);
    }
  }
  if (startPart === "0") return "0";
  return sign + startPart;
}

/**
 * Convert a JS number to a string that is a legal lexical value for xsd:decimal
 *
 * You should call this function if you're converting an arbitrarily large number.
 *
 * https://www.w3.org/TR/xpath-functions/#op.numeric states the following For
 *  xs:decimal values, let N be the number of digits of precision supported by
 *  the implementation, and let M (M <= N) be the minimum limit on the number of
 *  digits required for conformance (18 digits for XSD 1.0, 16 digits for XSD
 *  1.1).
 */
export function numberToDecimalLexicalValue(decimalValue: number): string {
  if (!Number.isFinite(decimalValue)) new Error("Decimals must be finite");
  /**
   * Localestring removes the 'e' notation. Decimal parsing does not include
   * scientific notation
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
   *
   * The maximumSignificantDigits is 15, since node doesn't support 16. When we
   * use 15, we get precision issues in serialized literals.
   * See https://issues.triply.cc/issues/8921
   */
  return decimalValue.toLocaleString("fullwide", { useGrouping: false, maximumSignificantDigits: 15 });
}

export function doubleToCanonical(s: string): string {
  return numberToCanonicalDouble(+s);
}

export function numberToCanonicalDouble(n: number): string {
  if (Number.isNaN(n)) return "NaN";
  if (n === Infinity) return "INF";
  if (-n === Infinity) return "-INF";
  if (Object.is(n, -0)) return "-0.0E0";
  const str = n.toExponential().toUpperCase();
  const [mantissa, exponent] = str.split("E");
  return `${mantissa}${mantissa.includes(".") ? "" : ".0"}E${+exponent}`;
}

export function floatToCanonical(value: string) {
  // The float canonical value is very similar to the double,
  // with the exception of validating the range (range validation is not implemented yet)
  return doubleToCanonical(value);
}
