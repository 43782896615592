import { Alert } from "@mui/material";
import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { connect, useSelector } from "react-redux";
import { asyncConnect } from "redux-connect";
import * as ReduxForm from "redux-form";
import { SUBSCRIPTIONS_PAGE } from "@triply/utils/Constants";
import * as Forms from "#components/Forms/index.ts";
import { AccountMetadata, Button, Dialog, ErrorPage, FlexContainer, OrgListItem, SinkList } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import type { Org, User } from "#reducers/accountCollection.ts";
import { accountIsCurrentAccount, getAccountInfo, getCurrentAccount } from "#reducers/app.ts";
import type { DispatchedFn, GlobalState } from "#reducers/index.ts";
import { createOrganization, removeMemberFromOrg } from "#reducers/orgs.ts";

namespace OrgList {
  export interface OwnProps extends IComponentProps<{ showForm?: boolean; orgAddModalShown?: boolean }> {}
  export interface DispatchProps {
    createOrganization: DispatchedFn<typeof createOrganization>;
    leaveOrganization: DispatchedFn<typeof removeMemberFromOrg>;
    goBack: typeof connectedReactRouter.goBack;
    pushState: typeof connectedReactRouter.push;
    replaceState: typeof connectedReactRouter.replace;
  }
  export interface PropsFromState {
    currentUser?: User;
    showForm: boolean;
    triplyDbComMode?: boolean;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState;
}

const OrgList: React.FC<OrgList.Props> = ({
  currentUser,
  goBack,
  triplyDbComMode,
  location,
  leaveOrganization,
  pushState,
  createOrganization,
  replaceState,
}) => {
  const acl = useAcl();
  const triplyDbDemoLink = useSelector((state: GlobalState) => state.config.clientConfig?.triplydb?.triplydbDemoLink);
  if (!currentUser) return <ErrorPage statusCode={404} />;
  const roleInAccount = acl.getRoleInAccount(currentUser);
  if (!acl.check({ action: "editAccountMetadata", context: { roleInAccount: roleInAccount } }).granted) {
    return <ErrorPage statusCode={401} />;
  }
  const canCreateOrganization = acl.check({
    action: "createOrganization",
    context: { roleInUser: roleInAccount },
  }).granted;
  return (
    <FlexContainer>
      <AccountMetadata
        currentAccount={currentUser}
        currentPath={location.pathname}
        title={"Organizations - User settings"}
      />
      {triplyDbComMode && roleInAccount === "owner" && currentUser?.role === "light" && (
        <div className="whiteSink">
          {
            <Alert
              severity="info"
              action={
                triplyDbDemoLink && (
                  <a href={triplyDbDemoLink} target="_blank">
                    <Button variant="text">Book a demo</Button>
                  </a>
                )
              }
            >
              Only{" "}
              <a href={SUBSCRIPTIONS_PAGE} target="_blank">
                enterprise users
              </a>{" "}
              may create organizations.
              <br />
              To find out more about this feature, book a demo!
            </Alert>
          }
        </div>
      )}

      {canCreateOrganization && currentUser.orgs && currentUser.orgs.length > 0 && (
        <Button
          color="secondary"
          className="ml-3 mt-3"
          elevation
          onClick={() => pushState({ state: { orgAddModalShown: true } })}
        >
          Create organization
        </Button>
      )}
      {canCreateOrganization && currentUser.orgs && currentUser.orgs.length === 0 && (
        <div className="whiteSink">
          <h3>Create organization</h3>
          <Forms.OrgAdd
            onSubmit={(values: Forms.OrgAdd.FormData) => {
              return createOrganization(currentUser, values)
                .then(() => {
                  pushState(`/${values.accountName}`);
                })
                .catch((e) => {
                  if (e?.status === 409) {
                    throw new ReduxForm.SubmissionError({ accountName: "URL is already in use." });
                  }
                  throw new ReduxForm.SubmissionError({ _error: e.message });
                });
            }}
          />
        </div>
      )}
      <SinkList noContentMsg="There are currently no organizations">
        {currentUser.orgs &&
          currentUser.orgs.map((o) => (
            <OrgListItem
              key={o.accountName}
              account={o}
              leaveOrgHandler={(org: Org) => {
                return leaveOrganization(currentUser, org);
              }}
            />
          ))}
      </SinkList>
      <Dialog
        open={!!location.state && !!location.state.orgAddModalShown}
        onClose={goBack}
        title="Create organization"
        maxWidth="md"
        fullWidth
      >
        <Forms.OrgAdd
          className="p-5"
          onSubmit={(values: Forms.OrgAdd.FormData) => {
            return createOrganization(currentUser, values)
              .then(() => {
                replaceState(`/${values.accountName}`);
              })
              .catch((e) => {
                if (e?.status === 409) {
                  throw new ReduxForm.SubmissionError({ accountName: "URL is already in use." });
                }
                throw new ReduxForm.SubmissionError({ _error: e.message });
              });
          }}
          cancel={goBack}
        />
      </Dialog>
    </FlexContainer>
  );
};

export default connect<
  OrgList.PropsFromState,
  { [K in keyof OrgList.DispatchProps]: any },
  OrgList.OwnProps,
  GlobalState
>(
  (state, ownProps) => {
    const currentAccount = getCurrentAccount(state);
    return {
      currentUser: currentAccount?.type === "user" ? currentAccount : undefined,
      showForm: !!ownProps.location?.state?.showForm,
      triplyDbComMode: !!state.config.clientConfig?.triplydb,
    };
  },
  //dispatch
  {
    createOrganization: createOrganization,
    leaveOrganization: removeMemberFromOrg,
    goBack: connectedReactRouter.goBack,
    pushState: connectedReactRouter.push,
    replaceState: connectedReactRouter.replace,
  },
)(
  asyncConnect<GlobalState>([
    {
      promise: ({ match: { params }, store: { dispatch, getState } }) => {
        if (!accountIsCurrentAccount(getState(), params.account)) {
          return dispatch<any>(getAccountInfo(getState(), params.account));
        }
      },
    },
  ])(OrgList) as typeof OrgList,
);
