import { jsonLanguage } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";
import { Divider } from "@mui/material";
import { githubLight } from "@uiw/codemirror-theme-github";
import CodeMirror from "@uiw/react-codemirror";
import getClassName from "classnames";
import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import * as ReduxForm from "redux-form";
import type { Hook, WebHookTriggerRecord } from "@triply/utils/Models";
import FontAwesomeButton from "#components/FontAwesomeButton/index.tsx";
import type { WebHook } from "#components/Forms/index.ts";
import * as Forms from "#components/Forms/index.ts";
import { Button, Dialog, FontAwesomeIcon, HumanizedDate } from "#components/index.ts";
import LinkButton from "#components/LinkButton/index.tsx";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import type { Account } from "#reducers/accountCollection.ts";
import type { Dataset } from "#reducers/datasetManagement.ts";
import type { getHookRecords } from "#reducers/hookRecords.ts";
import type { DispatchedFn, GlobalState } from "#reducers/index.ts";
import type { removeHook, updateHook } from "#reducers/webhooks.ts";
import * as styles from "./style.scss";

export interface Props {
  hook: Hook;
  currentAccount: Account;
  currentDs: Dataset;
  updateHook: DispatchedFn<typeof updateHook>;
  removeHook: DispatchedFn<typeof removeHook>;
  getRecords: DispatchedFn<typeof getHookRecords>;
  className?: string;
  writeAccess: boolean;
}

const WebHookListItem: React.FC<Props> = ({
  hook,
  currentAccount,
  currentDs,
  updateHook,
  removeHook,
  getRecords,
  className,
  writeAccess,
}) => {
  const [showHistory, setShowHistory] = React.useState(false);
  const disableHostnameValidationFor = useSelector(
    (state: GlobalState) => state.config.values?.disableHostnameValidationFor,
  );
  const [records, setRecords] = React.useState([]);
  const [dialogInfo, setDialogInfo] = React.useState(null);
  const [canLoadMore, setCanLoadMore] = React.useState(true);
  const confirm = useConfirmation();
  const location = useLocation<{ editWebhook: string } | undefined>();
  const dispatch = useDispatch();

  const goBack = () => dispatch(connectedReactRouter.goBack());

  const handleHookSubmit = (values: WebHook.FormData) => {
    return updateHook(currentAccount, currentDs, values).then(
      () => {
        goBack();
      },
      (e: Error) => {
        throw new ReduxForm.SubmissionError({ _error: e.message });
      },
    );
  };

  const toggleHistory = async () => {
    if (!showHistory) {
      await getRecords(currentAccount, currentDs, hook, 0, 10).then((res: any) => {
        setRecords(res.body.records);
        setCanLoadMore(res.body.canLoadMore);
      });
    }

    setShowHistory(!showHistory);
  };

  const showMore = async () => {
    await getRecords(
      currentAccount,
      currentDs,
      hook,
      //records.length, // this would save us some data, but isn't safe since the list of hooks might have changed since the previous refresh of the list
      0,
      records.length + 10,
    ).then((res: any) => {
      setRecords(res.body.records);
      setCanLoadMore(res.body.canLoadMore);
    });
  };

  const showLess = async () => {
    await getRecords(
      currentAccount,
      currentDs,
      hook,
      0,
      Math.max(records.length - (records.length % 10 || 10), 10),
    ).then((res: any) => {
      setRecords(res.body.records);
      setCanLoadMore(res.body.canLoadMore);
    });
  };

  const closeDetailDialog = () => setDialogInfo(null);

  return (
    <div className={getClassName(className, "pt-1 px-5 pb-5")}>
      <div className="flex">
        <div className="grow">
          <dl>
            <dt>Payload target</dt>
            <dd>{hook.url}</dd>
            <dt>Payload format</dt>
            <dd>{hook.payloadFormat === "JSON" ? "JSON" : "Form-Encoded"}</dd>
            <dt>Trigger events</dt>
            <dd>
              {Object.keys(hook.onEvents)
                .map((key) => {
                  if (!hook.onEvents[key as keyof typeof hook.onEvents]) return;
                  switch (key) {
                    case "graphImport":
                      return "Graph import";
                    case "linkedDataUpload":
                      return "Linked data upload";
                    case "fileUpload":
                      return "Asset upload";
                  }
                })
                .filter((i) => !!i)
                .join(", ")}
            </dd>
            <dt>Active</dt>
            <dd aria-label={hook.active ? "true" : "false"}>
              <FontAwesomeIcon icon={hook.active ? "check" : "times"} />
            </dd>
          </dl>
        </div>
        {writeAccess && (
          <div className="ml-3 mt-5">
            <div className="flex">
              <FontAwesomeButton
                title="Edit"
                icon="pencil"
                onClick={() =>
                  dispatch(connectedReactRouter.push({ state: { editWebhook: hook.id, preserveScrollPosition: true } }))
                }
              />
              <FontAwesomeButton
                title="Remove"
                icon="times"
                onClick={() => {
                  confirm({
                    description: "Are you sure you want to remove this webhook?",
                    title: "Remove webhook?",
                    actionLabel: "Remove",
                    onConfirm: () => removeHook(currentAccount, currentDs, hook),
                  });
                }}
              />
            </div>
          </div>
        )}
        {writeAccess && (
          <Dialog
            open={location.state?.editWebhook === hook.id}
            onClose={goBack}
            title="Edit webhook"
            maxWidth="md"
            fullWidth
          >
            <Forms.WebHook
              className="m-5"
              form={`hook-form-${hook.id}`}
              disableHostnameValidationFor={disableHostnameValidationFor || []}
              initialValues={hook}
              onSubmit={handleHookSubmit}
              cancelFunction={goBack}
            />
          </Dialog>
        )}
      </div>

      <div className="mt-5">
        <div>
          {/*toggle history button*/}
          <FontAwesomeButton
            iconOnStart
            icon={["fas", showHistory ? "caret-down" : "caret-right"]}
            onClick={toggleHistory}
            aria-label={(showHistory ? "Hide" : "Show") + " webhook trigger history"}
          >
            {(showHistory ? "Hide" : "Show") + " webhook trigger history"}
          </FontAwesomeButton>
        </div>

        {showHistory && dialogInfo && (
          <Dialog open={true} onClose={closeDetailDialog} title="Webhook Activation Details" maxWidth={"xl"} fullWidth>
            <div className={getClassName("m-5", styles.cmContainer)}>
              <CodeMirror
                className={styles.codemirror}
                value={JSON.stringify(dialogInfo, null, 2)}
                extensions={[jsonLanguage, githubLight, EditorView.lineWrapping]}
                readOnly={true}
              />
            </div>
            <div className="mx-5 mb-5">
              <Button variant="text" onClick={closeDetailDialog}>
                Close
              </Button>
            </div>
          </Dialog>
        )}

        {/*history table*/}
        {showHistory && (
          <div className={getClassName(styles.centered)}>
            <div key="TableHeader" className={getClassName(styles.record)}>
              <div className={styles.recordColumn}>Called at</div>
              <div className={styles.recordColumn}>To</div>
              <div className={styles.recordColumn}>Response code</div>
              <div className={styles.recordColumn}>Content</div>
            </div>
            <Divider />
            <div key="TableBody" className={getClassName(styles.records)}>
              {records.map((v: WebHookTriggerRecord) => (
                <div key={v.id} className={styles.record}>
                  <div className={styles.recordColumn}>{v.createdAt && <HumanizedDate date={v.createdAt} />}</div>
                  <div className={styles.recordColumn}>
                    {/*{v.url.length>30?v.url.slice(0,25)+'...':v.url}*/}
                    {v.url}
                  </div>
                  <div className={styles.recordColumn}>{v.errorCode ? v.errorCode : v.statusCode}</div>
                  <div className={styles.recordColumn}>
                    <LinkButton
                      onClickOrEnter={() => {
                        // deepcopy, then delete id field from the copy
                        let r = JSON.parse(JSON.stringify(v));
                        delete r.id;
                        setDialogInfo(r);
                      }}
                    >
                      Details
                    </LinkButton>
                  </div>
                </div>
              ))}
            </div>

            <div>
              <span>
                {records.length > 10 && <LinkButton onClickOrEnter={showLess}>Show less</LinkButton>}

                {canLoadMore && records.length > 10 && <span> / </span>}

                {canLoadMore && <LinkButton onClickOrEnter={showMore}>Show more</LinkButton>}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default WebHookListItem;
