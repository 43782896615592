import getClassName from "classnames";
import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { asyncConnect } from "redux-connect";
import { AccountListItem, FlexContainer, Meta, SearchField, SinkList } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import type { Account } from "#reducers/accountCollection.ts";
import { getAccountList, getAccounts, isAccountListLoaded } from "#reducers/accounts.ts";
import type { DispatchedFn, GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

export namespace Accounts {
  export interface OwnProps extends IComponentProps {}
  export interface DispatchProps {
    getAccounts: DispatchedFn<typeof getAccounts>;
    pushState: typeof connectedReactRouter.push;
  }
  export interface PropsFromState {
    accounts: Account[];
    fetchingList: boolean;
    fetchingListError?: string;
    lastSearchTerm?: string;
    nextPage?: string;
  }
  export interface State {
    locationQueryTerm: string;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState;
}

@asyncConnect<GlobalState>([
  {
    promise: ({ location, match: {}, store: { dispatch, getState } }) => {
      if (!isAccountListLoaded(getState(), location.search)) {
        return dispatch<any>(getAccounts(undefined, parseSearchString(location.search)));
      }
    },
  },
])
class Accounts extends React.PureComponent<Accounts.Props, any> {
  constructor(props: Accounts.Props) {
    super(props);
    this.state = {
      locationQueryTerm: parseSearchString(props.location.search).q || "",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Accounts.Props) {
    const nextQuery = parseSearchString(nextProps.location.search);
    if (nextQuery.q !== parseSearchString(this.props.location.search).q) {
      this.setState({
        locationQueryTerm: nextQuery.q || "",
      });
    }
  }

  loadNextAccountsPage = () => {
    this.props.getAccounts(this.props.nextPage).catch(() => {});
  };

  search = (term: string) => {
    this.props.pushState({
      pathname: `/accounts`,
      search: stringifyQuery({ q: term.trim() }),
    });
  };

  render() {
    const { accounts, nextPage, fetchingList, fetchingListError } = this.props;

    return (
      <>
        <Meta title="Accounts" currentPath={this.props.location.pathname} />
        <FlexContainer className="mt-3">
          <div className={styles.outerSearchContainer}>
            <div className={getClassName("mt-7 px-1", styles.innerSearchContainer)}>
              <SearchField
                ariaLabel="Sitewide"
                search={this.search}
                searching={fetchingList}
                initialSearchTerm={this.state.locationQueryTerm}
                placeHolder="Find a user or an organization"
                autoFocus
              />
            </div>
          </div>

          <SinkList
            loadNextPage={nextPage ? this.loadNextAccountsPage : undefined}
            fetchingListError={fetchingListError}
            noContentMsg="No accounts found"
          >
            {accounts
              .filter((a) => a.accountName)
              .map((account) => (
                <AccountListItem key={account.accountName} account={account} />
              ))}
          </SinkList>
        </FlexContainer>
      </>
    );
  }
}
export default connect<
  Accounts.PropsFromState,
  { [K in keyof Accounts.DispatchProps]: any },
  Accounts.OwnProps,
  GlobalState
>(
  (state) => {
    return {
      accounts: getAccountList(state),
      lastSearchTerm: state.accounts.listFor?.searchTerm || undefined,
      fetchingList: !!state.accounts.fetchingList,
      fetchingListError: state.accounts.fetchingListError,
      nextPage: state.accounts.nextPage,
    };
  },
  //dispatch
  {
    getAccounts,
    pushState: connectedReactRouter.push,
  },
)(Accounts);
