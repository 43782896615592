import { formatNumber } from "@core/utils/formatting";
import { Badge, CircularProgress, Fade, ListItemAvatar, ListItemButton, ListItemText, Tooltip } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "#components/index.ts";
import * as styles from "./style.scss";

interface Props {
  name: string;
  icon?: FontAwesomeIcon.Props["icon"];
  beta?: true;
  className?: string;
  active?: boolean;
  panelCollapsed: boolean;
  to: LinkProps["to"];
  number?: number;
  loading?: boolean;
  warning?: boolean;
  mobile?: boolean;
}

const PanelItem: React.FC<Props> = ({
  name,
  className,
  active,
  to,
  number,
  loading,
  panelCollapsed,
  beta,
  warning,
  mobile,
  icon,
}) => {
  if (panelCollapsed) {
    if (!icon) return null;
    return (
      <Tooltip
        title={name}
        placement="right"
        enterDelay={0}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 0 }}
        describeChild
      >
        <ListItemButton
          component={Link}
          className={getClassName(className, styles.item)}
          to={to}
          selected={active}
          replace={mobile}
        >
          {icon && (
            <ListItemAvatar className={styles.collapsedAvatar}>
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={
                  warning ? (
                    <FontAwesomeIcon className={styles.warning} icon={["fas", "exclamation-triangle"]} />
                  ) : undefined
                }
              >
                <Badge
                  badgeContent={loading ? <CircularProgress size="0.75rem" /> : number}
                  className={styles.numberBadge}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <FontAwesomeIcon
                    size="lg"
                    fixedWidth
                    className={getClassName({
                      ["fa-flip-vertical"]: icon === "paperclip",
                    })}
                    icon={icon}
                  />
                </Badge>
              </Badge>
            </ListItemAvatar>
          )}
        </ListItemButton>
      </Tooltip>
    );
  }

  return (
    <ListItemButton
      component={Link}
      className={getClassName(className, styles.item)}
      to={to}
      selected={active}
      replace={mobile}
    >
      {icon && (
        <ListItemAvatar className={styles.collapsedAvatar}>
          <Badge>
            <FontAwesomeIcon
              size="lg"
              fixedWidth
              className={getClassName({
                ["fa-flip-vertical"]: icon === "paperclip",
              })}
              icon={icon}
            />
          </Badge>
        </ListItemAvatar>
      )}
      <ListItemText inset={!icon} className={styles.text}>
        {name}
        {beta && <sup className={styles.beta}>BETA</sup>}
        {warning && (
          <FontAwesomeIcon className={getClassName("ml-2", styles.warning)} icon={["fas", "exclamation-triangle"]} />
        )}
      </ListItemText>
      {loading && <CircularProgress size="1.25rem" color={active ? "secondary" : "primary"} />}
      {!loading && number !== undefined && number > -1 && <div className={styles.number}>{formatNumber(number)}</div>}
    </ListItemButton>
  );
};
export default PanelItem;
