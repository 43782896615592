import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { Alert, FlexContainer, Meta } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import { verify as verifyUser } from "#reducers/auth.ts";
import type { DispatchedFn, GlobalState } from "#reducers/index.ts";

export namespace VerifyAccount {
  export interface OwnProps extends IComponentProps {}
  export interface DispatchProps {
    pushState: typeof connectedReactRouter.push;
    verifyUser: DispatchedFn<typeof verifyUser>;
  }
  export interface PropsFromState {
    verificationError?: string;
    pathName: string;
    verified: boolean;
    verifying: boolean;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState;
}

class VerifyAccount extends React.PureComponent<VerifyAccount.Props, any> {
  componentDidMount() {
    if (__CLIENT__) {
      const pathname = this.props.pathName.split("/");
      const l = pathname.length;
      return this.props.verifyUser(pathname[l - 2], pathname[l - 1]);
    }
  }

  render() {
    const { verificationError } = this.props;
    return (
      <FlexContainer>
        <Meta currentPath={this.props.location.pathname} title="Verify account" />
        <div style={{ padding: 20 }}>
          {verificationError && <Alert error message={`${verificationError}`} className="shadow" />}
        </div>
      </FlexContainer>
    );
  }
}

export default connect<
  VerifyAccount.PropsFromState,
  { [K in keyof VerifyAccount.DispatchProps]: any },
  VerifyAccount.OwnProps,
  GlobalState
>(
  (state, ownProps) => {
    return {
      verificationError: state.auth.verifyError,
      verified: state.auth.verified,
      verifying: state.auth.verifying,
      pathName: ownProps.location.pathname,
    };
  },
  //dispatch
  {
    pushState: connectedReactRouter.push,
    verifyUser: verifyUser,
  },
)(VerifyAccount);
