import {
  accountDescriptionValidations,
  applyRuleConfig,
  emailValidations,
  getAccountNameValidations,
  getProfileNameValidations,
  required,
  toStringValidator,
} from "@core/utils/validation";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import type { MarkdownEditField, MuiTextField } from "#components/index.ts";
import { Alert, FormField, Label, MarkdownEditFieldRedux, MuiTextFieldRedux, NameAndUrl } from "#components/index.ts";

namespace OrgProfile {
  export interface FormData {
    accountName: string;
    name: string;
    email: string;
    description: string;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    passwordAuthMethod?: boolean;
  }
}

const accountNameValidator = toStringValidator(getAccountNameValidations());
const nameValidator = toStringValidator([
  ...getProfileNameValidations({ messageSubject: "An organization name" }),
  applyRuleConfig(required, {
    formatMessage: () => `An organization name is required.`,
  }),
]);
const emailValidator = toStringValidator(emailValidations);
const descriptionValidator = toStringValidator(accountDescriptionValidations);

const OrgProfile = ReduxForm.reduxForm<OrgProfile.FormData, OrgProfile.Props>({
  form: "orgProfileForm",
  enableReinitialize: true,
  validate: memoizee(
    (formData: OrgProfile.FormData) => {
      return {
        accountName: accountNameValidator(formData.accountName),
        name: nameValidator(formData.name),
        email: emailValidator(formData.email, {
          skipRuleOn: (_, err) => {
            return err.code === "REQUIRED";
          },
        }),
        description: descriptionValidator(formData.description),
      };
    },
    { max: 10 },
  ),
  warn: memoizee(
    (formData: OrgProfile.FormData, props: OrgProfile.Props) => {
      return {
        accountName:
          (formData.accountName &&
            props.initialValues?.accountName &&
            formData.accountName.toLowerCase() !== props.initialValues.accountName.toLowerCase() &&
            "Changing the URL will break links to pages and APIs of the organization and its datasets, services, stories and queries.") ||
          undefined,
      };
    },
    { max: 10 },
  ),
})(
  class OrgProfile extends React.PureComponent<
    OrgProfile.Props & ReduxForm.InjectedFormProps<OrgProfile.FormData>,
    any
  > {
    render() {
      const { handleSubmit, submitting, submitSucceeded, error, pristine, change, initialValues, invalid } = this.props;
      return (
        <div>
          <form method="POST" onSubmit={handleSubmit}>
            <NameAndUrl
              className="mt-3 mb-6"
              changeFormValues={change}
              initialSlug={initialValues?.accountName}
              formIsPristine={pristine}
              autoFocus
              nameFieldName="name"
              nameFieldLabel="Organization name"
              slugFieldName="accountName"
              slugFieldLabel="URL"
              urlPrefixPath="/"
            />

            <FormField label="Primary email address" className="mb-6">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextField.Props>>
                name="email"
                props={{
                  type: "email",
                  fullWidth: true,
                }}
                component={MuiTextFieldRedux}
              />
            </FormField>

            <FormField label="Description (optional)" className="mb-6">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MarkdownEditField.Props>>
                name="description"
                props={{
                  multiline: true,
                  rows: 5,
                  fullWidth: true,
                }}
                component={MarkdownEditFieldRedux}
              />
            </FormField>

            <Alert transparent message={error} />

            <div className="form-group mt-5">
              <LoadingButton
                type="submit"
                color="secondary"
                disabled={invalid || pristine}
                onClick={handleSubmit}
                loading={submitting}
              >
                Update profile
              </LoadingButton>
            </div>
          </form>

          {submitSucceeded && pristine && (
            <div className="mt-4">
              <Label success message="Profile successfully updated." />
            </div>
          )}
        </div>
      );
    }
  } as any,
);

export default OrgProfile;
