import commonCrs from "./Crs/commonCrs.ts";
import generatedCrs from "./Crs/generatedCrs.ts";

export { commonCrs as commonCrsProjectionDefs };

export const allCrsProjectionDefs = {
  // This has to be before the other written-down definitions, because the latter are corrected.
  ...generatedCrs,
  ...commonCrs,
};

export type AllCrsUrl = keyof typeof allCrsProjectionDefs;
type CommonCrsUrl = keyof typeof commonCrs;

export const supportedCrsUrls = new Set(Object.keys(allCrsProjectionDefs));

const supportedAsCommonCrsUrls = new Set(Object.keys(commonCrs));
/**
 * Check whether projection is supported for a given CRS URL
 */
export function isSupportedCrs(crs: string): crs is AllCrsUrl {
  return supportedCrsUrls.has(crs);
}

/**
 * Check whether projection is supported as one of the common CRSes
 */
export function isSupportedAsCommonCrs(crs: string): crs is CommonCrsUrl {
  return supportedAsCommonCrsUrls.has(crs);
}
