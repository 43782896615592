import { Alert, DialogContent, DialogContentText } from "@mui/material";
import getClassName from "classnames";
import * as connectedReactRouter from "connected-react-router";
import type { Location } from "history";
import { isEmpty, isEqual, pickBy } from "lodash-es";
import * as React from "react";
import { useSelector } from "react-redux";
import { Prompt, Redirect, useLocation } from "react-router";
import { asyncConnect } from "redux-connect";
import type { Models } from "@triply/utils";
import { Button, Dialog, ErrorPage, FlexContainer, HumanizedDate, LoadingPage } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import type { TestValues } from "#containers/Story/Query.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { accountIsCurrentAccount, getAccountInfo, getCurrentAccount } from "#reducers/app.ts";
import type { GlobalState } from "#reducers/index.ts";
import { addVersion, getCurrentQuery, getQuery } from "#reducers/queries.ts";
import { addQueryHistoryItem } from "#reducers/sessionHistory.ts";
import { useAdjustQueryBeforeRequest } from "../../components/QueryVariableUtils/useAdjustQueryBeforeRequest.ts";
import { EditorContextProvider, useContextualEditor } from "../../components/Sparql/Editor/EditorContext.tsx";
import type { VisualizationConfig, VisualizationLabel } from "../../components/Sparql/QueryResults/index.tsx";
import {
  getIdeVisualization,
  isIdeVisualization,
  yasguiVisualizationConfigToIdeVisualizationConfig,
} from "../../components/Sparql/SparqlUtils.ts";
import { createDatasetPrefix } from "../../reducers/datasetManagement.ts";
import Dependents from "./Dependents.tsx";
import Editor from "./Editor.tsx";
import Meta from "./Meta.tsx";
import QueryInfo from "./QueryInfo.tsx";
import type { LocationState as QueryVarLocationState } from "./QueryVars.tsx";
import QueryVars from "./QueryVars.tsx";
import { SavedQueryProvider } from "./SavedQueryContext.tsx";
import useUncontrolledLocalStorage from "./useUncontrolledLocalStorage.ts";
import VersionSelector from "./VersionSelector.tsx";
import * as styles from "./style.scss";

function compareIgnoringNulls(lhs: any, rhs: any) {
  return isEqual(
    pickBy(lhs, (value) => !!value && !isEmpty(value)),
    pickBy(rhs, (value) => !!value && !isEmpty(value)),
  );
}

interface QueryChanges {
  visualization: VisualizationLabel | undefined;
  visualizationConfig: VisualizationConfig;
  variables: Models.VariableConfig[] | undefined;
}

interface PersistedDraftQuery extends QueryChanges {
  query: string;
}

function versionConfigIsEqual(lhs: QueryChanges, rhs: QueryChanges): boolean {
  return (
    lhs.visualization === rhs.visualization &&
    compareIgnoringNulls(lhs.visualizationConfig, rhs.visualizationConfig) &&
    compareIgnoringNulls(lhs.variables, rhs.variables)
  );
}

interface LocationState extends QueryVarLocationState {
  skipDraftPrompt?: boolean;
  queryVariableTestValues?: TestValues;
}

const getVersion = (query: Models.Query | undefined): number => {
  if (!query) return 0;
  const v = +query.version;
  if (isNaN(v)) return 0;
  return v;
};
const getPath = (accountName: string | undefined, queryName: string, postPath?: string | number) =>
  "/" + [accountName || "", "-", "queries", queryName, postPath || ""].join("/");

const Query: React.FC<
  IComponentProps & {
    reset: () => void;
    query: Models.Query;
    recoveredQueryConfig?: QueryChanges;
    storeDraft: (draft: QueryChanges & { query: string }) => void;
    clearDraft: () => void;
  }
> = ({ location, match: { params }, reset, query, recoveredQueryConfig, storeDraft, clearDraft }) => {
  const currentAccountId = useSelector((state: GlobalState) => getCurrentAccount(state)?.uid);
  const accountCollection = useSelector((state: GlobalState) => state.accountCollection);
  const acl = useAcl();
  const dispatch = useDispatch();
  const staticConfig = useSelector((state: GlobalState) => state.config.staticConfig);
  // Request config
  const { getQueryString, dirty } = useContextualEditor();

  // Results config
  const isIdeConfig = !query?.renderConfig?.output || isIdeVisualization(query.renderConfig.output);
  const [visualization, setVisualization] = React.useState(
    recoveredQueryConfig?.visualization ||
      (query?.requestConfig?.ldFrame ? "LDFrame" : getIdeVisualization(query?.renderConfig?.output)),
  );
  const isFramed = visualization === "LDFrame";
  const [visualizationConfig, setVisualizationConfig] = React.useState<VisualizationConfig>(
    recoveredQueryConfig?.visualizationConfig ||
      (isFramed
        ? { frame: query?.requestConfig?.ldFrame }
        : !query?.renderConfig?.output || isIdeConfig
          ? query?.renderConfig?.settings
          : yasguiVisualizationConfigToIdeVisualizationConfig(
              query?.renderConfig?.output,
              query?.renderConfig?.settings,
              staticConfig,
            )),
  );

  // VariableConfig
  const [variableConfig, setVariableConfigLocal] = React.useState<Models.VariableConfig[] | undefined>(
    recoveredQueryConfig?.variables || query?.variables,
  );
  const [variableNames, setVariableNames] = React.useState<string[]>(
    recoveredQueryConfig
      ? recoveredQueryConfig?.variables?.map((variable) => variable.name) || []
      : query?.variables?.map((variable) => variable.name) || [],
  );
  const setVariableConfig = React.useCallback((variableConfig: Models.VariableConfig[] | undefined) => {
    setVariableConfigLocal(variableConfig);
    // Make sure we remove the variables from the testvalues as well, if they are removed
    setTestValues((currentValues) => {
      // using the functional setter here, as to not create a dependency
      for (const variableName of Object.keys(currentValues)) {
        if (!variableConfig?.find((config: Models.VariableConfig) => config.name === variableName)) {
          delete currentValues[variableName];
        }
      }
      return currentValues;
    });
    setVariableNames((currentFromVarConfig) => {
      const newVariablesFromVarConfig = variableConfig?.map((variable) => variable.name) || [];

      return isEqual(currentFromVarConfig, newVariablesFromVarConfig)
        ? currentFromVarConfig
        : newVariablesFromVarConfig;
    });
  }, []);

  const { state: locationState, search } = useLocation<LocationState | undefined>();
  const [testValues, setTestValues] = React.useState<{ [varName: string]: string | undefined }>(
    locationState?.queryVariableTestValues || {},
  );

  const version = getVersion(query);
  const noVersionsAvailable = version === 0;
  const isDraft =
    noVersionsAvailable ||
    dirty ||
    (!!query &&
      !versionConfigIsEqual(
        {
          visualizationConfig: visualizationConfig,
          visualization: visualization,
          variables: variableConfig,
        },
        {
          visualizationConfig: query?.requestConfig?.ldFrame
            ? { frame: query?.requestConfig?.ldFrame }
            : !query?.renderConfig?.output || isIdeConfig
              ? query?.renderConfig?.settings
              : yasguiVisualizationConfigToIdeVisualizationConfig(
                  query?.renderConfig?.output,
                  query?.renderConfig?.settings,
                  staticConfig,
                ),
          visualization: query?.requestConfig?.ldFrame ? "LDFrame" : getIdeVisualization(query?.renderConfig?.output),
          variables: query.variables,
        },
      ));

  const getVersionInfo = React.useCallback(
    (queryString: string) => {
      return {
        requestConfig: {
          payload: {
            query: queryString,
          },
          ...(isFramed
            ? {
                ldFrame: (visualizationConfig && "frame" in visualizationConfig && visualizationConfig.frame) || {},
                headers: {
                  Accept: "application/ld+json;profile=http://www.w3.org/ns/json-ld#framed",
                },
              }
            : {}),
        },
        renderConfig: !isFramed
          ? {
              output: visualization || "Table",
              settings: isEmpty(visualizationConfig) ? undefined : visualizationConfig,
            }
          : undefined,
        variables: variableConfig,
      } as Models.QueryVersionUpdate;
    },
    [isFramed, variableConfig, visualization, visualizationConfig],
  );

  React.useEffect(() => {
    if (query) dispatch(addQueryHistoryItem(query));
  }, [query, dispatch]);

  React.useEffect(() => {
    if (isDraft) {
      const storeConfig = () =>
        storeDraft({
          query: getQueryString(),
          visualizationConfig: visualizationConfig,
          visualization: visualization,
          variables: variableConfig,
        });
      storeConfig();
      // We need to store it at an interval as we don't have a way to store the query string
      const interfal = setInterval(storeConfig, 30 * 1000);
      return () => clearInterval(interfal);
    } else {
      clearDraft();
    }
  }, [clearDraft, getQueryString, isDraft, storeDraft, variableConfig, visualization, visualizationConfig]);

  const adjustQueryBeforeRequest = useAdjustQueryBeforeRequest(testValues, query?.variables, variableConfig);

  const queryLink = query?.link;

  const saveNewVersion = React.useCallback(() => {
    if (!isDraft || !queryLink) return;
    dispatch<typeof addVersion>(addVersion(queryLink, getVersionInfo(getQueryString()))).then(
      ({ body: query }) => {
        if (query.owner.accountName && query.name) {
          clearDraft();
          dispatch(
            connectedReactRouter.push({
              pathname: getPath(query.owner.accountName, query.name, query.version),
              search: search,
              state: { skipDraftPrompt: true },
            }),
          );
        }
      },
      () => {},
    );
  }, [dispatch, isDraft, queryLink, search, getVersionInfo, getQueryString, clearDraft]);

  const allowedToCreatePrefixOnData = acl.check({
    action: "editDatasetMetadata",
    context: {
      roleInOwnerAccount: acl.getRoleInAccountFromState(accountCollection, currentAccountId),
      accessLevel: query.dataset?.accessLevel || "private",
      newAccessLevel: undefined,
    },
  }).granted;
  const createPrefix = React.useCallback(
    (prefix: Models.PrefixUpdate) => {
      if (query.dataset) {
        return dispatch<typeof createDatasetPrefix>(createDatasetPrefix(prefix, query.dataset?.owner, query.dataset))
          .then(() => true)
          .catch((e) => e.message);
      }
      throw new Error("Dataset not found");
    },
    [dispatch, query.dataset],
  );

  if (!query)
    return (
      <ErrorPage
        statusCode={404}
        message={`Query '${params.query}' ${
          params.queryVersion ? `version '${params.queryVersion}'` : ""
        } does not exist`}
      />
    );
  if (!currentAccountId) return <ErrorPage statusCode={404} />;

  const mayEdit = acl.check({
    action: "manageQuery",
    context: {
      roleInOwnerAccount: acl.getRoleInAccountFromState(accountCollection, currentAccountId),
      accessLevel: query.accessLevel,
      newAccessLevel: undefined,
    },
  }).granted;

  return (
    <SavedQueryProvider initialQuery={query}>
      <FlexContainer innerClassName={styles.container}>
        <Meta />
        <div className={getClassName("whiteSink")}>
          <QueryInfo
            editAllowed={mayEdit}
            hasUnsavedChanges={isDraft}
            variableConfig={variableConfig}
            visualization={visualization}
            visualizationConfig={visualizationConfig}
            testValues={testValues}
          />
        </div>
        {mayEdit || !noVersionsAvailable ? (
          <div className={getClassName("whiteSink")}>
            <div className={styles.versionRow}>
              <VersionSelector isDraft={isDraft} reset={reset} mayEdit={mayEdit} />
              {isDraft && mayEdit && (
                <Button size="small" onClick={saveNewVersion} variant="text">
                  Save as new version
                </Button>
              )}
              <div className="grow"></div>
              {isDraft && (
                <Button size="small" color="primary" onClick={reset} variant="text">
                  Reset
                </Button>
              )}
            </div>
            <QueryVars
              isDraft={isDraft}
              currentVersion={params.queryVersion}
              visualization={visualization}
              visualizationConfig={visualizationConfig}
              variableDefinitions={variableConfig}
              testValues={testValues}
              onTestValuesChange={setTestValues}
              onVariableDefinitionsChange={setVariableConfig}
            />
            <Editor
              adjustQueryBeforeRequest={adjustQueryBeforeRequest}
              setVisualization={setVisualization}
              setVisualizationConfig={setVisualizationConfig}
              visualization={visualization || "Table"}
              visualizationConfig={visualizationConfig}
              onCreatePrefix={allowedToCreatePrefixOnData ? createPrefix : undefined}
              variableNamesFromConfig={variableNames}
              isDraft={isDraft}
              executeOnLoad={query.executionStats?.status !== "Timeout"}
            />
            <Prompt
              when={noVersionsAvailable ? false : isDraft}
              message={(targetLocation: Location) => {
                if ((targetLocation.state as LocationState)?.skipDraftPrompt) return true;
                //dont want the prompt to show up when only changing the location state
                //Otherwise, drawing the modal would trigger it
                if (location.pathname === targetLocation.pathname) return true;
                return "Your draft query has not been saved. Are you sure you want to continue?";
              }}
            />
          </div>
        ) : (
          <Alert severity="info">This query has no versions yet.</Alert>
        )}

        <Dependents queryId={query.id} />
      </FlexContainer>
    </SavedQueryProvider>
  );
};

const QueryWrapper: React.FC<IComponentProps> = (props) => {
  const { params } = props.match;
  const query = useSelector((state: GlobalState) =>
    getCurrentQuery(state, params.account, params.query, params.queryVersion),
  );
  const [resetCount, setResetCount] = React.useState(0);
  const { getItem, setItem, clearItem, getStorageTime } =
    useUncontrolledLocalStorage<PersistedDraftQuery>("savedQuery");
  const cacheKey = React.useRef([params.account, params.query] as string[]);

  const reset = React.useCallback(() => {
    setResetCount((resetCount) => resetCount + 1);
    setQueryFromLocalStorage(undefined);
  }, []);
  const reduxLocation = useSelector((state: GlobalState) => state.router.location);

  const restoreableDraft = React.useRef(getItem(cacheKey.current));
  const restoreableDraftTime = React.useRef(getStorageTime(cacheKey.current));
  const [draftRestorable, setDraftRestoreable] = React.useState(
    // We're being defensive here, making sure not to set draft restorable when it is identical with the current version. (#10144)
    !!restoreableDraft.current &&
      !!query &&
      !versionConfigIsEqual(restoreableDraft.current, {
        variables: query.variables,
        visualization: query.renderConfig?.output as any,
        visualizationConfig: query.renderConfig?.output as any,
      }),
  );
  const [queryFromLocalStorage, setQueryFromLocalStorage] = React.useState<QueryChanges & { query: string }>();

  const storeDraft = React.useCallback(
    (draft: QueryChanges & { query: string }) => {
      setItem(cacheKey.current, draft);
    },
    [setItem],
  );
  const removeDraft = React.useCallback(() => clearItem(cacheKey.current), [clearItem]);
  //Show a loading page when location.pathname is not yet equal to the pathname in the redux state.
  //We cannot use the value of asyncConnect.loading to do this check, because that would also trigger
  //when opening a modal, resulting in the query being unmounted and mounted.
  if (__CLIENT__ && props.location.pathname !== reduxLocation.pathname) {
    return <LoadingPage />;
  }

  const accountName = query?.owner.accountName;
  const queryName = query?.name;
  if (query?.version && query.version.toString() !== params.queryVersion && accountName && queryName) {
    return <Redirect to={getPath(accountName, queryName, query.version)} />;
  }

  if (query) {
    return (
      <EditorContextProvider
        key={resetCount + "" + props.location.pathname}
        initialQueryString={query.requestConfig?.payload.query || ""}
        initialQueryStringValue={queryFromLocalStorage?.query}
      >
        <Dialog open={draftRestorable} title="Restore draft">
          <DialogContent>
            <DialogContentText>
              Unsaved changes are detected
              {restoreableDraftTime.current && (
                <>
                  {" "}
                  from <HumanizedDate date={restoreableDraftTime.current} />
                </>
              )}
              .
            </DialogContentText>
            <div className="mt-5">
              <Button
                color="warning"
                type="submit"
                onClick={() => {
                  try {
                    restoreableDraft.current && setQueryFromLocalStorage(restoreableDraft.current);
                  } finally {
                    setDraftRestoreable(false);
                    setResetCount((resetCount) => resetCount + 1);
                  }
                }}
              >
                Restore
              </Button>
              <Button
                color="warning"
                variant="text"
                onClick={() => {
                  removeDraft();
                  setDraftRestoreable(false);
                }}
              >
                Discard
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        <Query
          {...props}
          reset={reset}
          query={query}
          recoveredQueryConfig={queryFromLocalStorage}
          storeDraft={storeDraft}
          clearDraft={removeDraft}
        />
      </EditorContextProvider>
    );
  }

  return (
    <ErrorPage
      statusCode={404}
      message={`Query '${params.query}' ${
        params.queryVersion ? `version '${params.queryVersion}'` : ""
      } does not exist`}
    />
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ store: { dispatch, getState }, match: { params } }) => {
      if (!accountIsCurrentAccount(getState(), params.account)) {
        return dispatch<any>(getAccountInfo(getState(), params.account)).catch(() => {});
      }
    },
  },
  {
    promise: async ({ store: { dispatch, getState }, match: { params } }) => {
      const versionIsSet = !!params.queryVersion;
      let version: number | undefined;
      if (versionIsSet) {
        version = Number(params.queryVersion);
        if (version === undefined || isNaN(version)) {
          return; //it's probably a string or something, ie invalid
        }
      }
      //check if we happen to have the query in the current query
      const currentQuery = getState().queries.current;
      if (
        currentQuery &&
        currentQuery.name === params.query &&
        currentQuery.owner.accountName === params.account &&
        (versionIsSet ? currentQuery.version === version : currentQuery.version === currentQuery.numberOfVersions)
      ) {
        //currentVersion is already the query we are looking for
        return;
      }

      //else fetch the query
      return dispatch<any>(getQuery(params.account, params.query, Number(params.queryVersion)));
    },
  },
])(QueryWrapper) as typeof Query;
