import {
  Divider,
  Drawer,
  Fade,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import getClassName from "classnames";
import { push } from "connected-react-router";
import { find } from "lodash-es";
import * as React from "react";
// @ts-ignore
import { Droppable } from "react-drag-and-drop";
import { useSelector } from "react-redux";
import { matchRoutes } from "react-router-config";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "#components/index.ts";
import SquareLogo from "#components/SquareLogo/index.tsx";
import type { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDataEditorEnabled from "#helpers/hooks/useDataEditorEnabled.ts";
import useDataModelEditorEnabled from "#helpers/hooks/useDataModelEditorEnabled.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useGraphqlEnabled } from "#helpers/hooks/useGraphQLEnabled.ts";
import useLocalStorage from "#helpers/hooks/useLocalStorage.tsx";
import { stringifyQuery } from "#helpers/utils.ts";
import { toggleSidePanelCollapsed, useCurrentAccount } from "#reducers/app.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import type { GlobalState } from "#reducers/index.ts";
import { getRunningServices } from "#reducers/services.ts";
import { addDatasetHistoryItem } from "#reducers/sessionHistory.ts";
import useIsEditorModeEnabled from "../../helpers/hooks/useEditMode.tsx";
import PanelGroup from "./PanelGroup/index.tsx";
import PanelItem from "./PanelItem/index.tsx";
import * as itemStyles from "./PanelItem/style.scss";
import * as styles from "./style.scss";

export interface Props {
  route: IComponentProps["route"];
}
const addTrailingSlash = (str: string) => {
  if (str[str.length - 1] === "/") return str;
  return str + "/";
};
const getQueryForResource = (page: "browser" | "table" | "schema" | "data-model" | "data-editor", resource: string) => {
  switch (page) {
    case "browser":
    case "data-editor":
    case "data-model":
      return { resource: resource };
    case "table":
      return { subject: resource };
    case "schema":
      return { f: resource };
  }
};
const DatasetPanel: React.FC<Props> = ({ route }) => {
  const acl = useAcl();
  const dispatch = useDispatch();
  const currentDs = useCurrentDataset();
  const currentAccount = useCurrentAccount();
  const {
    lastTableQuery,
    lastBrowserResource,
    lastDataEditorResource,
    lastDataModelResource,
    lastSearchQuery,
    loadingAssets,
    loadingGraphs,
    loadingServices,
    runningSearchServices,
  } = useSelector((state: GlobalState) => {
    const dataState = currentDs ? state.datasets[currentDs.id] : undefined;
    const services = currentDs && state.services[currentDs.id];
    const assetUploads = currentDs && state.assetUploads[currentDs.id];
    const jobUploads = currentDs && state.uploading[currentDs.id];

    return {
      lastTableQuery: dataState?.lastTableQuery,
      lastBrowserResource: dataState?.lastBrowserResource,
      lastDataEditorResource: dataState?.lastDataEditorResource,
      lastDataModelResource: dataState?.lastDataModelResource,
      lastSearchQuery: dataState?.lastSearchQuery,
      runningSearchServices: !!getRunningServices(services || [], "elasticSearch").length,
      loadingServices:
        !!services && !!services.find((s) => ["starting", "stopping", "removing", "updating"].includes(s.status)),
      loadingAssets: !!assetUploads && find(assetUploads, (a) => !a.message) !== undefined,
      loadingGraphs:
        (!!jobUploads && find(jobUploads, (upload) => !upload.error && !upload.cancelled) !== undefined) ||
        (!!dataState &&
          !!dataState.jobs.find((job) => ["downloading", "indexing", "pending"].indexOf(job.status) > -1)),
    };
  });
  const { appConfig } = useSelector((state: GlobalState) => {
    return {
      appConfig: state.config.clientConfig,
    };
  });

  const history = useHistory();

  const collapsed = useSelector((state: GlobalState) => state.app.sidePanelCollapsed ?? false);

  const location = useLocation<{ sidePanelOpen: boolean } | undefined>();
  const panelOpen = !!location.state?.sidePanelOpen;
  const mobilePanel = useMediaQuery("(max-width: 767px)");
  const panelCollapsed = collapsed && !mobilePanel;
  const assetsEnabled = useSelector((state: GlobalState) => state.config.values?.assetsEnabled);

  const logoBgType = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoBgType);
  const logoBgColor = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoBgColor);

  const editMode = useIsEditorModeEnabled();

  const graphqlEnabled = useGraphqlEnabled();
  const dataEditorEnabled = useDataEditorEnabled();
  const dataModelEditorEnabled = useDataModelEditorEnabled();

  const [lastDataSkosConceptSceme] = useLocalStorage(`triply::schemeselector::${currentDs?.id}`, "");

  //Moved this here from NavDataset, since this is a functional component and using componentDidMount did not work when navigating from dataset to dataset
  const currentDsId = currentDs?.id; //only trigger the useEffect when the dataset id changes
  React.useEffect(() => {
    if (currentDs) dispatch(addDatasetHistoryItem(currentDs));
  }, [currentDsId, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentAccount || !currentDs) return <></>;

  const matches = route?.routes ? matchRoutes(route.routes, location.pathname) : undefined;

  const currentPath = "/" + currentAccount.accountName + "/" + currentDs.name + "/";

  const linkIsMatched = (subPath: string, exact = true) => {
    if (exact) return !!matches && addTrailingSlash(matches[0].match.url) === addTrailingSlash(currentPath + subPath);
    return !!matches && matches[0].match.url.indexOf(currentPath + subPath) === 0;
  };

  const dropHandler = (data: any, path: "browser" | "table" | "schema" | "data-model" | "data-editor") => {
    if (data && data["text/plain"]) {
      dispatch(
        push({
          pathname: currentPath + path,
          search: stringifyQuery(getQueryForResource(path, data["text/plain"].trim())),
        }),
      );
    }
  };

  const mayEditDsMetadata = acl.check({
    action: "editDatasetMetadata",
    context: {
      roleInOwnerAccount: acl.getRoleInAccount(currentAccount),
      accessLevel: currentDs.accessLevel,
      newAccessLevel: undefined,
    },
  }).granted;

  const mayManageGraphs = acl.check({
    action: "manageGraphs",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  const mayManageServices = acl.check({
    action: "manageServices",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  const mayManageAssets = acl.check({
    action: "manageAssets",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  return (
    <Drawer
      variant={mobilePanel ? "temporary" : "permanent"}
      open={panelOpen}
      onClose={() => history.goBack()}
      className={getClassName("hideOnPrint")}
      PaperProps={{ elevation: 3 }}
    >
      <List
        component="nav"
        disablePadding
        aria-label="Dataset"
        className={getClassName(styles.nav, { [styles.collapsed]: panelCollapsed })}
      >
        {/*header*/}
        {appConfig && (
          <div className={styles.homeButton}>
            <ListItemButton component={Link} to="/" className={itemStyles.item}>
              <ListItemAvatar className={itemStyles.collapsedAvatar}>
                <SquareLogo
                  heightWidthPx={34}
                  logo={appConfig.branding.logo}
                  logoBgColor={logoBgColor}
                  logoBgType={logoBgType}
                />
              </ListItemAvatar>
              {!panelCollapsed && <ListItemText className={itemStyles.text}>{appConfig.branding.name}</ListItemText>}
            </ListItemButton>
          </div>
        )}
        <div>
          <PanelItem
            mobile={mobilePanel}
            active={linkIsMatched("")}
            panelCollapsed={panelCollapsed}
            icon="home"
            name={currentDs.displayName || currentDs.name}
            to={currentPath}
          />
        </div>
        {/*main*/}
        <Divider />
        <div className={styles.main}>
          <Droppable types={["text/plain"]} onDrop={(data: any) => dropHandler(data, "browser")}>
            <PanelItem
              mobile={mobilePanel}
              active={linkIsMatched("browser")}
              panelCollapsed={panelCollapsed}
              icon="id-card"
              name={editMode ? "Resources" : "Browser"}
              to={currentPath + `browser?${stringifyQuery({ resource: lastBrowserResource })}`}
            />
          </Droppable>
          <Droppable types={["text/plain"]} onDrop={(data: any) => dropHandler(data, "table")}>
            <PanelItem
              mobile={mobilePanel}
              active={linkIsMatched("table")}
              panelCollapsed={panelCollapsed}
              icon="th"
              name="Triples"
              to={currentPath + `table?${stringifyQuery(lastTableQuery)}`}
            />
          </Droppable>
          {dataEditorEnabled && mayManageGraphs && (
            <Droppable types={["text/plain"]} onDrop={(data: any) => dropHandler(data, "data-editor")}>
              <PanelItem
                active={linkIsMatched("data-editor") || linkIsMatched("data-editor/skos")}
                panelCollapsed={panelCollapsed}
                icon="pencil"
                name="Editor"
                to={
                  currentPath +
                  `data-editor/skos?${stringifyQuery({ resource: lastDataEditorResource, conceptScheme: lastDataSkosConceptSceme })}`
                }
              />
            </Droppable>
          )}
          {dataModelEditorEnabled && (
            <Droppable types={["text/plain"]} onDrop={(data: any) => dropHandler(data, "data-model")}>
              <PanelItem
                active={linkIsMatched("data-model")}
                panelCollapsed={panelCollapsed}
                icon="ruler-triangle"
                name="Data model"
                to={currentPath + `data-model?${stringifyQuery({ resource: lastDataModelResource })}`}
              />
            </Droppable>
          )}
          <PanelGroup
            active={linkIsMatched("insights") || linkIsMatched("schema")}
            panelCollapsed={panelCollapsed}
            icon="glasses"
            name="Insights"
          >
            <Droppable types={["text/plain"]} onDrop={(data: any) => dropHandler(data, "schema")}>
              <PanelItem
                mobile={mobilePanel}
                active={linkIsMatched("schema")}
                panelCollapsed={panelCollapsed}
                name="Schema"
                beta
                to={currentPath + "schema"}
              />
            </Droppable>
            <PanelItem
              mobile={mobilePanel}
              active={matches?.[1]?.match.url === currentPath + "insights/classFrequency"}
              panelCollapsed={panelCollapsed}
              name="Class frequency"
              to={currentPath + "insights/classFrequency"}
            />
            <PanelItem
              mobile={mobilePanel}
              active={matches?.[1]?.match.url === currentPath + "insights/classHierarchy"}
              panelCollapsed={panelCollapsed}
              name="Class hierarchy"
              to={currentPath + "insights/classHierarchy"}
            />
          </PanelGroup>
          <Divider />
          <PanelItem
            mobile={mobilePanel}
            active={linkIsMatched("sparql")}
            panelCollapsed={panelCollapsed}
            icon="bath"
            name="SPARQL"
            to={currentPath + "sparql"}
          />
          {graphqlEnabled && (
            <PanelItem
              mobile={mobilePanel}
              active={linkIsMatched("graphql")}
              panelCollapsed={panelCollapsed}
              icon="gramophone"
              name="GraphQL"
              to={currentPath + "graphql"}
            />
          )}
          {runningSearchServices && (
            <PanelItem
              mobile={mobilePanel}
              active={linkIsMatched("search", false) || linkIsMatched("elasticsearch", false)}
              panelCollapsed={panelCollapsed}
              icon="search"
              name="Elasticsearch"
              to={
                currentPath + "elasticsearch" + (!!lastSearchQuery ? `?${stringifyQuery({ q: lastSearchQuery })}` : "")
              }
            />
          )}
          <Divider />
          {(mayManageGraphs || currentDs.graphCount > 0) && (
            <PanelItem
              mobile={mobilePanel}
              active={linkIsMatched("graphs")}
              panelCollapsed={panelCollapsed}
              icon="share-alt"
              name="Graphs"
              number={currentDs.graphCount}
              loading={loadingGraphs}
              to={currentPath + "graphs"}
              warning={mayManageGraphs && currentDs.hasDataQualityIssues}
            />
          )}
          {(mayManageServices || currentDs.serviceCount > 0) && (
            <PanelItem
              mobile={mobilePanel}
              active={linkIsMatched("services")}
              panelCollapsed={panelCollapsed}
              icon="cloud"
              name="Services"
              number={currentDs.serviceCount + 1} // +1 for speedy
              loading={loadingServices}
              to={currentPath + "services"}
            />
          )}
          {assetsEnabled && (mayManageAssets || currentDs.assetCount > 0) && (
            <PanelItem
              mobile={mobilePanel}
              active={linkIsMatched("assets")}
              panelCollapsed={panelCollapsed}
              icon="paperclip"
              name="Assets"
              number={currentDs.assetCount}
              loading={loadingAssets}
              to={currentPath + "assets"}
            />
          )}
          <Divider />
          {mayEditDsMetadata && (
            <PanelItem
              mobile={mobilePanel}
              active={linkIsMatched("settings")}
              panelCollapsed={panelCollapsed}
              icon="cog"
              name="Settings"
              to={currentPath + "settings"}
            />
          )}
        </div>

        {/*footers*/}
        <ListItemButton
          className={styles.toggler}
          onClick={() => {
            const newValue = !panelCollapsed;
            dispatch(toggleSidePanelCollapsed(newValue));
          }}
          aria-label={panelCollapsed ? `Expand sidebar` : "shrink sidebar"}
          aria-pressed={panelCollapsed}
        >
          <FontAwesomeIcon icon={panelCollapsed ? "chevron-right" : "chevron-left"} />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default DatasetPanel;
