import { Alert, Autocomplete, Box, ListItem, Paper, Skeleton, TextField } from "@mui/material";
import { fromPairs, uniqBy } from "lodash-es";
import * as React from "react";
import { useHistory } from "react-router";
import { substringMatch } from "#components/Highlight/index.tsx";
import type { Item } from "#components/Tree/index.tsx";
import { getHierarchy } from "#components/Tree/index.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { Highlight, Tree } from "../../components";
import { useDatasetPrefixes } from "../../helpers/hooks/useDatasetPrefixes";
import { stringifyQuery } from "../../helpers/utils";
import { useCurrentAccount } from "../../reducers/app";
import AddClass from "./AddClass";
import * as styles from "./styles/index.scss";

const SubclassTree: React.FC<{}> = ({}) => {
  const history = useHistory();
  const currentClass = useCurrentResource();
  const prefixes = useDatasetPrefixes();
  const applyPrefixes = useApplyPrefixes();
  const currentAccount = useCurrentAccount();
  const acl = useAcl();

  const mayManageDataModel = acl.check({
    action: "manageDataModel",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  const { data, error, loading } = useSparql<Item[]>(
    `
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix owl: <http://www.w3.org/2002/07/owl#>
    prefix sh: <http://www.w3.org/ns/shacl#>
    prefix triply: <https://triplydb.com/Triply/function/>
    select ?id ?parent (triply:firstLabel(?id) as ?label) (sample(?o) as ?order) where {
      {
        {
          select distinct ?class where {
            {
              ?class a rdfs:Class
            } union {
              ?class a owl:Class
            } union {
              ?class rdfs:subClassOf|^rdfs:subClassOf []
            } union {
              [] sh:targetClass ?class
            }
            filter (!regex(str(?class), "\.well-known/genid"))
          }
          limit 1000
        }
        optional {
          ?class rdfs:subClassOf ?superclass
          filter(?superclass != ?class)
        }
        optional {
          ?class ^sh:targetClass/sh:order ?o
        }
        bind(coalesce(?superclass, <tree:root>) as ?parent)
        bind(?class as ?id)
      }
    }
    group by ?id ?parent
    `,
  );

  const [expandedKeys, setExpandedKeys] = React.useState<{ [key: string]: boolean }>({
    "tree:root>http://www.w3.org/2000/01/rdf-schema#Resource": true,
  });

  React.useEffect(() => {
    if (currentClass && data) {
      const { hierarchy } = getHierarchy(data, currentClass, prefixes);
      const toExpand = fromPairs(
        hierarchy
          ?.descendants()
          .filter((node) => node.data.iri === currentClass)
          .flatMap(
            (node) =>
              node
                .ancestors()
                .slice(1)
                .map((node) => [node.data.key, true]) || [],
          ),
      );
      setExpandedKeys((expandedKeys) => {
        return {
          ...expandedKeys,
          ...toExpand,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClass, data]);

  return (
    <Paper className="p-5">
      <Box className={styles.hierarchyContainer}>
        <div className="mb-5 flex center g-5">
          <Autocomplete
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.label || applyPrefixes(option.id)
            }
            getOptionKey={(option) => option.id}
            options={uniqBy(data || [], "id")}
            autoComplete
            filterSelectedOptions
            fullWidth
            noOptionsText="No results"
            onChange={(_event: any, newValue: Item | null, reason) => {
              if (reason === "selectOption") {
                history.push({
                  search: stringifyQuery({ resource: newValue?.id }),
                });
              }
            }}
            renderInput={(params: any) => {
              return <TextField variant="outlined" placeholder="Search" {...params} />;
            }}
            renderOption={(props, option, { inputValue }) => {
              return (
                <ListItem {...props}>
                  <Highlight
                    fullText={typeof option === "string" ? option : option.label || applyPrefixes(option.id)}
                    highlightedText={inputValue}
                    matcher={substringMatch}
                  />
                </ListItem>
              );
            }}
          />
          {mayManageDataModel && <AddClass />}
        </div>
        {data?.length === 0 && <Alert severity="info">No classes defined.</Alert>}
        <Tree
          className={styles.tree}
          items={data}
          currentItemIri={currentClass}
          expandedKeys={expandedKeys}
          onToggle={(e) => setExpandedKeys(e.value)}
          emptyMessage={
            loading ? (
              <Skeleton sx={{ width: "100%" }} />
            ) : error ? (
              <Alert severity="error">Could not load the class hierarchy.</Alert>
            ) : (
              " "
            )
          }
          onSelect={({ node }) => {
            history.push({ search: stringifyQuery({ resource: node.data.iri }) });
          }}
        />
      </Box>
    </Paper>
  );
};

export default SubclassTree;
