import { Checkbox } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import type { FacetItem } from "@triply/utils/Models";
import { FontAwesomeIcon } from "#components/index.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import * as styles from "./style.scss";

interface Props {
  data: FacetItem[];
  query: any;
  title?: string;
  id: string;
}

const Item: React.FC<{ d: FacetItem; query: any; id: string }> = ({ d, query, id }) => {
  return (
    <Link
      to={{ search: stringifyQuery({ ...query, [id]: d.active ? undefined : d.id }) }}
      title={`${d.label}${d.owner ? ` by ${d.owner}` : ""}`}
      className={getClassName(styles.facetRow, "flex center rounding noLinkDecoration", {
        [styles.active]: d.active,
        [styles.inactive]: d.count === -1,
      })}
    >
      {["sparql", "search"].includes(id) && <Checkbox checked={d.active} color="default" className={styles.checkbox} />}
      <div className="grow ellipsis">{d.label}</div>
      {(d.active && <FontAwesomeIcon icon="times" />) ||
        (d.count >= 0 && <div className={styles.smaller}>{d.count}</div>) || <div className={styles.smaller}>-</div>}
    </Link>
  );
};

const Facet: React.FC<Props> = ({ data, query, title, id }) => {
  return (
    <div>
      {title && <h5 className={getClassName("mb-2", styles.title)}>{title}</h5>}
      <div className={styles.facetRows}>
        {data.map((d) => {
          return <Item key={d.id.toString()} d={d} id={id} query={query} />;
        })}
      </div>
      {["owner", "dataset", "query", "topic", "visualization"].includes(id) && (
        <>
          {data.length === 3 && !(`${id}All` in query) && (
            <div className={styles.showAll}>
              <Link
                to={{ state: { preserveScrollPosition: true }, search: stringifyQuery({ ...query, [`${id}All`]: "" }) }}
              >
                Show all
              </Link>
            </div>
          )}
          {data.length > 3 && `${id}All` in query && (
            <div className={styles.showAll}>
              <Link
                to={{
                  state: { preserveScrollPosition: true },
                  search: stringifyQuery({ ...query, [`${id}All`]: undefined }),
                }}
              >
                Show less
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Facet;
