import * as React from "react";
import type { AccessLevel, AccountType } from "@triply/utils/Models";
import { FontAwesomeIcon } from "#components/index.ts";
import type AccessLevels from "./index.tsx";
import { getAccessLevelText } from "./index.tsx";

namespace AccessLevelIcon {
  export interface Props {
    level: AccessLevel;
    type: AccessLevels.Type;
    style?: React.CSSProperties;
    className?: string;
    addTitleForAccountType?: AccountType;
    size?: FontAwesomeIcon.Props["size"];
  }
}

const iconMapping: { [key in AccessLevel]: FontAwesomeIcon.Props["icon"] } = {
  private: "lock",
  internal: "shield",
  public: "globe",
};

const AccessLevelIcon: React.FC<AccessLevelIcon.Props> = (props) => {
  const icon = (
    <FontAwesomeIcon
      size={props.size}
      icon={iconMapping[props.level]}
      className={(!props.addTitleForAccountType && props.className) || undefined}
      style={props.style}
    />
  );
  if (props.addTitleForAccountType) {
    return (
      <div
        title={getAccessLevelText(props.level, props.type, true, props.addTitleForAccountType)}
        className={props.className}
      >
        {icon}
      </div>
    );
  }
  return icon;
};

export default AccessLevelIcon;
