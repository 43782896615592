// @ts-ignore Typescript issue...
import de9im from "de9im";
import type { Geometry } from "@triplydb/recognized-datatypes/wkt";
import { GeoFunctionError, prepareForDe9im } from "./helpers.ts";

/**
 * Implementation of GeoSPARQL 1.0 Within function.
 * See here details https://defs.opengis.net/vocprez/object?uri=http%3A//www.opengis.net/def/function/geosparql/sfWithin .
 * Checks if geometry A is fully inside geometry B, by ensuring A's edge or interior doesn't cross B's exterior
 *
 * @param  {Geometry|Feature<LineString|MultiLineString|Polygon|MultiPolygon>} feature1 input
 * @param  {Geometry|Feature<LineString|MultiLineString|Polygon|MultiPolygon>} feature2 input
 *
 * @returns true/false
 */

export function geoWithin(feature1: Geometry, feature2: Geometry): boolean {
  try {
    return de9im.within(prepareForDe9im(feature1), prepareForDe9im(feature2));
  } catch (e) {
    throw new GeoFunctionError((e as Error).message, { cause: e });
  }
}
