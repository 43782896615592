import { Slider } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useHistory } from "react-router";
import type { FilterItem, OrganizationFacets } from "@triply/utils/Models";
import { stringifyQuery } from "../../helpers/utils.ts";
import * as styles from "./style.scss";

interface Props {
  filters: OrganizationFacets["filters"];
  query: any;
}

const NumberFilter: React.FC<{ filter: FilterItem; query: any; title: string; field: string }> = ({
  filter,
  query,
  title,
  field,
}) => {
  const history = useHistory();

  const minValue = Number(query[`${field}Min`]);
  const maxValue = Number(query[`${field}Max`]);

  const [value, setValue] = React.useState([
    !isNaN(minValue) ? minValue : filter.min,
    !isNaN(maxValue) ? maxValue : filter.max,
  ]);

  React.useEffect(() => {
    setValue([!isNaN(minValue) ? minValue : filter.min, !isNaN(maxValue) ? maxValue : filter.max]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minValue, maxValue]);

  return (
    <div>
      <h5 id={`${field}-slider`} className={getClassName("mb-2", styles.title)}>
        {title}
      </h5>
      <div className="px-3">
        <Slider
          size="small"
          value={value}
          getAriaValueText={(v) => "" + v}
          aria-labelledby={`${field}-slider`}
          min={filter.min}
          max={filter.max}
          marks={filter.max <= 30}
          valueLabelDisplay="auto"
          onChange={(_, values) => {
            if (typeof values === "number") return;
            setValue(values);
          }}
          onChangeCommitted={(_, values) => {
            if (typeof values === "number") return;
            const [min, max] = values;
            history.push({
              search: stringifyQuery({
                ...query,
                [`${field}Min`]: min > filter.min ? min : undefined,
                [`${field}Max`]: max < filter.max ? max : undefined,
              }),
            });
          }}
        />
      </div>
    </div>
  );
};

const Filters: React.FC<Props> = ({ filters, query }) => {
  return (
    <>
      {"members" in filters && (
        <NumberFilter filter={filters.members} query={query} title="Number of members" field="members" />
      )}
      {"datasets" in filters && (
        <NumberFilter filter={filters.datasets} query={query} title="Number of datasets" field="datasets" />
      )}
      {"stories" in filters && (
        <NumberFilter filter={filters.stories} query={query} title="Number of stories" field="stories" />
      )}
      {"queries" in filters && (
        <NumberFilter filter={filters.queries} query={query} title="Number of queries" field="queries" />
      )}
    </>
  );
};

export default Filters;
