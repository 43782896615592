import type * as rdfjs from "@rdfjs/types";
import type { StreamWriter as N3StreamWriterInterface, Writer as N3WriterInterface } from "n3";
import type * as stream from "stream";
// @ts-ignore: the console validation script complains wrt missing types
import N3StreamWriter from "./forked/N3StreamWriter.js";
// @ts-ignore: the console validation script complains wrt missing types
import N3Writer from "./forked/N3Writer.js";

export type Format = "turtle" | "trig" | "n-triples" | "n-quads";

export type Prefixes = { [prefix: string]: string };

type N3Prefixes = { [name: string]: rdfjs.NamedNode | string };

/**
 *
 * @param format Be default, we'll keep the graph (this is similar to how n3 behaves)
 */
function getHandleGraphNameOpt(format?: Format) {
  return format === "n-triples" || format === "turtle" ? ("drop" as const) : ("keep" as const);
}

export function serializeToString(quads: rdfjs.Quad[], format?: Format): Promise<string>;
export function serializeToString(
  quads: rdfjs.Quad[],
  opts?: { format?: Format; prefixes?: Prefixes },
): Promise<string>;
export function serializeToString(
  quads: rdfjs.Quad[],
  optsOrFormat?: Format | { format?: Format; prefixes?: Prefixes },
): Promise<string> {
  let opts: WriterOptions | undefined;
  switch (typeof optsOrFormat) {
    case "string":
      opts = { format: optsOrFormat };
    case "undefined":
      break;
    case "object":
      opts = {};
      if (optsOrFormat.format) opts.format = optsOrFormat.format;
      if (optsOrFormat.prefixes) {
        opts.prefixes = onlyUsedPrefixes(quads, optsOrFormat.prefixes);
      }
  }

  return new Promise<string>((resolve, reject) => {
    const writer = getWriter(opts);
    writer.addQuads(quads);
    writer.end(function (error: Error, result: string) {
      if (error) return reject(error);
      resolve(result);
    });
  });
}

function onlyUsedPrefixes(quads: rdfjs.Quad[], prefixes: Prefixes) {
  const allIrisInQuads = new Set(
    quads
      .flatMap(({ subject, predicate, object, graph }) =>
        object.termType === "Literal" && !object.language
          ? [subject, predicate, object.datatype, graph]
          : [subject, predicate, object, graph],
      )
      .filter((t) => t.termType === "NamedNode")
      .map((t) => t.value),
  );
  const longestPrefixFirst = Object.entries(prefixes).sort((a, b) => b[1].length - a[1].length);
  const usedPrefixes: Prefixes = {};
  for (const quadIri of allIrisInQuads) {
    for (const [prefixLabel, prefixIri] of longestPrefixFirst) {
      if (quadIri.startsWith(prefixIri)) {
        usedPrefixes[prefixLabel] = prefixIri;
        break;
      }
    }
  }
  return usedPrefixes;
}

export function serializeToNQuadsSync(quads: rdfjs.Quad[]): string {
  const writer: N3WriterInterface = new N3Writer({ format: "n-quads", handleGraphNames: "keep" }) as any;
  return writer.quadsToString(quads);
}

export interface StreamWriterOptions {
  prefixes?: N3Prefixes;
  format?: Format;
}
export function getStreamWriter(opts?: StreamWriterOptions) {
  return new N3StreamWriter({
    handleGraphNames: getHandleGraphNameOpt(opts?.format),
    ...opts,
  }) as any as N3StreamWriterInterface;
}
export interface WriterOptions {
  end?: boolean | undefined;
  prefixes?: N3Prefixes;
  format?: Format;
}

function isFileDescriptorOrStream(fd: any): fd is stream.Stream | number {
  return (
    fd !== undefined &&
    fd !== null &&
    // A file descriptor is a number
    (typeof fd === "number" || typeof fd.pipe === "function")
  );
}

export function getWriter(fileDescriptorOrStream: stream.Stream | number, opts?: WriterOptions): N3WriterInterface;
export function getWriter(opts?: WriterOptions): N3WriterInterface;
export function getWriter(fdOrOpts?: WriterOptions | stream.Stream | number, opts?: WriterOptions): N3WriterInterface {
  if (isFileDescriptorOrStream(fdOrOpts)) {
    return new N3Writer(fdOrOpts, {
      handleGraphNames: getHandleGraphNameOpt(opts?.format),
      ...opts,
    }) as any as N3WriterInterface;
  }
  return new N3Writer({
    handleGraphNames: getHandleGraphNameOpt(fdOrOpts?.format),
    ...fdOrOpts,
  }) as any as N3WriterInterface;
}

const writer = new N3Writer({ format: "n-triples", handleGraphNames: "keep" }) as any;
export function serializeLiteralToNtriples(literal: rdfjs.Literal): string {
  return writer._encodeLiteral(literal);
}
