export class BaseParseError extends Error {
  public lexicalValue?: string;

  constructor(opts: { lexicalValue?: string; message: string; cause?: any }) {
    super(opts.message, { cause: opts.cause });
    this.lexicalValue = opts.lexicalValue;
  }
}

export class StandardParseError extends BaseParseError {
  private _datatype?: string;
  public offset?: number;
  private setMessage() {
    this.message = `Unable to parse value '${this.lexicalValue}'`;
    if (this.offset !== undefined) {
      this.message += ` at character ${this.offset}`;
    }
    if (this.cause instanceof RangeError) {
      this.message += `: ${this.cause.message}`;
    }
    if (this.message.at(-1) !== ".") this.message += ".";
  }
  constructor(opts: { lexicalValue: string; offset?: number; cause?: any }) {
    super({ ...opts, message: `Unable to parse value '${opts.lexicalValue}'.`, cause: opts.cause });
    this.offset = opts.offset;
    this.setMessage();
  }
  public set datatype(datatype: string | undefined) {
    this._datatype = datatype;
    this.setMessage();
  }
  public get datatype() {
    return this._datatype;
  }
}

export class AmbiguousParseError extends BaseParseError {
  public possibleValues: any[];
  private _datatype?: string;
  constructor(opts: { lexicalValue: string; possibleValues: any[] }) {
    super({ lexicalValue: opts.lexicalValue, message: `Unable to unambiguously parse value '${opts.lexicalValue}'.` });
    this.possibleValues = opts.possibleValues;
  }
  public set datatype(datatype: string | undefined) {
    this.message =
      `Unable to unambiguously parse value '${this.lexicalValue}' ` +
      `according to datatype IRI '${datatype}'` +
      ".  The following parses are possible:\n" +
      this.possibleValues.reduce((x: string, y: string) => `${x}  - ${y}\n`, "");
    this._datatype = datatype;
  }
  public get datatype() {
    return this._datatype;
  }
}

export class ValueRangeError extends BaseParseError {
  private _datatype?: string;
  private value: unknown;
  constructor(opts: { value: unknown }) {
    super({
      message: `The parsed value '${JSON.stringify(opts.value)}' does not belong to the value space.`,
    });
    this.value = opts.value;
  }
  public set datatype(datatype: string | undefined) {
    this.message = `The parsed value '${JSON.stringify(
      this.value,
    )}' does not belong to the value space of '${datatype}'.`;
    this._datatype = datatype;
  }
  public get datatype() {
    return this._datatype;
  }
}
