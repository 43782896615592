import {
  Alert,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import IdeToolTipButton from "#components/IdeTooltipButton/index.tsx";
import { FontAwesomeIcon } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import AddProperty from "./AddProperty";
import AddSparqlBasedConstraint from "./AddSparqlBasedConstraint";
import EditProperty from "./EditProperty";
import EditSparqlBasedConstraint from "./EditSparqlBasedConstraint";
import RemoveProperty from "./RemoveProperty";
import * as styles from "./styles/index.scss";

export const factory = factories.compliant;

const NO_GROUP = "none";

export interface PropertyInfo {
  propertyShape: string;
  propertyShapeLabel?: string;
  path: string;
  propertyShapeInherited: boolean;
  propertyClass?: string;
  propertyClassLabel?: string;
  propertyRange?: string;
  propertyRangeLabel?: string;
  propertyShapeDataType?: string;
  propertyShapeDescription?: string;
  propertyShapeDefaultValue?: string;
  propertyMin?: number;
  propertyMax?: number;
  order?: number;
}

const classQuery = (classIri: string) => `
prefix sh: <http://www.w3.org/ns/shacl#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix triply: <https://triplydb.com/Triply/function/>

select
  ?shapeIri
  ?groups_groupIri
  ?groups_groupName
  ?groups_properties_propertyShape
  (sample(?groups_properties_propertyShapeLabel_u) as ?groups_properties_propertyShapeLabel)
  ?groups_properties_path
  ?groups_groupOrder
  ?groups_properties_propertyShapeInherited
  ?groups_properties_propertyClass
  ?groups_properties_propertyClassLabel
  ?groups_properties_propertyRange
  ?groups_properties_propertyRangeLabel
  ?groups_properties_propertyShapeDataType
  (sample(?groups_properties_propertyShapeDescription_u) as ?groups_properties_propertyShapeDescription)
  ?groups_properties_propertyShapeDefaultValue
  ?groups_properties_propertyMin
  ?groups_properties_propertyMax
  ?groups_properties_order
where {
  bind(${termToString(factory.namedNode(classIri))} as ?currentClass)
  ?currentClass rdfs:subClassOf*/^sh:targetClass ?nodeShape .
  optional {
    ?shapeIri sh:targetClass ?currentClass.
  }
  optional {
    ?nodeShape sh:property ?groups_properties_propertyShape .
    ?groups_properties_propertyShape sh:path ?groups_properties_path .
    bind((!bound(?shapeIri) || (?nodeShape != ?shapeIri)) as ?groups_properties_propertyShapeInherited)
    optional {
      ?nodeShape sh:targetClass ?groups_properties_propertyClass
      bind(triply:firstLabel(?groups_properties_propertyClass) as ?groups_properties_propertyClassLabel)
    }
    optional {
      ?groups_properties_propertyShape sh:class ?groups_properties_propertyRange
      bind(triply:firstLabel(?groups_properties_propertyRange) as ?groups_properties_propertyRangeLabel)
    }
    optional {
      ?groups_properties_propertyShape sh:datatype ?groups_properties_propertyShapeDataType
    }
    optional {
      ?groups_properties_propertyShape sh:description ?groups_properties_propertyShapeDescription_u
    }
    optional {
      ?groups_properties_propertyShape sh:name ?groups_properties_propertyShapeLabel_sh.
    }
    optional {
      ?groups_properties_propertyShape sh:defaultValue ?groups_properties_propertyShapeDefaultValue
    }
    bind(triply:firstLabel(?groups_properties_path) as ?groups_properties_propertyRangeLabel_rdfs)
    optional {
      ?groups_properties_propertyShape sh:order ?groups_properties_order .
    }
    bind(coalesce(?groups_properties_propertyShapeLabel_sh,?groups_properties_propertyRangeLabel_rdfs) as ?groups_properties_propertyShapeLabel_u)
    optional {
      ?groups_properties_propertyShape sh:minCount ?groups_properties_propertyMin.
    }
    optional {
      ?groups_properties_propertyShape sh:maxCount ?groups_properties_propertyMax.
    }
    optional {
      ?groups_properties_propertyShape sh:group ?groupIri .
      bind(triply:firstLabel(?groupIri) as ?groups_groupName)
      optional {
        ?groupIri  sh:order ?groups_groupOrder .
      }
    }
  bind(coalesce(?groupIri, "${NO_GROUP}") as ?groups_groupIri)
  }
} group by
  ?shapeIri
  ?groups_groupIri
  ?groups_properties_propertyShape
  ?groups_properties_path
  ?groups_groupOrder
  ?groups_groupName
  ?groups_properties_propertyShapeInherited
  ?groups_properties_propertyClass
  ?groups_properties_propertyClassLabel
  ?groups_properties_propertyRange
  ?groups_properties_propertyRangeLabel
  ?groups_properties_propertyShapeDataType
  ?groups_properties_propertyShapeDefaultValue
  ?groups_properties_propertyMin
  ?groups_properties_propertyMax
  ?groups_properties_order
order by (!bound(?groups_groupOrder)) coalesce(?groups_groupOrder, -1000000) (!bound(?groups_properties_order)) ?groups_properties_order
`;

export type SparqlBasedConstraint = {
  nodeShape: string;
  inherited: "true" | "false";
  query: string;
  message?: string;
  shSparql: string;
  datatype: undefined;
  classIri: string;
  classLabel?: string;
};

const ClassInfo: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();
  const currentAccount = useCurrentAccount();
  const acl = useAcl();
  const mayManageDataModel = acl.check({
    action: "manageDataModel",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  const {
    data: classInfo,
    loading,
    error,
  } = useSparql<{
    groups: {
      groupIri: string;
      groupName?: string;
      groupOrder?: number;
      properties: PropertyInfo[];
    }[];
    shapeIri?: string;
  }>(classQuery(currentClass), {
    singularizeVariables: { "": true },
  });

  const {
    data: sparqlBasedConstraints,
    error: sparqlBasedConstraintsError,
    loading: loadingConstraints,
  } = useSparql<SparqlBasedConstraint[]>(
    currentClass &&
      `
      prefix owl: <http://www.w3.org/2002/07/owl#>
      prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
      prefix sh: <http://www.w3.org/ns/shacl#>
      prefix triply: <https://triplydb.com/Triply/function/>

      select
        ?nodeShape
        ?inherited
        ?query
        ?shSparql
        ?message
        ?classIri
        ?classLabel
      where {
        bind(${termToString(factory.namedNode(currentClass))} as ?currentClass)
        ?currentClass rdfs:subClassOf* ?superClass .
        ?nodeShape sh:targetClass ?superClass .

        bind((?currentClass != ?superClass) as ?inherited)
        optional {
          ?nodeShape sh:targetClass ?classIri
            optional {
            bind(triply:firstLabel(?classIri) as ?classLabel)
          }
        }


        ?nodeShape sh:sparql ?shSparql .
        ?shSparql sh:select ?query .

        optional {
          ?shSparql sh:message ?message
        }

      }

      order by
      ?message
    `,
  );

  const applyPrefixes = useApplyPrefixes();

  if (error || sparqlBasedConstraintsError) return <Alert severity="warning">Class info could not be loaded.</Alert>;

  if (loading || loadingConstraints) return <Skeleton />;
  if (!currentClass) return null;

  return (
    <>
      <Typography variant="h6" component="h2">
        Instance properties
      </Typography>
      <TableContainer className={styles.tableContainer}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Datatype/Range</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Default value</TableCell>
              <TableCell>Min relations</TableCell>
              <TableCell>Max relations</TableCell>
              <TableCell>Order</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classInfo?.groups?.map((group) => {
              return (
                <React.Fragment key={group.groupIri}>
                  {group.groupIri !== NO_GROUP && (
                    <TableRow className={styles.grouped}>
                      <TableCell colSpan={7}>{group.groupName}</TableCell>
                      <TableCell>{group.groupOrder}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                  {group.properties?.map((property) => {
                    return (
                      <TableRow key={property.propertyShape}>
                        <TableCell className={group.groupIri !== NO_GROUP ? styles.grouped : undefined}></TableCell>
                        <TableCell>
                          <Link
                            title={applyPrefixes(property.path)}
                            onDragStart={(e) => e.dataTransfer.setData("text/plain", property.propertyShape)}
                            to={{
                              pathname: "./browser",
                              search: stringifyQuery({ resource: property.propertyShape }),
                            }}
                          >
                            {property.propertyShapeLabel || applyPrefixes(property.propertyShape)}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {property.propertyShapeDataType ? (
                            <Link
                              title={applyPrefixes(property.propertyShapeDataType)}
                              onDragStart={(e) => e.dataTransfer.setData("text/plain", property.propertyShapeDataType!)}
                              to={{
                                pathname: "./browser",
                                search: stringifyQuery({ resource: property.propertyShapeDataType }),
                              }}
                            >
                              {applyPrefixes(property.propertyShapeDataType)}
                            </Link>
                          ) : (
                            <Link
                              title={applyPrefixes(property.propertyRange)}
                              onDragStart={(e) => e.dataTransfer.setData("text/plain", property.propertyRange!)}
                              to={{
                                search: stringifyQuery({ resource: property.propertyRange }),
                              }}
                            >
                              {property.propertyRangeLabel || applyPrefixes(property.propertyRange)}
                            </Link>
                          )}
                        </TableCell>
                        <TableCell>{property.propertyShapeDescription}</TableCell>
                        <TableCell>
                          {property.propertyRange ? (
                            <Link
                              to={{
                                pathname: "./browser",
                                search: stringifyQuery({ resource: property.propertyShapeDefaultValue }),
                              }}
                              onDragStart={(e) =>
                                e.dataTransfer.setData("text/plain", property.propertyShapeDefaultValue!)
                              }
                            >
                              {applyPrefixes(property.propertyShapeDefaultValue)}
                            </Link>
                          ) : (
                            property.propertyShapeDefaultValue
                          )}
                        </TableCell>
                        <TableCell>{property.propertyMin}</TableCell>
                        <TableCell>{property.propertyMax}</TableCell>
                        <TableCell>{property.order}</TableCell>
                        <TableCell>
                          {property.propertyShapeInherited === true && (
                            <IconButton
                              component={Link}
                              size="small"
                              to={(location) => {
                                const search = parseSearchString(location.search);
                                search.resource = property.propertyClass;
                                return { search: stringifyQuery(search) };
                              }}
                              title={`Go to the property definition in "${property.propertyClassLabel || applyPrefixes(property.propertyClass)}"`}
                              aria-label={`Go to the property definition in "${property.propertyClassLabel || applyPrefixes(property.propertyClass)}"`}
                            >
                              <FontAwesomeIcon icon="folder-magnifying-glass" fixedWidth />
                            </IconButton>
                          )}
                          {property.propertyShapeInherited === false && mayManageDataModel && (
                            <div className="flex">
                              <EditProperty
                                propertyInfo={property}
                                group={group.groupIri !== NO_GROUP ? group : undefined}
                              />
                              <RemoveProperty
                                label={property.propertyShapeLabel || property.propertyShape}
                                subjectIri={property.propertyShape}
                              />
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div>{mayManageDataModel && classInfo?.shapeIri && <AddProperty classShapeIri={classInfo.shapeIri} />}</div>

      <Typography variant="h6" component="h3">
        Constraints
      </Typography>
      <TableContainer className={styles.propertyTableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Query</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sparqlBasedConstraints?.map((sparqlBasedConstraint) => (
              <TableRow key={sparqlBasedConstraint.shSparql} className={styles.propertyRow}>
                <TableCell title={applyPrefixes(sparqlBasedConstraint.shSparql)}>
                  {sparqlBasedConstraint.message || applyPrefixes(sparqlBasedConstraint.shSparql)}
                </TableCell>
                <TableCell>
                  <IdeToolTipButton queryString={sparqlBasedConstraint.query ?? ""}></IdeToolTipButton>
                </TableCell>
                <TableCell>
                  {sparqlBasedConstraint.inherited === "true" && (
                    <IconButton
                      component={Link}
                      size="small"
                      to={(location) => {
                        const search = parseSearchString(location.search);
                        search.resource = sparqlBasedConstraint.classIri;
                        return { search: stringifyQuery(search) };
                      }}
                      title={`Go to the constraint definition in "${sparqlBasedConstraint.classLabel || applyPrefixes(sparqlBasedConstraint.classIri)}"`}
                      aria-label={`Go to the constraint definition in "${sparqlBasedConstraint.classLabel || applyPrefixes(sparqlBasedConstraint.classIri)}"`}
                    >
                      <FontAwesomeIcon icon="folder-magnifying-glass" fixedWidth />
                    </IconButton>
                  )}

                  {sparqlBasedConstraint.inherited === "false" && mayManageDataModel && (
                    <>
                      <EditSparqlBasedConstraint
                        shapeIri={sparqlBasedConstraint.shSparql}
                        message={sparqlBasedConstraint.message || ""}
                        query={sparqlBasedConstraint.query}
                      />
                      <RemoveProperty
                        subjectIri={sparqlBasedConstraint.shSparql}
                        label={sparqlBasedConstraint.message ?? sparqlBasedConstraint.shSparql}
                      />
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        {mayManageDataModel && classInfo?.shapeIri && <AddSparqlBasedConstraint classShapeIri={classInfo.shapeIri} />}
      </div>
    </>
  );
};

export default ClassInfo;
