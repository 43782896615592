import * as React from "react";
import type { Models } from "@triply/utils";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";

interface QueryContextProps {
  initialQuery: Models.Query;
}

const QueryContext = React.createContext<QueryContextProps>({
  initialQuery: undefined as any,
});

export const SavedQueryProvider: React.FC<{
  children: React.ReactElement<any>;
  initialQuery: Models.Query;
}> = ({ children, initialQuery }) => {
  return (
    <QueryContext
      value={{
        initialQuery: initialQuery,
      }}
    >
      {children}
    </QueryContext>
  );
};

export const useSavedQuery = () => {
  const constructUrlToApi = useConstructUrlToApi();
  const { initialQuery } = React.useContext(QueryContext);
  const datasetPath = initialQuery.dataset
    ? `${initialQuery.dataset.owner.accountName}/${initialQuery.dataset.name}`
    : undefined;
  const termsUrl =
    initialQuery?.dataset &&
    constructUrlToApi({
      pathname: `/datasets/${datasetPath}/terms`,
    });

  return {
    query: initialQuery,
    datasetPath: datasetPath,
    termsUrl,
    datasetPrefixes: initialQuery.dataset?.prefixes,
    endpoint: initialQuery.service,
    queryName: initialQuery.name,
  };
};
