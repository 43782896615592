import { isEmpty, pickBy } from "lodash-es";
import type { Options as MemoizeeOptions } from "memoizee";
import memoizee from "memoizee";
import { parse } from "qs";

export { stringifyQuery } from "@core/utils";

export const UNSUPPORTED_QUERY_PARAM_MESSAGE = "Unsupported query parameter type";

export type MemoizeOpts<F extends (...args: any[]) => any> = MemoizeeOptions<F>;
export function memoize<F extends (...args: any[]) => any>(opts: MemoizeOpts<F> = {}) {
  return function (
    _target: Object, //context, i.e. the class
    propertyKey: string, //name of the function
    descriptor: PropertyDescriptor,
  ) {
    /**
     * ######################################################################
     * To disable memoization (for e.g. debugging), turn on the code below
     * ######################################################################
     */
    // if (1==1) {
    //   console.warn('Memoization disabled for ' + propertyKey)
    //   return descriptor;
    // }
    const { value } = descriptor;
    return {
      value: function (this: any) {
        if (!opts.max && __SERVER__) {
          //don't want to keep caching stuff on the server
          opts.max = 10;
        }
        descriptor.value = memoizee(value, opts);
        Object.defineProperty(this, propertyKey, descriptor);
        return this[propertyKey](...arguments);
      },
    };
  };
}

export function parseSearchString(searchString: string) {
  const parsedQuery = parse(searchString, { ignoreQueryPrefix: true });
  for (const value of Object.values(parsedQuery)) {
    if (typeof value !== "string") throw new Error(UNSUPPORTED_QUERY_PARAM_MESSAGE);
  }
  return parsedQuery;
}

export const ensureTrailingDot = (msg: string) => {
  if (msg && msg.trim) {
    msg = msg.trim();
    if (msg.length && !msg[msg.length - 1].match(/[\.\!\?]/)) {
      msg += ".";
    }
  }
  return msg;
};

export function filterEmptyVals(jsonLd: {}) {
  //Used for filtering the jsonld, as we dont want key/value pairs where the value is an empty array (this'll end up as 0 statements anyway)
  //or where it's undefined (that'll probably end up as a literal `"undefined"`)
  //Empty strings are ok though
  return pickBy(jsonLd, (val) => typeof val === "string" || typeof val === "number" || !isEmpty(val));
}
