import getClassName from "classnames";
import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { Button } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import type { User } from "#reducers/accountCollection.ts";
import { updateProfile } from "#reducers/accounts.ts";
import type { GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

const Consent: React.FC<{ currentAccount: User; editingSelf: boolean }> = ({ currentAccount, editingSelf }) => {
  const dispatch = useDispatch();
  const privacyPolicyUrl = useSelector((state: GlobalState) => state.config.clientConfig?.legal?.privacyPolicyUrl);
  const generalTermsUrl = useSelector((state: GlobalState) => state.config.clientConfig?.legal?.generalTermsUrl);
  const latestRequiredConsentUpdate = useSelector(
    (state: GlobalState) => state.config.clientConfig?.legal?.latestRequiredConsentUpdate,
  );

  return (
    <div className={getClassName("whiteSink", styles.consent)}>
      <h3>General terms and privacy policy update</h3>
      <div>
        Our{" "}
        <a href={generalTermsUrl} target="_blank">
          general terms
        </a>{" "}
        and{" "}
        <a href={privacyPolicyUrl} target="_blank">
          privacy policy
        </a>{" "}
        have been updated
        {latestRequiredConsentUpdate
          ? ` and will take effect on ${moment(latestRequiredConsentUpdate).format("LL")}`
          : ""}
        . {editingSelf ? "We need your" : "This user needs to"} consent to the changes in order{" "}
        {editingSelf ? "for you " : ""}to continue using TriplyDB
        {latestRequiredConsentUpdate ? ` after ${moment(latestRequiredConsentUpdate).format("LL")}` : ""}.
      </div>
      <div>
        <Button onClick={() => dispatch<typeof updateProfile>(updateProfile(currentAccount, { legalConsent: true }))}>
          {editingSelf
            ? "I agree with the general terms and privacy policy"
            : `I confirm that ${currentAccount.accountName} has explicitly agreed with the general terms and privacy policy`}
        </Button>
      </div>
    </div>
  );
};

export default Consent;
