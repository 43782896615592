import { Alert } from "@mui/material";
import * as React from "react";
import { useHistory } from "react-router";
import { LoadingButton } from "#components/index.ts";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { cancelTusUploadsOfUser } from "#helpers/tusUploadManagement.ts";
import { ensureTrailingDot } from "#helpers/utils.ts";
import type { Account, User } from "#reducers/accountCollection.ts";
import { deleteAccount } from "#reducers/accounts.ts";

const DeleteAccount: React.FC<{ currentAccount: Account; loggedInUser?: User; editingSelf: boolean }> = ({
  currentAccount,
  loggedInUser,
  editingSelf,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirmation();
  const [deletingAccount, setDeletingAccount] = React.useState(false);
  const [deleteAccountError, setDeleteAccountError] = React.useState<string>();

  const handleDelete = () => {
    cancelTusUploadsOfUser(currentAccount.uid);
    setDeletingAccount(true);
    setDeleteAccountError(undefined);
    dispatch<typeof deleteAccount>(deleteAccount(currentAccount))
      .then(() => {
        setDeletingAccount(false);
        if (__CLIENT__ && currentAccount && loggedInUser) {
          const targetIsOrg = currentAccount.type === "org";
          const iAmOrganizationOwner =
            currentAccount.type === "org" &&
            currentAccount?.members &&
            currentAccount.members
              .filter((m) => m.role === "owner")
              .map((o) => o.user.accountName)
              .indexOf(loggedInUser.accountName) > -1;

          if (editingSelf) {
            // When deleting self, redirect to root
            window.location.replace("/");
          } else if (targetIsOrg && iAmOrganizationOwner) {
            // When deleting org you're the owner off redirect the org list of the user
            history.replace(`/${loggedInUser.accountName}/-/settings/orgs`);
          } else {
            // Assuming only admins are able to delete other accounts or organizations where they aren't the owner
            // Redirect the admin to the admin users page
            history.replace("/_admin/users");
          }
        }
      })
      .catch((e: Error) => {
        setDeletingAccount(false);
        setDeleteAccountError(e.message);
      });
  };

  return (
    <div className="whiteSink">
      <h3>Delete {currentAccount.type === "user" ? "account" : "organization"}</h3>
      <Alert severity="warning">This will also delete all related datasets, services, stories and queries.</Alert>
      {deleteAccountError ? (
        <Alert variant="outlined" severity="error">
          {ensureTrailingDot(deleteAccountError)}
        </Alert>
      ) : null}
      <LoadingButton
        color="error"
        loading={deletingAccount}
        onClick={() =>
          confirm({
            confirmationString: currentAccount.accountName,
            title: `Are you sure you want to delete ${
              editingSelf
                ? "your account"
                : `${currentAccount.type === "user" ? "account" : "organization"} '${currentAccount.accountName}'`
            } permanently?`,
            onConfirm: handleDelete,
            actionLabel: `Delete ${editingSelf ? "my account" : "permanently"}`,
            description: (
              <Alert severity="warning">
                This will also delete all related datasets, services, stories and queries.
              </Alert>
            ),
          })
        }
      >
        Delete {currentAccount.type === "user" ? "account" : "organization"}
      </LoadingButton>
    </div>
  );
};

export default DeleteAccount;
