/**
 * W3C XML Schema Definition Language (XSD) 1.1 Part 2: Datatypes.
 * --------------------------------------------------------------
 *  A W3C Recommendation published on 2012-04-05
 *  @see https://www.w3.org/TR/xmlschema11-2/
 */
import { EmbeddedActionsParser, Lexer } from "chevrotain";
import { div, mod } from "../utils/helpers.ts";
import { decimalToCanonical } from "./decimal.ts";
import { D, Dash, Digit, Dot, H, M, P, S, T, Y } from "./tokens.ts";

const tokens = [Digit, Dot, Dash, P, Y, M, D, H, S, T];
/**
 * The grammar from the standard was partly rewritten to make sure that the
 * parser never has to backtrack.
 *
 * Because durations only signify what a number means _after_ the number (e.g.
 * `P5Y` has the `Y` after the `5`), we parse the number before branching
 * (`this.OR`), s.t. all the branches start with disambiguating letters.
 *
 * So the standard does: (number 'Y') OR (number 'M') OR (number 'D')
 * while we do: number ('Y' OR 'M' or 'D')
 *
 * To make sure we save the right value to the right place, the subrules take
 * the parsed number as an argument.
 */
export class DurationParser extends EmbeddedActionsParser {
  constructor() {
    super(
      tokens,
      // https://chevrotain.io/docs/guide/initialization_performance.html#use-a-smaller-global-maxlookahead
      // Even though we don't expect big performance difference between this value and the default (maxLookAhead=3),
      // we kept it here as a best practice for when designing rules in the future.
      { maxLookahead: 2 },
    );
    this.performSelfAnalysis();
  }

  /**
   * [46] unsignedNoDecimalPtNumeral ::= digit+
   */
  private unsignedNoDecimalPtNumeral = this.RULE("unsignedNoDecimalPtNumeral", () => {
    let value = "";
    this.AT_LEAST_ONE(() => (value += this.CONSUME(Digit).image));

    return value;
  });

  /**
   * A rule that:
   * - optionally matches a dot and then a number, then
   * - matches the letter S
   * - returns the argument as seconds
   *
   * This is for the following rule, except that the first sequence of digits
   * has already been matched.
   *
   * [11] duSecondFrag ::= (unsignedNoDecimalPtNumeral | unsignedDecimalPtNumeral) 'S'
   */
  private seconds = this.RULE("seconds", (seconds: string) => {
    const fraction = this.OPTION(() => {
      this.CONSUME(Dot);
      return this.SUBRULE(this.unsignedNoDecimalPtNumeral);
    });
    this.CONSUME(S);
    if (fraction) return { seconds, fraction };
    return { seconds };
  });

  /**
   * A rule that:
   * - matches the letter M
   * - optionally matches duSecondFrag
   *
   * This is equivalent to the second branch of rule 13: (duMinuteFrag duSecondFrag?)
   * given that we have already parsed a number.
   *
   * [10] duMinuteFrag ::= unsignedNoDecimalPtNumeral 'M'
   */
  private minutesOnwards = this.RULE("minutesOnwards", (minutes: string) => {
    this.CONSUME(M);
    const duration = this.OPTION(() => {
      const someNumber = this.SUBRULE(this.unsignedNoDecimalPtNumeral);
      return this.SUBRULE(this.seconds, { ARGS: [someNumber] });
    });
    return { ...duration, minutes };
  });

  /**
   * A rule that:
   * - matches the letter H
   * - optionally matches duMinuteFrag
   * - optionally matches duSecondFrag
   *
   * This is equivalent to the first branch of rule 13: (duHourFrag duMinuteFrag? duSecondFrag?)
   * given that we have already parsed a number.
   *
   * [9]  duHourFrag ::= unsignedNoDecimalPtNumeral 'H'
   */
  private hoursOnwards = this.RULE("hoursOnwards", (hours: string) => {
    this.CONSUME(H);
    const duration = this.OPTION(() => {
      const someNumber = this.SUBRULE(this.unsignedNoDecimalPtNumeral);
      return this.OR<{ minutes?: string; seconds?: string; fraction?: string }>([
        { ALT: () => this.SUBRULE(this.minutesOnwards, { ARGS: [someNumber] }) },
        { ALT: () => this.SUBRULE(this.seconds, { ARGS: [someNumber] }) },
      ]);
    });
    return { ...duration, hours };
  });

  /**
   *  [13] duTimeFrag ::= 'T' ( (duHourFrag duMinuteFrag? duSecondFrag?) |
   *                            (duMinuteFrag duSecondFrag?) | duSecondFrag )
   */
  private duTimeFrag = this.RULE("duTimeFrag", () => {
    this.CONSUME(T);
    const someNumber = this.SUBRULE(this.unsignedNoDecimalPtNumeral);
    const { fraction, ...strDuration } = this.OR<{
      hours?: string;
      minutes?: string;
      seconds?: string;
      fraction?: string;
    }>([
      { ALT: () => this.SUBRULE(this.hoursOnwards, { ARGS: [someNumber] }) },
      { ALT: () => this.SUBRULE(this.minutesOnwards, { ARGS: [someNumber] }) },
      { ALT: () => this.SUBRULE(this.seconds, { ARGS: [someNumber] }) },
    ]);

    const { hours, minutes, seconds } = {
      hours: strDuration.hours ? +strDuration.hours : 0,
      minutes: strDuration.minutes ? +strDuration.minutes : 0,
      seconds: strDuration.seconds ? +strDuration.seconds : 0,
    };

    return {
      seconds: 3600 * hours + 60 * minutes + seconds,
      fraction: fraction ?? "",
    };
  });

  /**
   * A rule that matches duDayFrag, given that the unsignedNoDecimalPtNumeral
   * was already consumed and is passed as an argument
   *
   * [8]  duDayFrag ::= unsignedNoDecimalPtNumeral 'D'
   */
  private day = this.RULE("day", (day: string) => {
    this.CONSUME(D);
    return { day };
  });

  /**
   * A rule that:
   * - matches the letter 'M'
   * - optionally matches duDayFrag
   *
   * [7]  duMonthFrag ::= unsignedNoDecimalPtNumeral 'M'
   */
  private monthOnwards = this.RULE("monthOnwards", (month: string) => {
    this.CONSUME(M);
    const day = this.OPTION(() => {
      const day = this.SUBRULE(this.unsignedNoDecimalPtNumeral);
      return this.SUBRULE(this.day, { ARGS: [day] });
    });
    if (day) return { ...day, month };
    return { month };
  });

  /**
   * A rule that:
   * - matches the letter 'Y'
   * - optionally matches duMonthFrag
   * - optionally matches duDayFrag
   *
   * [6]  duYearFrag ::= unsignedNoDecimalPtNumeral 'Y'
   */
  private yearOnwards = this.RULE("yearOnwards", (year: string) => {
    this.CONSUME(Y);
    const monthAndOrDay = this.OPTION(() => {
      const someNumber = this.SUBRULE(this.unsignedNoDecimalPtNumeral);
      return this.OR<{ day?: string; month?: string }>([
        { ALT: () => this.SUBRULE(this.monthOnwards, { ARGS: [someNumber] }) },
        { ALT: () => this.SUBRULE(this.day, { ARGS: [someNumber] }) },
      ]);
    });
    return { ...monthAndOrDay, year };
  });

  /**
   * Notice that even though the grammar is a bit convoluted, it summarizes to:
   * > each part (year, month, day, time) is optional, as long as at least one
   * > of them is defined.
   *
   * [12] duYearMonthFrag     ::= (duYearFrag duMonthFrag?) | duMonthFrag
   * [14] duDayTimeFrag       ::= (duDayFrag duTimeFrag?) | duTimeFrag
   * [15] durationLexicalRep  ::= '-'? 'P' ((duYearMonthFrag duDayTimeFrag?) | duDayTimeFrag)
   * [42] yearMonthDurationLexicalRep ::= '-'? 'P' duYearMonthFrag
   * [43] dayTimeDurationLexicalRep ::= '-'? 'P' duDayTimeFrag
   *
   * @param only  optionally validate whether the parsed value is also legal for
   *              `yearMonthDurationLexicalRep` or `dayTimeDurationLexicalRep`.
   */
  public durationLexicalRep = this.RULE(
    "durationLexicalRep",
    (only?: "yearMonthDurationLexicalRep" | "dayTimeDurationLexicalRep") => {
      const isNegative = !!this.OPTION(() => this.CONSUME(Dash).image);
      this.CONSUME(P);
      return this.OR<Duration>([
        // First branch of rule 15
        {
          ALT: () => {
            const someNumber = this.SUBRULE(this.unsignedNoDecimalPtNumeral);
            const date = this.OR1<{ year?: string; month?: string; day?: string }>([
              { ALT: () => this.SUBRULE(this.yearOnwards, { ARGS: [someNumber] }) },
              { ALT: () => this.SUBRULE(this.monthOnwards, { ARGS: [someNumber] }) },
              { ALT: () => this.SUBRULE(this.day, { ARGS: [someNumber] }) },
            ]);
            this.ACTION(() => {
              // if we want to make sure that what we just parsed is legal for `yearMonthDurationLexicalRep`,
              // it must not contain a `day`.
              // similarly, if what we just parsed should be (the start of) a legal `dayTimeDurationLexicalRep`,
              // it must not contain `year` or `month` parts.
              if (
                (only === "yearMonthDurationLexicalRep" && date.day !== undefined) ||
                (only === "dayTimeDurationLexicalRep" && (date.year ?? date.month) !== undefined)
              )
                throw new RangeError("Invalid");
            });
            let time = { seconds: 0, fraction: "" };
            // yearMonthDurationLexicalRep does not have a `duTimeFrag`.
            if (only !== "yearMonthDurationLexicalRep")
              time = { ...time, ...this.OPTION1(() => this.SUBRULE1(this.duTimeFrag)) };
            return {
              isNegative,
              months: Number(date.year ?? 0) * 12 + Number(date.month ?? 0),
              seconds: time.seconds + Number(date.day ?? 0) * 86400,
              fraction: time.fraction,
            };
          },
        },
        // Second branch of rule 15
        {
          // yearMonthDurationLexicalRep does not have a `duTimeFrag`.
          GATE: () => only !== "yearMonthDurationLexicalRep",
          ALT: () => ({ isNegative, months: 0, ...this.SUBRULE(this.duTimeFrag) }),
        },
      ]);
    },
  );

  /**
   * [12] duYearMonthFrag     ::= (duYearFrag duMonthFrag?) | duMonthFrag
   * [42] yearMonthDurationLexicalRep ::= '-'? 'P' duYearMonthFrag
   */
  public yearMonthDurationLexicalRep = this.RULE("yearMonthDurationLexicalRep", () =>
    this.SUBRULE(this.durationLexicalRep, { ARGS: ["yearMonthDurationLexicalRep"] }),
  );

  /**
   * [14] duDayTimeFrag       ::= (duDayFrag duTimeFrag?) | duTimeFrag
   * [43] dayTimeDurationLexicalRep ::= '-'? 'P' duDayTimeFrag
   */
  public dayTimeDurationLexicalRep = this.RULE("dayTimeDurationLexicalRep", () =>
    this.SUBRULE(this.durationLexicalRep, { ARGS: ["dayTimeDurationLexicalRep"] }),
  );
}

export const lexer = new Lexer(tokens, {
  ensureOptimizations: true,
  // not tracking lines for this grammar (not setting this will print a warning)
  positionTracking: "onlyOffset",
});
export const parser = new DurationParser();

export function durationToCanonical(duration: Duration) {
  const start = (duration.isNegative ? "-" : "") + "P";
  if (duration.months) {
    if (duration.seconds) {
      return (
        start +
        yearMonthDurationFragmentToCanonical(duration.months) +
        dayTimeDurationFragmentToCanonical(duration.seconds, duration.fraction)
      );
    }
    return start + yearMonthDurationFragmentToCanonical(duration.months);
  }

  return start + dayTimeDurationFragmentToCanonical(duration.seconds, duration.fraction);
}

/**
 * @DECISION We coin our own canonical representation of a 0 yearMonthDuration: P0M
 *
 * > Note: The yearMonthDuration value whose ·months· and ·seconds· are both
 * > zero has no ·canonical representation· in this datatype since its
 * > ·canonical representation· in duration ('PT0S') is not in the ·lexical
 * > space· of yearMonthDuration.
 * from: https://www.w3.org/TR/2012/REC-xmlschema11-2-20120405/datatypes.html#yearMonthDuration
 */
export function yearMonthDurationToCanonical(duration: Duration) {
  const value = durationToCanonical(duration);
  if (value === "PT0S") return "P0M";
  return value;
}

// ####################### canonicalization #######################

/**
 * Duration is a datatype that represents durations of time.
 *
 * @see ISO-8601 The concept of duration is drawn from this standard;
 * specifically "durations without fixed endpoints".
 */
export interface Duration {
  /** Whether this Duration is a negative value */
  isNegative: boolean;

  /**
   * A positive integer.
   */
  months: number;

  /**
   * A positive integer
   */
  seconds: number;

  /**
   * The part after the decimal point of seconds.
   *
   * This is stored separately as a string to make sure we don't underflow or
   * have other strange issues due to float representations in memory.
   *
   * This only works because we're not doing any calculations with durations yet.
   * When we start doing that, we should use proper Decimal representations.
   * See https://issues.triply.cc/issues/6951.
   */
  fraction: string;
}

/**
 * ·duDayTimeCanonicalFragmentMap· (ss) → duDayTimeFrag
 * Maps a nonnegative decimal number, presumably the absolute value of the
 * seconds of a duration value, to a `duDayTimeFrag`, a fragment of a duration
 * lexical representation.
 *
 * @param ss A nonnegative decimal number.
 * @return   A string that matches grammar rule `duDayTimeFrag`.
 *
 * Algorithm:
 * - Let `d` be `ss div 86400`,
 * - Let `h` be `(ss mod 86400) div 3600`,
 * - Let `m` be `(ss mod 3600) div 60`, and
 * - Let `s` be `ss mod 60`,
 * - Return `duDayCanonicalFragmentMap(d)` &
 *          `duTimeCanonicalFragmentMap(h, m, s)`
 *   when `ss` is not zero and
 * - Return `'T0S'`
 *   when `ss` is zero.
 */
function dayTimeDurationFragmentToCanonical(ss: number, fraction: string): string {
  if (!ss && !fraction) {
    return "T0S";
  } else {
    const d = div(ss, 86400);
    const h = div(mod(ss, 86400), 3600);
    const m = div(mod(ss, 3600), 60);
    const s = mod(ss, 60);
    return dayDurationFragmentToCanonical(d) + timeDurationFragmentToCanonical(h, m, s, fraction);
  }
}

/**
 * Maps three nonnegative numbers, presumably the hour, minute, and second
 * normalized values from a duration's seconds, to a string that matches
 * grammar rule `duTimeFrag`, a fragment of a duration lexical representation.
 *
 * @param h A nonnegative integer.
 * @param m A nonnegative integer.
 * @param s A nonnegative decimal number.
 * @return  A string that matches grammar rule `duTimeFrag`.
 *
 * Algorithm:
 * - Return `'T'` &
 *          `duHourCanonicalFragmentMap(h)` &
 *          `duMinuteCanonicalFragmentMap(m)` &
 *          `duSecondCanonicalFragmentMap(s)`
 *   when `h`, `m`, and `s` are not all zero, and
 * - Return the empty string (`''`)
 *   when all arguments are zero.
 */
function timeDurationFragmentToCanonical(h: number, m: number, s: number, fraction: string): string {
  if (!h && !m && !s && !fraction) {
    return "";
  } else {
    return (
      "T" +
      hourDurationFragmentToCanonical(h) +
      minuteDurationFragmentToCanonical(m) +
      secondDurationFragmentToCanonical(s, fraction)
    );
  }
}

/**
 * Maps a nonnegative decimal number, presumably the second normalized value
 * from the seconds of a duration value, to a string that matches grammar rule
 * `duSecondFrag`, a fragment of a duration lexical representation.
 *
 * @param s A nonnegative decimal number.
 * @return  A string that matches grammar rule `duSecondFrag`.
 *
 * Algorithm:
 * - Return `unsignedNoDecimalPtCanonicalMap(s)` &
 *          `'S'`
 *   when `s` is a non-zero integer,
 * - Return `unsignedDecimalPtCanonicalMap(s)` &
 *          `'S'`
 *   when `s` is not an integer, and
 * - Return the empty string (`''`)
 *   when `s` is zero.
 */
function secondDurationFragmentToCanonical(s: number, fraction: string): string {
  if (!s && !fraction) {
    return "";
  } else {
    return decimalToCanonical(s.toString() + "." + fraction + "0") + "S";
  }
}

/**
 * Maps a nonnegative integer, presumably the minute normalized value from the
 * seconds of a duration value, to a string that matches grammar rule
 * `duMinuteFrag`, a fragment of a duration lexical representation.
 *
 * @param m A nonnegative integer.
 * @return  A string that matches grammar rule `duMinuteFrag`.
 *
 * Algorithm:
 * - Return `unsignedNoDecimalPtCanonicalMap(m)` &
 *          `'M'`
 *   when `m` is not zero, and
 * - Return the empty string (`''`)
 *   when `m` is zero.
 */
function minuteDurationFragmentToCanonical(m: number): string {
  return m ? decimalToCanonical(m.toString()) + "M" : "";
}

/**
 * Maps a nonnegative integer, presumably the hour normalized value from the
 * seconds of a duration value, to a string that matches grammar rule
 * `duHourFrag`, a fragment of a duration lexical representation.
 *
 * @param h A nonnegative integer.
 * @return  A string matching grammar rule `duHourFrag`.
 *
 * Algorithm:
 * - Return `unsignedNoDecimalPtCanonicalMap(h)` &
 *          `'H'`
 *   when `h` is not zero, and
 * - Return the empty string (`''`)
 *   when `h` is zero.
 */
function hourDurationFragmentToCanonical(h: number): string {
  return h ? decimalToCanonical(h.toString()) + "H" : "";
}

/**
 * Maps a nonnegative integer, presumably the day normalized value from the
 * seconds of a duration value, to a string that matches grammar rule
 * `duDayFrag`, a fragment of a duration lexical representation.
 *
 * @param d A nonnegative integer.
 * @return  A string that matches grammar rule `duDayFrag`.
 *
 * Algorithm:
 * - Return `unsignedNoDecimalPtCanonicalMap(d)` &
 *          `'D'`
 *   when `d` is not zero, and
 * - Return the empty string (`''`)
 *   when `d` is zero.
 */
function dayDurationFragmentToCanonical(d: number): string {
  return d ? decimalToCanonical(d.toString()) + "D" : "";
}

/**
 * Maps a nonnegative integer, presumably the absolute value of the months of a
 * duration value, to a string that matches grammar rule `duYearMonthFrag`, a
 * fragment of a duration lexical representation.
 *
 * @param ym A nonnegative integer.
 * @return   A string that matches grammar rule `duYearMonthFrag`.
 *
 * Algorithm:
 * - Let `y` be `ym div 12`, and
 * - Let `m` be `ym mod 12`,
 * - Return `unsignedNoDecimalPtCanonicalMap(y)` &
 *          'Y' &
 *          `unsignedNoDecimalPtCanonicalMap(m)` &
 *          `'M'`
 *   when neither `y` nor `m` is zero,
 * - Return `unsignedNoDecimalPtCanonicalMap(y)` &
 *          `'Y'`
 *   when `y` is not zero but `m` is, and
 * - Return `unsignedNoDecimalPtCanonicalMap(m)` &
 *          `'M'`
 *   when `y` is zero.
 */
function yearMonthDurationFragmentToCanonical(ym: number): string {
  const y = div(ym, 12);
  const m = mod(ym, 12);
  if (y && m) {
    return decimalToCanonical(y.toString()) + "Y" + decimalToCanonical(m.toString()) + "M";
  } else if (y) {
    return decimalToCanonical(y.toString()) + "Y";
  } else {
    return decimalToCanonical(m.toString()) + "M";
  }
}
