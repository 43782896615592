import { generateColor } from "@marko19907/string-to-color";
import { Autocomplete, Chip, CircularProgress, ListItem, TextField } from "@mui/material";
import { debounce } from "@mui/material/utils";
import * as React from "react";
import { useHistory } from "react-router";
import { getLocalNameInfo } from "@triply/utils/prefixUtils";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import { substringMatch } from "#components/Highlight/index.tsx";
import { Highlight } from "#components/index.ts";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { setLastDataEditorResource } from "#reducers/datasets.ts";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import { stringifyQuery } from "../../../helpers/utils";
import { useCurrentDataset } from "../../../reducers/datasetManagement";
import type { SearchMeta } from "../ClassInfoContext";
import { ClassInfoContext, getSearchMeta } from "../ClassInfoContext";
import { MAX_SEARCH_RESULTS } from "..";
import { ExpandedContext } from "./tree/ExpandedContext";
import { COLOR_GENERATE_OPTIONS } from "./SkosTree";
import * as styles from "./style.scss";
import { SkosTreeContext } from "./tree/SkosTreeContext";

const factory = factories.compliant;
const THERE_IS_MORE_KEY = "_";

interface Concept {
  id: string;
  label: string;
  scheme?: string;
  schemeLabel?: string;
}

const getSearchQuery = ({
  searchTerm,
  filterSchemes,
  searchMeta,
}: {
  searchTerm: string;
  filterSchemes: string[];
  searchMeta: SearchMeta;
}) => {
  const searchTermWords = searchTerm.trim().toLocaleLowerCase().split(" ").filter(Boolean);
  const searchClause = searchTermWords.length
    ? `filter(${searchTermWords.map((searchTermWord) => `contains(lcase(?searchLabel), ${termToString(factory.literal(searchTermWord))})`).join(" && ")})`
    : "";

  return `
  # SKOS hierarchy search

  prefix skos: <http://www.w3.org/2004/02/skos/core#>
  prefix triply: <https://triplydb.com/Triply/function/>

  select distinct ?id (triply:firstLabel(?id) as ?label) ?scheme (triply:firstLabel(?scheme) as ?schemeLabel) where {
    ${searchMeta
      .map(
        ({ propertyPaths, rdfClass }) => `{
          ${
            filterSchemes.length
              ? `?id skos:inScheme ?scheme .
          filter (?scheme IN ( ${filterSchemes.map((scheme) => termToString(factory.namedNode(scheme))).join(", ")}) )`
              : ""
          }
          ?id a ${termToString(factory.namedNode(rdfClass))} .
          ?id ${propertyPaths.map((p) => p.map((p) => termToString(factory.namedNode(p))).join("/")).join("|")} ?searchLabel .
          ${searchClause}
        }`,
      )
      .join("\n union \n")}
  }
  limit ${MAX_SEARCH_RESULTS + 1}
`;
};

export const SearchField: React.FC<{}> = () => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedConcept, setSelectedConcept] = React.useState<Concept | null>(null);
  const [options, setOptions] = React.useState<readonly Concept[]>([]);
  const [optionsFor, setOptionsFor] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { schemes } = React.useContext(SkosTreeContext);
  const search = useCurrentSearch();
  const dispatch = useDispatch();
  const { collapseAll } = React.useContext(ExpandedContext);
  const classInfo = React.useContext(ClassInfoContext);
  const conceptSchemesQueryString = (search.conceptScheme as string) ?? "";
  const selectedSchemes = conceptSchemesQueryString.split(",").filter(Boolean);

  const hasSchemes = !schemes || schemes.length > 0;

  const { data: classes, loading: loadingSchemeClasses } = useSparql<{ class: string }[]>(
    hasSchemes
      ? `
      # Scheme classes

      prefix skos: <http://www.w3.org/2004/02/skos/core#>

      select distinct ?class where {
        values ?scheme {
          ${selectedSchemes.map((scheme) => termToString(factory.namedNode(scheme))).join(" ")}
        }
        [] skos:inScheme ?scheme ;
           a ?class .
      }
    `
      : ``,
  );

  const currentDs = useCurrentDataset()!;
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/_console/sparql`,
    fromBrowser: true,
  });

  const { accountName } = currentDs.owner;
  const datasetName = currentDs.name;

  const sparql = React.useCallback(
    async (query: string, abortSignal: AbortSignal) => {
      const response = await fetch(sparqlUrl, {
        credentials: "same-origin",
        signal: abortSignal,
        method: "POST",
        headers: { Accept: "application/json" },
        body: new URLSearchParams({
          account: accountName,
          dataset: datasetName,
          queryString: query,
        }),
      });
      if (!response.ok) throw new Error(response.statusText);
      const result = await response.json();
      return result;
    },
    [sparqlUrl, accountName, datasetName],
  );

  const debouncedQuery = React.useMemo(() => {
    const selectedSchemes = conceptSchemesQueryString.split(",").filter(Boolean);

    return debounce(
      (
        { searchTerm, abortSignal }: { searchTerm: string; abortSignal: AbortSignal },
        callback: (results?: readonly Concept[]) => void,
      ) => {
        if (!classInfo || !classes) return;
        setLoading(true);
        sparql(
          getSearchQuery({
            searchTerm: searchTerm,
            filterSchemes: selectedSchemes,
            searchMeta: getSearchMeta({
              classInfo: classInfo,
              rdfClasses: hasSchemes ? classes.map((c) => c.class) : ["http://www.w3.org/2004/02/skos/core#Concept"],
              includeSubClasses: !hasSchemes,
            }),
          }),
          abortSignal,
        )
          .then(callback)
          .catch(() => {})
          .finally(() => setLoading(false));
      },
      500,
    );
  }, [conceptSchemesQueryString, classInfo, classes, sparql, hasSchemes]);

  React.useEffect(() => {
    const abortController = new AbortController();
    let active = true;

    if (searchTerm === "") {
      setSelectedConcept(null);
      setOptions([]);
      return;
    }

    debouncedQuery({ searchTerm: searchTerm, abortSignal: abortController.signal }, (results?: readonly Concept[]) => {
      if (active) {
        setOptions(results?.length ? results : []);
        setOptionsFor(searchTerm);
        if (!results?.length) setSelectedConcept(null);
      }
    });

    return () => {
      active = false;
      abortController.abort("Not needed anymore");
    };
  }, [searchTerm, debouncedQuery]);

  const history = useHistory();

  return (
    <Autocomplete
      disabled={!getSearchMeta || loadingSchemeClasses}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.label)}
      options={
        options.length > MAX_SEARCH_RESULTS
          ? [...options.slice(0, MAX_SEARCH_RESULTS), { id: THERE_IS_MORE_KEY } as Concept]
          : options
      }
      getOptionDisabled={(option) => option.id === THERE_IS_MORE_KEY}
      clearIcon={false}
      filterOptions={(options) => options}
      fullWidth
      value={selectedConcept}
      noOptionsText="No results"
      getOptionKey={(option) => option.id}
      onChange={(_event: any, newValue: Concept | null, reason) => {
        if (reason === "selectOption") {
          if (newValue?.id) {
            setSelectedConcept(newValue);
            dispatch(setLastDataEditorResource(currentDs.id, newValue.id));
            collapseAll();
            history.push({
              search: stringifyQuery({ ...search, resource: newValue.id }),
            });
          }

          setSearchTerm("");
        } else {
          setSelectedConcept(null);
        }
      }}
      renderOption={(props, option) => {
        if (option.id === THERE_IS_MORE_KEY) {
          return (
            <em key={THERE_IS_MORE_KEY} className="m-4">
              <small>{`Showing the first ${MAX_SEARCH_RESULTS} results`}</small>
            </em>
          );
        }
        return (
          <ListItem {...props}>
            <Highlight
              fullText={option.label}
              className={styles.skosSearchFieldOptionText}
              highlightedText={optionsFor}
              matcher={substringMatch}
            />
            {option.scheme && (
              <Chip
                style={{ background: generateColor(option.scheme, COLOR_GENERATE_OPTIONS) }}
                label={option.schemeLabel || getLocalNameInfo(option.scheme).localName}
                size="small"
              />
            )}
          </ListItem>
        );
      }}
      onInputChange={(_event, newSearchTerm) => setSearchTerm(newSearchTerm)}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search in hierarchy"
            InputProps={{
              ...params.InputProps,
              endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
            }}
          />
        );
      }}
    />
  );
};
