import type { AutocompleteInputChangeReason } from "@mui/material";
import { Autocomplete, debounce, ListItem, ListItemAvatar, ListItemIcon, ListItemText, TextField } from "@mui/material";
import * as React from "react";
import { CachePolicies } from "use-http";
import type { DatasetPublic } from "@triply/utils/Models";
import AccessLevelIcon from "#components/AccessLevels/Icon.tsx";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useFetch from "#helpers/hooks/useFetch.ts";
import Avatar from "../Avatar";
import Highlight, { substringMatch } from "../Highlight";

interface Props {
  id?: string;
  onChange: (dataset: DatasetPublic | null) => void;
  defaultValue?: { name: string; owner: { accountName: string } };
  value?: DatasetPublic | null;
  error?: string;
  required?: boolean;
}

function getDatasetLabel(dataset: { name: string; owner: { accountName: string } }) {
  return dataset.owner.accountName + " / " + dataset.name;
}

const SearchDataset: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  ({ id, onChange: _onChange, value, error, required, defaultValue }, ref) => {
    const [searchingFor, setSearchingFor] = React.useState(defaultValue ? getDatasetLabel(defaultValue) : "");
    const constructUrlToApi = useConstructUrlToApi();

    const datasetUrl = constructUrlToApi({
      pathname: `/datasets?limit=50&fields=id,name,displayName,accessLevel,avatarUrl,owner&verbose`,
      fromBrowser: true,
    });

    const { data: options = [], response } = useFetch<DatasetPublic[]>(
      `${datasetUrl}&q=${encodeURIComponent(searchingFor)}`,
      { cachePolicy: CachePolicies.NO_CACHE },
      [searchingFor],
    );
    const optionsFor = (response.url && new URL(response.url)?.searchParams?.get("q")) || "";

    const onChange = React.useCallback(
      (e: React.SyntheticEvent, data: DatasetPublic | null) => {
        _onChange(data);
        setSearchingFor(data ? getDatasetLabel(data) : "");
      },
      [_onChange],
    );

    const findSearch = React.useMemo(
      () =>
        debounce((e: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
          setSearchingFor(value);
        }),
      [],
    );

    return (
      <Autocomplete
        id={id}
        value={value}
        options={options}
        filterOptions={(x) => x}
        onChange={onChange}
        ref={ref}
        renderInput={({ inputProps, ...props }) => (
          <TextField
            {...props}
            inputProps={{
              ...inputProps,
              width: inputProps?.width + "",
              height: inputProps?.width + "",
            }}
            error={!!error}
            helperText={error}
            required={required}
          />
        )}
        noOptionsText="No datasets found"
        isOptionEqualToValue={(option: DatasetPublic, value: DatasetPublic) => {
          return (
            (option.owner.accountName === value.owner.accountName && option.name === value.name) ||
            option.id === value.id
          );
        }}
        getOptionLabel={getDatasetLabel}
        renderOption={(props, option: DatasetPublic) => {
          return (
            <ListItem {...props}>
              <ListItemAvatar>
                <Avatar size="sm" avatarUrl={option.avatarUrl} avatarName={option.displayName || option.name} alt="" />
              </ListItemAvatar>
              <ListItemText>
                <Highlight fullText={getDatasetLabel(option)} highlightedText={optionsFor} matcher={substringMatch} />
                <ListItemIcon>
                  <AccessLevelIcon level={option.accessLevel} size="xs" type="dataset" className="ml-3" />
                </ListItemIcon>
              </ListItemText>
            </ListItem>
          );
        }}
        onInputChange={findSearch}
        defaultValue={defaultValue as any}
      />
    );
  },
);

export default SearchDataset;
