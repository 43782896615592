import * as d3 from "d3";
import dagre from "dagre";
import * as React from "react";
import type { EdgeData, NodeData } from "./useData.ts";
import { escapeId } from "./utils.ts";

export default function useLayout(
  ref: any,
  data: { nodes: NodeData[]; edges: EdgeData[] } | undefined,
  structureOpts: {
    ranker: string;
    rankdir: string;
    align?: string;
  },
) {
  const [graph, setGraph] = React.useState<dagre.graphlib.Graph<NodeData>>();

  React.useEffect(() => {
    const zoomElement = d3.select(ref.current).select<SVGGElement>("g#zoom");
    const zoomNode = zoomElement.node();
    if (!zoomNode || !data) {
      setGraph(undefined);
      return;
    }

    const newGraph = new dagre.graphlib.Graph<NodeData>({ multigraph: true })
      .setGraph({
        marginx: 0,
        marginy: 0,
        rankdir: structureOpts.rankdir,
        ranker: structureOpts.ranker,
        align: structureOpts.align,
      })
      .setDefaultEdgeLabel(function () {
        return {};
      });

    console.info("Computing layout");

    const originalZoomTransform = d3.zoomTransform(zoomNode);
    // Temporarily reset zoom so we can properly measure the items
    zoomElement.attr("transform", d3.zoomIdentity.toString());

    for (const n of data.nodes) {
      const bbox = (
        d3
          .select(ref.current)
          .select(`#node-${escapeId(n.id)}`)
          .node() as any
      )?.getBoundingClientRect();
      if (!bbox) {
        console.error("Couldn't find node", n.id);
        continue;
      }
      if (bbox) newGraph.setNode(n.id, { width: bbox.width, height: bbox.height });
    }

    for (const l of data.edges) {
      if (l.label) {
        const bbox = (
          d3
            .select(ref.current)
            .select("#edgeLabel-" + escapeId(`${l.from}-${l.to}-${l.label}`))
            .node() as any
        )?.getBBox();
        if (!bbox) {
          console.error("Couldn't find edge", l.from, l.to);
          continue;
        }
        newGraph.setEdge(l.from, l.to, { width: bbox.width, height: bbox.height, labelpos: "c" }, l.label);
      } else {
        newGraph.setEdge(l.from, l.to, { width: 10, height: 10, labelpos: "c" });
      }
    }

    zoomElement.attr("transform", originalZoomTransform.toString());

    dagre.layout(newGraph);
    setGraph(newGraph);
  }, [data, structureOpts.ranker, structureOpts.rankdir, structureOpts.align, ref]);

  return graph;
}
