import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Alert,
  Autocomplete,
  debounce,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import getClassName from "classnames";
import { stringify } from "qs";
import * as React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { CachePolicies } from "use-http";
import type { Models } from "@triply/utils";
import { substringMatch } from "#components/Highlight/index.tsx";
import {
  Avatar,
  Button,
  FontAwesomeIcon,
  FontAwesomeRoundIcon,
  Highlight,
  LoadingButton,
  StoryBadge,
} from "#components/index.ts";
import { getQueryIconForType } from "#helpers/FaIcons.tsx";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useFetch from "#helpers/hooks/useFetch.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import * as styles from "./style.scss";

type PinnedItem = PinnedDataset | PinnedStory | PinnedQuery;
interface PinnedDataset {
  type: "Dataset";
  item: Pick<Models.SomeDataset, "id" | "name" | "displayName" | "avatarUrl">;
}
interface PinnedStory {
  type: "Story";
  item: Pick<Models.SomeStory, "id" | "name" | "displayName" | "bannerUrl">;
}
interface PinnedQuery {
  type: "Query";
  item: Pick<Models.SomeQuery, "id" | "name" | "displayName" | "resultType">;
}

export interface FormData {
  items: PinnedItem[];
}

interface Props {
  onCancel: React.EventHandler<React.MouseEvent<any>>;
  onSubmit: (data: FormData) => void;
  initialValues?: FormData;
}

const OverviewForm: React.FC<Props> = ({ onSubmit, onCancel, initialValues }) => {
  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    control,
  } = useForm<FormData>({
    defaultValues: { items: [], ...initialValues },
  });

  const [activeId, setActiveId] = React.useState<number | string | null>(null);
  const constructUrlToApi = useConstructUrlToApi();
  const currentAccount = useCurrentAccount();
  const [inputText, setInputText] = React.useState("");
  const [search, _setSearchFor] = React.useState("");
  const setSearchFor = React.useMemo(() => debounce(_setSearchFor), []);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "items",
  });

  const { data: options = [], response } = useFetch<PinnedItem[]>(
    `${constructUrlToApi({ pathname: "/_some" })}?${stringify(
      {
        q: search,
        e: fields.map(getId),
        o: currentAccount?.accountName,
      },
      { arrayFormat: "repeat" },
    )}`,
    {
      interceptors: {
        response: async ({ response }) => {
          response.data = [
            ...response.data.datasets.map((d: any) => ({ type: "Dataset", item: d }) as PinnedItem),
            ...response.data.stories.map((s: any) => ({ type: "Story", item: s }) as PinnedItem),
            ...response.data.queries.map((q: any) => ({ type: "Query", item: q }) as PinnedItem),
          ];
          return response;
        },
      },
      cachePolicy: CachePolicies.NO_CACHE,
    },
    [search, fields],
  );
  const optionsFor = (response.url && new URL(response.url)?.searchParams?.get("q")) || "";

  return (
    <div>
      <form method="POST" onSubmit={handleSubmit(onSubmit)}>
        <div style={{ width: "100%", maxWidth: 600 }}>
          <DndContext
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            sensors={sensors}
            onDragCancel={(event) => {
              setActiveId(null);
            }}
            onDragEnd={(event) => {
              const { active, over } = event;
              setActiveId(null);
              if (over?.id === undefined || over?.id === null) return;
              if (active.id !== over.id) {
                return move(
                  fields.findIndex((item) => item.id === active.id),
                  fields.findIndex((item) => item.id === over.id),
                );
              }
            }}
            onDragStart={(event) => {
              setActiveId(event.active.id);
            }}
          >
            <List dense>
              <SortableContext items={fields.map((item) => item.id)} strategy={verticalListSortingStrategy}>
                {fields.map((value, idx) => {
                  return <SortableItem id={value.id} key={value.id} pinnedItem={value} onRemove={() => remove(idx)} />;
                })}
              </SortableContext>
            </List>
            <DragOverlay>
              {activeId && <DraggingElement pinnedItem={fields.find((el) => el.id === activeId)!} />}
            </DragOverlay>
          </DndContext>
          <Autocomplete
            value={null} // No need  to track the value
            inputValue={inputText}
            options={options}
            onChange={(_event, value) => {
              if (value) append(value);
            }}
            onInputChange={(_event, newInputValue, reason) => {
              if (reason !== "reset") {
                setInputText(newInputValue);
                setSearchFor(newInputValue);
              } else {
                setInputText("");
                setSearchFor("");
              }
            }}
            renderOption={(props, pinnedItem) => {
              return (
                <ListItem dense {...props}>
                  <ListItemIcon>{getIcon(pinnedItem)}</ListItemIcon>
                  <ListItemText
                    primary={
                      <Highlight
                        fullText={pinnedItem.item.displayName || pinnedItem.item.name}
                        highlightedText={optionsFor}
                        matcher={substringMatch}
                      />
                    }
                  />
                </ListItem>
              );
            }}
            getOptionKey={(option) => option.item.id}
            getOptionLabel={(option) => option.item.displayName || option.item.name}
            filterOptions={(x) => x}
            renderInput={({ inputProps, ...rest }) => (
              <TextField
                {...rest}
                inputProps={{
                  ...inputProps,
                  width: inputProps?.width + "",
                  height: inputProps?.width + "",
                }}
                label="Add a dataset, story or query"
                placeholder="Type to search"
              />
            )}
          />
        </div>
        {errors.root && <Alert severity="error">{errors.root.message}</Alert>}
        <div className="form-group mt-5">
          <LoadingButton type="submit" color="secondary" disabled={!isDirty} loading={isSubmitting}>
            Save overview
          </LoadingButton>
          <Button onClick={onCancel} className="ml-3" variant="text">
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

const SortableItem: React.FC<{ id?: string; pinnedItem: PinnedItem; onRemove?: () => void }> = ({
  id,
  pinnedItem,
  onRemove,
}) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: id!,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ListItem
      dense
      ref={setNodeRef}
      style={style}
      className={getClassName(styles.sortableElement, { ["invisible"]: isDragging })}
      secondaryAction={
        onRemove && (
          <IconButton
            size="small"
            onClick={(e) => {
              onRemove();
            }}
            aria-label="Remove item"
            title="Remove item"
          >
            <FontAwesomeIcon icon="times" />
          </IconButton>
        )
      }
    >
      <IconButton
        ref={setActivatorNodeRef}
        {...listeners}
        {...attributes}
        size="small"
        className={getClassName(styles.handle, "mr-3")}
      >
        <FontAwesomeIcon icon="bars" />
      </IconButton>
      <ListItemContent pinnedItem={pinnedItem} />
    </ListItem>
  );
};

const ListItemContent: React.FC<{ pinnedItem: PinnedItem }> = ({ pinnedItem }) => {
  return (
    <>
      <ListItemIcon>{getIcon(pinnedItem)}</ListItemIcon>
      <ListItemText primary={pinnedItem.item.displayName || pinnedItem.item.name}></ListItemText>
    </>
  );
};

const DraggingElement: React.FC<{ pinnedItem: PinnedItem }> = ({ pinnedItem }) => {
  return (
    <ListItem dense className={getClassName(styles.sortableElement, styles.draggingElement)}>
      <IconButton disabled size="small" className={getClassName(styles.handle, "mr-3")}>
        <FontAwesomeIcon icon="bars" />
      </IconButton>
      <ListItemContent pinnedItem={pinnedItem} />
    </ListItem>
  );
};

const getIcon = (pinnedItem: PinnedItem) => {
  if (!pinnedItem) return <FontAwesomeIcon icon="search" />;
  const pinnedItemLabel = pinnedItem.item.displayName || pinnedItem.item.name;
  switch (pinnedItem.type) {
    case "Dataset":
      return <Avatar avatarName={pinnedItemLabel} avatarUrl={pinnedItem.item.avatarUrl} size="sm" alt={""} />;
    case "Query":
      return (
        <FontAwesomeRoundIcon
          icon={getQueryIconForType(pinnedItem.item.resultType)}
          size="sm"
          aria-label={`Query result type: ${pinnedItem.item.resultType}`}
        />
      );
    case "Story":
      return <StoryBadge bannerUrl={pinnedItem.item.bannerUrl} />;
    default:
      return <FontAwesomeIcon icon="search" aria-label={"Search result"} />;
  }
};

const getId = (p: PinnedItem) => p.item.id;

export default OverviewForm;
