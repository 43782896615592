/**
 * If `m` and `n` are numbers, then `m div n` is the greatest integer less
 * than or equal to `m / n`.
 */
export function div(m: number, n: number): number {
  return Math.floor(m / n);
}

/**
 * If `m` and `n` are numbers, then `m mod n` is `m - n × (m div n)`.
 */
export function mod(m: number, n: number): number {
  return m % n;
}
