import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import type { Models } from "@triply/utils";
import { FontAwesomeIcon } from "#components/index.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import * as styles from "./styles/topicSegment.scss";

interface Props {
  topic: Models.Topic;
  hasParent?: boolean;
}

const TopicSegment: React.FC<Props> = (props) => {
  return (
    <Link
      to={{ pathname: "/browse/datasets", search: stringifyQuery({ topic: props.topic.label.toLowerCase() }) }}
      className={getClassName(styles.segment, "noLinkDecoration")}
    >
      {props.hasParent && <FontAwesomeIcon icon={["fas", "caret-right"]} className={styles.caret} />}
      <div className="px-2">{props.topic.label}</div>
    </Link>
  );
};

export default TopicSegment;
