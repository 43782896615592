import { formatNumber } from "@core/utils/formatting";
import { IconButton, InputAdornment, Paper, Table, TableContainer, Toolbar, Tooltip, Typography } from "@mui/material";
import type { CellContext, ColumnDef, ColumnFiltersState, RowData } from "@tanstack/react-table";
import {
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import type { Models } from "@triply/utils";
import type { AdminQueries, AdminQuery, QueryExecutionStatus, QueryServiceType } from "@triply/utils/Models";
import AccessLevelIcon from "#components/AccessLevels/Icon.tsx";
import IdeToolTipButton from "#components/IdeTooltipButton/index.tsx";
import { Circle, Dialog, FontAwesomeIcon, HumanizedDate, MuiTextField, ServiceTypeBadge } from "#components/index.ts";
import { HumanizedDateInPastRenderer, NumberRenderer, TableFooter } from "#components/ReactTableUtils/index.ts";
import ColumnVisibility from "#components/ReactTableUtils/PersistedColumnVisibilityButton.tsx";
import ReactTableBody from "#components/ReactTableUtils/TableBody.tsx";
import TableHeader from "#components/ReactTableUtils/TableHeader.tsx";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import useLocalStorage from "#helpers/hooks/useLocalStorage.tsx";
import humanizeDuration from "#helpers/HumanizeDate.ts";
import type { KeyboardShortcut } from "../../Hotkeys/index.tsx";
import { useHotkeys } from "../../Hotkeys/index.tsx";
import * as tableStyles from "#components/ReactTableUtils/tableStyle.scss";

// type the fetch queries function, as described here https://tanstack.com/table/v8/docs/api/core/table#meta
declare module "@tanstack/table-core" {
  interface TableMeta<TData extends RowData> {
    fetchQueriesFunc: (q?: string) => void;
  }
}
interface Props {
  queries: AdminQueries;
  loading?: boolean;
  error?: string;
  fetchQueriesFunc: (q?: string) => void;
}

const FULLSCREEN_HOT_KEY: KeyboardShortcut = {
  component: "Query admin table",
  description: "Opens the admin table in fullscreen",
  keyBinds: "f",
};

/**
 * Render components
 */

const TableQueryActions: React.FC<CellContext<AdminQuery, any>> = ({ row, table }) => {
  const confirm = useConfirmation();
  const dispatch = useDispatch();
  const constructUrlToApi = useConstructUrlToApi();
  const deleteQueryHandler = (query: AdminQuery) => {
    confirm({
      title: "Delete query",
      description: `Are you sure you want to delete query '${query.name}'?`,
      actionLabel: "Delete",
      onConfirm: () => {
        fetch(
          constructUrlToApi({
            pathname: `/queries/${query.ownerName}/${query.name}`,
          }),
          { credentials: "same-origin", method: "DELETE" },
        )
          .then(() => table.options.meta?.fetchQueriesFunc())
          .catch(() => {});

        // dispatch<typeof deleteQuery>(deleteQuery(query)).catch(() => { });
      },
    });
  };

  return (
    <div className={tableStyles.actionButtons}>
      <IconButton color="error" onClick={() => deleteQueryHandler(row.original)} aria-label="Remove service">
        <FontAwesomeIcon icon="times" />
      </IconButton>
      {row.original.latestVersionQueryString && (
        <IdeToolTipButton
          queryString={row.original.latestVersionQueryString}
          queryName={row.original.name}
          queryOwner={row.original.ownerName}
        />
      )}
    </div>
  );
};

const QueryLinkRenderer: React.FC<CellContext<AdminQuery, unknown>> = ({ row }) => {
  return (
    <Link to={`/${row.original.ownerName}/-/queries/${row.original.name}`} target="_blank">
      {row.original.name}
    </Link>
  );
};

const QueryOwnnerLinkRenderer: React.FC<CellContext<AdminQuery, string>> = ({ cell }) => {
  const value = cell.getValue();
  return (
    <Link to={`/${value}`} target="_blank">
      {value}
    </Link>
  );
};

const DatasetLinkRenderer: React.FC<CellContext<AdminQuery, unknown>> = ({ row }) => {
  const dataset = row.original.datasetName;
  const datasetOwner = row.original.datasetOwnerName;
  if (dataset && datasetOwner) {
    return (
      <span>
        <Link to={`/${datasetOwner}`} target="_blank">
          {datasetOwner}
        </Link>
        {" / "}
        <Link to={`/${datasetOwner}/${dataset}`} target="_blank">
          {dataset}
        </Link>
      </span>
    );
  }
  return null;
};

const ServiceTypeRenderer: React.FC<CellContext<AdminQuery, unknown>> = ({ row }) => {
  if (row.original.serviceType)
    return <ServiceTypeBadge type={row.original.serviceType as QueryServiceType} size="sm" className="mr-2" />;
  else return null;
};

const AccessLevelRenderer: React.FC<CellContext<AdminQuery, Models.AccessLevel>> = (cell) => {
  return <AccessLevelIcon level={cell.getValue()} type="dataset" />;
};

const LastQueriedRenderer: React.FC<CellContext<AdminQuery, string>> = ({ row }) => {
  const value = row.original.lastQueriedAt;
  if (value !== undefined)
    return <HumanizedDate date={value} humanizeDuration={(date) => humanizeDuration(date, "past", "capitalize")} />;
  else return null;
};

function getCircleColor(status: QueryExecutionStatus) {
  switch (status) {
    case "Success":
      return "green";
    case "No results":
    case "No versions":
    case "Warning":
      return "orange";
    case "No dataset":
    case "Parsing error":
    case "No suitable service":
    case "Service error":
    case "Timeout":
      return "red";
    case "No information":
    default:
      return "default";
  }
}

const ExecutionStatusRenderer: React.FC<CellContext<AdminQuery, string>> = ({ row }) => {
  return (
    <Tooltip title={row.original.executionStats?.errorMessage} className={"pl-2"}>
      <div className="flex">
        <Circle color={getCircleColor(row.original.executionStats.status)} />
        <div className={"pl-2"} style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
          {row.original.executionStats.status}
        </div>
      </div>
    </Tooltip>
  );
};

/**
 * Column Config
 */
function getColumns(): ColumnDef<AdminQuery, any>[] {
  const cols: Array<ColumnDef<AdminQuery, any> | undefined> = [
    {
      header: "Name",
      cell: QueryLinkRenderer,
      accessorFn: (query) => query.name,
      sortingFn: "alphanumeric",
      filterFn: "includesString",
    },
    {
      header: "Access level",
      accessorKey: "accessLevel",
      cell: AccessLevelRenderer,
      filterFn: "equals",
    },
    {
      header: "Display name",
      accessorFn: (query) => query.displayName,
      sortingFn: "alphanumeric",
      filterFn: "includesString",
    },
    {
      header: "Owner",
      accessorFn: (query) => query.ownerName,
      cell: QueryOwnnerLinkRenderer,
      sortingFn: "alphanumeric",
    },
    {
      header: "Dataset",
      cell: DatasetLinkRenderer,
      sortingFn: "alphanumeric",
      accessorFn: (query) => query.datasetName + " " + query.datasetOwnerName,
      filterFn: "includesString",
    },
    {
      header: "No. of versions",
      accessorKey: "numberOfVersions",
      cell: NumberRenderer,
      filterFn: "inNumberRange",
    },
    {
      header: "Visualization",
      accessorFn: (query) => (query.renderConfig?.output ? query.renderConfig.output : "None"),
      filterFn: "equals",
    },
    {
      id: "stories",
      header: "No. of stories used in",
      accessorFn: (query) => query.stories.length ?? 0,
      filterFn: "inNumberRange",
      cell: NumberRenderer,
    },
    {
      header: "Created at",
      accessorKey: "createdAt",
      cell: HumanizedDateInPastRenderer,
      enableColumnFilter: false,
    },
    {
      header: "Updated at",
      accessorKey: "updatedAt",
      cell: HumanizedDateInPastRenderer,
      enableColumnFilter: false,
    },
    {
      header: "Service type",
      accessorFn: (query) => query.serviceType || "No service",
      cell: ServiceTypeRenderer,
      filterFn: "equals",
    },
    {
      header: "Last queried at",
      accessorFn: (query) => query.lastQueriedAt || new Date(0),
      cell: LastQueriedRenderer,
      enableColumnFilter: false,
    },
    {
      header: "Status",
      cell: ExecutionStatusRenderer,
      accessorKey: "executionStats.status",
      filterFn: "equals",
    },
    {
      header: "Query type",
      accessorFn: (query) => query.executionStats.queryType || "unknown",
      filterFn: "equals",
    },
    {
      header: "Duration",
      accessorFn: (query) => (query.executionStats.executionTimeInMs || 0) / 1000,
      cell: ({ getValue, row }) =>
        row.original.executionStats.executionTimeInMs === undefined ? "" : `${getValue()}s`,
      filterFn: "inNumberRange",
    },
    {
      header: "Number of results",
      accessorFn: (query) => query.executionStats.resultCount || 0,
      cell: ({ getValue, row }) =>
        row.original.executionStats.resultCount === undefined ? "" : formatNumber(getValue()),
      filterFn: "inNumberRange",
    },
    {
      id: "actions",
      header: "",
      cell: TableQueryActions,
    },
  ];
  return cols.filter((c) => !!c) as ColumnDef<AdminQuery>[];
}

const SearchQueryStringForm: React.FC<{ fetchQueriesFunc: Props["fetchQueriesFunc"] }> = ({ fetchQueriesFunc }) => {
  const [queryStringFilter, setQueryStringFilter] = React.useState("");
  const onQueryStringSearch = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const searchString = (e.currentTarget[0] as HTMLInputElement)?.value || undefined;
      fetchQueriesFunc(searchString);
      setQueryStringFilter(searchString || "");
    },
    [fetchQueriesFunc],
  );

  const onQueryStringReset = React.useCallback(() => {
    fetchQueriesFunc();
    setQueryStringFilter("");
  }, [fetchQueriesFunc]);

  return (
    <form onSubmit={onQueryStringSearch} onReset={onQueryStringReset} className="pt-2">
      <MuiTextField
        label="Search query string"
        helperText={queryStringFilter}
        InputProps={{
          endAdornment: [
            <InputAdornment position="end" className={queryStringFilter === "" ? "hidden" : ""} key="clearButton">
              <IconButton
                size="small"
                type="reset"
                title="Reset query string search"
                aria-label="Reset query string search"
              >
                <FontAwesomeIcon icon="xmark" />
              </IconButton>
            </InputAdornment>,
            <InputAdornment position="end" key="SearchButton">
              <IconButton size="small" type="submit" aria-label="Execute search query string">
                <FontAwesomeIcon icon="search" color="primary" />
              </IconButton>
            </InputAdornment>,
          ],
        }}
      />
    </form>
  );
};

const QueriesTable: React.FC<Props> = ({ queries, loading, error, fetchQueriesFunc }) => {
  const [fullScreen, setFullScreen] = React.useState(false);
  const columns = React.useMemo(() => getColumns(), []);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [localStorage, setLocalStorage] = useLocalStorage("table-queries", "{}");
  const ignoreColumnVisibilty = ["Name", "actions"];

  const table = useReactTable<AdminQuery>({
    meta: { fetchQueriesFunc },
    columns: columns,
    data: queries,
    state: {
      columnFilters,
      columnVisibility: JSON.parse(localStorage),
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    onColumnFiltersChange: setColumnFilters,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getRowId: (query) => query.id,
  });
  const toggleFullscreen = React.useCallback(() => setFullScreen((currentValue) => !currentValue), []);

  useHotkeys(FULLSCREEN_HOT_KEY, toggleFullscreen);

  const toolbarAndTable = (
    <>
      <Toolbar className={getClassName("mb-3 g-7 flex", tableStyles.alignEnd)}>
        <Typography variant="h4">Queries</Typography>
        <ColumnVisibility
          columns={table.getAllLeafColumns()}
          ignoreVisibility={ignoreColumnVisibilty}
          onChange={(visibility) => setLocalStorage(JSON.stringify(visibility))}
        />
        <div className={tableStyles.space} />
        <SearchQueryStringForm fetchQueriesFunc={fetchQueriesFunc} />
        <IconButton
          size="large"
          title="Fullscreen (f)"
          aria-keyshortcuts="f"
          aria-label={fullScreen ? "Fullscreen" : "Leave FullScreen"}
          onClick={toggleFullscreen}
        >
          <FontAwesomeIcon icon={fullScreen ? "xmark" : "expand"} />
        </IconButton>
      </Toolbar>
      <TableContainer className={tableStyles.tableContainer}>
        <div className="px-5">
          <Table size="small">
            <TableHeader headerGroups={table.getHeaderGroups()} />
            <ReactTableBody
              rows={table.getRowModel().rows}
              loading={loading}
              error={error}
              columnCount={columns.length}
            />
            <TableFooter
              currentPage={table.getState().pagination.pageIndex}
              pageSize={table.getState().pagination.pageSize}
              rowCount={table.getPrePaginationRowModel().rows.length}
              onChangePage={table.setPageIndex}
              onChangeRowsPerPage={table.setPageSize}
            />
          </Table>
        </div>
      </TableContainer>
    </>
  );

  return fullScreen ? (
    <Dialog open fullScreen onClose={() => setFullScreen(false)}>
      {toolbarAndTable}
    </Dialog>
  ) : (
    <Paper square className={tableStyles.tablePaper}>
      {toolbarAndTable}
    </Paper>
  );
};

export default QueriesTable;
