import { formatNumber } from "@core/utils/formatting";
import getClassName from "classnames";
import { sortBy } from "lodash-es";
import * as React from "react";
import { useSelector } from "react-redux";
import { FileRow, FontAwesomeButton, Label, LinkButton } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { getNumEnqueuedUploads, state as tusState } from "#helpers/tusUploadManagement.ts";
import { cancelAllUploads, cancelUpload } from "#reducers/assets.ts";
import { getCurrentDataset, useCurrentDataset } from "#reducers/datasetManagement.ts";
import type { GlobalState } from "#reducers/index.ts";
import * as styles from "./assetUploads.scss";

export interface AssetUpload {
  fileName: string;
  progress: number;
  fileSize: number;
  cancelUpload: () => void;
  uploadId: string;
}

export interface FailedAssetUpload {
  fileName: string;
  message?: string;
}

export interface Props {}

const FAILED_UPLOAD_PAGE_SIZE = 30;

const FuncAssetUploads: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const currentDs = useCurrentDataset();
  const uploads = useSelector(
    (state: GlobalState) => state.datasetManagement.current && state.assetUploads[state.datasetManagement.current],
  );
  const [failedUploadPagesShown, setFailedUploadPagesShown] = React.useState<number>(0);
  if (!currentDs) return null;
  const uploadingAssets: AssetUpload[] = [];
  const failedUploads: FailedAssetUpload[] = [];
  const numEnqueuedAssets = getNumEnqueuedUploads("asset", currentDs.id);
  if (uploads) {
    failedUploads.push(
      ...sortBy(
        Object.values(uploads).filter((f) => !!f.message),
        ["failTime", "uploadId"],
      ).map((f) => ({
        fileName: f.fileName,
        message: f.message,
      })),
    );
    uploadingAssets.push(
      ...(tusState.ongoingUploads
        .filter((item) => item.datasetId === currentDs.id && item.type === "asset")
        .map((upload) => {
          if (!uploads[upload.uploadId]) return undefined;
          return {
            fileName: uploads[upload.uploadId].fileName,
            fileSize: uploads[upload.uploadId].fileSize,
            progress: uploads[upload.uploadId].progress,
            cancelUpload: () => {
              dispatch(cancelUpload(currentDs, upload.uploadId));
            },
            uploadId: upload.uploadId,
          };
        })
        .filter((u) => !!u) as AssetUpload[]),
    );
  }
  if (!uploadingAssets.length && !numEnqueuedAssets && !failedUploads.length) return null;
  return (
    <div className={getClassName(styles.item)}>
      <div className={getClassName(styles.topRow)}>
        <div className={styles.titleContainer}>
          <h2 className="mb-3">Uploads</h2>
        </div>
        <FontAwesomeButton
          className={styles.bigXContainer}
          title="Cancel all uploads"
          icon="times"
          size="lg"
          onClick={() => dispatch(cancelAllUploads(currentDs))}
        />
      </div>
      {uploadingAssets.map((a) => (
        <FileRow
          key={`${a.fileName}_${a.fileSize}_${a.uploadId}`}
          isUploading={true}
          fileName={a.fileName}
          fileSize={a.fileSize}
          uploadProgress={a.progress}
          removeFile={a.cancelUpload}
          removeFileToolTip={"Cancel upload"}
        />
      ))}
      <div className={styles.statusInfo}>
        <p>
          {!!numEnqueuedAssets && (
            <span>
              {`There ${numEnqueuedAssets === 1 ? "is" : "are"} ${formatNumber(numEnqueuedAssets)} enqueued asset${
                numEnqueuedAssets === 1 ? "" : "s"
              }.`}{" "}
            </span>
          )}

          {!!failedUploads.length && (
            <span>
              <span>{`${formatNumber(failedUploads.length)} asset${
                failedUploads.length === 1 ? "" : "s"
              } failed to upload. `}</span>
              {failedUploadPagesShown === 0 && (
                <LinkButton className={styles.pointer} onClickOrEnter={() => setFailedUploadPagesShown(1)}>
                  Show failed uploads
                </LinkButton>
              )}
              {failedUploadPagesShown > 0 && (
                <LinkButton className={styles.pointer} onClickOrEnter={() => setFailedUploadPagesShown(0)}>
                  Hide failed uploads
                </LinkButton>
              )}
            </span>
          )}
        </p>

        {failedUploadPagesShown > 0 && (
          <div>
            <h4>Failed uploads</h4>
            <dl>
              {failedUploads.slice(0, failedUploadPagesShown * FAILED_UPLOAD_PAGE_SIZE).map((failedUpload, index) => (
                <React.Fragment key={"failed_upload_" + index}>
                  <dt>{failedUpload.fileName}</dt>
                  <dd>
                    <Label error>{failedUpload.message}</Label>
                  </dd>
                </React.Fragment>
              ))}
              {failedUploadPagesShown * FAILED_UPLOAD_PAGE_SIZE < failedUploads.length && [
                <dt key="dot-dot-dot">...</dt>,
                <dt key="pagination-buttons">
                  <LinkButton
                    className={styles.pointer}
                    onClickOrEnter={() => setFailedUploadPagesShown((pages) => pages + 1)}
                  >
                    show more
                  </LinkButton>{" "}
                  /{" "}
                  <LinkButton
                    className={styles.pointer}
                    onClickOrEnter={() =>
                      setFailedUploadPagesShown(Math.ceil(failedUploads.length / FAILED_UPLOAD_PAGE_SIZE))
                    }
                  >
                    show all
                  </LinkButton>
                </dt>,
              ]}
            </dl>
          </div>
        )}
      </div>
    </div>
  );
};

export default FuncAssetUploads;
