import { formatNumber } from "@core/utils/formatting";
import { Slider, Tooltip } from "@mui/material";
import type { Column } from "@tanstack/react-table";
import * as React from "react";
import * as styles from "./tableStyle.scss";

interface LabelComponentProps {
  children: React.ReactElement<any>;
  open: boolean;
  value: number;
}
const LabelComponent: React.FC<LabelComponentProps> = ({ children, open, value }) => {
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value} color="primary">
      {children}
    </Tooltip>
  );
};
function SliderColumnFilterGetter(logScale: boolean) {
  const SliderColumnFilter: React.FC<{ column: Column<any, number> }> = ({ column }) => {
    let undoLog: (x: number) => number;
    let log: (x: number) => number;
    if (logScale) {
      const base = 1.1;
      log = (x) => {
        if (x === 0) return 0;
        return Math.log(x) / Math.log(base);
      };
      undoLog = (x) => {
        if (x === 0) return 0;
        return Math.round(base ** x); // use round to fix precision errors
      };
    } else {
      undoLog = log = (x) => x;
    }

    // Calculate the min and max
    // using the preFilteredRows
    const [initialMin, initialMax] = React.useMemo(() => {
      const [min, max] = column.getFacetedMinMaxValues()?.map((v) => (Array.isArray(v) ? v[0] : v)) || [0, 0];
      return [log(min || 0), log(max || min || 0)]; // If we get NaN back, we should assume 0, max should never be smaller then min, so if max is undefined, return min or 0
    }, [column, log]);
    const [value, setValue] = React.useState((column.getFilterValue() as [number, number]) || [initialMin, initialMax]);
    return (
      <Slider
        value={value}
        min={initialMin}
        max={initialMax}
        size="small"
        scale={(x) => undoLog(x)}
        disabled={initialMin === initialMax}
        valueLabelDisplay="auto"
        onChange={(_event, newValue) => {
          setValue(newValue as [number, number]);
        }}
        onChangeCommitted={(_event, newValue) => {
          column.setFilterValue((newValue as number[]).map(undoLog));
        }}
        components={{
          ValueLabel: LabelComponent,
        }}
        valueLabelFormat={(value) => formatNumber(value)}
        className={styles.slider}
      />
    );
  };
  return SliderColumnFilter;
}

const LinearSliderColumnFilter: React.FC<{ column: Column<any, number> }> = SliderColumnFilterGetter(false);
export default LinearSliderColumnFilter;
export const LogSliderColumnFilter: React.FC<{ column: Column<any, number> }> = SliderColumnFilterGetter(true);
