import React from "react";
import type { IComponentProps } from "#containers/index.ts";
import { ConsoleTabs } from "#containers/index.ts";

const DatasetSettingsTabs: React.FC<IComponentProps> = (props) => (
  <ConsoleTabs
    {...props}
    title="Dataset settings"
    tabLinks={[
      { pathname: "", name: "Settings" },
      { pathname: "/hooks", name: "Webhooks" },
    ]}
  />
);

export default DatasetSettingsTabs;
