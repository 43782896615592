import type { HierarchyNode, HierarchyRectangularNode, Selection } from "d3";
import { arc, interpolate, partition, pointer, select } from "d3";
import { values, zipObject } from "lodash-es";
import React from "react";
import type { ClassHierarchyComponentProps, Datum } from "./helpers.tsx";
import { addShadowFilter, BreadCrumbs, catchErrors, formatInstances, getLabel, useRenderRef } from "./helpers.tsx";
import * as styles from "./style.scss";

const SunBurst: React.FC<ClassHierarchyComponentProps> = (props) => {
  const ref = useRenderRef(drawSunBurst, props.classHierarchyTree, props.focussedNode, props.setFocus);
  return (
    <div ref={ref} className={styles.sunBurst}>
      <BreadCrumbs type="sunburst" focussedNode={props.focussedNode} />
    </div>
  );
};

export default SunBurst;

const drawSunBurst = catchErrors(function (
  divElement: HTMLDivElement,
  classHierarchyTree: HierarchyNode<Datum>,
  _focussedNode: HierarchyNode<Datum>,
  setFocus: (className: string) => void,
) {
  const width = Math.max(divElement.offsetWidth, 300);
  const height = width;
  const innerWidth = width - 68;
  const radius = innerWidth / 8;

  const transitionDuration = 500;

  const root = partition<Datum>().size([2 * Math.PI, classHierarchyTree.height + 1])(classHierarchyTree);
  //the partition function has added attributes to the nodes, so focussedNode now is a HierarchyRectangularNode too:
  const focussedNode = _focussedNode as HierarchyRectangularNode<Datum>;

  const getRelativePosition = (d1: HierarchyRectangularNode<Datum>, d2: HierarchyRectangularNode<Datum>) => {
    return {
      x0: Math.max(0, Math.min(1, (d1.x0 - d2.x0) / (d2.x1 - d2.x0))) * 2 * Math.PI,
      x1: Math.max(0, Math.min(1, (d1.x1 - d2.x0) / (d2.x1 - d2.x0))) * 2 * Math.PI,
      y0: Math.max(0, d1.y0 - d2.depth),
      y1: Math.max(0, d1.y1 - d2.depth),
    };
  };

  const getArc = arc<{ x0: number; x1: number; y0: number; y1: number }>()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 1.5)
    .innerRadius((d) => d.y0 * radius)
    .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

  function arcVisible(p: { x0: number; x1: number; y0: number; y1: number }, isFocussed: boolean) {
    return (p.y1 <= 4 && p.y0 >= 1 && p.x1 > p.x0) || isFocussed;
  }

  function labelVisible(p: { x0: number; x1: number; y0: number; y1: number }, isFocussed: boolean) {
    return arcVisible(p, isFocussed) && !isFocussed && p.x1 - p.x0 > 0.03;
  }

  function labelTransform(d: HierarchyRectangularNode<Datum>) {
    const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    const y = ((d.y0 + d.y1) / 2) * radius;
    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
  }

  const svg = select(divElement)
    .selectAll<SVGSVGElement, number>("svg")
    .data([0])
    .join((enter) => {
      const svg = addShadowFilter(enter.append("svg"));
      svg.append("g").classed("shapes", true);
      svg.append("g").classed("labels", true);
      return svg;
    })
    .attr("width", width)
    .attr("height", height);

  function addHover(s: Selection<any, HierarchyRectangularNode<Datum>, Element, any>) {
    const mouseOffsetX = 20;
    const mouseOffsetY = 30;
    const labelHeight = 46;
    return s
      .on("mouseover", function (event, d) {
        const svgNode = svg.node();
        if (!svgNode) return;
        const [mouseX, mouseY] = pointer(event, svgNode);
        const g = svg
          .append("g")
          .classed(styles.hoverLabel, true)
          .attr("transform", () => "translate(" + [mouseX + mouseOffsetX, mouseY + mouseOffsetY] + ")");

        const firstLineLength =
          g
            .append("text")
            .text(() => getLabel(d) + ` (${formatInstances(d.value)})`)
            .attr("dx", 6)
            .attr("dy", 18)
            .node()
            ?.getComputedTextLength() || 0;

        const secondLineLength =
          g
            .append("text")
            .text(() =>
              d.data.prefixInfo?.prefixLabel
                ? `${d.data.prefixInfo.prefixLabel}:${d.data.prefixInfo.localName}`
                : d.id || "",
            )
            .attr("dx", 6)
            .attr("dy", 38)
            .node()
            ?.getComputedTextLength() || 0;

        g.append("rect")
          .attr("width", () => Math.max(firstLineLength, secondLineLength) + 12)
          .attr("height", labelHeight)
          .style("filter", "url(#drop-shadow)")
          .lower();
      })
      .on("mousemove", function (event) {
        const svgNode = svg.node();
        if (!svgNode) return;
        const [mouseX, mouseY] = pointer(event, svgNode);
        const g = svg.select(`.${styles.hoverLabel}`);
        const labelWidth = g.select<SVGRectElement>("rect").node()?.getBBox().width || 0;
        const offsetX = mouseX + mouseOffsetX + labelWidth > width - 5 ? width - 5 - mouseX - labelWidth : mouseOffsetX;
        const offsetY = mouseY + mouseOffsetY + labelHeight > height - 5 ? -labelHeight - 20 : mouseOffsetY;
        g.attr("transform", () => "translate(" + [mouseX + offsetX, mouseY + offsetY] + ")");
      })
      .on("mouseout", () => svg.selectAll(`.${styles.hoverLabel}`).remove());
  }

  //shapes
  const paths = svg
    .select<SVGGElement>("g.shapes")
    .attr("transform", `translate(${width / 2},${width / 2})`)
    .selectAll<SVGPathElement, HierarchyRectangularNode<Datum>>("path")
    .data(root.descendants())
    .join((enter) => {
      return enter
        .append("path")
        .attr("relativePosition", (d) => values(getRelativePosition(d, focussedNode)).join(","))
        .attr("fill-opacity", (d) =>
          arcVisible(getRelativePosition(d, focussedNode), d === focussedNode) ? (d.children ? 1 : 0.7) : 0,
        );
    })
    .attr("fill", (d) => d.data.branchColor || "black")
    .call(addHover);

  paths
    .transition()
    .duration(transitionDuration)
    .on("start", function (d) {
      if (arcVisible(getRelativePosition(d, focussedNode), d === focussedNode)) this.style.display = "inline";
    })
    .on("end", function (d) {
      if (!arcVisible(getRelativePosition(d, focussedNode), d === focussedNode)) this.style.display = "none";
    })
    .attr("fill-opacity", (d) =>
      arcVisible(getRelativePosition(d, focussedNode), d === focussedNode) ? (d.children ? 1 : 0.7) : 0,
    )
    .attrTween("d", function (d) {
      const el = select(this);
      const current = zipObject(
        ["x0", "x1", "y0", "y1"],
        el
          .attr("relativePosition")
          .split(",")
          .map((n) => +n),
      );

      const i = interpolate(current, getRelativePosition(d, focussedNode));

      return (t) => {
        const interpolated = i(t);
        el.attr("relativePosition", values(interpolated).join(","));

        return getArc(interpolated) as string;
      };
    });

  paths
    .filter((d) => !!d.children)
    .style("cursor", "pointer")
    .on("click", (_event, d) => setFocus(d.data.name));

  //labels
  svg
    .select<SVGGElement>("g.labels")
    .attr("transform", `translate(${width / 2},${width / 2})`)
    .attr("pointer-events", "none")
    .attr("text-anchor", "middle")
    .selectAll<SVGTextElement, HierarchyRectangularNode<Datum>>("text")
    .data(root.descendants())
    .join((enter) => {
      return enter
        .append("text")
        .text(getLabel)
        .attr("dy", "0.35em")
        .style("fill", "white")
        .attr("fill-opacity", 0)
        .attr("transform", labelTransform)
        .attr("relativePosition", (d) => values(getRelativePosition(d, focussedNode)).join(","));
    })
    .style("font-size", function () {
      //make sure we measure the size using the regular font size
      this.style.fontSize = "1em";
      return `${Math.min(1, (0.9 * radius) / this.getComputedTextLength())}em`;
    })
    .filter(function (d) {
      return (
        !!+(this.getAttribute("fill-opacity") as string) ||
        labelVisible(getRelativePosition(d, focussedNode), d === focussedNode)
      );
    })
    .transition()
    .duration(transitionDuration)
    .attr("fill-opacity", (d) => +labelVisible(getRelativePosition(d, focussedNode), d === focussedNode))
    .attrTween("transform", function (d) {
      const el = select(this);
      const current = zipObject(
        ["x0", "x1", "y0", "y1"],
        el
          .attr("relativePosition")
          .split(",")
          .map((n) => +n),
      );

      const i = interpolate(current, getRelativePosition(d, focussedNode));

      return (t) => {
        const interpolated = i(t);
        el.attr("relativePosition", values(interpolated).join(","));

        return labelTransform(interpolated as any) as string;
      };
    });

  const centerCircle = svg
    .selectAll<SVGGElement, Number>(`g.${styles.centerCircle}`)
    .data([0])
    .join((enter) => {
      const g = enter.append("g");
      g.append("circle");
      g.append("path")
        .attr(
          "d",
          "M296.64 99.674l-96.16-96.16c-4.686-4.687-12.285-4.686-16.97 0L87.353 99.671c-7.536 7.536-2.198 20.484 8.485 20.485l68.162.002V456H64a11.996 11.996 0 0 0-8.485 3.515l-32 32C15.955 499.074 21.309 512 32 512h164c13.255 0 24-10.745 24-24V120.159l68.154.001c10.626 0 16.066-12.906 8.486-20.486z",
        )
        .attr("fill", "currentColor");
      return g;
    })
    .classed(styles.centerCircle, true)
    .classed(styles.clickable, focussedNode !== root)
    .attr("transform", `translate(${width / 2},${width / 2})`)
    .attr("pointer-events", "all")
    .on("click", () => {
      if (focussedNode.parent) setFocus(focussedNode.parent.data.name);
    })
    .attr("title", "Level up");

  centerCircle
    .selectAll<SVGTextElement, HierarchyRectangularNode<Datum>>("text")
    .data([focussedNode], (d) => d.data.name)
    .join(
      (enter) => enter.append("text").text(getLabel).attr("dy", "0.35em").attr("fill-opacity", 0),
      (update) => update,
      (exit) =>
        exit
          .transition()
          .duration(transitionDuration)
          .attr("fill-opacity", 0)
          .style("font-size", (d) => (d.depth < focussedNode.depth ? "0em" : "2em"))
          .remove(),
    )
    .style("font-size", function () {
      //make sure we measure the size using the regular font size
      this.style.fontSize = "1em";
      return `${Math.min(1, (0.9 * 2 * radius) / this.getComputedTextLength())}em`;
    })
    .style("fill", (d) => (d === root ? "#555555" : "white"))
    .transition()
    .duration(transitionDuration)
    .delay(0.5 * transitionDuration)
    .attr("fill-opacity", 1);

  centerCircle.select("circle").attr("r", radius);

  centerCircle
    .select("path")
    .style("transform", () => {
      const scale = (0.1 * radius) / 140;
      return `translate(-${160 * scale}px, -${250 * scale}px) scale(${scale})`;
    })
    .raise();
});
