import { Alert, ToggleButton, ToggleButtonGroup } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { FormField } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { updateEditorProcessFlow } from "#reducers/config.ts";
import type { GlobalState } from "#reducers/index.ts";

interface Props {}

const EditorProcess: React.FC<Props> = ({}) => {
  const editorProcess = useSelector((state: GlobalState) => state.config.clientConfig?.editorProcessConfig);
  const isEditorEnabled = useSelector(
    (state: GlobalState) => state.config.clientConfig?.featureToggles?.dataEditor.value,
  );
  const dispatch = useDispatch();
  return (
    <div className="whiteSink">
      <h3>Editor process</h3>
      {isEditorEnabled && <Alert severity="warning">This is a temporary setting and will change in future</Alert>}
      {!isEditorEnabled && <Alert severity="info">The feature toggle for dataEditor needs to be enabled</Alert>}
      <p>
        Define the workflow for the users that use the data editor. For information about the workflows see the
        following diagrams:{" "}
        <a href="https://triplydb.com/Triply/-/queries/Simple-editor-schema/1" target="_blank">
          simple
        </a>
        ,{" "}
        <a href="https://triplydb.com/Triply/-/queries/Complex-editor-schema/1" target="_blank">
          complex
        </a>
        . When disabled, the editor is still visible for viewers with edit-rights.
      </p>
      <FormField label="User">
        <ToggleButtonGroup
          exclusive
          disabled={!isEditorEnabled}
          value={editorProcess?.users || "simple"}
          onChange={(e, value) => value && dispatch(updateEditorProcessFlow("users", value))}
        >
          <ToggleButton value="none">Disabled</ToggleButton>
          <ToggleButton value="simple">Simple</ToggleButton>
        </ToggleButtonGroup>
      </FormField>
      <FormField label="Organization">
        <ToggleButtonGroup
          exclusive
          disabled={!isEditorEnabled}
          value={editorProcess?.organizations || "simple"}
          onChange={(e, value) => value && dispatch(updateEditorProcessFlow("organizations", value))}
        >
          <ToggleButton value="none">Disabled</ToggleButton>
          <ToggleButton value="simple">Simple</ToggleButton>
          <ToggleButton value="complex">Complex</ToggleButton>
        </ToggleButtonGroup>
      </FormField>
    </div>
  );
};
export default EditorProcess;
