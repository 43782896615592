import * as React from "react";

function clickedOutsideEl(
  ref: React.RefObject<HTMLElement | undefined | null>,
  event: React.MouseEvent | React.TouchEvent,
) {
  if (!ref.current) return true;
  if (!ref.current.contains(event.target as Node)) {
    return true;
  }
  return false;
}
/**
 * useClickOutside will often react to propogating events from the previous page.
 * If you notice that it fires unexpectedly, use a 'e.stopPropagtion()' on the event that triggers it.
 *
 */
export default function useClickOutside<T extends HTMLElement>(
  callback: (event: React.MouseEvent | React.TouchEvent) => void,
  ref: React.RefObject<T | null>,
) {
  const callbackRef = React.useRef(callback);
  /**
   * Update callback if it changes
   */
  React.useEffect(() => {
    callbackRef.current = callback;
  });

  /**
   * Add and remove event listeners
   */
  React.useEffect(() => {
    const listener = (event: any) => {
      const outside = clickedOutsideEl(ref, event);
      if (!outside) return;

      callbackRef.current(event);
    };

    document.addEventListener("click", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("click", listener);
      document.removeEventListener("touchstart", listener);
    };
  });
}
