import { Alert, Fab } from "@mui/material";
import getClassName from "classnames";
import type { Location } from "history";
import type { MutableRefObject } from "react";
import React from "react";
import { Link } from "react-router-dom";
import FontAwesomeIcon from "#components/FontAwesomeIcon/index.tsx";
import usePrevious from "#helpers/hooks/usePrevious.ts";
import { parseSearchString } from "#helpers/utils.ts";
import Edge from "./Edge.tsx";
import EdgeLabel from "./EdgeLabel.tsx";
import FilterButton from "./FilterButton.tsx";
import Node from "./Node.tsx";
import SearchButton from "./SearchButton.tsx";
import useData from "./useData.ts";
import type { QuerySchema } from "./useFetchSchema.ts";
import useLayout from "./useLayout.ts";
import useZoomableSVG from "./useZoomableSVG.ts";
import * as styles from "./style.scss";

const Graph: React.FC<{
  className?: string;
  querySchema: QuerySchema;
  centerFunctionRef: MutableRefObject<any | null>;
  location: Location;
}> = ({ querySchema, centerFunctionRef, className, location }) => {
  const { search } = location;
  const query = parseSearchString(search);
  const data = useData(querySchema, location);
  const svgRef = React.useRef<SVGSVGElement>(null);
  const ranker = (query.r || "network-simplex") as string;
  const rankDirection = (query.d || "LR") as string;
  const align = query.a as string | undefined;
  const layoutGraph = useLayout(svgRef, data, {
    ranker: ranker,
    rankdir: rankDirection,
    align: align,
  });
  const dataChanged = data !== usePrevious(data);

  const width = layoutGraph?.graph().width;
  const height = layoutGraph?.graph().height;

  const { centerScale } = useZoomableSVG(svgRef, width || 0, height || 0);
  centerFunctionRef.current = centerScale;

  const prevWidth = usePrevious(width);
  const prevHeight = usePrevious(height);
  const widthDelta = prevWidth !== undefined && width !== undefined ? Math.abs(width - prevWidth) : Infinity;
  const heightDelta = prevHeight !== undefined && height !== undefined ? Math.abs(height - prevHeight) : Infinity;
  React.useEffect(() => {
    if (widthDelta > 500 || heightDelta > 500) centerScale();
    // Only center when width or height changes a lot.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  console.info(`Render ${data?.nodes.length} nodes and ${data?.edges.length} edges.`);

  if (data?.nodes.length === 0) {
    return (
      <div className="constrainWidth p-5">
        <Alert variant="filled" severity="info">
          No visualizable schema found.{" "}
          {!!search && (
            <Link style={{ color: "white" }} to={{ search: "", hash: "" }}>
              Clear all filters
            </Link>
          )}
        </Alert>
      </div>
    );
  }

  return (
    <>
      <div className={getClassName(styles.fabSpace, "mui-fixed")}>
        <Fab
          onClick={() => {
            centerFunctionRef.current?.();
          }}
          size="small"
          aria-label="Center visualization"
          title="Center"
        >
          <FontAwesomeIcon icon="arrows-to-circle" size="lg" />
        </Fab>
        <SearchButton
          nodes={data?.nodes}
          location={location}
          center={(iri: string) => centerFunctionRef.current?.(iri)}
        />
        <FilterButton querySchema={querySchema} location={location} />
      </div>
      <div className={getClassName(styles.graph, className)}>
        <svg ref={svgRef} id="schema-svg">
          <g id="zoom">
            <g className="edges">
              {data?.edges.map((l) => (
                <Edge
                  key={`${l.from}-${l.to}-${l.iri}`}
                  data={l}
                  layoutData={
                    l.label ? layoutGraph?.edge(l.from, l.to, l.label) : layoutGraph?.edge({ v: l.from, w: l.to })
                  }
                />
              ))}
            </g>
            <g className="edgeLabels">
              {data?.edges
                .filter((l) => !!l.label)
                .map((l) => (
                  <EdgeLabel
                    key={`${l.from}-${l.to}-${l.iri}`}
                    data={l}
                    layoutData={
                      l.label ? layoutGraph?.edge(l.from, l.to, l.label) : layoutGraph?.edge({ v: l.from, w: l.to })
                    }
                    location={location}
                  />
                ))}
            </g>
            <g className="nodes">
              {data?.nodes.map((n) => (
                <Node
                  key={n.id}
                  data={n}
                  layoutData={layoutGraph?.node(n.id)}
                  unrestricted={dataChanged}
                  location={location}
                />
              ))}
            </g>
          </g>
          <defs>
            <marker
              id="arrowhead"
              className={styles.arrowHead}
              viewBox="0 0 10 10"
              refX="9"
              refY="5"
              markerUnits="strokeWidth"
              markerWidth="8"
              markerHeight="6"
              orient="auto"
            >
              <path d="M 0 0 L 10 5 L 0 10 z" style={{ strokeWidth: 1, strokeDasharray: "1, 0" }}></path>
            </marker>
            <marker
              id="subClassArrow"
              className={styles.subClassArrow}
              refX="0"
              refY="7"
              markerWidth="190"
              markerHeight="240"
              orient="auto"
            >
              <path d="M 1,7 L18,13 V 1 Z"></path>
            </marker>
          </defs>
        </svg>
      </div>
    </>
  );
};
export default Graph;
