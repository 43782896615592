import * as React from "react";
import { useSelector } from "react-redux";
import type { Account } from "#reducers/accountCollection.ts";
import type { GlobalState } from "#reducers/index.ts";
import type { Props as MetaProps } from "./Meta.tsx";
import { default as Meta } from "./Meta.tsx";

export interface Props extends Partial<MetaProps> {
  currentAccount: Account;
}

const MetadataAccount: React.FC<Props> = (props) => {
  const { currentAccount, ...mainMetaProps } = props;
  const brandingName = useSelector((state: GlobalState) => state.config.clientConfig?.branding.name);
  const title = currentAccount.name || currentAccount.accountName;
  const longTitle = `${title} on ${brandingName}`;
  const description = `Account of ${currentAccount.type === "org" ? "organization" : "user"} ${title}.`;

  // We retrieve a larger image from gravatar to for the account.
  // IF we ever use lx we need to apply the same mechanisms.
  let imageUrl = currentAccount.avatarUrl;
  const parsedAvatarUrl = imageUrl ? new URL(imageUrl) : undefined;
  if (parsedAvatarUrl?.hostname === "www.gravatar.com") {
    // The default size is set to 80. To improve the size of the image we set
    // it 460. Change the query argument is it needs to be larger.
    parsedAvatarUrl.searchParams.set("s", "460");
    imageUrl = parsedAvatarUrl.toString();
  }

  const imageAlt = `Avatar of ${title}`;
  const currentPath = `/${currentAccount.accountName}`;

  return (
    <Meta
      title={title}
      currentPath={currentPath}
      type="profile"
      profileUsername={currentAccount.accountName}
      longTitle={longTitle}
      description={description}
      image={imageUrl}
      imageAlt={imageAlt}
      {...mainMetaProps}
    />
  );
};
export default MetadataAccount;
