import { Alert, FormHelperText, Skeleton, TextField, ThemeProvider, useTheme } from "@mui/material";
import { cloneDeep, merge } from "lodash-es";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { factories } from "@triplydb/data-factory";
import { parsers } from "@triplydb/sparql-ast";
import { SparqlAstError } from "@triplydb/sparql-ast/error";
import { termToString } from "@triplydb/sparql-ast/serialize";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { FormField, Prompt } from "#components/index.ts";
import SparqlEditor from "#components/Sparql/Editor/index.tsx";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import localTheme from "./Theme";
import type { SparqlBasedConstraintData } from "./Types";

const factory = factories.compliant;

const sparqlBasedConstraintQueryExample = `
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>

# Checks if there is no other instance with the same label.
select $this (rdfs:label as ?path) ?value {
  $this rdfs:label ?label.
  ?value rdfs:label ?label.
  filter(?value != $this)
}
`.trim();

const SparqlBasedConstraint: React.FC<{
  onSubmit: (values: SparqlBasedConstraintData) => Promise<void>;
  onDirty: (dirty: boolean) => void;
  initialValues?: SparqlBasedConstraintData;
}> = ({ onSubmit, onDirty, initialValues }) => {
  const currentDs = useCurrentDataset()!;

  const theme = useTheme();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty, isValid },
    setError,
    setValue,
    getValues,
  } = useForm<SparqlBasedConstraintData>({
    defaultValues: initialValues || { message: "", query: sparqlBasedConstraintQueryExample },
    mode: "onChange",
  });
  const location = useLocation();

  React.useEffect(() => {
    const values = getValues();
    if (values.query) setValue("query", sparqlBasedConstraintQueryExample);
  }, [setValue, getValues]);

  const currentClass = useCurrentResource();

  const { data: existingProperties } = useSparql<SparqlBasedConstraintData[]>(
    `
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix owl: <http://www.w3.org/2002/07/owl#>

    select
      ?id
      ?rangeOptions_id
      (sample(?label_t) as ?label)
      (sample(?description_t) as ?description)
      (sample(?rangeOptions_label_t) as ?rangeOptions_label)
      (sample(?rangeOptions_description_t) as ?rangeOptions_description)
    where {
      bind(${termToString(factory.namedNode(currentClass))} as ?class)
      # ?id rdfs:subPropertyOf*/rdfs:domain/^rdfs:subClassOf* ?class.
      ?id rdfs:domain/^rdfs:subClassOf* ?class.
      # filter not exists {
      #   ?id rdfs:subPropertyOf*/rdfs:range/rdfs:subClassOf* rdfs:Literal.
      # }
      ?id a owl:ObjectProperty.
      ?id rdfs:label ?label_t
      optional {
        ?id rdfs:comment ?description_t
      }
      # ?id rdfs:subPropertyOf*/rdfs:range/^rdfs:subClassOf* ?rangeOptions_id.
      ?id rdfs:range/^rdfs:subClassOf* ?rangeOptions_id.
      filter (!regex(str(?rangeOptions_id), "\.well-known/genid"))
      optional {
        ?rangeOptions_id rdfs:label ?rangeOptions_label_t
      }
      optional {
        ?rangeOptions_id rdfs:comment ?rangeOptions_description_t
      }

    }
    group by ?id ?rangeOptions_id
    order by ?label
    `,
    { singularizeVariables: {} },
  );

  const submit = async (values: SparqlBasedConstraintData) => {
    try {
      await onSubmit(values);
    } catch (e) {
      console.error(e);
      setError("root.serverError", {
        type: "500",
      });
    }
  };

  React.useEffect(() => {
    onDirty(isDirty);
  }, [isDirty, onDirty]);

  if (!existingProperties) {
    return <Skeleton variant="rectangular" width={860} height={175} />;
  }

  return (
    <ThemeProvider theme={merge(cloneDeep(localTheme), theme)}>
      <Prompt
        when={isDirty}
        message={(newState) => {
          //don't want the prompt to show up when only changing the location state
          //Otherwise, drawing the modal would trigger it
          if (location.pathname === newState.pathname) return true;
          return "Your changes have not been saved. Are you sure you want to continue?";
        }}
      />

      <form method="POST" onSubmit={handleSubmit(submit)} className="flex column g-7">
        {/* This field is required for things we make, it is not required in the type as the SHACL spec does not require it */}
        <FormField label="Message" required>
          <Controller
            name={"message"}
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                required
                autoFocus
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                value={value || ""}
                error={!!error}
                helperText={error?.message ?? <span>The validation message that will be shown</span>}
              />
            )}
          />
        </FormField>

        <FormField label="Query" required>
          <Controller
            name={"query"}
            control={control}
            rules={{
              validate: (value) => {
                if (!value.trim()) return "A query is required.";
                try {
                  const ast = parsers.compliant(value, { baseIri: "https://triplydb.com/", type: "query" });
                  if (ast.queryType !== "select") {
                    return "Expected a select query";
                  }
                } catch (e: unknown) {
                  if (e instanceof SparqlAstError) {
                    return e.message;
                  } else if (e instanceof Error) {
                    return e.message;
                  }
                  return "An unknown issue has occurred.";
                }
              },
            }}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => {
              return (
                <>
                  <SparqlEditor
                    initialValue={initialValues?.query || sparqlBasedConstraintQueryExample}
                    onChange={({ query }) => {
                      onChange(query);
                    }}
                  />
                  <FormHelperText error={!!error?.message}>
                    {error?.message ? (
                      <pre>{error.message}</pre>
                    ) : (
                      <span>
                        Add a{" "}
                        <a target="_blank" href="https://www.w3.org/TR/shacl/#sparql-constraints-example">
                          SPARQL based constraint
                        </a>{" "}
                        query. This query must include $this to reference the target node.
                      </span>
                    )}
                  </FormHelperText>
                </>
              );
            }}
          />
        </FormField>

        <LoadingButton color="secondary" type="submit" disabled={isSubmitting || !isValid} loading={isSubmitting}>
          Save
        </LoadingButton>

        {errors.root && <Alert severity="error">Something went wrong on the server...</Alert>}
      </form>
    </ThemeProvider>
  );
};

export default SparqlBasedConstraint;
