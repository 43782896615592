import * as React from "react";
import { useSelector } from "react-redux";
import UrlParse from "url-parse";
import { AccountMetadata } from "#components/index.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { filterEmptyVals } from "#helpers/utils.ts";
import type { Account } from "#reducers/accountCollection.ts";
import type { GlobalState } from "#reducers/index.ts";

export interface Props {
  currentAccount: Account;
}

const AccountInfoMetadata: React.FC<Props> = (props) => {
  const { currentAccount } = props;
  const constructConsoleUrl = useConstructConsoleUrl();
  const longName = currentAccount.name || currentAccount.accountName;
  const brandingName = useSelector((state: GlobalState) => state.config.clientConfig?.branding.name);
  const description = currentAccount.description
    ? currentAccount.description
    : `Profile of ${currentAccount.name || currentAccount.accountName} on ${brandingName}.`;
  //
  // // We retrieve a larger image from gravatar to for the account.
  // // IF we ever use lx we need to apply the same mechanisms.
  const logoLg = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoLg || "");
  let imageUrl = currentAccount.avatarUrl || logoLg;
  const parsedAvatarUrl = UrlParse(imageUrl, true);
  if (parsedAvatarUrl.hostname === "www.gravatar.com") {
    // The default size is set to 80. To improve the size of the image we set
    // it 460. Change the query argument is it needs to be larger.
    parsedAvatarUrl.set("query", { ...parsedAvatarUrl.query, s: "460" });
    imageUrl = parsedAvatarUrl.toString();
  }
  //
  const accountUrl = constructConsoleUrl({ pathname: `/${currentAccount.accountName}` });
  const members =
    (currentAccount.type === "org" &&
      currentAccount.members &&
      currentAccount.members.map((m) => ({
        url: constructConsoleUrl({ pathname: `/${m.user.accountName}` }),
        name: m.user.name || m.user.accountName,
      }))) ||
    [];
  const memberOf =
    (currentAccount.type === "user" &&
      currentAccount.orgs &&
      currentAccount.orgs.map((m) => ({
        url: constructConsoleUrl({ pathname: `/${m.accountName}` }),
        name: m.name || m.accountName,
      }))) ||
    [];
  const schemaDotOrgJsonLd = {
    "@context": "https://schema.org/",
    "@type": "WebPage",
    url: accountUrl,
    mainEntity: filterEmptyVals({
      "@id": accountUrl,
      "@type": currentAccount.type === "org" ? "Organization" : "Person",
      description: description,
      member: members.map((m) => ({
        "@type": "Person",
        "@id": m.url,
        name: m.name,
      })),
      memberOf: memberOf.map((m) => ({
        "@type": "Organization",
        "@id": m.url,
        name: m.name,
      })),
      image: imageUrl,
      name: longName,
      url: accountUrl,
    }),
    speakable: {
      "@type": "SpeakableSpecification",
      xpath: ["/html/head/title", "/html/head/meta[@name='description']/@content"],
    },
  };
  return (
    <AccountMetadata currentAccount={props.currentAccount} jsonLd={[{ key: "schema", json: schemaDotOrgJsonLd }]} />
  );
};
export default AccountInfoMetadata;
