import getClassName from "classnames";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { Button } from "#components/index.ts";
import type { LocationState } from "./index.tsx";
import * as styles from "./AddItemButton.scss";

const AddItemButton: React.FC<{ position?: number; dragging: boolean }> = ({ position, dragging }) => {
  const history = useHistory<LocationState>();
  const location = useLocation<LocationState>();
  return (
    <div className={getClassName(styles.addItemSegment, "my-7", { [styles.hidden]: dragging })}>
      <Button
        onClick={() => {
          history.push({
            search: location.search,
            state: { storyElementAddModalShown: true, position: position, preserveScrollPosition: true },
          });
        }}
      >
        + Add new element
      </Button>
    </div>
  );
};

export default AddItemButton;
