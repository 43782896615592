import { generateColor } from "@marko19907/string-to-color";
import { Autocomplete, Chip, CircularProgress, InputAdornment, ListItem, TextField } from "@mui/material";
import getClassname from "classnames";
import { set } from "lodash-es";
import * as React from "react";
import { useHistory } from "react-router";
import { getLocalNameInfo } from "@triply/utils/prefixUtils";
import { substringMatch } from "#components/Highlight/index.tsx";
import { FontAwesomeIcon, Highlight } from "#components/index.ts";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import useLocalStorage from "#helpers/hooks/useLocalStorage.tsx";
import { stringifyQuery } from "#helpers/utils.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { COLOR_GENERATE_OPTIONS } from "../SkosTree";
import { SkosTreeContext } from "./SkosTreeContext";
import * as styles from "../style.scss";

function fixInputParams<T extends React.InputHTMLAttributes<HTMLInputElement> & { ref: React.Ref<HTMLInputElement> }>(
  params: T,
) {
  return {
    ...params,
    // The MUI types do not match
    width: params?.width?.toString(),
    height: params?.height?.toString(),
  };
}

export type Scheme = { conceptScheme: string; label: string };

const SchemeSelector: React.FC<{}> = () => {
  const search = useCurrentSearch();
  const ds = useCurrentDataset();
  const [persistedScheme, setPersistedScheme] = useLocalStorage(`triply::schemeselector::${ds?.id}`, "");

  const selectedSchemes = ((search.conceptScheme as string) || "").split(",").filter(Boolean);
  const history = useHistory();
  const { schemes: allSchemes, hierarchy, loading } = React.useContext(SkosTreeContext);

  React.useEffect(() => {
    if (!persistedScheme && allSchemes && allSchemes.length === 1) {
      setPersistedScheme(allSchemes[0].conceptScheme);
    }
  }, [allSchemes, persistedScheme, setPersistedScheme]);

  const [deleteHoverIndex, setDeleteHoverIndex] = React.useState(-1);
  const schemeOptions: string[] = React.useMemo(() => {
    if (!allSchemes || !hierarchy) return [];
    let schemes: string[] = [];

    if (selectedSchemes.length === 0) {
      schemes = allSchemes.map((i) => i.conceptScheme);
    } else {
      schemes = hierarchy
        .filter((item) => item.parentConceptScheme === selectedSchemes.at(-1))
        .map((item) => item.childConceptScheme);
    }

    schemes.sort((a, b) => {
      const aIndex = allSchemes.indexOf(allSchemes.find((scheme) => scheme.conceptScheme === a)!);
      const bIndex = allSchemes.indexOf(allSchemes.find((scheme) => scheme.conceptScheme === b)!);
      return aIndex - bIndex;
    });

    return schemes;
  }, [allSchemes, hierarchy, selectedSchemes]);

  React.useEffect(() => {
    if (persistedScheme && !search.conceptScheme)
      history.replace({
        ...history.location,
        search: stringifyQuery({ ...search, conceptScheme: persistedScheme }),
      });
    // We only wwant to execute this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={getClassname(styles.skosSchemeSelector)}>
      <Autocomplete
        multiple
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => {
            const scheme = allSchemes?.find((i) => i.conceptScheme === option);
            const schemeColor = scheme && generateColor(scheme.conceptScheme, COLOR_GENERATE_OPTIONS);
            const { ...tagProps } = getTagProps({ index });
            return (
              <Chip
                {...tagProps}
                variant="filled"
                label={scheme?.label || (scheme && getLocalNameInfo(scheme?.conceptScheme).localName) || "Loading..."}
                deleteIcon={
                  <FontAwesomeIcon
                    onMouseEnter={() => setDeleteHoverIndex(index)}
                    onMouseLeave={() => setDeleteHoverIndex(-1)}
                    size="2xs"
                    icon="xmark"
                  />
                }
                key={option}
                className={getClassname(
                  index === selectedSchemes.length - 1 ? styles.end : "",
                  deleteHoverIndex > -1 && deleteHoverIndex <= index ? styles.focus : "",
                )}
                style={{ background: schemeColor, "--color": schemeColor } as React.CSSProperties}
              />
            );
          })
        }
        noOptionsText={""}
        slotProps={{
          popper: {
            className: styles.schemeSelectorPopper,
          },
        }}
        getOptionLabel={(item) => allSchemes?.find((i) => i.conceptScheme === item)?.label ?? "Not found"}
        disabled={allSchemes?.length === 0}
        value={selectedSchemes}
        onChange={(_event, values) => {
          setDeleteHoverIndex(-1);

          // When removing a scheme, also remove all schemes after it
          if (values.length < selectedSchemes.length) {
            const removedItem = selectedSchemes.find((item) => !values.includes(item));
            if (removedItem) {
              const removedItemIndex = selectedSchemes.indexOf(removedItem);
              values = selectedSchemes.slice(0, removedItemIndex);
            }
          }

          setPersistedScheme(values.join(","));
          set(search, "conceptScheme", values.join(","));
          history.push({ search: stringifyQuery(search) });
        }}
        options={schemeOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Concept scheme(s)"
            helperText={`Concept schemes can extend each other, you can select a hierarchy here.`}
            placeholder={
              schemeOptions.length
                ? selectedSchemes.length === 0
                  ? "Select concept scheme"
                  : "Select a narrower concept scheme"
                : ""
            }
            inputProps={fixInputParams(params.inputProps)}
            InputProps={{
              ...params.InputProps,
              endAdornment: loading ? (
                <InputAdornment position="end">
                  <CircularProgress size={16} />
                </InputAdornment>
              ) : null,
            }}
          />
        )}
        renderOption={(props, option, { inputValue }) => (
          <ListItem {...props}>
            <Highlight
              fullText={allSchemes?.find((i) => i.conceptScheme === option)?.label || option}
              highlightedText={inputValue}
              matcher={substringMatch}
            />
          </ListItem>
        )}
      />
    </div>
  );
};
export default React.memo(SchemeSelector);
