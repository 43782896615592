import { DialogTitle } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Button, Dialog } from "#components/index.ts";
import type { GlobalState } from "#reducers/index.ts";

interface Props {}

interface LocationState {
  consentModelShown?: string;
}

const ConsentDialog: React.FC<Props> = ({}) => {
  const { generalTermsUrl, privacyPolicyUrl, contactEmail } = useSelector((state: GlobalState) => {
    return {
      generalTermsUrl: state.config.clientConfig?.legal?.generalTermsUrl,
      privacyPolicyUrl: state.config.clientConfig?.legal?.privacyPolicyUrl,
      contactEmail: state.config.clientConfig?.contactEmail,
    };
  });
  const location = useLocation<LocationState | undefined>();
  const [hasRefused, setRefused] = React.useState(false);
  React.useEffect(() => {
    setRefused(false);
  }, [location.state?.consentModelShown]);
  const history = useHistory();

  return (
    <Dialog
      open={!!location.state?.consentModelShown}
      onClose={() => {
        history.goBack();
      }}
    >
      <DialogTitle>Consent</DialogTitle>
      {!hasRefused ? (
        <>
          <div className="py-3 mx-5">
            Before we create your account, you need to agree to our{" "}
            <a href={generalTermsUrl} target="_blank">
              general terms
            </a>{" "}
            and{" "}
            <a href={privacyPolicyUrl} target="_blank">
              privacy policy
            </a>
            .
          </div>
          <div className="py-3 ml-5">
            <Button href={location.state?.consentModelShown}>I agree</Button>
            <Button variant="text" className="mx-2" onClick={() => setRefused(true)}>
              I refuse
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="py-3 mx-5">
            In order to create an account we need your agreement on the <a href={generalTermsUrl}>general terms</a> and{" "}
            <a href={privacyPolicyUrl}>privacy policy</a>. For more information contact{" "}
            {contactEmail ? (
              <>
                us at <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
              </>
            ) : (
              "the system administrator"
            )}
            .
          </div>
          <div className="py-3 mx-5">
            <Button
              onClick={() => {
                setRefused(false);
              }}
            >
              Go back
            </Button>
            <Button
              className="mx-2"
              onClick={() => {
                history.goBack();
              }}
            >
              Close
            </Button>
          </div>
        </>
      )}
    </Dialog>
  );
};

export default ConsentDialog;
