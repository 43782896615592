import { FormControlLabel, Switch } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import type { Models } from "@triply/utils";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { Alert, ErrorPage, FlexContainer, Label, Meta } from "#components/index.ts";
import type { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { clearCache, enableCache } from "#reducers/admin.ts";
import type { GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

export namespace AdminCache {
  export interface OwnProps extends IComponentProps {}
  export type Props = OwnProps;
}

const AdminCache: React.FC<AdminCache.Props> = (props) => {
  const acl = useAcl();
  if (!acl.check({ action: "manageInstanceCache" }).granted) {
    return <ErrorPage statusCode={404} />;
  }
  return (
    <div>
      <Meta currentPath={props.location.pathname} title="Cache - Admin settings" />

      <FlexContainer className="mt-3">
        <CacheComponent
          title="1. HDT file pointer cache"
          description="The HDT cache keeps a file-pointer open to recently queried files, to improved query performance. Opening a large HDT file can take seconds."
          cacheType="hdtFilePointer"
          mayClear
          disableToggle
        />
        <CacheComponent
          title="2. Describe cache"
          description="The describe cache is a disk-based cache for the result of describe queries. Whenever a large or slow describe query is executed, we store the results in this cache."
          cacheType="describe"
          mayClear
        />

        <CacheComponent
          title="3. SPARQL cache"
          description="The SPARQL cache is a disk-based cache for the result of SPARQL queries."
          cacheType="sparql"
          mayClear
        />
        <CacheComponent
          title="4. Saved query cache"
          description="The Saved Query cache is a disk-based cache for saved query results."
          cacheType="savedQuery"
          mayClear
        />
        <CacheComponent
          title="5. Elastic search cache"
          description="The Elastic search cache is a disk-based cache for elastic search results."
          cacheType="elasticSearch"
          mayClear
        />

        <CacheComponent
          title="6. Frontpage cache"
          description="The frontpage cache is a disk-based cache for items rendered on the UI front page, and is used for quickly rendering this page in the browser"
          cacheType="frontpage"
          mayClear
        />
        <CacheComponent
          title="7. GraphQL Schema cache"
          description="The GraphQL Schema cache is a disk-based cache for the GraphQL schema that is deduced from the SHACL shapes in a dataset."
          cacheType="graphqlSchema"
          mayClear
        />
        <CacheComponent
          title="8. Internal SPARQL cache"
          description="The internal SPARQL cache is a disk-based cache for the internal SPARQL route."
          cacheType="internalSparql"
          mayClear
        />
      </FlexContainer>
    </div>
  );
};

const CacheComponent: React.FC<{
  title: string;
  description: string;
  cacheType: Models.CacheType;
  mayClear?: boolean;
  disableToggle?: boolean;
}> = (props) => {
  const dispatch = useDispatch();
  const enabledCaches = useSelector((state: GlobalState) => {
    return state.config.clientConfig?.enabledCache;
  });

  const [togglingCache, setTogglingCache] = React.useState(false);
  const [clearingCache, setClearingCache] = React.useState(false);
  const [cacheCleared, setCacheCleared] = React.useState(false);
  const [cacheClearedError, setCacheClearedError] = React.useState<string | undefined>();
  if (!enabledCaches) return null;
  const isEnabled = enabledCaches[props.cacheType];
  return (
    <div className="whiteSink">
      <div className={styles.cacheHeader}>
        <h3>{props.title}</h3>
        <div className={styles.space} />
        {props.disableToggle && (
          <FormControlLabel
            labelPlacement="start"
            label={"Active"}
            control={
              <Switch
                color="primary"
                checked={isEnabled}
                onChange={(_, value) => {
                  setTogglingCache(true);
                  dispatch<typeof enableCache>(enableCache(props.cacheType, value))
                    .catch(() => {})
                    .finally(() => setTogglingCache(false));
                }}
                disabled={togglingCache}
              />
            }
          />
        )}
      </div>
      {!isEnabled && <Alert transparent warning message="This cache is disabled" />}
      <p>{props.description}</p>
      {props.mayClear && (
        <>
          <LoadingButton
            disabled={clearingCache}
            onClick={() => {
              setClearingCache(true);
              dispatch<typeof clearCache>(clearCache(props.cacheType))
                .then(() => {
                  setCacheCleared(true);
                  setCacheClearedError(undefined);
                })
                .catch((e) => {
                  setCacheClearedError(e.message);
                  setCacheCleared(false);
                })
                .finally(() => setClearingCache(false));
            }}
            loading={clearingCache}
          >
            Clear cache
          </LoadingButton>
          {cacheCleared && (
            <div style={{ marginTop: 5 }}>
              <Label success message="Cache successfully cleared." />
            </div>
          )}
          {cacheClearedError && <Alert message={cacheClearedError} />}
        </>
      )}
    </div>
  );
};
export default AdminCache;
