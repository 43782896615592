import { syntaxTree } from "@codemirror/language";
import type { EditorState } from "@codemirror/state";
import { Decoration, ViewPlugin } from "@codemirror/view";
import { isEmpty } from "lodash-es";
import { OutsideVariableFacet } from "../facets/outsideVariables";
import { getScopeAndParentForPos, QueryScope } from "../fields/queryContext";
import { AS_KEY, Var } from "../grammar/sparqlParser.terms";

export function getHighlightUnusedVariables(unusedVariableClassName: string) {
  const markAsUnusedMark = Decoration.mark({ class: unusedVariableClassName });
  const highlightUnusedVariables = ViewPlugin.define(
    (view) => {
      return {
        unusedVariableNodes: getUnboundVariablesFromState(view.state),
        constructor() {},
        update(update) {
          if (
            update.docChanged ||
            update.viewportChanged ||
            syntaxTree(update.startState) != syntaxTree(update.state) ||
            update.startState.facet(OutsideVariableFacet) != update.state.facet(OutsideVariableFacet)
          ) {
            this.unusedVariableNodes = getUnboundVariablesFromState(update.state);
          }
        },
      };
    },
    {
      decorations: (pluginObject) =>
        Decoration.set(
          pluginObject.unusedVariableNodes
            .filter((node) => node.from !== node.to)
            .map((node) => markAsUnusedMark.range(node.from, node.to)),
          true,
        ),
    },
  );
  return highlightUnusedVariables.extension;
}
type SmallNode = { from: number; to: number };

export function getUnboundVariablesFromState(state: EditorState): readonly SmallNode[] {
  const diagnostics: SmallNode[] = [];
  const baseBoundVariables = state.facet(OutsideVariableFacet);
  /** Stack contains all the clauses that are not the current one */
  const tree = syntaxTree(state);
  const cursor = tree.cursor();
  do {
    if (cursor.type.isError || cursor.type.isSkipped) {
      continue;
    }
    const [scope, parent] = getScopeAndParentForPos(state.field(QueryScope), cursor.to);
    if (!scope) continue;
    // We introduced INCOMPLETE VAR as a token, we shouldn't highlight that one
    if (cursor.type.id === Var && cursor.to - cursor.from > 1) {
      const varName = state.sliceDoc(cursor.from + 1, cursor.to);
      // variables from query variables are always bound
      if (baseBoundVariables.includes(varName)) continue;
      // If projected values is empty, we're in a "select *","ask" or a "construct where"
      if (isEmpty(scope.projectedVariables)) {
        continue;
      }
      if (cursor.matchContext(["SelectClause"])) {
        // If we're binding something in the select clause and there is no parent, its automatically projected/bound
        if (cursor.node.prevSibling?.type.id === AS_KEY && !parent) {
          continue;
        }
        // Check if it bound within in its own query
        if (!(varName in scope.boundVariables)) {
          diagnostics.push({ from: cursor.from, to: cursor.to });
          continue;
        }
        // Check if it bound within the parents query
        if (
          parent &&
          !isEmpty(parent.projectedVariables) &&
          !parent.boundVariables[varName].bound &&
          !(varName in parent.projectedVariables)
        ) {
          diagnostics.push({ from: cursor.from, to: cursor.to });
          continue;
        }
      } else if (
        !scope.boundVariables[varName]?.bound &&
        !(varName in scope.projectedVariables || varName in scope.orderVariables || varName in scope.groupVariables)
      ) {
        diagnostics.push({ from: cursor.from, to: cursor.to });
        continue;
      }
    }
    // Report the scope when we're past the last scope
  } while (cursor.next());
  // Report the outermost scope
  // ("diag", diagnostics);
  return diagnostics;
}
