// Same as WGS84
const CRS84 = {
  proj4: "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees",
  name: "CRS84",
  ogcWkt: `GEOGCS["WGS 84 (CRS84)",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["OGC","CRS84"]]`,
};

const correctedCrs = {
  // same as CRS84, but then with axis swapped (the +axis=neu part)
  // WKT1 has no defined axis, meaning that exports will show the wrong way round
  "http://www.opengis.net/def/crs/EPSG/0/4326": {
    proj4: "+title=WGS 84 (lat/long) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees +axis=neu",
    name: "EPSG:4326",
    ogcWkt: `GEOGCS ["WGS 84",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4326"]]`,
  },
  // Important: Don't use the 28992 reference from epsg.io. It is outdated. See here for more info:
  // https://github.com/proj4js/proj4js/issues/417#issuecomment-1262569013
  "http://www.opengis.net/def/crs/EPSG/0/28992": {
    proj4:
      "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.999908 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs +no_defs",
    name: "EPSG:28992",
    ogcWkt: `PROJCS["Amersfoort / RD New",GEOGCS["Amersfoort",DATUM["Amersfoort",SPHEROID["Bessel 1841",6377397.155,299.1528128],TOWGS84[565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4289"]],PROJECTION["Oblique_Stereographic"],PARAMETER["latitude_of_origin",52.1561605555556],PARAMETER["central_meridian",5.38763888888889],PARAMETER["scale_factor",0.9999079],PARAMETER["false_easting",155000],PARAMETER["false_northing",463000],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AXIS["Easting",EAST],AXIS["Northing",NORTH],AUTHORITY["EPSG","28992"]]`,
  },
  // Important: Don't use the 7415 reference from epsg.io. It is outdated. See here for more info:
  // https://github.com/proj4js/proj4js/issues/417#issuecomment-1262569013
  "http://www.opengis.net/def/crs/EPSG/0/7415": {
    proj4:
      "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.999908 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs +vunits=m +no_defs",
    name: "EPSG:7415",
    ogcWkt: `PROJCS["Amersfoort / RD New",GEOGCS["Amersfoort",DATUM["Amersfoort",SPHEROID["Bessel 1841",6377397.155,299.1528128],TOWGS84[565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4289"]],PROJECTION["Oblique_Stereographic"],PARAMETER["latitude_of_origin",52.1561605555556],PARAMETER["central_meridian",5.38763888888889],PARAMETER["scale_factor",0.9999079],PARAMETER["false_easting",155000],PARAMETER["false_northing",463000],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AXIS["Easting",EAST],AXIS["Northing",NORTH],AUTHORITY["EPSG","7415"]]`,
  },
};

// Note that to add a EPSG CRS to this list, you have to copy paste it from the generated ones.
const commonCrs = {
  ...correctedCrs,
  "http://www.opengis.net/def/crs/OGC/1.3/CRS84": CRS84,
  "http://www.opengis.net/def/crs/EPSG/0/4978": { ...CRS84, name: "EPSG:4978" },
};

export default commonCrs;
