import type * as React from "react";
import { useEffect, useRef } from "react";

function tabbedOutsideEl(ref: React.RefObject<HTMLElement | undefined | null>, event: React.KeyboardEvent) {
  if (event.code === "Tab" || event.key === "Tab") {
    if (!ref.current) return true;
    if (!ref.current.contains(event.target as Node)) {
      return true;
    }
  }
  return false;
}
/**
 * useTabOutside will often react to propogating events from the previous page.
 * If you notice that it fires unexpectedly, use a 'e.stopPropagtion()' on the event that triggers it.
 *
 */
export default function useTabOutside<T extends HTMLElement>(
  callback: (event: React.KeyboardEvent) => void,
  ref: React.RefObject<T | null>,
) {
  const callbackRef = useRef(callback);
  /**
   * Update callback if it changes
   */
  useEffect(() => {
    callbackRef.current = callback;
  });

  /**
   * Add and remove event listeners
   */
  useEffect(() => {
    const listener = (event: any) => {
      const outside = tabbedOutsideEl(ref, event);
      if (!outside) return;

      callbackRef.current(event);
    };

    document.addEventListener("keyup", listener);

    return () => {
      document.removeEventListener("keyup", listener);
    };
  });
}
