import { stringifyQuery } from "@core/utils";
import { Badge, Divider, IconButton, LinearProgress } from "@mui/material";
import getClassName from "classnames";
import platform from "platform";
import * as React from "react";
import { useSelector } from "react-redux";
import type { ResizeCallbackData } from "react-resizable";
import { ResizableBox } from "react-resizable";
import { mergePrefixArray } from "@triply/utils/prefixUtils";
import FontAwesomeIcon from "#components/FontAwesomeIcon/index.tsx";
import ForwardedDragHandle from "#components/ForwardedDragHandle/index.tsx";
import { Button, FontAwesomeButton } from "#components/index.ts";
import ConfigurableSparqlVisualization from "#components/Sparql/QueryResults/ConfigurableSparqlVisualziation.tsx";
import type {
  VisualizationConfig,
  VisualizationLabel,
  VisualizationProperties,
} from "#components/Sparql/QueryResults/index.tsx";
import {
  isGeoCssApplicable,
  isNetworkCssApplicable,
  isResponseCssApplicable,
  isTimelineCssApplicable,
} from "#components/Sparql/SparqlUtils.ts";
import { KeyboardShortcutContext } from "#containers/Hotkeys/index.tsx";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useSparqlQuery from "#helpers/hooks/useSparqlQuery.tsx";
import type { GlobalState } from "#reducers/index.ts";
import type { TermAutocompleteFunction } from "../../components/Sparql/Editor/autocompleters/termAutocomplete";
import { ContextualEditor, useContextualEditor } from "../../components/Sparql/Editor/EditorContext";
import type { PrefixUpdate } from "../../reducers/datasetManagement";
import { DEFAULT_QUERY } from "../SparqlIde/useSparqlIDEContext";
import { useSavedQuery } from "./SavedQueryContext";
import * as styles from "./style.scss";

const buttonAnchor = {
  vertical: "bottom",
  horizontal: "right",
} as const;

const savedQueryVisualizationProperties: VisualizationProperties = {
  Gallery: {
    minimizeColumns: true,
    reduceSpacing: false,
  },
  Table: {
    hideFilters: false,
    hidePagination: false,
  },
};

export interface Props {
  adjustQueryBeforeRequest: React.MutableRefObject<(query: string) => string>;
  onCreatePrefix?: (prefix: PrefixUpdate) => Promise<true | string>;
  visualization: VisualizationLabel;
  visualizationConfig: VisualizationConfig | undefined;
  setVisualization: (visualization: VisualizationLabel) => void;
  setVisualizationConfig: (visualizationConfig: VisualizationConfig) => void;
  isDraft: boolean;
  variableNamesFromConfig: string[];
  executeOnLoad: boolean;
}
const Editor: React.FC<Props> = ({
  adjustQueryBeforeRequest,
  visualization,
  visualizationConfig,
  setVisualization,
  setVisualizationConfig,
  onCreatePrefix,
  variableNamesFromConfig,
  isDraft,
  executeOnLoad,
}) => {
  const constructUrlToApi = useConstructUrlToApi();
  const formRef = React.useRef<HTMLFormElement>(null);
  const globalPrefixes = useSelector((state: GlobalState) => state.config.clientConfig?.prefixes || []);
  const [showLargeResult, setShowLargeResult] = React.useState(false);
  const { datasetPrefixes, endpoint, termsUrl, datasetPath, queryName, query } = useSavedQuery();
  const {
    executeQuery,
    data,
    error,
    loading,
    queryDuration,
    queryDelay,
    cache,
    rawResponse,
    abort,
    responseSize,
    zeroResultOperationLocations,
  } = useSparqlQuery({
    endpoint: endpoint ? constructUrlToApi({ fullUrl: endpoint }) : "",
    savedQueryId: isDraft ? undefined : query.id,
    checkLimits: !showLargeResult,
  });
  const { getQueryString, getPrefixesFromQuery, valid } = useContextualEditor();
  const [dragging, setDragging] = React.useState(false);
  const autoFormatContainerRef = React.useRef<HTMLDivElement>(null);
  const [editorHeight, setEditorHeight] = React.useState(300);
  const [queryHeight, setQueryHeight] = React.useState(300); // Local state for maintaining the actual code mirror editor height for auto resize
  const optimalQueryHeight = queryHeight + 20;
  const { openShortcutDialog } = React.useContext(KeyboardShortcutContext);
  const hasVariables = variableNamesFromConfig.length > 0;
  const [localRenderConfig, setRenderConfig] = React.useState<{ [key: string]: VisualizationConfig }>(() => {
    if (visualization && visualizationConfig) {
      return {
        [visualization]: visualizationConfig,
      };
    } else {
      return {};
    }
  });
  const setSize = React.useCallback((_e: React.SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
    setEditorHeight(data.size.height);
  }, []);
  const startDrag = React.useCallback(() => {
    setDragging(true);
  }, []);
  const stopDrag = React.useCallback(() => {
    setDragging(false);
  }, []);
  const onVisualizationChange = React.useCallback(
    (visualization: VisualizationLabel) => {
      setVisualization(visualization);
      setVisualizationConfig(localRenderConfig[visualization]);
    },
    [localRenderConfig, setVisualization, setVisualizationConfig],
  );
  const onVisualizationUpdate = React.useCallback(
    (plugin: VisualizationLabel, pluginConfig: VisualizationConfig) => {
      setVisualization(plugin);
      setVisualizationConfig(pluginConfig);
      setRenderConfig((config) => ({
        ...config,
        [plugin]: pluginConfig,
      }));
    },
    [setVisualizationConfig, setVisualization],
  );

  const globalAndDsPrefixes = React.useMemo(
    () => mergePrefixArray(globalPrefixes, datasetPrefixes || []),
    [globalPrefixes, datasetPrefixes],
  );
  // Added a check for data, as getPrefixesFromQuery doesn't trigger rerender
  // We want to trigger it each time the query is executed to recalculate the prefixes
  const prefixes = React.useMemo(
    () => (data ? mergePrefixArray(globalAndDsPrefixes, getPrefixesFromQuery?.() || []) : []),
    [globalAndDsPrefixes, getPrefixesFromQuery, data],
  );

  const searchTerms: TermAutocompleteFunction = React.useCallback(
    async (searchString, position) => {
      return fetch(
        `${termsUrl}?${stringifyQuery({ q: searchString, ...(position === "all" ? {} : { pos: position }) })}`,
      ).then((res) => res.json());
    },
    [termsUrl],
  );

  React.useEffect(() => {
    const query = getQueryString();
    if (executeOnLoad && !data && adjustQueryBeforeRequest && endpoint !== "" && query) {
      // Possibly refactor the hook to add the query as we get rid of this
      // Though we need to add a state for the "executed query"
      executeQuery(hasVariables ? adjustQueryBeforeRequest.current(query) : query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitQuery = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (endpoint === "") return;
      if (loading) {
        abort();
      } else {
        executeQuery(hasVariables ? adjustQueryBeforeRequest.current(getQueryString()) : getQueryString());
      }
    },
    [endpoint, loading, abort, executeQuery, hasVariables, adjustQueryBeforeRequest, getQueryString],
  );

  const submitForm = React.useCallback(() => {
    formRef.current?.requestSubmit();
    return true;
  }, []);

  return (
    <div className={getClassName(styles.resultsElement, { [styles.dragging]: dragging })}>
      <form className={styles.newEditor} ref={formRef} onSubmit={submitQuery}>
        <Divider />
        {loading && <LinearProgress className={styles.loadingBar} />}
        <div className={getClassName("flex", styles.bar)}>
          <IconButton
            size="medium"
            onClick={openShortcutDialog}
            aria-label="Show keyboard shortcuts"
            title={`Show keyboard shortcuts (${platform.os?.family?.startsWith("OS") ? "⌘" : "Ctrl"}+Shift+/)`}
            aria-keyshortcuts="Control+Shift+/ Command+Shift+/"
          >
            <FontAwesomeIcon icon="keyboard" />
          </IconButton>
          <div ref={autoFormatContainerRef} className="flex" />
          <Badge
            className="pl-2"
            color="error"
            overlap="circular"
            title={endpoint === "" ? "No endpoint available" : valid !== false ? undefined : "Invalid query"}
            anchorOrigin={buttonAnchor}
            badgeContent={!valid || endpoint === "" ? <FontAwesomeIcon size="sm" icon="exclamation-triangle" /> : null}
          >
            <Button
              color="primary"
              aria-label={loading ? "Abort query" : "Execute query"}
              title={`${loading ? "Abort" : "Execute"} query (${platform.os?.family?.startsWith("OS") ? "⌘" : "Ctrl"}+Enter)`}
              aria-keyshortcuts="Control+Enter Command+Enter"
              disabled={endpoint === ""}
              className={styles.executeQueryButton}
              type="submit"
              startIcon={
                <FontAwesomeIcon
                  icon={loading ? ["fas", "stop"] : ["fas", "play"]}
                  beat={loading}
                  className={styles.playIcon}
                  size="3x"
                />
              }
            />
          </Badge>
        </div>
        <Divider />
        <ResizableBox
          height={editorHeight}
          axis="y"
          minConstraints={[0, 0]}
          maxConstraints={[Infinity, Infinity]}
          resizeHandles={["s"]}
          onResizeStart={startDrag}
          onResizeStop={stopDrag}
          onResize={setSize}
          className={styles.dragContainer}
          handle={<ForwardedDragHandle dragging={dragging} />}
        >
          <>
            <ContextualEditor
              initialValue={getQueryString() || DEFAULT_QUERY}
              searchTerms={searchTerms}
              prefixes={globalAndDsPrefixes}
              className={styles.resizableEditor}
              variables={variableNamesFromConfig}
              onSubmitQuery={submitForm}
              updateEditorHeight={setQueryHeight}
              noResultRanges={hasVariables ? undefined : zeroResultOperationLocations}
              autoFormatButtonContainer={autoFormatContainerRef.current}
            />
            {editorHeight !== optimalQueryHeight && (
              <div className={styles.autoResizeContainer}>
                <div
                  className={getClassName(
                    styles.autoResize,
                    editorHeight > queryHeight ? styles.autoResizeNoBg : styles.autoResizeWhiteBg,
                  )}
                >
                  <FontAwesomeButton
                    icon={editorHeight > optimalQueryHeight ? "chevron-up" : "chevron-down"}
                    title="Set editor height to the query size"
                    onClick={() => {
                      setEditorHeight(optimalQueryHeight);
                    }}
                  />
                </div>
              </div>
            )}
          </>
        </ResizableBox>
      </form>
      <ConfigurableSparqlVisualization
        queryName={queryName}
        selectorClassName={styles.newEditor}
        visualization={visualization}
        setCurrentVisualization={onVisualizationChange}
        data={data}
        rawData={rawResponse}
        error={error}
        loading={loading}
        duration={queryDuration}
        delay={queryDelay}
        cache={cache}
        visualizationClassName={getClassName({
          [styles.resultsGeo]: isGeoCssApplicable(visualization),
          [styles.resultsTimeline]: isTimelineCssApplicable(visualization),
          [styles.resultsNetwork]: isNetworkCssApplicable(visualization),
          [styles.resultsResponse]: isResponseCssApplicable(visualization),
        })}
        onCreatePrefix={onCreatePrefix}
        prefixes={prefixes}
        datasetPath={datasetPath}
        visualizationConfig={localRenderConfig[visualization]}
        setVisualizationConfig={onVisualizationUpdate}
        visualizationProperties={savedQueryVisualizationProperties}
        skipResultsCheck={showLargeResult}
        onPostProcess={() => setShowLargeResult(true)}
        responseSize={responseSize}
      />
    </div>
  );
};
export default React.memo(Editor);
