import { fromPairs } from "lodash-es";
import { Converter } from "sparqljson-to-tree";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";

const factory = factories.compliant;
const converter = new Converter({ materializeRdfJsTerms: true });

export const getSparqlBasedConstraints = async (
  classIri: string,
  sparqlUrl: string,
  datasetName: string,
  datasetOwnerName: string,
): Promise<
  {
    id: string;
    query: string;
    message?: string;
    prefixDeclarations?: {
      prefix: string;
      namespace: string;
    }[];
  }[]
> => {
  const constraintsQuery = `
        # Get SPARQL-based constraints

        prefix owl: <http://www.w3.org/2002/07/owl#>
        prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        prefix sh: <http://www.w3.org/ns/shacl#>
        prefix xsd: <http://www.w3.org/2001/XMLSchema#>
        
        select
          ?id
          ?query
          ?message
          ?prefixDeclarations_prefix
          ?prefixDeclarations_namespace
        where {
          bind(${termToString(factory.namedNode(classIri))} as ?currentClass)
          ?currentClass rdfs:subClassOf*/^sh:targetClass/sh:sparql ?id .
          ?id sh:select ?query .

          optional {
            ?id sh:message ?message
          }
          optional {
            ?id sh:prefixes/owl:imports*/sh:declare [
              sh:prefix ?prefixDeclarations_prefix ;
              sh:namespace ?prefixDeclarations_namespace
            ]
            filter(datatype(?prefixDeclarations_prefix) = xsd:string)
            filter(datatype(?prefixDeclarations_namespace) = xsd:anyURI)

          }
        }
    `;
  const constraintsResponse = await fetch(sparqlUrl, {
    credentials: "same-origin",
    method: "POST",
    headers: { Accept: "application/sparql-results+json" },
    body: new URLSearchParams({
      account: datasetOwnerName,
      dataset: datasetName,
      queryString: constraintsQuery,
    }),
  });

  const json = await constraintsResponse.json();

  const result = converter.sparqlJsonResultsToTree(json, {
    singularizeVariables: fromPairs(json.head.vars.map((v: any) => [v, true])),
  });

  return result;
};
