import { Alert } from "@mui/material";
import * as React from "react";
import type { DashViewer, DashViewerProps } from ".";

const SUPPORTED_IMAGE_EXTENSIONS = ["png", "apng", "avif", "gif", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "svg", "webp"];

const ImageViewerComponent: React.FC<DashViewerProps> = ({ property }) => {
  const [failedLoading, setFailedLoading] = React.useState(false);
  if (failedLoading) return <Alert severity="error">Failed to load image. Source: "{property.value}"</Alert>;
  return <img alt={property.value} src={property.value} onError={() => setFailedLoading(true)} />;
};

const ImageViewer: DashViewer = {
  component: ImageViewerComponent,
  getScore({ value }) {
    try {
      const parsed = new URL(value);
      if (SUPPORTED_IMAGE_EXTENSIONS.includes(parsed.pathname.split(".").pop() || "")) {
        return 50;
      }
    } catch {
      return 0;
    }
    return 0;
  },
};

export default ImageViewer;
