import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import type { Scopes, Token } from "@triply/utils/Models";
import type Form from "./index.tsx";

export interface FieldInfo {
  name: string;
  label: string;
  icon: IconProp;
  description: string;
  // disableOn?:string[],
  disabledOn?: string[];
}

export interface Group {
  mainField: FieldInfo;
}

export function tokenGroupsToScopes(formVals: Form.FormData): Scopes {
  return {
    [formVals.authLevel.split("sc_")[1]]: ["a"],
  };
}
export function tokenToAuthLevel(token: Token): string {
  let authLevel = "sc_acc";
  if (!token) return authLevel;
  if (token.scopes.ds && token.scopes.ds.length > 0) authLevel = "sc_ds";
  if (token.scopes.us && token.scopes.us.length > 0) authLevel = "sc_us";

  return authLevel;
}
export function tokenToAuthGroup(token: Token): Group | undefined {
  const authLevel = tokenToAuthLevel(token);
  return groups.find((group) => group.mainField.name === authLevel);
}
export var groups: Group[] = [
  {
    mainField: {
      name: "sc_us",
      icon: "user-edit",
      description: "Allows organizations and users that I manage to be changed.",
      label: "Management access",
    },
  },
  {
    mainField: {
      name: "sc_ds",
      icon: "edit",
      description: "Allows my data and metadata to be changed.",
      label: "Write access",
    },
  },
  {
    mainField: {
      name: "sc_acc",
      icon: "book-open",
      description: "Allows my data and metadata to be queried, but not changed.",
      label: "Read access",
    },
  },
];
