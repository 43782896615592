import type { IconPrefix } from "@fortawesome/fontawesome-svg-core";
import type { FontAwesomeIconProps as FaProps } from "@fortawesome/react-fontawesome";
import { FontAwesomeIcon as _FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

namespace FontAwesomeIcon {
  // We do not want the title property. That one causes problems with server side rendering
  // We don't want to be able to set actions directly on the component, as we can't ensure that the buttens/links contain text labels
  export interface Props extends Omit<FaProps, "title" | "onClick" | "href" | "to"> {}
}
const defaultIconPrefix: IconPrefix = "far"; //solid, regular or light
const FontAwesomeIcon: React.FC<FontAwesomeIcon.Props> = (props) => {
  const icon: FontAwesomeIcon.Props["icon"] =
    typeof props.icon === "string" ? [defaultIconPrefix, props.icon] : props.icon;
  return <_FontAwesomeIcon {...props} icon={icon} />;
};
export default FontAwesomeIcon;
