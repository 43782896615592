import getClassName from "classnames";
import * as React from "react";
import type { NtriplyTerm } from "@triply/utils/Models";
import {
  TermLiteralBoolean,
  TermLiteralDefault,
  TermLiteralHTML,
  TermLiteralImage,
  TermLiteralLink,
  TermLiteralNumeric,
  TermLiteralWkt,
} from "#components/index.ts";
import * as styles from "./style.scss";
import * as rowStyles from "#containers/Table/style.scss";

export namespace TermLiteral {
  export interface Props extends NtriplyTerm {
    className?: string;
    expanded?: boolean;
  }
  export interface State {
    showAll: boolean;
  }

  //Hacky interface so we can define a static function in an interface
  export interface TermLiteralRenderer {
    new (props?: TermLiteral.Props): React.PureComponent<TermLiteral.Props, any>;
    shouldRender(props: TermLiteral.Props): boolean;
  }
}

/**
 * Order matters! I.e., recommend the last one is a catch-all
 */

//used for e.g. IRIs and graphnames
export class TermLiteral extends React.PureComponent<TermLiteral.Props, TermLiteral.State> {
  LiteralRenderers = [
    TermLiteralHTML,
    TermLiteralBoolean,
    TermLiteralWkt,
    TermLiteralNumeric,
    TermLiteralImage,
    TermLiteralLink,
    TermLiteralDefault,
  ];

  constructor(props: TermLiteral.Props) {
    super(props);
    this.state = {
      showAll: false,
    };
  }

  renderLiteral() {
    for (const Renderer of this.LiteralRenderers) {
      if (Renderer.shouldRender(this.props)) return <Renderer {...this.props} />;
    }
    return null;
  }

  render() {
    const { className, datatype, language } = this.props;
    return (
      <div className={getClassName(className, styles.wrapper)}>
        {this.renderLiteral()}
        <div>
          {language ? (
            <span className={getClassName("literalInfo", styles.language, rowStyles.rowHover)}>@{language}</span>
          ) : (
            <a
              href={datatype}
              className={getClassName("literalInfo", styles.extLink, rowStyles.rowHover)}
              target="_blank"
              rel="noopener noreferrer"
              title={"Open external link in new window"}
            >
              {datatype}
            </a>
          )}
        </div>
      </div>
    );
  }
}
export default TermLiteral;
