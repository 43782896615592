import { Alert, FormControl, FormHelperText, ToggleButton, ToggleButtonGroup, Toolbar } from "@mui/material";
import getClassName from "classnames";
import { fromPairs, isEqual, some, toPairs } from "lodash-es";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import type { Models } from "@triply/utils";
import { mergePrefixArray, type Prefix } from "@triply/utils/prefixUtils";
import { Button, FontAwesomeIcon, LoadingButton, Markdown } from "#components/index.ts";
import type {
  VisualizationConfig,
  VisualizationLabel,
  VisualizationProperties,
} from "#components/Sparql/QueryResults/index.tsx";
import SparqlResults from "#components/Sparql/QueryResults/index.tsx";
import {
  extractQueryPrefixes,
  getIdeVisualization,
  isConstructResponse,
  isIdeVisualization,
  isResponseCssApplicable,
  isSelectResponse,
  yasguiVisualizationConfigToIdeVisualizationConfig,
} from "#components/Sparql/SparqlUtils.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useSparqlQuery from "#helpers/hooks/useSparqlQuery.tsx";
import { parseSearchString } from "#helpers/utils.ts";
import type { GlobalState } from "#reducers/index.ts";
import { QueryVariableField } from "../../components/QueryVariableUtils/QueryVariableField.tsx";
import { useAdjustQueryBeforeRequest } from "../../components/QueryVariableUtils/useAdjustQueryBeforeRequest.ts";
import useOpenLinkInCurrentWindow from "./useOpenLinkInCurrentWindow.ts";
import * as styles from "./Query.scss";

function shouldAddMaxHeight(visualization: VisualizationLabel, config: VisualizationConfig) {
  if (visualization === "Pivot") return true;
  if (visualization === "Charts" && (config === undefined || ("chartType" in config && config.chartType === "Table")))
    return true;
  if (visualization === "Response") return true;
  return false;
}

const storiesVisualizationProperties: VisualizationProperties = {
  Gallery: {
    minimizeColumns: true,
    reduceSpacing: true,
  },
  Table: {
    hideFilters: true,
    hidePagination: true,
  },
  Charts: {
    reducePadding: true,
  },
};

function getDefaultHeight(visualization: VisualizationLabel): Models.StoryElementHeight | undefined {
  if (visualization === "Geo") return "medium";
  if (visualization === "Network") return "small";
  if (visualization === "Timeline") return "medium";
  return undefined;
}
function canApplyHeight(visualization: VisualizationLabel): boolean {
  return visualization === "Geo" || visualization === "Network" || visualization === "Timeline";
}

function getHeightClass(height: Models.StoryElementHeight | undefined) {
  if (!height) return undefined;
  if (height === "large") return styles.resultsHeightLarge;
  if (height === "medium") return styles.resultsHeightDefault;
  if (height === "small") return styles.resultsHeightSmall;
}

export interface Props {
  storyElement: Models.StoryElementQuery;
  editMode?: boolean;
  handleStoryElementHeightChange: (storyElementId: string, width: Models.StoryElementHeight) => void;
  handleStoryElementWidthChange: (storyElementId: string, width: Models.StoryElementWidth) => void;
}

export type TestValues = { [variableName: string]: string | undefined };

const equalTestValues = (a: TestValues, b: TestValues | undefined) => {
  // We have to filter out empty strings from the comparison, since they mean the same as undefined.
  // For example {} and { a: "" } should be considered the same
  if (b === undefined) return false;
  return isEqual(fromPairs(toPairs(a).filter((v) => !!v[1])), fromPairs(toPairs(b).filter((v) => !!v[1])));
};

const QueryVars: React.FC<{
  query: Models.Query;
  testValues: TestValues;
  setTestValues: (testValues: TestValues) => void;
  runQuery: () => void;
  runningQuery: boolean;
  prefixes: Prefix[];
  resultTestValues: TestValues | undefined;
}> = ({ query, testValues, setTestValues, runQuery, runningQuery, resultTestValues, prefixes }) => {
  const emptyRequiredField = some(
    query.variables,
    (queryVariable) => queryVariable.required && !testValues[queryVariable.name],
  );
  const getQueryString = React.useCallback(
    () => query.requestConfig?.payload.query,
    [query.requestConfig?.payload.query],
  );

  return (
    <form className="flex wrap my-3">
      <div className={styles.fields}>
        {query.variables?.map((variableDefinition) => (
          <div key={variableDefinition.name} className={getClassName(styles.queryVariable, "flex center wrap my-3")}>
            <QueryVariableField
              testValue={testValues[variableDefinition.name]}
              onTestValueChange={(value) => setTestValues({ ...testValues, [variableDefinition.name]: value })}
              variableDefinition={variableDefinition}
              datasetPath={`${query.dataset?.owner.accountName}/${query.dataset?.name}`}
              getQueryString={getQueryString}
              prefixes={prefixes}
            />
          </div>
        ))}
      </div>
      <div className={getClassName(styles.buttons, "flex my-2")}>
        <Button
          onClick={() => {
            setTestValues({});
            if (!equalTestValues({}, resultTestValues)) {
              setTimeout(runQuery, 0);
            }
          }}
          size="small"
          variant="text"
          disabled={runningQuery || !some(testValues, (t) => !!t)}
          className="ml-4 resetButton"
          drawTextInSpan
        >
          Reset
        </Button>
        <LoadingButton
          onClick={(e) => {
            e.preventDefault();
            runQuery();
          }}
          pulse
          type="submit"
          size="small"
          loading={runningQuery}
          startIcon={<FontAwesomeIcon icon={["fas", "play"]} />}
          disabled={emptyRequiredField || equalTestValues(testValues, resultTestValues)}
          className="ml-2 runQueryButton"
          drawTextInSpan
        >
          Run query
        </LoadingButton>
      </div>
    </form>
  );
};

const Query: React.FC<Props> = ({
  storyElement,
  editMode,
  handleStoryElementHeightChange,
  handleStoryElementWidthChange,
}) => {
  const [testValues, setTestValues] = React.useState<TestValues>({});
  const getInternalPath = useConstructUrlToApi();
  const getTestValues = React.useRef(() => testValues);
  getTestValues.current = () => testValues;
  const resultTestValues = React.useRef<TestValues>(undefined);

  const openLinkInCurrentWindow = useOpenLinkInCurrentWindow();

  const globalPrefixes = useSelector((state: GlobalState) => state.config.clientConfig?.prefixes || []);

  const prefixes = React.useMemo(() => {
    const globalAndDsPrefixes = mergePrefixArray(storyElement.query?.dataset?.prefixes || [], globalPrefixes);
    return mergePrefixArray(
      extractQueryPrefixes(storyElement.query?.requestConfig?.payload.query),
      globalAndDsPrefixes,
    );
  }, [globalPrefixes, storyElement.query?.dataset?.prefixes, storyElement.query?.requestConfig?.payload.query]);

  const sparqlEndpoint = storyElement.query?.service;

  const adjustQueryBeforeRequest = useAdjustQueryBeforeRequest(testValues, storyElement.query?.variables);

  const { executeQuery, data, error, loading, rawResponse } = useSparqlQuery({
    endpoint: sparqlEndpoint ? getInternalPath({ fullUrl: sparqlEndpoint }) : "",
    savedQueryId: storyElement.query ? storyElement.query.id : undefined,
  });
  const showingWarning =
    !!error ||
    (data !== undefined &&
      (isConstructResponse(data)
        ? data.length === 0
        : isSelectResponse(data)
          ? data.results.bindings.length === 0
          : false));
  const queryLink = `/${storyElement.query?.owner.accountName}/-/queries/${storyElement.query?.name}/${storyElement.query?.version}`;
  const datasetLink = `/${storyElement.query?.dataset?.owner.accountName}/${storyElement.query?.dataset?.name}`;
  const queryId = React.useId();

  const embedded = parseSearchString(useLocation().search).embed !== undefined;
  const linkTarget = embedded ? "_blank" : "_self";

  const staticConfig = useSelector((state: GlobalState) => state.config.staticConfig);

  const execQuery = React.useCallback(() => {
    if (storyElement.query?.id && sparqlEndpoint && storyElement.query.requestConfig?.payload.query) {
      resultTestValues.current = { ...getTestValues.current() };
      executeQuery(adjustQueryBeforeRequest.current(storyElement.query.requestConfig.payload.query));
    }
  }, [
    adjustQueryBeforeRequest,
    executeQuery,
    sparqlEndpoint,
    storyElement.query?.id,
    storyElement.query?.requestConfig?.payload.query,
  ]);

  React.useEffect(() => {
    execQuery();
  }, [execQuery]);

  if (storyElement.query?.requestConfig?.ldFrame) {
    return (
      <div className={styles.constrainWidth}>
        <Alert severity="warning">LD-Frames are not supported in stories</Alert>
      </div>
    );
  }

  const visualization = getIdeVisualization(storyElement.query?.renderConfig?.output) || "Table";
  const visualizationConfig: VisualizationConfig =
    storyElement.query &&
    (isIdeVisualization(storyElement.query?.renderConfig?.output || "Table")
      ? storyElement.query?.renderConfig?.settings
      : yasguiVisualizationConfigToIdeVisualizationConfig(
          storyElement.query.renderConfig?.output || "table",
          storyElement.query.renderConfig?.settings,
          staticConfig,
        ));

  const queryWidth = storyElement?.width || "default";
  const queryHeight = storyElement?.height || getDefaultHeight(visualization);

  return (
    <>
      {storyElement.query && (
        <Toolbar variant="dense" className={getClassName(styles.constrainWidth, "mb-3")}>
          {editMode && (
            <form aria-controls={queryId} aria-label="Size controls">
              <FormControl size="small">
                <FormHelperText>Width</FormHelperText>
                <ToggleButtonGroup
                  size="small"
                  value={queryWidth}
                  exclusive
                  onChange={(_, value: Models.StoryElementWidth) =>
                    handleStoryElementWidthChange(storyElement.id, value)
                  }
                >
                  <ToggleButton value="default">
                    <FontAwesomeIcon icon="square" />
                  </ToggleButton>
                  <ToggleButton value="fullWidth">
                    <FontAwesomeIcon icon="rectangle-wide" />
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
              {canApplyHeight(visualization) && (
                <FormControl size="small" className="ml-2">
                  <FormHelperText>Height</FormHelperText>
                  <ToggleButtonGroup
                    size="small"
                    value={queryHeight}
                    exclusive
                    onChange={(_, value: Models.StoryElementHeight) =>
                      handleStoryElementHeightChange(storyElement.id, value)
                    }
                  >
                    <ToggleButton value="small">
                      <FontAwesomeIcon icon={["fas", "square-small"]} />
                    </ToggleButton>
                    <ToggleButton value="medium">
                      <FontAwesomeIcon icon={["fas", "square"]} />
                    </ToggleButton>
                    <ToggleButton value="large">
                      <FontAwesomeIcon icon={["fas", "square-full"]} />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
              )}
            </form>
          )}

          <div className={getClassName(styles.ideLinks, "mb-2", "queryActions")}>
            {storyElement.query.dataset && (
              <Button
                size="small"
                variant="text"
                LinkComponent={React.forwardRef(({ href, ...props }, _ref) => (
                  <Link {...props} to={href} />
                ))}
                title={`Go to dataset '${
                  storyElement.query.dataset.displayName || storyElement.query.dataset.name
                }' by ${storyElement.query.dataset.owner.name || storyElement.query.dataset.owner.accountName}`}
                href={datasetLink}
              >
                go to Dataset
              </Button>
            )}
            {storyElement.query.service && !editMode ? (
              <Button
                size="small"
                variant="text"
                href={queryLink}
                LinkComponent={React.forwardRef(({ href, ...props }, _ref) => (
                  <Link
                    {...props}
                    to={{
                      pathname: href,
                      state: { queryVariableTestValues: testValues },
                    }}
                    target={linkTarget}
                  />
                ))}
                title={`Try query '${storyElement.query.displayName || storyElement.query.name}' by ${
                  storyElement.query.owner.name || storyElement.query.owner.accountName
                }`}
                className="mx-2"
              >
                Try this query yourself
              </Button>
            ) : (
              <Button
                size="small"
                variant="text"
                href={queryLink}
                LinkComponent={React.forwardRef(({ href, ...props }, _ref) => (
                  <Link {...props} to={href} target={linkTarget} />
                ))}
                title={`Go to query '${storyElement.query.displayName || storyElement.query.name}' by ${
                  storyElement.query.owner.name || storyElement.query.owner.accountName
                }`}
                className="mx-2"
              >
                Go to query
              </Button>
            )}
          </div>
        </Toolbar>
      )}
      <figure className={getClassName(styles.query)}>
        {storyElement.query && (storyElement.query.variables?.length || 0) > 0 && (
          <div className={styles.constrainWidth}>
            <QueryVars
              query={storyElement.query}
              testValues={testValues}
              setTestValues={setTestValues}
              runQuery={() => {
                execQuery();
              }}
              runningQuery={loading}
              resultTestValues={resultTestValues.current}
              prefixes={prefixes}
            />
          </div>
        )}

        {/* QUERY RESULTS */}
        <div
          className={getClassName(styles.visualization, {
            [styles.resultsWide]: !showingWarning && queryWidth === "fullWidth",
          })}
          id={queryId}
        >
          {sparqlEndpoint && storyElement.query && storyElement.query.dataset ? (
            <SparqlResults
              className={getClassName(!showingWarning && canApplyHeight(visualization) && getHeightClass(queryHeight), {
                [styles.limitHeight]: !showingWarning && shouldAddMaxHeight(visualization, visualizationConfig),
                [styles.resultsResponse]: !showingWarning && isResponseCssApplicable(visualization),
              })}
              error={error}
              prefixes={prefixes}
              datasetPath={`${storyElement.query.dataset.owner.accountName}/${storyElement.query.dataset.name}`}
              data={data}
              rawData={rawResponse}
              visualization={visualization}
              visualizationConfig={visualizationConfig}
              customNoResultsMessage={`No information was found. ${
                (storyElement.query.variables?.length || 0) > 0
                  ? "Try changing your input values and run the query again; or contact the story author."
                  : "Contact the story author."
              }`}
              customErrorMessage="An error was encountered while trying to retrieve the requested information. Contact the story author if this problem persists."
              visualizationProperties={storiesVisualizationProperties}
            />
          ) : (
            <div className={styles.cannotShowQuery}>
              <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className={styles.warningIcon} />
              {`Could not show query result, the ${
                !storyElement.query ? "query" : !storyElement.query.dataset ? "dataset" : "SPARQL endpoint"
              } is not accessible.`}
            </div>
          )}
        </div>
        {storyElement.caption && (
          <figcaption className={getClassName(styles.caption, styles.constrainWidth, "mt-3 mx-2")}>
            <Markdown openLinkInCurrentWindow={openLinkInCurrentWindow} compact>
              {storyElement.caption}
            </Markdown>
          </figcaption>
        )}
      </figure>
    </>
  );
};

export default Query;
