export function parse(iri: string) {
  return new Iri(new ElementPositions(iri));
}

/**
 * Validate an IRI, and throw an error if it is syntactically invalid
 */
export function validate(iri: string) {
  parse(iri);
}

/**
 * Resolve and normalize an IRI. This will rewrite '../../' path segments, and prepend the base IRI when applicable
 */
export function resolve(_iri: string): never {
  throw new Error(' Function "resolve" is not implemented yet.'); // See #7629
}

class Iri {
  public positions: ElementPositions;

  constructor(positions: ElementPositions) {
    this.positions = positions;
  }
  public get iri() {
    return this.positions.iri;
  }
  public get scheme(): string | undefined {
    if (this.positions.schemeEnd === 0) return;
    return this.iri.substring(0, this.positions.schemeEnd - 1);
  }

  public get authority(): string | undefined {
    if (this.positions.schemeEnd + 2 > this.positions.authorityEnd) return;
    return this.iri.substring(this.positions.schemeEnd + 2, this.positions.authorityEnd);
  }

  public get path(): string | undefined {
    if (this.positions.authorityEnd >= this.positions.pathEnd) return;
    return this.iri.substring(this.positions.authorityEnd, this.positions.pathEnd);
  }

  public get query(): string | undefined {
    if (this.positions.pathEnd >= this.positions.queryEnd) return;
    return this.iri.substring(this.positions.pathEnd + 1, this.positions.queryEnd);
  }

  public get fragment(): string | undefined {
    if (this.positions.queryEnd >= this.iri.length) return;
    return this.iri.substring(this.positions.queryEnd + 1);
  }
}

class ParserInput {
  value: string;
  letterPosition: number = 0;

  constructor(value: string) {
    this.value = value;
  }

  public front(): string {
    return this.value[0];
  }

  public startsWith(c: string): boolean {
    return this.value[this.letterPosition] === c;
  }
  public startsWithSlash(): boolean {
    return this.value[this.letterPosition] === "/";
  }
}

// note:
//  - we keep the regex at the top level, because then we don't keep reconstructing it during runtime.
//    this saves us some cpu.
//  - since we reuse the same regex, we don't use the `g` flag, because that makes the regex stateful,
//    and we don't intend for the state to be carried over between usages (see https://stackoverflow.com/a/35355828)
//  - also, `g` is not necessary since we are only looking for one match, after which we can stop.
//  - `i` flag is not needed since the regex already appears to account for casing.
const hostRegexp =
  /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/;

class ElementPositions {
  public iri: string;
  private base: ElementPositions | undefined;
  private input: ParserInput;
  private output = "";
  private inputSchemeEnd = 0;
  public schemeEnd = 0;
  public authorityEnd = 0;
  public pathEnd = 0;
  public queryEnd = 0;
  constructor(iri: string, base?: string) {
    this.iri = iri;
    if (base) this.base = new ElementPositions(base);
    this.input = new ParserInput(iri);
    this.parse();
    if (this.schemeEnd === 0) throw new Error(`Unable to parse relative IRI without a base IRI: <${iri}>`);
  }

  private parse() {
    const c = this.input.front();

    if (c === ":") {
      throw new Error(`The IRI does not have a schema.`);
    } else if (onlyLatinCharacters(c)) {
      this.parseScheme();
    } else {
      this.parseRelative();
    }
  }
  private parsePathOrAuthority() {
    if (this.input.startsWithSlash()) {
      ++this.input.letterPosition;
      this.output += "/";
      this.parseAuthority();
      return;
    } else {
      this.authorityEnd = this.output.length - 1;
      this.parsePath();
      return;
    }
  }

  private parseScheme() {
    while (true) {
      const c = this.input.value[this.input.letterPosition++];

      if (is_ascii_alphanumeric(c) || c === "+" || c === "-" || c === ".") {
        this.output += c;
      } else if (c === ":") {
        this.output += ":";

        this.schemeEnd = this.output.length;
        this.inputSchemeEnd = this.input.letterPosition;

        if (this.input.startsWithSlash()) {
          ++this.input.letterPosition;
          this.output += "/";
          this.parsePathOrAuthority();
          return;
        } else {
          this.authorityEnd = this.output.length;
          this.parsePath();
          return;
        }
      } else {
        this.input = new ParserInput(this.iri);
        this.output = "";
        this.parseRelative();
        return;
      }
    }
  }

  private parseRelative() {
    if (this.base) {
      switch (this.input.front()) {
        case undefined: {
          this.output += this.base.iri.substring(0, this.base.queryEnd);
          this.schemeEnd = this.base.schemeEnd;
          this.authorityEnd = this.base.authorityEnd;
          this.pathEnd = this.base.pathEnd;
          this.queryEnd = this.base.queryEnd;
          return;
        }
        case "/": {
          ++this.input.letterPosition;
          this.parseRelativeSlash(this.base);
          return;
        }
        case "?": {
          ++this.input.letterPosition;
          this.output += this.base.iri.substring(0, this.base.pathEnd) + "?";
          this.schemeEnd = this.base.schemeEnd;
          this.authorityEnd = this.base.authorityEnd;
          this.pathEnd = this.base.pathEnd;
          this.parseQuery();
          return;
        }
        case "#": {
          ++this.input.letterPosition;
          this.output += this.base.iri.substring(0, this.base.queryEnd) + "#";
          this.schemeEnd = this.base.schemeEnd;
          this.authorityEnd = this.base.authorityEnd;
          this.pathEnd = this.base.pathEnd;
          this.queryEnd = this.base.queryEnd;
          this.parseFragment();
          return;
        }
        default: {
          this.schemeEnd = this.base.schemeEnd;
          this.authorityEnd = this.base.authorityEnd;
          this.pathEnd = this.base.pathEnd;
          this.removeLastSegment();
          if (this.output.length > this.base.schemeEnd) {
            this.output += "/";
          }
          this.parsePath();
          return;
        }
      }
    } else {
      this.schemeEnd = 0;
      this.inputSchemeEnd = 0;
      if (this.input.startsWithSlash()) {
        ++this.input.letterPosition;
        this.output += "/";
        this.parsePathOrAuthority();
        return;
      } else {
        this.authorityEnd = 0;
        this.parsePath();
        return;
      }
    }
  }
  private parseRelativeSlash(base: ElementPositions) {
    if (this.input.startsWithSlash()) {
      ++this.input.letterPosition;
      this.output += base.iri.substring(0, base.schemeEnd).split("") + "//";
      this.schemeEnd = base.schemeEnd;
      this.parseAuthority();
      return;
    } else {
      this.output += base.iri.substring(0, base.authorityEnd) + "/";
      this.schemeEnd = base.schemeEnd;
      this.authorityEnd = base.authorityEnd;
      this.parsePath();
      return;
    }
  }
  private parseAuthority() {
    while (true) {
      const c = this.input.value[this.input.letterPosition++];
      switch (c) {
        case "/":
        case "#":
        case "?":
        case "[":
        case undefined: {
          this.input = new ParserInput(this.iri);
          this.input.letterPosition = this.inputSchemeEnd + 2;
          this.output = this.output.slice(0, this.schemeEnd + 2);
          this.parseHost();
          return;
        }
        case "@": {
          this.output += c;
          this.parseHost();
          return;
        }
        default: {
          this.readUrlCodepointOrEchar(c);
        }
      }
    }
  }
  private parseHost() {
    if (this.input.startsWith("[")) {
      const start_position = this.input.letterPosition;
      let c = this.input.value[this.input.letterPosition++];

      while (c) {
        this.output += c;
        if (c === "]") {
          const ip = this.input.value.slice(start_position + 1, this.input.letterPosition - 1);
          if (!hostRegexp.test(ip)) throw new Error(`The IRI includes the invalid host "${ip}".`);

          c = this.input.value[this.input.letterPosition++];

          if (c === ":") {
            this.output += c;
            this.parsePort();
            return;
          } else if (c === "/" || c === "?" || c === "#" || c === undefined) {
            this.authorityEnd = this.output.length;
            this.parsePathStart(c);
            return;
          } else {
            throw new Error(`The IRI includes the invalid host character '${c}'.)`);
          }
        }
        c = this.input.value[this.input.letterPosition++];
      }
      throw new Error(`The IRI includes the invalid host character '['.`);
    } else {
      while (true) {
        const c = this.input.value[this.input.letterPosition++];
        switch (c) {
          case ":": {
            this.output += ":";
            this.parsePort();
            return;
          }
          case undefined:
          case "/":
          case "?":
          case "#": {
            this.authorityEnd = this.output.length;
            this.parsePathStart(c);
            return;
          }
          default: {
            this.readUrlCodepointOrEchar(c);
          }
        }
      }
    }
  }
  private parsePort() {
    while (true) {
      const c = this.input.value[this.input.letterPosition++];

      if (isHexaDigit(c)) {
        this.output += c;
      } else if (c === "/" || c === "?" || c === "#" || c === undefined) {
        this.authorityEnd = this.output.length;
        this.parsePathStart(c);
        return;
      } else {
        throw new Error(`The IRI includes the invalid port character '${c}'.`);
      }
    }
  }
  private parsePathStart(c: string) {
    if (c === undefined) {
      this.pathEnd = this.output.length;
      this.queryEnd = this.output.length;
      return;
    } else if (c === "?") {
      this.pathEnd = this.output.length;
      this.output += "?";
      this.parseQuery();
      return;
    } else if (c === "#") {
      this.pathEnd = this.output.length;
      this.queryEnd = this.output.length;
      this.output += "#";
      this.parseFragment();
      return;
    } else if (c === "/") {
      this.output += "/";
      this.parsePath();
      return;
    } else {
      this.readUrlCodepointOrEchar(c);
      this.parsePath();
      return;
    }
  }

  private parsePath() {
    while (true) {
      const c = this.input.value[this.input.letterPosition++];
      switch (c) {
        case undefined:
        case "/":
        case "?":
        case "#":
          if (this.output.endsWith("/..")) {
            this.removeLastSegment();
            this.removeLastSegment();
            this.output += "/";
          } else if (this.output.endsWith("/.")) {
            this.removeLastSegment();
            this.output += "/";
          } else if (c === "/") {
            this.output += "/";
            break;
          }

          if (c === "?") {
            this.pathEnd = this.output.length;
            this.output += "?";
            this.parseQuery();
            return;
          } else if (c === "#") {
            this.pathEnd = this.output.length;
            this.queryEnd = this.output.length;
            this.output += "#";
            this.parseFragment();
            return;
          } else if (c === undefined) {
            this.pathEnd = this.output.length;
            this.queryEnd = this.output.length;
            return;
          }
          break;

        default: {
          this.readUrlCodepointOrEchar(c);
        }
      }
    }
  }

  private parseQuery() {
    let c = this.input.value[this.input.letterPosition++];
    while (c) {
      if (c === "#") {
        this.queryEnd = this.output.length;
        this.output += "#";
        this.parseFragment();
        return;
      } else {
        this.readUrlCodepointOrQuery(c);
      }
      c = this.input.value[this.input.letterPosition++];
    }
    this.queryEnd = this.output.length;
    return;
  }
  private parseFragment() {
    let c: string;
    while ((c = this.input.value[this.input.letterPosition++])) {
      this.readUrlCodepointOrEchar(c);
    }
    return;
  }
  private removeLastSegment() {
    const last_slash_position = this.output.lastIndexOf("/");
    if (last_slash_position > 0) {
      this.output = this.output.slice(0, last_slash_position + this.authorityEnd);
    }
  }
  private readUrlCodepointOrEchar(c: string) {
    if (c === "%") {
      this.readEchar();
      return;
    } else if (isUrlCodePoint(c)) {
      this.output += c;
      return;
    } else if (possible32BitUnicode.test(c) && isUrlCodePoint32(c + this.input.value[this.input.letterPosition])) {
      this.output += c + this.input.value[this.input.letterPosition];
      this.input.letterPosition++;
      return;
    } else {
      throw new Error(`The IRI includes the invalid character '${c}'.`);
    }
  }
  private readUrlCodepointOrQuery(c: string) {
    if (c === "%") {
      this.readEchar();
      return;
    } else if (isUrlQueryCodePoint(c)) {
      this.output += c;
      return;
    } else if (possible32BitUnicode.test(c) && isUrlQueryCodePoint32(c + this.input.value[this.input.letterPosition])) {
      this.output += c + this.input.value[this.input.letterPosition];
      this.input.letterPosition++;
      return;
    } else {
      throw new Error(`The IRI includes the invalid character '${c}'.`);
    }
  }
  private readEchar() {
    const c1 = this.input.value[this.input.letterPosition++];
    const c2 = this.input.value[this.input.letterPosition++];
    if (isHexaDigit(c1) && isHexaDigit(c2)) {
      this.output += "%" + c1 + c2;
      return;
    } else {
      throw new Error(`The IRI includes the invalid percent-encoding \`%${c1}${c2}\`.`);
    }
  }
}

function onlyLatinCharacters(c: string) {
  return (c >= "a" && c <= "z") || (c >= "A" && c <= "Z");
}

function is_ascii_alphanumeric(c: string) {
  return (c >= "a" && c <= "z") || (c >= "A" && c <= "Z") || (c >= "0" && c <= "9");
}

// see notes on regex near `hostRegexp` variable
const r1 = /[\u{A0}-\u{D7FF}]+/u;
const r2 = /[\u{F900}-\u{FDCF}]+/u;
const r3 = /[\u{FDF0}-\u{FFEF}]+/u;
const r4 = /[\u{10000}-\u{1FFFD}]+/u;
const r5 = /[\u{20000}-\u{2FFFD}]+/u;
const r6 = /[\u{30000}-\u{3FFFD}]+/u;
const r7 = /[\u{40000}-\u{4FFFD}]+/u;
const r8 = /[\u{50000}-\u{5FFFD}]+/u;
const r9 = /[\u{60000}-\u{6FFFD}]+/u;
const r10 = /[\u{70000}-\u{7FFFD}]+/u;
const r11 = /[\u{80000}-\u{8FFFD}]+/u;
const r12 = /[\u{90000}-\u{9FFFD}]+/u;
const r13 = /[\u{A0000}-\u{AFFFD}]+/u;
const r14 = /[\u{B0000}-\u{BFFFD}]+/u;
const r15 = /[\u{C0000}-\u{CFFFD}]+/u;
const r16 = /[\u{D0000}-\u{DFFFD}]+/u;
const r17 = /[\u{E1000}-\u{EFFFD}]+/u;

// This range of unicode characters represent is the first character of the 32-bit unicode characters from \u{10000} to \u{10FFFD}
// And nicely falls in between r1 and r2
const possible32BitUnicode = /[\u{D800}-\u{DBFF}]+/u;

function isUrlCodePoint(c: string): boolean {
  return (
    // alphanumeric, plus certain special symbols.
    // find ranges at e.g. https://www.lookuptables.com/text/ascii-table
    (c >= "a" && c <= "z") ||
    (c >= "?" && c <= "Z") ||
    (c >= "&" && c <= ";") ||
    c === "!" ||
    c === "$" ||
    c === "=" ||
    c === "_" ||
    c === "~" ||
    r1.test(c) ||
    r2.test(c) ||
    r3.test(c)
  );
}
function isUrlCodePoint32(c: string): boolean {
  return (
    r4.test(c) ||
    r5.test(c) ||
    r6.test(c) ||
    r7.test(c) ||
    r8.test(c) ||
    r9.test(c) ||
    r10.test(c) ||
    r11.test(c) ||
    r12.test(c) ||
    r13.test(c) ||
    r14.test(c) ||
    r15.test(c) ||
    r16.test(c) ||
    r17.test(c)
  );
}

const r18 = /[\u{E000}-\u{F8FF}]+/u;
const r19 = /[\u{F0000}-\u{FFFFD}]+/u;
const r20 = /[\u{100000}-\u{10FFFD}]+/u;
const r21 = /[\u{A0}-\u{10FFFD}]+/u;
function isUrlQueryCodePoint(c: string): boolean {
  return isUrlCodePoint(c) || r18.test(c) || r21.test(c);
}
function isUrlQueryCodePoint32(c: string): boolean {
  return isUrlCodePoint32(c) || r19.test(c) || r20.test(c) || r21.test(c);
}

function isHexaDigit(c: string): boolean {
  return (c >= "0" && c <= "9") || (c >= "a" && c <= "f") || (c >= "A" && c <= "F");
}
