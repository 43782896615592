import { useSelector } from "react-redux";
import type { GlobalState } from "#reducers/index.ts";
import type { ConstructConsoleUrl } from "#staticConfig.ts";
import { getConstructConsoleUrl } from "#staticConfig.ts";

export default function useConstructConsoleUrl(): ConstructConsoleUrl {
  const consoleUrlInfo = useSelector((state: GlobalState) => state.config.staticConfig?.consoleUrl);
  if (!consoleUrlInfo) return () => ""; //Should never happen
  return getConstructConsoleUrl({ consoleUrlInfo: consoleUrlInfo });
}
