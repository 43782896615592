import { fromPairs, groupBy } from "lodash-es";
import { Converter } from "sparqljson-to-tree";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import type { FormValues } from "./Types";
import useSparql from "./useSparql";

const factory = factories.compliant;
const converter = new Converter({ materializeRdfJsTerms: true });

const valuesQuery = (resource: string) => `
# Initial values

prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix meta: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>
prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>
prefix sh: <http://www.w3.org/ns/shacl#>
prefix dash: <http://datashapes.org/dash#>
prefix triply: <https://triplydb.com/Triply/function/>

select
  *
where {
  bind(${termToString(factory.namedNode(resource))} as ?value)
  ?value a ?type_id .
  bind(triply:firstLabel(?type_id) as ?type_label)
  bind("IRI" as ?nodeKind)
  optional {
    ?value ?properties_predicate ?properties_rawValue.
    filter(!strstarts(str(?properties_predicate), str(meta:)))
    bind(str(?properties_rawValue) as ?properties_value)
    filter(?properties_predicate != rdf:type)
    optional {
      [] sh:targetClass/^rdfs:subClassOf* ?type_id ;
         sh:property [
                       sh:path ?properties_predicate ;
                       dash:editor dash:DetailsEditor
                       ]
      bind(true as ?properties_nestedNode)
    }
    bind(if(isiri(?properties_rawValue), if(bound(?properties_nestedNode), "NestedNode", "IRI"), "Literal") as ?properties_nodeKind)

    optional {
      filter(?properties_nodeKind = "Literal")
      bind(datatype(?properties_rawValue) as ?properties_datatype)
      bind(lang(?properties_rawValue) as ?properties_language)
    }
    optional {
      filter(?properties_nodeKind = "NestedNode")
      ?properties_rawValue a ?properties_type .
      ?properties_rawValue ?properties_properties_predicate ?properties_properties_rawValue
      bind(str(?properties_properties_rawValue) as ?properties_properties_value)
      filter(?properties_properties_predicate != rdf:type)
      bind(if(isiri(?properties_properties_rawValue), "IRI", "Literal") as ?properties_properties_nodeKind)
      optional {
        filter(?properties_properties_nodeKind = "Literal")
        bind(datatype(?properties_properties_rawValue) as ?properties_properties_datatype)
        bind(lang(?properties_properties_rawValue) as ?properties_properties_language)
      }
      optional {
        filter(?properties_properties_nodeKind = "IRI")
        bind(triply:firstLabel(?properties_properties_rawValue) as ?properties_properties_label)
      }
    }
    optional {
      filter(?properties_nodeKind = "IRI")
      bind(triply:firstLabel(?properties_rawValue) as ?properties_label)
    }
  }
}

`;

const transformProperties = (properties: any) => {
  if (!properties) return;
  return groupBy(
    properties.map((v: any) => ({
      ...v,
      key: v.predicate.replace(/\./g, " "),
      properties: transformProperties(v.properties),
    })),
    "key",
  );
};

const useFetchInitialValues = () => {
  const sparql = useSparql();

  const fetchInitialValues = async (resource: string) => {
    const results = await sparql(valuesQuery(resource));
    let json = converter.sparqlJsonResultsToTree(results, {
      singularizeVariables: {
        "": true,
        type: true,
        ...fromPairs(results.head!.vars.map((v) => [v, true])),
      },
    });

    json.properties = transformProperties(json.properties);

    return json as FormValues;
  };

  return fetchInitialValues;
};

export default useFetchInitialValues;
