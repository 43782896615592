import { Alert, Autocomplete, Skeleton, TextField, ThemeProvider, useTheme } from "@mui/material";
import { cloneDeep, merge } from "lodash-es";
import * as React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { Button, FontAwesomeButton, FontAwesomeIcon, FormField } from "#components/index.ts";
import PropertyValueField from "./PropertyValueField";
import localTheme from "./Theme";
import type { ResourceData } from "./Types";
import useClasses from "./useClasses";
import useFetchInitialValues from "./useFetchInitialValues";

const ResourceForm: React.FC<{ onSubmit: (values: ResourceData) => Promise<void>; editingResource?: string }> = ({
  onSubmit,
  editingResource,
}) => {
  const theme = useTheme();
  const fetchInitialValues = useFetchInitialValues();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, errors, submitCount },
    getValues,
    watch,
    setError,
    setValue,
    trigger,
  } = useForm<ResourceData>({
    defaultValues: (editingResource && (() => fetchInitialValues(editingResource))) || undefined,
  });

  const { fields, remove, append } = useFieldArray({
    name: "properties",
    control,
  });

  const type = watch("type.id");
  watch("properties");

  const classes = useClasses();
  const properties = classes?.find((c) => c.id === type)?.properties || [];

  const submit = async (values: ResourceData) => {
    try {
      await onSubmit(values);
    } catch (e) {
      console.error(e);
      setError("root.serverError", {
        type: "500",
      });
    }
  };

  if (!classes) {
    return <Skeleton variant="rectangular" width={860} height={175} />;
  }
  if (classes.length === 0) {
    return (
      <Alert severity="info">No SHACL classes were {editingResource ? "for this resource" : "in this dataset"}</Alert>
    );
  }

  return (
    <ThemeProvider theme={merge(cloneDeep(localTheme), theme)}>
      <form method="POST" onSubmit={handleSubmit(submit)} className="flex column g-7">
        <FormField label="Type">
          <Controller
            name="type"
            control={control}
            rules={{ required: "A type is required." }}
            defaultValue={null}
            render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
              <Autocomplete
                options={classes}
                onChange={(_e, data) => {
                  setValue("properties", []);
                  return onChange(data);
                }}
                renderInput={(params) => (
                  <TextField
                    {...(params as any)}
                    error={!!error}
                    helperText={error?.message || rest.value?.description}
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                {...rest}
              />
            )}
          />
        </FormField>

        <FormField label="Label">
          <Controller
            name="label"
            control={control}
            rules={{ required: "A label is required." }}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField {...field} error={!!fieldState.error} helperText={fieldState.error?.message} />
            )}
          />
        </FormField>

        {properties.length > 0 && (
          <FormField label="Properties">
            <div className="flex column g-5">
              {fields.map((field, index) => {
                return (
                  <div key={field.id} className="flex wrap g-5">
                    <Controller
                      name={`properties.${index}.property`}
                      control={control}
                      rules={{ required: "A property is required." }}
                      defaultValue={null}
                      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                        <Autocomplete
                          options={properties}
                          onChange={(_e, data) => {
                            setValue(`properties.${index}.value`, null);
                            if (submitCount > 0) trigger(`properties.${index}.value`).catch(console.error);
                            onChange(data);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...(params as any)}
                              error={!!error}
                              helperText={error?.message || rest.value?.description}
                            />
                          )}
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                          }}
                          {...rest}
                        />
                      )}
                    />
                    <PropertyValueField
                      name={`properties.${index}.value`}
                      control={control}
                      rules={{ required: "A value is required." }}
                      datatype={getValues(`properties.${index}.property`)?.datatype}
                      values={getValues(`properties.${index}.property`)?.values || []}
                    />
                    <FontAwesomeButton
                      color="error"
                      icon="trash"
                      onClick={() => remove(index)}
                      title="Remove property"
                    />
                  </div>
                );
              })}
              <Button
                onClick={() => append({ property: properties[0], value: null })}
                startIcon={<FontAwesomeIcon icon="plus" />}
                title="Add new property"
              />
            </div>
          </FormField>
        )}

        <LoadingButton color="secondary" type="submit" disabled={!isValid && 1 != 1} loading={isSubmitting}>
          Save
        </LoadingButton>

        {errors.root && <Alert severity="error">Something went wrong on the server...</Alert>}
      </form>
    </ThemeProvider>
  );
};

export default ResourceForm;
