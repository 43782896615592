import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import AccessLevelIcon from "#components/AccessLevels/Icon.tsx";
import { FontAwesomeRoundIcon, HumanizedDate, Markdown } from "#components/index.ts";
import { getQueryIcon } from "#helpers/FaIcons.tsx";
import type { Query } from "#reducers/queries.ts";
import * as styles from "./style.scss";

namespace QueryListItem {
  export interface Props {
    query: Query;
    className?: string;
    showOwner?: boolean;
  }
  export interface State {}
}

const QueryListItem = React.memo<QueryListItem.Props>(({ query, className, showOwner }) => {
  {
    const linkItems = [query.owner.accountName, "-", "queries", query.name];
    if (query.version) linkItems.push("" + query.version);
    const link = "/" + linkItems.join("/");

    return (
      <div className={getClassName(className, styles.container)}>
        <div className={getClassName(styles.queryTypeIcon)}>
          <Link to={link}>
            <FontAwesomeRoundIcon
              aria-label={`Visualization type: ${
                query.renderConfig?.output === "gchart"
                  ? query.renderConfig.settings?.chartType || "gchart"
                  : query.renderConfig?.output || "unknown"
              }`}
              icon={["far", getQueryIcon(query)]}
              className={getClassName({
                [styles.flipped]:
                  query.renderConfig?.output === "gchart" &&
                  query.renderConfig?.settings?.chartConfig?.chartType === "BarChart", // since there is no icon for barchart, we use the same as columnchart and flip it
              })}
            />
          </Link>
        </div>
        <div className={styles.main}>
          <Link to={link}>
            <h3>{query.displayName || query.name}</h3>
          </Link>

          {showOwner && (
            <div>
              by <Link to={`/${query.owner.accountName}`}>{query.owner.name || query.owner.accountName}</Link>
            </div>
          )}

          {query.description && (
            <Markdown className="py-2" compact>
              {query.description}
            </Markdown>
          )}

          <div className="pt-2">
            {!query.dataset && "Dataset is not accessible."}
            {!!query.dataset && (
              <Link
                to={`/${query.dataset.owner.accountName}/${query.dataset.name}`}
              >{`${query.dataset.owner.accountName} / ${query.dataset.name}`}</Link>
            )}
          </div>
          <div className="pt-2">
            Created <HumanizedDate date={query.createdAt} />,{" "}
            {query.numberOfVersions > 0 ? query.numberOfVersions : "no"} version{query.numberOfVersions !== 1 && "s"}
          </div>
        </div>
        <div className={styles.icons}>
          <AccessLevelIcon level={query.accessLevel} type="query" addTitleForAccountType={query.owner.type} />
        </div>
      </div>
    );
  }
});

export default QueryListItem;
