import { betterAjvErrors } from "@apideck/better-ajv-errors";
import type { JSONSchemaType } from "ajv";
import { Ajv } from "ajv";
import type { IToken } from "chevrotain";
import yaml from "yaml";
import { AnnotationError } from "./error.ts";
import type * as Types from "./types.ts";

const ajv = new Ajv();
function getAnnotationsValidator<S extends {}>(schema: JSONSchemaType<S>) {
  const validator = ajv.compile(schema);
  return function validate(value: unknown, location: Types.LocationContext): asserts value is S {
    const valid = validator(value);

    if (!valid) {
      const error = betterAjvErrors({
        errors: validator.errors,
        data: value,
        // Any cast, see https://github.com/apideck-libraries/better-ajv-errors/issues/24
        schema: schema as any,
      })[0];
      let errorMessage = `Invalid annotation: ${error.message}`;
      if (error.message === `'' property type must be object`) {
        // This error occurs when you have a line like this: `#! paginate`
        errorMessage = `Invalid annotation`;
      } else if (
        error.context.errorType === "enum" &&
        "allowedValues" in error.context &&
        Array.isArray(error.context.allowedValues)
      ) {
        // Print the enum values when we have them
        errorMessage += `: ${error.context.allowedValues.join(", ")}`;
      } else if (error.suggestion) {
        // If better-ajv-errors came up with a suggestion, use that. Such suggestions may indicate typos and such
        errorMessage += `. ${error.suggestion}.`;
      }
      throw new AnnotationError(errorMessage, { location });
    }
  };
}

export const assertQueryAnnotations: (
  value: unknown,
  location: Types.LocationContext,
) => asserts value is Types.QueryAnnotations = getAnnotationsValidator<Types.QueryAnnotations>({
  type: "object",
  properties: {
    cache: { type: "boolean", nullable: true },
    debug: { type: "string", enum: ["after-execution", "before-execution"], nullable: true },
    optimize: { type: "boolean", nullable: true },
    reorder: { type: "boolean", nullable: true },
  },
  additionalProperties: false,
});

export const assertSubSelectAnnotations: (
  value: unknown,
  location: Types.LocationContext,
) => asserts value is Types.SubSelectAnnotations = getAnnotationsValidator<Types.SubSelectAnnotations>({
  type: "object",
  properties: {
    paginate: { type: "boolean", nullable: true },
  },
  additionalProperties: false,
});

/**
 * The value is unknown, as validation is applied when parsing (when we know which shape the validation should have)
 */
export interface AnnotationBlock {
  location: Types.LocationContext;
  annotations: { [key: string]: unknown };
}

/**
 * Go over all tokens, and extract the annotation blocks with the corresponding lines.
 * Note that validation (other than yaml parsing) is not applied yet. This is done during parsing,
 * where we know the context of the annotation
 */
export function dissectAnnotationBlocks(tokens: Array<IToken>) {
  type AnnotationBlockRaw = { location: Types.LocationContext; value: string };
  const legacyPaginateAnnotationLines: Array<number> = [];
  const annotationBlockRawStack: Array<AnnotationBlockRaw> = [];
  let currentAnnotationBlockRaw: AnnotationBlockRaw | undefined;
  const parseableTokens = tokens.filter((t, index) => {
    if (t.tokenType.name === "LegacyPaginateAnnotation" && t.startLine !== undefined) {
      legacyPaginateAnnotationLines.push(t.startLine);
      return false;
    }
    if (t.tokenType.name === "Annotation") {
      if (
        t.startLine === undefined ||
        t.endLine === undefined ||
        t.startColumn === undefined ||
        t.endColumn === undefined
      ) {
        // Not expecting this to happen (probably only when doing fancy custom token matching)
        // Still having this here for typing purposes
        throw new AnnotationError("Cannot parse annotations: Start or end line is not defined", {
          location: undefined,
        });
      }
      if (index > 0 && tokens[index - 1]?.startLine === t.startLine) {
        throw new AnnotationError("Invalid annotation: annotations should be placed on a separate line", {
          location: {
            startLine: t.startLine,
            endLine: t.endLine,
            startColumn: t.startColumn,
            endColumn: t.endColumn,
          },
        });
      }
      let annotationLine = t.image.substring(2); // remove #!
      if (!currentAnnotationBlockRaw) {
        currentAnnotationBlockRaw = {
          location: { startLine: t.startLine, startColumn: t.startColumn, endLine: t.endLine, endColumn: t.endColumn },
          value: annotationLine,
        };
      } else {
        if (currentAnnotationBlockRaw.location.endLine === t.startLine - 1) {
          // We have a previous line, append the current line to the block
          currentAnnotationBlockRaw.location.endLine = t.endLine;
          currentAnnotationBlockRaw.location.endColumn = t.endColumn;
          currentAnnotationBlockRaw.value += "\n" + annotationLine;
        } else {
          // We have a new annotation, but it's unrelated to the previous annotation
          annotationBlockRawStack.push(currentAnnotationBlockRaw);

          currentAnnotationBlockRaw = {
            location: {
              startLine: t.startLine,
              startColumn: t.startColumn,
              endLine: t.endLine,
              endColumn: t.endColumn,
            },
            value: annotationLine,
          };
        }
      }

      return false; // don't include annotations in the list of tokens used by the parser
    }
    return true;
  });
  // When it exists, add last annotation to stack
  if (currentAnnotationBlockRaw) annotationBlockRawStack.push(currentAnnotationBlockRaw);

  // Parse the annotation blocks. T
  const annotations: Array<AnnotationBlock> = [];
  for (const annotationBlockRaw of annotationBlockRawStack) {
    if (!annotationBlockRaw.value.trim().length) continue;
    try {
      const doc = yaml.parseDocument(annotationBlockRaw.value, {});
      // Parser doesn't always throw, so also throw warnings...
      // See https://github.com/eemeli/yaml/issues/430
      // Note that we're throwing a regular error, but we'll wrap this in a proper error in the below
      // catch statement (together with errors that may have been thrown by the parseDocument call)

      if (doc.errors.length || doc.warnings.length) throw doc.errors[0] || doc.warnings[0];
      annotations.push({
        location: annotationBlockRaw.location,
        annotations: doc.toJSON(),
      });
    } catch (e: any) {
      if (e instanceof AnnotationError) throw e;
      if (e.message.includes("Map keys must be unique")) {
        throw new AnnotationError(`Failed to parse annotations: duplicate annotation keys detected`, {
          location: annotationBlockRaw.location,
        });
      }
      throw new AnnotationError(`Failed to parse annotations`, { cause: e, location: annotationBlockRaw.location });
    }
  }
  return { parseableTokens, annotations, legacyPaginateAnnotationLines };
}
