import numeral from "numeral";
import type { Constants } from "@triply/utils";
import type * as Models from "@triply/utils/Models";

export interface MongooseOperator {
  $eq?: number;
  $gt?: number;
  $lt?: number;
}
export interface QueryObject {
  accountName?: string;
  accessLevel?: Models.AccessLevel;
  statements?: MongooseOperator;
  license?: string | null;
  topic?: string;
  q: string;
}
export function expandSearchQuery(q: string): QueryObject {
  var { q, accountName } = parseAccount(q);
  var { q, accessLevel } = parseAccessLevel(q);
  var { q, statements } = parseStatements(q);
  var { q, license } = parseLicense(q);
  var { q, topic } = parseTopic(q);

  return {
    accountName,
    accessLevel,
    statements,
    license,
    topic,
    q,
  };
}

function parseAccount(q: string): Pick<QueryObject, "q" | "accountName"> {
  const accountMatch = q.match(/(?:^|\s)((?:user|org|account):(\S+))/i);
  if (accountMatch && accountMatch.length === 3) {
    return {
      q: q.replace(accountMatch[1], "").trim(),
      accountName: accountMatch[2].toLowerCase(),
    };
  }
  return {
    q,
  };
}

function parseAccessLevel(q: string): Pick<QueryObject, "q" | "accessLevel"> {
  const accessLevelMatch = q.match(/(?:^|\s)((?:access|accesslevel):(\S+))/i);
  if (accessLevelMatch && accessLevelMatch.length === 3) {
    return {
      accessLevel: accessLevelMatch[2].toLowerCase() as Models.AccessLevel,
      q: q.replace(accessLevelMatch[1], "").trim(),
    };
  }
  return { q };
}

function parseStatements(q: string): Pick<QueryObject, "q" | "statements"> {
  const statementsMatch = q.match(/(?:^|\s)((?:triples|statements):?([><=]?)(\S+))/i);

  if (statementsMatch && statementsMatch.length === 4) {
    const number = numeral(statementsMatch[3]).value();
    if (typeof number === "number" && !isNaN(number)) {
      const statements = { statements: { [getOperator(statementsMatch[2])]: number } };
      return {
        ...statements,
        q: q.replace(statementsMatch[1], "").trim(),
      };
    }
    return { q: q.replace(statementsMatch[1], "").trim() };
  }
  return { q };
}

function getOperator(sign: string): string {
  switch (sign) {
    case ">":
      return "$gt";
    case "<":
      return "$lt";
    default:
      return "$eq";
  }
}

function parseLicense(q: string): Pick<QueryObject, "q" | "license"> {
  const licenseMatch = q.match(/(?:^|\s)((?:license):(\S+))/i);
  if (licenseMatch && licenseMatch.length === 3) {
    return {
      license: licenseMatch[2] as Constants.DatasetLicenseId,
      q: q.replace(licenseMatch[1], "").trim(),
    };
  }
  return { q };
}

export function parseTopic(q: string): Pick<QueryObject, "q" | "topic"> {
  const topicMatch = q.match(/(?:^|\s)((?:topic|tag|subject):(\S+))/i);
  if (topicMatch && topicMatch.length === 3) {
    return {
      topic: topicMatch[2],
      q: q.replace(topicMatch[1], "").trim(),
    };
  }
  return { q };
}
