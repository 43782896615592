import { MenuItem } from "@mui/material";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import type { Dataset } from "@triply/utils/Models";
import { Avatar, Highlight, MuiAutosuggest } from "#components/index.ts";
import fetch from "#helpers/fetch.ts";

export namespace PreImportLocalGraphImport {
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    onImportSelect: (datasetJson: Dataset) => void;
    datasetsUrl: string;
  }
  export interface FormData {
    datasetName: "";
  }
}

const PreImportLocalGraphImport = ReduxForm.reduxForm<
  PreImportLocalGraphImport.FormData,
  PreImportLocalGraphImport.Props
>({
  form: "PreImportLocalGraphImport",
})(
  class PreImportLocalGraphImport extends React.PureComponent<PreImportLocalGraphImport.Props, any> {
    getDatasetName = (dataset: Dataset) => {
      return dataset.owner.accountName + " / " + dataset.name;
    };
    searchDataset = (queryString: string) => this.search(this.props.datasetsUrl + "/?q=" + queryString);

    search(url: string): Promise<Dataset[]> {
      return this.fetch(url).then((results) => {
        if (results && Array.isArray(results)) {
          return results;
        }
        return [];
      });
    }

    fetch = memoizee(
      (url: string) => {
        return fetch(url, { credentials: "same-origin" })
          .then((response) => {
            if (response.status === 200) return response.json() as Promise<Dataset[]>;
          })
          .catch((error) => {
            console.error(error);
          });
      },
      { primitive: true, async: true },
    );

    shouldDatasetRender = () => true;
    render() {
      return (
        <MuiAutosuggest<Dataset, Dataset>
          loadSuggestions={this.searchDataset}
          getSuggestionSearchText={this.getDatasetName}
          renderSuggestion={(ds, { query, isHighlighted }) => {
            return (
              <MenuItem selected={isHighlighted} component="div">
                <Avatar
                  size="sm"
                  className="mr-2"
                  avatarUrl={ds.avatarUrl}
                  avatarName={ds.displayName || ds.name}
                  alt=""
                />
                <Highlight fullText={this.getDatasetName(ds)} highlightedText={query} />
              </MenuItem>
            );
          }}
          onSuggestionSelected={(_event, data) => this.props.onImportSelect(data.suggestion)}
          TextFieldProps={{ fullWidth: true, variant: "outlined" }}
          placeholder="Add data from an existing dataset"
        />
      );
    }
  } as any,
);

export default PreImportLocalGraphImport;
