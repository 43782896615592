import type { SortingFn } from "@tanstack/react-table";

const sortTypes: Record<string, SortingFn<boolean | "n/a" | undefined>> = {
  boolean: (rowA, rowB, columnId) => {
    const boolA = rowA.getValue(columnId);
    const boolB = rowB.getValue(columnId);
    if (boolA === boolB) return 0;
    if (boolA === undefined || boolA === "n/a") return -1;
    if (boolB === undefined || boolB === "n/a") return 1;
    return boolA ? 1 : -1;
  },
};

export default sortTypes;
