import { filter, flatMap } from "lodash-es";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { factories } from "@triplydb/data-factory";
import { rdf } from "@triplydb/recognized-datatypes/utils/constants";
import { termToString } from "@triplydb/sparql-ast/serialize";
import fetch from "#helpers/fetch.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { getClassDescription, getDescription } from "#reducers/resourceEditorDescriptions.ts";
import { Button, ConfirmationDialog, Dialog, FontAwesomeIcon } from "../../../components";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../../reducers/datasetManagement";
import { getGraphs } from "../../../reducers/graphs";
import InstanceForm from "../InstanceForm";
import formValuesToSparqlValues from "../InstanceForm/formValuesToSparqlValues";
import { getChangeDiff } from "../InstanceForm/getFormChanges";
import type { MuiColor } from "../Process/processDefinitions";

const factory = factories.compliant;

const EditResource: React.FC<{
  resource: string;
  color?: MuiColor;
  name: string;
  toStatus: string;
  fromStatus: string;
}> = ({ resource, color, name, toStatus, fromStatus }) => {
  const [open, setOpen] = React.useState(false);
  const [isDirty, setDirty] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();
  const authorAcc = useAuthenticatedUser();
  const authorUrl = `${consoleUrl}/${authorAcc?.accountName}`;
  const dispatch = useDispatch();
  const removePrefixes = useRemovePrefixes();
  const onClose = () => {
    if (isDirty) {
      setConfirmationDialogOpen(true);
    } else {
      setOpen(false);
    }
  };
  const resourceIri = factory.namedNode(resource);
  const applyPrefixes = useApplyPrefixes();
  return (
    <>
      <Button
        color={color === "default" ? undefined : color}
        elevation
        onClick={() => setOpen(true)}
        title="Edit instance"
        startIcon={<FontAwesomeIcon icon="pencil" />}
        size="small"
      >
        {name}
      </Button>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          setOpen(false);
        }}
        onClose={() => setConfirmationDialogOpen(false)}
        title="Are sure you want to close this form?"
        actionLabel="Close"
        description="If you close the form now, all changes will be lost."
      />

      {open && (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="md"
          fullWidth
          title="Edit instance"
          closeButton
          disableEscapeKeyDown
        >
          <div className="px-5 pb-5">
            <InstanceForm
              onDirty={setDirty}
              editingResource={resource}
              onSubmit={async (values, initialValues) => {
                const changeLog = getChangeDiff(values, applyPrefixes, initialValues);

                const propertyValues = formValuesToSparqlValues(resource, values.properties, removePrefixes);
                const nestedNodes = filter(
                  flatMap(values.properties),
                  (property) => property?.nodeKind === "NestedNode",
                );

                const datasetPath = `${currentDs.owner.accountName}/${currentDs.name}`;
                const historyIri = factory.namedNode(`${consoleUrl}/${datasetPath}/history/r-${uuid()}`);

                const query = `
                prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
                prefix meta: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>

                delete { ?subject ?p ?o } where {
                  values ?subject {
                    ${termToString(resourceIri)}
                    ${nestedNodes.map((nestedNode) => termToString(factory.namedNode(nestedNode.value))).join("\n")}
                  }
                  ?subject ?p ?o
                };
                insert {
                  ?historyObject a meta:Event;
                    meta:actor ?editor;
                    meta:time ?editedAt;
                    meta:product ?editedValue;
                    meta:fromStatus ?oldStatus;
                    meta:toStatus ?newStatus;
                    meta:action ?takenAction;
                    meta:summary ?changeLog.

                  ?subject ?predicate ?object.
                } where {
                  bind(now() as ?editedAt)
                  bind(${termToString(resourceIri)} as ?editedValue)
                  bind(${termToString(historyIri)} as ?historyObject)
                  bind(${termToString(factory.namedNode(authorUrl))} as ?editor)
                  bind(${termToString(factory.literal(changeLog, factory.namedNode(rdf("HTML"))))} as ?changeLog)

                  values(?oldStatus ?newStatus ?takenAction) {
                    (${fromStatus !== "unknown" ? termToString(factory.literal(fromStatus)) : "undef"} ${toStatus !== "unknown" ? termToString(factory.literal(toStatus)) : "undef"} ${termToString(factory.literal(name))})
                  }

                  values (?subject ?predicate ?object) {
                    (${termToString(resourceIri)} rdf:type ${termToString(factory.namedNode(values.type!.id))})
                    ${nestedNodes.map((nestedNode) => `(${termToString(factory.namedNode(nestedNode.value))} rdf:type ${termToString(factory.namedNode(nestedNode.type))})`).join("\n")}
                    ${propertyValues.map((triple) => `(${triple})`).join("\n\t\t")}
                  }
                }`;

                const body = new FormData();
                body.set("update", query);

                const result = await fetch(updateUrl, {
                  credentials: "same-origin",
                  method: "POST",
                  body: body,
                });
                if (result.status !== 200) {
                  throw new Error("Something went wrong on the server...");
                }

                setOpen(false);

                const promises = [
                  dispatch<typeof refreshDatasetsInfo>(
                    refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
                  ),
                  dispatch<typeof getGraphs>(
                    getGraphs({
                      accountName: currentDs.owner.accountName,
                      datasetName: currentDs.name,
                      datasetId: currentDs.id,
                    }),
                  ),
                  dispatch<typeof getDescription>(getDescription({ dataset: currentDs, resource: resource })),
                ];
                if (values.type?.id && values.type.id !== initialValues?.type?.id) {
                  promises.push(
                    dispatch<typeof getClassDescription>(
                      getClassDescription({ dataset: currentDs, classIri: values.type.id }),
                    ),
                  );
                }
                await Promise.all(promises);
              }}
            />
          </div>
        </Dialog>
      )}
    </>
  );
};

export default EditResource;
