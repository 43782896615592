// @ts-ignore @types/jsesc incorrect as of 02.10.19
import jsesc from "jsesc";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { filterEmptyVals } from "#helpers/utils.ts";
import type { GlobalState } from "#reducers/index.ts";

export interface Props {
  //Optional tags
  currentPath?: string;
  title?: string;
  type?: "website" | "profile" | "article" | "schema:Thing";
  profileUsername?: string;
  articleAuthor?: string;
  longTitle?: string;
  locale?: string;
  description?: string;
  themeColor?: string;
  image?: string;
  imageAlt?: string;
  jsonLd?: { key: string; json: {} }[];

  //Helmet specific
  titleTemplate?: string;
  defaultTitle?: string;
}
const Meta: React.FC<Props> = (props) => {
  const clientConfig = useSelector((state: GlobalState) => state.config.clientConfig);
  const values = useSelector((state: GlobalState) => state.config.values);
  const constructConsoleUrl = useConstructConsoleUrl();
  const pathname = useLocation().pathname;
  const url = constructConsoleUrl({ pathname: props.currentPath ?? pathname });
  if (!clientConfig) return <></>;
  const longTitle =
    props.longTitle ||
    `${clientConfig.branding.name}${clientConfig.branding.tagline ? `: ${clientConfig.branding.tagline}` : ""}`;
  return (
    <Helmet
      titleTemplate={props.titleTemplate || "%s - " + clientConfig.branding.name}
      defaultTitle={props.defaultTitle || clientConfig.branding.name}
    >
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>{props.title || clientConfig.branding.name}</title>
      {values?.theme.primary && <meta theme-color="utf-8" content={values.theme.primary} />}
      <meta property="og:type" content={props.type || "website"} />;
      <meta
        property="og:site_name"
        content={`${clientConfig.branding.name}${
          clientConfig.branding.tagline ? `: ${clientConfig.branding.tagline}` : ""
        }`}
      />
      <meta property="og:locale" content="en_US" />;
      <meta property="og:image" content={props.image || clientConfig.branding.logoLg.replace(".svg", ".png")} />
      <meta name="twitter:image" content={props.image || clientConfig.branding.logoLg} />
      {props.imageAlt && [
        //fragments are not support in helmet components... See https://github.com/nfl/react-helmet/issues/342*
        <meta key="og:image:alt" property="og:image:alt" content={props.imageAlt} />,
        <meta key="twitter:image:alt" name="twitter:image:alt" content={props.imageAlt} />,
      ]}
      <meta name="twitter:card" content="summary" />;
      <meta property="og:title" content={longTitle} />
      <meta name="twitter:title" content={longTitle} />
      <meta property="og:description" content={props.description || clientConfig.branding.description} />
      <meta name="twitter:description" content={props.description || clientConfig.branding.description} />
      {url && [
        //fragments are not support in helmet components... See https://github.com/nfl/react-helmet/issues/342*
        <meta key="og" property="og:url" content={url} />,
        <link key="link" rel="canonical" href={url} />,
      ]}
      {props.jsonLd &&
        props.jsonLd.map((jsonLd) => (
          <script key={jsonLd.key} type="application/ld+json">
            {jsesc(filterEmptyVals(jsonLd.json), {
              isScriptContext: true,
              compact: false,
              indentLevel: 2,
              indent: " ",
              minimal: true,
              quotes: "double",
            })}
          </script>
        ))}
    </Helmet>
  );
};

export default Meta;
