import getClassName from "classnames";
import * as React from "react";
import type { Models } from "@triply/utils";
import { default as TopicSegment } from "./TopicSegment.tsx";
import * as styles from "./styles/topic.scss";

interface Props {
  topic: Models.Topic;
  parents: Models.Topic[];
  className?: string;
}

const Topic: React.FC<Props> = (props) => {
  const { topic, parents } = props;
  if (!topic) return null;
  return (
    <div className={getClassName(props.className, styles.topic)}>
      {parents &&
        parents.length > 0 &&
        parents.map((parent, key) => <TopicSegment key={parent.id} topic={parent} hasParent={key !== 0} />)}
      <TopicSegment topic={topic} hasParent={parents.length > 0} />
    </div>
  );
};

export default Topic;
