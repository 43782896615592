import * as React from "react";

const DEFAULT_DRAFT_AGE = 30 * 24 * 60 * 60 * 1000;

function isServer() {
  return typeof window === "undefined";
}
export default function useUncontrolledLocalStorage<T>(globalIdentifier: string) {
  type StorageItem = { o: T; issued: number };

  const getIdentifier = React.useCallback(
    (localIdentifier: string[]) => {
      return ["triply", globalIdentifier, ...localIdentifier].join("::");
    },
    [globalIdentifier],
  );
  const baseIdentifier = ["triply", globalIdentifier].join("::");

  // Pruning
  React.useEffect(() => {
    if (isServer()) return;
    for (const key of Object.keys(window.localStorage)) {
      if (key.startsWith(baseIdentifier)) {
        try {
          const item = JSON.parse(localStorage.getItem(key) || "{}") as StorageItem;
          if (item.issued + DEFAULT_DRAFT_AGE < Date.now()) {
            localStorage.removeItem(key);
          }
        } catch (e) {
          if (e instanceof Error && e.message.includes("JSON")) {
            localStorage.removeItem(key);
          }
          throw e;
        }
      }
    }
  });

  const getItem = React.useCallback(
    (key: string[]) => {
      if (isServer()) return null;
      const itemString = localStorage.getItem(getIdentifier(key));
      if (itemString) {
        try {
          return (JSON.parse(itemString) as StorageItem)?.o;
        } catch (e) {
          if (e instanceof Error && e.message.includes("JSON")) {
            localStorage.removeItem(getIdentifier(key));
          }
          throw e;
        }
      }
      return null;
    },
    [getIdentifier],
  );
  const getStorageTime = React.useCallback(
    (key: string[]) => {
      if (isServer()) return null;
      const itemString = localStorage.getItem(getIdentifier(key));
      if (itemString) {
        try {
          return (JSON.parse(itemString) as StorageItem)?.issued;
        } catch (e) {
          if (e instanceof Error && e.message.includes("JSON")) {
            localStorage.removeItem(getIdentifier(key));
          }
          throw e;
        }
      }
      return null;
    },
    [getIdentifier],
  );
  const setItem = React.useCallback(
    (key: string[], item: T) => {
      if (isServer()) return null;
      localStorage.setItem(getIdentifier(key), JSON.stringify({ o: item, issued: Date.now() } as StorageItem));
    },
    [getIdentifier],
  );
  const clearItem = React.useCallback(
    (key: string[]) => {
      if (isServer()) return null;
      localStorage.removeItem(getIdentifier(key));
    },
    [getIdentifier],
  );
  return {
    getItem: getItem,
    setItem: setItem,
    clearItem: clearItem,
    getStorageTime: getStorageTime,
  };
}
