import { Masonry } from "@mui/lab";
import getClassName from "classnames";
import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SubmissionError } from "redux-form";
import { DatasetWidget, Dialog, FontAwesomeIcon, QueryWidget, StoryWidget } from "#components/index.ts";
import { getQueryType } from "#helpers/FaIcons.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { updateProfile } from "#reducers/accounts.ts";
import { useCurrentAccount, usePinnedItems } from "#reducers/app.ts";
import type { GlobalState } from "#reducers/index.ts";
import type { FormData } from "./OverviewForm.tsx";
import { default as OverviewForm } from "./OverviewForm.tsx";
import * as styles from "./style.scss";

const Overview: React.FC<{}> = () => {
  const acl = useAcl();
  const dispatch = useDispatch();
  const location = useLocation<{ showForm: boolean } | undefined>();
  const currentAccount = useCurrentAccount();
  const allResourceDescriptions = useSelector((state: GlobalState) => state.resourceDescriptions);
  const pinnedItems = usePinnedItems(currentAccount?.uid);

  if (!currentAccount) return null;

  const goBack = () => dispatch(connectedReactRouter.goBack());
  const saveOverview = ({ items }: FormData) => {
    return dispatch<typeof updateProfile>(
      updateProfile(currentAccount, {
        pinnedItems: items.map((pinnedItem) => ({ type: pinnedItem.type, item: pinnedItem.item.id })),
      }),
    )
      .catch((e) => {
        throw new SubmissionError({ _error: e.message });
      })
      .then(() => goBack());
  };

  const mayEditAccount = acl.check({
    action: "editAccountMetadata",
    context: { roleInAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  const hasPinnedItems = pinnedItems.length > 0;

  return (
    <div className="my-5">
      {!mayEditAccount && !hasPinnedItems && (
        <div className={getClassName(styles.center, styles.empty)}>
          <FontAwesomeIcon size="3x" icon={["fal", "empty-set"]} />
        </div>
      )}

      {mayEditAccount && (
        <div className={getClassName("mb-3", { [styles.center]: pinnedItems.length === 0 })}>
          <Link
            to={{ state: { showForm: true, preserveScrollPosition: true } }}
            className={getClassName("noLinkDecoration", styles.configure)}
          >
            <FontAwesomeIcon size="sm" icon={["fas", "pencil"]} className="mx-3" />
            Configure overview
          </Link>
        </div>
      )}

      {hasPinnedItems && (
        <div className={styles.masonryWrapper}>
          <Masonry columns={{ xs: 1, lg: pinnedItems.length === 1 ? 1 : 2 }} spacing={2} className={styles.masonry}>
            {pinnedItems.map((pinnedItem) => {
              switch (pinnedItem.type) {
                case "Dataset":
                  const ds = pinnedItem.item;
                  return (
                    <DatasetWidget
                      key={ds.id}
                      ds={ds}
                      datasetResourceDescriptions={allResourceDescriptions[ds.id]}
                      className={styles.item}
                      headerSize="md"
                    />
                  );
                case "Story":
                  const story = pinnedItem.item;
                  return <StoryWidget key={story.id} story={story} className={styles.item} />;
                case "Query":
                  const query = pinnedItem.item;
                  return <QueryWidget key={query.id} query={query} className={styles.item} />;
                default:
                  return null;
              }
            })}
          </Masonry>
        </div>
      )}

      {
        <Dialog maxWidth="sm" fullWidth open={!!location.state?.showForm} onClose={goBack}>
          <div className={styles.overviewForm}>
            <h3 className="headerSpacing">Configure overview</h3>
            Select datasets, stories and queries to show on the overview page.
            <OverviewForm
              initialValues={{
                items: pinnedItems.map((item) => {
                  return {
                    type: item.type,
                    item: {
                      id: item.item.id,
                      name: item.item.name,
                      displayName: item.item.displayName,
                      avatarUrl: item.type === "Dataset" ? item.item.avatarUrl : undefined,
                      bannerUrl: item.type === "Story" ? item.item.bannerUrl : undefined,
                      resultType: item.type === "Query" ? getQueryType(item.item) : undefined,
                    },
                  };
                }),
              }}
              onSubmit={saveOverview}
              onCancel={goBack}
            />
          </div>
        </Dialog>
      }
    </div>
  );
};

export default Overview;
