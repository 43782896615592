/**
 * Calver follows the semver semantics
 */
import { padStart } from "lodash-es";

export interface ParsedCalver {
  yearShort: number;
  month: number;
  versionInMonth: number;
  patch?: number;
}
const positiveIntCheck = /^\d+$/;
function asPositiveInt(val?: string) {
  if (typeof val === "string" && positiveIntCheck.test(val)) {
    return Number(val);
  }
  throw new Error(`Expected '${val}' to be a (positive) integer`);
}

function parseAndValidateCalver(calver: string): ParsedCalver {
  return validate(parse(calver));
}

/**
 * Parses a calver string into an object. If the calver is `latest`, then we use a date far in the future
 */
export function parse(calver: string): ParsedCalver {
  // Cater to situations where the version is 'latest-k8' or 'k8-latest' (yes, we use both...)
  if (calver.includes("latest")) calver = "99.1.100";

  if (isNewCalver(calver)) {
    const [year, month, semverPatchSegment] = calver.split(".");
    const patch = Number(semverPatchSegment.substring(1));
    const versionInMonth = asPositiveInt(semverPatchSegment[0]);
    return {
      yearShort: asPositiveInt(year),
      month: asPositiveInt(month),
      versionInMonth: versionInMonth,
      patch: patch > 0 ? patch : undefined,
    };
  }
  const [base, patch] = calver.split("-");
  const [year, month, versionInMonth] = base.split(".");
  // This is legacy calver
  try {
    if (month.length === 1)
      throw new Error(
        "Invalid (legacy) calver notation: this is an old calver notation, and we expect the month segment to be two numbers",
      );
    if (patch && patch.length > 2)
      throw new Error("Invalid calver notation. The patch version may not exceed 2 characters");
    if (versionInMonth.length > 1)
      throw new Error("Invalid calver notation. The version-in-month may not exceed 3 characters");
    return {
      yearShort: asPositiveInt(year),
      month: asPositiveInt(month),
      versionInMonth: asPositiveInt(versionInMonth),
      patch: patch ? asPositiveInt(patch) : undefined,
    };
  } catch (e: any) {
    throw new Error(`Failed to parse calver string ${calver}: ${e.message}`, { cause: e });
  }
}

/**
 * Validate whether a version string is valid. It's valid when:
 * - We have a valid month section (1-12)
 * - zero padding is correctly applied (e.g `01` as month notation instead of `1`)
 */
export function validate(calverOrSemver: ParsedCalver): ParsedCalver {
  if (!parsedVersionHasValidYear(calverOrSemver)) return calverOrSemver; // Dont validate this, it's semver
  if (calverOrSemver.month < 1 || calverOrSemver.month > 12) {
    throw new Error(`Invalid month number ${calverOrSemver.month}`);
  }

  return calverOrSemver;
}
export function serializeCalver(calver: ParsedCalver): string {
  if (calver?.patch && calver.patch > 99) throw new Error("Cannot create calver version with patch higher than 99");
  if (calver.versionInMonth > 9) throw new Error("Cannot create valver version with version-in-month higher than 9");
  const calverPatchSegment = calver.patch === undefined ? "00" : padStart(String(calver.patch), 2, "0");
  const base = [calver.yearShort, calver.month, calver.versionInMonth + calverPatchSegment].join(".");

  return base;
}

function getShortYear(date: Date) {
  return Number(String(date.getFullYear()).substring(2));
}
function isNewCalver(calver: string) {
  const semverPatchSegment = calver.split(".")[2];
  return semverPatchSegment && semverPatchSegment.length === 3 && !semverPatchSegment.includes("-");
}
export function increment(currentVersion: string, opts?: { patch?: boolean; currentDate?: Date }) {
  /**
   * If we want to patch a release, dont modify the calver part. Only increment the patch version
   */
  const parsedCurrentVersion = parseAndValidateCalver(currentVersion);
  if (opts?.patch) {
    if (parsedCurrentVersion.versionInMonth === 0)
      throw new Error("Cannot increment the old calver notation, with a 0 as version-in-month");
    if (parsedCurrentVersion.patch !== undefined) {
      parsedCurrentVersion.patch++;
    } else {
      parsedCurrentVersion.patch = 1;
    }
    return serializeCalver(parsedCurrentVersion);
  }
  const date = opts?.currentDate || new Date();
  const currentYearShort = getShortYear(date);
  const currentMonth = date.getMonth() + 1; // dates by default are zero-indexed
  if (currentYearShort !== parsedCurrentVersion.yearShort || currentMonth !== parsedCurrentVersion.month) {
    // This also triggers when we pass a semver (we're assuming the major-version is never 21)
    return serializeCalver({
      yearShort: currentYearShort,
      month: currentMonth,
      versionInMonth: 1,
    });
  } else {
    // Incrementing versionInMonth
    return serializeCalver({
      ...parsedCurrentVersion,
      versionInMonth: parsedCurrentVersion.versionInMonth + 1,
      patch: undefined,
    });
  }
}

export function isSemver(version: string) {
  try {
    return !parsedVersionHasValidYear(parseAndValidateCalver(version));
  } catch {
    return false;
  }
}
/**
 * Check whether the string is valid calver. For simplicity, we consider 'latest' a valid calver as well (defaulting to a date far in the future)
 */
export function isCalver(version: string) {
  try {
    return parsedVersionHasValidYear(parseAndValidateCalver(version));
  } catch (e) {
    return false;
  }
}
function parsedVersionHasValidYear(parsedVersion: ParsedCalver): boolean {
  /**
   * Assuming we're not using major-versions of 21 or higher
   */
  return parsedVersion.yearShort >= 21;
}
function compare(lhs: string, rhs: string) {
  const lhsIsSemver = !parsedVersionHasValidYear(parseAndValidateCalver(lhs));
  const rhsIsSemver = !parsedVersionHasValidYear(parseAndValidateCalver(rhs));
  if (lhsIsSemver && rhsIsSemver)
    throw new Error(`Assuming ${lhs} and ${rhs} are both semver's. We do not support comparing semver alone`);
  if (lhsIsSemver) return -1;
  if (rhsIsSemver) return 1;
  // simple string comparison suffices when we know we're comparing our calver versions
  // Parse and serialize again, so we can compare legacy calver (23.01.1-1) with current
  // calver (23.1.101)
  return serializeCalver(parse(lhs)).localeCompare(serializeCalver(parse(rhs)));
}

/**
 * Check whether lhs is greather-than rhs
 * Supported values are calver notation and 'latest'
 */
export function gt(lhs: string, rhs: string) {
  return compare(lhs, rhs) > 0;
}
/**
 * Check whether lhs is greather-than-or-equal to rhs
 * Supported values are calver notation and 'latest'
 */
export function gte(lhs: string, rhs: string) {
  return compare(lhs, rhs) >= 0;
}
/**
 * Check whether lhs is less-than rhs
 * Supported values are calver notation and 'latest'
 */
export function lt(lhs: string, rhs: string) {
  return compare(lhs, rhs) < 0;
}
/**
 * Check whether lhs is less-than-than-or-equal to rhs
 * Supported values are calver notation and 'latest'
 */
export function lte(lhs: string, rhs: string) {
  return compare(lhs, rhs) <= 0;
}
