import type * as elasticSearch from "@elastic/elasticsearch";

export function getElasticSimpleSearchQueryObject(searchString: string, from?: number) {
  const query: elasticSearch.estypes.SearchRequest = {
    query: {
      bool: {
        must: {
          simple_query_string: {
            query: searchString,
          },
        },
        should: {
          simple_query_string: {
            query: searchString,
            fields: ["http://www w3 org/2000/01/rdf-schema#label"],
          },
        },
      },
    },
  };
  if (from !== undefined) {
    query.from = from;
  }
  return query;
}
