import { Button, Checkbox, Menu, MenuItem } from "@mui/material";
import type { Column } from "@tanstack/react-table";
import { fromPairs, omit, upperFirst } from "lodash-es";
import * as React from "react";
import FontAwesomeIcon from "../FontAwesomeIcon";

export interface Props {
  columns: Column<any, any>[];
  ignoreVisibility: Array<string>;
  onChange: (visibility: Record<string, boolean>) => void;
}
const PersistedColumnVisibilityButton: React.FC<Props> = ({ columns, ignoreVisibility, onChange }) => {
  const [openToggleColumnsMenu, setOpenToggleColumnsMenu] = React.useState(false);
  const menuAnchorRef = React.useRef<any>(undefined);
  const getVisibilityHandler = React.useCallback(
    (column: Column<any, any>) => {
      return (e: unknown) => {
        // Casting is based on https://github.com/TanStack/table/blob/0caf6955409de57bed80a0982060b9441e1c9f73/packages/table-core/src/features/ColumnVisibility.ts#L195
        const value = !!((e as MouseEvent).target as HTMLInputElement).checked;
        column.toggleVisibility(value);
        let columnVisibility = fromPairs(columns.map((c) => [c.id, c.getIsVisible()]));
        columnVisibility[column.id] = value;
        columnVisibility = omit(columnVisibility, ignoreVisibility);
        onChange(columnVisibility);
      };
    },
    [columns, ignoreVisibility, onChange],
  );
  return (
    <>
      <Button
        variant="outlined"
        size="small"
        aria-label={openToggleColumnsMenu ? "Hide columns menu" : "Show columns menu"}
        onClick={() => {
          setOpenToggleColumnsMenu(!openToggleColumnsMenu);
        }}
        ref={menuAnchorRef}
        endIcon={<FontAwesomeIcon icon={openToggleColumnsMenu ? "chevron-up" : "chevron-down"} />}
      >
        Columns
      </Button>
      <Menu
        open={openToggleColumnsMenu}
        anchorEl={menuAnchorRef.current}
        onClose={() => {
          setOpenToggleColumnsMenu(false);
        }}
      >
        {columns.map((column) => {
          if (!ignoreVisibility.includes(column.id)) {
            const handler = getVisibilityHandler(column);
            return (
              <MenuItem key={column.id} value={column.id} onClick={handler}>
                <Checkbox checked={column.getIsVisible()} onChange={handler} />
                {upperFirst(column.columnDef.header?.toString() || column.id)}
              </MenuItem>
            );
          }
        })}
      </Menu>
    </>
  );
};
export default PersistedColumnVisibilityButton;
