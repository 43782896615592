/**
 * W3C XML Schema Definition Language (XSD) 1.1 Part 2: Datatypes.
 * --------------------------------------------------------------
 *  A W3C Recommendation published on 2012-04-05
 *  @see https://www.w3.org/TR/xmlschema11-2/
 */
import { EmbeddedActionsParser, Lexer } from "chevrotain";
import { False, OneOrZero, True } from "./tokens.ts";

const tokens = [False, True, OneOrZero];
export class BooleanParser extends EmbeddedActionsParser {
  constructor() {
    super(
      tokens,
      // https://chevrotain.io/docs/guide/initialization_performance.html#use-a-smaller-global-maxlookahead
      // Even though we don't expect big performance difference between this value and the default (maxLookAhead=3),
      // we kept it here as a best practice for when designing rules in the future.
      { maxLookahead: 2 },
    );
    this.performSelfAnalysis();
  }

  public boolean = this.RULE("booleanRep", () => {
    const result = this.OR([
      {
        ALT: () => this.CONSUME1(True),
      },
      {
        ALT: () => this.CONSUME2(False),
      },
      {
        ALT: () => this.CONSUME3(OneOrZero),
      },
    ]).image;
    if (result === "0" || result === "false") {
      return false;
    }
    return true;
  });
}
export const lexer = new Lexer(tokens, {
  ensureOptimizations: true,
  // not tracking lines for this grammar (not setting this will print a warning)
  positionTracking: "onlyOffset",
});
export const parser = new BooleanParser();
