import formatNumber from "@core/utils/formatting";
import { Chip, Divider, Tooltip } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import FontAwesomeIcon from "#components/FontAwesomeIcon/index.tsx";
import JsonViewer from "#components/JsonViewer/index.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import ErrorResponse from "../QueryResults/Visualizations/Error";
import type { CacheHeader, UpdateResponse } from "../SparqlUtils";
import * as styles from "./styles.scss";

interface Props {
  className?: string;
  loading?: boolean;
  error?: Error;
  duration?: number;
  delay?: number;
  cache?: CacheHeader;
  response?: UpdateResponse;
}

const SparqlUpdateResults: React.FC<Props> = ({ className, loading, error, duration, delay = 0, cache, response }) => {
  const acl = useAcl();
  return (
    <>
      <div className={getClassName(styles.visualizationSelectorContainer, className)}>
        {loading && (
          <Chip
            className="ml-3"
            label={"Fetching results"}
            icon={<FontAwesomeIcon icon="spinner-third" spin />}
            size="small"
          />
        )}
        {!loading && !!error && duration !== undefined && (
          <Chip className="ml-3" color="error" label={`${duration / 1000} seconds`} size="small" />
        )}
        {!loading && !error && duration !== undefined && (
          <Chip
            className="ml-3"
            label={
              <>
                {`${formatNumber(duration / 1000, "0,0.000")} seconds`}
                {acl.check({ action: "seeQueryDelayAndCacheInfo" }).granted && (
                  <>
                    {delay >= 100 && (
                      <Tooltip title={`${formatNumber(delay / 1000, "0,0.000")} seconds in queue`}>
                        <span className="ml-2">
                          <FontAwesomeIcon icon="hourglass-clock" fixedWidth />
                        </span>
                      </Tooltip>
                    )}
                    {cache === "HIT" && (
                      <Tooltip title="Cache hit">
                        <span className="ml-2">
                          <FontAwesomeIcon icon={["fas", "database"]} fixedWidth />
                        </span>
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            }
            size="small"
          />
        )}
      </div>
      <Divider />
      {error ? (
        <div>
          <ErrorResponse error={error} />
        </div>
      ) : (
        response && <JsonViewer data={JSON.stringify(response, null, 2)} />
      )}
    </>
  );
};

export default SparqlUpdateResults;
