import { EditorView } from "@codemirror/view";
import { TextField } from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import * as React from "react";
import type * as Models from "@triply/utils/Models";
import { Alert, LoadingButton } from "#components/index.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useFetch from "#helpers/hooks/useFetch.ts";
import * as styles from "./style.scss";

interface Props {
  apiServicePath: string;
}

const Logs: React.FC<Props> = ({ apiServicePath }) => {
  const [tail, setTailSize] = React.useState<number | "">(500);
  const constructUrlToApi = useConstructUrlToApi();
  const { response, error, loading, data, get } = useFetch<Models.PodLogs | Models.ErrorResponse>(
    constructUrlToApi({ pathname: `${apiServicePath}/logs`, query: { tail: `${tail || 500}` } }),
    {
      // By default, this hook caches all requests in memory
      cachePolicy: "no-cache" as any, //typescript def is out of date
    },
    [],
  );
  return (
    <>
      <div className="p-4">
        <h2>Logs</h2>
        <div className={styles.logControls}>
          <TextField
            label="Tail"
            value={tail}
            type="number"
            onChange={(event) => {
              if (event.target.value === "") {
                setTailSize("");
                // @ts-ignore Ignoring typescript error about always evaluating to true. The suggestion presented by typescript
                // for using `Number.isNaN()` does _not_ behave the same: `+'123a'` is NaN where `Number.isNaN('123a')` returns false
              } else if (+event.target.value !== NaN) {
                if (+event.target.value > 10000) {
                  setTailSize(10000);
                } else {
                  setTailSize(+event.target.value);
                }
              }
              // Don't do anything when the value is not a number or empty
            }}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                get().catch(() => {});
                ev.preventDefault();
              }
            }}
            variant="outlined"
            placeholder="500"
            InputProps={{
              endAdornment: (
                <LoadingButton
                  loading={loading}
                  onClick={() => {
                    get().catch(() => {});
                  }}
                >
                  Load
                </LoadingButton>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 1,
              max: 10000,
            }}
          />
        </div>
      </div>

      {error && (
        <Alert
          message={
            <div>
              [{response.status}] {data && "serverError" in data ? data.serverError : error.message}
            </div>
          }
        />
      )}
      {!error && data && "logs" in data && (
        <div className={styles.logsCodemirrorWrapper}>
          <CodeMirror
            key={data.logs}
            className={styles.codemirror}
            value={data.logs}
            readOnly
            extensions={[EditorView.lineWrapping]}
          />
        </div>
      )}
    </>
  );
};
export default Logs;
