import { produce } from "immer";
import { last, sumBy } from "lodash-es";
import type { ParsedQs } from "qs";
import type { Models, Routes } from "@triply/utils";
import type { Dataset } from "#reducers/datasetManagement.ts";
import type { Action, BeforeDispatch, GlobalAction } from "#reducers/index.ts";
import { Actions } from "#reducers/index.ts";

export const LocalActions = {
  START_JOB: "triply/data/START_JOB",
  START_JOB_SUCCESS: "triply/data/START_JOB_SUCCESS",
  START_JOB_FAIL: "triply/data/START_JOB_FAIL",
  CREATE_JOB: "triply/data/CREATE_JOB",
  CREATE_JOB_SUCCESS: "triply/data/CREATE_JOB_SUCCESS",
  CREATE_JOB_FAIL: "triply/data/CREATE_JOB_FAIL",
  DELETE_JOB: "triply/data/DELETE_JOB",
  DELETE_JOB_SUCCESS: "triply/data/DELETE_JOB_SUCCESS",
  DELETE_JOB_FAIL: "triply/data/DELETE_JOB_FAIL",
  DELETE_ALL_JOBS_IN_ERROR_STATE: "triply/data/DELETE_ALL_JOBS_IN_ERROR_STATE",
  DELETE_ALL_JOBS_IN_ERROR_STATE_SUCCESS: "triply/data/DELETE_ALL_JOBS_IN_ERROR_STATE_SUCCESS",
  DELETE_ALL_JOBS_IN_ERROR_STATE_FAIL: "triply/data/DELETE_ALL_JOBS_IN_ERROR_STATE_FAIL",
  UPDATE_START_JOB_WHEN_UPLOADED: "triply/data/UPDATE_START_JOB_WHEN_UPLOADED",
  REMOVE_FILE_FROM_JOB: "triply/data/REMOVE_FILE_FROM_JOB",
  REMOVE_FILE_FROM_JOB_SUCCESS: "triply/data/REMOVE_FILE_FROM_JOB_SUCCESS",
  REMOVE_FILE_FROM_JOB_FAIL: "triply/data/REMOVE_FILE_FROM_JOB_FAIL",
  RESET_JOB: "triply/data/RESET_JOB",
  RESET_JOB_SUCCESS: "triply/data/RESET_JOB_SUCCESS",
  RESET_JOB_FAIL: "triply/data/RESET_JOB_FAIL",
  UPDATE_JOB_PROGRESS: "triply/data/UPDATE_JOB_PROGRESS",
  SET_LAST_BROWSER_RESOURCE: "triply/data/SET_LAST_BROWSER_RESOURCE",
  SET_LAST_DATA_EDITOR_RESOURCE: "triply/data/SET_LAST_DATA_EDITOR_RESOURCE",
  SET_LAST_DATA_MODEL_RESOURCE: "triply/data/SET_LAST_DATA_MODEL_RESOURCE",
  SET_LAST_SEARCH_QUERY: "triply/data/SET_LAST_SEARCH_QUERY",
  SET_LAST_SKOS_CONCEPT_HIERARCHY: "triply/data/SET_LAST_SKOS_TREE_FILTER",
} as const;

type START_JOB = GlobalAction<
  {
    types: [typeof LocalActions.START_JOB, typeof LocalActions.START_JOB_SUCCESS, typeof LocalActions.START_JOB_FAIL];
    datasetId: string;
    job: Job;
  },
  Routes.datasets._account._dataset.jobs._jobId.start.Post
>;

type CREATE_JOB = GlobalAction<
  {
    types: [
      typeof LocalActions.CREATE_JOB,
      typeof LocalActions.CREATE_JOB_SUCCESS,
      typeof LocalActions.CREATE_JOB_FAIL,
    ];
    datasetId: string;
  },
  Routes.datasets._account._dataset.jobs.Post
>;

type DELETE_JOB = GlobalAction<
  {
    types: [
      typeof LocalActions.DELETE_JOB,
      typeof LocalActions.DELETE_JOB_SUCCESS,
      typeof LocalActions.DELETE_JOB_FAIL,
    ];
    job: Job;
    datasetId: string;
  },
  Routes.datasets._account._dataset.jobs._jobId.Delete
>;
type DELETE_ALL_JOBS_IN_ERROR_STATE = GlobalAction<
  {
    types: [
      typeof LocalActions.DELETE_ALL_JOBS_IN_ERROR_STATE,
      typeof LocalActions.DELETE_ALL_JOBS_IN_ERROR_STATE_SUCCESS,
      typeof LocalActions.DELETE_ALL_JOBS_IN_ERROR_STATE_FAIL,
    ];
    datasetId: string;
  },
  Routes.datasets._account._dataset.jobs.Delete
>;

type REMOVE_FILE_FROM_JOB = GlobalAction<
  {
    types: [
      typeof LocalActions.REMOVE_FILE_FROM_JOB,
      typeof LocalActions.REMOVE_FILE_FROM_JOB_SUCCESS,
      typeof LocalActions.REMOVE_FILE_FROM_JOB_FAIL,
    ];
    datasetId: string;
    filename: string;
    sourceFileId: string;
    job: Job;
  },
  Routes.datasets._account._dataset.jobs._jobId._sourceFileId.Delete
>;

type RESET_JOB = GlobalAction<
  {
    types: [typeof LocalActions.RESET_JOB, typeof LocalActions.RESET_JOB_SUCCESS, typeof LocalActions.RESET_JOB_FAIL];
    datasetId: string;
  },
  Routes.datasets._account._dataset.jobs._jobId.reset.Post
>;

type UPDATE_START_JOB_WHEN_UPLOADED = GlobalAction<{
  type: typeof LocalActions.UPDATE_START_JOB_WHEN_UPLOADED;
  job: Job;
  start: boolean;
}>;

type UPDATE_JOB_PROGRESS = GlobalAction<{
  type: typeof LocalActions.UPDATE_JOB_PROGRESS;
  datasetId: string;
  partiallyUploadedBytes?: number;
  uploadedBytes?: number;
  enqueuedBytes?: number;
  currentUploadSize?: number;
}>;

type SET_LAST_BROWSER_RESOURCE = GlobalAction<{
  type: typeof LocalActions.SET_LAST_BROWSER_RESOURCE;
  datasetId: string;
  resource: string;
}>;
type SET_LAST_DATA_EDITOR_RESOURCE = GlobalAction<{
  type: typeof LocalActions.SET_LAST_DATA_EDITOR_RESOURCE;
  datasetId: string;
  resource: string;
}>;
type SET_LAST_DATA_MODEL_RESOURCE = GlobalAction<{
  type: typeof LocalActions.SET_LAST_DATA_MODEL_RESOURCE;
  datasetId: string;
  resource: string;
}>;
type SET_LAST_SEARCH_QUERY = GlobalAction<{
  type: typeof LocalActions.SET_LAST_SEARCH_QUERY;
  datasetId: string;
  query: string;
}>;
type SET_LAST_SKOS_CONCEPT_HIERARCHY = GlobalAction<{
  type: typeof LocalActions.SET_LAST_SKOS_CONCEPT_HIERARCHY;
  datasetId: string;
  conceptHierarchy: string;
}>;

export type LocalAction =
  | START_JOB
  | CREATE_JOB
  | DELETE_JOB
  | REMOVE_FILE_FROM_JOB
  | RESET_JOB
  | UPDATE_START_JOB_WHEN_UPLOADED
  | UPDATE_JOB_PROGRESS
  | SET_LAST_BROWSER_RESOURCE
  | SET_LAST_DATA_EDITOR_RESOURCE
  | SET_LAST_DATA_MODEL_RESOURCE
  | SET_LAST_SEARCH_QUERY
  | SET_LAST_SKOS_CONCEPT_HIERARCHY
  | DELETE_ALL_JOBS_IN_ERROR_STATE;

export type Job = Models.IndexJob;
export type JobFileInfo = Models.IndexJobFileInfo;

export interface DatasetRecordProps {
  jobs: Job[];
  startingJob: boolean;
  startingJobWhenUploaded: boolean;

  //things related to querying
  fetchingTriples: boolean;
  lastTableQuery?: ParsedQs; //keep track of the last statements query we executed, so we don't issue a query both on backend and frontend
  lastBrowserResource?: string;
  lastDataEditorResource?: string;
  lastDataModelResource?: string;
  lastSearchQuery?: string;

  uploadProgress: number;
  partiallyUploadedBytes: number;
  uploadedBytes: number;
  enqueuedBytes: number;
  currentUploadSize: number;
  downloadProgress: number;
  downloadedBytes: number;
}

export interface State {
  [datasetId: string]: DatasetRecordProps;
}

const computeUploadProgress = (
  currentUploadSize = 0,
  enqueuedBytes = 0,
  uploadedBytes = 0,
  partiallyUploadedBytes = 0,
) => {
  const totalUploaded = uploadedBytes + partiallyUploadedBytes;
  const totalNotUploaded = enqueuedBytes + currentUploadSize - partiallyUploadedBytes;
  if (!totalUploaded) return 0;
  const progress = totalUploaded / (totalUploaded + totalNotUploaded);
  return Math.ceil(progress * 100);
};

function ensureState(draftState: State, datasetId: string) {
  if (!draftState[datasetId]) {
    draftState[datasetId] = {
      jobs: [],
      startingJob: false,
      startingJobWhenUploaded: false,
      fetchingTriples: false,
      lastTableQuery: {},
      lastBrowserResource: undefined,
      lastDataModelResource: undefined,

      uploadProgress: 0,
      partiallyUploadedBytes: 0,
      uploadedBytes: 0,
      enqueuedBytes: 0,
      currentUploadSize: 0,
      downloadProgress: 0,
      downloadedBytes: 0,
    };
  }
  return draftState[datasetId];
}

export const reducer = produce((draftState: State, action: Action) => {
  switch (action.type) {
    case Actions.UPDATE_JOB_PROGRESS:
      var ds = draftState[action.datasetId];
      ds.partiallyUploadedBytes =
        action.partiallyUploadedBytes !== undefined ? action.partiallyUploadedBytes : ds.partiallyUploadedBytes;
      ds.uploadedBytes = action.uploadedBytes !== undefined ? action.uploadedBytes : ds.uploadedBytes;
      ds.enqueuedBytes = action.enqueuedBytes !== undefined ? action.enqueuedBytes : ds.enqueuedBytes;
      ds.currentUploadSize = action.currentUploadSize !== undefined ? action.currentUploadSize : ds.currentUploadSize;
      ds.uploadProgress = computeUploadProgress(
        ds.currentUploadSize,
        ds.enqueuedBytes,
        ds.uploadedBytes,
        ds.partiallyUploadedBytes,
      );
      return;

    case Actions.UPLOAD_FILE_SUCCESS:
      var ds = draftState[action.datasetId];
      const uploadFileJobIndex = ds.jobs.findIndex((job) => job.jobId === action.result.jobId);
      //don't update if the action result is older than the info we already have
      if (ds.jobs[uploadFileJobIndex].updatedAt > action.result.updatedAt) return;

      ds.jobs[uploadFileJobIndex] = { ...action.result };
      ds.uploadedBytes = sumBy(action.result.files, (f) => f.fileSize);
      ds.uploadProgress = computeUploadProgress(
        ds.currentUploadSize,
        ds.enqueuedBytes,
        ds.uploadedBytes,
        ds.partiallyUploadedBytes,
      );
      return;

    case Actions.RESET_JOB_SUCCESS:
      var ds = draftState[action.datasetId];
      const resetJobIndex = ds.jobs.findIndex((job) => job.jobId === action.result.jobId);
      ds.jobs[resetJobIndex] = action.result;
      ds.partiallyUploadedBytes = 0;
      ds.uploadedBytes = sumBy(action.result.files, (f) => f.fileSize);
      ds.enqueuedBytes = 0;
      ds.currentUploadSize = 0;
      ds.uploadProgress = computeUploadProgress(
        ds.currentUploadSize,
        ds.enqueuedBytes,
        ds.uploadedBytes,
        ds.partiallyUploadedBytes,
      );
      ds.startingJobWhenUploaded = false;
      return;

    case Actions.CREATE_JOB_SUCCESS:
      var ds = draftState[action.datasetId];
      ds.jobs.push(action.result);
      ds.partiallyUploadedBytes = 0;
      ds.uploadedBytes = 0;
      ds.enqueuedBytes = 0;
      ds.currentUploadSize = 0;
      ds.uploadProgress = 0;
      ds.startingJobWhenUploaded = false;
      return;

    case Actions.START_JOB:
      draftState[action.datasetId].startingJob = true;
      draftState[action.datasetId].startingJobWhenUploaded = false;
      return;

    case Actions.START_JOB_SUCCESS:
      var ds = draftState[action.datasetId];
      ds.startingJob = false;
      const startedJobIndex = ds.jobs.findIndex((job) => job.jobId === action.result.jobId);
      //only update when we've got newer info (to avoid race conditions)
      if (ds.jobs[startedJobIndex].updatedAt >= action.result.updatedAt) return;
      ds.jobs[startedJobIndex] = action.result;
      return;

    case Actions.UPDATE_START_JOB_WHEN_UPLOADED:
      if (action.job) {
        draftState[action.job.datasetId].startingJobWhenUploaded = action.start;
      }
      return;
    case Actions.SOCKET_EVENT.indexJobIndexingProgress: {
      var ds = draftState[action.data.datasetId];
      const updatedJobIndex = ds.jobs.findIndex(({ jobId }) => jobId === action.data.jobId);
      if (updatedJobIndex >= 0) {
        const job = ds.jobs[updatedJobIndex];
        const indexingProgress = action.data.indexingProgress;
        if (job.indexingProgress < indexingProgress) {
          job.indexingProgress = indexingProgress;
        }
      }
      return;
    }
    case Actions.SOCKET_EVENT.indexJobUpdate: {
      const job = action.data.indexJob;
      var ds = draftState[job.datasetId];
      const updatedJobIndex = ds.jobs.findIndex(({ jobId }) => jobId === job.jobId);
      if (updatedJobIndex >= 0 && ds.jobs[updatedJobIndex].updatedAt < job.updatedAt) {
        ds.jobs[updatedJobIndex] = job;
      }
      return;
    }

    case Actions.START_JOB_FAIL:
      var ds = draftState[action.datasetId];
      ds.startingJob = false;
      const failedJobIndex = ds.jobs.findIndex((job) => job.jobId === action.job.jobId);
      ds.jobs[failedJobIndex] = {
        ...ds.jobs[failedJobIndex],
        error: { message: action.message },
      };
      return;

    case Actions.GET_TRIPLES:
      draftState[action.dataset.id].fetchingTriples = true;
      draftState[action.dataset.id].lastTableQuery = action.query;
      return;

    case Actions.GET_TRIPLES_FAIL:
      draftState[action.dataset.id].fetchingTriples = false;
      draftState[action.dataset.id].lastTableQuery = undefined;
      return;

    case Actions.SET_LAST_DATA_EDITOR_RESOURCE:
      draftState[action.datasetId].lastDataEditorResource = action.resource;
      return;
    case Actions.SET_LAST_DATA_MODEL_RESOURCE:
      draftState[action.datasetId].lastDataModelResource = action.resource;
      return;
    case Actions.SET_LAST_BROWSER_RESOURCE:
      draftState[action.datasetId].lastBrowserResource = action.resource;
      return;
    case Actions.SET_LAST_SEARCH_QUERY:
      draftState[action.datasetId].lastSearchQuery = action.query;
      return;

    case Actions.REMOVE_FILE_FROM_JOB_SUCCESS:
      var ds = draftState[action.datasetId];
      const removedFileJob = ds.jobs.find((job) => job.jobId === action.job.jobId);
      if (removedFileJob) {
        removedFileJob.files = removedFileJob.files.filter((file) => file.sourceFileId !== action.sourceFileId);
        ds.uploadedBytes = sumBy(removedFileJob.files, (f) => f.fileSize);
      }
      ds.uploadProgress = computeUploadProgress(
        ds.currentUploadSize,
        ds.enqueuedBytes,
        ds.uploadedBytes,
        ds.partiallyUploadedBytes,
      );
      return;

    case Actions.DELETE_JOB_SUCCESS:
      var ds = draftState[action.datasetId];
      ds.jobs = ds.jobs.filter((job) => job.jobId !== action.job.jobId);
      ds.startingJobWhenUploaded = false;
      return;
    case Actions.DELETE_ALL_JOBS_IN_ERROR_STATE_SUCCESS: {
      const ds = draftState[action.datasetId];
      // A bit of an expensive operation for very large lists of jobs. Highly unlikely that'll happen
      ds.jobs = ds.jobs.filter((job) => !action.result.deletedJobIds.includes(job.jobId));
      return;
    }

    case Actions.GET_TRIPLES_SUCCESS:
      draftState[action.dataset.id].fetchingTriples = false;
      return;

    case Actions.GET_CURRENT_DATASET_SUCCESS:
    case Actions.REFRESH_CURRENT_DATASET_SUCCESS:
    case Actions.SOCKET_EVENT.indexJobFinished:
      let datasetJson;
      if (action.type === Actions.SOCKET_EVENT.indexJobFinished) {
        datasetJson = action.data.dataset;
      } else {
        datasetJson = action.result;
      }

      var ds = ensureState(draftState, datasetJson.id);
      if (datasetJson.openJobs) {
        //if this user has no write permissions, then the jobs value will be undefined
        const currentUploadJob = last(datasetJson.openJobs.filter((job) => job.type === "upload"));
        ds.partiallyUploadedBytes = 0;
        ds.uploadedBytes = currentUploadJob ? sumBy(currentUploadJob.files, (f) => f.fileSize) : 0;
        ds.enqueuedBytes = 0;
        ds.currentUploadSize = 0;
        ds.uploadProgress = computeUploadProgress(
          ds.currentUploadSize,
          ds.enqueuedBytes,
          ds.uploadedBytes,
          ds.partiallyUploadedBytes,
        );
        ds.jobs = datasetJson.openJobs;
      }
      return;
  }
}, {} as State);

export function setLastBrowserResource(datasetId: string, resource: string): BeforeDispatch<SET_LAST_BROWSER_RESOURCE> {
  return {
    type: Actions.SET_LAST_BROWSER_RESOURCE,
    datasetId,
    resource,
  };
}
export function setLastDataEditorResource(
  datasetId: string,
  resource: string,
): BeforeDispatch<SET_LAST_DATA_EDITOR_RESOURCE> {
  return {
    type: Actions.SET_LAST_DATA_EDITOR_RESOURCE,
    datasetId,
    resource,
  };
}
export function setLastDataModelResource(
  datasetId: string,
  resource: string,
): BeforeDispatch<SET_LAST_DATA_MODEL_RESOURCE> {
  return {
    type: Actions.SET_LAST_DATA_MODEL_RESOURCE,
    datasetId,
    resource,
  };
}
export function setLastSearchQuery(datasetId: string, query: string): BeforeDispatch<SET_LAST_SEARCH_QUERY> {
  return {
    type: Actions.SET_LAST_SEARCH_QUERY,
    datasetId,
    query: query,
  };
}

export function createJob(
  forDataset: Dataset,
  body: Routes.datasets._account._dataset.jobs.Post["Req"]["Body"],
): BeforeDispatch<CREATE_JOB> {
  return {
    datasetId: forDataset.id,
    types: [Actions.CREATE_JOB, Actions.CREATE_JOB_SUCCESS, Actions.CREATE_JOB_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/datasets/" + forDataset.owner.accountName + "/" + forDataset.name + "/jobs",
        method: "post",
        body: body,
      }),
  };
}

export function startJob(job: Job): BeforeDispatch<START_JOB> {
  return {
    datasetId: job.datasetId,
    job: job,
    types: [Actions.START_JOB, Actions.START_JOB_SUCCESS, Actions.START_JOB_FAIL],
    promise: (client) =>
      client.req({
        url: job.jobUrl + "/start",
        method: "post",
      }),
  };
}
export function updateStartWhenUploaded(job: Job, start: boolean): BeforeDispatch<UPDATE_START_JOB_WHEN_UPLOADED> {
  return {
    job,
    start,
    type: Actions.UPDATE_START_JOB_WHEN_UPLOADED,
  };
}
export function removeFileFromJob(
  filename: string,
  job: Job,
  sourceFileId: string,
): BeforeDispatch<REMOVE_FILE_FROM_JOB> {
  return {
    datasetId: job.datasetId,
    filename,
    sourceFileId,
    job,
    types: [Actions.REMOVE_FILE_FROM_JOB, Actions.REMOVE_FILE_FROM_JOB_SUCCESS, Actions.REMOVE_FILE_FROM_JOB_FAIL],
    promise: (client) =>
      client.req({
        url: `${job.jobUrl}/${sourceFileId}`,
        method: "delete",
      }),
  };
}
export function resetJob(job: Job): BeforeDispatch<RESET_JOB> {
  return {
    datasetId: job.datasetId,
    types: [Actions.RESET_JOB, Actions.RESET_JOB_SUCCESS, Actions.RESET_JOB_FAIL],
    promise: (client) =>
      client.req({
        url: job.jobUrl + "/reset",
        method: "post",
      }),
  };
}
export function deleteJob(job: Job): BeforeDispatch<DELETE_JOB> {
  return {
    job: job,
    datasetId: job.datasetId,
    types: [Actions.DELETE_JOB, Actions.DELETE_JOB_SUCCESS, Actions.DELETE_JOB_FAIL],
    promise: (client) =>
      client.req({
        url: job.jobUrl,
        method: "delete",
      }),
  };
}

export function deleteAllJobsInErrorState(opts: {
  datasetId: string;
  accountName: string;
  datasetName: string;
}): BeforeDispatch<DELETE_ALL_JOBS_IN_ERROR_STATE> {
  return {
    datasetId: opts.datasetId,
    types: [
      Actions.DELETE_ALL_JOBS_IN_ERROR_STATE,
      Actions.DELETE_ALL_JOBS_IN_ERROR_STATE_SUCCESS,
      Actions.DELETE_ALL_JOBS_IN_ERROR_STATE_FAIL,
    ],
    promise: (client) =>
      client.req({
        pathname: "/datasets/" + opts.accountName + "/" + opts.datasetName + "/jobs",
        method: "delete",
      }),
  };
}

export function updateJobProgress(
  datasetId: string,
  partiallyUploadedBytes?: number,
  uploadedBytes?: number,
  enqueuedBytes?: number,
  currentUploadSize?: number,
): BeforeDispatch<UPDATE_JOB_PROGRESS> {
  return {
    datasetId,
    type: Actions.UPDATE_JOB_PROGRESS,
    partiallyUploadedBytes,
    uploadedBytes,
    enqueuedBytes,
    currentUploadSize,
  };
}
